import React, { useState } from 'react';
import LoadingContext from '../contexts/LoadingContext';
import { ProviderProps } from './interfaces/provider-default-props.interface';

const LoadingProvider: React.FC<ProviderProps> = ({ children }) => {
    const [open, setOpen] = useState(false);

    const showLoading = () => {
        setOpen(true);
    };

    const closeLoading = () => setOpen(false);

    return (
        <LoadingContext.Provider
            value={{ showLoading, closeLoading, loading: open }}
        >
            {children}
        </LoadingContext.Provider>
    );
};

export default LoadingProvider;
