import styled from 'styled-components';

export const ContainerModal = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    height: 100%;

    .content-modal {
        background: #ffffff;
        border-radius: 16px;
        width: 285px;
        max-width: 544px;
        height: 100%;
        overflow: auto;
        z-index: 9999;

        header {
            text-align: right;

            svg {
                cursor: pointer;
            }
        }

        #title {
            margin-bottom: 30px;

            h1 {
                text-align: center;
                font-size: 1.5em;
                color: #1a202c;
                font-weight: 700;
                margin-top: 33px;
                margin-bottom: 12px;
            }

            p {
                text-align: center;
                font-weight: 500;
                font-size: 0.875em;
                color: #718096;
            }
        }

        form {
            > div {
                margin-bottom: 25px;
            }

            > div:last-of-type {
                margin-top: 5px;
            }

            .input {
                position: relative;
                display: flex;
                align-items: center;

                input {
                    border: 1px solid #cccccc !important;
                    border-radius: 8px;
                    min-height: 42px;
                    max-height: 42px;
                }

                .icon {
                    position: absolute;
                    left: 18px;
                }
            }
        }

        ul {
            display: flex;
            gap: 4px;
            flex-direction: column;
            max-height: 176px;
            overflow: auto;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            ::-webkit-scrollbar-thumb {
                background: #edf2f7;
                border-radius: 50px;
            }

            li {
                width: 100%;
                margin: 0;
                padding: 0;
                min-height: 56px;
                font-size: 14px;

                display: flex;
                align-content: center;

                cursor: pointer;

                .option-list {
                    font-weight: 500;
                    font-size: 14px;
                    color: #000;
                }

                .default {
                    color: #1a202c;
                    font-weight: 600;
                    background-color: #d0ff61;
                    display: block;
                    height: 18px;
                    margin-left: 6px;
                    font-size: 12px;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                }
            }
        }

        input {
            border-radius: 12px;
            border: 1px solid #e2e8f0;
        }

        .container-submit {
            display: flex;
            gap: 5px;

            button {
                width: 164px;
                height: 48px;
                font-size: 0.875em;
                border-radius: 12px;
            }

            button[type='submit'] {
                background: #d0ff61;
                color: #1a202c;
            }

            button[type='button'] {
                border: 1px solid #a0aec0;
                border-radius: 12px;
                color: #a0aec0;
                background-color: #fff;
            }
        }
    }
    &.listProjectsDark {
        .content-modal {
            background: #1d1d1f;

            #title {
                h1 {
                    color: #fff;
                }
                p {
                    color: #a09fa5;
                }
            }

            form {
                input {
                    background: #3a3a3a !important;
                    border-color: #3a3a3a;
                    color: #ffffff !important;

                    &:focus {
                        border: 1px solid #fff !important;
                    }
                }
            }

            ul {
                li {
                    .option-list {
                        color: #fff;
                    }
                    .default {
                        color: #000;
                    }
                }
            }
        }
    }

    @media (max-width: 63.9375rem) {
        justify-content: flex-start;
        .content-modal {
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            border-radius: 0px;

            ul {
                max-height: 100%;
            }
        }
    }
`;
