import React from 'react';
import { DetailsContent } from './styles';
import { Button, HeaderModalDetails, Input } from 'app/presentation/components';
import ModalDeleteAccount from '../../../../app/presentation/components/dashboard/ModalDeleteAccount';

import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { useSnackbar } from 'notistack';
import { AuthContext } from 'app/presentation/contexts/AuthContext';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';

const General: React.FC = () => {
    const { register, handleSubmit, setValue } = useForm<FieldValues>();
    const [open, setModal] = React.useState(false);

    const { updateWorkspace, workspace } = useUserConfig();
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();

    const closeModalListProject = () => {
        setModal(false);
    };

    const user = React.useContext(AuthContext);

    const email = user?.email;

    React.useEffect(() => {
        setValue('name', workspace?.name);
        setValue('company_domain', workspace?.company_domain);
        setValue('email', email);
    }, [workspace]);

    const domainFormatError = () => {
        enqueueSnackbar('Confira o formato do dominio!', {
            variant: 'error',
        });
    };

    const internalError = () => {
        enqueueSnackbar('Erro interno!', {
            variant: 'error',
        });
    };

    const nameFormatError = () => {
        enqueueSnackbar('O nome é obrigatório!', {
            variant: 'error',
        });
    };

    const handleSubmitForm: SubmitHandler<FieldValues> = async data => {
        try {
            if (data.name.trim() === '') {
                nameFormatError();
                return;
            }

            if (
                !/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
                    data.company_domain,
                )
            ) {
                domainFormatError();
                return;
            }

            await updateWorkspace(workspace.id, data.name, data.company_domain);
        } catch (e: any) {
            internalError();
        }
    };

    return (
        <>
            <DetailsContent>
                <HeaderModalDetails
                    title={routeTranslate(intl, 'general.title')}
                />
                <form onSubmit={handleSubmit(handleSubmitForm)} id="form">
                    <div className="content-form">
                        <div>
                            <Input
                                id="name"
                                register={register}
                                label={routeTranslate(
                                    intl,
                                    'general.companyName',
                                )}
                            />
                            <Input
                                id="company_domain"
                                register={register}
                                label={routeTranslate(intl, 'general.domain')}
                            />
                        </div>
                        <div>
                            <Input
                                id="email"
                                register={register}
                                label={routeTranslate(
                                    intl,
                                    'general.companyMail',
                                )}
                                disabled
                            />
                        </div>
                    </div>
                    <Button type="submit">
                        {routeTranslate(intl, 'button.save')}
                    </Button>
                </form>

                {/*   <div
                    className="box"
                    id="account-cancel"
                    style={{ borderColor: darkmode ? '#000' : '' }}
                >
                    <div>
                        <h1>
                            {routeTranslate(intl, 'billing.terminateAccount')}
                        </h1>
                    </div>
                    <div>
                        <p
                            style={{
                                color: darkmode ? '#A09FA5' : '',
                            }}
                        >
                            {routeTranslate(
                                intl,
                                'billing.terminateAccount.description',
                            )}
                        </p>
                        <Button
                            onClick={openModalListProject}
                            style={{
                                borderColor: darkmode ? '#E2E8F0' : '',
                                background: darkmode ? '#000' : '',
                                color: darkmode ? '#fff' : '',
                            }}
                        >
                            {routeTranslate(
                                intl,
                                'billing.terminateAccount.button',
                            )}
                        </Button>
                    </div>
                            </div>*/}
            </DetailsContent>
            <ModalDeleteAccount
                active={open}
                handleClose={closeModalListProject}
            />
        </>
    );
};

export default General;
