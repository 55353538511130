import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Download } from 'app/presentation/components/icons';
import {
    Image,
    ImageHistoryInterface,
} from '../../../../../interfaces/arte-ai.interface';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { TfiMoreAlt } from 'react-icons/tfi';
import { Menu, MenuList, Paper, useMediaQuery } from '@mui/material';
import { MuiMenuHeader } from 'pages/dashboard/documents/styles';
import Tooltip from '@mui/material/Tooltip';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import { TbLayersIntersect } from 'react-icons/tb';
import { ArteAiService } from 'services/arte-ai';
import Loading from '../../common/Loading';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    imagesData: Array<Image | ImageHistoryInterface> | undefined | null;
    initalImageIndex: number;
    handleDownload: (
        withId: boolean,
        id?: number,
        useLink?: boolean,
        link?: string,
    ) => void;
    handleGenerateVarition: (withId: boolean, id?: number) => void;
    h: number;
    w: number;
    project_id: number;
    setIsUpscaled: (value: boolean) => void;
    isUpscaled: boolean;
}

const arteAiService = new ArteAiService();

const ModalArteAiImages: React.FC<ModalProps> = ({
    active,
    handleClose,
    imagesData,
    initalImageIndex = 0,
    handleDownload,
    h,
    w,
    project_id,
    setIsUpscaled,
    isUpscaled,
    handleGenerateVarition,
}) => {
    const { darkmode } = useDarkMode();
    const [swiper, setSwiper] = React.useState<any>(null);
    const swiperRef = React.useRef<SwiperCore>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
    const [anchorImgId, setAnchorImgId] = React.useState(0);
    const openMenu = Boolean(anchorEl);
    const [isLoadingUpscaling, setIsLoadingUpscaling] = React.useState(false);
    const [images, setImages] = React.useState<
        Array<Image | ImageHistoryInterface> | undefined | null
    >(imagesData);
    const intl = useIntl();
    const isDesktop = useMediaQuery('(min-width: 64em)');
    const aspect = `${w} / ${h}`;

    const slideTo = (index: number) => {
        if (swiper) {
            swiper.slideTo(index);
        }
    };

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            handleClose();
            setImages(imagesData);
        }
    };

    const handleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
        id: number,
        i: number,
    ) => {
        if (!event) {
            return;
        }
        slideTo(i);
        setAnchorImgId(id);
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const screenW = window.innerWidth;

    const modalStyleProps = {
        width: isDesktop ? 'auto' : screenW - 30,
        maxHeight: isDesktop ? '98vh' : '',
        borderRadius: isDesktop ? 12 : undefined,
        aspectRatio: aspect,
    };

    const handleUpscale = async () => {
        setIsLoadingUpscaling(true);
        const imgId = anchorImgId;
        const formData = new FormData();
        formData.append('project_id', String(project_id));
        formData.append('image_id', String(imgId));
        formData.append('width', String(w * 2));
        formData.append('height', String(h * 2));

        const result = await arteAiService.upscaleImage(formData);

        if (result instanceof Error) {
            setIsLoadingUpscaling(false);

            return;
        }

        if (result && result.image) {
            setImages([result.image]);
            setIsLoadingUpscaling(false);
            handleCloseMenu();
            setIsUpscaled(true);
        }
    };

    React.useEffect(() => {
        if (imagesData && imagesData.length > 0) {
            setImages(imagesData);
        }
    }, [imagesData]);
    React.useEffect(() => {
        slideTo(initalImageIndex);
    }, [active, initalImageIndex]);

    const handleVariation = (id: number) => {
        handleClose();
        handleCloseMenu();
        handleGenerateVarition(true, id);
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'modalArteDark' : ''}
                style={{ aspectRatio: aspect }}
            >
                {images ? (
                    <div className="content-modal" style={modalStyleProps}>
                        <button
                            className="btn-prev"
                            onClick={() => swiperRef.current?.slidePrev()}
                        >
                            <RiArrowLeftSLine size={24} />
                        </button>
                        <Swiper
                            onBeforeInit={(swiper: any) => {
                                swiperRef.current = swiper;
                            }}
                            slidesPerView={1}
                            onSwiper={setSwiper}
                            spaceBetween={30}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            style={modalStyleProps}
                        >
                            {images.map((image, i) => {
                                return (
                                    <SwiperSlide key={image.id}>
                                        <img
                                            src={image.response}
                                            alt={image.response}
                                            width={'100%'}
                                            height={'95%'}
                                            style={{ aspectRatio: aspect }}
                                        />
                                        <div className="actions">
                                            <Tooltip
                                                title={routeTranslate(
                                                    intl,
                                                    'tooltip.variation',
                                                )}
                                                placement="top"
                                                sx={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                }}
                                                PopperProps={{
                                                    sx: {
                                                        '& .MuiTooltip-tooltip':
                                                            {
                                                                backgroundColor:
                                                                    darkmode
                                                                        ? '#fff'
                                                                        : '#000',
                                                                color: darkmode
                                                                    ? '#000 '
                                                                    : '#fff',
                                                            },
                                                    },
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [
                                                                    0, -10,
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                }}
                                            >
                                                <div
                                                    className="copy"
                                                    onClick={() =>
                                                        handleVariation(
                                                            image.id,
                                                        )
                                                    }
                                                >
                                                    <TbLayersIntersect />
                                                </div>
                                            </Tooltip>

                                            <section>
                                                <div
                                                    className="download"
                                                    onClick={() =>
                                                        handleDownload(
                                                            true,
                                                            image.id,
                                                            true,
                                                            image.response,
                                                        )
                                                    }
                                                >
                                                    <Download
                                                        hideTooltip={false}
                                                        color={
                                                            darkmode
                                                                ? '#fff'
                                                                : '#000'
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    onClick={e =>
                                                        handleClick(
                                                            e,
                                                            image.id,
                                                            i,
                                                        )
                                                    }
                                                >
                                                    <TfiMoreAlt
                                                        size={14}
                                                        style={{
                                                            color: darkmode
                                                                ? '#fff'
                                                                : '#000',
                                                        }}
                                                    />
                                                </div>
                                            </section>
                                        </div>
                                        {isUpscaled ||
                                        (image.image_generation &&
                                            image.image_generation.type ===
                                                'upscale') ? (
                                            <div className="upscaleLabel">
                                                <p>
                                                    {routeTranslate(
                                                        intl,
                                                        'tooltip.upscaled',
                                                    )}
                                                </p>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <button
                            className="btn-next"
                            onClick={() => swiperRef.current?.slideNext()}
                        >
                            <RiArrowRightSLine size={24} />
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{
                                width: '100%',
                                '& > .MuiPaper-root': {
                                    borderRadius: '12px',
                                    boxShadow:
                                        '8px 12px 24px rgba(93, 106, 131, 0.1)',
                                    background: darkmode ? '#1D1D1F' : '',
                                },
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Paper
                                sx={{
                                    width: 'auto',
                                    boxShadow: 0,
                                    background: darkmode ? '#1D1D1F' : '',
                                }}
                            >
                                <MenuList
                                    sx={{
                                        padding: 0,
                                        boxShadow: 0,
                                        background: darkmode ? '#1D1D1F' : '',
                                    }}
                                    disablePadding
                                    dense
                                >
                                    <MuiMenuHeader>
                                        <ul>
                                            <li
                                                onClick={() => {
                                                    handleCloseMenu();
                                                }}
                                                onMouseEnter={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#151718'
                                                            : '')
                                                }
                                                onMouseLeave={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#1D1D1F'
                                                            : '')
                                                }
                                            >
                                                {routeTranslate(
                                                    intl,
                                                    'tooltip.open',
                                                )}
                                            </li>
                                            <Tooltip
                                                title={routeTranslate(
                                                    intl,
                                                    'tooltip.upscale',
                                                )}
                                                placement="top"
                                                sx={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                }}
                                                PopperProps={{
                                                    sx: {
                                                        '& .MuiTooltip-tooltip':
                                                            {
                                                                backgroundColor:
                                                                    darkmode
                                                                        ? '#fff'
                                                                        : '#000',
                                                                color: darkmode
                                                                    ? '#000 '
                                                                    : '#fff',
                                                            },
                                                    },
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [
                                                                    0, -10,
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                }}
                                            >
                                                <li
                                                    onClick={handleUpscale}
                                                    onMouseEnter={(e: any) =>
                                                        (e.target.style.backgroundColor =
                                                            darkmode
                                                                ? '#151718'
                                                                : '')
                                                    }
                                                    onMouseLeave={(e: any) =>
                                                        (e.target.style.backgroundColor =
                                                            darkmode
                                                                ? '#1D1D1F'
                                                                : '')
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            width: 0,
                                                            marginLeft: '50%',
                                                            display:
                                                                isLoadingUpscaling
                                                                    ? undefined
                                                                    : 'none',
                                                        }}
                                                    >
                                                        <Loading
                                                            open={true}
                                                            absolute={false}
                                                            size={25}
                                                        />
                                                    </div>

                                                    <div
                                                        style={{
                                                            opacity:
                                                                isLoadingUpscaling
                                                                    ? 0
                                                                    : 1,
                                                        }}
                                                    >
                                                        {routeTranslate(
                                                            intl,
                                                            'navmenu.user.expand',
                                                        )}
                                                    </div>
                                                </li>
                                            </Tooltip>

                                            <li
                                                style={{ paddingRight: 0 }}
                                                onMouseEnter={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#151718'
                                                            : '')
                                                }
                                                onMouseLeave={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#1D1D1F'
                                                            : '')
                                                }
                                                onClick={() =>
                                                    handleVariation(anchorImgId)
                                                }
                                            >
                                                {routeTranslate(
                                                    intl,
                                                    'tooltip.variation',
                                                )}
                                            </li>
                                            <li
                                                onMouseEnter={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#151718'
                                                            : '')
                                                }
                                                onMouseLeave={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#1D1D1F'
                                                            : '')
                                                }
                                                onClick={() =>
                                                    handleDownload(
                                                        true,
                                                        anchorImgId,
                                                        isUpscaled,
                                                        images[0].response,
                                                    )
                                                }
                                            >
                                                Download
                                            </li>
                                            <li
                                                onMouseEnter={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#151718'
                                                            : '')
                                                }
                                                onMouseLeave={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#1D1D1F'
                                                            : '')
                                                }
                                            >
                                                {routeTranslate(
                                                    intl,
                                                    'tooltip.report',
                                                )}
                                            </li>
                                        </ul>
                                    </MuiMenuHeader>
                                </MenuList>
                            </Paper>
                        </Menu>
                    </div>
                ) : (
                    <></>
                )}
            </ContainerModal>
        </Fade>
    );
};

export default ModalArteAiImages;
