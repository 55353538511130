import React from 'react';
import * as S from './styles';
import { IconLogo } from 'app/presentation/components/icons';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Fade, Menu, MenuList, Paper, useMediaQuery } from '@mui/material';
import { Input } from 'app/presentation/components/index';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { routeTranslate } from 'utils/intl';
import { FormattedMessage, useIntl } from 'react-intl';
import useUserConfig from '../../../hooks/useUserConfigs';
import useDarkMode from '../../../hooks/useDarkMode';
import { Brands } from 'app/presentation/hooks/interfaces/brand';
import ModalNewbBrand from 'app/presentation/components/dashboard/ModalNewBrand';
import { BsThreeDots } from 'react-icons/bs';
import { BiTrash } from 'react-icons/bi';
import { BrandServices } from 'services/brand';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { FiEdit3 } from 'react-icons/fi';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { NavItem } from './nav-item';
import useText from 'app/presentation/hooks/useText';
import {
    IconBolt,
    IconBook,
    IconChartPie,
    IconInbox,
} from '@tabler/icons-react';
import useAgent from 'app/presentation/hooks/useAgent';

interface Props {
    handleCloseMenu?: () => void;
}

const brandsService = new BrandServices();

export const AgentMenu: React.FC<Props> = ({
    handleCloseMenu = () => {
        return;
    },
}) => {
    const [edintingBrand, setEdintingBrand] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState(false);
    const [openMenuBrand, setOpenMenuBrand] = React.useState(false);
    const [openSubMenuBrand, setOpenSubMenuBrand] = React.useState(false);
    const [anchorElSubMenuBrand, setAnchorElSubMenuBrand] =
        React.useState<null | HTMLElement>(null);
    const [selectedAnchorBrand, setSelectedAnchorBrand] = React.useState<
        Brands | undefined
    >();
    const { register, handleSubmit, watch } = useForm<FieldValues>();
    const isDesktop = useMediaQuery('(min-width: 64em)');
    const intl = useIntl();
    const {
        workspace,
        project,
        brands,
        activeBrand,
        setActiveBrand,
        getBrands,
        modalNewBrand,
        setModalNewBrand,
        limits,
    } = useUserConfig();
    const { agent } = useAgent()
    const reachedTotalBrands =
        limits.total_brands === -1
            ? false
            : limits.used_brands >= limits.total_brand_voices;
    const { darkmode } = useDarkMode();
    const watchSearchBrand = watch('search-brand');
    const { enqueueSnackbar } = useSnackbar();
    const { routeGetter } = useText();
    const agentRoutes = routeGetter('agent');
    const routes = routeGetter();

    const openModalNewBrand = () => {
        setModalNewBrand(true);
    };
    const closeModalNewBrand = () => {
        setModalNewBrand(false);
        setEdintingBrand(false);
    };

    const handleClickSubMenuBrand = (
        event: React.MouseEvent<HTMLLIElement>,
        item: Brands,
    ) => {
        setAnchorElSubMenuBrand(event.currentTarget);
        setOpenSubMenuBrand(true);
        setSelectedAnchorBrand(item);
    };

    const handleCloseSubMenuBrand = () => {
        setAnchorElSubMenuBrand(null);
        setOpenSubMenuBrand(false);
    };

    const handleNewBrand = () => {
        handleOpenMenuBrand();
        openModalNewBrand();
    };

    const handleSubmitForm: SubmitHandler<FieldValues> = async () => {
        return;
    };

    const handleOpenMenuBrand = () => {
        setOpenMenuBrand(!openMenuBrand);
    };

    const closeModalBrand = (e: any) => {
        if (e.target === e.currentTarget) setOpenMenuBrand(false);
    };

    const handleChangeBrand = (brand: Brands) => {
        if (brand.id !== activeBrand?.id) {
            setActiveBrand(brand);
            localStorage.setItem('activeBrandId', String(brand.id));
        }
    };

    const handleGetBrands = async () => {
        await getBrands(workspace.id, project.id);
    };

    const handleDeleteBrand = async () => {
        if (selectedAnchorBrand) {
            try {
                setLoadingDelete(true);

                await brandsService.delete(workspace.id, project.id, selectedAnchorBrand.id);

                const currentBrandId = localStorage.getItem("activeBrandId")
                if (currentBrandId && parseInt(currentBrandId) == selectedAnchorBrand.id) {
                    localStorage.removeItem("activeBrandId")
                }

                handleCloseSubMenuBrand();

                setLoadingDelete(false);
                enqueueSnackbar(routeTranslate(intl, 'brand.delete.success'), { variant: 'success' });

                await handleGetBrands();
            } catch (error) {
                enqueueSnackbar(routeTranslate(intl, 'brand.delete.error'), { variant: 'error' });
            } finally {
                setLoadingDelete(false);
            }
        }
    };

    const brandImg = activeBrand?.logo_url !== '';

    return (
        <S.ContainerMenu className={darkmode ? 'dark' : ''}>
            <div>
                <div id="logo">
                    <Link
                        title="Dashboard"
                        to={`/${routeTranslate(
                            intl,
                            'route.urlLang',
                        )}${routeTranslate(intl, 'route.agent.home')}`}
                    >
                        {isDesktop ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="36"
                                viewBox="0 0 40 36"
                                fill={darkmode ? '#fff' : 'none'}
                            >
                                <g clipPath="url(#clip0_15458_13313)">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M31.9347 20.5445C31.816 22.3156 31.5787 24.0388 30.9247 25.4717C29.3637 28.8882 25.2395 28.1614 21.8681 27.8297C16.6984 27.3205 15.8084 27.318 10.7336 27.9295C9.17644 28.1173 6.74385 28.5566 5.55722 27.6833C2.85566 25.6959 3.18264 17.1125 5.46097 13.6545C8.20736 9.48656 23.9869 7.95516 29.1712 11.5116C30.396 12.3511 30.9735 13.3811 31.435 15.1756C31.8411 16.7562 32.0507 18.8136 31.9347 20.5445ZM14.631 7.62478C10.3117 8.30109 4.88348 10.1007 3.1444 14.235C2.73963 15.1963 2.51944 16.2095 2.34145 17.2265C2.21883 17.9261 2.3876 17.7331 1.84702 18.0492C1.35523 18.3368 0.921449 18.8603 0.630065 19.3694C-0.340335 21.0706 -0.00676032 24.2824 0.405923 26.0586C0.59974 26.8943 0.750046 27.2985 1.18778 27.8582C1.35259 28.0694 1.60046 28.2676 1.89317 28.4231C2.23202 28.6019 2.63283 28.6395 2.88203 28.7664C3.04684 29.0035 3.18132 29.4751 3.31581 29.7692C3.46743 30.1022 3.64147 30.4028 3.82078 30.7008C4.20446 31.3382 4.68043 31.8914 5.16827 32.2931C7.87774 34.5202 16.1011 35.3857 19.6241 35.5256C24.0199 35.6992 30.839 35.1771 33.9005 32.1117C34.9593 31.0506 35.2454 29.8612 35.8928 28.2365C37.1519 28.0435 38.2502 28.4412 39.0703 26.6144C40.1106 24.2953 40.3861 21.02 39.3155 18.6892C38.6998 17.3483 38.1685 16.707 36.6456 16.5126C36.0721 14.678 35.4498 13.2438 34.3146 12.1711C34.006 11.8795 33.7924 11.6606 33.443 11.3691C31.3612 9.62519 27.8606 8.43194 25.1617 7.90074C21.6136 7.20241 18.2067 7.24517 14.6297 7.62348L14.631 7.62478Z"
                                        fill={darkmode ? '#fff' : 'black'}
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M21.995 15.6077C19.3805 16.1169 19.8235 21.3602 22.4235 20.9352C23.4467 20.7681 24.2325 19.4647 24.1428 17.9683C24.0651 16.6688 23.1751 15.3784 21.995 15.6077Z"
                                        fill={darkmode ? '#fff' : 'black'}
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.317 15.6404C9.34525 15.9254 8.65964 17.1575 8.78226 18.6954C8.88378 19.9651 9.79221 21.249 11.0013 20.9031C11.9558 20.6297 12.6559 19.3419 12.5228 17.8377C12.412 16.5758 11.5207 15.2867 10.317 15.6391V15.6404Z"
                                        fill={darkmode ? '#fff' : 'black'}
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M14.2723 22.4053C13.472 22.5776 14.2077 23.8706 15.1438 24.3227C16.3502 24.9045 18.0273 24.4562 18.6193 23.031C18.9226 22.3016 18.3372 22.3262 17.7109 22.3405C16.9857 22.3573 14.801 22.2912 14.2736 22.404L14.2723 22.4053Z"
                                        fill={darkmode ? '#fff' : 'black'}
                                    />
                                    <path
                                        d="M18.0986 5.8354C19.7385 5.8354 21.0678 4.5291 21.0678 2.9177C21.0678 1.3063 19.7385 0 18.0986 0C16.4588 0 15.1294 1.3063 15.1294 2.9177C15.1294 4.5291 16.4588 5.8354 18.0986 5.8354Z"
                                        fill={darkmode ? '#fff' : 'black'}
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.913 22.318C13.5208 26.6259 18.8079 26.6038 19.3973 22.2817C19.4223 22.099 19.3709 21.9332 19.247 21.7945C19.1231 21.6559 18.9609 21.5847 18.7737 21.586L13.5274 21.6196C13.3415 21.6209 13.1807 21.6935 13.0594 21.8321C12.9381 21.9707 12.888 22.1366 12.913 22.318Z"
                                        fill={darkmode ? '#fff' : 'black'}
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_15458_13313">
                                        <rect
                                            width="40"
                                            height="35.5556"
                                            fill="white"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        ) : (
                            <IconLogo fill={darkmode ? '#fff' : '#1A202C'} />
                        )}
                    </Link>
                </div>

                <div>
                    {activeBrand && (
                        <div id="select-brand">
                            <div
                                className="select-label"
                                onClick={handleOpenMenuBrand}
                            >
                                <div
                                    className="img-div"
                                    style={{
                                        background: !brandImg
                                            ? activeBrand.color
                                            : '#d0ff61',
                                    }}
                                >
                                    {brandImg && (
                                        <img
                                            src={activeBrand.logo_url}
                                            alt="User logo"
                                        />
                                    )}
                                </div>
                                {!isDesktop && (
                                    <>
                                        <div className="desc">
                                            <span>
                                                <FormattedMessage id="brand" />
                                            </span>
                                            <p>{activeBrand.name}</p>
                                        </div>

                                        <div className="icon-content">
                                            <MdOutlineKeyboardArrowDown
                                                size={20}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <Fade in={openMenuBrand}>
                                <div>
                                    <div
                                        className="modal-banner active"
                                        onClick={closeModalBrand}
                                    ></div>
                                    <div className="modal-project active">
                                        <S.MuiMenuHeader>
                                            <div className="menu-mui-header">
                                                <form
                                                    onSubmit={handleSubmit(
                                                        handleSubmitForm,
                                                    )}
                                                    id="search"
                                                >
                                                    <Input
                                                        placeholder={routeTranslate(
                                                            intl,
                                                            'navmenu.brand.search',
                                                        )}
                                                        id="search-brand"
                                                        register={register}
                                                    />
                                                </form>
                                            </div>
                                            <ul className="list-project">
                                                <p className="desc">
                                                    {routeTranslate(
                                                        intl,
                                                        'navmenu.brand',
                                                    )}
                                                </p>
                                                <div className="scroll-project">
                                                    {brands &&
                                                        brands.length > 0 ? (
                                                        brands
                                                            .filter(b =>
                                                                b.name
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        watchSearchBrand?.toLowerCase(),
                                                                    ),
                                                            )
                                                            .map(
                                                                (
                                                                    item,
                                                                    index,
                                                                ) => {
                                                                    const hasImg =
                                                                        item.logo_url !==
                                                                        '';
                                                                    const bgColor =
                                                                        item.color;

                                                                    return (
                                                                        <li
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleChangeBrand(
                                                                                        item,
                                                                                    )
                                                                                }
                                                                                className="helper-container"
                                                                            >
                                                                                <div
                                                                                    className="img-brand"
                                                                                    style={{
                                                                                        background:
                                                                                            hasImg
                                                                                                ? undefined
                                                                                                : bgColor,
                                                                                    }}
                                                                                >
                                                                                    {hasImg ? (
                                                                                        <img
                                                                                            src={
                                                                                                item.logo_url
                                                                                            }
                                                                                            alt="User logo"
                                                                                        />
                                                                                    ) : (
                                                                                        <p>
                                                                                            {item.name.charAt(
                                                                                                0,
                                                                                            )}
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                                <span>
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </span>
                                                                            </div>

                                                                            <li
                                                                                className="menu-open"
                                                                                onClick={(
                                                                                    e: React.MouseEvent<HTMLLIElement>,
                                                                                ) =>
                                                                                    handleClickSubMenuBrand(
                                                                                        e,
                                                                                        item,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <BsThreeDots color="#A09FA5" />
                                                                            </li>

                                                                            <Menu
                                                                                id="basic-menu-workspace"
                                                                                anchorEl={
                                                                                    anchorElSubMenuBrand
                                                                                }
                                                                                open={
                                                                                    openSubMenuBrand
                                                                                }
                                                                                onClose={
                                                                                    handleCloseSubMenuBrand
                                                                                }
                                                                                MenuListProps={{
                                                                                    'aria-labelledby':
                                                                                        'basic-button',
                                                                                }}
                                                                                sx={{
                                                                                    width: '100%',
                                                                                    '& > .MuiPaper-root':
                                                                                    {
                                                                                        boxShadow:
                                                                                            '8px 16px 32px rgba(113, 128, 150, 0.08);',
                                                                                        borderRadius:
                                                                                            '12px',
                                                                                        background:
                                                                                            darkmode
                                                                                                ? '#1d1d1f'
                                                                                                : '',
                                                                                    },
                                                                                }}
                                                                                anchorOrigin={{
                                                                                    vertical: 40,
                                                                                    horizontal:
                                                                                        'left',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical:
                                                                                        'top',
                                                                                    horizontal:
                                                                                        'left',
                                                                                }}
                                                                            >
                                                                                <Paper
                                                                                    sx={{
                                                                                        width: 202,
                                                                                        boxShadow: 0,
                                                                                        padding:
                                                                                            '0px 16px',
                                                                                        background:
                                                                                            darkmode
                                                                                                ? '#1d1d1f'
                                                                                                : '',
                                                                                    }}
                                                                                >
                                                                                    <MenuList
                                                                                        sx={{
                                                                                            padding: 0,
                                                                                            boxShadow: 0,
                                                                                            background:
                                                                                                darkmode
                                                                                                    ? '#1d1d1f'
                                                                                                    : '',
                                                                                        }}
                                                                                        disablePadding
                                                                                        dense
                                                                                    >
                                                                                        <S.MuiMenuHeader>
                                                                                            <ul className="list-submenu-options">
                                                                                                <li
                                                                                                    onClick={() => {
                                                                                                        handleCloseSubMenuBrand();
                                                                                                        handleChangeBrand(
                                                                                                            selectedAnchorBrand as Brands,
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <AiOutlineCheckCircle
                                                                                                        size={
                                                                                                            18
                                                                                                        }
                                                                                                    />
                                                                                                    {routeTranslate(
                                                                                                        intl,
                                                                                                        'brand.button.default',
                                                                                                    )}
                                                                                                </li>
                                                                                                <li
                                                                                                    onClick={() => {
                                                                                                        handleNewBrand();
                                                                                                        setEdintingBrand(
                                                                                                            true,
                                                                                                        );
                                                                                                        handleCloseSubMenuBrand();
                                                                                                    }}
                                                                                                >
                                                                                                    <FiEdit3
                                                                                                        size={
                                                                                                            18
                                                                                                        }
                                                                                                    />
                                                                                                    {routeTranslate(
                                                                                                        intl,
                                                                                                        'home.documents.edit',
                                                                                                    )}
                                                                                                </li>
                                                                                                <li
                                                                                                    onClick={
                                                                                                        loadingDelete
                                                                                                            ? undefined
                                                                                                            : handleDeleteBrand
                                                                                                    }
                                                                                                >
                                                                                                    {loadingDelete ? (
                                                                                                        <div className="loading-spinner">
                                                                                                            <CircularProgress
                                                                                                                size={
                                                                                                                    24
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <BiTrash
                                                                                                                size={
                                                                                                                    18
                                                                                                                }
                                                                                                            />
                                                                                                            {routeTranslate(
                                                                                                                intl,
                                                                                                                'home.documents.delete',
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </li>
                                                                                            </ul>
                                                                                        </S.MuiMenuHeader>
                                                                                    </MenuList>
                                                                                </Paper>
                                                                            </Menu>
                                                                        </li>
                                                                    );
                                                                },
                                                            )
                                                    ) : (
                                                        <li>
                                                            <span>
                                                                {routeTranslate(
                                                                    intl,
                                                                    'navmenu.project.noResults',
                                                                )}
                                                            </span>
                                                        </li>
                                                    )}
                                                </div>
                                                {!reachedTotalBrands && (
                                                    <div>
                                                        <li
                                                            onClick={
                                                                handleNewBrand
                                                            }
                                                        >
                                                            <span className="bold">
                                                                {routeTranslate(
                                                                    intl,
                                                                    'navmenu.brand.new',
                                                                )}
                                                            </span>
                                                        </li>
                                                    </div>
                                                )}
                                            </ul>
                                        </S.MuiMenuHeader>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    )}
                    <ul>
                        <NavItem
                            handleCloseMenu={handleCloseMenu}
                            to={agentRoutes('home')}
                            title="Home"
                            activePaths={[agentRoutes('home', { param: agent ? agent.id : '0' })]}
                            icon={<IconBolt />}
                        />

                        <NavItem
                            handleCloseMenu={handleCloseMenu}
                            to={agentRoutes('home', 'inbox')}
                            title="Inbox"
                            disable
                            icon={<IconInbox />}
                        />

                        <NavItem
                            handleCloseMenu={handleCloseMenu}
                            to={agentRoutes('home', 'insights')}
                            title="Insights"
                            icon={<IconChartPie />}
                        />

                        <NavItem
                            handleCloseMenu={handleCloseMenu}
                            to={routes(
                                'agent.home',
                                'agent.brand-center',
                                'brand-center.knowledge',
                            )}
                            title="Brand Center"
                            icon={<IconBook />}
                        />
                    </ul>
                </div>
            </div>
            <ModalNewbBrand
                active={modalNewBrand}
                handleClose={() => closeModalNewBrand()}
                callBack={handleGetBrands}
                editing={edintingBrand}
                item_editing={selectedAnchorBrand}
            />
        </S.ContainerMenu>
    );
};
