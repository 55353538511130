import React from 'react';
import { Step3and4Container, StyledSlider } from './../styles';
import { Button } from 'app/presentation/components/common';
import { HiArrowNarrowRight } from 'react-icons/hi';

interface Step3Props {
    setVoiceToneFormality: React.Dispatch<React.SetStateAction<number>>;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const Step3: React.FC<Step3Props> = (props) => {

    const marks = [
        { value: 0, label: 'Baixo' },
        { value: 100, label: 'Alto' },
    ];

    return (
        <Step3and4Container>
            <h1>Qual o nível de formalidade da sua marca?</h1>

            <span>
                O nível de formalidade pode deixar o tom mais formal e
                rebuscado, ou mais familiar e casual
            </span>

            <StyledSlider
                marks={marks}
                defaultValue={50}
                step={50}
                onChange={(e, num) => {
                    props.setVoiceToneFormality(num as number);
                }}
             />

            <div className="buttons">
                <Button
                    className="buttons-child left"
                    onClick={() => props.setCurrentStep(prev => prev - 1)}
                >
                    <p> Anterior </p>
                </Button>

                <Button
                    className="buttons-child right"
                    onClick={() => props.setCurrentStep(prev => prev + 1)}
                    icon={<HiArrowNarrowRight />}
                >
                    <p> Continuar </p>
                </Button>
            </div>
        </Step3and4Container>
    )
}