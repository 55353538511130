import { Page, client } from '../client';
import {
    GetEligibleInformationResponse,
    GetEnabledTrainingsInformationResponse,
    GetTrainingStatusReponse,
    GetUseMemoriesResponse,
    Training,
    TrainingResourceTypes,
    UpdateTrainingEnabledRequest,
    UpdateUseMemoriesRequest,
} from './interfaces';

export class TrainingService {
    private readonly trainingClient = client
        .addPath('/agents')
        .addBrandHeaders();

    async list(
        agentID: number,
        page = 1,
        limit = 5,
        title = '',
    ): Promise<Page<Training>> {
        const pagination = await this.trainingClient.getData<Page<Training>>({
            pathParams: [agentID, 'trainings'],
            queryParams: { page, limit, title },
        });

        const { data } = pagination;
        for (const training of data) {
            training.getResourceID = () => {
                switch (training.resource_type) {
                    case TrainingResourceTypes.BrandCenter:
                        return training.brand_memory_content!.id;
                    case TrainingResourceTypes.URLContent:
                        return training.url_content!.id;
                    case TrainingResourceTypes.Excerpt:
                        return training.excerpt!.id;
                    default:
                        return 0;
                }
            };

            training.getLastUpdatedAt = () => {
                switch (training.resource_type) {
                    case TrainingResourceTypes.BrandCenter:
                        return training.brand_memory_content!.updated_at;
                    default:
                        return training.updated_at;
                }
            };
        }

        return pagination;
    }

    async updateUseMemories(agentID: number, use: boolean): Promise<void> {
        return this.trainingClient.putData<void, UpdateUseMemoriesRequest>(
            {
                use,
            },
            {
                pathParams: [agentID, 'trainings', 'use-memories'],
            },
        );
    }

    async getUseMemories(agentID: number): Promise<GetUseMemoriesResponse> {
        return this.trainingClient.getData<GetUseMemoriesResponse>({
            pathParams: [agentID, 'trainings', 'use-memories'],
        });
    }

    async getEligibleInformation(
        agentID: number,
    ): Promise<GetEligibleInformationResponse> {
        return this.trainingClient.getData<GetEligibleInformationResponse>({
            pathParams: [agentID, 'trainings', 'eligible-information'],
        });
    }

    async getEnabledTrainingsInformation(
        agentID: number,
    ): Promise<GetEnabledTrainingsInformationResponse> {
        return this.trainingClient.getData<GetEnabledTrainingsInformationResponse>(
            {
                pathParams: [agentID, 'trainings', 'enabled-information'],
            },
        );
    }

    async getTrainingStatus(
        agentID: number,
    ): Promise<GetTrainingStatusReponse> {
        return this.trainingClient.getData<GetTrainingStatusReponse>({
            pathParams: [agentID, 'trainings', 'status'],
        });
    }

    async updateTrainingEnabled(
        agentID: number,
        resourceID: number,
        resourceType: TrainingResourceTypes,
        enabled: boolean,
    ): Promise<void> {
        return this.trainingClient.putData<void, UpdateTrainingEnabledRequest>(
            {
                resource_id: resourceID,
                resource_type: resourceType,
                enabled,
            },
            {
                pathParams: [agentID, 'trainings'],
            },
        );
    }
}
