import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import DateRangePicker from '../DateRangePicker';

export interface DateInterval {
    start: Date,
    end: Date,
}

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    onDateIntervalChange: (date: DateInterval) => void,
}

export const ModalDateInterval: React.FC<ModalProps> = ({
    active,
    handleClose,
    onDateIntervalChange,
}) => {
    const [startDate, setStartDate] = React.useState<Date | undefined>();
    const [endDate, setEndDate] = React.useState<Date | undefined>();

    React.useEffect(()=>{
        if (!active) {
            return
        }
        setStartDate(undefined);
        setEndDate(undefined)
    },[active])

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget !== e.target) {
            return
        }
        if (startDate) {
            onDateIntervalChange({
                start: startDate,
                end: endDate ? endDate : startDate,
            })
        }
        handleClose();
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal onClick={handleCloseContainer}>
                <div className="content-modal">
                    {active && 
                        <DateRangePicker setEndDate={setEndDate} setStartDate={setStartDate} />
                    }
                </div>
            </ContainerModal>
        </Fade>
    );
};
