import React from 'react';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import Tooltip from '@mui/material/Tooltip';

type CopyProps = {
    hideTooltip?: boolean;
    darkmode?: boolean;
    color?: string;
};

export const Copy: React.FC<CopyProps> = ({
    hideTooltip = true,
    darkmode = false,
    color = '#A0AEC0',
}) => {
    const intl = useIntl();

    return (
        <Tooltip
            title={routeTranslate(intl, 'tooltip.copy')}
            disableHoverListener={hideTooltip}
            placement="top"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15.0003 6.66669H8.33366C7.41318 6.66669 6.66699 7.41288 6.66699 8.33335V15C6.66699 15.9205 7.41318 16.6667 8.33366 16.6667H15.0003C15.9208 16.6667 16.667 15.9205 16.667 15V8.33335C16.667 7.41288 15.9208 6.66669 15.0003 6.66669Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.3335 6.66668V5.00001C13.3335 4.55798 13.1579 4.13406 12.8453 3.8215C12.5328 3.50894 12.1089 3.33334 11.6668 3.33334H5.00016C4.55814 3.33334 4.13421 3.50894 3.82165 3.8215C3.50909 4.13406 3.3335 4.55798 3.3335 5.00001V11.6667C3.3335 12.1087 3.50909 12.5326 3.82165 12.8452C4.13421 13.1577 4.55814 13.3333 5.00016 13.3333H6.66683"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Tooltip>
    );
};
