import styled from 'styled-components';

export const AudioTextNew = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fafafa;

    .steps {
        display: flex;

        justify-content: space-between;
        div {
            display: flex;
            @media (max-width: 64em) {
                flex-direction: column;
            }
            flex: 1;
            p {
                margin-right: 20px;
                color: #7e7e73;
                font-weight: 600;
            }
            .active {
                font-weight: 700;
                color: #000;
            }

            .done {
                color: #16a34a;
                font-weight: 700;
            }
        }
        button {
            max-width: 140px;
            font-size: 0.875rem;
            height: 48px;
            svg {
                margin-left: 0 !important;
            }
        }
    }

    &.darkAudio {
        background-color: #000;

        .steps {
            div {
                p {
                    color: #7e7e73;
                }
                .active {
                    color: #fff;
                }

                .done {
                    color: #d0ff61;
                }
            }
        }
    }
`;

export const Send = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 173px;

    #audioUpload {
        display: none;
    }

    .bigButton {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 12px;
        background: #fff;
        border: 1px solid #cbd5e0;
        width: 525px;
        @media (max-width: 64em) {
            width: 100%;
        }
        height: 110px;
        padding: 16px;
        margin-bottom: 20px;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            @media (max-width: 64em) {
                min-width: 48px;
                min-height: 48px;
            }
            border-radius: 8px;
            background: #f7fafc;
        }
        span {
            font-size: 0.875rem;
            color: #a09fa5;
        }

        .textArea {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            p {
                margin-bottom: 8px;
                color: #000;
                font-weight: 700;
                font-size: 1.125rem;
            }

            input {
                height: 36px;
                border: none;
                color: #1a202c;
                font-weight: 500;
                width: 100%;
                border-radius: 12px;
                background: transparent;
                width: 283px;

                :focus {
                    border: none;
                }

                ::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #a09fa5;
                    opacity: 1; /* Firefox */
                    font-size: 0.875rem;
                }

                :-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #a0aec0;
                }

                ::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #a0aec0;
                }
            }
        }
    }

    .bigDashed {
        border-style: dashed;
    }

    .activeBig {
        background: #f6ffdf;
        border-color: #1a202c;
        .icon {
            background: #d0ff61;
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 525px;
        @media (max-width: 64em) {
            width: 100%;
        }

        .cleanField {
            border: 1px solid #e2e8f0;
            background: #f7fafc;
            height: 34px;
            width: 179px;
            font-weight: 600;
            font-size: 0.875rem;
            cursor: pointer;
        }

        .nextButton {
            width: 140px;
            height: 48px;
            font-size: 0.875rem;
            font-weight: 700;
        }
    }

    &.darkSend {
        background-color: #000;

        .bigButton {
            cursor: pointer;
            background: #1d1d1f;

            .icon {
                background: #1d1d1f;
            }
            span {
                font-size: 0.875rem;
                color: #a09fa5;
            }

            .textArea {
                p {
                    color: #fff;
                }

                input {
                    color: #fff;
                }
            }
        }

        .activeBig {
            background: #d0ff61;
            .textArea {
                p {
                    color: #000;
                }

                input {
                    color: #000;
                    border-color: #000;
                }
            }
            .icon {
                background: #1d1d1f;
            }
        }
    }
`;

export const Model = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    .containerModels {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @media (max-width: 64em) {
            grid-template-columns: 1fr;
            padding-bottom: 20px;
        }
        row-gap: 24px;
        column-gap: 20px;

        .itemContainer {
            flex: 1;
            height: 190px;
            position: relative;
            .languageSelector {
                position: absolute;
                top: 25px;
                right: 22px;
                width: 138px;
                height: 34px;
            }
        }

        .itemModels {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px;
            border-radius: 12px;
            background-color: #fff;
            border: 1px solid #e2e8f0;
            flex: 1;
            height: 190px;

            .iconModel {
                width: 42px;
                height: 42px;
                border-radius: 9999px;
                background: #fafafa;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(0, 0, 0, 0.1);
            }

            p {
                color: #000;
                font-weight: 700;
                margin: 13px 0;
            }

            span {
                font-size: 0.875rem;
                color: #a09fa5;
                font-size: 500;
            }
        }

        .activeItem {
            border-color: #000;
            background: #f6ffdf;
        }
    }

    &.darkModel {
        background-color: #000;

        .containerModels {
            .itemModels {
                background-color: #000;

                p {
                    color: #fff;
                }

                span {
                    color: #a09fa5;
                }

                .iconModel {
                    background: #1d1d1f;
                }
            }

            .activeItem {
                border-color: #000;
                background: #d0ff61;

                p {
                    color: #000;
                }

                .iconModel {
                    background: #000;
                }

                span {
                    color: #000;
                }
            }
        }
    }
`;

export const Final = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
    margin-top: 72px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 72px;

    .alertContainer {
        border-radius: 6px;
        display: flex;
        align-items: center;
        background: #fbeeb6;
        flex: 1;
        height: 52px;
        padding: 6px 12px;
        position: relative;
        margin-bottom: 40px;

        p {
            margin-left: 8px;
            color: #000;
            font-size: 0.875rem;
            font-weight: 500;
        }

        button {
            border: none;
            background: transparent;
            position: absolute;
            right: 12px;
            top: 16px;
            cursor: pointer;
        }
    }

    .contentText {
        width: 100%;
        height: 100%;
        background-color: transparent;
        display: flex;
        margin-bottom: 60px;

        @media (max-width: 64em) {
            flex-direction: column;
        }
    }

    .listContainer {
        display: flex;
        flex-direction: column;
        margin-right: 25px;
        @media (max-width: 64em) {
            margin-right: 0;
        }

        .listItem {
            display: flex;
            width: 270px;
            @media (max-width: 64em) {
                width: 100%;
                flex: 1;
            }
            min-height: 52px;
            max-height: fit-content;
            padding: 12px;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #e2e8f0;
            cursor: pointer;
            position: relative;

            .icon {
                .checked-icon {
                    width: 18px;
                    height: 18px;
                    background: #d0ff61;
                    border: 1px solid #000000;
                    color: #000;
                    border-radius: 9999px;
                }
            }

            .text {
                margin-left: 8px;
                display: flex;
                flex-direction: column;
                p {
                    font-size: 0.875rem;
                    color: #000;
                    font-weight: 600;
                    margin-bottom: 4px;
                }

                span {
                    color: #3a3a3a;
                    font-size: 0.75rem;
                }
            }

            .loading {
                position: absolute;
                right: 12px;
                top: 12px;
            }
        }
        .doneItem {
            background: #fff;
        }

        .selectedItem {
            border-color: #000;
            background: #f6ffdf;
            .icon {
                align-self: start;
            }
        }

        .itemPending {
            opacity: 0.5;
        }
    }

    .resultTextContainer {
        flex: 1;
        border-radius: 8px;
        border: 1px solid #e2e8f0;
        background: #fff;
        padding: 25px 20px;
        height: 602px;
        overflow-y: scroll;
        @media (max-width: 64em) {
            margin-top: 12px;
        }

        p {
            color: #000;
            font-weight: 500;
        }
        .paragraph_summarization {
            .summary {
                margin: 14px 0 14px 40px;
                border-left: 2px solid #22c55e;
                padding-left: 15px;
                span {
                    font-weight: 500;
                    color: #000;
                }
            }
        }

        .keyword_detection {
            .keywords {
                margin: 14px 0 14px 40px;
                border-left: 2px solid #22c55e;
                padding-left: 15px;
                span {
                    font-weight: 500;
                    color: #000;
                }
            }
        }

        .sentiment_analysis {
            .sentiment {
                margin: 14px 0 14px 40px;
                border-left: 2px solid #22c55e;
                padding-left: 15px;
                span {
                    font-weight: 500;
                    color: #000;
                }
            }
        }

        .speakers_detection {
            .Speaker1 {
                color: #194bfb;
            }
            .Speaker2 {
                color: #058434;
            }
            .Speaker3 {
                color: #ff4747;
            }

            .Speaker4 {
                color: #7e57c2;
            }
            .Speaker5 {
                color: #00bcd4;
            }
            .Speaker6 {
                color: #009688;
            }
            .Speaker7 {
                color: #f44336;
            }
            .Speaker8 {
                color: #673ab7;
            }
            .Speaker9 {
                color: #4caf50;
            }
            .Speaker10 {
                color: #ff5722;
            }
            .Speaker11 {
                color: #3f51b5;
            }
            .Speaker12 {
                color: #e91e63;
            }
            .Speaker13 {
                color: #795548;
            }
            .Speaker14 {
                color: #9c27b0;
            }
            .Speaker15 {
                color: #2196f3;
            }
        }

        .important_sentences {
            .sentences {
                margin: 14px 0 14px 40px;
                border-left: 2px solid #22c55e;
                padding-left: 15px;
                span {
                    font-weight: 500;
                    color: #000;
                }
            }
        }

        .translation {
            display: flex;

            .original {
                border-right: 4px solid #e2e8f0;
                flex: 1;
                padding-right: 14px;
            }

            .translated {
                flex: 1;
                margin-left: 14px;
            }
        }
    }

    &.darkFinal {
        background-color: #000;
        .listContainer {
            .listItem {
                background: #000;
                .text {
                    p {
                        color: #fff;
                    }

                    span {
                        color: #3a3a3a;
                    }
                }
            }

            .selectedItem {
                background: #d0ff61;

                .text {
                    p {
                        color: #000;
                    }

                    span {
                        color: #3a3a3a;
                    }
                }
            }
        }

        .resultTextContainer {
            border-color: #000;
            background: #1d1d1f;

            p {
                color: #fff;
                font-weight: 500;
            }
            .paragraph_summarization {
                .summary {
                    span {
                        color: #fff;
                    }
                }
            }

            .keyword_detection {
                .keywords {
                    span {
                        color: #fff;
                    }
                }
            }

            .sentiment_analysis {
                .sentiment {
                    span {
                        color: #fff;
                    }
                }
            }

            .important_sentences {
                .sentences {
                    span {
                        color: #fff;
                    }
                }
            }
        }
    }
`;
