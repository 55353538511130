export const getNameLetters = (name: string) => {
    const split = name.split(' ');

    if (split.length === 1) {
        return Array.from(split[0])[0];
    } else {
        const firstLetter = Array.from(split[0])[0];
        const secondLetter = Array.from(split[1])[0];

        return `${firstLetter}${secondLetter}`;
    }
};

export const replaceTitlesWithContent = (
    str: string,
    memories: Array<{ title: string; content: string }>,
): string => {
    let result = str;

    memories.forEach(({ title, content }) => {
        // We need to escape special characters in the title because it may contain regex special characters
        const escapedTitle = title.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        const regex = new RegExp(escapedTitle, 'g');
        result = result.replace(regex, content);
    });

    return result;
};
