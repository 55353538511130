import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { routeTranslate } from '../utils/intl';
import Reason from '../pages/onboarding/reason';
import YourBusiness from '../pages/onboarding/your-business';
import Plans from '../pages/onboarding/plans';
import {
    Plans as IPlans,
    IUsecasesInterests,
} from '../interfaces/plans.interface';
import { PlansService } from 'services/plans';
import { OnboardingService } from 'services/onboarding';
import { BrandServices } from 'services/brand';
import Loading from 'app/presentation/components/common/Loading';
import Interests from 'pages/onboarding/interests';

const plansService = new PlansService();
const onboardingService = new OnboardingService();
const bs = new BrandServices();

const AnswersRoutes = () => {
    const intl = useIntl();
    const [plans, setPlans] = React.useState<IPlans[]>();
    const [usecases, setUsecases] = React.useState<IUsecasesInterests[]>();
    const [interests, setInterests] = React.useState<IUsecasesInterests[]>();
    const [loading, setLoading] = React.useState(true);
    const [selectedUsecase, setSelectedUsecase] = React.useState(0);
    const [selectedInterests, setSelectedInterests] = React.useState<number[]>([]);

    const callApi = async () => {
        await plansService.getAll().then(async data => {
            setPlans(data);
        }).catch(error => {
            console.log(error);
        });

        await onboardingService.getInterests().then((data: IUsecasesInterests[]) => {
            const sortedData = data.sort((a, b) => a.ordering - b.ordering);
            setInterests(sortedData);
        }).catch(error => {
            console.log(error);
        });

        await onboardingService.getUsecases().then((data: IUsecasesInterests[]) => {
            const sortedData = data.sort((a, b) => a.ordering - b.ordering);
            setUsecases(sortedData);
        }).catch(error => {
            console.log(error);
        });

        setLoading(false);
    };

    React.useEffect(() => { callApi(); }, []);

    const createOnboarding = async (id: number) => {
        if (selectedUsecase === 0) return;
        if (selectedInterests.length < 1) return;

        const data = {
            interests: selectedInterests,
            usecase: selectedUsecase,
        };

        await bs.createOnboarding(id, data);
    };

    return (
        <Routes>
            <Route
                path={routeTranslate(intl, 'route.reason')}
                element={loading ?
                    (<Loading open={true} />) :
                    (<Reason useCaseList={usecases} selected={selectedUsecase} setSelected={setSelectedUsecase} />)
                }
            />

            {/*<Route
                path={routeTranslate(intl, 'route.interests')}
                element={loading ?
                    (<Loading open={true} />) :
                    (<Interests interestsList={interests} selected={selectedInterests} setSelected={setSelectedInterests} />)
                }
            />*/}
            <Route
                path={routeTranslate(intl, 'route.yourBusiness')}
                element={<YourBusiness createOnboarding={createOnboarding} />}
            />
            <Route
                path={routeTranslate(intl, 'route.plans')}
                element={loading ?
                    (<Loading open={true} />) :
                    (<Plans plans={plans as IPlans[]} />)
                }
            />
        </Routes>
    );
};

export default AnswersRoutes;
