import styled from 'styled-components';

export const ContainerSignUp = styled.div`
    display: flex;
    align-items: center;
    min-height: 100vh;

    > div {
        padding: 0;
    }

    #form-login {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: calc(100vh - 80px);
    }

    @media (min-width: 768px) {
        display: block;

        .logo {
            svg {
                display: none;
            }
        }
    }

    .center {
        text-align: center;

        h1 {
            @media (min-width: 768px) {
                margin-bottom: 8px;
                font-size: 2em;
            }
        }
    }

    .tagline {
        color: #718096;
    }

    #call-user {
        text-align: center;

        &.desktop {
            display: none;
            padding-top: 32px;
        }

        @media (min-width: 768px) {
            &.desktop {
                display: block;
            }

            &.mobile {
                display: none;
            }
        }
    }

    #footer {
        display: none;

        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;

            a {
                color: #718096;
                font-weight: 600;
            }
        }
    }

    &.containerSingupDark {
        #form-login {
            div {
                p {
                    color: #cccccc;
                }
            }
        }

        input {
            background: #1d1d1f !important;
            border-color: #1d1d1f !important;
            color: #fff;

            &:focus {
                border: 1px solid #fff !important;
            }
        }

        #footer {
            a {
                color: #cccccc;
            }
        }
    }
`;

interface LoginAlternativeProps {
    text: string;
}

export const LoginAlternatives = styled.div<LoginAlternativeProps>`
    display: flex;
    justify-content: center;

    margin-top: 29px;

    button {
        cursor: pointer;

        border: 1px solid #e2e8f0;
        border-radius: 12px;
        width: 100%;
        height: 56px;
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 768px) {
            max-width: 427px;
            transition: 0.3s;

            &:hover {
                background-color: #f7fafc;
            }
        }

        span {
            display: inline-block;
            margin-left: 10px;

            @media (min-width: 768px) {
                &::before {
                    content: '${props => props.text}';
                }
            }
        }
    }

    &.alternativesDark {
        button {
            border-color: #3a3a3a;
            background: #000;
            color: #fff;

            &:hover {
                filter: brightness(1.5);
            }
        }
    }
`;

export const OrRegister = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 48px 0;

    hr {
        height: 1px;
        background-color: #e2e8f0;
        border: 0;
        display: block;
        width: 100%;
    }

    span {
        position: absolute;
        display: inline-block;
        padding: 5px 12px;
        color: #718096;
        font-size: 0.875em;
    }
`;

export const Form = styled.form`
    width: 100%;
    max-width: 427px;
    margin: 0 auto;

    input {
        margin-bottom: 16px;
    }

    #name {
        display: flex;
        justify-content: space-between;

        > div:first-of-type {
            margin-right: 16px;
        }
    }

    #password-field {
        position: relative;

        svg {
            position: absolute;
            right: 18px;
            top: 20px;
            cursor: pointer;
        }
    }

    #remember {
        display: flex;
        align-items: center;
    }

    #options {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        #remember-text {
            font-weight: 500;
            font-size: 14px;
        }
    }
`;

export const Showcase = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    header {
        position: absolute;
        top: 31px;
        left: 32px;
        width: 100%;
        text-align: left;
    }

    header > svg {
        width: 185px;
    }

    &.showcaseDark {
        h1,
        p {
            color: #000;
        }
    }
`;

export const DesktopLayout = styled.div`
    margin: 0 auto;

    #background {
        display: none;
    }

    @media (min-width: 768px) {
        display: flex;

        > div {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        #background {
            position: relative;

            img {
                width: 350px;
            }

            > div {
                max-width: 100%;
                padding: 0 62px;
            }

            background-color: #d0ff61;
            width: calc(100% - 48px);
            height: calc(100% - 48px);
            padding: 0 20px;

            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            h1 {
                margin-top: 69px;
                margin-bottom: 20px;
                font-size: 2.5em;
                line-height: 44px;
            }

            p {
                font-size: 0.875em;
                line-height: 21px;
                letter-spacing: 0.2px;
            }
        }
    }
`;
