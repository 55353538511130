import styled from 'styled-components';

export const WorkingHoursContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: fit-content;
    .working-hours-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: fit-content;
        .working-hours {
            display: flex;
            gap: 12px;
            flex-direction: column;
            justify-content: center;

            @media (min-width: 64em) {
                flex-direction: row;
                justify-content: start;
                align-items: center;
            }

            .hours-container {
                display: flex;
                gap: 12px;
                align-items: center;

                .delete-button {
                    width: 18px;
                    height: 18px;
                    margin-left: 6px;
                    background-color: transparent;
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
    .add-button {
        width: fit-content;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 21px;
        font-size: 14px;
        padding: 0px;
    }
`;
