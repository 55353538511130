import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import { AuthRoutes, PublicRoutes } from './routes';
import BaseLayout from './app/presentation/layout/base-layout';
import AppProviders from './appProviders';

function App() {
    return (
        <div className="App">
            <AppProviders>
                <BaseLayout>
                    <BrowserRouter>
                        <AuthRoutes />
                        <Routes>
                            <Route
                                path=":language/*"
                                element={<PublicRoutes />}
                            />
                        </Routes>
                    </BrowserRouter>
                </BaseLayout>
            </AppProviders>
        </div>
    );
}

export default App;
