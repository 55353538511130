import React from 'react';

export const Webgeo: React.FC = () => (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.66675 13.8332L8.33341 8.83317L13.3334 7.1665L11.6667 12.1665L6.66675 13.8332Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.5 10.5C2.5 11.4849 2.69399 12.4602 3.0709 13.3701C3.44781 14.2801 4.00026 15.1069 4.6967 15.8033C5.39314 16.4997 6.21993 17.0522 7.12987 17.4291C8.03982 17.806 9.01509 18 10 18C10.9849 18 11.9602 17.806 12.8701 17.4291C13.7801 17.0522 14.6069 16.4997 15.3033 15.8033C15.9997 15.1069 16.5522 14.2801 16.9291 13.3701C17.306 12.4602 17.5 11.4849 17.5 10.5C17.5 9.51509 17.306 8.53982 16.9291 7.62987C16.5522 6.71993 15.9997 5.89314 15.3033 5.1967C14.6069 4.50026 13.7801 3.94781 12.8701 3.5709C11.9602 3.19399 10.9849 3 10 3C9.01509 3 8.03982 3.19399 7.12987 3.5709C6.21993 3.94781 5.39314 4.50026 4.6967 5.1967C4.00026 5.89314 3.44781 6.71993 3.0709 7.62987C2.69399 8.53982 2.5 9.51509 2.5 10.5Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
