import React from 'react';
import * as S from './styles';
import {
    IconTrash,
    IconGripVertical,
    IconUser,
    IconMail,
    IconPhone,
} from '@tabler/icons-react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from '@hello-pangea/dnd';
import { FormFieldInfo } from '../FormField';
import { Select } from '../Select';
import { Button } from '../Button';
import { FormField } from 'services/agent/interfaces';
import useText from 'app/presentation/hooks/useText';

interface Props {
    fields: FormField[];
    setFields: (fields: FormField[]) => void;
}

interface FieldOption {
    name: string;
    dataType: string;
}

const fieldOptions: FieldOption[] = [
    { name: 'name', dataType: 'text' },
    { name: 'email', dataType: 'email' },
    { name: 'phone', dataType: 'number' },
];

export const AgentFormContainer: React.FC<Props> = ({ fields, setFields }) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.agent-form-container');

    const fieldLabels: Map<string, React.ReactNode> = new Map<
        string,
        React.ReactNode
    >([
        [
            'name',
            <div className="select-option">
                <IconUser></IconUser>
                <span>{t('name')}</span>
            </div>,
        ],
        [
            'email',
            <div className="select-option">
                <IconMail></IconMail>
                <span>{t('email')}</span>
            </div>,
        ],
        [
            'phone',
            <div className="select-option">
                <IconPhone></IconPhone>
                <span>{t('phone')}</span>
            </div>,
        ],
    ]);

    const reorder = (
        list: FormField[],
        startIndex: number,
        endIndex: number,
    ) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (e: DropResult) => {
        if (e.combine && fields != undefined) {
            const items = [...fields];
            items.splice(e.source.index, 1);
            setFields(items);
            return;
        }
        if (!e.destination) {
            return;
        }
        const items = reorder(fields, e.source.index, e.destination.index);
        setFields(items);
    };

    const handleDeleteQuestion = (field: FormField) => {
        if (fields != undefined) {
            const index = fields.indexOf(field);
            if (index !== -1) {
                const newData = fields.filter((el, i) => {
                    return i != index;
                });
                setFields(newData);
            }
        }
    };

    const addField = () => {
        const options = availableOptions();
        if (fields != undefined && options.length > 0) {
            const items = [...fields];
            items.push({
                data_type: options[0].dataType,
                name: options[0].name,
                order: items.length + 1,
            });
            setFields(items);
            return;
        }
    };

    const availableOptions = (): FieldOption[] => {
        return fieldOptions.filter(
            field => fields.find(f => f.name == field.name) == undefined,
        );
    };

    const onSelectChange = (index: number): ((value: string) => void) => {
        return (value: string) => {
            const fieldOption = fieldOptions.find(
                option => option.name == value,
            );
            if (fieldOption == undefined) {
                return;
            }
            const items = [...fields];
            items[index] = {
                order: index,
                name: fieldOption.name,
                data_type: fieldOption.dataType,
            };
            setFields(items);
        };
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <S.AgentFormContainer>
                <FormFieldInfo title={t('title')}></FormFieldInfo>
                {fields && fields.length > 0 && (
                    <Droppable
                        droppableId="agentFormDroppableZone"
                        direction="vertical"
                    >
                        {provided => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="form-fields-container"
                            >
                                {fields.map((item, index) => {
                                    return (
                                        <Draggable
                                            draggableId={String(index)}
                                            key={index}
                                            index={index}
                                            isDragDisabled={fields.length < 2}
                                        >
                                            {providedDraggable => (
                                                <div
                                                    ref={
                                                        providedDraggable.innerRef
                                                    }
                                                    className="form-field-container"
                                                    {...providedDraggable.draggableProps}
                                                >
                                                    {fields.length > 1 && (
                                                        <div
                                                            className="drag-icon"
                                                            {...providedDraggable.dragHandleProps}
                                                        >
                                                            <IconGripVertical></IconGripVertical>
                                                        </div>
                                                    )}
                                                    <Select
                                                        variant="white"
                                                        value={item.name}
                                                        onSelectChange={onSelectChange(
                                                            index,
                                                        )}
                                                        options={availableOptions()
                                                            .concat({
                                                                name: item.name,
                                                                dataType:
                                                                    item.data_type,
                                                            })
                                                            .map(option => {
                                                                return {
                                                                    value: option.name,
                                                                    node: fieldLabels.get(
                                                                        option.name,
                                                                    ),
                                                                };
                                                            })}
                                                        width="214px"
                                                    ></Select>
                                                    <Button
                                                        type="button"
                                                        variant="danger"
                                                        className="delete-button"
                                                        onClick={() =>
                                                            handleDeleteQuestion(
                                                                item,
                                                            )
                                                        }
                                                    >
                                                        <IconTrash></IconTrash>
                                                    </Button>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                )}
                {fieldOptions.length > fields.length && (
                    <Button
                        type="button"
                        variant="link"
                        className="add-button"
                        onClick={addField}
                    >
                        + {t('add-field')}
                    </Button>
                )}
            </S.AgentFormContainer>
        </DragDropContext>
    );
};
