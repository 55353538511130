import styled from 'styled-components';
import Phone from 'assets/images/jpg/phone-background.jpg';

export const WelcomeContainer = styled.div`
    width: 100%;
    max-width: 850px;
    margin: 0 auto;

    > h1 {
        margin: 24px 0;
    }

    #welcome {
        padding: 23px 20px;
        background-color: #d0ff61;
        border-radius: 12px;
        margin-bottom: 20px;
        background-image: url(${Phone});
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: bottom right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-image: none;

        h1 {
            margin-bottom: 13px;
            font-size: 1.1em;
        }

        p {
            font-size: 0.75em;
        }

        button {
            margin-top: 34px;
            margin-bottom: 20px;
            background-color: #ffffff;
            color: #1a202c;
            font-size: 0.75em;
            width: 155px;
            height: 36px;
            border-radius: 8px;
            padding: 0;
        }

        @media (min-width: 64em) {
            padding: 34px 24px 35px 24px;
            height: 280px;

            h1 {
                font-size: 1.875em;
            }

            p {
                font-size: 1.125em;
            }

            button {
                width: 200px;
                min-height: 55px;
                margin-top: 31px;
                margin-bottom: 0;
                font-size: 1em;
            }
        }
    }

    #friends {
        padding: 24px;
        border: 1px solid #edf2f7;
        border-radius: 8px;

        .label {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #d0ff61;
            color: #1a202c;
            border-radius: 8px;
            min-height: 40px;
            max-width: 246px;
            width: 100%;

            span {
                font-size: 0.75em;
                font-weight: 500;
            }
        }

        > h1 {
            margin: 12px 0;
        }

        > p {
            font-weight: 500;
            font-size: 0.75em;
            line-height: 150%;
            color: #718096;
        }

        button {
            margin-top: 30px;
            background-color: #ffffff;
            color: #1a202c;
            font-size: 0.75em;
            width: 139px;
            height: 48px;
            padding: 0 10px;
            border: 1px solid #e2e8f0;
            border-radius: 12px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            svg {
                margin-right: 11px;
                margin-left: 0px !important;
            }
        }

        @media (min-width: 64em) {
            > p {
                font-size: 1em;
            }

            .label {
                border-radius: 6px;
                max-width: 305px;

                span {
                    font-weight: 600;
                    font-size: 0.875em;
                }
            }
        }
    }

    &.dark {
        > h1 {
            color: #fff !important;
        }

        h1 {
            color: #000;
        }

        p {
            color: #000;
            font-weight: 400;
        }

        #friends {
            h1 {
                color: #fff;
            }

            background: #232b38;
            border: 1px solid #2a313c;
        }
    }
`;

export const FavoritesContainer = styled.div`
    width: 100%;
    max-width: 850px;
    margin: 30px auto 15px auto;

    border: 1px solid #edf2f7;
    border-radius: 16px;
    padding: 24px 24px 34px 24px;

    > h1 {
        margin-bottom: -24px;
    }

    .container-carousel {
        display: flex;
        flex-direction: column-reverse;
    }

    .carousel-button-group {
        align-self: flex-end;
        margin-bottom: 36px;

        button {
            border: none;
            background: none;
            cursor: pointer;
            margin-right: 10px;
        }
    }

    .items-carousel {
        padding: 18px 0;
        border: 1px solid #edf2f7;
        border-radius: 12px;
        margin-bottom: 16px;
    }

    .box {
        text-align: center;
        padding: 18px 0;
        margin-bottom: 16px;
        height: 106px;

        > h1 {
            font-size: 0.75em;
            margin-top: 12px;
        }
    }

    .box:last-of-type {
        margin-bottom: 0;
    }

    .icon {
        width: 40px;
        height: 40px;
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        border-radius: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        overflow: hidden;

        img {
            min-width: 100%;
            min-height: 100%;
        }
    }

    @media (min-width: 64em) {
        > h1 {
            margin-bottom: -27px;
        }

        .items-carousel {
            max-width: 187px;
            margin-right: 16px;
        }

        .icon {
            width: 50px;
            height: 51px;

            font-size: 27px;
        }

        .box {
            margin: 0 12px 0 0;
            width: 186.35px;
            height: 129px;

            > h1 {
                margin-top: 18px;
            }
        }

        .box:last-of-type {
            margin: 0;
        }
    }

    &.dark {
        background: #000000;
        border: 1px solid #3a3a3a;

        .box {
            background: transparent;
            border: 1px solid transparent;
        }

        ul {
            li {
                background: #1d1d1f;
                border-color: #1d1d1f;
            }
        }

        #table {
            th {
                color: red !important;
            }
        }
    }
`;

export const DocumentsContainer = styled.div`
    width: 100%;
    max-width: 850px;
    margin: 30px auto 25px auto;
    border: 1px solid #edf2f7;
    border-radius: 8px;
    padding: 29px 15px 0 15px;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            width: 98px;
            height: 34px;
            font-size: 0.75em;
            background: #fafafa;
            border-radius: 8px;
            font-weight: 600;
            padding: 0px;
        }

        h1 {
            margin-left: 9px;
            font-size: 1em;
        }
    }

    .box:first-of-type {
        border-top: 1px solid #edf2f7;
    }

    .box {
        margin-top: 28px;
        padding: 24px 0 36px 0;
        border-bottom: 2px solid #edf2f7;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .flex {
            flex: 1;
            margin: 0 24px;

            h1 {
                font-size: 0.875em;
                font-weight: 700;
            }
        }
    }

    .box:last-of-type {
        border-top: 0;
        border-bottom: 0;
        margin: 0;
    }

    .dots {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon {
        width: 40px;
        height: 40px;
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        border-radius: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name-table {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 26px;
        }

        span {
            font-weight: 600;
            font-size: 1em;
        }
    }

    .infos .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;

        strong {
            font-size: 0.875em;
            color: #718096;
            font-weight: 600;
        }

        span {
            color: #a09fa5;
            font-size: 0.875em;
            font-weight: 600;
        }
    }

    #table {
        display: none;
    }

    @media (min-width: 64em) {
        border-radius: 16px;

        .box {
            display: none;
        }

        #table {
            margin-top: 25px;
            margin-bottom: 18px;
            display: block;
            width: 100%;
        }

        header {
            h1 {
                font-size: 1.125em;
            }

            button {
                width: 107.29px;
                height: 34px;
            }
        }
    }

    &.dark {
        background: #000000;
        border: 1px solid #3a3a3a;

        tr {
            border-bottom: 1px solid #3a3a3a;

            td {
                color: #fff;
            }

            span {
                color: #fff;
            }
        }

        tbody {
            tr:last-of-type {
                border: 0;
            }
        }

        .box {
            background: #000;
        }
    }
`;
