
import Fade from '@mui/material/Fade';
import {
    IconLinkPlus,
    IconPlus,
    IconRotateClockwise,
    IconTrash,
    IconWorld,
} from '@tabler/icons-react';
import {
    Button,
    CardSelect,
    InfoMessage,
    Input,
} from 'app/presentation/components/agent';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';
import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Agent } from 'services/agent/interfaces';
import { URLContentsService } from 'services/url_contents';
import { ContainerModal } from './styles';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    item_editing?: Agent | undefined;
}

export const ModalUrlContent: React.FC<ModalProps> = ({
    active,
    handleClose,
    item_editing = undefined,
}) => {
    const { agent, notifyAgentTrainingChanged } = useAgent();
    const service = new URLContentsService();
    const { textGetter } = useText();
    const modalText = textGetter('agent.components.training.modals.new-url');

    const [step, setStep] = useState(0);
    const [usedExtraction, setUsedExtraction] = useState(true);
    const [urlExtract, setUrlExtract] = useState('');
    const [extractedUrls, setExtractedUrls] = useState<string[]>([]);

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            handleClose();
        }
    };

    const textSteps = [
        {
            title: modalText('title'),
            description: modalText('description'),
            firstButton: modalText('buttonCancel'),
            secondButton: modalText('buttonContinue'),
        },
        {
            title: modalText('extract.title'),
            description: '',
            firstButton: modalText('extract.buttonCancel'),
            secondButton: modalText('extract.buttonContinue'),
        },
        {
            title: modalText('add.title'),
            description: '',
            firstButton: modalText('extract.third.buttonCancel'),
            secondButton: modalText('extract.third.buttonContinue'),
        },
    ];

    const getAboutText = (): Record<string, string> => {
        if (usedExtraction) {
            return {
                firstLine: modalText('extract.second.firstLine'),
                secondLine: modalText('extract.second.secondLine'),
                thirdLineFirst: modalText('extract.second.thirdLineFirst'),
                thirdLineSecond: modalText('extract.second.thirdLineSecond'),
            };
        }

        return {
            firstLine: modalText('specific.second.firstLine'),
            secondLine: modalText('specific.second.secondLine'),
            thirdLineFirst: modalText('specific.second.thirdLineFirst'),
            thirdLineSecond: '',
        };
    };

    const addEmptyExtractedUrl = () => {
        setExtractedUrls([...extractedUrls, '']);
    };

    const removeNthExtractedUrl = (index: number) => {
        const newExtractedUrls = extractedUrls.filter((_, i) => i !== index);
        setExtractedUrls(newExtractedUrls);
    };

    const onChangeNthExtractedUrl = (index: number, value: string) => {
        const newExtractedUrls = extractedUrls.map((url, i) =>
            i === index ? value : url,
        );
        setExtractedUrls(newExtractedUrls);
    };

    const steps = [
        <>
            <CardSelect
                variant="primary"
                height="195px"
                padding="25px 20px"
                align="center"
                iconBG={{
                    height: '42px',
                    width: '42px',
                    radius: '6px',
                }}
                value={usedExtraction ? '2' : '3'}
                iconGap="12px"
                textGap="12px"
                cardGap="15px"
                cards={[
                    {
                        value: '2',
                        icon: <IconWorld color="#000"></IconWorld>,
                        iconBackgroundColor: '#00000000',
                        title: modalText('allUrls.title'),
                        text: modalText('allUrls.description'),
                        onClick: () => setUsedExtraction(true),
                    },
                    {
                        value: '3',
                        icon: <IconLinkPlus color="#000"></IconLinkPlus>,
                        iconBackgroundColor: '#00000000',
                        title: modalText('specificUrls.title'),
                        text: modalText('specificUrls.description'),
                        onClick: () => {
                            setUsedExtraction(false);
                            addEmptyExtractedUrl();
                        },
                    },
                ]}
            ></CardSelect>
        </>,

        <>
            <div className="step-2-first">
                {getAboutText().firstLine}
                <br />
                <br />
                <span className="step-2-third">
                    <p className="step-2-second">{getAboutText().secondLine}</p>
                    <ul>
                        <li>
                            <strong>{getAboutText().thirdLineFirst}</strong>
                            {getAboutText().thirdLineSecond}
                        </li>
                    </ul>
                    <InfoMessage
                        type="yellow"
                        text={modalText('extract.second.disclaimer')}
                    />
                    <br />

                    <Input
                        placeholder="https://www.jarbas.ai"
                        value={urlExtract}
                        onChange={e => setUrlExtract(e.target.value)}
                    />
                </span>
            </div>
        </>,

        <>
            <div className="step-2-first">
                {getAboutText().firstLine}
                <br />
                <br />
                <span className="step-2-third">
                    <p className="step-2-second">{getAboutText().secondLine}</p>
                    <ul>
                        <li>
                            <strong>{getAboutText().thirdLineFirst}</strong>
                            {getAboutText().thirdLineSecond}
                        </li>
                    </ul>
                    <InfoMessage
                        type="yellow"
                        text={modalText('extract.second.disclaimer')}
                    />
                    <br />
                    {extractedUrls.map((url, index) => (
                        <div className="extract-url-input-container" key={index}>
                            <Input
                                value={url}
                                className="extract-url-input"
                                onChange={e => {
                                    onChangeNthExtractedUrl(
                                        index,
                                        e.target.value,
                                    );
                                }}
                            />

                            {index !== 0 && (
                                <Button
                                    variant="secondary"
                                    onClick={() => removeNthExtractedUrl(index)}
                                >
                                    <IconTrash size={15} />
                                </Button>
                            )}
                            {index === extractedUrls.length - 1 && (
                                <Button
                                    variant="outline"
                                    onClick={addEmptyExtractedUrl}
                                >
                                    <IconPlus size={15} />
                                </Button>
                            )}
                        </div>
                    ))}
                </span>
            </div>
        </>,
    ];

    const handleExtractUrls = async () => {
        const response = await service.extractUrls(urlExtract);
        setExtractedUrls(response);
    };

    const handleFinish = async () => {
        if (!agent) return;
        await service.create(agent.id, extractedUrls);
        notifyAgentTrainingChanged();
    };

    const handleStepFinish = async () => {
        if (step === 0) return;

        if (step === 1) {
            await handleExtractUrls();
        }

        if (step === 2) {
            await handleFinish();
            handleClose();
        }
    };

    const next = async () => {
        if (step === 0 && !usedExtraction) {
            await handleStepFinish();
            return setStep(prev => prev + 2);
        }

        await handleStepFinish();
        step <= 2 && setStep(prev => prev + 1);
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal onClick={handleCloseContainer}>
                <div className="content-modal">
                    <div className="modal-header">
                        <p>{textSteps[step]?.title}</p>
                        <IoMdClose size={25} onClick={handleClose} />
                    </div>

                    {textSteps[step]?.description && (
                        <p className="modal-description">
                            {textSteps[step]?.description}
                        </p>
                    )}

                    {item_editing == undefined && steps[step]}
                    <div className="button-container">
                        <Button
                            variant="secondary"
                            fullStyled
                            onClick={() => {
                                setStep(0);
                                setUsedExtraction(true);
                                setUrlExtract('');
                                setExtractedUrls([]);
                                handleClose();
                            }}
                        >
                            {textSteps[step]?.firstButton}
                        </Button>
                        <Button
                            variant="primary"
                            fullStyled
                            onClick={async () => {
                                await next();
                            }}
                        >
                            {step === 2 && <IconRotateClockwise size={15} />}
                            {textSteps[step]?.secondButton}
                        </Button>
                    </div>
                </div>
            </ContainerModal>
        </Fade>
    );
};
