import React from 'react';

export const Popup: React.FC = () => (
    <svg
        width="34"
        height="33"
        viewBox="0 0 34 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_13095_25441)">
            <path
                d="M31 0.5H3C1.89543 0.5 1 1.39543 1 2.5V30.5C1 31.6046 1.89543 32.5 3 32.5H31C32.1046 32.5 33 31.6046 33 30.5V2.5C33 1.39543 32.1046 0.5 31 0.5Z"
                stroke="#A09FA5"
            />
            <path
                d="M26.5 8.20508H8.5C7.94772 8.20508 7.5 8.65279 7.5 9.20508V25.1031C7.5 25.6554 7.94772 26.1031 8.5 26.1031H26.5C27.0523 26.1031 27.5 25.6554 27.5 25.1031V9.20508C27.5 8.65279 27.0523 8.20508 26.5 8.20508Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_13095_25441">
                <rect
                    width="33"
                    height="33"
                    fill="white"
                    transform="translate(0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
