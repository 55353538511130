import {
    IconAdjustments,
    IconHandStop,
    IconMessageQuestion,
    IconUser,
} from '@tabler/icons-react';
import { ExpansionPanelContainer } from 'app/presentation/components/agent';
import useText from 'app/presentation/hooks/useText';
import { BehaviorPanel } from './behavior-panel';
import { IdentityPanel } from './identity-panel';
import { InitialQuestionsPanel } from './initial-questions-panel';
import * as S from './styles';
import { WelcomeMessagePanel } from './welcome-message-panel';

const Behavior = () => {
    const { textGetter } = useText();
    const t = textGetter('agent.pages.behavior');

    return (
        <S.Behavior>
            <div className="behavior-form-container">
                <h2 className="title">{t('title')}</h2>
                <p className="subtitle">{t('subtitle')}</p>
                <div className="expansion-panel-container">
                    <ExpansionPanelContainer
                        id="behavior-expansion-panel-container"
                        panels={[
                            {
                                buttonTitle: t('panel.behavior'),
                                icon: <IconAdjustments></IconAdjustments>,
                                panelContent: <BehaviorPanel></BehaviorPanel>,
                            },
                            {
                                buttonTitle: t('panel.identity'),
                                icon: <IconUser></IconUser>,
                                panelContent: <IdentityPanel></IdentityPanel>,
                            },
                            {
                                buttonTitle: t('panel.welcome-messages'),
                                icon: <IconHandStop></IconHandStop>,
                                panelContent: (
                                    <WelcomeMessagePanel></WelcomeMessagePanel>
                                ),
                            },
                            {
                                buttonTitle: t('panel.initial-questions'),
                                icon: (
                                    <IconMessageQuestion></IconMessageQuestion>
                                ),
                                panelContent: (
                                    <InitialQuestionsPanel></InitialQuestionsPanel>
                                ),
                            },
                        ]}
                    ></ExpansionPanelContainer>
                </div>
            </div>
            {false && <div className="behavior-chat-container"></div>}
        </S.Behavior>
    );
};

export default Behavior;
