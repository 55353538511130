import React from 'react';
import { BrandsContextProps } from '../contexts/interfaces/brands-context-props-interface';
import BrandsContext from '../contexts/BrandsContext';

function useBrandsContext(): BrandsContextProps {
    const context = React.useContext(BrandsContext);

    if (!context) {
        throw new Error('useBrandsContext must be used within BrandProvider');
    }

    return context;
}

export default useBrandsContext;
