import styled from 'styled-components';

export const HeaderContainer = styled.header`
    width: 100%;
    float: left;
    height: 80px;
    background-color: #ffffff;
    display: flex;
    align-items: center;

    .ds-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            display: none;
            width: 150px;
            height: 56px;
            font-size: 16px;
        }

        @media (min-width: 768px) {
            button {
                display: flex;
            }
        }
    }
`;
