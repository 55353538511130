import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import { DarkMode, GlobalStyle, DefaultStyle } from '../../../../styles/';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { LayoutProps } from '../interfaces/layout-default-props.interface';

const returnMode = (darkMode: boolean) => {
    if (darkMode) return <DarkMode />;

    return <DefaultStyle />;
};

const BaseLayout: React.FC<LayoutProps> = ({ children }) => {
    const { darkmode } = useDarkMode();

    return (
        <>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>

            <GlobalStyle />

            {returnMode(darkmode)}
        </>
    );
};

export default BaseLayout;
