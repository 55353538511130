import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { Button } from 'app/presentation/components';
import { useNavigate } from 'react-router-dom';
import { routeTranslate } from '../../../../../utils/intl';
import { useIntl } from 'react-intl';

interface ModalProps {
    active: boolean;
    handleCloseModal: () => void;
}

const ModalAccess: React.FC<ModalProps> = ({ active, handleCloseModal }) => {
    const navigate = useNavigate();
    const intl = useIntl();

    const path = routeTranslate(intl, 'route.urlLang');
    const handleBack = () => {
        return navigate(
            `/${path}${routeTranslate(
                intl,
                'route.backoffice',
            )}${routeTranslate(intl, 'route.home')}`,
        );
    };

    const handleClose = () => {
        handleBack();
        handleCloseModal();
    };

    const handlePlans = () => {
        navigate(
            `/${path}${routeTranslate(intl, 'route.begin')}${routeTranslate(
                intl,
                'route.plans',
            )}`,
        );

        handleCloseModal();
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal>
                <div className="content-modal">
                    <div id="title">
                        <h1>Ops, atualize seu plano!</h1>
                        <p>
                            O plano contratado não dá acesso a alguns recursos.
                            Não fique de fora, atualize agora!
                        </p>
                    </div>
                    <div className="container-submit">
                        <Button onClick={handlePlans} type="submit">
                            Atualizar plano
                        </Button>
                        <Button onClick={handleClose} type="button">
                            Ok, entendi
                        </Button>
                    </div>
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalAccess;
