import React from 'react';
import * as S from './styles';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/pt';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { useIntl } from 'react-intl';
import { useMediaQuery } from '@mui/material';

dayjs.extend(isBetweenPlugin);
dayjs.extend(updateLocale);

interface DayContainerProps extends PickersDayProps<Dayjs> {
  isFirstDay: boolean;
  isLastDay: boolean;
  isHovered: boolean;
  isInInterval: boolean;
  outsideCurrentMonth: boolean;
}

const DayContainer: React.FC<DayContainerProps> = (props) => {
  return (
    <S.DayContainer 
      isHovered={props.isHovered}
      isFirstDay={props.isFirstDay}
      isLastDay={props.isLastDay}
      isInInterval={props.isInInterval}
      outsideCurrentMonth={props.outsideCurrentMonth}
      day={props.day.day()}
    >
      <div className='btn-container'>
        <PickersDay className='pickers-day' {...props} />
      </div>
    </S.DayContainer>
    );
}

const initialFirstCalendarMaxDate = (): Dayjs => {
  const now = dayjs();
  return now.date(1).subtract(1,'day')
}

const initialSecondCalendarMinDate = (): Dayjs => {
  const now = dayjs();
  return now.date(1)
}

interface DateRangePickerProps {
  setStartDate: (date: Date | undefined) => void;
  setEndDate: (date: Date | undefined) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  setStartDate, setEndDate
}) => {
    const isDesktop = useMediaQuery('(min-width: 64em)');
    const { locale } = useIntl();
    const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
    const [firstDay, setFirstDay] = React.useState<Dayjs | null>(null);
    const [lastDay, setLastDay] = React.useState<Dayjs | null>(null);
    const [secondCalendarMinDate, setSecondCalendarMinDate] = React.useState<Dayjs | undefined>(
      isDesktop ? initialSecondCalendarMinDate() : undefined
    );
    const [firstCalendarMaxDate, setFirstCalendarMaxDate] = React.useState<Dayjs>(
      initialFirstCalendarMaxDate()
    );

    React.useEffect(()=>{
      setStartDate(firstDay ? firstDay.toDate() : undefined)
    },[firstDay]);

    React.useEffect(()=>{
      setEndDate(lastDay ? lastDay.toDate() : undefined)
    },[lastDay]);
    
    React.useEffect(()=>{
      if (isDesktop) {
        setSecondCalendarMinDate(firstCalendarMaxDate.add(1, 'day'))
      } else {
        setSecondCalendarMinDate(undefined)
      }
      
    },[isDesktop]);

    const isInInterval = (firstDay: Dayjs | null | undefined, lastDay: Dayjs | null | undefined , day: Dayjs) => {
      if (firstDay == null || lastDay == null) {
        return false;
      }
    
      return day.isBetween(firstDay, lastDay) || day.isSame(firstDay) || day.isSame(lastDay);
    };
    
    function Day(
      props: PickersDayProps<Dayjs>,
    ) {
      const { day, ...other } = props;
      props.outsideCurrentMonth
      return (
        <DayContainer
          {...other}
          disabled={props.outsideCurrentMonth}
          day={day}
          sx={{ padding: '3px 0px 0px 0px', maxWidth: '36px', maxHeight: '36px', fontSize: '16px' }}
          disableMargin
          selected={false}
          isFirstDay={day.isSame(firstDay)}
          isLastDay={day.isSame(lastDay)}
          isHovered={day.isSame(hoveredDay)}
          isInInterval={isInInterval(firstDay, lastDay, day)}
          
        />
      );
    }

    const handleChange = (day: Dayjs) => {
      if (firstDay == null) {
        setFirstDay(day);
        return
      }

      if (firstDay.isSame(day)) {
        setFirstDay(null);
        setLastDay(null);
        return
      }

      if (lastDay == null) {
        if (day.isBefore(firstDay)) {
          setLastDay(firstDay);
          setFirstDay(day);
        } else {
          setLastDay(day);
        }
        return
      }

      if (lastDay.isSame(day)) {
        setFirstDay(null);
        setLastDay(null);
        return
      }

      if (day.isBefore(firstDay)) {
        setFirstDay(day);
        return
      }

      setLastDay(day);
    }

    const handleFirstCalendarMonthChange = (day: Dayjs) => {
      setSecondCalendarMinDate(day.add(day.daysInMonth(), 'day'))
    }

    const handleSecondCalendarMonthChange = (day: Dayjs) => {
      setFirstCalendarMaxDate(day.subtract(1, 'day'))
    }

    dayjs.updateLocale('pt', {
      weekStart: 0,
    })

    dayjs.updateLocale('es', {
      weekStart: 0,
    })
    
    return (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={ locale == 'es-ES' ? 'es' : locale == 'en-US' ? 'en' : 'pt' }
      >
        <S.DateRangePicker>
          {isDesktop && 
            <DateCalendar
              disableFuture
              sx={
                {width: '252px'}
              }
              fixedWeekNumber={3}
              maxDate={firstCalendarMaxDate}
              dayOfWeekFormatter={(day: string)=> day[0].toUpperCase() + day.slice(1)}
              onMonthChange={handleFirstCalendarMonthChange}
              onChange={handleChange}
              showDaysOutsideCurrentMonth
              views={['day']}
              slots={{ day: Day }}
              slotProps={{
                  day: (ownerState) =>
                  ({
                      onPointerEnter: () => setHoveredDay(ownerState.day),
                      onPointerLeave: () => setHoveredDay(null),
                  }) as any,
              }}
            />
          }
          <DateCalendar
            disableFuture
            sx={
              {width: '252px'}
            }

            minDate={secondCalendarMinDate}
            dayOfWeekFormatter={(day: string)=> day[0].toUpperCase() + day.slice(1)}
            onMonthChange={handleSecondCalendarMonthChange}
            onChange={handleChange}
            showDaysOutsideCurrentMonth
            views={['day']}
            slots={{ day: Day }}
            slotProps={{
                day: (ownerState) =>
                ({
                    onPointerEnter: () => setHoveredDay(ownerState.day),
                    onPointerLeave: () => setHoveredDay(null),
                }) as any,
            }}
          />
          </S.DateRangePicker>
        </LocalizationProvider>
    );
};


export default DateRangePicker;
