import { Tooltip } from '@mui/material';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
    handleCloseMenu: () => void;
    to: string;
    icon: any;
    title: string;
    //specialItemElement?: any;
    customClass?: any;
}

export const NavItem: React.FC<Props> = ({
    handleCloseMenu,
    title,
    icon,
    to,
    //specialItemElement,
    customClass,
}) => {
    const { darkmode } = useDarkMode();
    return (
        <Tooltip
            title={title}
            placement="right"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                        fontSize: 14,
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <NavLink
                onClick={handleCloseMenu}
                to={to}
                style={{ width: 'fit-content' }}
            >
                <li className={`itemNavMenu ${customClass}`}>
                    <>{icon}</>

                    {/* <span className="title">{title}</span>

                {specialItemElement && (
                    <div className="specialElement">{specialItemElement}</div>
                )} */}
                </li>
            </NavLink>
        </Tooltip>
    );
};
