import { Tooltip } from '@mui/material';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface Props {
    handleCloseMenu: () => void;
    to: string;
    icon: any;
    title: string;
    activePaths?: string[];
    //specialItemElement?: any;
    customClass?: any;
    disable?: boolean;
}

export const NavItem: React.FC<Props> = ({
    handleCloseMenu,
    title,
    icon,
    to,
    //specialItemElement,
    customClass,
    activePaths,
    disable = false,
}) => {
    const { pathname } = useLocation();
    const { darkmode } = useDarkMode();

    const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (disable) {
            event.preventDefault();
            return;
        }

        handleCloseMenu();
    };

    return (
        <Tooltip
            title={disable ? '' : title}
            placement="right"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                        fontSize: 14,
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <NavLink
                onClick={onLinkClick}
                to={to}
                style={{ width: 'fit-content' }}
                className={
                    disable
                        ? 'disabled'
                        : to == pathname ||
                          activePaths
                              ?.map(path => {
                                  return pathname.startsWith(path);
                              })
                              .reduce((prev, current) => prev || current)
                        ? 'active-route'
                        : ''
                }
            >
                <li
                    className={`itemNavMenu ${
                        customClass + (disable ? ' disabled' : '')
                    }`}
                >
                    <>{icon}</>
                </li>
            </NavLink>
        </Tooltip>
    );
};
