import React from 'react';
import * as S from './styled';
import ReactSelect, { SingleValue, components } from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';
import { IconChevronDown } from '@tabler/icons-react';
import { SelectThemeVariants } from 'styles/themes/themes';
import { ThemeContext } from 'styled-components';
import useText from 'app/presentation/hooks/useText';

export interface Option {
    value: string;
    node: React.ReactNode;
}

interface SelectProps {
    options: Option[];
    value: string;
    onSelectChange: (value: string) => void;
    noOptionsMessage?: string;
    placeholder?: string;
    height?: string;
    width?: string;
    isSearchable?: boolean;
    isClearable?: boolean;
    variant: SelectThemeVariants;
}

export const Select: React.FC<SelectProps> = ({
    options,
    value,
    onSelectChange,
    width = 'fit-content',
    noOptionsMessage,
    placeholder,
    height,
    variant,
    isSearchable = false,
    isClearable = false,
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.select');
    const theme = React.useContext(ThemeContext);
    const selectOptionMap = (option: Option): React.ReactNode => {
        return option.node;
    };
    const onChange = (newValue: SingleValue<Option>) => {
        onSelectChange(newValue ? newValue.value : '');
    };
    return (
        <S.Select width={width}>
            <ReactSelect
                styles={S.SelectStyle({
                    variantTheme: theme.components.select[variant],
                    width,
                    height,
                })}
                noOptionsMessage={
                    noOptionsMessage
                        ? () => noOptionsMessage
                        : () => t('no-option')
                }
                placeholder={placeholder ? placeholder : t('placeholder')}
                value={options.find(option => option.value == value)}
                options={options}
                formatOptionLabel={selectOptionMap}
                onChange={onChange}
                isMulti={false}
                isSearchable={isSearchable}
                isClearable={isClearable}
                components={{
                    DropdownIndicator: () => (
                        <IconChevronDown className="select-icon"></IconChevronDown>
                    ),
                }}
            ></ReactSelect>
        </S.Select>
    );
};

interface CreatableSelectProps extends SelectProps {
    parseNewOption?: (value: string) => Option;
}

export const CreatableSelect: React.FC<CreatableSelectProps> = ({
    options,
    value,
    onSelectChange,
    noOptionsMessage,
    placeholder,
    parseNewOption = value => {
        return { value, node: <span>{value}</span> };
    },
    width = 'fit-content',
    height,
    variant,
    isSearchable = true,
    isClearable = true,
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.select');
    const theme = React.useContext(ThemeContext);
    const selectOptionMap = (option: Option): React.ReactNode => {
        return option.node;
    };
    const onChange = (newValue: SingleValue<Option>) => {
        onSelectChange(newValue ? newValue.value : '');
    };
    const parseValue = (value: string): Option => {
        const result = options.find(option => option.value == value);
        return result ? result : parseNewOption(value);
    };
    return (
        <S.Select width={width}>
            <ReactCreatableSelect
                styles={S.SelectStyle({
                    variantTheme: theme.components.select[variant],
                    width,
                    height,
                })}
                value={value ? parseValue(value) : undefined}
                noOptionsMessage={
                    noOptionsMessage
                        ? () => noOptionsMessage
                        : () => t('no-option')
                }
                placeholder={placeholder ? placeholder : t('placeholder')}
                getNewOptionData={parseNewOption}
                options={options}
                formatOptionLabel={selectOptionMap}
                onChange={onChange}
                isMulti={false}
                isSearchable={isSearchable}
                isClearable={isClearable}
                components={{
                    DropdownIndicator: () => (
                        <IconChevronDown></IconChevronDown>
                    ),
                    Option: ({ ...props }) => {
                        const option = props.data;
                        const isNewOption =
                            options.find(opt => opt.value == option.value) ==
                            undefined;
                        return (
                            <components.Option {...props}>
                                {isNewOption
                                    ? t('new-option-prefix') + ' '
                                    : null}{' '}
                                {option.node}
                            </components.Option>
                        );
                    },
                }}
            ></ReactCreatableSelect>
        </S.Select>
    );
};
