import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { Input, Button } from 'app/presentation/components/agent';
import { IconTrash } from '@tabler/icons-react';
import { Agent } from 'services/agent/interfaces';
import useAgent from 'app/presentation/hooks/useAgent';
import { SubmitHandler, useForm } from 'react-hook-form';
import useText from 'app/presentation/hooks/useText';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    agent?: Agent | undefined;
}

type AgentData = {
    name: string;
};

export const ModalDeleteAgent: React.FC<ModalProps> = ({
    active,
    handleClose,
    agent = undefined,
}) => {
    const { deleteAgent } = useAgent();
    const { register, handleSubmit, setValue } = useForm<AgentData>();
    const { textGetter } = useText();
    const modalText = textGetter('agent.components.modal.delete-agent');
    const commonsText = textGetter('agent.components.modal.commons');
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    const onSubmit: SubmitHandler<AgentData> = async (data: AgentData) => {
        if (!agent || agent.identity.agent_name != data.name) {
            return;
        }
        setFormSubmitLoading(true);
        deleteAgent(agent.id)
            .then(() => {
                handleClose();
            })
            .finally(() => setFormSubmitLoading(false));
    };

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            handleClose();
        }
    };

    React.useEffect(() => {
        if (!active) {
            return;
        }
        setValue('name', '');
    }, [active]);

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal onClick={handleCloseContainer}>
                <div className="content-modal">
                    <div className="modal-header">
                        <IoMdClose size={25} onClick={handleClose} />
                    </div>
                    <form
                        className="modal-body"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <p className="body-title">{modalText('title')}</p>
                        <p className="body-text">
                            {modalText('text-start')}
                            <span className="agent-name">
                                {' “' + agent?.identity.agent_name + '” '}
                            </span>
                            {modalText('text-end')}
                        </p>
                        <Input
                            placeholder="Insira o nome do agente para excluir"
                            registerValues={register('name')}
                            customValidation={{
                                validate: value =>
                                    agent != undefined &&
                                    agent.identity.agent_name == value,
                                message: modalText('input.message'),
                            }}
                        />

                        <div className="button-container">
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={handleCloseContainer}
                            >
                                {commonsText('button.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                variant="danger"
                                className="delete-button"
                                loading={formSubmitLoading}
                            >
                                <IconTrash></IconTrash>
                                <span>
                                    {modalText('button.delete')}{' '}
                                    <span className="desktop">
                                        {modalText('button.delete-suffix')}
                                    </span>
                                </span>
                            </Button>
                        </div>
                    </form>
                </div>
            </ContainerModal>
        </Fade>
    );
};
