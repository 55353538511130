import React from 'react';
import { SECOND, timeDifference } from 'utils/time';

interface TimeFromNowProps {
    time: Date;
}

export const TimeFromNow: React.FC<TimeFromNowProps> = ({ time }) => {
    const [now, setNow] = React.useState<Date>(new Date());
    React.useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 30 * SECOND);
        return () => clearInterval(interval);
    }, []);

    return <span>{timeDifference(now, time)}</span>;
};
