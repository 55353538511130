import React from 'react';
import { useIntl } from 'react-intl';
import { Box, StageContainer } from './styles';
import { Container, Stage } from 'app/presentation/components';
import { routeTranslate as t } from 'utils/intl';
import { useNavigate } from 'react-router-dom';
import { Global } from 'app/presentation/components/icons';
import { sleep } from 'utils/sleep';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { IUsecasesInterests } from 'interfaces/plans.interface';

type IReason = {
    useCaseList: Array<IUsecasesInterests> | undefined;
    selected: number;
    setSelected: (value: number) => void;
};

const Reason: React.FC<IReason> = ({ useCaseList, selected, setSelected }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [fadeEffect, setFadeEffect] = React.useState('fade-in');
    const { darkmode } = useDarkMode();

    const handleClick = async (option: number) => {
        setSelected(option);
        setFadeEffect('fade-out');
        await sleep(0.4);
        navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.begin')}${t(intl, 'route.yourBusiness')}`);
    };

    return (
        <Container className={fadeEffect}>
            <StageContainer>
                <Stage current={1} total={3} />
            </StageContainer>
            <Box className={darkmode ? 'containerReasonDark' : ''}>
                <h1>{t(intl, 'onboarding.title')}</h1>
                <p>{t(intl, 'onboarding.description')}</p>
                <div id="grid">
                    {useCaseList &&
                        useCaseList.map(usecase => {
                            const hasImg = usecase.icon.trim() !== '';
                            return (
                                <div
                                    className={selected === usecase.id ? 'itemSelected' : ''}
                                    onClick={() => handleClick(usecase.id)}
                                    key={usecase.id}
                                >
                                    <div className="icon">
                                        {hasImg ?
                                            (<img src={usecase.icon} alt="Icon" />) :
                                            (<Global darkmode={darkmode} />)
                                        }
                                    </div>
                                    <h1>{usecase.translations[0].title}</h1>
                                    <p>{usecase.translations[0].description}</p>
                                </div>
                            );
                        })}
                </div>
            </Box>
        </Container>
    );
};

export default Reason;
