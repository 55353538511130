import React from 'react';
import { useSnackbar } from 'notistack';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import useUserConfigs from '../hooks/useUserConfigs';
import { Hide, InfoGenerate, Rate } from '../../../interfaces/chat.interface';
import { getToken } from 'services/api';
import { ChatService } from 'services/chat';
import ChatContext from '../contexts/ChatContext';
import {
    Chat,
    ChatData,
    Daum,
    Personality,
    RegenerateChat,
    SaveChat,
} from './interfaces/chat-props.interface';
import { PersonalitiesService } from 'services/personalities';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import { idsStore } from '../stores/idsStores';

const chatService = new ChatService();
const personalitiesService = new PersonalitiesService();

const websearch_status = [
    'processing_message',
    'searching_web',
    'fetching_pages',
    'processing_page_content',
    'starting_answer_generation',
];

const ChatProvider: React.FC<ProviderProps> = ({ children }) => {
    const [countTemplates, setCountTemplates] = React.useState<number>(0);
    const [chats, setChats] = React.useState<Chat[]>();
    const [chat, setChat] = React.useState<number | undefined>();
    const [chatData, setChatData] = React.useState<ChatData>();
    const [personalities, setPersonalities] = React.useState<Personality>();
    const [personality, setPersonality] = React.useState<Daum | undefined>();
    const [entries, setEntries] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [writing, setWriting] = React.useState(false);
    const [currentSocket, setWebsocket] = React.useState<WebSocket>();
    const { data: dataId } = idsStore();
    const [statusWebSearch, setStatusWebSearch] = React.useState<string[]>([
        '',
    ]);

    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const { project, workspace } = useUserConfigs();

    React.useEffect(() => {
        const fetchData = async () => {
            await getChats(undefined, undefined);
            await getPersonalities();
        };

        fetchData().catch(error => console.log(error));
    }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            if (chat) {
                await getChat(chat, undefined, true);
            }
        };

        fetchData().catch(error => console.log(error));
    }, [chat]);

    const showLoading = () => {
        setLoading(true);
    };

    const closeLoading = () => {
        setLoading(false);
    };

    const getChats = async (
        category: string | undefined,
        search: string | undefined,
    ) => {
        try {
            showLoading();

            const result = await chatService.getAll();
            const data = result.data;

            if (!category && !search) {
                setCountTemplates(data.length);
            }

            setChats(data);
            if (data[0]) {
                setChat(data[0].id);
            }

            if (!data.length) {
                if (workspace && workspace.has_active_plan) {
                    await newChat(routeTranslate(intl, 'chat.newChat.title'));
                    await getChats(undefined, undefined);
                }
                const res = await chatService.getAll();
                const data = res.data;
                if (data) {
                    setChat(data[0].id);
                }
            }
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const getChat = async (id: number, page?: number, update?: boolean) => {
        try {
            showLoading();

            const pageNumber = update ? 1 : page ?? 1;
            const dataChat = await chatService.getOne(id, pageNumber);

            if (update) {
                setChatData(dataChat);
                return;
            }

            setChatData({
                ...dataChat,
                entries:
                    chatData && chatData.data
                        ? [...dataChat.data, ...chatData.data]
                        : dataChat.data,
            });
        } catch (e) {
            setChatData({ ...chatData, data: undefined });
        } finally {
            closeLoading();
        }
    };

    const getTranscript = async (id: number, file: File) => {
        try {
            showLoading();

            const data = await chatService.getTranscript(id, file);

            return data;
        } catch (e) {
            // setChatData({ ...chatData, entries: undefined });
        } finally {
            closeLoading();
        }
    };

    let message = '';
    let resource_usages_data: any = undefined;

    const startWebSocket = (
        websocket: WebSocket,
        prompt: string,
        callback?: () => void,
    ) => {
        setWebsocket(websocket);

        websocket.onclose = async () => {
            setWriting(false);

            resource_usages_data = undefined;
            message = '';
            if (callback) callback();

            if (chat) {
                await getChat(chat, chatData?.page, true);
            }
        };

        websocket.onmessage = async function (event) {
            closeLoading();

            setWriting(true);

            const { data, type, resource_usages, error } = JSON.parse(
                event.data,
            );

            if (resource_usages_data === undefined) {
                resource_usages_data = resource_usages;
                return;
            }

            if (error === 'websearch_usage_exceeded') {
                enqueueSnackbar(
                    routeTranslate(intl, 'chat.websearch.error.limit'),
                    {
                        variant: 'error',
                    },
                );
                return;
            }

            if (websearch_status.includes(data)) {
                setStatusWebSearch(prev => [...prev, data]);
                return;
            }

            message = message + data;

            const messagesFind = chatData?.data?.find(
                data => data.id === resource_usages_data[0].resource_id,
            );

            const messagesFilter = chatData?.data?.filter(
                data => data.id !== resource_usages_data[0].resource_id,
            );

            if (messagesFind) {
                if (chatData && messagesFilter && chat) {
                    setChatData({
                        page: chatData?.page,
                        limit: chatData.limit,
                        total_pages: chatData?.total_pages,
                        data: [
                            ...messagesFilter,
                            {
                                chat_id: chat,
                                created_at: '',
                                deleted_at: '',
                                updated_at: '',
                                sender: '',
                                message: message,
                                generation_id:
                                    resource_usages_data[0].resource_id,
                                previous_message_id:
                                    resource_usages_data[0].resource_id,
                                type: resource_usages_data[0].resource_id,
                                transcription:
                                    resource_usages_data[0].resource_id,
                                id: resource_usages_data[0].resource_id,
                            },
                        ],
                    });
                } else if (chatData && chat) {
                    setChatData({
                        page: chatData?.page,
                        limit: chatData.limit,
                        total_pages: chatData?.total_pages,
                        data: [
                            {
                                chat_id: chat,
                                created_at: '',
                                deleted_at: '',
                                updated_at: '',
                                sender: '',
                                message: message,
                                generation_id:
                                    resource_usages_data[0].resource_id,
                                previous_message_id:
                                    resource_usages_data[0].resource_id,
                                type: resource_usages_data[0].resource_id,
                                transcription:
                                    resource_usages_data[0].resource_id,
                                id: resource_usages_data[0].resource_id,
                            },
                        ],
                    });
                }
            } else if (chatData && chat && messagesFilter) {
                setChatData({
                    page: chatData?.page,
                    limit: chatData.limit,
                    total_pages: chatData?.total_pages,
                    data: [
                        ...messagesFilter,
                        {
                            chat_id: chat,
                            created_at: '',
                            deleted_at: '',
                            updated_at: '',
                            sender: 'user',
                            message: prompt,
                        },
                        {
                            chat_id: chat,
                            created_at: '',
                            deleted_at: '',
                            updated_at: '',
                            sender: '',
                            message: message,
                            generation_id: resource_usages_data[0].resource_id,
                            previous_message_id:
                                resource_usages_data[0].resource_id,
                            type: resource_usages_data[0].resource_id,
                            transcription: resource_usages_data[0].resource_id,
                            id: resource_usages_data[0].resource_id,
                        },
                    ],
                });
            } else if (chatData && chat) {
                setChatData({
                    page: chatData?.page,
                    limit: chatData.limit,
                    total_pages: chatData?.total_pages,
                    data: [
                        {
                            chat_id: chat,
                            created_at: new Date().toString(),
                            deleted_at: '',
                            updated_at: new Date().toString(),
                            sender: 'user',
                            message: prompt,
                        },
                        {
                            chat_id: chat,
                            created_at: '',
                            deleted_at: '',
                            updated_at: '',
                            sender: '',
                            message: message,
                            generation_id: resource_usages_data[0].resource_id,
                            previous_message_id:
                                resource_usages_data[0].resource_id,
                            type: resource_usages_data[0].resource_id,
                            transcription: resource_usages_data[0].resource_id,
                            id: resource_usages_data[0].resource_id,
                        },
                    ],
                });
            }

            if (type === 'end') {
                resource_usages_data = undefined;
                message = '';
                if (callback) callback();

                if (chat) {
                    await getChat(chat, chatData?.page, true);
                }

                setStatusWebSearch(['']);
                setWriting(false);
            }
        };
    };

    const saveChat = async (chatReq: SaveChat, callback?: () => void) => {
        try {
            if (!chat) return;

            showLoading();

            const websocket = chatService.getSocket();
            const token = await getToken();

            websocket.onopen = () => {
                websocket.send(
                    JSON.stringify({
                        authorization: {
                            token: token,
                            workspace_id: dataId.workspace_id,
                            project_id: dataId.project_id,
                        },
                        input: chatReq,
                    }),
                );

                setLoading(true);
            };

            startWebSocket(websocket, chatReq.prompt, callback);
        } catch (e) {
            enqueueSnackbar('Erro ao executar modelo!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const regenerateChat = async (
        chatReq: RegenerateChat,
        callback?: () => void,
    ) => {
        try {
            if (!chat) return;

            showLoading();

            const websocket = chatService.getSocketRegenerate();
            const token = await getToken();

            websocket.onopen = () => {
                websocket.send(
                    JSON.stringify({
                        authorization: {
                            token: token,
                            workspace_id: dataId.workspace_id,
                            project_id: dataId.project_id,
                        },
                        input: { ...chatReq, chat_id: chat },
                    }),
                );

                setLoading(true);
            };

            if (chatData && chatData.data) {
                const entriesArray = chatData.data;
                entriesArray.pop();
                setChatData({ ...chatData, data: entriesArray });
            }

            startWebSocket(websocket, '', callback);
        } catch (e) {
            enqueueSnackbar('Erro ao executar modelo!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const newChat = async (name: string) => {
        try {
            showLoading();

            await chatService.create(name, project.id);

            await getChats(undefined, undefined);
        } catch (e) {
            enqueueSnackbar('Erro ao criar chat!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const updateChat = async (name: string, chat_id: number) => {
        try {
            showLoading();

            await chatService.update(name, chat_id);

            await getChats(undefined, undefined);
        } catch (e) {
            enqueueSnackbar('Erro ao criar chat!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const deleteChat = async (id: number) => {
        try {
            showLoading();

            await chatService.removeChat(id);

            await getChats(undefined, undefined);
            if (chat) {
                await getChat(chat);
            }
        } catch (e) {
            enqueueSnackbar('Erro ao criar chat!', {
                variant: 'error',
            });

            setChatData({ ...chatData, data: undefined });
        } finally {
            closeLoading();
        }
    };

    const hideResponse = async (hide: Hide) => {
        try {
            if (!chat) return;

            showLoading();

            await chatService.hideResponse(hide, workspace.id, project.id);

            await getChat(chat, chatData?.page, true);

            enqueueSnackbar('Ação realizada com sucesso!', {
                variant: 'success',
            });
        } catch (e) {
            enqueueSnackbar('Erro ao realizar essa ação!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const rateResponse = async (rate: Rate) => {
        try {
            if (!chat) return;

            showLoading();

            await chatService.rateResponse(rate, workspace.id);

            enqueueSnackbar('Avaliação enviada com sucesso!', {
                variant: 'success',
            });
        } catch (e) {
            enqueueSnackbar('Erro ao avaliar modelo!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const getPersonalities = async (search?: string) => {
        try {
            if (workspace && workspace.has_active_plan) {
                showLoading();

                const personalitiesData = await personalitiesService.getAll(
                    search,
                );
                setPersonalities(personalitiesData);
                setPersonality(personalitiesData.data[0]);
            }
        } catch (e) {
            enqueueSnackbar('Erro ao obter personalidades!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const generateImage = async (data: InfoGenerate) => {
        try {
            showLoading();

            if (!chat) return;
        } catch (e) {
            enqueueSnackbar('Erro ao obter personalidades!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const cleanEntries = () => {
        setEntries([]);
    };

    const stopChat = () => {
        try {
            if (!chat) return;

            if (currentSocket) {
                currentSocket.send(
                    JSON.stringify({
                        type: 'abort',
                    }),
                );
            }
        } catch (e) {
            enqueueSnackbar('Erro ao interromper o chat!', {
                variant: 'error',
            });
        }
    };

    return (
        <ChatContext.Provider
            value={{
                statusWebSearch,
                getChats,
                getChat,
                setChat,
                newChat,
                writing,
                currentSocket,
                getTranscript,
                chats,
                chat,
                cleanEntries,
                entries,
                countTemplates,
                rateResponse,
                hideResponse,
                generateImage,
                loading,
                showLoading,
                closeLoading,
                deleteChat,
                updateChat,
                personalities,
                setPersonality,
                getPersonalities,
                personality,
                chatData,
                saveChat,
                regenerateChat,
                stopChat,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};

export default ChatProvider;
