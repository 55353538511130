import React from 'react';
import { ContainerContent, TableStyle, MuiMenuHeader } from './styles';
import { useIntl } from 'react-intl';
import { CircularProgress } from '@mui/material';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { VoiceToneServices } from 'services/brand/voice_tone';
import { VoiceTone } from 'app/presentation/hooks/interfaces/brand';
import { BsPlusLg, BsThreeDots } from 'react-icons/bs';
import { routeTranslate } from 'utils/intl';
import { Menu, MenuList, Paper } from '@mui/material';
import { FiEdit3 } from 'react-icons/fi';
import { BiTrash } from 'react-icons/bi';
import { useSnackbar } from 'notistack';
//@ts-ignore
import newTone from '../../../assets/images/svg/new-tone.svg';
import { Button } from 'app/presentation/components';
import ModalNewVoiceTone from 'app/presentation/components/dashboard/ModalNewVoiceTone';
import Loading from 'app/presentation/components/common/Loading';

const voiceToneService = new VoiceToneServices();

const VoiceToneView: React.FC = () => {
    const intl = useIntl();
    const [loadingDelete, setLoadingDelete] = React.useState(false);
    const [showNewVoiceToneModal, setShowNewVoiceToneModal] =
        React.useState(false);
    const [voices, setVoices] = React.useState<VoiceTone[]>();
    const [opeMenuTable, setOpenMenuTable] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [editing, setEditing] = React.useState(false);
    const [selectedAnchorItem, setSelectedAnchorItem] = React.useState<
        VoiceTone | undefined
    >();
    const [voiceItem, setVoiceItem] = React.useState<VoiceTone | undefined>();
    const { darkmode } = useDarkMode();
    const { workspace, project, activeBrand } = useUserConfig();
    const workspaceId = workspace.id;
    const projectId = project.id;
    const { enqueueSnackbar } = useSnackbar();
    const [loadingVoiceTones, setLoadingVoiceTones] = React.useState(true);

    React.useEffect(() => {
        getBrandVoices();
    }, [workspaceId, projectId, activeBrand]);

    const getBrandVoices = async () => {
        if (activeBrand) {
            try {
                const data = await voiceToneService.getAll(
                    workspaceId,
                    projectId,
                    activeBrand.id,
                );

                if (data instanceof Error) {
                    throw new Error('error to get voices');
                }

                setVoices(data);
                setLoadingVoiceTones(false);
            } catch (error) {
                enqueueSnackbar(
                    routeTranslate(intl, 'brand.voiceTone.getAll.error'),
                    {
                        variant: 'error',
                    },
                );
            }
        }
    };

    const handleClickMenuTable = (
        event: React.MouseEvent<HTMLLIElement>,
        item: VoiceTone,
    ) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuTable(true);
        setSelectedAnchorItem(item);
    };

    const handleCloseMenuTable = () => {
        setAnchorEl(null);
        setOpenMenuTable(false);
    };

    const handleEdit = () => {
        if (selectedAnchorItem) {
            setEditing(true);
            setVoiceItem(selectedAnchorItem);
            // setModalOpen(true);
            handleCloseMenuTable();
        }
    };

    const handleDeleteVoice = async () => {
        try {
            if (selectedAnchorItem) {
                setLoadingDelete(true);
                await voiceToneService.delete(
                    workspaceId,
                    projectId,
                    selectedAnchorItem.id,
                );
                await getBrandVoices();
                enqueueSnackbar(
                    routeTranslate(intl, 'brand.voiceTone.delete.success'),
                    {
                        variant: 'success',
                    },
                );
                setLoadingDelete(false);
                handleCloseMenuTable();
            }
        } catch (error) {
            setLoadingDelete(false);

            enqueueSnackbar(
                routeTranslate(intl, 'brand.voiceTone.delete.error'),
                {
                    variant: 'error',
                },
            );
        }
    };

    return (
        <ContainerContent>
            {loadingVoiceTones && <Loading open={loadingVoiceTones} />}
            {!loadingVoiceTones && voices && voices.length > 0 ? (
                <>
                    <div className="voiceToneHeader">
                        <div>
                            <h3>{routeTranslate(intl, `brand.tone`)}</h3>
                            <p>{routeTranslate(intl, `brand.tone.desc`)}</p>
                        </div>

                        <div className="btn-container">
                            <Button
                                style={{
                                    fontSize: '0.9em',
                                    fontWeight: '1000',
                                    paddingLeft: '1.5em',
                                    paddingTop: '0.9em',
                                    paddingBottom: '0.9em',
                                }}
                                icon={<BsPlusLg size={20} />}
                                iconPositon="start"
                                //onClick={() => setShowNewVoiceToneModal(true)}
                            >
                                {routeTranslate(
                                    intl,
                                    'brand.voiceTone.empty.btn',
                                )}
                            </Button>
                        </div>
                    </div>
                    <div className="table">
                        <TableStyle className={darkmode ? 'tableDark' : ''}>
                            <thead>
                                <tr>
                                    <th className="nameField">
                                        <p>
                                            {routeTranslate(
                                                intl,
                                                'brand.voiceTone.name',
                                            )}
                                        </p>
                                    </th>
                                    <th>
                                        <p>
                                            {routeTranslate(
                                                intl,
                                                'brand.voiceTone.lasteEditor',
                                            )}
                                        </p>
                                    </th>

                                    <th className="action">
                                        <p>
                                            {routeTranslate(
                                                intl,
                                                'home.documents.actions',
                                            )}
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {voices.map(voice => {
                                    return (
                                        <tr key={voice.id}>
                                            <td className="nameField">
                                                <p>
                                                    {voice.name}

                                                    {voice.is_default && (
                                                        <span>
                                                            {routeTranslate(
                                                                intl,
                                                                'brand.voiceTone.default',
                                                            )}
                                                        </span>
                                                    )}
                                                </p>
                                                <span>{voice.content}</span>
                                            </td>
                                            <td>
                                                <p>{voice.last_editor}</p>
                                            </td>
                                            <td className="actionTd">
                                                <li
                                                    onClick={(
                                                        e: React.MouseEvent<HTMLLIElement>,
                                                    ) =>
                                                        handleClickMenuTable(
                                                            e,
                                                            voice,
                                                        )
                                                    }
                                                >
                                                    <BsThreeDots
                                                        color="#A09FA5"
                                                        size={20}
                                                    />
                                                </li>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <Menu
                                id="basic-menu-workspace"
                                anchorEl={anchorEl}
                                open={opeMenuTable}
                                onClose={handleCloseMenuTable}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                sx={{
                                    width: '100%',
                                    '& > .MuiPaper-root': {
                                        boxShadow:
                                            '8px 16px 32px rgba(113, 128, 150, 0.08);',
                                        borderRadius: '12px',
                                        background: darkmode ? '#1d1d1f' : '',
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: 40,
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <Paper
                                    sx={{
                                        width: 202,
                                        boxShadow: 0,
                                        padding: '0px 16px',
                                        background: darkmode ? '#1d1d1f' : '',
                                    }}
                                >
                                    <MenuList
                                        sx={{
                                            padding: 0,
                                            boxShadow: 0,
                                            background: darkmode
                                                ? '#1d1d1f'
                                                : '',
                                        }}
                                        disablePadding
                                        dense
                                    >
                                        <MuiMenuHeader>
                                            <ul className="list-submenu-options">
                                                <li onClick={handleEdit}>
                                                    <FiEdit3 size={18} />
                                                    {routeTranslate(
                                                        intl,
                                                        'home.documents.edit',
                                                    )}
                                                </li>
                                                <li
                                                    onClick={
                                                        loadingDelete
                                                            ? undefined
                                                            : handleDeleteVoice
                                                    }
                                                >
                                                    {loadingDelete ? (
                                                        <div className="loading-spinner">
                                                            <CircularProgress
                                                                size={24}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <BiTrash
                                                                size={18}
                                                            />
                                                            {routeTranslate(
                                                                intl,
                                                                'home.documents.delete',
                                                            )}
                                                        </>
                                                    )}
                                                </li>
                                            </ul>
                                        </MuiMenuHeader>
                                    </MenuList>
                                </Paper>
                            </Menu>
                        </TableStyle>
                    </div>
                </>
            ) : (
                <>
                    {!loadingVoiceTones && (
                        <div className="empty-voice-tone">
                            <img src={newTone} alt="new tone image" />
                            <div>
                                <h3>
                                    {routeTranslate(
                                        intl,
                                        'brand.voiceTone.empty.title',
                                    )}
                                </h3>
                                <p>
                                    {routeTranslate(
                                        intl,
                                        'brand.voiceTone.empty.desc',
                                    )}
                                </p>
                            </div>

                            <div className="btn-container">
                                <Button
                                    icon={<BsPlusLg size={20} />}
                                    iconPositon="start"
                                    onClick={() =>
                                        setShowNewVoiceToneModal(true)
                                    }
                                    // disabled={reachedTotalVoices}
                                >
                                    {routeTranslate(
                                        intl,
                                        'brand.voiceTone.empty.btn',
                                    )}
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}

            <ModalNewVoiceTone
                active={showNewVoiceToneModal}
                handleClose={() => {
                    setAnchorEl(null);
                    setShowNewVoiceToneModal(false);
                }}
                editing={editing}
                callback={getBrandVoices}
            />
        </ContainerContent>
    );
};

export default VoiceToneView;
