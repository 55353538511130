import styled from 'styled-components';

export const Form = styled.form`
    width: 100%;
    max-width: 427px;
    margin: 0 auto;
    padding: 32px 24px 32px 24px;
    border-radius: 16px;

    > h1,
    > p {
        text-align: center;
    }

    > h1 {
        margin-bottom: 12px;
    }

    #two-fields {
        div {
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            input[type='number'] {
                -moz-appearance: textfield; /* Firefox */
            }
        }

        .label-phone {
            color: #000;
            font-weight: 600;
            margin-top: 20px;
            margin-bottom: 15px;
            text-align: start;
            width: 100%;
        }

        .input-icon {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
        }

        .input-icon > i {
            position: absolute;
            display: block;
            transform: translate(0, 0);
            top: 0;
            width: 125px;
            height: 100%;
            text-align: center;
            font-style: normal;
        }

        .input-icon > div {
            input {
                padding-left: 130px;
            }
            padding-right: 0;
        }

        .input-icon-right > i {
            right: 0;
        }

        .input-icon-right > input {
            padding-left: 0;
            padding-right: 25px;
            text-align: right;
        }
    }

    @media (min-width: 768px) {
        max-width: 740px;
        padding-top: 0;

        > h1 {
            font-size: 40px !important;
        }

        > p {
            font-size: 18px;
            margin-bottom: 91px !important;
        }

        #two-fields {
            display: flex;
            flex-direction: column;
            justify-content: center !important;
            align-items: center !important;
            margin: 0;
            padding: 0;
            margin-bottom: 36px;

            div {
                max-width: 520px;
            }
            .label-phone {
                max-width: 520px;
            }
        }
    }

    button {
        margin-bottom: 24px;
    }

    a {
        display: block;
        text-align: center;
    }

    > p {
        margin-bottom: 24px;
        color: #718096;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.2px;
    }

    #password-field {
        position: relative;

        svg {
            position: absolute;
            right: 18px;
            top: 20px;
            cursor: pointer;
        }
    }

    #remember {
        display: flex;
        align-items: center;
    }

    #options {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        #remember-text {
            font-weight: 500;
        }
    }

    &.formDarkMode {
        input {
            background: #3a3a3a !important;
            color: #fff !important;
            border-color: #3a3a3a;

            &:focus {
                border: 1px solid #fff !important;
            }
        }

        .tagline {
            color: #cccccc;
        }
    }

    .buttons {
        display: flex;
        width: 100%;
        max-width: 740px;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        button {
            cursor: pointer;
            border: none;
            color: #000;
            font-weight: 700;
            font-size: 1rem;
        }

        .buttonBack {
            background: transparent;
        }

        .buttonContinue {
            background: #d0ff61;
            width: 180px;
            height: 56px;
            border-radius: 12px;
        }
    }
`;

export const StageContainer = styled.div`
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
`;
