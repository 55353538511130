import { useIntl } from 'react-intl';
import { routeTranslate } from './intl';

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const MONTH = DAY * 30;
export const YEAR = DAY * 365;

export function timeDifference(current: Date, previous: Date) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;
    const intl = useIntl();

    const elapsed = Math.abs(current.getTime() - previous.getTime());

    if (elapsed < msPerMinute) {
        return (
            Math.round(elapsed / 1000) + routeTranslate(intl, 'time.seconds')
        );
    } else if (elapsed < msPerHour) {
        return (
            Math.round(elapsed / msPerMinute) +
            routeTranslate(intl, 'time.minutes')
        );
    } else if (elapsed < msPerDay) {
        return (
            Math.round(elapsed / msPerHour) + routeTranslate(intl, 'time.hours')
        );
    } else if (elapsed < msPerMonth) {
        return (
            routeTranslate(intl, 'time.approximately') +
            Math.round(elapsed / msPerDay) +
            routeTranslate(intl, 'time.days')
        );
    } else if (elapsed < msPerYear) {
        return (
            routeTranslate(intl, 'time.approximately') +
            Math.round(elapsed / msPerMonth) +
            routeTranslate(intl, 'time.months')
        );
    } else {
        return (
            routeTranslate(intl, 'time.approximately') +
            Math.round(elapsed / msPerYear) +
            routeTranslate(intl, 'time.years')
        );
    }
}



export function daySuffix(date: Date): string {
    const day = date.getDate();
    let suffix;
    if (day > 3 && day < 21) {
        suffix = 'th';
    } else {
        switch (day % 10) {
            case 1:
                suffix = 'st';
                break;
            case 2:
                suffix = 'nd';
                break;
            case 3:
                suffix = 'rd';
                break;
            default:
                suffix = 'th';
                break;
        }
    }
    
    return suffix
}

export function formatFullDateISOString(str: string, locale: string) {
    const date = dateFromISOString(str)
    return formatFullDate(date, locale)
}

export function formatFullDate(date: Date, locale: string) {
    const dayFormatter = new Intl.DateTimeFormat(locale, { day: 'numeric' });
    const monthFormatter = new Intl.DateTimeFormat(locale, { month: 'long' });
    const yearFormatter = new Intl.DateTimeFormat(locale, { year: 'numeric' });

    const day = dayFormatter.format(date);
    const month = monthFormatter.format(date);
    const year = yearFormatter.format(date);

    switch (locale) {
        case 'en-US': 
            return `${day}${daySuffix(date)} of ${month}, ${year}`
        default:
            return `${day} de ${month} de ${year}`;
    }
}

export function formatISOStringToShortFullDate(str: string, locale: string) {
    const date = dateFromISOString(str)
    return formatToShortFullDate(date, locale)
}

export function formatToShortFullDate(date: Date, locale: string) {
    const dayFormatter = new Intl.DateTimeFormat(locale, { day: 'numeric' });
    const monthFormatter = new Intl.DateTimeFormat(locale, { month: 'short' });
    const yearFormatter = new Intl.DateTimeFormat(locale, { year: 'numeric' });

    const day = dayFormatter.format(date);
    const month = monthFormatter.format(date).replaceAll('.','');
    const year = yearFormatter.format(date);

    return `${year} ${month.charAt(0).toUpperCase() + month.slice(1)} ${day}`
}

export function formatDayAndMonthISOString(str: string, locale: string) {
    const date = dateFromISOString(str)
    return formatDayAndMonth(date, locale)
}

export function formatDayAndMonth(date: Date, locale: string) {
    const dayFormatter = new Intl.DateTimeFormat(locale, { day: 'numeric' });
    const monthFormatter = new Intl.DateTimeFormat(locale, { month: 'short' });
    const day = dayFormatter.format(date);
    const month = monthFormatter.format(date).replaceAll('.','');
    return `${month.charAt(0).toUpperCase() + month.slice(1)} ${day}`
}

export function formatHoursISOString(str: string) {
    const date = dateFromISOString(str)
    return formatHours(date)
}

export function formatHours(date: Date) {
    return `${date.getHours()}h`
}

export function formatSecondsInterval(seconds: number) {
    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
  
    return (!days ? '' : `${days}d `)
        + (!days && !hours ? '' : `${hours}h `)
        + (!days && !hours && !minutes ? '' : `${minutes}m `)
        + `${seconds}s`
}

export function daysInISOStringInterval(start: string, end: string): number {
    return daysInDateInterval(dateFromISOString(start), dateFromISOString(end));
}

export function daysInDateInterval(start: Date, end: Date): number {
    const startTimestamp = start.getTime();
    const endTimestamp = end.getTime();

    const millisecondsInterval = Math.abs(startTimestamp - endTimestamp);

    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    return Math.round(millisecondsInterval / millisecondsPerDay);
}

export function msInISOStringInterval(start: string, end: string): number {
    return msInDateInterval(dateFromISOString(start), dateFromISOString(end));
}

export function msInDateInterval(start: Date, end: Date): number {
    const startTimestamp = start.getTime();
    const endTimestamp = end.getTime();

    const millisecondsInterval = Math.abs(startTimestamp - endTimestamp);
    return millisecondsInterval;
}

export function dateFromISOString(str: string): Date {
    return new Date(Date.parse(str))
}
