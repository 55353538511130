import React from 'react';
import { HeaderContainer } from './styles';
import { IconLogo } from 'app/presentation/components/icons';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

export const Header: React.FC = () => {
    const { darkmode } = useDarkMode();

    return (
        <HeaderContainer style={{ background: darkmode ? '#000' : '' }}>
            <nav className="content">
                <IconLogo fill={darkmode ? '#fff' : '#1A202C'} />
                <div className="clear"></div>
            </nav>
        </HeaderContainer>
    );
};
