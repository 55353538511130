import styled from 'styled-components';

export const Header = styled.div`
    display: none;
    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.5em;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    .beta {
        min-width: 82px;
        min-height: 20px;
        background: #fbeeb6;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-left: 12px;
    }

    @media (min-width: 64em) {
        padding: 0px 40px 0px 40px;
        position: relative;
        display: flex;
        justify-content: space-between;

        h1 {
            position: absolute;
            top: -55px;
        }
    }

    &.headerChatDark {
        border-color: #1d1d1f !important;

        h1 {
            color: #ffffff;
            span {
                color: #000;
            }
        }
    }
`;

export const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 64em) {
        flex-direction: row;

        #content {
            height: calc(100vh - 80px);
            overflow: auto;
            flex: 1;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            ::-webkit-scrollbar-thumb {
                background: #edf2f7;
                border-radius: 50px;
            }
        }
    }
`;

export const ProgressContainer = styled.div`
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
