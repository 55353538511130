import { CircularProgress } from '@mui/material';
import React from 'react';
import { ButtonThemeVariants } from 'styles/themes/themes';
import * as S from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant: ButtonThemeVariants;
    children: React.ReactNode;
    loading?: boolean;
    loadingSize?: string;
    fullStyled?: boolean;
    width?: string;
}

export const Button: React.FC<Props> = ({
    children,
    loading,
    onClick,
    loadingSize = '1rem',
    fullStyled = false,
    width,
    ...props
}) => {
    const [onClickLoading, setOnClickLoading] = React.useState<boolean>(false);
    const onClickWrapper = async (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        if (!onClick) {
            return;
        }
        setOnClickLoading(true);
        try {
            await onClick(event);
        } catch (err) {
        } finally {
            setOnClickLoading(false);
        }
    };
    return (
        <S.Button
            {...props}
            fullStyled={fullStyled}
            width={width}
            onClick={onClickWrapper}
        >
            {loading || onClickLoading ? (
                <CircularProgress size={loadingSize} color="inherit" />
            ) : (
                children
            )}
        </S.Button>
    );
};
