import React from 'react';
import {
    ContainerLogin,
    LoginAlternatives,
    OrRegister,
    Form,
    DesktopLayout,
    Showcase,
} from './styles';
import {
    Container,
    Input,
    CheckBox,
    FormControlLabel,
    Button,
} from 'app/presentation/components';
import { IconGoogle, IconLogo } from 'app/presentation/components/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import showCase from 'assets/images/png/showcase.png';
import { AuthContext } from 'app/presentation/contexts/AuthContext';
import { auth, signInWithGoogle } from 'config/firebaseSetup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { routeTranslate as t } from 'utils/intl';
import { useSnackbar } from 'notistack';
import useLoading from 'app/presentation/hooks/useLoading';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneIcon from '@mui/icons-material/Done';
import { UsersService } from '../../services/users';
import translations from 'i18n/translations.json';
import useIntlConfig from '../../app/presentation/hooks/useIntlConfig';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { getToken } from 'services/api';
import { WorkspacesService } from 'services/workspaces';
import { Workspace } from 'app/presentation/hooks/interfaces/workspace';

const usersService = new UsersService();

declare global {
    interface Window {
        jarbas: any;
    }
}

const SignIn = () => {
    const { register, handleSubmit } = useForm<FieldValues>();
    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const intl = useIntl();
    const { setLanguage } = useIntlConfig();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { showLoading, closeLoading } = useLoading();
    const { darkmode } = useDarkMode();
    const [searchParams] = useSearchParams();
    const fallback = searchParams.get('fallback');

    const user = React.useContext(AuthContext);

    const userNotFound = () => {
        enqueueSnackbar(t(intl, 'tooltip.wrongPassUser'), { variant: 'error' });
    };

    const passwordWrong = () => {
        enqueueSnackbar(t(intl, 'tooltip.wrongPassUser'), { variant: 'error' });
    };

    const currentYear = new Date(Date.now()).getFullYear();

    React.useEffect(() => {
        const fetchUser = async () => {
            return usersService.getOne();
        };

        if (user) {
            fetchUser().then(data => {
                const keyTyped = data.user_settings.language.lang as keyof typeof translations;
                setLanguage(keyTyped);

                if (fallback) {
                    getToken().then(token => {
                        const jarbasController = window.jarbas;
                        if (!jarbasController) {
                            console.log('Jarbas is not available');
                        }

                        jarbasController.socialLogin(token);
                    });
                    return;
                }

                const service = new WorkspacesService();

                service.getAll().then((workspaces: Workspace[]) => {
                    const workspace = workspaces.find(workspace => workspace.has_active_plan);

                    if (workspace && workspace.has_old_plan) {
                        navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.backoffice')}${t(intl, 'route.home')}`);
                        return;
                    }

                    navigate(`/${translations[keyTyped]['route.urlLang']}${translations[keyTyped]['route.agent.home']}`);
                    return;
                }).catch(() => {
                    navigate(`/${translations[keyTyped]['route.urlLang']}${translations[keyTyped]['route.agent.home']}`);
                    return;
                })
            }).catch(() => console.log());
        }
    }, [user]);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const signIn: SubmitHandler<FieldValues> = async data => {
        try {
            setLoading(true);

            showLoading();

            await auth.signInWithEmailAndPassword(data.email, data.password);

            await usersService.getOne().then(data => {
                const keyTyped = data.user_settings.language.lang as keyof typeof translations;
                setLanguage(keyTyped);

                const inviteReply = localStorage.getItem('inviteReply');
                const inviteToken = localStorage.getItem('inviteToken');

                if (inviteReply && inviteToken) {
                    navigate(`${translations[keyTyped]['route.urlLang']}/workspaces/invites/${inviteToken}?reply=${inviteReply}`);
                    return;
                }

                navigate(`/${translations[keyTyped]['route.urlLang']}${translations[keyTyped]['route.agent.home']}`);
            })
                .catch(e => {
                    console.log(e);
                    setLoading(false);
                });
        } catch (error: any) {
            if (error.code === 'auth/user-not-found') {
                userNotFound();
                setLoading(false);
            } else if (error.code === 'auth/wrong-password') {
                passwordWrong();
                setLoading(false);
            }
        } finally {
            closeLoading();
        }
    };
    return (
        <DesktopLayout>
            <ContainerLogin className={darkmode ? 'containerLoginDark' : ''}>
                <Container>
                    <div id="form-login">
                        <header>
                            <IconLogo fill={darkmode ? '#fff' : ''} />
                        </header>
                        <div>
                            <div className="center">
                                <FormattedMessage
                                    tagName={'h1'}
                                    id="signin.title"
                                />
                                <p className="tagline">
                                    <FormattedMessage id="signin.subtitle" />
                                </p>
                                <LoginAlternatives
                                    text={t(intl, 'signin.signinWith')}
                                    className={darkmode ? 'alternativesDark' : ''}
                                >
                                    <button
                                        onClick={() => { signInWithGoogle(); }}
                                        className="background"
                                    >
                                        <IconGoogle size={22} />
                                        <span>Google</span>
                                    </button>
                                </LoginAlternatives>
                            </div>
                            <Form onSubmit={handleSubmit(signIn)}>
                                <OrRegister>
                                    <hr />
                                    <span
                                        className="background"
                                        style={{
                                            background: darkmode ? '#000' : '',
                                            color: darkmode ? '#A09FA5' : '',
                                        }}
                                    >
                                        <FormattedMessage id="signin.orEmail" />
                                    </span>
                                </OrRegister>
                                <Input
                                    id="email"
                                    type="email"
                                    placeholder={t(intl, 'signin.placeholderEmail')}
                                    required
                                    register={register}
                                />
                                <div id="password-field">
                                    <Input
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder={t(intl, 'signin.placeholderPassword')}
                                        required
                                        register={register}
                                    />
                                    {showPassword ? (
                                        <AiOutlineEye
                                            style={{ color: darkmode ? '#A09FA5' : '#718096' }}
                                            size={22}
                                            onClick={handleShowPassword}
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            style={{ color: darkmode ? '#A09FA5' : '#718096' }}
                                            size={22}
                                            onClick={handleShowPassword}
                                        />
                                    )}
                                </div>
                                <div id="options">
                                    <div id="remember">
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                    checkedIcon={
                                                        <DoneIcon style={{ backgroundColor: '#d0ff61', borderRadius: 4 }} />
                                                    }
                                                    sx={{ color: '#E2E8F0', '&.Mui-checked': { color: '#1A202C' } }}
                                                />
                                            }
                                            label={
                                                <span id="remember-text">
                                                    <FormattedMessage id="signin.remember" />
                                                </span>
                                            }
                                        />
                                    </div>
                                    <Link
                                        title={t(intl, 'signin.forgotPassword')}
                                        to={`/${t(intl, 'route.urlLang')}${t(intl, 'route.forgotPassword')}`}
                                    >
                                        <FormattedMessage id="signin.forgotPassword" />
                                    </Link>
                                </div>
                                <Button loading={loading} type="submit">
                                    <FormattedMessage id="signin.submitButton" />
                                </Button>
                            </Form>
                            <div id="call-user" className="desktop">
                                <p>
                                    <FormattedMessage id="signin.notHaveAccount" />
                                    <Link
                                        title={t(intl, 'signin.registerCall')}
                                        to={`/${t(intl, 'route.urlLang')}${t(intl, 'route.signup')}`}
                                    >
                                        <FormattedMessage id="signin.registerCall" />
                                    </Link>
                                </p>
                            </div>
                        </div>
                        <div id="call-user" className="mobile">
                            <p>
                                <FormattedMessage id="signin.notHaveAccount" />
                                <Link
                                    title={t(intl, 'signin.registerCall')}
                                    to={`/${t(intl, 'route.urlLang')}${t(intl, 'route.signup')}`}
                                >
                                    <FormattedMessage id="signin.registerCall" />
                                </Link>
                            </p>
                        </div>
                        <div id="footer">
                            <a
                                href="https://www.jarbas.ai/privacy"
                                title={t(intl, 'footer.privacy')}
                            >
                                <FormattedMessage id="footer.privacy" />
                            </a>
                            <a href="#jarbas">© {currentYear} Jarbas.AI</a>
                        </div>
                    </div>
                </Container>
            </ContainerLogin>
            <Showcase className={darkmode ? 'showcaseDark' : ''}>
                <div id="background">
                    <div>
                        <img
                            src={showCase}
                            title={t(intl, 'showcase.title')}
                            alt={t(intl, 'showcase.title')}
                        />
                        <FormattedMessage tagName="h1" id="showcase.title" />
                        <FormattedMessage tagName="p" id="showcase.text" />
                    </div>
                </div>
            </Showcase>
        </DesktopLayout>
    );
};

export default SignIn;
