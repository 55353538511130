import React from 'react';
import { Final } from '../styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { AudioService } from 'services/audio';
import { modelList } from '..';
import { TbAlertTriangle, TbCheck, TbX } from 'react-icons/tb';
import Loading from 'app/presentation/components/common/Loading';
import {
    AudioTranscriptionAction,
    Transcript,
    TranscriptIds,
} from 'interfaces/audio-ai.interface';
import ReactInterval from 'react-interval';
import AudioPlay from 'app/presentation/components/dashboard/AudioPlayer';
import { FormattedMessage, useIntl } from 'react-intl';
import YouTubePlayer from 'app/presentation/components/dashboard/YtPlayer';

interface IFinalView {
    audioId: number;
}

const audioService = new AudioService();

const FinalView: React.FC<IFinalView> = ({ audioId }) => {
    const { darkmode } = useDarkMode();
    const { locale } = useIntl();
    const isBr = locale === 'pt-BR';

    const [data, setData] = React.useState<Transcript>();
    const [selected, setSelected] = React.useState<string>();
    const [needCall, setNeedCall] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(true);
    const [selectedData, setSelectedData] =
        React.useState<AudioTranscriptionAction>();
    const [transcription, setTranscription] = React.useState<any>();
    const [selectedDataResult, setSelectedDataResult] = React.useState<any>();
    const isTranscriptionDone = data && data.text.length > 0;

    const checkStatus = async (res: Transcript) => {
        if (res.status === 'done') {
            setNeedCall(false);
            return;
        }
        const result = await audioService.getStatus(audioId);

        if (result instanceof Error) {
            return;
        }
        setData(result);
    };

    const firstCall = async () => {
        if (!data) {
            const result = await audioService.getStatus(audioId);

            if (result instanceof Error) {
                return;
            }
            setData(result);
            const parsed = JSON.parse(result.text);
            setTranscription(parsed);
        }
    };

    React.useEffect(() => {
        firstCall();
    }, []);

    React.useEffect(() => {
        if (data) {
            if (data.status !== 'done') {
                setNeedCall(true);
            } else {
                setShowAlert(false);
            }

            if (data.text !== '') {
                setSelected('transcription');
            }
        }
    }, [data]);

    React.useEffect(() => {
        if (selected === TranscriptIds.transcription && data) {
            const customData: AudioTranscriptionAction = {
                id: -1,
                created_at: data.created_at,
                result: data.text,
                status: data.status,
                type: TranscriptIds.transcription,
                updated_at: data.updated_at,
            };

            setSelectedData(customData);
        } else if (data) {
            const selectedItem = data.audio_transcription_actions.find(
                item => item.type === selected,
            );

            if (selectedItem) {
                setSelectedData(selectedItem);
            }
        }
    }, [selected]);

    React.useEffect(() => {
        if (selectedData && selectedData.result) {
            try {
                const res = JSON.parse(selectedData.result);

                setSelectedDataResult(res);
            } catch (error) {
                console.log(error);
                setSelectedDataResult(selectedData.result);
            }
        }
    }, [selectedData]);

    return (
        <Final className={darkmode ? 'darkFinal' : ''}>
            {data && (
                <ReactInterval
                    enabled={needCall}
                    callback={() => checkStatus(data)}
                    timeout={8000}
                />
            )}
            {showAlert && (
                <div className="alertContainer">
                    <TbAlertTriangle size={18} />
                    <p>
                        <FormattedMessage id="audioai.step3.warning" />
                    </p>

                    <button onClick={() => setShowAlert(false)}>
                        <TbX size={18} />
                    </button>
                </div>
            )}

            <div className="contentText">
                <div className="listContainer">
                    <div
                        className={`listItem ${
                            selected === modelList[0].id && 'selectedItem'
                        }
                    ${isTranscriptionDone && 'doneItem'}
                    ${!isTranscriptionDone && 'itemPending'}`}
                        onClick={() => setSelected(modelList[0].id)}
                        style={{
                            pointerEvents: isTranscriptionDone ? 'all' : 'none',
                        }}
                    >
                        <div className="icon">
                            {isTranscriptionDone &&
                            selected === modelList[0].id ? (
                                <TbCheck className="checked-icon" />
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                >
                                    <circle
                                        cx="9"
                                        cy="9"
                                        r="8.5"
                                        stroke="#E2E8F0"
                                    />
                                </svg>
                            )}
                        </div>
                        <div className="text">
                            <p>{modelList[0].title}</p>

                            {selected && selected === modelList[0].id && (
                                <span>{modelList[0].description}</span>
                            )}
                        </div>

                        {!isTranscriptionDone && (
                            <div className="loading">
                                <Loading
                                    open={true}
                                    absolute={false}
                                    size={20}
                                />
                            </div>
                        )}
                    </div>
                    {data &&
                        data.audio_transcription_actions.length > 0 &&
                        data.audio_transcription_actions.map(item => {
                            const itemData = modelList.find(
                                itemModel => itemModel.id === item.type,
                            );

                            const status = item.status;
                            return (
                                <div
                                    className={`listItem ${
                                        selected === item.type && 'selectedItem'
                                    }                       
                                ${status === 'done' && 'doneItem'}         
                                ${status === 'pending' && 'itemPending'}`}
                                    onClick={() => setSelected(item.type)}
                                    style={{
                                        marginTop: 12,
                                        pointerEvents:
                                            status !== 'done' ? 'none' : 'all',
                                    }}
                                >
                                    <div className="icon">
                                        {status === 'done' &&
                                        selected === item.type ? (
                                            <TbCheck className="checked-icon" />
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="9"
                                                    cy="9"
                                                    r="8.5"
                                                    stroke="#E2E8F0"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="text">
                                        <p>{itemData?.title}</p>

                                        {selected && selected === item.type && (
                                            <span>{itemData?.description}</span>
                                        )}
                                    </div>

                                    {status !== 'done' && (
                                        <div className="loading">
                                            <Loading
                                                open={true}
                                                absolute={false}
                                                size={20}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
                <div className="resultTextContainer">
                    {selectedData && selectedDataResult && (
                        <div>
                            {Array.isArray(selectedDataResult) ? (
                                selectedDataResult.map((item: any, i) => {
                                    const type = selectedData.type;

                                    if (
                                        type ===
                                        TranscriptIds.paragraph_summarization
                                    ) {
                                        const paragraph = transcription[i];

                                        return (
                                            <div className={type} key={item.id}>
                                                <p>{paragraph.text}</p>
                                                <div className="summary">
                                                    <span>
                                                        <strong>
                                                            resumo:{' '}
                                                        </strong>
                                                        {item.summary}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    }

                                    if (
                                        type === TranscriptIds.keyword_detection
                                    ) {
                                        const paragraph = transcription[i];

                                        return (
                                            <div className={type} key={item.id}>
                                                <p>{paragraph.text}</p>
                                                <div className="keywords">
                                                    <span>
                                                        <strong>
                                                            tópicos:{' '}
                                                        </strong>
                                                        "{item.keywords}"
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    }

                                    if (
                                        type ===
                                        TranscriptIds.sentiment_analysis
                                    ) {
                                        const paragraph = transcription[i];

                                        return (
                                            <div className={type} key={item.id}>
                                                <p>{paragraph.text}</p>
                                                <div className="sentiment">
                                                    <span>
                                                        <strong>
                                                            sentimento:{' '}
                                                        </strong>{' '}
                                                        "{item.sentiment}"
                                                        {item.emoji}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    }

                                    if (
                                        type ===
                                        TranscriptIds.speakers_detection
                                    ) {
                                        const paragraph = transcription[i];

                                        return (
                                            <div className={type} key={item.id}>
                                                <p>
                                                    <span
                                                        className={item.speaker?.replace(
                                                            ' ',
                                                            '',
                                                        )}
                                                        style={{
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {isBr
                                                            ? item.speaker?.replace(
                                                                  'Speaker',
                                                                  'Locutor',
                                                              )
                                                            : item.speaker}
                                                        :
                                                    </span>{' '}
                                                    {paragraph.text}
                                                </p>
                                                <br />
                                            </div>
                                        );
                                    }

                                    if (
                                        type ===
                                        TranscriptIds.important_sentences
                                    ) {
                                        const paragraph = transcription[i];

                                        return (
                                            <div className={type} key={item.id}>
                                                <p>{paragraph.text}</p>
                                                <div className="sentences">
                                                    <span>
                                                        <strong>
                                                            frase importante:{' '}
                                                        </strong>{' '}
                                                        "{item.context}"
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    }

                                    if (type === TranscriptIds.translation) {
                                        const paragraph = transcription[i];

                                        return (
                                            <div className={type} key={item.id}>
                                                <div className="original">
                                                    <p>{paragraph.text}</p>
                                                </div>

                                                <div className="translated">
                                                    <p>{item.text}</p>
                                                </div>
                                            </div>
                                        );
                                    }

                                    return (
                                        <div className={type} key={item.id}>
                                            <p>
                                                {item.summary ||
                                                    item.text ||
                                                    item.context ||
                                                    item.keywords}
                                            </p>
                                            <br />
                                        </div>
                                    );
                                })
                            ) : (
                                <p>{selectedDataResult}</p>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {data && data.youtube_url.length === 0 && (
                <AudioPlay url={data.audio_url} />
            )}

            {data && data.youtube_url.length > 0 && (
                <>
                    <YouTubePlayer youtubeUrl={data.youtube_url} />
                </>
            )}
        </Final>
    );
};

export default FinalView;
