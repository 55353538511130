import styled from 'styled-components';

export const ContainerInput = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
    position: relative;

    .icon-count {
        display: none;
        min-width: 30px;
        min-height: 30px;
        background: #edf2f7;
        border-radius: 1000px;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 5px;

        font-size: 1em;
        color: #718096;
        font-weight: 600;
    }

    .icon-add {
        background: #ffffff;
        border: 1px solid #edf2f7;
        border-radius: 4px;
    }

    .icon-remove {
        background: #edf2f7;
        border-radius: 4px;
        margin-left: 14px;
    }

    .icon-add,
    .icon-remove {
        min-width: 42px;
        min-height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .content-input {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        justify-content: space-between;
        width: 100%;
    }

    .content-input {
        label {
            display: none;
        }
    }

    .content-input.first {
        label {
            display: block !important;
        }
    }

    @media (min-width: 64em) {
        flex-direction: column;

        .icon-add {
            position: absolute;
            right: 24px;
        }

        .icon-count {
            display: flex;
        }

        .content-input.first {
            label {
                position: relative;
                left: -34px;
            }
        }
    }
`;
