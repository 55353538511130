import styled from 'styled-components';

export const ContainerFull = styled.div`
    float: left;
    width: 100%;
    min-height: calc(100vh - 80px - var(--footer-height));
    display: flex;
    align-items: center;
    background-color: #fafafa;

    &.containerDark {
        background-color: #000;
        form {
            background-color: #1d1d1f;

            p {
                color: #a09fa5;
            }

            input {
                background: #3a3a3a !important;
                border-color: #3a3a3a !important;
                color: #fff;

                &:focus {
                    border: 1px solid #fff !important;
                }
            }
        }
    }
`;

export const Form = styled.form`
    width: 100%;
    max-width: 427px;
    margin: 0 auto;
    background-color: #fff;
    padding: 32px 24px 32px 24px;
    border-radius: 16px;

    .inputConfirmPass {
        margin-top: 40px;
    }

    .sendEmail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            text-align: center;
            margin-top: 8px;
        }
    }

    > h1,
    > p {
        text-align: center;
    }

    > h1 {
        margin-bottom: 12px;
    }

    button {
        margin-top: 24px;
    }

    a {
        display: block;
        text-align: center;
    }

    > p {
        margin-bottom: 24px;
        color: #718096;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.2px;
    }

    #password-field {
        position: relative;

        svg {
            position: absolute;
            right: 18px;
            top: 20px;
            cursor: pointer;
        }
    }

    #remember {
        display: flex;
        align-items: center;
    }

    #options {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        #remember-text {
            font-weight: 500;
        }
    }
`;
