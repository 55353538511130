import React from 'react';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import Tooltip from '@mui/material/Tooltip';

type LikeProps = {
    unliked?: boolean;
    darkmode?: boolean;
};

export const UnLike: React.FC<LikeProps> = ({ unliked, darkmode = false }) => {
    const intl = useIntl();
    return (
        <Tooltip
            title={routeTranslate(intl, 'tooltip.unlike')}
            placement="top"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.5114 8.61861C16.8067 9.00885 16.9702 9.48697 16.9702 9.98444C16.9702 10.7737 16.529 11.5208 15.8188 11.9374C15.636 12.0446 15.4279 12.101 15.2159 12.1008H11.0007L11.1062 14.2612C11.1308 14.7833 10.9462 15.279 10.5876 15.6569C10.4116 15.8432 10.1993 15.9914 9.96378 16.0924C9.72825 16.1933 9.47452 16.245 9.21826 16.244C8.3042 16.244 7.49561 15.6288 7.25303 14.7481L5.74307 9.28131H3.47021C3.15908 9.28131 2.90771 9.02994 2.90771 8.71881V2.32037C2.90771 2.00924 3.15908 1.75787 3.47021 1.75787H14.0399C14.2017 1.75787 14.3599 1.78951 14.5058 1.8528C15.3425 2.20963 15.8821 3.02701 15.8821 3.93404C15.8821 4.15553 15.8505 4.3735 15.7872 4.58444C16.0825 4.97467 16.246 5.4528 16.246 5.95026C16.246 6.17174 16.2144 6.38971 16.1511 6.60065C16.4464 6.99088 16.6099 7.46901 16.6099 7.96647C16.6063 8.18795 16.5747 8.40768 16.5114 8.61861ZM4.17334 3.0235V8.01569H5.59717V3.0235H4.17334ZM15.3601 9.22858L14.9751 8.89459L15.2194 8.44811C15.2999 8.30103 15.3417 8.13589 15.3407 7.96822C15.3407 7.67819 15.2142 7.40221 14.9962 7.21237L14.6112 6.87838L14.8556 6.4319C14.9361 6.28482 14.9778 6.11968 14.9769 5.95201C14.9769 5.66197 14.8503 5.386 14.6323 5.19615L14.2474 4.86217L14.4917 4.41569C14.5722 4.26861 14.6139 4.10347 14.613 3.9358C14.613 3.54205 14.381 3.18697 14.0224 3.02526H6.72217V8.07194L8.47119 14.4088C8.51629 14.5713 8.61311 14.7146 8.74696 14.817C8.88082 14.9195 9.04443 14.9755 9.21299 14.9766C9.34658 14.9766 9.47842 14.938 9.58389 14.8588C9.75791 14.7288 9.85107 14.5319 9.84053 14.3227L9.67178 10.8352H15.1983C15.5112 10.6436 15.7046 10.3202 15.7046 9.98444C15.7046 9.6944 15.578 9.42018 15.3601 9.22858Z"
                    fill={unliked ? (darkmode ? '#fff' : '#000000') : '#A0AEC0'}
                />
            </svg>
        </Tooltip>
    );
};
