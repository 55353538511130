import React from 'react';
import IntlProviderConfigured from './app/presentation/providers/IntlProvider';
import AuthProvider from './app/presentation/providers/AuthProvider';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LoadingProvider from './app/presentation/providers/LoadingProvider';
import DarkModeProvider from './app/presentation/providers/DarkModeProvider';
import { IconButton } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import { LayoutProps } from './app/presentation/layout/interfaces/layout-default-props.interface';
import TextProvider from 'app/presentation/providers/TextProvider';


const AppProviders: React.FC<LayoutProps> = ({ children }) => {
    function SnackbarCloseButton({ snackbarKey }: any) {
        const { closeSnackbar } = useSnackbar();

        return (
            <IconButton onClick={() => closeSnackbar(snackbarKey)}>
                <IoMdClose style={{ color: '#fff' }} />
            </IconButton>
        );
    }

    return (
        <IntlProviderConfigured>
            <TextProvider>
                <AuthProvider>
                    <DarkModeProvider>
                        <SnackbarProvider
                            action={snackbarKey => (
                                <SnackbarCloseButton
                                    snackbarKey={snackbarKey}
                                />
                            )}
                            maxSnack={10}
                        >
                            <LoadingProvider>{children}</LoadingProvider>
                        </SnackbarProvider>
                    </DarkModeProvider>
                </AuthProvider>
            </TextProvider>
        </IntlProviderConfigured>
    );
};

export default AppProviders;
