import React from 'react';
import { MdAdd } from 'react-icons/md';
import * as S from './styles';
import { Container } from '../../../app/presentation/components';
import {
    ModalNewAgent,
    ModalDeleteAgent,
    Button,
    Paginator,
} from '../../../app/presentation/components/agent';
import LogoWhiteImg from 'assets/images/png/logo-chat-branco.png';
import UserImg from 'assets/images/jpeg/user.jpeg';
import OperatorImg from 'assets/images/jpeg/operator.jpeg';
import { IconDots, IconEdit, IconTrash } from '@tabler/icons-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import useAgent from 'app/presentation/hooks/useAgent';
import { TimeFromNow } from 'app/presentation/components/agent/TimeFromNow';
import { Agent } from 'services/agent/interfaces';
import useText from 'app/presentation/hooks/useText';
import { CircularProgress } from '@mui/material';
import { CreateYourFirstAgent } from 'app/presentation/components/icons';

const Home = () => {
    const navigate = useNavigate();
    const [editingAgent, setEditingAgent] = React.useState<Agent>();
    const [modalNewAgent, setModalNewAgent] = React.useState<boolean>(false);
    const [anchorOptsMenu, setAnchorOptsMenu] =
        React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorOptsMenu);
    const [modalDeleteAgent, setModalDeleteAgent] =
        React.useState<boolean>(false);
    const theme = React.useContext(ThemeContext);

    const {
        agents,
        getAgents,
        changeAgent,
        agentsCurrentPage: current,
        agentsTotalPages: total,
        agentsLoading,
    } = useAgent();

    const { routeGetter, textGetter } = useText();
    const headerText = textGetter('agent.pages.home.header');
    const contentText = textGetter('agent.pages.home.content');

    const agentRoutes = routeGetter('agent');

    React.useEffect(() => {
        getAgents();
    }, []);

    const onPageChange = (page: number) => {
        getAgents(page);
    };

    const openModalEditAgent = () => {
        if (editingAgent) {
            setModalNewAgent(true);
        }
        closeOptsMenu();
    };

    const openModalDeleteAgent = () => {
        if (editingAgent) {
            setModalDeleteAgent(true);
        }
        closeOptsMenu();
    };

    const closeModalDeleteAgent = () => {
        setEditingAgent(undefined);
        setModalDeleteAgent(false);
    };

    const openModalNewAgent = () => {
        setEditingAgent(undefined);
        setModalNewAgent(true);
    };

    const closeModalNewAgent = () => {
        setEditingAgent(undefined);
        setModalNewAgent(false);
    };

    const openOptsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorOptsMenu(event.currentTarget);
    };

    const closeOptsMenu = () => {
        setAnchorOptsMenu(null);
    };

    const agentInfo = async (agentID: number) => {
        navigate(agentRoutes('home', {param: agentID}, 'sources'));
    };

    const paperPropsStyle: React.CSSProperties = {
        borderRadius: '12px',
        boxShadow: '1px 1px 2px 0px #5D6A831A',
        background: theme.agent.pages.home.content.menu.background.color,
        padding: '0px',
        width: '160px',
    };

    const menuListStyle: React.CSSProperties = {
        padding: '8px 0px',
        boxShadow: '0',
        background: theme.agent.pages.home.content.menu.background.color,
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    };

    const menuItemStyle: React.CSSProperties = {
        backgroundColor: 'transparent',
        padding: '0px',
    };

    return (
        <Container className={'content-full'}>
            <S.Header>
                <h1>
                    {headerText('title')}{' '}
                    <span className="beta">{headerText('beta')}</span>
                </h1>
                <div className="header-content">
                    <div className="header-card">
                        <p className="card-title">{headerText('card.title')}</p>
                        <p className="card-text">{headerText('card.text')}</p>
                        <div className="button-container">
                            <Button
                                type="button"
                                variant="primary"
                                className="header-button"
                                onClick={async () => {
                                    openModalNewAgent();
                                }}
                            >
                                <MdAdd size={26} />
                                <span>{headerText('button.create')}</span>
                            </Button>
                            <Button
                                type="button"
                                variant="secondary"
                                className="header-button"
                            >
                                {headerText('button.tutorial')}
                            </Button>
                        </div>
                        <p className="user-agreement">
                            {headerText('card.user-agreement')}
                        </p>
                    </div>
                    <div className="header-chat">
                        <p className="chat-title">{headerText('chat.title')}</p>
                        <div className="chat-img-container">
                            <img className="user-img" src={UserImg}></img>
                            <div className="logo-img-container">
                                <img
                                    className="logo-img"
                                    src={LogoWhiteImg}
                                ></img>
                            </div>
                            <img className="user-img" src={OperatorImg}></img>
                        </div>
                        <p className="chat-subtitle">
                            {headerText('chat.subtitle')}
                        </p>
                        <div className="chat-body">
                            <div className="chat-logo-container">
                                <img
                                    className="chat-logo"
                                    src={LogoWhiteImg}
                                ></img>
                            </div>
                            <div className="chat-message-container">
                                <p className="chat-first-message">
                                    {headerText('chat.first-message')}
                                </p>
                                <p className="chat-last-message">
                                    {headerText('chat.last-message')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </S.Header>
            <S.Content>
                <div className="content-header">
                    <p className="content-title">{contentText('title')}</p>
                    <p className="content-subtitle">
                        {contentText('subtitle')}
                    </p>
                </div>
                {agentsLoading ? (
                    <div className="progress-container">
                        <CircularProgress size={40} color="inherit" />
                    </div>
                )
                : agents && agents.length == 0 ? (
                    <div className="create-your-first-agent">
                        <CreateYourFirstAgent />
                        <p className="title">{contentText('create-your-first-agent.title')}</p>
                        <p className="text">{contentText('create-your-first-agent.text')}</p>
                    </div>
                ) : (
                    <div className="agent-card-container">
                        {agents &&
                            agents.map((agent, index) => {
                                return (
                                    <S.Card
                                        key={index}
                                        actionColor={
                                            agent.identity.background_color
                                                ? agent.identity
                                                      .background_color
                                                : 'transparent'
                                        }
                                    >
                                        <div>
                                            <div className="agent-card-header">
                                                <div className="agent-img-container">
                                                    <img
                                                        src={
                                                            agent.identity
                                                                .logo_url
                                                                ? agent.identity
                                                                      .logo_url
                                                                : LogoWhiteImg
                                                        }
                                                    ></img>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="agent-card-opts-btn"
                                                    onClick={(
                                                        event: React.MouseEvent<HTMLButtonElement>,
                                                    ) => {
                                                        setEditingAgent(agent);
                                                        openOptsMenu(event);
                                                    }}
                                                >
                                                    <IconDots></IconDots>
                                                </button>
                                                <Menu
                                                    anchorEl={anchorOptsMenu}
                                                    open={open}
                                                    onClose={closeOptsMenu}
                                                    PaperProps={{
                                                        style: paperPropsStyle,
                                                    }}
                                                    MenuListProps={{
                                                        style: menuListStyle,
                                                    }}
                                                >
                                                    <MenuItem
                                                        style={menuItemStyle}
                                                    >
                                                        <S.MenuButton
                                                            onClick={
                                                                openModalEditAgent
                                                            }
                                                        >
                                                            <IconEdit></IconEdit>
                                                            <p>
                                                                {contentText(
                                                                    'button.rename',
                                                                )}
                                                            </p>
                                                        </S.MenuButton>
                                                    </MenuItem>
                                                    <MenuItem
                                                        style={menuItemStyle}
                                                    >
                                                        <S.MenuButton
                                                            onClick={
                                                                openModalDeleteAgent
                                                            }
                                                        >
                                                            <IconTrash></IconTrash>
                                                            <p>
                                                                {contentText(
                                                                    'button.delete',
                                                                )}
                                                            </p>
                                                        </S.MenuButton>
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                            <p className="agent-card-title">
                                                {agent.identity.agent_name}
                                            </p>
                                            <p className="agent-card-time">
                                                <TimeFromNow
                                                    time={
                                                        new Date(
                                                            agent.created_at,
                                                        )
                                                    }
                                                ></TimeFromNow>
                                            </p>
                                            <p className="agent-card-type">
                                                {contentText('type.support')}
                                            </p>
                                        </div>
                                        <Button
                                            type="button"
                                            variant="primary"
                                            className="agent-card-btn"
                                            onClick={async () => {
                                                return agentInfo(agent.id);
                                            }}
                                        >
                                            {contentText('button.card-button')}
                                        </Button>
                                    </S.Card>
                                );
                            })}
                    </div>
                )}
                {total > 1 && (
                    <div className="paginator-container">
                        <Paginator
                            current={current}
                            onChange={onPageChange}
                            total={total}
                        ></Paginator>
                    </div>
                )}
            </S.Content>
            <ModalNewAgent
                active={modalNewAgent}
                item_editing={editingAgent}
                handleClose={closeModalNewAgent}
            ></ModalNewAgent>
            <ModalDeleteAgent
                active={modalDeleteAgent}
                agent={editingAgent}
                handleClose={closeModalDeleteAgent}
            ></ModalDeleteAgent>
        </Container>
    );
};

export default Home;
