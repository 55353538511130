import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1200;

    &.plugin-active {
        svg.plugin-active {
            path {
                fill: #222f3e !important;
            }
        }

        .tox-toolbar__primary {
            .tox-icon svg path {
                fill: #222f3e !important;
            }
        }
    }

    .content-modal {
        background: #ffffff;
        width: 100%;
        height: 100vh;
        padding-bottom: 84px;
        overflow: auto;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            background: #edf2f7;
            border-radius: 50px;
        }

        header {
            display: flex;
            align-items: center;
            //height: 72px;
            padding: 0 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            #titles {
                display: flex;
                align-items: center;
                //width: 50%;

                div {
                    display: flex;
                    align-items: center;
                }
            }

            #back {
                min-width: 40px;
                min-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                background: #fafafa;
            }

            #change-layout {
                display: none;
            }

            svg {
                cursor: pointer;
            }

            input {
                font-size: 1.125em;
                margin-right: 37px;
                border-radius: 0;
                border: 1px solid #fff;
                padding-right: 16px;
                padding-left: 16px;

                &:focus {
                    border: 1px solid #1a202c;
                }
            }

            .icons {
                display: flex;
                align-items: center;
                margin-left: auto;
                gap: 8px;

                .icon-option {
                    display: none;
                    width: 70px;
                    font-size: 0.75em;
                    color: #a0aec0;
                }

                @-webkit-keyframes rotating /* Safari and Chrome */ {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
                @keyframes rotating {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }

                .icon {
                    width: 40px;
                    height: 40px;
                    background: #fafafa;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg path {
                        stroke: #000000;
                    }

                    &.save {
                        border: 0;
                        width: 26px;
                        height: 26px;
                        display: none;
                    }

                    &.animation-flip {
                        animation: rotating 2s linear infinite;
                        border: 0;
                    }

                    &.darkIcons {
                        background-color: #000 !important;
                    }
                }
            }
        }

        .form {
            .fields {
                background: #fafafa;
                padding-top: 19px;
                padding-bottom: 29px;
                position: relative;

                .feature-header {
                    display: none;
                }
            }

            .feature {
                display: none;
            }

            .editor {
                background: #fff;
                padding-bottom: 20px;
                position: relative;

                @media (max-width: 64em) {
                    height: 100vh;
                }

                .toolbar-editor {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 0 24px;

                    .icons {
                        color: #a0aec0;
                        width: 100%;
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        .icon-option {
                            display: block;
                            width: 70px;
                            font-size: 0.75em;
                            color: #a0aec0;
                        }

                        @-webkit-keyframes rotating {
                            from {
                                transform: rotate(0deg);
                            }
                            to {
                                transform: rotate(360deg);
                            }
                        }
                        @keyframes rotating {
                            from {
                                transform: rotate(0deg);
                            }
                            to {
                                transform: rotate(360deg);
                            }
                        }

                        .icon {
                            margin-right: 9px;
                            width: 40px;
                            height: 40px;
                            border: 1px solid #e2e8f0;
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg path {
                                stroke: #a0aec0;
                            }

                            &.save {
                                border: 0;
                                width: 26px;
                                height: 26px;
                            }

                            &.animation-flip {
                                animation: rotating 2s linear infinite;
                                border: 0;
                            }
                        }
                    }
                }

                .tox-tbtn--enabled {
                    background: #d0ff61;
                    border-radius: 4px;
                }

                .tox-toolbar__group {
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    align-items: center;
                }

                .tox-toolbar__group:nth-last-of-type(2) {
                    border-right: 0;
                }

                .tox-toolbar__group:nth-last-of-type(1) {
                    border-right: 0;
                }

                .tox-toolbar__primary {
                    > div:last-of-type {
                        margin-left: auto !important;
                        margin-right: 24px;
                        gap: 10px;

                        svg path {
                            fill: #a0aec0;
                        }

                        svg.correct-active {
                            path:first-of-type {
                                fill: #22c55e !important;
                            }

                            path {
                                fill: transparent !important;
                            }
                        }
                    }
                }

                .tox-tbtn__select-label {
                    //font-family: 'Manrope', sans-serif;
                }

                .tox .tox-tbtn--select,
                .tox .tox-tbtn {
                    margin: 0;
                }

                .tox-editor-header {
                    box-shadow: unset !important;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 12px 0;

                    span {
                        font-weight: bold;
                        font-family: 'Manrope';
                    }
                }

                .tox-tinymce {
                    border: 0;
                    border-radius: 0;
                }
            }

            input {
                border-radius: 8px;
            }
        }

        #submit {
            position: fixed;
            z-index: 5;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 24px;
            height: 84px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            #shortcut {
                display: none;
            }

            button {
                width: 100%;
                height: 48px;
                font-size: 0.875em;
                border-radius: 8px;
            }
        }
    }

    @media (min-width: 64em) {
        #back {
            background: #fff !important;
        }

        .toolbar-editor {
            display: none !important;
        }

        .editor {
            padding: 0;
        }

        #titles {
            width: 40%;
            max-width: 40%;
        }

        #change-layout {
            display: flex !important;
            width: 20%;
            max-width: 20%;
            justify-content: center;
            align-items: center;

            div:first-of-type {
                border-radius: 6px 0px 0px 6px;
            }

            div:last-of-type {
                border-radius: 0px 6px 6px 0px;
            }

            div {
                width: 40px;
                height: 35px;
                border: 1px solid #edf2f7;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.active {
                    background: #d0ff61;
                    border: 1px solid #d0ff61;
                }
            }
        }

        .icons {
            width: 40%;
            max-width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .icon {
                border: 1px solid #e2e8f0;
                background: #fff !important;

                svg path {
                    stroke: #a0aec0 !important;
                }
            }

            .icon-option,
            .save {
                display: inline-flex !important;
            }

            .dots {
                position: absolute;
                top: 63px;
                z-index: 5;
            }
        }

        .form {
            display: flex;

            > div {
                flex: 1;
                overflow: auto;

                ::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                }

                ::-webkit-scrollbar-thumb {
                    background: #edf2f7;
                    border-radius: 50px;
                }
            }
        }

        .fields {
            max-width: 339px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            position: relative;
            padding-bottom: 84px;
            height: calc(100vh - 132px);
            overflow: hidden !important;

            &.active {
                height: calc(100vh - 57px);

                max-width: 419px;
                position: relative;
                padding-top: 0 !important;
                padding-bottom: 0 !important;

                .feature-header {
                    display: flex !important;
                    align-items: center;
                    min-height: 124px;
                    padding: 20px 25px;
                    background-color: #fff;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    h1 {
                        font-size: 1.125em;
                        margin-bottom: 8px;
                        font-weight: 700;
                    }

                    p {
                        color: #718096;
                        font-size: 0.875em;
                        font-weight: 500;
                    }

                    .icon {
                        min-width: 50px;
                        max-width: 50px;
                        min-height: 50px;
                        max-height: 50px;
                        border: 1px solid #e2e8f0;
                        border-radius: 1000px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1em;
                        margin-right: 13px;
                        overflow: hidden;

                        img {
                            min-width: 50px;
                            min-height: 50px;
                        }
                    }
                }

                #toolbar {
                    background-color: #fff;
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    margin-bottom: 0 !important;

                    .header {
                        padding-top: 16px;
                        padding-bottom: 16px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        gap: 8px;

                        #clean {
                            width: 147px;
                            height: 34px;
                            border: 1px solid #e2e8f0;
                            border-radius: 6px;
                            font-size: 0.875em;
                            background: #ffffff;
                            display: flex;
                            flex-direction: row-reverse;
                            padding: 0;

                            #icon {
                                margin-left: 0 !important;
                                margin-right: 4px;
                            }
                        }

                        #quatity {
                            display: flex;

                            > div {
                                min-width: 70px;
                                max-width: 70px;
                                min-height: 48px;
                                max-height: 48px;
                                margin: 0;

                                input {
                                    min-height: 48px;
                                    max-height: 48px;
                                }
                            }

                            button {
                                margin-left: 12px;
                                width: 95px;
                                min-height: 48px;
                                padding: 0;
                                font-size: 0.875em;
                            }
                        }
                    }

                    .options {
                        padding: 9px 0;
                        display: flex;
                        align-items: center;

                        .link {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 108px;
                            height: 32px;
                            border-radius: 8px;
                            font-size: 0.75em;
                            font-weight: 600;
                            margin-right: 19px;
                            cursor: pointer;
                            color: #718096;

                            &.active {
                                background: #f7fafc;
                                color: #1a202c;
                            }
                        }

                        .option {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 108px;
                            height: 32px;
                            border-radius: 8px;
                            font-size: 0.75em;
                            font-weight: 600;
                            color: #718096;
                            cursor: pointer;

                            &.active {
                                background: #f7fafc;
                                color: #1a202c;
                            }
                        }

                        .option:last-of-type {
                            margin-left: auto;
                        }
                    }
                }

                #appetizer {
                    background-color: #fff;
                    padding: 30px 27px;
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                    margin-bottom: 0 !important;

                    .box {
                        border: 1px solid #edf2f7;
                        border-radius: 16px;
                        padding: 24px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        p {
                            font-size: 0.875em;
                            line-height: 150%;
                            font-weight: 500;
                        }

                        .footer {
                            margin-top: 32px;
                            display: flex;
                            justify-content: space-between;

                            span {
                                font-size: 0.75em;
                                font-weight: 500;
                                color: #718096;
                            }

                            .option-box {
                                display: flex;
                                align-items: center;
                                gap: 16px;

                                div {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                                div:nth-last-of-type(1) svg,
                                div:nth-last-of-type(2) svg {
                                    path {
                                        stroke: #a0aec0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .feature.active {
            display: block !important;
            flex: 1;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            max-width: 339px;
            height: calc(100vh - 57px);
            overflow: hidden;

            .form-feature {
                padding: 13px 14px 0 14px;
            }

            > h1 {
                font-size: 0.875em;
                margin: 15px 0;
                padding: 0 16px;
            }

            ul {
                max-height: calc(100vh - 57px - 19px - 69px - 30px);
                overflow: auto;

                ::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                }

                ::-webkit-scrollbar-thumb {
                    background: #edf2f7;
                    border-radius: 50px;
                }

                li {
                    padding: 13px 14px;
                    display: flex;
                    height: 56px;
                    align-items: center;
                    cursor: pointer;

                    &.active {
                        background: #d0ff61;
                    }
                }

                h1 {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .icon {
                width: 32px;
                height: 32px;
                background: #f7fafc;
                border: 1px solid #e2e8f0;
                border-radius: 1000px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                margin-right: 16px;

                overflow: hidden;

                img {
                    min-width: 32px;
                    min-height: 32px;
                }
            }
        }

        .formFeatDark {
            .feature {
                .form-feature {
                    input {
                        background: #1d1d1f;
                        color: #fff;

                        &:focus {
                            border: 1px solid #fff !important;
                        }
                    }
                }
                ul {
                    .active {
                        h1 {
                            color: #000;
                        }
                    }
                }
            }

            .fields {
                .feature-header {
                    background: #000;
                    border-bottom: 1px solid #1d1d1f;

                    p {
                        color: #cccccc;
                    }
                }

                form {
                    textarea {
                        background: #1d1d1f;
                        color: #fff;
                        border-color: #1d1d1f;

                        &:focus {
                            border: 1px solid #fff !important;
                        }
                    }
                    input {
                        background: #1d1d1f;
                        color: #fff;

                        &:focus {
                            border: 1px solid #fff !important;
                        }
                    }
                }
            }
        }

        .editor {
            background: #fff;
            padding-bottom: 20px;
            height: calc(100vh - 57px);

            .tox-tbtn--enabled {
                background: #d0ff61;
                border-radius: 4px;
            }

            .tox-toolbar__primary {
                > div:last-of-type {
                    margin-left: auto !important;
                    margin-right: 24px;
                }
            }

            .tox-tbtn__select-label {
                //font-family: 'Manrope', sans-serif;
            }

            .tox-editor-header {
                box-shadow: unset !important;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                padding-right: 50px !important;
            }

            .tox-tinymce {
                border: 0;
                border-radius: 0;
            }

            &.active {
                flex: 1;
                padding-bottom: 90px;
                height: calc(100vh - 57px);
            }
        }

        #submit {
            position: absolute;
            max-width: 339px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            bottom: 0;
            z-index: 5;

            button {
                width: 100%;
                min-width: 279px;
                min-height: 60px;
                font-size: 1em;
                border-radius: 8px;
            }

            #shortcut {
                display: inline-flex !important;
                align-items: center;
                justify-content: center;
                width: 87px;
                height: 32px;
                background: #98df50;
                border-radius: 8px;
                padding: 8px;
                margin-left: 10px;

                &.disabled {
                    background: #eee !important;
                }

                span {
                    font-size: 0.75em;
                    font-weight: bold !important;
                }
            }

            &.active {
                position: absolute;
                min-width: 100%;
                left: inherit;
                right: 0;
                border: 0;

                button {
                    width: 100%;
                    min-width: 279px;
                    max-width: 279px;
                    height: 48px;
                    font-size: 1em;
                    border-radius: 8px;
                }
            }
        }

        .content-modal {
            padding-bottom: 0 !important;

            header {
                input {
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }
    }

    .darkContent {
        .editor {
            background: #222f3e !important;
        }

        #title-document {
            input {
                &:focus {
                    border: 1px solid #fff !important;
                }
            }
        }

        .icons {
            .icon {
                border: 1px solid #e2e8f0;
                background: #222f3e !important;

                svg path {
                    stroke: #fff !important;
                }
            }
        }

        background: #000000 !important;

        input {
            background: #000;
            color: #fff;
            border: none !important;
        }

        #back {
            background: #000 !important;
        }

        #change-layout {
            div {
                background: #1d1d1f;

                &.active {
                    background: #d0ff61;
                    border: 1px solid #d0ff61;
                }
            }
        }
    }
`;

export const Tags = styled.div``;

export const LabelCustom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .toggle {
        width: 44px;
        height: 20px;
        background: #edf2f7;
        border-radius: 1000px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.active {
            justify-content: flex-end;
            background: #000000;

            > div {
                border: 2px solid #000000;
            }
        }

        > div {
            width: 20px;
            height: 20px;
            background: #ffffff;
            border-radius: 1000px;
            border: 2px solid #edf2f7;
        }
    }
`;

export const MuiMenuHeader = styled.div`
    .menu-mui-header-workspace {
        margin-top: 9px;
        padding-bottom: 17px;
        border-bottom: 2px solid #edf2f7;
        margin-bottom: 8px;

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        a {
            font-weight: 500;
            font-size: 0.75em;
            color: #194bfb;
        }
    }

    .menu-mui-header {
        margin-top: 9px;
        padding: 0 16px 16px 16px;
        border-bottom: 1px solid #edf2f7;

        #search > div {
            height: 42px;
            margin: 0;
        }

        input {
            height: 42px;
            border: 1px solid #718096;
            border-radius: 8px;
        }

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        p {
            font-weight: 500;
            font-size: 0.75em;
            color: #718096;
        }
    }

    ul.workspace {
        li {
            border-bottom: 0;
            padding: 0;
            cursor: pointer;

            span {
                font-weight: 600;
            }
        }
    }

    ul {
        list-style: none;

        li {
            cursor: pointer;
            height: 42px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            border-bottom: 1px solid #edf2f7;

            &:hover {
                background-color: #f7fafc;
            }
        }

        li:last-of-type {
            border-bottom: 0;
        }

        span {
            color: #718096;
            font-size: 0.75em;
            font-weight: 600;
            margin-left: 12px;
        }
    }
`;

export const FieldsTemplate = styled.div`
    height: calc(100vh - 181px);
    width: 100%;

    > div,
    form > div {
        padding-right: 24px;
        padding-left: 24px;
    }

    > div:last-of-type {
        margin-bottom: 0;
    }

    @media (min-width: 64em) {
        > div,
        form > div {
            margin-bottom: 12px;
        }

        &.active {
            overflow: auto !important;
            padding-top: 40px;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            ::-webkit-scrollbar-thumb {
                background: #edf2f7;
                border-radius: 50px;
            }

            > div,
            form > div {
                margin-bottom: 32px !important;

                label {
                    font-size: 0.875em !important;
                }
            }
        }
    }
`;

export const FieldsDocument = styled.div`
    overflow: auto;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        background: #edf2f7;
        border-radius: 50px;
    }

    max-height: calc(100vh - 56px - 84px - 19px - 5px);

    > div,
    form > div {
        padding-right: 24px;
        padding-left: 24px;

        label {
            font-size: 0.875em !important;
        }
    }

    > div:last-of-type {
        margin-bottom: 0;
    }

    &.darkFieldsDocument {
        background: #000;

        textarea {
            background: #1d1d1f;
            color: #fff;
            border-color: #1d1d1f;

            &:focus {
                border-color: #fff;
            }
        }

        input {
            background: #1d1d1f !important;
            color: #fff;
            border-color: #1d1d1f !important;

            &:focus {
                border-color: #fff !important;
            }
        }

        .toggle {
            background: #1d1d1f;

            &.active {
                background: #22c55e;

                > div {
                    border: 2px solid #22c55e;
                    background: #fff;
                }
            }

            > div {
                background: #a09fa5;

                border: 2px solid #1d1d1f;
            }
        }
    }
`;
