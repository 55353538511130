import styled from 'styled-components';

export const FadeBackground = styled.div`
    background-color: rgba(17, 21, 29, 0.5);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContainer = styled.div`
    width: 496px;
    height: 234px;
    background-color: white;
    border-radius: 15px;
`;

export const ModalContent = styled.div`
    margin-top: 42px;
    margin-left: 26px;
    margin-right: 38px;

    button {
        cursor: pointer;
    }

    .span-line {
        margin-top: 12px;
        display: flex;

        span {   
            text-align: center;
            color: rgba(160, 159, 165, 1);
            font-size: 14px;
        }
    }



    .header-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //margin-left: 15%;

        h1 {
            font-size: 20px;
            font-weight: 700;
            margin-left: auto;
            margin-right: auto;
        }

        button {
            width: 22px;
            height: 22px;
            border: 0;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -10%;
            margin-right: -5%;
        }
    }

    .btn-line {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        button {
            height: 48px;
            font-size: 14px;
            font-weight: 700;
            border: 0;
            border-radius: 8px;
        }

        .cancel {
            width: 111px;
            background-color: rgba(241, 241, 241, 1);
        }

        .danger {
            width: 180px;
            background-color: rgba(221, 51, 51, 0.1);
            color: rgba(221, 51, 51, 1);

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
        }

        
    }
`;
