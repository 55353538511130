import styled from 'styled-components';

export const Header = styled.header`
    display: flex;
    align-items: center;
    height: 72px;
    margin-bottom: 24px;

    #back {
        min-width: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fafafa;
        border-radius: 8px;
        margin-right: 16px;
    }

    svg {
        cursor: pointer;
    }

    .message {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        h1 {
            font-size: 1.125em;
        }
    }

    @media (min-width: 64em) {
        margin-bottom: 32px;
        height: auto;

        #back {
            display: none;
        }

        .message {
            flex-direction: row;
            justify-content: space-between;
            h1 {
                font-size: 1.5em;
            }
        }
    }
`;
