import styled, { css } from 'styled-components';

export const ContainerModal = styled.div`
    ${({
        theme: {
            agent: {
                components: {
                    modalNewQuestion: { background, border },
                },
            },
        },
    }) => css`
        background-color: #11151deb;
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;

        .content-modal {
            background: ${background.color};
            border-radius: 16px;
            min-width: 328px;
            max-width: 328px;
            height: fit-content;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 30px;

            @media (min-width: 64em) {
                min-width: 626px;
                max-width: 626px;
            }

            .modal-header {
                display: flex;
                align-items: start;
                justify-content: space-between;
                min-height: 80px;
                border-bottom: 1px solid ${border.color};

                @media (min-width: 64em) {
                    min-height: 51px;
                }

                p {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 31.2px;
                }

                svg {
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                }
            }
            .input-container {
                display: flex;
                flex-direction: column;
                gap: 16px;

                p {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    line-height: 0.2px;
                }

                input {
                    border-radius: 8px;
                    height: 50px;
                }
                textarea {
                    height: 112px;
                }
            }
            .button-container {
                display: flex;
                justify-content: end;
                gap: 15px;

                button {
                    padding: 8px 24px;
                    height: 48px;
                    border-radius: 8px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 21px;
                    line-height: 0.2px;
                    gap: 7px;

                    svg {
                        height: 20px;
                        width: 20px;
                    }
                }

                .save-button {
                    width: 120px;
                }
            }
        }
    `}
`;
