import styled from 'styled-components';

interface TooltipProps {
    height: number;
    width: number;
}

export const Tooltip = styled.div<TooltipProps>`
    width: fit-content;
    height: fit-content;

    svg {
        max-width: ${({width}) => width}px;
        max-height: ${({height}) => height}px;
        min-width: ${({width}) => width}px;
        min-height: ${({height}) => height}px;
        color: #A09FA5;
    }
`;