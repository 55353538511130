import { colors } from '../commons/colors';
import { input } from '../commons/components';

export const pageCommons = {
    dark: {
        title: { color: colors.white },
        subtitle: { color: colors.secondary },
        background: { color: colors.gs900 },
        border: { color: colors.gs700 },
    },
    light: {
        title: { color: colors.gs900 },
        subtitle: { color: colors.secondary },
        background: { color: colors.white },
        border: { color: colors.gs300 },
    },
};

export const panelCommons = {
    dark: {
        hr: { color: colors.gs700 },
    },
    light: {
        hr: { color: colors.gs300 },
    },
};

export const behavior = {
    dark: {
        chat: {
            background: { color: colors.gs800 },
        },
        behaviorPanel: {
            menu: {
                background: { color: colors.gs800, active: '#151718' },
                border: { color: colors.gs700 },
                hr: { color: colors.gs700 },
                text: { color: colors.white },
                icon: {
                    background: { color: colors.primary },
                    text: { color: colors.gs900 },
                },
            },
            ...panelCommons.dark,
        },
        identityPanel: {
            ...panelCommons.dark,
        },
        welcomeMessagesPanel: {
            ...panelCommons.dark,
        },
        ...pageCommons.dark,
    },
    light: {
        chat: {
            background: { color: colors.white },
        },
        behaviorPanel: {
            menu: {
                background: { color: colors.white, active: '#f7fafc' },
                border: { color: colors.gs300 },
                hr: { color: colors.gs200 },
                text: { color: colors.gs900 },
                icon: {
                    background: { color: colors.primary },
                    text: { color: colors.gs900 },
                },
            },
            ...panelCommons.light,
        },
        identityPanel: {
            ...panelCommons.light,
        },
        welcomeMessagesPanel: {
            ...panelCommons.light,
        },
        ...pageCommons.light,
    },
};

export const config = {
    dark: {
        configPanel: {
            ...panelCommons.dark,
        },
        formPanel: {
            ...panelCommons.dark,
        },
        multilanguagePanel: {
            ...panelCommons.dark,
        },
        securityPanel: {
            input: input.dark,
            ...panelCommons.dark,
        },
        workingHoursPanel: {
            ...panelCommons.dark,
        },
        ...pageCommons.dark,
    },
    light: {
        configPanel: {
            ...panelCommons.light,
        },
        formPanel: {
            ...panelCommons.light,
        },
        multilanguagePanel: {
            ...panelCommons.light,
        },
        securityPanel: {
            input: input.light,
            ...panelCommons.light,
        },
        workingHoursPanel: {
            ...panelCommons.light,
        },
        ...pageCommons.light,
    },
};

export const integration = {
    dark: {
        panel: {
            border: { color: colors.white },
            text: { color: colors.white },
            agentLink: {
                border: { color: colors.gs700 },
                background: { color: colors.gs800 },
                button: {
                    border: { color: colors.gs700 },
                    background: { color: colors.gs800 },
                    icon: { color: colors.gs650 },
                },
            },
            widgetScript: {
                border: { color: colors.gs700 },
                background: { color: colors.gs800 },
            },
            ...panelCommons.dark,
        },
        ...pageCommons.dark,
    },
    light: {
        panel: {
            border: { active: colors.gs900 },
            text: { color: colors.gs650 },
            agentLink: {
                border: { color: colors.gs300 },
                background: { color: colors.gs200 },
                button: {
                    border: { color: colors.gs300 },
                    background: { color: colors.white },
                    icon: { color: colors.gs650 },
                },
            },
            widgetScript: {
                border: { color: colors.gs300 },
                background: { color: colors.gs100 },
            },
            ...panelCommons.light,
        },
        ...pageCommons.light,
    },
};

export const home = {
    dark: {
        header: {
            background: { color: colors.gs900 },
            border: { color: colors.gs700 },
            title: { color: colors.white },
            text: { color: colors.gs600 },
            userAgreement: { color: colors.white },
            chat: {
                background: { color: colors.gs800 },
                text: { color: colors.white },
                logo: {
                    border: { color: colors.gs700 },
                },
                message: {
                    background: { color: colors.gs200 },
                    text: { color: colors.gs900 },
                },
            },
        },
        content: {
            title: { color: colors.white },
            subtitle: { color: colors.secondary },
            card: {
                border: { color: colors.gs800 },
                background: { color: colors.gs800 },
                title: { color: colors.white },
                timeText: { color: colors.gs600 },
                text: { color: colors.secondary },
                icon: {
                    border: { color: colors.gs700 },
                },
            },
            menu: {
                background: { color: colors.gs800, active: '#151718' },
                text: { color: colors.white },
            },
        },
    },
    light: {
        header: {
            background: { color: colors.white },
            border: { color: colors.gs300 },
            title: { color: colors.gs900 },
            text: { color: colors.gs700 },
            userAgreement: { color: colors.gs900 },
            chat: {
                background: { color: colors.white },
                text: { color: colors.gs900 },
                logo: {
                    border: { color: colors.gs300 },
                },
                message: {
                    background: { color: colors.gs200 },
                    text: { color: colors.gs900 },
                },
            },
        },
        content: {
            title: { color: colors.gs900 },
            subtitle: { color: colors.secondary },
            card: {
                border: { color: colors.gs300 },
                background: { color: colors.white },
                title: { color: colors.gs900 },
                timeText: { color: colors.gs600 },
                text: { color: colors.secondary },
                icon: {
                    border: { color: colors.gs300 },
                },
            },
            menu: {
                background: { color: colors.white, active: '#f7fafc' },
                text: { color: colors.secondary },
            },
        },
    },
};
