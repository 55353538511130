import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { routeTranslate } from 'utils/intl';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../common';
import { PlansService } from 'services/plans';
import { format } from 'util';
import { intervalToDuration } from 'date-fns';
import { useSnackbar } from 'notistack';
import { IoMdClose } from 'react-icons/io';
import { sleep } from 'utils/sleep';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    workspace_id: number;
    workspaceUsage: any;
    customMessage?: boolean;
}

const plansService = new PlansService();

const ModalAntecipatePlan: React.FC<ModalProps> = ({
    active,
    handleClose,
    workspace_id,
    workspaceUsage,
    customMessage = false,
}) => {
    const intl = useIntl();
    const [loading, setLoading] = React.useState(false);
    const [end, setEnd] = React.useState('2030-05-02T23:25:37.105229Z');
    const today = new Date(Date.now());
    const endDate = new Date(end);
    const { enqueueSnackbar } = useSnackbar();

    const planEndValue =
        intervalToDuration({
            start: today,
            end: endDate,
        }).days || 0;

    const { darkmode } = useDarkMode();

    const callApi = async () => {
        const { data } = await workspaceUsage();

        if (data) {
            setEnd(data.billing_cycle.end);
        }
    };

    React.useEffect(() => {
        callApi();
    }, []);

    const handleFinishTrial = async () => {
        try {
            setLoading(true);
            const req = {
                workspace_id: workspace_id,
            };
            const data = await plansService.finishTrial(req);

            if (data === 'OK') {
                await sleep(4);
                window.location.reload();
            }
        } catch (error) {
            setLoading(false);

            enqueueSnackbar(routeTranslate(intl, 'modal.tone.error.generic'), {
                variant: 'error',
            });
        }
    };

    return (
        <Fade in={loading || active} timeout={400}>
            <ContainerModal
                onClick={() => handleClose()}
                className={darkmode ? 'listProjectsDark' : ''}
            >
                <div className="content-modal">
                    <header>
                        <IoMdClose size={25} onClick={handleClose} />
                    </header>
                    <h2>
                        <FormattedMessage
                            id={'modal.planAntecipate.title'.concat(
                                customMessage ? '.custom' : '',
                            )}
                        />
                    </h2>
                    <p>
                        {format(
                            routeTranslate(
                                intl,
                                'modal.planAntecipate.description'.concat(
                                    customMessage ? '.custom' : '',
                                ),
                            ),
                            planEndValue,
                        )}
                    </p>
                    <div className="action-buttons">
                        <Button loading={loading} onClick={handleFinishTrial}>
                            <FormattedMessage id="modal.planAntecipate.button" />
                        </Button>

                        <Button className="close" onClick={() => handleClose()}>
                            <FormattedMessage id="modal.planAntecipate.ok" />
                        </Button>
                    </div>
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalAntecipatePlan;
