import styled, { css } from 'styled-components';

export const ContainerModal = styled.div`
    ${({
        theme: {
            agent: {
                components: {
                    modalNewContent: { background },
                },
            },
        },
    }) => css`
        background-color: #11151deb;
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9998 !important;
        .content-modal {
            background: ${background.color};
            border-radius: 16px;
            min-width: 328px;
            max-width: 328px;
            padding: 20px 32px 32px 32px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            height: fit-content;
            max-height: 80vh;

            overflow-y: scroll;

            @media (min-width: 64em) {
                min-width: 847px;
                max-width: 847px;
            }

            .modal-description {
                font-weight: 400;
                font-size: 14px;
                line-height: 20.8px;
            }

            .button-container {
                display: flex;
                justify-content: end;
                gap: 15px;

                button {
                    padding: 8px 24px;
                    height: 48px;
                    border-radius: 8px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 21px;
                    line-height: 0.2px;
                }

                .save-button {
                    width: 166px;
                }
            }

            .step-2-first {
                font-size: 16px;
                font-weight: 700;
            }

            .step-2-second {
                font-size: 14px;
                font-weight: 500;
            }

            .step-2-third {
                font-weight: 500;
                ul {
                    list-style-position: inside;
                    margin-bottom: 16px;
                }
                font-size: 14px;
            }

            .extract-url-input {
                margin-bottom: 10px;
            }

            .extract-url-input-container {
                display: flex;
                height: fit-content;

                align-items: center;

                input {
                    margin-top: 5px;
                }

                gap: 4px;
                button {
                    border-radius: 8px;
                    margin-left: 8px;
                    width: 40px !important;
                    height: 40px !important;
                }

                button:nth-of-type(2) {
                    border: 1px solid #e2e8f0;
                }
            }

            .modal-header {
                display: flex;
                align-items: start;
                justify-content: space-between;
                min-height: 80px;

                @media (min-width: 64em) {
                    min-height: 30px;
                }

                p:first-of-type {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 31.2px;
                }

                svg {
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    `}
`;
