import React from 'react';
import { Content, Clear } from './styles';
import { ContainerProps } from '../../interfaces/container-props.interface';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

export const Container: React.FC<ContainerProps> = ({
    tag = 'div',
    className = null,
    bg = '',
    ...props
}) => {
    const Tag = tag;

    const { darkmode } = useDarkMode();

    return (
        <Tag
            role={props.role}
            className={`container${className ? ` ${className}` : ''}`}
            style={{ background: darkmode ? '#000' : bg }}
        >
            <Content>
                {props.children}
                <Clear />
            </Content>
        </Tag>
    );
};
