import axios from 'axios';
import { API_BASE_URL } from 'config/contants';
import { getToken } from 'services/api';
import {
    type CreateMemoryReq,
    type Memory,
    type DeleteMemoryReq,
    type GetAllMemoriesReq,
    type GetMemoryReq,
    type MemoryContentStatus,
    type GetMemoryContentsPublishStatusesReq,
    type UpdateMemoryReq
} from './types';
import { type Pagination, axiosConfig, applyQuerystringParams } from "services/commons";

const api = axios.create({
    baseURL: API_BASE_URL,
});

const servicePath = (): string => {
    return "/brand-memories";
}

export const createMemory = async (req: CreateMemoryReq): Promise<Memory> => {
    return (await api.post<Memory>(servicePath(), req, await axiosConfig(req.headers))).data;
}

export const deleteMemory = async (req: DeleteMemoryReq): Promise<void> => {
    (await api.delete(servicePath().concat(`/${req.memoryId}`), await axiosConfig(req.headers))).data;
}

export const getAllMemories = async (req: GetAllMemoriesReq): Promise<Pagination<Memory>> => {
    const headers = await axiosConfig(req.headers);
    const path = applyQuerystringParams(servicePath().concat("/list"),
        req.paginationParams, { "l": req.language_id }, req.onlyParentless ? { "p": req.onlyParentless } : {});

    return (await api.get<Pagination<Memory>>(path, headers)).data;
}

export const getMemory = async (req: GetMemoryReq): Promise<Memory> => {
    const headers = await axiosConfig(req.headers);
    const path = `${servicePath()}/${req.id}?l=${req.language_id}`

    return (await api.get<Memory>(path, headers)).data;
}

export const getMemoryContentsPublishStatuses = async (req: GetMemoryContentsPublishStatusesReq): Promise<MemoryContentStatus[]> => {
    const headers = await axiosConfig(req.headers);
    const path = `${servicePath()}/${req.id}/statuses`

    return (await api.get<MemoryContentStatus[]>(path, headers)).data;
}

export const putMemory = async (req: UpdateMemoryReq): Promise<Memory> => {
    const headers = await axiosConfig(req.headers);
    const path = `${servicePath()}/${req.memoryID}`;

    return (await api.put<Memory>(path, req.data, headers)).data;
}
export class MemoriesServices {
    private readonly path = '/brand-memories';

    async getAllGroups(workspace_id: number, project_id: number) {
        const token = await getToken();
        const { data } = await api.get(
            this.path.concat(`/groups?page=1&limit=50`),
            {
                headers: {
                    Authorization: token,
                    'X-Workspace-Id': workspace_id,
                    'X-Project-Id': project_id,
                },
            },
        );

        return data.data;
    }

    async getAllBrandMemoryGroups(
        workspace_id: number,
        project_id: number,
        brand_id: number,
    ): Promise<any[]> {
        const token = await getToken();
        const { data } = await api.get(
            this.path.concat(`/groups?page=1&limit=50`),
            {
                headers: {
                    Authorization: token,
                    'X-Workspace-Id': workspace_id,
                    'X-Project-Id': project_id,
                    'X-Brand-Id': brand_id,
                },
            },
        );

        return data.data;
    }

    async getMemories(
        workspace_id: number,
        project_id: number,
        brand_id: number,
        group_ids: Array<number>,
    ) {
        const token = await getToken();

        const { data } = await api.get(
            this.path.concat(`?brand_id=${brand_id}&group_ids=${group_ids}`),
            {
                headers: {
                    Authorization: token,
                    'X-Workspace-Id': workspace_id,
                    'X-Project-Id': project_id,
                },
            },
        );

        return data;
    }

    async createBrandMemory(
        workspace_id: number,
        project_id: number,
        brand_id: number,
        req: CreateMemoryReq,
    ): Promise<Memory> {
        const token = await getToken();

        const { data } = await api.post(this.path, req, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
                'X-Brand-Id': brand_id,
            },
        });

        return data as Memory;
    }

    async create(
        workspace_id: number,
        project_id: number,
        dataObj: URLSearchParams,
    ) {
        const token = await getToken();
        const { data } = await api.post(this.path, dataObj, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        return data;
    }

    async update(
        workspace_id: number,
        project_id: number,
        dataObj: URLSearchParams,
        memorie_id: number,
    ) {
        const token = await getToken();
        const { data } = await api.put(`${this.path}/${memorie_id}`, dataObj, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        return data;
    }

    async delete(
        workspace_id: number,
        project_id: number,
        memorie_id: number,
        brand_id: number,
    ) {
        const token = await getToken();
        const { data } = await api.delete(
            `${this.path}/${memorie_id}?brand_id=${brand_id}`,
            {
                headers: {
                    Authorization: token,
                    'X-Workspace-Id': workspace_id,
                    'X-Project-Id': project_id,
                },
            },
        );

        return data;
    }
}
