import React from "react";
import { FadeBackground, ModalContainer, ModalContent } from "./styles";
import { Fade } from '@mui/material';
import { IconTrash, IconX } from '@tabler/icons-react';
import { deleteMemory } from 'services/brand/memories';
import { DeleteMemoryReq } from 'services/brand/memories/types';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { useSnackbar } from 'notistack';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import { Memory } from 'services/brand/memories/types';
import { FormattedMessage } from 'react-intl';

type ModalDeleteMemoryProps = {
    animate: boolean;
    setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setAnimateDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;

    memory: Memory | undefined;
}

export const ModalDeleteMemory: React.FC<ModalDeleteMemoryProps> = (props) => {
    const { animate, setShowDeleteModal, setAnimateDeleteModal, memory } = props;
    const { workspace, project, activeBrand } = useUserConfig();
    const { enqueueSnackbar: toast } = useSnackbar();
    const { setLoading, fetchMemories } = useMemoriesContext();

    const handleKnowledgeDeletion = async () => {
        if (!activeBrand || !memory) {
            console.log('something bad happened');
            return;
        }

        const req: DeleteMemoryReq = {
            memoryId: memory.id,
            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: activeBrand?.id
            }
        }

        setLoading(true);

        try {
            await deleteMemory(req);
            fetchMemories();
            toast(<FormattedMessage id="brand-center.knowledge.delete.success" />, { variant: "success" });
        } catch (err: any) {
            err instanceof Error ?
                toast(<FormattedMessage id="brand-center.knowledge.delete.error" />, { variant: "error" }) :
                toast(<FormattedMessage id="commons.something-went-wrong" />, { variant: "error" })
        } finally {
            setAnimateDeleteModal(false)
            setLoading(false);
        }
    }

    return (
        <>
            <Fade in={animate} timeout={300} onExited={() => setShowDeleteModal(false)}>
                <FadeBackground>
                    <ModalContainer>
                        <ModalContent>
                            <div className="header-line">

                                <h1><FormattedMessage id="brand-center.knowledge.modal-delete.delete?" /></h1>

                                <button onClick={() => setAnimateDeleteModal(false)} >
                                    <IconX stroke={1.5} size={22} />
                                </button>
                            </div>

                            <div className="span-line">
                                <span> <FormattedMessage id="brand-center.knowledge.modal-delete.text" /> </span>
                            </div>

                            <div className="btn-line">
                                <button className="cancel" onClick={() => setAnimateDeleteModal(false)}>
                                    <span> <FormattedMessage id="button.cancel" /> </span>
                                </button>

                                <button className="danger" onClick={() => handleKnowledgeDeletion()}>
                                    <IconTrash stroke={1.5} size={18} />
                                    <span> <FormattedMessage id="brand-center.knowledge.modal-delete.button-delete" /> </span>
                                </button>
                            </div>
                        </ModalContent>
                    </ModalContainer>
                </FadeBackground>
            </Fade>


        </>
    )
}
