import React from 'react';
import { LayoutDefinitions, Nav } from './styles';
import { definitionsRoutes } from 'routes';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import {
    matchRoutes,
    NavLink,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import {
    Data,
    DetailsPerfil,
    General,
    Plans,
    Team,
} from '../../components/icons';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

const DefinitionsLayout: React.FC = () => {
    const intl = useIntl();
    const isDesktop = useMediaQuery('(min-width: 64em)');
    const navigate = useNavigate();
    const { darkmode } = useDarkMode();

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.definitions');

    const routes = [
        {
            path: path + routeTranslate(intl, 'route.details'),
        },
        {
            path: path + routeTranslate(intl, 'route.general'),
        },
        {
            path: path + routeTranslate(intl, 'route.plans'),
        },
        {
            path: path + routeTranslate(intl, 'route.integrations'),
        },
        {
            path: path + routeTranslate(intl, 'route.team'),
        },
        {
            path: path + routeTranslate(intl, 'route.data'),
        },
    ];

    const useCurrentPath = () => {
        const location = useLocation();
        const router = matchRoutes(routes, location);
        return !!router;
    };

    const currentPath = useCurrentPath();

    const redirectIfDesktop = () => {
        if (!currentPath && isDesktop) {
            return navigate(`/${path}${routeTranslate(intl, 'route.details')}`);
        }
    };

    React.useEffect(() => {
        redirectIfDesktop();
    }, [isDesktop]);

    return (
        <div className="container">
            <LayoutDefinitions className={darkmode ? 'layoutDark' : ''}>
                <Nav>
                    <div className="header">
                        <h2>
                            {routeTranslate(intl, 'definitions.title.personal')}
                        </h2>
                    </div>
                    <ul>
                        <NavLink
                            to={`/${path}${routeTranslate(
                                intl,
                                'route.details',
                            )}`}
                        >
                            <li>
                                <div className="icon">
                                    <DetailsPerfil />
                                </div>
                                <div className="description">
                                    <h1>
                                        {routeTranslate(
                                            intl,
                                            'definitions.profileDetails',
                                        )}
                                    </h1>
                                    <p>
                                        {routeTranslate(
                                            intl,
                                            'definitions.profileDetails.description',
                                        )}
                                    </p>
                                </div>
                            </li>
                        </NavLink>
                    </ul>
                    <div className="header">
                        <h2>WORKSPACES</h2>
                    </div>
                    <ul>
                        <NavLink
                            to={`/${path}${routeTranslate(
                                intl,
                                'route.general',
                            )}`}
                        >
                            <li>
                                <div className="icon">
                                    <General />
                                </div>
                                <div className="description">
                                    <h1>
                                        {routeTranslate(
                                            intl,
                                            'definitions.general',
                                        )}
                                    </h1>
                                    <p>
                                        {routeTranslate(
                                            intl,
                                            'definitions.general.description',
                                        )}
                                    </p>
                                </div>
                            </li>
                        </NavLink>
                        <NavLink
                            to={`/${path}${routeTranslate(
                                intl,
                                'route.plans',
                            )}`}
                        >
                            <li>
                                <div className="icon">
                                    <Plans />
                                </div>
                                <div className="description">
                                    <h1>
                                        {routeTranslate(
                                            intl,
                                            'definitions.billing',
                                        )}
                                    </h1>
                                    <p>
                                        {routeTranslate(
                                            intl,
                                            'definitions.billing.description',
                                        )}
                                    </p>
                                </div>
                            </li>
                        </NavLink>
                        <NavLink
                            to={`/${path}${routeTranslate(intl, 'route.team')}`}
                        >
                            <li>
                                <div className="icon">
                                    <Team />
                                </div>
                                <div className="description">
                                    <h1>
                                        {routeTranslate(
                                            intl,
                                            'definitions.team',
                                        )}
                                    </h1>
                                    <p>
                                        {routeTranslate(
                                            intl,
                                            'definitions.team.description',
                                        )}
                                    </p>
                                </div>
                            </li>
                        </NavLink>
                        <NavLink
                            to={`/${path}${routeTranslate(intl, 'route.data')}`}
                        >
                            <li>
                                <div className="icon">
                                    <Data />
                                </div>
                                <div className="description">
                                    <h1>
                                        {routeTranslate(
                                            intl,
                                            'definitions.useData',
                                        )}
                                    </h1>
                                    <p>
                                        {routeTranslate(
                                            intl,
                                            'definitions.useData.description',
                                        )}
                                    </p>
                                </div>
                            </li>
                        </NavLink>
                    </ul>
                    {/*<div className="header">
                        <h2>
                            {routeTranslate(intl, 'definitions.title.products')}
                        </h2>
                    </div>
                   <ul>
                        <NavLink
                            to={`/${path}${routeTranslate(
                                intl,
                                'route.integrations',
                            )}`}
                        >
                            <li>
                                <div className="icon">
                                    <Integration />
                                </div>
                                <div className="description">
                                    <h1>
                                        {routeTranslate(
                                            intl,
                                            'definitions.integrations',
                                        )}
                                    </h1>
                                    <p>
                                        {routeTranslate(
                                            intl,
                                            'definitions.integrations.description',
                                        )}
                                    </p>
                                </div>
                            </li>
                        </NavLink>
                    </ul>*/}
                </Nav>
                <div className={`page ${currentPath ? 'active' : 'inative'}`}>
                    {definitionsRoutes()}
                </div>
            </LayoutDefinitions>
            <div className="clear"></div>
        </div>
    );
};

export default DefinitionsLayout;
