import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { Button } from 'app/presentation/components';
import { FiCheck } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
}

const ModalDeleteAccount: React.FC<ModalProps> = ({ active, handleClose }) => {
    const [radio, setRadio] = React.useState<number | null>();
    const intl = useIntl();
    const { darkmode } = useDarkMode();

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };

    const handleChange = (id: number) => {
        setRadio(id);
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'containerCancelDark' : ''}
            >
                <div className="content-modal">
                    <header>
                        <IoMdClose size={25} onClick={handleClose} />
                    </header>
                    <div id="title">
                        <h1>{routeTranslate(intl, 'cancel.title')}</h1>
                        <p>{routeTranslate(intl, 'cancel.description')}</p>
                    </div>
                    <ul>
                        <li>
                            <span>{routeTranslate(intl, 'cancel.hard')}</span>
                            <div
                                onClick={() => handleChange(1)}
                                className={
                                    radio === 1 ? 'radio active' : 'radio'
                                }
                            >
                                <FiCheck size={16} />
                            </div>
                        </li>
                        <li>
                            <span>
                                {routeTranslate(intl, 'cancel.product')}
                            </span>
                            <div
                                onClick={() => handleChange(2)}
                                className={
                                    radio === 2 ? 'radio active' : 'radio'
                                }
                            >
                                <FiCheck size={16} />
                            </div>
                        </li>
                        <li>
                            <span>{routeTranslate(intl, 'cancel.price')}</span>
                            <div
                                onClick={() => handleChange(3)}
                                className={
                                    radio === 3 ? 'radio active' : 'radio'
                                }
                            >
                                <FiCheck size={16} />
                            </div>
                        </li>
                        <li>
                            <span>
                                {routeTranslate(intl, 'cancel.solution')}
                            </span>
                            <div
                                onClick={() => handleChange(4)}
                                className={
                                    radio === 4 ? 'radio active' : 'radio'
                                }
                            >
                                <FiCheck size={16} />
                            </div>
                        </li>
                        <li>
                            <span>{routeTranslate(intl, 'cancel.other')}</span>
                            <div
                                onClick={() => handleChange(5)}
                                className={
                                    radio === 5 ? 'radio active' : 'radio'
                                }
                            >
                                <FiCheck size={16} />
                            </div>
                        </li>
                    </ul>
                    <div className="container-submit">
                        <Button type="submit">
                            {routeTranslate(intl, 'button.back')}
                        </Button>
                        <Button type="button">
                            {routeTranslate(intl, 'button.next')}
                        </Button>
                    </div>
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalDeleteAccount;
