import React from 'react';
import {
    Document,
    DocumentProps,
} from '../components/interfaces/document-props.interface';
import { useSnackbar } from 'notistack';
import useUserConfig from '../hooks/useUserConfigs';
import { DocumentsContext } from '../contexts/DocumentContext';
import {
    PageProps,
    ProviderProps,
} from './interfaces/provider-default-props.interface';
import ModalNewDocument from '../components/dashboard/ModalNewDocument';
import { DocumentsService } from '../../../services/documents';
import {
    ExtraFields,
    SendCompose,
} from '../../../interfaces/documents.interface';
import ReactDOMServer from 'react-dom/server';
import { jsPDF } from 'jspdf';

const documentsService = new DocumentsService();

const DocumentsProvider: React.FC<ProviderProps> = ({ children }) => {
    const [documents, setDocuments] = React.useState<Document[] | undefined>();
    const [document, setDocument] = React.useState<Document | undefined>();
    const [loading, setLoading] = React.useState(false);
    const [selectDocument, setSelectDocument] = React.useState<
        number | undefined
    >();
    const [page, setPage] = React.useState<PageProps>();
    const [modalNewDocument, setModalNewDocument] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { project, workspace } = useUserConfig();

    const showLoading = () => {
        setLoading(true);
    };

    const closeLoading = () => {
        setLoading(false);
    };

    const newDocument = async (document: DocumentProps) => {
        try {
            const requestCreate: any = {
                project_id: project.id,
                title: document.title,
            };

            const data: Document = await documentsService.create(requestCreate);

            if (document.content) {
                const html = document.content.replace(/\n/g, '<br />');

                const requestWrite = {
                    id: data.id,
                    content: html,
                };

                await documentsService.write(requestWrite);

                setDocument(data);
                return data;
            }

            setDocument(data);
            return data;
        } catch (e: any) {
            enqueueSnackbar('Erro ao cadastrar documento!', {
                variant: 'error',
            });
        } finally {
        }
    };

    const updateTitle = async (id: number, title: string) => {
        try {
            if (document && document.title !== title) {
                const requestTitle = {
                    id,
                    title,
                };

                const dataTitle = await documentsService.title(requestTitle);
                setDocument(dataTitle);
            }
        } catch (e) {
        } finally {
        }
    };

    const updateContent = async (id: number, content: string) => {
        try {
            if (document && document.content !== content) {
                console.log('novo');
                console.log(content);

                console.log('content');
                console.log(document.content);

                const requestWrite = {
                    id,
                    content,
                };

                const dataWrite = await documentsService.write(requestWrite);
                setDocument(dataWrite);
            }
        } catch (e) {
        } finally {
        }
    };

    const deleteDocument = async (id: number | undefined) => {
        try {
            if (!id) return;

            await documentsService.delete(id);
            await getDocuments(page?.CurrentPage, page?.PerPage);

            enqueueSnackbar('Documento excluido.', {
                variant: 'success',
            });
        } catch (e) {
            enqueueSnackbar('Erro ao excluir documento.', {
                variant: 'error',
            });
        }
    };

    const copyDocument = async (
        workspaceId: number,
        docData: Array<{ id: number | undefined; title: string }>,
    ) => {
        try {
            if (!workspaceId) return;

            await documentsService.copy(workspaceId, docData);
            await getDocuments(page?.CurrentPage, page?.PerPage);

            enqueueSnackbar('Documento duplicado!', {
                variant: 'success',
            });
        } catch (e) {
            enqueueSnackbar('Erro ao duplicar documento.', {
                variant: 'error',
            });
        }
    };

    const getDocuments = async (
        pageInfo?: number,
        limit?: number,
        search?: string,
    ) => {
        try {
            showLoading();

            const requestDocuments = {
                projectId: project.id,
                page: pageInfo,
                limit: limit,
                search: search,
            };

            const data = await documentsService.getAll(requestDocuments);

            setPage({
                CurrentPage: data.page,
                PerPage: data.limit,
                TotalPages: data.total,
            });
            setDocuments(data.data);
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const getDocument = async (idDocument: number) => {
        try {
            showLoading();

            const data = await documentsService.getOne(idDocument);
            setDocument(data);
            return data;
        } catch (e: any) {
            enqueueSnackbar('Erro ao cadastrar documento!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const saveExtraFields = async (fields: ExtraFields, idDocument: number) => {
        try {
            showLoading();

            await documentsService.extraFields(fields, idDocument);
        } catch (e: any) {
            enqueueSnackbar('Erro ao cadastrar campos extra!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const getCompose = async (request: SendCompose) => {
        try {
            showLoading();

            const socket = documentsService.getSocket();

            socket.onopen = async () => {
                const data = await documentsService.getCompose(
                    socket,
                    request,
                    workspace.id,
                    project.id,
                );
                return data;
            };

            return socket;
        } catch (e: any) {
            console.log(e);
            enqueueSnackbar('Erro ao cadastrar documento!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const exportToPDF = async (id_document: number) => {
        const documentSelected = await getDocument(id_document);

        const element = (
            <div
                dangerouslySetInnerHTML={{ __html: documentSelected.content }}
                style={{ padding: '10px', width: '600px' }}
            ></div>
        );

        const doc = new jsPDF('p', 'pt', 'letter');
        doc.html(ReactDOMServer.renderToString(element), {
            callback: function (doc) {
                doc.save(`${documentSelected.title}.pdf`);
            },
        });
    };

    const exportToMsWord = async (id_document: number) => {
        const documentSelected = await getDocument(id_document);

        const header =
            "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";

        const footer = '</body></html>';

        const html = header + documentSelected.content + footer;

        const blob = new Blob(['\ufeff', html], {
            type: 'application/msword',
        });

        const url =
            'data:application/vnd.ms-word;charset=utf-8,' +
            encodeURIComponent(html);

        const filename = documentSelected.title + '.docx';

        return { url, filename, blob };
    };

    const handleSelectDocument = (id: number | undefined) => {
        setSelectDocument(id);
    };

    const openModalNewDocument = () => {
        setModalNewDocument(true);
    };

    const closeNewDocument = () => {
        setModalNewDocument(false);
    };

    return (
        <DocumentsContext.Provider
            value={{
                setDocument,
                newDocument,
                getDocument,
                getDocuments,
                deleteDocument,
                updateTitle,
                copyDocument,
                updateContent,
                getCompose,
                handleSelectDocument,
                selectDocument,
                documents,
                document,
                loading,
                pageInfo: page,
                openModalNewDocument,
                exportToMsWord,
                exportToPDF,
                showLoading,
                closeLoading,
                saveExtraFields,
            }}
        >
            {children}
            {/* eu to mal */}
            <ModalNewDocument
                active={modalNewDocument}
                handleClose={closeNewDocument}
            />
        </DocumentsContext.Provider>
    );
};

export default DocumentsProvider;
