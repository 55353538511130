import styled, { css } from 'styled-components';

export const TextArea = styled.textarea`
    ${({
        theme: {
            components: {
                input: { border, text, placeholder, background },
            },
        },
    }) => css`
        width: 100%;
        height: 100px;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid ${border.color};
        resize: none;
        background-color: inherit;
        overflow-y: hidden;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.2px;
        transition: all linear 0.05s;
        color: ${text.color};
        background-color: ${background.color};
        ::placeholder {
            color: ${placeholder.color};
        }
        :hover,
        :focus {
            border-color: ${border.active};
        }
    `}
`;
