import { client } from '../client';
import {
    CountGroupedResponse,
    CreateUrlContentsRequest,
    ExtractUrlsRequest,
    TotalWithMostRecentResponse,
    UpdateURLContentRequest,
} from './interfaces';

export class URLContentsService {
    private readonly urlContentsClient = client
        .addPath('/url-contents')
        .addBrandHeaders();

    async delete(id: number): Promise<void> {
        return this.urlContentsClient.deleteData<void>({
            pathParams: [id],
        });
    }

    async update(id: number, content: string): Promise<void> {
        return this.urlContentsClient.patchData<void, UpdateURLContentRequest>(
            {
                content: content,
            },
            {
                pathParams: [id],
            },
        );
    }

    async countGrouped(id: number): Promise<CountGroupedResponse> {
        return this.urlContentsClient.getData<CountGroupedResponse>({
            pathParams: [id, 'count-grouped-by-domain'],
        });
    }

    async totalWithMostRecent(
        id: number,
    ): Promise<TotalWithMostRecentResponse> {
        return this.urlContentsClient.getData<TotalWithMostRecentResponse>({
            pathParams: [id, 'count-all-with-most-recent'],
        });
    }

    async extractUrls(url: string): Promise<string[]> {
        return this.urlContentsClient.postData<string[], ExtractUrlsRequest>(
            {
                urls: [url],
            },

            {
                path: '/scrape-urls',
            },
        );
    }

    async create(agent_id: number, urls: string[]): Promise<void> {
        return this.urlContentsClient.postData<void, CreateUrlContentsRequest>({
            agent_id: agent_id,
            urls: urls.map(url => ({
                url: url,
                is_discovered: false,
            })),
        });
    }
}
