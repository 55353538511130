import React from 'react';
import * as S from './styles';
import {
    Select,
    SwitchCheckbox,
    FormFieldContainer,
    ModalAdditionalMessages,
    Button,
    UserLimitInput,
    TextArea,
} from 'app/presentation/components/agent';
import { IconCheck, IconChevronRight } from '@tabler/icons-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';

export const ConfigPanel = () => {
    const { textGetter } = useText();
    const panelText = textGetter('agent.panel.config');
    const commonsText = textGetter('agent.panel.commons');

    const [businessDesc, setBusinessDesc] = React.useState<string>('');
    const [messageLen, setMessageLen] = React.useState<string>('medium');
    const [widgetPos, setWidgetPos] = React.useState<string>('right');
    const [showSources, setShowSources] = React.useState<boolean>(false);
    const [messageSound, setMessageSound] = React.useState<boolean>(false);
    const [showJarbasLink, setShowJarbasLink] = React.useState<boolean>(false);
    const [userLimit, setUserLimit] = React.useState<number>(0);
    const [modalAdditionalMessages, setModalAdditionalMessages] =
        React.useState<boolean>(false);
    const { handleSubmit } = useForm<{}>();
    const { agent, updateConfig } = useAgent();
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    const loadForm = () => {
        if (!agent || !agent.config) return;
        const {
            message_length,
            widget_position,
            show_answers_sources,
            show_jarbas_link,
            message_sound,
            max_messages_per_user,
            short_business_description,
        } = agent.config;
        setMessageLen(message_length);
        setWidgetPos(widget_position);
        setShowSources(show_answers_sources);
        setMessageSound(message_sound);
        setShowJarbasLink(show_jarbas_link);
        setUserLimit(max_messages_per_user);
        setBusinessDesc(short_business_description);
    };

    React.useEffect(() => {
        loadForm();
    }, [agent]);

    const onSubmit: SubmitHandler<{}> = async () => {
        setFormSubmitLoading(true);
        updateConfig(
            messageLen,
            widgetPos,
            showSources,
            messageSound,
            showJarbasLink,
            userLimit,
            businessDesc,
        ).finally(() => setFormSubmitLoading(false));
    };

    const openModalAdditionalMessages = () => {
        setModalAdditionalMessages(true);
    };

    const closeModalAdditionalMessages = () => {
        setModalAdditionalMessages(false);
    };

    const onBusinessDescTextAreaChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setBusinessDesc(event.target.value);
    };

    return (
        <S.ConfigPanel>
            <form
                className="config-panel-content"
                onSubmit={handleSubmit(onSubmit)}
            >
                {false && (
                    <FormFieldContainer
                        info={{
                            title: panelText('message-len.title'),
                        }}
                    >
                        <Select
                            variant="white"
                            value={messageLen}
                            onSelectChange={setMessageLen}
                            options={[
                                {
                                    node: (
                                        <span>
                                            {panelText('message-len.short')}
                                        </span>
                                    ),
                                    value: 'short',
                                },
                                {
                                    node: (
                                        <span>
                                            {panelText('message-len.medium')}
                                        </span>
                                    ),
                                    value: 'medium',
                                },
                                {
                                    node: (
                                        <span>
                                            {panelText('message-len.long')}
                                        </span>
                                    ),
                                    value: 'long',
                                },
                            ]}
                            width={'265px'}
                        ></Select>
                    </FormFieldContainer>
                )}
                {false && (
                    <FormFieldContainer
                        info={{
                            title: panelText('widget-pos.title'),
                            tooltip: panelText('widget-pos.tooltip'),
                        }}
                    >
                        <Select
                            variant="white"
                            value={widgetPos}
                            onSelectChange={setWidgetPos}
                            options={[
                                {
                                    node: (
                                        <span>
                                            {panelText('widget-pos.right')}
                                        </span>
                                    ),
                                    value: 'right',
                                },
                                {
                                    node: (
                                        <span>
                                            {panelText('widget-pos.left')}
                                        </span>
                                    ),
                                    value: 'left',
                                },
                            ]}
                            width={'265px'}
                        ></Select>
                    </FormFieldContainer>
                )}

                {false && (
                    <SwitchCheckbox
                        name="show-sources"
                        setValue={setShowSources}
                        value={showSources}
                        info={{
                            title: panelText('show-sources.title'),
                            tooltip: panelText('show-sources.tooltip'),
                        }}
                    ></SwitchCheckbox>
                )}

                {false && (
                    <SwitchCheckbox
                        name="message-sound"
                        setValue={setMessageSound}
                        value={messageSound}
                        info={{
                            title: panelText('message-sound.title'),
                            tooltip: panelText('message-sound.tooltip'),
                        }}
                    ></SwitchCheckbox>
                )}

                <SwitchCheckbox
                    name="show-jarbas-link"
                    setValue={setShowJarbasLink}
                    value={showJarbasLink}
                    info={{
                        title: panelText('show-jarbas-link.title'),
                    }}
                ></SwitchCheckbox>

                <div className="user-limit-field-container">
                    <FormFieldContainer
                        info={{
                            title: panelText('user-limit.title'),
                        }}
                    >
                        <UserLimitInput
                            value={userLimit}
                            onChange={setUserLimit}
                        ></UserLimitInput>
                    </FormFieldContainer>
                </div>
                {false && (
                    <Button
                        type="button"
                        variant="link"
                        className="additional-messages-button"
                        onClick={openModalAdditionalMessages}
                    >
                        <span>{panelText('button.additional-messages')}</span>
                        <IconChevronRight></IconChevronRight>
                    </Button>
                )}
                <FormFieldContainer
                    info={{
                        title: panelText('business-desc.title'),
                    }}
                    className="business-desc"
                >
                    <TextArea
                        value={businessDesc}
                        placeholder={panelText('business-desc.placeholder')}
                        onChange={onBusinessDescTextAreaChange}
                        required
                    ></TextArea>
                </FormFieldContainer>
                <div className="buttons-container">
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={loadForm}
                    >
                        {commonsText('button.cancel')}
                    </Button>
                    <Button
                        variant="dark"
                        type="submit"
                        loading={formSubmitLoading}
                        className="save-button"
                    >
                        <IconCheck></IconCheck>
                        <span>
                            {commonsText('button.save')}
                            <span className="desktop">
                                {' '}
                                {commonsText('button.save-suffix')}
                            </span>
                        </span>
                    </Button>
                </div>
            </form>
            <ModalAdditionalMessages
                active={modalAdditionalMessages}
                handleClose={closeModalAdditionalMessages}
            ></ModalAdditionalMessages>
        </S.ConfigPanel>
    );
};
