import React from 'react';
import { ContentContainer } from './styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { TbPlayerPauseFilled, TbPlayerPlayFilled } from 'react-icons/tb';
import { RiVolumeMuteFill, RiVolumeUpFill } from 'react-icons/ri';

interface Props {
    url: string;
}

const AudioPlay: React.FC<Props> = ({ url }) => {
    const darkmode = useDarkMode();
    return (
        <ContentContainer className={darkmode ? 'dropdownDark' : ''}>
            <AudioPlayer
                src={url}
                customIcons={{
                    play: (
                        <TbPlayerPlayFilled
                            color={darkmode ? '#000' : '#fff'}
                            size={18}
                        />
                    ),
                    pause: (
                        <TbPlayerPauseFilled
                            color={darkmode ? '#000' : '#fff'}
                            size={18}
                        />
                    ),
                    volume: (
                        <RiVolumeUpFill
                            color={darkmode ? '#000' : '#fff'}
                            size={18}
                        />
                    ),
                    volumeMute: (
                        <RiVolumeMuteFill
                            color={darkmode ? '#000' : '#fff'}
                            size={18}
                        />
                    ),
                }}
            />
        </ContentContainer>
    );
};

export default AudioPlay;
