import axios from 'axios';
import { TENOR_API_KEY } from 'config/contants';

const tenorAPI = axios.create({
    baseURL: 'https://g.tenor.com/v1',
});

export interface IGifs {
    gifs: string[];
    next: string;
}

type QueryResult = {
    results: [{ media: [{ gif: { url: string } }] }];
    next: string;
};

export class GifService {
    async get(search?: string, pos?: string, limit = '30'): Promise<IGifs> {
        const basePath = search ? `/search?q=${search}&` : `/trending?`;
        const positonPathParam = pos ? `&pos=${pos}` : '';
        const path = `${basePath}key=${TENOR_API_KEY}&media_filter=gif&limit=${limit}${positonPathParam}`;
        const { data } = await tenorAPI.get<QueryResult>(path);
        const gifs = {
            next: data.next,
            gifs: data.results.map(gif => {
                return gif.media[0].gif.url;
            }),
        };
        return gifs;
    }
}
