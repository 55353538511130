import React from 'react';

type IconProps = {
    strokeColor?: string;
};

export const Send: React.FC<IconProps> = ({ strokeColor = 'white' }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_4363_14893)">
            <path
                d="M6.35354 11.9548H21.9099M6.35354 11.9548L3.87867 19.3794C3.84199 19.4782 3.83443 19.5853 3.85689 19.6882C3.87934 19.7911 3.93086 19.8854 4.00533 19.9599C4.0798 20.0343 4.17409 20.0859 4.27698 20.1083C4.37987 20.1308 4.48705 20.1232 4.58577 20.0865L21.9099 11.9548M6.35354 11.9548L3.87867 4.53019C3.84199 4.43147 3.83443 4.32429 3.85688 4.2214C3.87934 4.1185 3.93086 4.02422 4.00533 3.94975C4.0798 3.87528 4.17408 3.82375 4.27698 3.8013C4.37987 3.77885 4.48705 3.78641 4.58577 3.82308L21.9099 11.9548"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_4363_14893">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
