import React from 'react';
import * as S from './styles';
import { InfoMessage } from 'app/presentation/components/agent/InfoMessage';
import {
    Button,
    CardSelect,
    FormFieldContainer,
} from 'app/presentation/components/agent';
import { IconApps, IconCode, IconCopy } from '@tabler/icons-react';
import useSnackbar from 'app/presentation/hooks/useSnackbar';
import { Popover, Popup, Standard } from 'app/presentation/components/icons';
import useText from 'app/presentation/hooks/useText';
import useAgent from 'app/presentation/hooks/useAgent';
import { WIDGET_JS_API_URL } from 'config/contants';

const Integrations = () => {
    const { success, error } = useSnackbar();
    const { textGetter } = useText();
    const { agent } = useAgent();
    const t = textGetter('agent.pages.integration');

    const [agentLink] = React.useState<string>('https://jarbas.link/R0ONyDm');
    const [widgetScript] = React.useState<string>(
        `<script type="text/javascript">
    window.APP_ID = '${agent ? agent.uuid : ''}';
    (function () {
        d = document;
        s = d.createElement('script');
        s.src = '${WIDGET_JS_API_URL || "https://widget-jarbasai.vercel.app"}/js/widget.js';
        s.type= 'module';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
    })();
</script>`,
    );
    const [config, setConfig] = React.useState<string>('code');
    const [position, setPosition] = React.useState<string>('default');

    const onCopyLinkClick = () => {
        navigator.clipboard
            .writeText(agentLink)
            .then(() => {
                success('copy-content');
            })
            .catch(() => {
                error('copy-content');
            });
    };
    const onCopyCodeClick = () => {
        navigator.clipboard
            .writeText(widgetScript)
            .then(() => {
                success('copy-content');
            })
            .catch(() => {
                error('copy-content');
            });
    };

    return (
        <S.Integrations>
            <div className="integrations-form-container">
                <h2 className="title">{t('title')}</h2>
                <div className="panel">
                    <span className="desc">{t('desc')}</span>
                    {false && (
                        <InfoMessage type="yellow" text={t('info-message')} />
                    )}
                    {false && (
                        <FormFieldContainer info={{ title: t('agent-link') }}>
                            <div className="share-your-agent">
                                <div className="text-container">
                                    <span>{agentLink}</span>
                                </div>
                                <Button
                                    variant="outline"
                                    onClick={onCopyLinkClick}
                                >
                                    <IconCopy />
                                </Button>
                            </div>
                        </FormFieldContainer>
                    )}
                    <div className="config-select-container">
                        <span className="config-select-title">
                            {t('config-select.title')}
                        </span>
                        <CardSelect
                            variant="primary"
                            cards={[
                                {
                                    icon: <IconCode />,
                                    title: t('config-select.code.title'),
                                    text: t('config-select.code.text'),
                                    value: 'code',
                                },
                                {
                                    icon: <IconApps />,
                                    title: t('config-select.integration.title'),
                                    text: t('config-select.integration.text'),
                                    value: 'integrations',
                                    disable: true,
                                },
                            ]}
                            value={config}
                            onChange={setConfig}
                            height="144px"
                        ></CardSelect>
                    </div>
                    {config == 'code' && (
                        <div className="code-container">
                            <span className="title">
                                {t('code-container.position-select.title')}
                            </span>
                            <div className="position-select-container">
                                <CardSelect
                                    variant="lightGrey"
                                    padding="12px 15px"
                                    title={{ height: '19.5px', size: '13px' }}
                                    cards={[
                                        {
                                            icon: <Popover />,
                                            title: t(
                                                'code-container.position-select.default.title',
                                            ),
                                            value: 'default',
                                        },
                                        {
                                            icon: <Standard />,
                                            title: t(
                                                'code-container.position-select.middle.title',
                                            ),
                                            value: 'middle',
                                            disable: true,
                                        },
                                        {
                                            icon: <Popup />,
                                            title: t(
                                                'code-container.position-select.popup.title',
                                            ),
                                            value: 'popup',
                                            disable: true,
                                        },
                                    ]}
                                    value={position}
                                    onChange={setPosition}
                                    height="84px"
                                ></CardSelect>
                            </div>
                            <div className="widget-script-container">
                                <span>
                                    {t('code-container.widget-script.desc')}
                                </span>
                                <div className="text-container">
                                    <span>
                                        {widgetScript.split('\n').map(line => (
                                            <pre>
                                                {line}
                                                <br />
                                            </pre>
                                        ))}
                                    </span>
                                    <Button
                                        variant="dark"
                                        onClick={onCopyCodeClick}
                                    >
                                        <IconCopy />
                                        <span>
                                            {t(
                                                'code-container.widget-script.button',
                                            )}
                                        </span>
                                    </Button>
                                </div>

                                <span>{t('code-container.desc')}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </S.Integrations>
    );
};

export default Integrations;
