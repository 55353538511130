import React from 'react';
import { IconType } from 'react-icons';
import * as S from './styles';

interface IconInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    icon: IconType;
}

const IconInput = ({
    placeholder = '',
    icon,
    className,
    ...props
}: IconInputProps) => {
    return (
        <S.Wrapper className={className}>
            <S.InputIcon>{icon({})}</S.InputIcon>
            <S.Input type="text" placeholder={placeholder} {...props} />
        </S.Wrapper>
    );
};

export default IconInput;
