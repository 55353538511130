export interface Generate {
    project_id: number;
    count: number;
    user_prompt: string;
    additional_prompts: Array<number>;
    width?: number;
    height?: number;
}

export type TypeField = 'humor' | 'mode' | 'style';

export interface PromptArteAi {
    images: Image[];
    prompt: string;
}

export interface Image {
    id: number;
    created_at: string;
    updated_at: string;
    deleted_at: any;
    project_id: number;
    user_id: number;
    credits: number;
    response: string;
    feedback_status: string;
    image_generation?: {
        id: number;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        display_prompt: string;
        width: number;
        height: number;
        type: string;
        style_preset: string;
    };
}

export interface Variation {
    image_id: number;
    project_id: number;
    count: number;
    prompt_strength: number;
    similarity: number;
}

export interface Report {
    resource_usage_id: number;
    report_type: string;
    description: string;
}

export interface Categories {
    id: number;
    icon: string;
    display_name: string;
    tag: string;
}

export interface Prompt {
    id: number;
    display_name: string;
    example_url: string;
    example_url_thumb: string;
}

export interface Subcategories {
    id: number;
    display_name: string;
    tag: string;
}

type ImageGeneration = {
    id: number;
    created_at: string;
    updated_at: string;
    display_prompt: string;
    width: number;
    height: number;
    type: string;
    style_preset: string;
};

export type ImageHistoryInterface = {
    id: number;
    created_at: string;
    updated_at: string;
    project_id: number;
    user_id: number;
    credits: number;
    response: string;
    thumbnail: string;
    feedback_status: string;
    rewrite_proposal: string;
    rated_by: null | number | string;
    image_generation: ImageGeneration;
};

export const ratios = {
    1: {
        key: '',
        width: 1344,
        height: 768,
        aspect: '16/9',
    },
    2: {
        key: '',
        width: 1216,
        height: 832,
        aspect: '3/2',
    },
    3: {
        key: '',
        width: 1152,
        height: 896,
        aspect: '5/4',
    },
    4: {
        key: '',
        width: 1024,
        height: 1024,
        aspect: '1/1',
    },
    5: {
        key: '',
        width: 896,
        height: 1152,
        aspect: '4/5',
    },
    6: {
        key: '',
        width: 832,
        height: 1216,
        aspect: '2/3',
    },
    7: {
        key: '',
        width: 768,
        height: 1344,
        aspect: '9/16',
    },
};
