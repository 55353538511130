import styled, { css } from 'styled-components';

interface ImgInputProps {
    color?: string;
}

export const ImgInput = styled.div<ImgInputProps>`
    ${({
        color,
        theme: {
            components: {
                imgInput: { border, background },
            },
        },
    }) => css`
        width: fit-content;

        .input-body-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (min-width: 64em) {
                flex-direction: row;
            }
            .img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                width: 48px;
                border: 1px solid ${border.color};
                background-color: ${color ? color : background.color};
                border-radius: 8px;
                img {
                    min-height: 24px;
                    min-width: 24px;
                    max-height: 24px;
                    max-width: 24px;
                }
            }
            .input-container {
                display: flex;
                gap: 12px;
                align-items: center;
                button {
                    gap: 12px;
                    padding: 8px 12px;
                    height: 30px;
                    border-radius: 4px;
                    svg {
                        height: 18px;
                        width: 18px;
                    }

                    span {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
                input {
                    display: none;
                }
            }
            .options-container {
                display: grid;
                grid-template-columns: repeat(5, minmax(48px, 1fr));
                grid-row-gap: 20px;
                grid-column-gap: 14px;
                align-items: center;
                padding: 20px 0px 0px 0px;
                border-top: 1px solid ${border.color};

                @media (min-width: 64em) {
                    width: fit-content;
                    grid-template-columns: repeat(5, minmax(48px, 1fr));
                    grid-column-gap: 20px;
                    border-top: none;
                    border-left: 1px solid ${border.color};
                    padding: 0px 0px 0px 20px;
                }

                .options-button {
                    cursor: pointer;
                    &.active {
                        border-color: ${border.active};
                    }
                }
            }
        }
    `}
`;
