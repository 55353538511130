import styled, { css } from 'styled-components';

export const SelectDropdown = styled.div`
    position: relative;
    ${({
        theme: {
            components: {
                selectDropdown: {
                    button: { border, icon },
                },
            },
        },
    }) => css`
        .create-option-input {
            opacity: 0;
            position: absolute;
            top: -20px;
            z-index: -1;
        }

        .open-menu-button {
            border: 1px solid ${border.color};
            gap: 4px;
            padding: 4px 8px;
            border-radius: 4px;
            white-space: nowrap;
            max-width: 100px;
            @media (min-width: 64em) {
                max-width: 250px;
            }

            span {
                overflow-x: scroll;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.2px;
                max-width: 80px;
                @media (min-width: 64em) {
                    max-width: 250px;
                }

                overflow-x: hidden;
                text-overflow: ellipsis;
            }

            svg {
                min-width: 16px;
                min-height: 16px;
                width: 16px;
                height: 16px;
                color: ${icon.color};
            }
        }
    `}
`;

export const MenuHeader = styled.div`
    ${({
        theme: {
            components: {
                selectDropdown: {
                    menu: { hr, title },
                },
            },
        },
    }) => css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        input {
            height: 42px;
        }
        .title-container {
            display: flex;
            align-items: center;
            height: 38px;
            padding: 0px 8px;
            border-bottom: 1px solid ${hr.color};

            span {
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.2px;
                color: ${title.color};
            }
        }
    `}
`;

export const MenuBody = styled.div`
    ${({
        theme: {
            components: {
                selectDropdown: {
                    menu: {
                        item: { text, background },
                    },
                },
            },
        },
    }) => css`
        display: flex;
        flex-direction: column;

        .menu-item-container {
            transition: all linear 0.05s;
            display: flex;
            align-items: center;
            min-height: 38px;
            padding: 0px 8px;
            border-radius: 6px;
            margin-top: 12px;
            color: ${text.color};
            background-color: inherit;
            border: none;
            cursor: pointer;

            :hover,
            &.active {
                background-color: ${background.active};
            }
        }

        max-height: 212px;
        overflow-y: scroll;
    `}
`;

export const MenuFooter = styled.div`
    ${({
        theme: {
            components: {
                selectDropdown: {
                    menu: {
                        hr,
                        item: { text, background },
                    },
                },
            },
        },
    }) => css`
        display: flex;
        flex-direction: column;
        border-top: 1px solid ${hr.color};

        .menu-item-container {
            overflow-y: scroll;
            transition: all linear 0.05s;
            display: flex;
            align-items: center;
            min-height: 38px;
            padding: 0px 8px;
            border-radius: 6px;
            margin-top: 12px;
            color: ${text.color};
            background-color: inherit;
            border: none;
            cursor: pointer;

            :hover,
            &.active {
                background-color: ${background.active};
            }

            .create-option-text {
                white-space: nowrap;
                display: flex;
                gap: 4px;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.2px;
                .new-option {
                    display: inline-block;
                    vertical-align: top;
                    max-width: 110px;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    `}
`;
