import styled from 'styled-components';

export const ContainerStyled = styled.div`
    text-align: center;
    max-width: 430px;

    h1 {
        margin: 14px 0;
    }

    p {
        font-weight: 500;
        font-size: 14px;
        color: #a09fa5;
        line-height: 150%;
    }
`;
