import React from 'react';
import { Button, Container } from 'app/presentation/components';
import {
    WelcomeContainer,
    FavoritesContainer,
    DocumentsContainer,
} from './styles';
import { HiArrowNarrowRight, HiOutlineDotsHorizontal } from 'react-icons/hi';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import {
    Copy,
    Doc,
    Edit,
    Pdf,
    Receipt,
    Trash,
} from 'app/presentation/components/icons';
import TableDocuments from 'app/presentation/components/dashboard/TableDocuments';
import { Menu, MenuList, Paper } from '@mui/material';
import { MuiMenuHeader } from '../documents/styles';
import useDocuments from 'app/presentation/hooks/useDocuments';
import { routeTranslate } from '../../../utils/intl';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useUserConfigs from '../../../app/presentation/hooks/useUserConfigs';
import useTemplates from '../../../app/presentation/hooks/useTemplates';
import useDarkMode from '../../../app/presentation/hooks/useDarkMode';
import Loading from '../../../app/presentation/components/common/Loading';
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AddFav from 'assets/images/svg/add-fav.svg';
import useDocumentTitle from 'utils/useDocumentTitle';

const Home: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedDocuments, SetSelectedDocuments] = React.useState<
        Array<{ id: number | undefined; title: string }>
    >([]);

    const intl = useIntl();
    const { project, workspace } = useUserConfigs();
    const {
        documents,
        selectDocument,
        exportToMsWord,
        exportToPDF,
        getDocuments,
        deleteDocument,
        handleSelectDocument,
        loading: loadingDocument,
        copyDocument,
    } = useDocuments();
    const { darkmode } = useDarkMode();
    const navigate = useNavigate();

    const currentPageName = routeTranslate(intl, 'route.home').replace('/', '');
    const pageTitle =
        currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1);
    useDocumentTitle(pageTitle);

    const { favorites, loading: loadingTemplate } = useTemplates();

    React.useEffect(() => {
        const fetchData = async () => {
            await getDocuments();
        };

        fetchData().catch(console.error);
    }, [project]);

    const handleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
        id: number | undefined,
    ) => {
        if (!event || !id) return;

        handleSelectDocument(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const exportPDF = async () => {
        if (!selectDocument) return;

        await exportToPDF(selectDocument);
    };

    const exportWord = async () => {
        if (!selectDocument) return;

        const msWord = await exportToMsWord(selectDocument);

        const nav = window.navigator as any;
        const downloadLink = document.createElement('a');

        if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(msWord.blob, msWord.filename);
        } else {
            document.body.appendChild(downloadLink);
            downloadLink.href = msWord.url;
            downloadLink.download = msWord.filename;
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    };

    const handleDelete = async () => {
        handleClose();
        await deleteDocument(selectDocument);
    };

    const handleCopy = async () => {
        handleClose();
        const docSelectedTitle =
            documents?.filter(doc => doc.id === selectDocument)[0].title || '';
        const title = `${routeTranslate(
            intl,
            'document.copy.text',
        )} ${docSelectedTitle}`;

        const data = [
            {
                id: selectDocument,
                title: title,
            },
        ];
        await copyDocument(workspace.id, data);
    };

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.library');

    const handleEdit = (id?: number) => {
        if (!documents) return;

        const document = documents.find(document => document.id === id);

        if (!document || !id) return;

        const pathDocument =
            routeTranslate(intl, 'route.urlLang') +
            routeTranslate(intl, 'route.backoffice');

        navigate(
            `/${pathDocument}${routeTranslate(
                intl,
                'route.documents',
            )}${routeTranslate(intl, 'route.document')}/${
                document.rtc_id
            }/${id}`,
        );
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const ButtonGroup = ({ next, previous, ...rest }: any) => {
        const {
            carouselState: {
                currentSlide,
                deviceType,
                totalItems,
                slidesToShow,
            },
        } = rest;

        const disableNext =
            deviceType === 'mobile'
                ? totalItems === currentSlide + 1
                : currentSlide + slidesToShow === totalItems;

        return (
            <div className="carousel-button-group">
                <button
                    className={currentSlide === 0 ? 'disable' : ''}
                    onClick={() => previous()}
                    disabled={currentSlide === 0}
                >
                    <IoIosArrowBack
                        color={
                            darkmode
                                ? currentSlide === 0
                                    ? '#aaa2a2'
                                    : '#FFFFFF'
                                : ''
                        }
                    />
                </button>
                <button
                    onClick={() => next()}
                    className={disableNext ? 'disable' : ''}
                    disabled={disableNext}
                >
                    <IoIosArrowForward
                        color={
                            darkmode
                                ? disableNext
                                    ? '#aaa2a2'
                                    : '#FFFFFF'
                                : ''
                        }
                    />
                </button>
            </div>
        );
    };

    return (
        <Container>
            <WelcomeContainer className={darkmode ? 'dark' : ''}>
                <h1>{routeTranslate(intl, 'home.title')}👋</h1>
                <div id="welcome">
                    <h1>
                        {parse(routeTranslate(intl, 'home.main.description'))}
                    </h1>
                    <p>{routeTranslate(intl, 'home.second.description')}</p>
                    <Button type="submit" icon={<HiArrowNarrowRight />}>
                        {routeTranslate(intl, 'home.button.explore')}
                    </Button>
                </div>
            </WelcomeContainer>
            {!loadingTemplate && (
                <FavoritesContainer className={darkmode ? 'dark' : ''}>
                    <h1>{routeTranslate(intl, 'home.favorites')}</h1>

                    <div className="container-carousel">
                        <Carousel
                            responsive={responsive}
                            arrows={false}
                            renderButtonGroupOutside={true}
                            customButtonGroup={<ButtonGroup />}
                            itemClass="items-carousel"
                        >
                            {favorites && favorites.length > 0 ? (
                                favorites.map(favorite => (
                                    <Link
                                        key={favorite.id}
                                        className="box"
                                        to={`/${path}${routeTranslate(
                                            intl,
                                            'route.model',
                                        )}/${favorite.id}`}
                                    >
                                        <div className="icon">
                                            <img src={favorite.image.String} />
                                        </div>
                                        <h1>{favorite.name}</h1>
                                    </Link>
                                ))
                            ) : (
                                <Link className="box" to={`/${path}`}>
                                    <div className="icon">
                                        <img src={AddFav} />
                                    </div>
                                    <h1
                                        style={{
                                            fontWeight: 700,
                                            fontSize: '0.875rem',
                                        }}
                                    >
                                        {routeTranslate(
                                            intl,
                                            'home.favorites.add',
                                        )}
                                    </h1>
                                </Link>
                            )}
                        </Carousel>
                    </div>
                </FavoritesContainer>
            )}
            {loadingTemplate && (
                <WelcomeContainer style={{ padding: '40px 0' }}>
                    <Loading absolute={false} open={loadingTemplate} />
                </WelcomeContainer>
            )}

            {!loadingDocument && documents && documents.length > 0 && (
                <DocumentsContainer className={darkmode ? 'dark' : ''}>
                    <header>
                        <h1>{routeTranslate(intl, 'home.documents.recent')}</h1>
                        <Link
                            to={`/${routeTranslate(
                                intl,
                                'route.urlLang',
                            )}${routeTranslate(
                                intl,
                                'route.backoffice',
                            )}${routeTranslate(intl, 'route.documents')}`}
                        >
                            <Button icon={<IoIosArrowForward />}>
                                {routeTranslate(intl, 'home.documents.seeAll')}
                            </Button>
                        </Link>
                    </header>
                    {documents.map(document => {
                        const date = new Date(document.created_at);
                        const dateString = date.toLocaleString('pt-BR', {
                            month: 'long',
                            year: 'numeric',
                            day: 'numeric',
                        });

                        return (
                            <div key={document.id} className="box">
                                <div className="header">
                                    <div className="icon">
                                        <Receipt />
                                    </div>
                                    <div className="flex">
                                        <h1>{document.title}</h1>
                                    </div>
                                    <div
                                        className="dots"
                                        onClick={e =>
                                            handleClick(e, document.id)
                                        }
                                    >
                                        <HiOutlineDotsHorizontal
                                            size={22}
                                            style={{ color: '#718096' }}
                                        />
                                    </div>
                                </div>
                                <div className="infos">
                                    <div className="info">
                                        <strong>
                                            {routeTranslate(
                                                intl,
                                                'home.documents.createBy',
                                            )}
                                        </strong>
                                        <span>
                                            {document.creator?.first_name}
                                        </span>
                                    </div>
                                    <div className="info">
                                        <strong>
                                            {routeTranslate(
                                                intl,
                                                'home.documents.modify',
                                            )}
                                        </strong>
                                        <span>{dateString}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <article id="table">
                        <TableDocuments
                            data={documents}
                            menuOptions={handleClick}
                            limit={2}
                            SetSelectedDocuments={SetSelectedDocuments}
                            selectedDocuments={selectedDocuments}
                        />
                    </article>
                </DocumentsContainer>
            )}
            {loadingDocument && (
                <WelcomeContainer style={{ padding: '40px 0' }}>
                    <Loading absolute={false} open={loadingDocument} />
                </WelcomeContainer>
            )}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    width: '100%',
                    '& > .MuiPaper-root': {
                        borderRadius: '12px',
                        boxShadow: '8px 12px 24px rgba(93, 106, 131, 0.1)',
                        background: darkmode ? '#1D1D1F' : '',
                    },
                }}
                anchorOrigin={{
                    vertical: 260,
                    horizontal: -50,
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Paper
                    sx={{
                        width: 160,
                        boxShadow: 0,
                        background: darkmode ? '#1D1D1F' : '',
                    }}
                >
                    <MenuList
                        sx={{
                            padding: 0,
                            boxShadow: 0,
                            background: darkmode ? '#1D1D1F' : '',
                        }}
                        disablePadding
                        dense
                    >
                        <MuiMenuHeader>
                            <ul>
                                <li
                                    onClick={() => handleEdit(selectDocument)}
                                    className="icon"
                                    onMouseEnter={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#151718' : '')
                                    }
                                    onMouseLeave={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#1D1D1F' : '')
                                    }
                                >
                                    <Edit />
                                    <span
                                        style={{
                                            color: darkmode ? '#fff' : '',
                                        }}
                                    >
                                        {routeTranslate(
                                            intl,
                                            'home.documents.edit',
                                        )}
                                    </span>
                                </li>
                                <li
                                    onClick={() => handleCopy()}
                                    className="icon"
                                    onMouseEnter={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#151718' : '')
                                    }
                                    onMouseLeave={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#1D1D1F' : '')
                                    }
                                >
                                    <Copy />
                                    <span
                                        style={{
                                            color: darkmode ? '#fff' : '',
                                        }}
                                    >
                                        {routeTranslate(
                                            intl,
                                            'home.documents.duplicate',
                                        )}
                                    </span>
                                </li>
                                <li
                                    onClick={exportPDF}
                                    className="icon"
                                    onMouseEnter={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#151718' : '')
                                    }
                                    onMouseLeave={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#1D1D1F' : '')
                                    }
                                >
                                    <Pdf />
                                    <span
                                        className="bold"
                                        style={{
                                            color: darkmode ? '#fff' : '',
                                        }}
                                    >
                                        PDF
                                    </span>
                                </li>
                                <li
                                    onClick={exportWord}
                                    className="icon"
                                    onMouseEnter={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#151718' : '')
                                    }
                                    onMouseLeave={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#1D1D1F' : '')
                                    }
                                >
                                    <Doc />
                                    <span
                                        className="bold"
                                        style={{
                                            color: darkmode ? '#fff' : '',
                                        }}
                                    >
                                        Microsoft Word
                                    </span>
                                </li>
                                <li
                                    onClick={handleDelete}
                                    className="icon"
                                    onMouseEnter={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#151718' : '')
                                    }
                                    onMouseLeave={(e: any) =>
                                        (e.target.style.backgroundColor =
                                            darkmode ? '#1D1D1F' : '')
                                    }
                                >
                                    <Trash />
                                    <span
                                        className="bold"
                                        style={{
                                            color: darkmode ? '#fff' : '',
                                        }}
                                    >
                                        {routeTranslate(
                                            intl,
                                            'home.documents.delete',
                                        )}
                                    </span>
                                </li>
                            </ul>
                        </MuiMenuHeader>
                    </MenuList>
                </Paper>
            </Menu>
        </Container>
    );
};

export default Home;
