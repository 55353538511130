import { api } from '../api';
import { Hide, InfoGenerate, Rate } from '../../interfaces/chat.interface';
import axios from 'axios';
import { getToken } from 'services/api';
import { API_BASE_URL } from 'config/contants';

export class ChatService {
    private readonly path = '/chats';

    async getAll() {
        const { data } = await api.get(`${this.path}`);

        return data;
    }

    async getOne(id: number, page: number) {
        const { data } = await api.get(
            `${this.path}/${id}/messages?page=${page}&limit=12`,
        );

        return data;
    }

    async generateImage(
        chat_id: number,
        workspace: number,
        dataGenerate: InfoGenerate,
    ) {
        const token = await getToken();

        const { data } = await axios.post(
            `${API_BASE_URL}${this.path}/${chat_id}/generate-image`,
            dataGenerate,
            {
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json',
                    'X-Workspace-Id': workspace,
                },
            },
        );

        return data;
    }

    async create(title: string, project_id: number) {
        const { data } = await api.post(`${this.path}`, {
            title,
            project_id,
        });

        return data;
    }

    async update(title: string, chat_id: number) {
        const { data } = await api.patch(`${this.path}/${chat_id}`, {
            title,
        });

        return data;
    }

    async getHistory(id: number, idProject: number) {
        const { data } = await api.get(
            `${this.path}/history/${id}?project_id=${idProject}`,
        );

        return data;
    }

    async removeHistory(ids: number[], idProject: number) {
        const { data } = await api.delete(
            `${this.path}/history/delete?project_id=${idProject}`,
            {
                data: { resource_ids: ids },
            },
        );

        return data;
    }

    async rateResponse(rate: Rate, workspace: number) {
        const token = await getToken();

        const { data } = await axios.post(
            `${API_BASE_URL}${this.path}/messages/rate`,
            rate,
            {
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json',
                    'X-Workspace-Id': workspace,
                },
            },
        );

        return data;
    }

    async hideResponse(hide: Hide, workspace: number, project_id: number) {
        const token = await getToken();

        const { data } = await axios.post(
            `${API_BASE_URL}${this.path}/messages/hide`,
            hide,
            {
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json',
                    'X-Workspace-Id': workspace,
                    'X-Project-Id': project_id,
                },
            },
        );

        return data;
    }

    async setFavorite(id: number, user_id: number) {
        const { data } = await api.post(`${this.path}/${id}/favorite`, {
            user_id,
        });

        return data;
    }

    async getFavorites(user_id: number) {
        const { data } = await api.get(`${this.path}/user/${user_id}/favorite`);

        return data;
    }

    async removeChat(id: number) {
        const { data } = await api.delete(`${this.path}`, {
            data: { chat_ids: [id] },
        });

        return data;
    }

    async getCategories() {
        const { data } = await api.get('categories');

        return data;
    }

    async getOutputLanguages() {
        const { data } = await api.get(`${this.path}/output-languages/list`);

        return data;
    }

    async getTranscript(chat_id: number, file: File) {
        const token = await getToken();

        const { data } = await axios.post(
            `${API_BASE_URL}${this.path}/audio/transcript`,
            {
                chat_id,
                file,
            },
            {
                headers: {
                    Authorization: token,
                    'Content-Type': 'multipart/form-data',
                },
            },
        );

        return data;
    }

    getSocket = () => {
        return new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET}/../../chats/ws`,
        );
    };

    getSocketRegenerate = () => {
        return new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET}/../../chats/ws/regenerate`,
        );
    };
}
