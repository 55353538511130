import { api } from '../api';

export class UsersService {
    private readonly path = '/user-settings';

    async getOne() {
        const { data } = await api.get(this.path);

        return data;
    }

    async update(languageId: number) {
        return api.put(this.path, {
            language_id: languageId,
        });
    }

    async sendEmailVerify(email: string) {
        await api.get('/auth/me');
        const result = await api.post('/auth/verify-email', {
            email: email,
        });
        return result;
    }

    async sendEmailPassword(email: string) {
        const result = await api.post('/auth/recover-password', {
            email: email,
        });
        return result;
    }
}
