import CollectionsContext from 'app/presentation/contexts/CollectionsContext';
import {
    Visibility,
    type GetAllCollectionsReq,
    type UpdateCollectionReq,
    type GetCollectionReq,
    type Collection,
    type CreateCollectionReq,
    type CollectionTranslationReq,
    type DeleteCollectionReq,
    type PutCollectionIconReq
} from 'services/collections/types';
import { getAllCollections, putCollection, getCollection, postCollection, deleteCollection, putCollectionIcon } from 'services/collections';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { type Pagination } from 'services/commons';
import React from 'react';
import { Memory } from 'services/brand/memories/types';

export interface ProviderProps {
    children: React.ReactNode;
}

export enum RowContent {
    Collection = "collection",
    Memory = "memory"
}

export type TreeNode = {
    type: RowContent;
    obj: Collection | Memory;
    children: string[];
    parentId: string | undefined;
    open: boolean;
}

// TODO fix opened state when deleting
// TODO maybe change way of rendering of whenever tree changes in TableCollections 

const CollectionsProvider: React.FC<ProviderProps> = ({ children }) => {
    const { workspace, project, activeBrand: brand } = useUserConfig();

    const [collectionsTree, setCollectionsTree] = React.useState<Map<string, TreeNode>>(new Map());
    const [selectedLanguage, setSelectedLanguage] = React.useState<number>(brand ? brand.brand_config.default_language : 1);
    const [focusedElement, setFocusedElement] = React.useState<string>();
    const [selectedCollection, setSelectedCollection] = React.useState<number>();

    React.useEffect(() => { fetchAllAndSetCollections(); }, [selectedLanguage, brand]);

    const updateTreeItem = (id: string, fields: Partial<TreeNode>): void => {
        const node = collectionsTree.get(id);
        if (!node) return;

        const tempMap = new Map(collectionsTree);
        tempMap.set(id, { ...node, ...fields });
        setCollectionsTree(tempMap);
    }

    const setTreeItem = (id: string, obj: TreeNode): void => {
        const tempMap = new Map(collectionsTree);
        tempMap.set(id, obj);
        setCollectionsTree(tempMap);
    }

    const deleteTreeItem = (id: string): void => {
        const tempMap = new Map(collectionsTree);
        tempMap.delete(id);
        setCollectionsTree(tempMap);
    }

    const fetchAllCollections = async (
        page: number, limit: number,
        language_id?: number,
        collection_id?: number,
        all?: boolean): Promise<Pagination<Collection> | undefined> => {
        if (!brand) return;

        const req: GetAllCollectionsReq = {
            paginationParams: {
                page: page,
                limit: limit,
            },
            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id,
            }

        }

        if (language_id) req.paginationParams.l = language_id;
        if (collection_id) req.collection_id = collection_id;
        if (all) req.all = all;

        try {
            return await getAllCollections(req);
        } catch (err: any) {
            throw err;
        } finally {
            //setLoading(false);
        }

    }

    const fetchAllAndSetCollections = async (): Promise<void> => {
        try {
            const collections = await fetchAllCollections(1, 500, selectedLanguage, undefined, true);
            if (!collections) return;

            const tempTree: Map<string, TreeNode> = new Map();
            const collectionsChildren: Collection[] = [];
            const memories: Memory[] = [];

            collections.data.map(collection => {
                const parentID = collection.parent?.id ? `${RowContent.Collection},${collection.parent.id}` : undefined;

                if (collection.parent) collectionsChildren.push(collection);

                tempTree.set(`${RowContent.Collection},${collection.id}`, {
                    type: RowContent.Collection,
                    obj: collection,
                    children: [],
                    parentId: parentID,
                    open: false
                });

                collection.brand_memories.map(memory => {
                    memories.push(memory);

                    tempTree.set(`${RowContent.Memory},${memory.id}`, {
                        type: RowContent.Memory,
                        obj: memory,
                        children: [],
                        parentId: `${RowContent.Collection},${collection.id}`,
                        open: false
                    });
                })

            })

            collectionsChildren.map(collection => {
                const parentNode = tempTree.get(`${RowContent.Collection},${collection.parent!.id}`)!;
                parentNode.children.push(`${RowContent.Collection},${collection.id}`);

                tempTree.set(`${RowContent.Collection},${collection.parent!.id}`, parentNode);
            })

            memories.map(memory => {
                const memoryNode = tempTree.get(`${RowContent.Memory},${memory.id}`)!;
                const parentNode = tempTree.get(memoryNode.parentId!)!;

                parentNode.children.push(`${RowContent.Memory},${memory.id}`);

                tempTree.set(memoryNode.parentId!, parentNode)
            })

            setCollectionsTree(tempTree);
        } catch (err: any) {
            throw err
        }
    }

    const updateCollection = async (collection: Collection, title: string, description: string, language_id: number, groupID?: number): Promise<Collection | undefined> => {
        if (!brand) return;

        const collectionNode = collectionsTree.get(`${RowContent.Collection},${collection.id}`);
        if (!collectionNode) return;

        const collectionObj = collectionNode.obj as Collection;

        const req: UpdateCollectionReq = {
            collection_id: collection.id,

            data: {
                icon: collectionObj.icon,
                visibility: collectionObj.visibility,
                brand_memory_group_id: groupID,
                translations: [
                    {
                        title: title,
                        description: description,
                        language_id: language_id
                    }
                ]
            },
            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id
            }
        }


        try {
            const collection = await putCollection(req);


            const nodeId = `${RowContent.Collection},${collection.id}`;
            const collectionNode = collectionsTree.get(nodeId);
            updateTreeItem(nodeId, { ...collectionNode, obj: collection })

            return collection;
        } catch (err: any) {
            throw err;
        } finally {

        }

    }

    const updateCollectionParent = async (collection: Collection, groupID: number): Promise<Collection | undefined> => {
        if (!brand) return;

        const req: UpdateCollectionReq = {
            collection_id: collection.id,

            data: {
                icon: collection.icon,
                visibility: collection.visibility,
                brand_memory_group_id: groupID,
            },

            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id
            }
        }

        try {
            const collection = await putCollection(req);
            if (!collection) return;

            return collection;
        } catch (err: any) {
            throw err;
        }
    }

    /*const checkCollectionDepth = (collectionID: number): number => {
        let depth = 0;
        const rootNode = tree.get(`${collectionID},collection`);

        const stack: TreeNode[] = [];
        stack.push(rootNode!);

        while (stack.length > 0) {
            const currNode = stack.pop()!;

            if (currNode.type == "collection") {
                depth++;

                tree.forEach((childNode) => {
                    if (childNode.parent_id === currNode.obj.id) {
                        childNode.depth = currNode.depth + 1;
                        stack.push(childNode);
                    }
                });
            }
        }

        return depth;
    }*/

    const fetchCollection = async (collectionId: number, languageId: number): Promise<Collection | undefined> => {
        if (!brand) return;

        const req: GetCollectionReq = {
            collection_id: collectionId,
            language_id: languageId,

            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id
            }
        }

        try {
            const collection = await getCollection(req);
            return collection;
        } catch (err: any) {
            throw err;
        }
    }

    // TODO collection depth checking
    const createCollection = async (defaultTitle: string, groupId = 0): Promise<Collection | undefined> => {
        if (!brand) return;

        const translations: CollectionTranslationReq[] = brand.supported_languages.map(language => {
            return {
                title: defaultTitle,
                description: "",
                language_id: language.language_id
            }
        })

        // TODO when ordering stuff implemented, the ordering gotta be done on backend
        const req: CreateCollectionReq = {
            data: {
                icon: "IconFolder",
                ordering: 0,
                visibility: Visibility.Public,
                brand_memory_group_id: groupId,
                translations: translations
            },

            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id
            }
        }

        try {
            const collection = await postCollection(req);
            const parentId = groupId != 0 ? `${RowContent.Collection},${groupId}` : undefined;
            const createdCollectionId = `${RowContent.Collection},${collection!.id}`;

            const tempTree = new Map(collectionsTree);

            tempTree.set(createdCollectionId, {
                type: RowContent.Collection,
                obj: collection,
                children: [],
                parentId: parentId,
                open: false
            });

            if (parentId) {
                const parentNode = tempTree.get(parentId)!;
                parentNode.children.push(createdCollectionId);
                parentNode.open = true;
                tempTree.set(parentId, parentNode);
            }

            setCollectionsTree(tempTree);

            setFocusedElement(`${RowContent.Collection},${collection!.id}`);
            return collection;
        } catch (err: any) {
            throw err;
        }
    }

    const removeCollection = async (collection_id: number): Promise<void> => {
        if (!brand) return;

        const req: DeleteCollectionReq = {
            collection_id: collection_id,

            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id
            }
        }

        return deleteCollection(req);
    }

    const updateCollectionIcon = async (collection_id: number, icon: string): Promise<Collection | undefined> => {
        if (!brand) return;

        const req: PutCollectionIconReq = {
            collection_id: collection_id,

            data: {
                icon: icon
            },

            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id
            }
        }

        return putCollectionIcon(req);
    }

    return (
        <CollectionsContext.Provider
            value={{
                fetchCollection: fetchCollection,
                fetchAllCollections: fetchAllCollections,
                fetchAllAndSetCollections: fetchAllAndSetCollections,
                updateCollection: updateCollection,
                updateCollectionIcon: updateCollectionIcon,
                createCollection: createCollection,
                removeCollection: removeCollection,

                tree: collectionsTree,
                setTree: setCollectionsTree,
                updateTreeItem: updateTreeItem,
                setTreeItem: setTreeItem,
                deleteTreeItem: deleteTreeItem,
                updateCollectionParent: updateCollectionParent,
                //checkCollectionDepth: checkCollectionDepth

                selectedLanguage: selectedLanguage,
                setSelectedLanguage: setSelectedLanguage,

                selectedCollection: selectedCollection,
                setSelectedCollection: setSelectedCollection,

                focusedElement: focusedElement
            }}
        >
            {children}
        </CollectionsContext.Provider>
    )
}

export default CollectionsProvider;
