import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routeTranslate } from '../utils/intl';
import { useIntl } from 'react-intl';
import MessageLayout from '../app/presentation/layout/message-layout';
import PageNotFound from 'pages/not-found/PageNotFound';
import { AccessDenied } from '../pages/messages';

export const MessagesRoutes = () => {
    const intl = useIntl();

    return (
        <MessageLayout>
            <Routes>
                <Route
                    path={`${routeTranslate(
                        intl,
                        'route.messages.access_denied',
                    )}`}
                    element={<AccessDenied />}
                />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </MessageLayout>
    );
};
