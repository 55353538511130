import React from 'react';
import DarkModeContext from '../contexts/DarkModeContext';
import { DarkModeContextData } from '../contexts/interfaces/darkmode-context-props.interface';

function useDarkMode(): DarkModeContextData {
    const context = React.useContext(DarkModeContext);

    if (!context) {
        throw new Error('useDarkMode deve ser usado com o DarkModeProvider');
    }

    return context;
}

export default useDarkMode;
