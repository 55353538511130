import React from 'react';
import { Model } from '../styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

import useTemplates from 'app/presentation/hooks/useTemplates';
import { Select as CustomSelect } from 'app/presentation/components';
import { FieldValues, useForm } from 'react-hook-form';
import { modelList } from '..';

interface IModelView {
    selectedModels: Array<string>;
    setSelectedModels: (value: Array<string>) => void;
    setTranslationLanguage: (value: string) => void;
}

const ModelView: React.FC<IModelView> = ({
    selectedModels,
    setSelectedModels,
    setTranslationLanguage,
}) => {
    const { darkmode } = useDarkMode();
    const { register, watch } = useForm<FieldValues>();
    const watchLanguage = watch('language');

    const { languages } = useTemplates();

    React.useEffect(() => {
        if (watchLanguage && languages) {
            const item = languages[watchLanguage];
            setTranslationLanguage(item);
        } else {
            setTranslationLanguage('Portuguese');
        }
    }, [watchLanguage]);

    const handleClick = async (option: string) => {
        const newArr = [...selectedModels];
        const index = newArr.indexOf(option);

        if (index !== -1) {
            newArr.splice(index, 1);
        } else {
            newArr.push(option);
        }

        setSelectedModels(newArr);
    };

    return (
        <Model className={darkmode ? 'darkModel' : ''}>
            <div className="containerModels">
                {modelList.map(model => {
                    return (
                        <div className="itemContainer">
                            <div
                                className={`itemModels ${
                                    (model.id === 'transcription' ||
                                        selectedModels.includes(model.id)) &&
                                    'activeItem'
                                }`}
                                id={model.id}
                                onClick={() => handleClick(model.id)}
                            >
                                <div className="iconModel">
                                    <>{model.icon}</>
                                </div>
                                <p>{model.title}</p>
                                <span>{model.description}</span>
                            </div>
                            {languages && model.id === 'translation' && (
                                <div className="languageSelector">
                                    <CustomSelect
                                        id="language"
                                        placeholder="Português (BR)"
                                        register={register}
                                        options={languages.map(language => {
                                            return { name: language };
                                        })}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </Model>
    );
};

export default ModelView;
