import { Fade } from '@mui/material';
import Loading from 'app/presentation/components/common/Loading';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import React from 'react';
import { Backdrop, SlideBackground, StyledSlide } from './styles';

export type SliderModalProps = {
    animateSlide: boolean;
    children?: React.ReactElement;

    onExit?: () => void;

    zIndex?: number;
};

// maybe this component could be more generic for both modals but it would be a huge pain to do now
export const SliderModal: React.FC<SliderModalProps> = props => {
    const { animateSlide, children } = props;
    const { loading } = useMemoriesContext();

    return (
        <>
            {animateSlide && <Backdrop zIndex={props.zIndex} />}
            {loading && <Loading open={loading} />}

            <Fade in={animateSlide} timeout={300}>
                <SlideBackground />
            </Fade>

            <StyledSlide
                direction="left"
                in={props.animateSlide}
                timeout={300}
                onExited={() => props.onExit && props.onExit()}
                zIndex={props.zIndex}
            >
                {children || <></>}
            </StyledSlide>
        </>
    );
};
