import styled from 'styled-components';

export const AudioText = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;

    .emptyAudio {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 442px;
        margin: 40px auto;

        h1 {
            color: #000;
            font-size: 1.5rem;
            font-weight: 700;
            text-align: center;
        }

        p {
            text-align: center;
            margin-top: 14px;
            color: #a09fa5;
        }

        button {
            margin-top: 30px;
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 48px;
            border: none;
            cursor: pointer;
            border-radius: 12px;
            background: #d0ff61;
            padding: 8px 24px;
            font-weight: 700;
            font-size: 0.875rem;
            svg {
                margin-right: 8px;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;

        .input > div {
            margin: 0;
        }

        .input {
            position: relative;
            display: flex;
            align-items: center;

            .icon {
                position: absolute;
                left: 18px;
            }

            input {
                padding-left: 58px;
                height: 48px;
            }
        }

        button {
            display: flex;
            align-items: center;
            border: none;
            cursor: pointer;
            border-radius: 12px;
            background: #d0ff61;
            padding: 8px 24px;
            font-weight: 700;
            font-size: 0.875rem;
            svg {
                margin-right: 8px;
            }
        }
    }

    .contentContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .createAudioContent {
            margin-top: 70px;
            display: flex;
            flex-direction: column;
            padding: 50px 35px;
            border-radius: 12px;
            border: 1px solid #e2e8f0;
            width: 100%;
            max-width: 414px;

            .icon {
                padding: 18px;
                border-radius: 9999px;
                background: #f7fafc;
                width: 65px;
                height: 65px;
            }

            .title {
                margin-top: 30px;
                color: #000;
                font-size: 20px;
                font-weight: 700;
            }

            .desc {
                color: #a09fa5;
                font-weight: 500;
                margin-top: 10px;
                margin-bottom: 30px;
                text-align: justify;
            }

            ul {
                li {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    color: #000;
                    font-weight: 500;
                    margin-bottom: 12px;
                    svg {
                        margin-right: 10px;
                    }
                }

                li:last-of-type {
                    margin-bottom: 0;
                }
            }

            button {
                margin-top: 30px;
                width: 100%;
                max-width: 170px;
                height: 44px;
                border-radius: 8px;
                border: 1px solid #e2e8f0;
                display: flex;
                align-items: center;
                padding: 16px;
                background: transparent;
                cursor: pointer;
                color: #000;
                font-weight: 600;
                svg {
                    margin-left: 8px;
                }
            }
        }
    }

    &.darkAudio {
        background-color: #000;

        .contentContainer {
            .createAudioContent {
                .icon {
                    background: #1d1d1d;
                }

                .title {
                    color: #fff;
                }

                .desc {
                    color: #a09fa5;
                }

                ul {
                    li {
                        color: #fff;
                    }
                }

                button {
                    color: #fff;
                }
            }
        }
    }
`;
