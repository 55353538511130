import React from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../app/presentation/contexts/AuthContext';
import { routeTranslate } from '../utils/intl';
import { useIntl } from 'react-intl';
import { DashboardRoutes } from './dashboardRoutes';
import Answers from '../pages/onboarding';
import { auth } from 'config/firebaseSetup';
import { MessagesRoutes } from './messagesRoutes';
import { AgentRoutes } from './agentRoutes';

export const AuthRoutes = () => {
    const user = React.useContext(AuthContext);

    const intl = useIntl();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const mode = searchParams.get('mode');

    React.useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (!user) {
                if (mode !== 'resetPassword') {
                    navigate(
                        `/${routeTranslate(
                            intl,
                            'route.urlLang',
                        )}${routeTranslate(intl, 'route.signin')}${
                            searchParams ? '?' + searchParams.toString() : ''
                        }`,
                    );
                }
            }
        });
    }, []);

    if (!user) {
        return <></>;
    }

    return (
        <Routes>
            <Route
                path={`:language${routeTranslate(intl, 'route.backoffice')}/*`}
                element={<DashboardRoutes />}
            />
            <Route
                path={`:language${routeTranslate(intl, 'route.begin')}/*`}
                element={<Answers />}
            />
            <Route
                path={`:language${routeTranslate(intl, 'route.messages')}/*`}
                element={<MessagesRoutes />}
            />
            <Route
                path={`:language${routeTranslate(intl, 'route.agent.home')}/*`}
                element={<AgentRoutes />}
            />
        </Routes>
    );
};
