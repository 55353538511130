import styled from 'styled-components';

export const StageStyled = styled.div`
    margin-bottom: 33px;

    h1 {
        text-align: center;
        font-size: 18px;
        color: #718096;
        font-weight: 500;
        margin-bottom: 13px;
    }

    .circles {
        display: flex;
        justify-content: center;
    }

    .circles > div {
        width: 6px;
        height: 6px;
        background: #1a202c;
        margin: 0 4px;
        border-radius: 6px;

        &.active {
            width: 24px;
            height: 6px;
            background: #d0ff61;
            border-radius: 50px;
            opacity: 1;
        }
    }
`;
