import React from 'react';
import * as S from './styles';
import { Menu } from 'app/presentation/components/icons';
import Slide from '@mui/material/Slide';
import { Link, useLocation } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { AgentMenu } from '../AgentMenu';
import useMenu from 'app/presentation/hooks/useMenu';
import { IconBell, IconBolt, IconInbox } from '@tabler/icons-react';
import useText from 'app/presentation/hooks/useText';
import useAgent from 'app/presentation/hooks/useAgent';

const AgentToolbar: React.FC = () => {
    const { menu, setMenu } = useMenu();

    const { routeGetter } = useText();
    const agentRoutes = routeGetter('agent');
    const { pathname } = useLocation();
    const { darkmode } = useDarkMode();
    const { agent } = useAgent();

    const handleMenu = () => {
        setMenu(!menu);
    };

    const CloseMenu = () => {
        setMenu(false);
    };

    const disableLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <S.AgentToolbar
                className={`container ${darkmode && 'containerToolbarDark'}`}
            >
                <div className="content">
                    <S.ToolbarContainer
                        className={darkmode ? 'darkInside' : ''}
                    >
                        <ul>
                            <li onClick={handleMenu}>
                                {!menu ? <Menu /> : <IoMdClose size={26} />}
                            </li>
                            <Link onClick={CloseMenu} to={agentRoutes('home')}>
                                <li
                                    className={
                                        pathname == agentRoutes('home') ||
                                        pathname.startsWith(
                                            agentRoutes('home', {param: agent ? agent.id : '0'}),
                                        )
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    <IconBolt />
                                </li>
                            </Link>
                            <Link
                                onClick={/*CloseMenu*/ disableLink}
                                to={agentRoutes('home', 'inbox')}
                            >
                                <li
                                    className={
                                        /*pathname == agentRoutes('home', 'inbox')
                                            ? 'active'
                                            : ''*/ 'disabled'
                                    }
                                >
                                    <IconInbox />
                                </li>
                            </Link>
                            <Link
                                onClick={CloseMenu}
                                to={agentRoutes('home', 'notifications')}
                            >
                                <li
                                    className={
                                        pathname ==
                                        agentRoutes('home', 'notifications')
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    <IconBell />
                                </li>
                            </Link>
                        </ul>
                    </S.ToolbarContainer>
                    <div className="clear"></div>
                </div>
                <S.BodyPadding />
                <Slide direction="right" in={menu}>
                    <S.MenuMobile>
                        <AgentMenu handleCloseMenu={handleMenu} />
                    </S.MenuMobile>
                </Slide>
            </S.AgentToolbar>
        </>
    );
};

export default AgentToolbar;
