import styled from 'styled-components';

export const TableStyle = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    tbody {
        tr {
            cursor: pointer;
        }
    }

    tr {
        border-bottom: 1px solid #edf2f7;
        height: 80px;

        .action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    tr:last-of-type {
        border-bottom: 0;
    }

    th {
        color: #718096;
        font-weight: 700;
        vertical-align: middle;

        .order {
            position: relative;
            top: 3px;
            right: -5px;
        }
    }

    td {
        color: #000000;
        font-weight: 500;
        text-align: left;
    }

    .name-table {
        display: flex;
        align-items: center;
        position: relative;
    }

    .icon {
        margin: 0 24px 0 0 !important;
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 1000px;
        border: 1px solid #e2e8f0;
        background: transparent;
    }

    .containerRenameInput {
        display: flex;
        position: absolute;
        left: 73px;

        button {
            margin-left: 8px;
            cursor: pointer;
            border: none;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input {
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #edf2f7;
        }
    }

    .optionsFromSelected {
        display: flex;
        align-items: center;
        .icon {
            margin: 0 !important;
            background: transparent !important;
            border: none !important;
            cursor: pointer;
        }
    }

    &.darkTable {
        background: #000;

        .containerRenameInput {
            input {
                color: #fff;
                background: #000;
                border-color: #fff;
            }
        }
    }
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 34px;
    border-top: 1px solid #edf2f7;
    background: red;

    &.dark {
        border-top: 1px solid #2a313c !important;
    }

    button {
        color: #718096;
    }

    .Mui-selected {
        border: 1px solid #1a202c;
        border-radius: 8px;
        min-width: 40px;
        height: 40px;
        color: #000000;
        font-weight: 700;
        background: #fff !important;
    }

    .per-page {
        display: flex;
        align-items: center;
        gap: 16px;
        min-width: 214px;

        span {
            color: #a0aec0;
            font-size: 0.875em;
            font-weight: 700;
            flex: 1;
        }

        > div {
            width: 68px;
            min-height: 37px;

            select {
                font-weight: 700;
                font-size: 0.875em;
                padding: 0 12px;
                width: 68px;
                min-height: 37px;
                height: 37px;
                border-radius: 8px;
            }
        }
    }
`;

export const MuiMenuHeader = styled.div`
    .menu-mui-header-workspace {
        margin-top: 9px;
        padding-bottom: 17px;
        border-bottom: 2px solid #edf2f7;
        margin-bottom: 8px;

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        a {
            font-weight: 500;
            font-size: 0.75em;
            color: #194bfb;
        }
    }

    .menu-mui-header {
        margin-top: 9px;
        padding: 0 16px 16px 16px;
        border-bottom: 1px solid #edf2f7;

        #search > div {
            height: 42px;
            margin: 0;
        }

        input {
            height: 42px;
            border: 1px solid #718096;
            border-radius: 8px;
        }

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        p {
            font-weight: 500;
            font-size: 0.75em;
            color: #718096;
        }
    }

    ul.workspace {
        li {
            border-bottom: 0;
            padding: 0;
            cursor: pointer;

            span {
                font-weight: 600;
            }
        }
    }

    ul {
        list-style: none;

        li {
            cursor: pointer;
            height: 42px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            border-bottom: 1px solid #edf2f7;

            &:hover {
                background-color: #f7fafc;
            }
        }

        li:last-of-type {
            border-bottom: 0;
        }

        span {
            color: #718096;
            font-size: 0.75em;
            font-weight: 600;
            margin-left: 12px;
        }
    }
    &.muiHeaderDark {
        ul {
            li {
                span {
                    color: #fff !important;
                }
            }
        }
    }
`;
