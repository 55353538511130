import styled, { css } from 'styled-components';

export const Config = styled.div`
    ${({
        theme: {
            agent: {
                pages: {
                    config: { background },
                },
            },
        },
    }) => css`
        position: relative;
        padding: 30px 0px;
        display: flex;
        justify-content: center;
        max-height: calc(100vh - 80px);
        overflow-y: scroll;
        background-color: ${background.color};
        @media (min-width: 64em) {
            padding: 30px 28px;
            justify-content: start;
        }

        .config-form-container {
            min-width: 331px;
            width: 331px;
            height: fit-content;

            @media (min-width: 64em) {
                width: 100%;
                max-width: 1014px;
            }

            .title {
                letter-spacing: 0.2px;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                margin-bottom: 30px;
            }
        }
    `}
`;
