import React from 'react';
import {
    Button,
    HeaderModalDetails,
    Input,
    TableTeam,
} from 'app/presentation/components';
import { TeamContent, DocumentsContainer } from './styles';
import { Search } from 'app/presentation/components/icons';
import { FieldValues, useForm } from 'react-hook-form';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { MdAdd } from 'react-icons/md';
import ModalInviteMember from 'app/presentation/components/dashboard/ModalInviteMember';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import useTeam from 'app/presentation/hooks/useTeam';
import { FormattedMessage, useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import { WorkspacesService } from '../../../../services/workspaces';
import { useSnackbar } from 'notistack';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { TbLock } from 'react-icons/tb';
import { Link } from 'react-router-dom';

const workspaceService = new WorkspacesService();

const Team: React.FC = () => {
    const [modal, setModal] = React.useState(false);
    const { register, watch } = useForm<FieldValues>();
    const { team, getTeam } = useTeam();
    const { workspace, settings, limits } = useUserConfig();
    const intl = useIntl();
    const isOwner = workspace.owner_id === settings.id;
    const { darkmode } = useDarkMode();
    const isFull =
        limits.total_seats === -1
            ? false
            : limits.used_seats >= limits.total_seats;

    const { enqueueSnackbar } = useSnackbar();

    const watchSearch = watch('search');

    React.useEffect(() => {
        const fetchData = async (search: string) => {
            await getTeam(1, 6, search);
        };

        fetchData(watchSearch).catch(console.error);
    }, [watchSearch]);

    React.useEffect(() => {
        const fetchData = async () => {
            await getTeam();
        };

        fetchData().catch(console.error);
    }, [workspace]);

    const handleModal = () => {
        setModal(!modal);
    };

    const handleUpdateWordLimit = async (userId: number, limit: number) => {
        try {
            await workspaceService.updateWordLimit(workspace.id, userId, limit);
        } catch {
            enqueueSnackbar(
                'Não foi possível atualizar o numero de palavras desse usuário!',
                {
                    variant: 'error',
                },
            );
        }
    };

    if (!team) return <></>;

    return (
        <TeamContent>
            <HeaderModalDetails
                title={routeTranslate(intl, 'definitions.team')}
                hasAd={isFull}
                adElement={
                    <p
                        style={{
                            color: '#7E7E7E',
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}
                    >
                        <Link
                            style={{
                                color: '#194BFB',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}
                            to={`/${routeTranslate(
                                intl,
                                'route.urlLang',
                            )}${routeTranslate(
                                intl,
                                'route.backoffice',
                            )}${routeTranslate(
                                intl,
                                'route.definitions',
                            )}${routeTranslate(intl, 'route.plans')}`}
                        >
                            <TbLock size={16} />
                            <FormattedMessage id="definitions.team.add.seats.1" />
                        </Link>
                        &nbsp;
                        <FormattedMessage id="definitions.team.add.seats.2" />
                    </p>
                }
            />
            <div className="header-team">
                <div className="input">
                    <div className="icon">
                        <Search darkmode={darkmode} />
                    </div>
                    <Input
                        id="search"
                        placeholder={routeTranslate(intl, 'team.search')}
                        register={register}
                    />
                </div>
                <Button
                    onClick={handleModal}
                    icon={<MdAdd size={24} />}
                    disabled={!isOwner || isFull}
                >
                    {routeTranslate(intl, 'team.invite')}
                </Button>
            </div>
            <DocumentsContainer>
                {team ? (
                    team.map(member => {
                        const date = new Date(member.created_at);
                        const dateString = date.toLocaleString('pt-BR', {
                            month: 'long',
                            year: 'numeric',
                            day: 'numeric',
                        });

                        return (
                            <div className="box">
                                <div className="header">
                                    <div className="flex">
                                        <h1>{member.user.email}</h1>
                                    </div>
                                    <div className="dots">
                                        <HiOutlineDotsHorizontal
                                            size={22}
                                            style={{ color: '#718096' }}
                                        />
                                    </div>
                                </div>
                                <div className="infos">
                                    <div className="info">
                                        <strong>
                                            {routeTranslate(intl, 'team.role')}
                                        </strong>
                                        <span></span>
                                    </div>
                                    <div className="info">
                                        <strong>
                                            {routeTranslate(intl, 'team.limit')}
                                        </strong>
                                        <Input
                                            id="number"
                                            placeholder="10.000"
                                            register={register}
                                            disabled={!isOwner}
                                        />
                                    </div>
                                    <div className="info">
                                        <strong>
                                            {routeTranslate(
                                                intl,
                                                'team.joined',
                                            )}
                                        </strong>
                                        <span>{dateString}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
                <article id="table">
                    <TableTeam
                        data={team}
                        isOwner={isOwner}
                        handleUpdateWordLimit={handleUpdateWordLimit}
                    />
                </article>
            </DocumentsContainer>
            <ModalInviteMember active={modal} handleClose={handleModal} />
        </TeamContent>
    );
};

export default Team;
