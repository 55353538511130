import React from 'react';
import { AudioTextNew } from './styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

import { Button } from 'app/presentation/components';
import { BsArrowRight } from 'react-icons/bs';
import SendView from './steps/send';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { routeTranslate } from 'utils/intl';
import { AudioService } from 'services/audio';
import ModelView from './steps/model';
import FinalView from './steps/final';
import {
    TbClipboardList,
    TbFileText,
    TbMessage,
    TbMessages,
    TbMoodSmile,
    TbSpeakerphone,
    TbTextPlus,
} from 'react-icons/tb';
import { MdOutlineTranslate } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { TranscriptIds } from 'interfaces/audio-ai.interface';

export type IStep = 'send' | 'model' | 'final';
const audioService = new AudioService();

export const modelList = [
    {
        id: TranscriptIds.transcription,
        icon: <TbMessage size={20} />,
        title: 'Transcrição',
        description:
            'Converta automaticamente seus arquivos de áudio e vídeo em texto com um modelo avançado de IA.',
    },
    {
        id: TranscriptIds.paragraph_summarization,
        icon: <TbFileText size={20} />,
        title: 'Sumarização',
        description:
            'Jarbas pode gerar um resumo claro e objetivo sobre todo o conteúdo que foi transcrito.',
    },
    {
        id: TranscriptIds.full_summarization,
        icon: <TbTextPlus size={20} />,
        title: 'Resumo detalhado',
        description:
            'Resuma seções de conteúdo em seu áudio em detalhes para melhor legibilidade e análise.',
    },
    {
        id: TranscriptIds.sentiment_analysis,
        icon: <TbMoodSmile size={20} />,
        title: 'Análise de sentimentos',
        description:
            'Determine opiniões positivas, neutras ou negativas para melhor CX, saúde da marca e muito mais.',
    },
    {
        id: TranscriptIds.keyword_detection,
        icon: <TbClipboardList size={20} />,
        title: 'Detecção de tópico',
        description:
            'Identifique os principais tópicos para facilitar a pesquisa e descobrir tendências e insights.',
    },
    // {
    //     id: TranscriptIds.entity_detection,
    //     icon: <TbListSearch size={20} />,
    //     title: 'Detecção de Entidades',
    //     description:
    //         'Identifique uma variedade de entidades, como nomes de pessoas e empresas, locais e outros.',
    // },
    {
        id: TranscriptIds.important_sentences,
        icon: <TbSpeakerphone size={20} />,
        title: 'Frases Importantes',
        description:
            'Detecte automaticamente frases e palavras importantes em sua fonte.',
    },
    {
        id: TranscriptIds.speakers_detection,
        icon: <TbMessages size={20} />,
        title: 'Locutores',
        description:
            'Saiba quem está falando. Detecte  as alterações de locutor durante uma conversa.',
    },
    {
        id: TranscriptIds.translation,
        icon: <MdOutlineTranslate size={20} />,
        title: 'Tradução',
        description:
            'Converta a transcrição do seu audio em dezenas de idiomas disponíveis.',
    },
];

const NewTextTransformView: React.FC<{
    hasId: boolean;
}> = ({ hasId }) => {
    const { darkmode } = useDarkMode();
    const location = useLocation();
    const navigate = useNavigate();
    const [step, setStep] = React.useState<IStep>(hasId ? 'final' : 'send');
    const [file, setFile] = React.useState<File>();
    const [btnLoading, setBtnLoading] = React.useState(false);
    const id = location.pathname.split('audio/')[1];
    const [resAudioId, setResAudioId] = React.useState(
        hasId && id ? Number(id) : 0,
    );
    const [videoLink, setVideoLink] = React.useState('');
    const [selected1, setSelected1] = React.useState<'link' | 'file'>('link');
    const [selectedModels, setSelectedModels] = React.useState<Array<string>>(
        [],
    );
    const [translationLanguage, setTranslationLanguage] =
        React.useState('Portuguese');
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();

    React.useEffect(() => {
        if (hasId && id) {
            setStep('final');
            setResAudioId(Number(id));
        }
    }, [hasId]);

    const submitAudio = async () => {
        setBtnLoading(true);
        const formData = new FormData();
        const arr = selectedModels.filter(
            item => item !== TranscriptIds.transcription,
        );
        const commaSeparatedString: string = arr.join(',');

        formData.append('actions', commaSeparatedString);

        if (arr.includes(TranscriptIds.translation) && translationLanguage) {
            formData.append('translation_output_language', translationLanguage);
        }

        if (file) {
            formData.append('audio', file);
        }

        if (videoLink !== '') {
            formData.append('youtube_url', videoLink);
        }

        try {
            const result = await audioService.generate(formData);

            if (
                result &&
                result.data &&
                result.data.id &&
                typeof result.data.id === 'number'
            ) {
                setResAudioId(result.data.id);
                const path =
                    routeTranslate(intl, 'route.urlLang') +
                    routeTranslate(intl, 'route.backoffice');

                navigate(
                    `/${path}${routeTranslate(intl, 'route.audioAi')}/${
                        result.data.id
                    }`,
                );
            }
        } catch (e: any) {
            if (e.error === 'youtube_video_too_long') {
                setStep('send');
            }
            setBtnLoading(false);
        }
    };

    const handleChangeStep = async () => {
        if (step === 'send') {
            if (selected1 === 'link' && videoLink === '') {
                return;
            }
            if (selected1 === 'file' && file === undefined) {
                return;
            }
            if (
                selected1 === 'link' &&
                (!videoLink.includes('youtube') ||
                    !videoLink.includes('http') ||
                    !videoLink.includes('watch') ||
                    videoLink.length < 10)
            ) {
                enqueueSnackbar(routeTranslate(intl, 'audioai.error.link'), {
                    variant: 'error',
                });
                return;
            }
            setStep('model');
        } else if (step === 'model') {
            submitAudio();
        }
    };

    return (
        <AudioTextNew className={darkmode ? 'darkAudio' : ''}>
            <div className="steps">
                <div>
                    <p
                        className={`${step === 'send' ? 'active' : ''} ${
                            step === 'model' || step === 'final' ? 'done' : ''
                        }`}
                    >
                        • <FormattedMessage id="audioai.steps.send" />
                    </p>
                    <p
                        className={`${step === 'model' ? 'active' : ''} ${
                            step === 'final' ? 'done' : ''
                        }`}
                    >
                        • <FormattedMessage id="audioai.steps.model" />
                    </p>
                    <p className={`${step === 'final' ? 'active' : ''}`}>
                        • <FormattedMessage id="audioai.steps.final" />
                    </p>
                </div>

                {step === 'model' && (
                    <Button
                        onClick={handleChangeStep}
                        icon={<BsArrowRight />}
                        loading={btnLoading}
                    >
                        <FormattedMessage id="button.next" />
                    </Button>
                )}
            </div>
            {step === 'send' && (
                <SendView
                    setStep={handleChangeStep}
                    file={file}
                    setFile={setFile}
                    setVideoLink={setVideoLink}
                    selected={selected1}
                    setSelected={setSelected1}
                    videoLink={videoLink}
                />
            )}
            {step === 'model' && (
                <ModelView
                    setSelectedModels={setSelectedModels}
                    selectedModels={selectedModels}
                    setTranslationLanguage={setTranslationLanguage}
                />
            )}
            {step === 'final' && <FinalView audioId={resAudioId} />}
        </AudioTextNew>
    );
};

export default NewTextTransformView;
