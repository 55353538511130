const theme = {
    breakpoints: {
        largeMobile: '(min-width: 20.5em)', //328px
        tablet: '(min-width: 48em)', // 768px
        desktop: '(min-width: 90em)', //1440px
    },
    colors: {
        backgroundDefault: '#FFFFFF',
        colorDefault: '#232B38',
    },
};

export default theme;
