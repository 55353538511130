import React from 'react';
import { StageStyled } from './styles';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface StagesProps {
    current: number;
    total: number;
}

export const Stage: React.FC<StagesProps> = ({ current, total }) => {
    const items = [];
    const intl = useIntl();
    const { darkmode } = useDarkMode();

    for (let i = 1; i <= total; i++) {
        items.push(i);
    }

    return (
        <StageStyled>
            <h1 style={{ color: darkmode ? '#fff' : '' }}>
                {routeTranslate(intl, 'step')} {current}{' '}
                {routeTranslate(intl, 'step.of')} {total}
            </h1>
            <div className="circles">
                {items.map(item => (
                    <div className={item === current ? 'active' : ''}></div>
                ))}
            </div>
        </StageStyled>
    );
};
