import styled from 'styled-components';

export const DetailsContent = styled.div`
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 70px;

    #change-img {
        text-align: center;
    }

    #image {
        width: 100px;
        height: 100px;
        border: 1px solid #d0ff61;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .border {
            width: 88px;
            height: 88px;
            border-radius: 88px;
            border: 1px solid #d0ff61;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
    }

    #form {
        label {
            color: #718096;
        }

        .content-form {
            > div div {
                margin-bottom: 24px;
            }
        }

        button[type='submit'] {
            margin-top: -2px;
            width: 100%;
            height: 48px;
            font-size: 0.875em;
        }
    }

    #submit-image {
        margin-top: 32px;
        margin-bottom: 48px;
        display: flex;
        justify-content: center;
        gap: 12px;
        height: 40px;

        button[type='submit'] {
            width: 180px;
            font-size: 0.75em;
            border-radius: 8px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;

            svg {
                margin-right: 11px;
                margin-left: 0px !important;
            }
        }

        #remove {
            width: 90px;
            background: #ffffff;
            border: 1px solid #e2e8f0;
            border-radius: 8px;
            font-size: 0.75em;
        }
    }

    .box {
        padding: 22px 20px 20px;
        border: 1px solid #edf2f7;
        border-radius: 16px;
        margin-top: 32px;

        &#fature {
            height: 168px;

            h1 {
                font-weight: 700;
                font-size: 1.25em;
                margin-bottom: 12px;
            }

            p {
                font-size: 1em;
                color: #718096;
                font-weight: 500;
                margin-bottom: 0.875em;
                margin-bottom: 15px;
            }

            button {
                color: #1a202c;
                font-size: 0.875em;
                border: 1px solid #e2e8f0;
                border-radius: 8px;
                background: #fff;
                width: 154px;
                height: 42px;
                padding: 0;
            }
        }

        &#account-cancel {
            height: auto;

            h1 {
                font-weight: 700;
                font-size: 1.25em;
                margin-bottom: 12px;
            }

            p {
                font-size: 0.875em;
                color: #718096;
                font-weight: 500;
                margin-bottom: 0.875em;
                margin-bottom: 12px;
            }

            button {
                color: #1a202c;
                font-size: 0.875em;
                border: 1px solid #e2e8f0;
                border-radius: 12px;
                background: #fff;
                width: 94px;
                height: 48px;
                padding: 0;
            }
        }
    }

    @media (min-width: 64em) {
        margin-bottom: 0;
        overflow-y: hidden;

        #form {
            .content-form {
                display: flex;
                gap: 24px;

                > div {
                    flex: 1;

                    div {
                        margin-bottom: 24px;
                    }
                }

                > div div {
                    margin-bottom: 32px;
                }
            }

            button[type='submit'] {
                width: 145px;
            }
        }
    }
`;
