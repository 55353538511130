import styled from 'styled-components';

export const Header = styled.div`
    max-width: 880px;
    margin: 24px auto 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;

    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.5em;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        span {
            margin-left: 13px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 66px;
            height: 24px;
            background: #d0ff61;
            border-radius: 100px;
            font-size: 14px;
        }
    }

    #history {
        width: 118.25px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        font-size: 0.875em;
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
        font-weight: 600;

        #icon {
            margin-left: 0 !important;
            margin-right: 8px;
        }
    }

    @media (min-width: 64em) {
        padding: 0 40px;
        margin: 24px auto 55px auto;

        max-width: none;
        margin-top: -36px;
        margin-right: 235px;
        p {
            display: block;
        }
    }

    &.headerDark {
        h1 {
            color: #fff;
            .beta {
                color: #000;
            }
        }

        p {
            color: #a09fa5;
        }

        button {
            background: #000 !important;
            color: #fff;
        }
    }
`;

export const Form = styled.form`
    .label-custom {
        margin-bottom: 25px;
    }

    > div {
        margin-bottom: 20px;
    }

    input,
    select {
        border-radius: 8px;
    }

    select.empty {
        color: #a0aec0;

        option {
            color: #000;
        }
    }

    textarea {
        height: 112px;
        font-size: 1rem;
    }

    .content-submit {
        padding-top: 5px;
        label,
        input {
            position: relative;
            display: block;
            width: 130px;
            box-sizing: border-box;
            height: 48px;
        }

        label::after {
            content: attr(data-domain);
            position: absolute;
            top: 33%;
            left: 35%;
            font-size: 0.875rem;
            display: block;
            color: #a09fa5;
            font-weight: bold;
        }

        .buttons {
            > div {
                width: 70px;
                height: 48px;

                input {
                    height: 48px;
                    border-radius: 8px;
                }
            }

            display: flex;
            gap: 7px;
        }

        #clean {
            margin-top: 30px;
            text-align: start;
            padding: 16px 8px;
            height: 34px;
            border: 1px solid #e2e8f0;
            border-radius: 6px;
            font-size: 0.875em;
            background: #f7fafc;
            display: flex;
            flex-direction: row-reverse;
            font-weight: 700;
            #icon {
                margin-left: 0 !important;
                margin-right: 13px;
            }
        }

        button {
            font-size: 0.875em;
            padding: 0;
        }

        button[type='submit'] {
            width: 100px;
            height: 48px;
            background: #d0ff61;
            color: #1a202c;
            border-radius: 8px;
        }

        button[type='button'] {
            border-radius: 8px;
            width: 135px;
            height: 48px;
            border: 1px solid #cbd5e0;
            color: #1a202c;
            background-color: #fff;
        }
    }

    .textarea-filters {
        height: 112px;

        .scrollFilters {
            display: flex;
            overflow-x: scroll !important;
            &::-webkit-scrollbar {
                display: none !important;
            }

            &::-moz-scrollbar {
                display: none !important;
            }
        }

        .filters-list {
            display: flex;
            margin-left: 16px;
            margin-top: -55px;
            width: 97%;

            .filter-content {
                min-width: fit-content;

                color: #000;
                font-weight: 700;
                display: flex;
                align-items: center;
                height: 35px;
                background: #f7fafc;
                border: 1px solid #e2e8f0;
                border-radius: 4px;
                font-size: 0.75rem;
                margin-right: 8px;
                padding: 0 13px;
                .icon {
                    margin-left: 15px;
                    cursor: pointer;
                    color: #a09fa5;
                    font-size: 0.875rem;
                }

                img {
                    margin-right: 10px;
                    border-radius: 4px;
                }
            }
        }
    }

    @media (min-width: 64em) {
        .content-submit {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            #clean {
                margin: 0;
                width: 160px;
                height: 34px;
            }

            button[type='button'] {
                border: 0;
            }
        }
    }

    &.formDark {
        .textarea-filters {
            .filters-list {
                .filter-content {
                    color: #fff;
                    height: 35px;
                    background: #000;
                    border-color: #000;

                    .icon {
                        margin-left: 15px;
                        cursor: pointer;
                        color: #a09fa5;
                        font-size: 0.875rem;
                    }

                    .img-invert {
                        filter: invert(1);
                    }
                }
            }
        }
    }
`;

export const Images = styled.div`
    flex: 2;
    position: relative;

    .header-images {
        > svg {
            cursor: pointer;
            margin-bottom: 20px;
        }
    }

    .images {
        margin-bottom: 40px;

        .options {
            margin-bottom: 15px;
            display: flex;
            gap: 16px;

            > div {
                cursor: pointer;
            }
        }



        .logo {
            margin-top: 15px;
            text-align: center;

            p {
                font-size: 0.625em;
            }

            svg {
                width: 100px;
            }
        }

        .grid {
            display: grid;
            gap: 7px;

            &.active {
                grid-template-columns: 1fr !important;
            }

        }
    }

    @media (min-width: 64em) {
        padding: 0;
        width: 30vw;

        .grid {
            grid-template-columns: 1fr 1fr;

        }

        .header-images {
            display: flex;
            justify-content: space-between;

            > svg {
                margin-bottom: 0px;
            }
        }

        .images {
            height: 50vh;

        .empty {
            height: 100%;
        }
        }
    }

    
    @media (min-width: 105em) {
        .images {
            height: 56vh;

        }
    }

    @media (min-width: 118em) {

        .images {
            height: 59vh;

        }
    }

    @media (min-width: 130em) {
        .images {
            height: 64vh;

        }
    }

    @media (min-width: 150em) {
        .images {
            height: 68vh;

        }
    }
    @media (min-width: 160em) {
        .images {
            height: 70vh;

        }
    }
    @media (min-width: 175em) {
        .images {
            height: 73vh;

        }
    }
    @media (min-width: 237em) {
        .images {
            height: 80vh;

        }
    }

    @media (min-width: 305em) {
        .images {
            height: 92vh;

        }
    }
}

    &.imagesContainerDark {
        header {
            button {
                background: #000 !important;
                color: #cccccc;
                border: 1px solid #cccccc;
            }
        }

        .images {         

        .grid {
            .box {
                .rate,
                .icon,
                .iconDownload {
                    background-color: #1d1d1f;
                }
            }
        }
    }
`;

export const DropdownContent = styled.div<{ isOpen: boolean }>`
    opacity: ${props => (props.isOpen ? 1 : 0)};
    transform: translateY(${props => (props.isOpen ? '0' : '-10px')});
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
`;

export const Responsive = styled.div`
    max-width: 880px;
    margin: 0 auto;

    .image-options {
        width: 100%;
        max-height: 750px;
        overflow-y: scroll;
        margin-bottom: 20px;
        border: 1px solid #edf2f7;
        padding: 21px 24px;
        border-radius: 16px;
        background: #fff;

        ::-webkit-scrollbar {
            width: 4px;
        }

        ::-webkit-scrollbar-thumb {
            background: #e2e8f0;
            margin-right: 2px;
            border-radius: 50px !important;
        }

        .ratio-section {
            p {
                margin-bottom: 4px;
                font-size: 0.875rem;
                color: #000;
                font-weight: 700;
            }
            .img-ratios {
                display: flex;
                justify-content: space-between;
                svg {
                    width: 24px;
                    height: 24px;
                }
                margin: 8px 0;
            }
            .ratiosOptions {
                display: flex;
                align-items: center;
                justify-content: space-between;

                div {
                    display: flex;
                    align-items: center;
                    p {
                        margin-left: 8px;
                        font-weight: 500;
                    }

                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 32px;
                        height: 32px;
                        background: #edf2f7;
                        border-radius: 4px;
                        font-size: 24px;
                    }
                }

                .checked-icon {
                    width: 18px;
                    height: 18px;
                    background: #d0ff61;
                    border: 1px solid #000000;
                    color: #000;
                    border-radius: 9999px;
                    display: none;
                }
            }
        }

        .lineHr {
            border: none;
            border-bottom: 1px solid #e2e8f0;
            margin-top: 26px;
            margin-bottom: 14px;
        }

        .type-section {
            p {
                font-weight: 700;
                color: #000000;
            }
            .type-buttons {
                margin-top: 12px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 6px;

                p {
                    font-weight: 500;
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 15px;
                    height: 42px;
                    width: 100%;

                    background: #f7fafc;
                    border: 1px solid #cbd5e0;
                    font-size: 0.875rem;
                    border-radius: 4px;
                    cursor: pointer;

                    .photo-name {
                        border: none;
                        padding: 0;
                        margin: 0;
                        height: auto;
                        width: auto;
                        p {
                            margin-left: 15px;
                            font-size: 0.75rem;
                            color: #a09fa5;
                        }

                        @media (max-width: 64em) {
                            p {
                                margin-left: 5px;
                                font-size: 11px;
                            }
                        }
                        img {
                            opacity: 0.6;
                            border-radius: 4px;
                        }
                    }
                }

                .selected {
                    border-color: #000;

                    .photo-name {
                        p {
                            color: #000;
                        }

                        img {
                            opacity: 1;
                        }
                    }

                    .checked-icon {
                        width: 18px;
                        height: 18px;
                        background: #d0ff61;
                        border: 2px solid #000000;
                        color: #000;
                        border-radius: 9999px;
                    }
                }
            }
        }

        .style-section {
            p {
                display: flex;
                align-items: center;
                font-weight: 700;
                color: #000000;

                span {
                    display: flex;
                    align-items: center;
                    margin-right: 6px;
                    transition-duration: 0.5s;
                }

                .iconDropdownClose {
                    transform: rotate(-180deg);
                }
            }

            .scrollFilters {
                overflow-x: scroll !important;
                font-size: 0.75rem;
                &::-webkit-scrollbar {
                    display: none !important;
                }

                &::-moz-scrollbar {
                    display: none !important;
                }
                .style-filters {
                    margin: 11px 0;
                    width: max-content;
                    .filterButton {
                        cursor: pointer;
                        color: #000;
                        background: #fff;
                        border: 1px solid #e2e8f0;
                        border-radius: 30px;
                        padding: 5px 16px;
                        font-weight: 500;
                        margin-right: 6px;
                    }

                    .selectedFilter {
                        background: #d0ff61;
                        font-weight: 700;
                        border-color: #d0ff61;
                    }
                }
            }

            .style-options {
                p {
                    color: #000;
                    font-weight: 700;
                }

                .options {
                    margin-top: 12px;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 25px;

                    .style-option {
                        position: relative;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        .checked-icon {
                            position: absolute;
                            top: 7px;
                            right: 7px;
                            width: 18px;
                            height: 18px;
                            background: #d0ff61;
                            border: 2px solid #000000;
                            color: #000;
                            border-radius: 9999px;
                        }

                        p {
                            font-size: 0.75rem;
                            color: #000;
                            font-weight: 500;
                            height: 40px;
                        }
                        img {
                            border-radius: 4px;
                        }
                    }

                    .styleSelected {
                        img {
                            border: 2.5px solid #000000;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 64em) {
        max-width: none;
        .image-options {
            max-width: 360px;
            max-height: none;
            height: 77vh;
        }

        display: flex;
        justify-content: flex-end;
        gap: 38px;

        > form,
        > div {
            flex: 1;
        }
    }
    @media (min-width: 105em) {
        .image-options {
            height: 80vh;
        }
    }

    @media (min-width: 118em) {
        .image-options {
            height: 82vh;
        }
    }

    @media (min-width: 150em) {
        .image-options {
            height: 86vh;
        }
    }

    @media (min-width: 237em) {
        .image-options {
            height: 91vh;
        }
    }

    @media (min-width: 305em) {
        .image-options {
            height: 97vh;
        }
    }
`;

export const ContainerPage = styled.div`
    background: #fafafa;
    height: fit-content;

    @media (min-width: 64em) {
        padding: 40px 40px 20px 40px;
        height: max-content;
        overflow: hidden !important;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #quatity {
        display: flex;
        label,
        input {
            position: relative;
            display: block;
            width: 130px;
            box-sizing: border-box;
        }

        label::after {
            content: attr(data-domain);
            position: absolute;
            top: 33%;
            left: 35%;
            font-size: 0.875rem;
            display: block;
            color: #a09fa5;
            font-weight: bold;
        }

        > div {
            min-width: 70px;
            max-width: 70px;
            min-height: 48px;
            max-height: 48px;
            margin: 0;

            input {
                min-height: 48px;
                max-height: 48px;
            }
        }

        button {
            margin-left: 12px;
            width: 95px;
            min-height: 48px;
            padding: 0;
            font-size: 0.875em;
        }
    }

    &.arteAiContainerDark {
        background-color: #000;
        form {
            input,
            textarea {
                background: #1d1d1f !important;
                color: #fff !important;
                border-color: #1d1d1f;

                &:focus {
                    border: 1px solid #fff !important;
                }
            }
            button[type='reset'] {
                background: #000 !important;
                color: #cccccc;
                border: 1px solid #cccccc;
            }
        }

        .textarea-filters {
            button {
                background: #000 !important;
                color: #cccccc;
                border: none;
            }
        }

        .content-submit {
            #clean {
                background: #000;
                color: #fff;
            }
        }

        .image-options {
            background: #1d1d1f;
            border-color: #1d1d1f;

            .an-simple-select__option.an-simple-select__option--is-selected {
                background-color: #000;
                color: #fff;
            }

            .an-simple-select__option:hover {
                background-color: #3a3a3a !important;
                opacity: 0.8;
            }

            .an-simple-select__option {
                background-color: #000 !important;
                opacity: 0.8;
            }

            .ratio-section {
                .ratiosOptions {
                    div {
                        span {
                            background: #1d1d1f;
                        }
                    }
                }
            }

            p {
                color: #fff;
            }

            select {
                background: #000 !important;
            }

            .lineHr {
                border-color: #3a3a3a;
            }
            .type-buttons {
                div {
                    background: #000;

                    .photo-name {
                        img {
                            filter: invert(1);
                        }
                    }
                }
                .type-item {
                    background: #000;
                    color: #cccccc;
                    border: 1px solid #cccccc;
                }

                .selected {
                    background: #d0ff61;
                    border-color: #d0ff61;
                    color: #000;

                    .photo-name {
                        img {
                            filter: none;
                        }
                    }

                    div {
                        background: #d0ff61;
                    }

                    .checked-icon {
                        background: #d0ff61;
                        color: #000;
                    }
                }
            }

            .style-section {
                .filterButton {
                    background: #1d1d1f !important;
                    color: #fff !important;
                    border-color: #fff !important;
                }

                .selectedFilter {
                    background: #d0ff61 !important;
                    border-color: #d0ff61 !important;
                    color: #000 !important;
                }
                .style-options {
                    p {
                        color: #fff;
                    }
                    .options {
                        p {
                            margin-top: 6px;
                            color: #fff;
                        }
                    }

                    .styleSelected {
                        img {
                            border-color: #d0ff61;
                        }
                    }
                }
            }
        }
    }
`;
