import styled from 'styled-components';
import { StylesConfig } from 'react-select';
import { Option } from './index';

interface Props {
    width: string;
}

export const Select = styled.div<Props>`
    width: ${props => props.width};
    svg {
        height: 16px;
        width: 16px;
        color: #7e7e7e;
    }
`;

interface SelectStyleProps {
    variantTheme: any;
    width: string;
    height?: string;
}

export const SelectStyle = (props: SelectStyleProps): StylesConfig<Option> => ({
    container: provided => ({
        ...provided,
        width: props.width,
    }),
    control: (provided, state) => ({
        ...provided,
        height: props.height ? props.height : props.variantTheme.height,
        minHeight: props.height ? props.height : props.variantTheme.height,
        padding: props.variantTheme.padding,
        borderRadius: props.variantTheme.borderRadius,
        fontWeight: props.variantTheme.fontWeight,
        backgroundColor: props.variantTheme.background.color,
        border: `1px solid ${
            state.isFocused
                ? props.variantTheme.border.active
                : props.variantTheme.border.color
        }`,
        '&:hover': {
            borderColor: props.variantTheme.border.active,
        },
        fontSize: '14px',
        lineHeight: '21px',
        gap: '4px',
        width: '100%',
        margin: '0px',
        boxShadow: 'none',
        cursor: 'pointer',
    }),
    placeholder: provided => ({
        ...provided,
        fontWeight: props.variantTheme.fontWeight,
        fontSize: '14px',
        lineHeight: '21px',
    }),
    valueContainer: provided => ({
        ...provided,
        padding: '0px',
    }),
    singleValue: provided => ({
        ...provided,
        color: props.variantTheme.text.color,
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
    }),
    menu: provided => ({
        ...provided,
        minWidth: provided.width,
        width: 'auto',
    }),
    option: provided => ({
        ...provided,
        width: 'auto',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        alignItems: 'start',
        textAlign: 'left',
        backgroundColor: 'inherit',
        color: '#000000',
        fontWeight: props.variantTheme.fontWeight,
        fontSize: '14px',
        lineHeight: '21px',
        '&:hover': {
            backgroundColor: '#deebff',
        },
    }),
    input: provided => ({
        ...provided,
        height: 'fit-content',
        width: '100%',
        padding: '0px',
        margin: '0px',
        display: 'flex',
        alignItems: 'center',
    }),
});
