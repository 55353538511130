import styled from 'styled-components';

export const TextAreaStyled = styled.textarea`
    height: 112px;
    padding: 10px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;
    resize: none;
    :disabled {
        background-color: transparent;
        cursor: text;
    }

    :focus {
        border: 1px solid #1a202c;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a0aec0;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a0aec0;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a0aec0;
    }
`;

export const Label = styled.label`
    font-weight: bold;
    display: block;
    margin-top: 9px;
    margin-bottom: 15px;
`;

export const InputContainer = styled.div`
    font-size: 0.9em;
    width: 100%;

    .step {
        text-align: right;
        padding-top: 8px;

        span {
            color: #718096;
            font-size: 0.75em;
        }
    }

    .label-custom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 9px;
        margin-bottom: 15px;

        label {
            margin: 0;
        }

        .step {
            display: flex;
            align-items: center;
            padding: 0;
        }
    }
`;
