import React from 'react';

export const BaselineTranslate: React.FC = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
    >
        <path d="M10.7249 12.5577L8.60825 10.466L8.63325 10.441C10.045 8.87193 11.0998 7.01539 11.7249 4.99935H14.1666V3.33268H8.33325V1.66602H6.66658V3.33268H0.833252V4.99102H10.1416C9.58325 6.59935 8.69992 8.12435 7.49992 9.45768C6.72492 8.59935 6.08325 7.65768 5.57492 6.66602H3.90825C4.51659 8.02435 5.34992 9.30768 6.39158 10.466L2.14992 14.6493L3.33325 15.8327L7.49992 11.666L10.0916 14.2577L10.7249 12.5577ZM15.4166 8.33268H13.7499L9.99992 18.3327H11.6666L12.5999 15.8327H16.5583L17.4999 18.3327H19.1666L15.4166 8.33268ZM13.2333 14.166L14.5833 10.5577L15.9333 14.166H13.2333Z" />
    </svg>
);
