import { BubbleDataPoint, Chart as ChartJS, ChartTypeRegistry, Point, TooltipModel } from 'chart.js';

export const externalTooltip = (suffix?: string): (context: {
    chart: ChartJS<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[], unknown>;
    tooltip: TooltipModel<"bar">;
}) => void => {
    return (context) => {
        let tooltipEl = document.getElementById('chartjs-tooltip');
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '';
            document.body.appendChild(tooltipEl);
        }

        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
        }

        if (tooltipModel.body) {
            const innerHtml = tooltipModel.body[0].lines[0] + (suffix ? suffix : '');
            tooltipEl.innerHTML = innerHtml;
        }

        const position = context.chart.canvas.getBoundingClientRect();
        tooltipEl.style.opacity = '.8';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + 2 + 'px';
        tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY - tooltipEl.scrollHeight - 2 + 'px';
        tooltipEl.style.font = 'Manrope';
        tooltipEl.style.fontSize = '12px'
        tooltipEl.style.backgroundColor = '#000'
        tooltipEl.style.color = '#fff'
        tooltipEl.style.padding = '5px 10px'
        tooltipEl.style.borderRadius = '6px'
        tooltipEl.style.pointerEvents = 'none';
    }
}