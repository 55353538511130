import { Route, Routes } from 'react-router-dom';
import SignIn from 'pages/signin';
import { useIntl } from 'react-intl';
import SignUp from 'pages/signup';
import { routeTranslate } from 'utils/intl';
import SendEmail from 'pages/forgot-password/send-email';
import EmailVerify from '../pages/email-verify';
import { AcceptInvite } from '../pages/actions';
import { Management } from 'pages/management';

export const PublicRoutes = () => {
    const intl = useIntl();

    return (
        <Routes>
            <Route path="/" element={<SignIn />} />
            <Route
                path={routeTranslate(intl, 'route.signin')}
                element={<SignIn />}
            />
            <Route
                path={routeTranslate(intl, 'route.signup')}
                element={<SignUp />}
            />
            <Route
                path={routeTranslate(intl, 'route.forgotPassword')}
                element={<SendEmail />}
            />
            <Route
                path={`${routeTranslate(intl, 'route.emailVerify')}`}
                element={<EmailVerify />}
            />

            <Route
                path={`${routeTranslate(intl, 'route.management')}`}
                element={<Management />}
            />
            <Route
                path={'/workspaces/invites/:token'}
                element={<AcceptInvite />}
            />
        </Routes>
    );
};
