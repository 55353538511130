import React from 'react';

export const ChatIcon: React.FC = () => (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.993 10.0105V10.0205M5.99611 10.0105V10.0205M13.9899 10.0105V10.0205M1 18L2.29899 14.1051C1.17631 12.4456 0.770172 10.4803 1.1561 8.57472C1.54202 6.66912 2.69374 4.95286 4.39712 3.74505C6.1005 2.53724 8.23963 1.92008 10.4168 2.0083C12.5939 2.09653 14.6609 2.88414 16.2334 4.2247C17.806 5.56526 18.777 7.36755 18.9661 9.29645C19.1551 11.2254 18.5493 13.1496 17.2612 14.7115C15.9731 16.2733 14.0905 17.3664 11.9633 17.7876C9.83606 18.2088 7.60906 17.9294 5.69635 17.0013L1 18Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
