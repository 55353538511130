import styled from 'styled-components';

interface Props {
    isInTheAgentInfoLayout: boolean;
}

export const Insights = styled.div<Props>`
    position: relative;
    h1 {
        display: none;
        font-weight: 700;
        font-size: 1.5em;
        align-items: center;
        margin-bottom: 24px;
        color: black;

        @media (min-width: 64em) {
            display: flex;
            position: absolute;
            top: -55px;
            left: 40px;
        }
    }
    .insights-body {
        padding: 42px 0px 30px 0px;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 350px;

        @media (min-width: 64rem) {
            width: 541.5px;  
        }

        @media (min-width: ${({isInTheAgentInfoLayout}) => isInTheAgentInfoLayout ? '1540px' : '1200px'}) {
            width: 1103px;  
        }

        .insights-header {
            display: flex;
            justify-content: space-between;
            
            .insights-title-container {
                display: flex;
                align-items: center;
                gap: 10px;
                min-width: 140px;
                
                span {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 27px;
                    letter-spacing: 0.2px;
                }

                svg {
                    max-width: 14px;
                    max-height: 14px;
                    min-width: 14px;
                    min-height: 14px;
                    color: #A09FA5;
                }
            }
        }

        .insights-body {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .row {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;

                @media (min-width: ${({isInTheAgentInfoLayout}) => isInTheAgentInfoLayout ? '1540px' : '1200px'}) {
                    flex-direction: row;
                }
            }

            .first-row-card {
                width: 350px;

                @media (min-width: 64rem) {
                    width: 541.5px;  
                }
            }

            .second-row-card {
                gap: 15px;
                width: 350px;

                @media (min-width: 64rem) {
                    width: 354.33px;
                }
            }

            .third-row-card {
                height: fit-content;
                width: 350px;

                @media (min-width: 64rem) {
                    width: 541.5px;
                }

                @media (min-width: 64rem) {
                    height: 336px;
                    width: 541.5px;
                }
            }

            .resolutions {
                height: fit-content;

                @media (min-width: 64rem) {
                    height: 323px;
                }
                .card-header{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .sessions-resolution-text{
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 21px;
                        letter-spacing: 0.2px;
                    }
                }
                .card-labels{
                    display: flex;
                    gap: 20px;
                    width: fit-content;
                    .card-label{
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        gap: 6px;
                        .label-circle {
                            border: 1px solid #E2E8F0;
                            background-color: #E2E8F0;
                            width: 14px;
                            height: 14px;
                            border-radius: 7px;
                        }
                        .solved-circle{
                            background-color: #22C55E;
                        }
                        span {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 21px;
                            letter-spacing: 0.2px;
                            color: #7E7E7E;
                        }
                    }
                }
            }

            .rating-chart-container{
                display: flex;
                flex-direction: column;
                gap: 20px;

                .rating-chart{
                    margin-bottom: 14px;
                }
            }

            hr {
                border: 1px solid #E2E8F0;

                width: 300px;
                @media (min-width: 64rem) {
                    width: 459px;
                }
            }
        }
    }
`;

export const Loading = styled.div`
    padding-top: 30vh;
    margin: auto;
    width: fit-content;
`;