import React from 'react';

type CheckEnableProps = {
    version?: "primary" | "secondary" | "terciary"
}

export const CheckEnable: React.FC<CheckEnableProps> = ({ version = "primary" }) => {
    const handleOutsideFill = (): string => {
        switch (version) {
            case "primary":
                return "#d0ff61";

            case "secondary":
                return "#1a202c";

            case "terciary":
                return "rgba(144, 97, 255, 1)"
        }
    }

    const handleInsideFill = (): string => {
        if (version == "terciary") return "#FFFFFF";

        return "#1A202C";
    }


    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.4"
                d="M8.99971 17.9269C8.36529 17.9269 7.73162 17.686 7.24734 17.2049L6.64507 16.6027C6.41159 16.37 6.0989 16.2413 5.76724 16.2404H4.91993C3.55038 16.2404 2.43577 15.1258 2.43577 13.7562V12.908C2.43494 12.5772 2.30624 12.2645 2.07275 12.0294L1.48038 11.4378C0.510147 10.4733 0.506021 8.89665 1.47131 7.92555L2.07358 7.32243C2.30624 7.08893 2.43494 6.77625 2.43577 6.44457V5.59806C2.43577 4.22764 3.55038 3.11299 4.91993 3.11299H5.76806C6.0989 3.11299 6.41076 2.98428 6.64589 2.74914L7.23909 2.15758C8.20351 1.18731 9.77934 1.18236 10.7513 2.14851L11.3535 2.75079C11.5878 2.98428 11.8997 3.11299 12.2305 3.11299H13.0787C14.4482 3.11299 15.5628 4.22764 15.5628 5.59806V6.4454C15.5636 6.77625 15.6923 7.08894 15.9258 7.32408L16.5182 7.91647C16.9877 8.38343 17.2475 9.00555 17.25 9.66968C17.2517 10.3298 16.9976 10.9511 16.5347 11.4205C16.5265 11.4287 16.5191 11.4378 16.5108 11.4452L15.925 12.0311C15.6923 12.2645 15.5636 12.5772 15.5628 12.9089V13.7562C15.5628 15.1258 14.4482 16.2404 13.0787 16.2404H12.2305C11.8997 16.2413 11.587 16.37 11.3527 16.6035L10.7595 17.1959C10.2761 17.6827 9.63744 17.9269 8.99971 17.9269Z"
                fill={handleOutsideFill()}
            />
            <path
                d="M7.71601 11.9269C7.53789 11.9279 7.35979 11.8591 7.2225 11.7176L5.45631 9.8967C5.18359 9.61388 5.1808 9.1548 5.45074 8.87003C5.72068 8.58428 6.1613 8.58135 6.43495 8.8632L7.70581 10.1728L10.8087 6.89313C11.0795 6.6074 11.5202 6.6045 11.7929 6.88635C12.0665 7.16918 12.0693 7.62926 11.7994 7.91305L8.20674 11.7109C8.07129 11.8542 7.89414 11.9259 7.71601 11.9269Z"
                fill={handleInsideFill()}
            />
        </svg>
    );
} 
