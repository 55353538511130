import React from 'react';
import { Container, Input } from '../../../app/presentation/components';
import { Header, Grid, Tags, IconStar } from './styles';
import { FieldValues, useForm } from 'react-hook-form';
import { BsGrid } from 'react-icons/bs';
import { FiMenu } from 'react-icons/fi';
import { Search } from '../../../app/presentation/components/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { routeTranslate } from '../../../utils/intl';
import { useIntl } from 'react-intl';
import useTemplates from '../../../app/presentation/hooks/useTemplates';
import { AiOutlineStar } from 'react-icons/ai';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import useDocumentTitle from 'utils/useDocumentTitle';

type Layout = 'grid' | 'list';

const Library: React.FC = () => {
    const [layout, setLayout] = React.useState<Layout>('grid');
    const { register, watch } = useForm<FieldValues>();
    const { darkmode } = useDarkMode();

    const intl = useIntl();
    const {
        getTemplates,
        templates,
        favorites,
        categories,
        setFavorite,
        removeFavorite,
    } = useTemplates();
    const { category } = useParams();
    const navigate = useNavigate();

    const currentPageName = routeTranslate(intl, 'route.library').replace(
        '/',
        '',
    );
    const pageTitle =
        currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1);
    useDocumentTitle(pageTitle);

    const watchSearch = watch('search') ?? '';

    React.useEffect(() => {
        const fetchData = async () => {
            const stringSearch = category
                ? category.replace(/-/g, ' ')
                : undefined;

            await getTemplates(stringSearch, watchSearch);
        };

        fetchData().catch(console.error);
    }, [category, watchSearch]);

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.library');

    const handleCategory = (value: string) => {
        let search: string;
        const stringSearch = value.replace(/ /g, '-');

        if (category && category.includes(stringSearch)) {
            let searchArray = category.split(',');
            searchArray = searchArray.filter(e => e !== stringSearch);
            search = searchArray.join(',');
        } else if (category) {
            search = `${category} ${stringSearch}`;
            const searchArray = search.split(' ');
            search = searchArray.join(',');
        } else {
            search = `${stringSearch}`;
        }

        navigate(`/${path}/${search}`);
    };

    const handleAll = () => {
        navigate(`/${path}`);
    };

    const isFavorite = (id: number) => {
        return !!(favorites && favorites.find(fav => fav.id === id));
    };

    const handleFavorite = async (id: number) => {
        const condition = isFavorite(id);

        if (!condition) {
            await setFavorite(id);
            return;
        }

        await removeFavorite(id);
    };

    const handleToFav = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
        if (!['svg', 'path'].includes((e.target as HTMLDivElement).nodeName)) {
            navigate(`/${path}${routeTranslate(intl, 'route.model')}/${id}`);
        }
    };

    return (
        <Container>
            <Header className={darkmode ? 'headerLibDark' : ''}>
                <h1 className="dark-text-white">
                    {routeTranslate(intl, 'navmenu.library')}
                </h1>
                <form>
                    <div className="input">
                        <div className="icon">
                            <Search darkmode={darkmode} />
                        </div>
                        <Input
                            id="search"
                            placeholder={routeTranslate(
                                intl,
                                'label.search.resource',
                            )}
                            register={register}
                        />
                    </div>
                </form>
                <div id="options">
                    <div
                        className={`option ${layout === 'list' && ' active'}`}
                        onClick={() => setLayout('list')}
                    >
                        <FiMenu size={20} />
                    </div>
                    <div
                        className={`option ${layout === 'grid' && ' active'}`}
                        onClick={() => setLayout('grid')}
                    >
                        <BsGrid size={20} />
                    </div>
                </div>
            </Header>
            <Tags>
                <div
                    onClick={() => handleAll()}
                    className={`${
                        !category
                            ? 'active tag box-dark-mode'
                            : 'tag box-dark-mode'
                    }`}
                >
                    {routeTranslate(intl, 'all')}
                </div>
                {categories?.map((cat, index) => (
                    <div
                        onClick={() => handleCategory(cat.name)}
                        className={`${
                            category?.includes(
                                cat.name.split(/[ ,]+/).join('-'),
                            )
                                ? 'active tag box-dark-mode'
                                : 'tag box-dark-mode'
                        }`}
                        key={index}
                    >
                        {routeTranslate(intl, `cat.${cat.name}`)}
                    </div>
                ))}
            </Tags>
            <Grid className={`${layout} ${darkmode && 'gridLayoutDark'}`}>
                {templates &&
                    templates
                        .sort(
                            (a, b) =>
                                Number(isFavorite(b.id)) -
                                Number(isFavorite(a.id)),
                        )
                        .map(feature => (
                            <div
                                key={feature.id}
                                onClick={e => handleToFav(e, feature.id)}
                            >
                                <div
                                    className={`box box-dark-mode ${
                                        isFavorite(feature.id) && 'favoriteDark'
                                    }`}
                                    style={{
                                        backgroundColor: isFavorite(feature.id)
                                            ? '#FFC8371A'
                                            : '',

                                        borderColor: isFavorite(feature.id)
                                            ? '#FFC837'
                                            : '',
                                    }}
                                >
                                    <div className="header">
                                        <div className="icon">
                                            <img src={feature.image} alt="" />
                                        </div>
                                        <div></div>
                                    </div>
                                    <div>
                                        <IconStar className="star">
                                            <AiOutlineStar
                                                size={26}
                                                onClick={async () => {
                                                    await handleFavorite(
                                                        feature.id,
                                                    );
                                                }}
                                                style={{
                                                    color: isFavorite(
                                                        feature.id,
                                                    )
                                                        ? '#FFC837'
                                                        : '#A0AEC0',
                                                }}
                                            />
                                        </IconStar>
                                        <h1
                                            style={{
                                                color: isFavorite(feature.id)
                                                    ? '#000'
                                                    : '',
                                            }}
                                        >
                                            {feature.name}
                                        </h1>
                                        <p
                                            style={{
                                                color: isFavorite(feature.id)
                                                    ? darkmode
                                                        ? '#000'
                                                        : '#A09FA5'
                                                    : '',
                                            }}
                                        >
                                            {feature.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
            </Grid>
        </Container>
    );
};

export default Library;
