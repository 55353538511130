import styled from 'styled-components';

export const HeaderContainer = styled.header`
    width: 100%;
    float: left;
    height: 80px;
    background-color: #ffffff;
    display: flex;
    align-items: center;

    &.headerDark {
        background-color: #000;
    }
`;
