import React from 'react';
import Image from 'assets/images/svg/access_denied.svg';
import { ContainerStyled } from './styles';

export const AccessDenied: React.FC = () => {
    return (
        <ContainerStyled>
            <img src={Image} />
            <h1>Você não possui acesso</h1>
            <p>
                Para visualizar ou modificar as opções de planos e cobrança,
                fale com o proprietário da sua conta.
            </p>
        </ContainerStyled>
    );
};
