import React from 'react';
import * as S from './styles';
import { 
    InsightsCard,
    InsightsCardTitle,
    InsightsCardStatistic,
    DateInterval,
    SingleBarChart,
    BarChart,
    DateIntervalSelectDropdown,
    BarChartValue
} from 'app/presentation/components/agent';
import { InsightsService } from 'services/insights';
import { AgentUsageData } from 'services/insights/interfaces';
import {
    DAY,
    dateFromISOString,
    daysInISOStringInterval,
    formatDayAndMonthISOString,
    formatHoursISOString,
    formatISOStringToShortFullDate,
    msInISOStringInterval
} from 'utils/time';
import { PercentageStatistic, Statistic, percentage } from 'utils/statistic';
import { useIntl } from 'react-intl';
import { CircularProgress, useMediaQuery } from '@mui/material';
import useText from 'app/presentation/hooks/useText';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { useNavigate, useParams } from 'react-router-dom';
import useSnackbar from 'app/presentation/hooks/useSnackbar';


interface SessionsStatistics {
    sessionsCount: number;
    previosSessionsCount: number;
    solvedSessionsCount: number;
    unfinishedRate: Statistic;
    transferRate: Statistic;
    resolutionRate: Statistic;
    avgSessionDuration: Statistic;
    newSessions: Statistic;
    agentMessages: Statistic;
}

interface RatingStatistics {
    ratingsCount: Statistic;
    highRatingsRate: Statistic;
    ratesPercentage: number[];
}

const Insights = () => {
    const [dateInterval, setDateInterval] 
        = React.useState<DateInterval>()

    const [sessionStatistics, setSessionStatistics] 
        = React.useState<SessionsStatistics>()

    const [newSessionsChartData, setNewSessionsChartData]
        = React.useState<BarChartValue[]>()
        
    const [agentMessagesChartData, setAgentMessagesChartData]
        = React.useState<BarChartValue[]>()

    const [ratingStatistics, setRatingStatistics] 
        = React.useState<RatingStatistics>()

    const [loading, setLoading] 
        = React.useState<boolean>(false)

    const [daysInPreviousInterval, setDaysInPreviousInterval] 
        = React.useState<number>()

    const [isInTheAgentInfoLayout, setIsInTheAgentInfoLayout] 
        = React.useState<boolean>(false)

    const { textGetter, routeGetter } = useText()
    const t = textGetter('agent.pages.insights')
    const service = new InsightsService()

    const { locale } = useIntl();

    const isDesktop = useMediaQuery('(min-width: 64em)');

    const { id } = useParams();
    const { activeBrand } = useUserConfig();

    const agentRoutes = routeGetter('agent');
    const navigate = useNavigate();
    const { error } = useSnackbar();

    const extractSessionsStatistics = (data: AgentUsageData, previosData: AgentUsageData) => {
        const newSessions = new Statistic(
            data.sessions,
            previosData.sessions,
        )
        const solvedSessionsCount = data.solved_by_jarbas_sessions
        const unfinishedRate = new PercentageStatistic(
                data.unfinished_sessions,
                data.sessions,
                previosData.unfinished_sessions,
                previosData.sessions,
            )
        const transferRate = new PercentageStatistic(
                data.transfered_sessions,
                data.sessions,
                previosData.transfered_sessions,
                previosData.sessions,
            )
        const resolutionRate = new PercentageStatistic(
                data.solved_by_jarbas_sessions,
                data.sessions,
                previosData.solved_by_jarbas_sessions,
                previosData.sessions,
            )

        const avgSessionDuration = new Statistic(
                data.avg_session_duration,
                previosData.avg_session_duration
            )

        const agentMessages = new Statistic(
                data.agent_messages,
                previosData.agent_messages
            )

        setSessionStatistics({
            newSessions: newSessions,
            resolutionRate: resolutionRate,
            sessionsCount: data.sessions,
            previosSessionsCount: previosData.sessions,
            solvedSessionsCount: solvedSessionsCount,
            transferRate: transferRate,
            unfinishedRate: unfinishedRate,
            avgSessionDuration: avgSessionDuration,
            agentMessages: agentMessages
        });
    }

    const extractDaysInPreviousInterval = (previosData: AgentUsageData) => {
        setDaysInPreviousInterval(daysInISOStringInterval(previosData.start_at, previosData.end_at))
    }

    const extractRatingStatistics = (data: AgentUsageData, previosData: AgentUsageData) => {
        const ratingsCount = data.session_rating
            .reduce((prev, current) => prev + current)

        const previosRatingsCount = previosData.session_rating
            .reduce((prev, current) => prev + current)

        const ratesPercentage
            = data.session_rating.map(value => percentage(value, ratingsCount))
        
        const previousRatesPercentage
            = previosData.session_rating.map(value => percentage(value, previosRatingsCount))


        setRatingStatistics({
            highRatingsRate: 
                new Statistic(
                    ratesPercentage[4] + ratesPercentage[3],
                    previousRatesPercentage[4] + previousRatesPercentage[3]
                ),
            ratesPercentage: ratesPercentage,
            ratingsCount:
                new Statistic(
                    ratingsCount,
                    previosRatingsCount
                ),   
        })
    }

    const hasAtLeastOneDay = (start: string, end: string): boolean => {
        return msInISOStringInterval(start, end) >= DAY
    }

    const areInDifferentYears = (start: string, end: string): boolean => {
        return dateFromISOString(start).getFullYear() != dateFromISOString(end).getFullYear()
    }

    const extractNewSessionsChartData = (data: AgentUsageData) => {
        if (!data.subintervals_data) {
            setNewSessionsChartData(undefined)
            return
        }

        const chartData = data.subintervals_data.map<BarChartValue>(subinterval => {
            return {
                x: !hasAtLeastOneDay(data.start_at, data.end_at) 
                    ? formatHoursISOString(subinterval.start_at)
                    : areInDifferentYears(data.start_at, data.end_at)
                    ? formatISOStringToShortFullDate(subinterval.start_at, locale)
                    : formatDayAndMonthISOString(subinterval.start_at, locale),
                y: subinterval.sessions,
            }
        })

        setNewSessionsChartData(chartData)
    }

    const extractAgentMessagesChartData = (data: AgentUsageData) => {
        if (!data.subintervals_data) {
            setAgentMessagesChartData(undefined)
            return
        }

        const chartData = data.subintervals_data.map<BarChartValue>(subinterval => {
            return {
                x: !hasAtLeastOneDay(data.start_at, data.end_at) 
                    ? formatHoursISOString(subinterval.start_at)
                    : areInDifferentYears(data.start_at, data.end_at)
                    ? formatISOStringToShortFullDate(subinterval.start_at, locale)
                    : formatDayAndMonthISOString(subinterval.start_at, locale),
                y: subinterval.agent_messages,
            }
        })

        setAgentMessagesChartData(chartData)
    }

    React.useEffect(()=>{
        if (!activeBrand) {
            navigate(agentRoutes('home'));
            return
        }

        if(!dateInterval) {
            return;
        }

        let agentID: number | undefined
        if(id && !isNaN(Number(id))) {
            agentID = Number(id)
            setIsInTheAgentInfoLayout(true)
        }
        
        setLoading(true)
        service.getAgentUsageData(activeBrand.id, dateInterval.start, dateInterval.end, agentID).then((res)=>{
            extractSessionsStatistics(res.data, res.previous_data)
            extractRatingStatistics(res.data, res.previous_data)
            extractDaysInPreviousInterval(res.previous_data)
            extractNewSessionsChartData(res.data)
            extractAgentMessagesChartData(res.data)
        })
        .catch(()=>{
            error('get-agent-usage-data');
            navigate(agentRoutes('home'));
        })
        .finally(()=>{setLoading(false)})
    },[dateInterval,activeBrand])
 
    return (
        <S.Insights isInTheAgentInfoLayout={isInTheAgentInfoLayout}>
            {!isInTheAgentInfoLayout && (
                <h1>Insights</h1>
            )}
            <div className='insights-body'>
                <div className='insights-header'>
                    <div className='insights-title-container'>
                        <span>{t('title')}</span>
                    </div>
                    <DateIntervalSelectDropdown
                        dateInterval={dateInterval}
                        onDateIntervalChange={setDateInterval}
                    />
                </div>
                {!loading
                    ? (<div className='insights-body'>
                        {sessionStatistics && (
                        <div className='row'>
                            <InsightsCard className='first-row-card'>
                                <InsightsCardTitle
                                    tooltip={t('new-sessions.tooltip')}
                                >{t('new-sessions.title')}</InsightsCardTitle>
                                <InsightsCardStatistic 
                                    statistic={sessionStatistics.newSessions}
                                    lastIntervalDays={daysInPreviousInterval}
                                    valueFractionDigits={0}
                                />
                                {newSessionsChartData && (
                                    <BarChart
                                        height='182px'
                                        width={isDesktop ? '457px' : '300px'}
                                        label={t('new-sessions.title')}
                                        data={isDesktop || newSessionsChartData.length < 7 
                                            ? newSessionsChartData 
                                            : newSessionsChartData
                                                .reduce<BarChartValue[]>((prev, curr, i, list) => {
                                                if (i % 2 == 0) {
                                                    prev.push({
                                                        x: curr.x,
                                                        y: curr.y + (list.length > i+1 ? list[i+1].y : 0)
                                                    });
                                                }
                                                return prev;
                                                }, [])}
                                    />
                                )}
                            </InsightsCard>
                            <InsightsCard className='first-row-card resolutions'>
                                <div className='card-header'>
                                    <InsightsCardTitle
                                        tooltip={t('resolutions.tooltip')}
                                    >{t('resolutions.title')}</InsightsCardTitle>
                                    <InsightsCardStatistic 
                                        statistic={sessionStatistics.resolutionRate}
                                        suffix='%'
                                        lastIntervalDays={daysInPreviousInterval}
                                    />
                                    <span className='sessions-resolution-text'>
                                        {t('resolutions.text', {
                                            solved: sessionStatistics.solvedSessionsCount,
                                            total: sessionStatistics.sessionsCount
                                        })}
                                    </span>
                                </div>
                                {sessionStatistics.sessionsCount > 0 && (
                                    <SingleBarChart
                                        width={isDesktop ? '457px' : '300px'}
                                        data={[
                                            { 
                                                label: t('resolutions.chart-label.solved'),
                                                value: sessionStatistics.resolutionRate.getValue(),
                                                color: '#22C55E' 
                                            },
                                            {   
                                                label: t('resolutions.chart-label.unsolved'),
                                                value: 100 - sessionStatistics.resolutionRate.getValue(),
                                                color: '#E2E8F0'
                                            },
                                        ]}
                                    />
                                )}
                                {sessionStatistics.sessionsCount > 0 && (
                                    <div className='card-labels'>
                                        <div className='card-label'>
                                            <div className='label-circle solved-circle' />
                                            <span>{t('resolutions.chart-label.solved')}</span>
                                        </div>
                                        <div className='card-label'>
                                            <div className='label-circle' />
                                            <span>{t('resolutions.chart-label.unsolved')}</span>
                                        </div>
                                    </div>
                                )}
                            </InsightsCard>
                        </div>)}
                        {sessionStatistics && (
                        <div className='row'>
                            <InsightsCard className='second-row-card'>
                                <InsightsCardTitle
                                    tooltip={t('transfer-rate.tooltip')}
                                >{t('transfer-rate.title')}</InsightsCardTitle>
                                <InsightsCardStatistic 
                                    suffix='%'
                                    statistic={sessionStatistics.transferRate}
                                    lastIntervalDays={daysInPreviousInterval}
                                />
                            </InsightsCard>
                            <InsightsCard className='second-row-card'>
                                <InsightsCardTitle
                                    tooltip={t('resolution-rate.tooltip')}
                                >{t('resolution-rate.title')}</InsightsCardTitle>
                                <InsightsCardStatistic
                                    suffix='%'
                                    statistic={sessionStatistics.resolutionRate}
                                    lastIntervalDays={daysInPreviousInterval}
                                />
                            </InsightsCard>
                            <InsightsCard className='second-row-card'>
                                <InsightsCardTitle
                                    tooltip={t('unfinished-rate.tooltip')}
                                >{t('unfinished-rate.title')}</InsightsCardTitle>
                                <InsightsCardStatistic 
                                    suffix='%'
                                    statistic={sessionStatistics.unfinishedRate}
                                    lastIntervalDays={daysInPreviousInterval}
                                />
                            </InsightsCard>
                        </div>)}
                        {sessionStatistics && ratingStatistics && (
                            <div className='row'>
                                <InsightsCard className='third-row-card'>
                                    <InsightsCardTitle
                                        tooltip={t('agent-messages.tooltip')}
                                    >{t('agent-messages.title')}</InsightsCardTitle>
                                    <InsightsCardStatistic 
                                        statistic={sessionStatistics.agentMessages}
                                        lastIntervalDays={daysInPreviousInterval}
                                        valueFractionDigits={0}
                                    />
                                    {agentMessagesChartData && (
                                        <BarChart
                                            height='182px'
                                            width={isDesktop ? '457px' : '300px'}
                                            label={t('agent-messages.title')}
                                            data={isDesktop || agentMessagesChartData.length < 7 
                                                ? agentMessagesChartData 
                                                : agentMessagesChartData
                                                    .reduce<BarChartValue[]>((prev, curr, i, list) => {
                                                        if (i % 2 == 0) {
                                                            prev.push({
                                                                x: curr.x,
                                                                y: curr.y + (list.length > i+1 ? list[i+1].y : 0)
                                                            });
                                                        }
                                                        return prev;
                                                    }, [])}
                                        />
                                    )}
                                </InsightsCard>
                                <InsightsCard className='third-row-card'>
                                    <InsightsCardTitle
                                        tooltip={t('rating.tooltip')}
                                        >{t('rating.title')}</InsightsCardTitle>
                                    <InsightsCardStatistic 
                                        statistic={ratingStatistics.ratingsCount}
                                        valueFractionDigits={0}
                                        lastIntervalDays={daysInPreviousInterval}
                                    />
                                    <hr />
                                    <div className='rating-chart-container'>
                                        <InsightsCardStatistic
                                            statistic={ratingStatistics.highRatingsRate}
                                            suffix={t('high-ratings.suffix')}
                                            statisticFontSize={24}
                                            lastIntervalDays={daysInPreviousInterval}
                                        />
                                        {ratingStatistics.ratingsCount.getValue() > 0 && (
                                            <SingleBarChart
                                                className='rating-chart'
                                                width={isDesktop ? '457px' : '300px'}
                                                data={[
                                                    { 
                                                        label: '🤩',
                                                        value: ratingStatistics.ratesPercentage[4],
                                                        color: '#22C55E'
                                                    },
                                                    { 
                                                        label: '😀',
                                                        value: ratingStatistics.ratesPercentage[3],
                                                        color: '#4ADE80'
                                                    },
                                                    {
                                                        label: '😐',
                                                        value: ratingStatistics.ratesPercentage[2],
                                                        color: '#FDE047'
                                                    },
                                                    { 
                                                        label: '😕',
                                                        value: ratingStatistics.ratesPercentage[1],
                                                        color: '#EAB308'
                                                    },
                                                    { 
                                                        label: '😤', 
                                                        value: ratingStatistics.ratesPercentage[0], 
                                                        color: '#FF4747' 
                                                    },
                                                ]}
                                                barSpacing={2}
                                            />
                                        )}
                                    </div>
                                    <InsightsCardStatistic 
                                        description={t('avg-duration.desc')}
                                        timeStatistic
                                        statisticFontSize={24}
                                        statistic={sessionStatistics.avgSessionDuration}
                                        lastIntervalDays={daysInPreviousInterval}
                                    />
                                </InsightsCard>
                            </div> 
                        )}
                    </div>)
                    : (
                        <S.Loading>
                            <CircularProgress size={40} color="inherit" /> 
                        </S.Loading>
                    )
                }
            </div>
        </S.Insights>
    );
};


export default Insights;
