import useText from 'app/presentation/hooks/useText';
import * as S from './styles';

interface TrainingStatsProps {
    [key: string]: { enabled?: number; total?: number };
    brandCenter: { enabled?: number; total?: number };
    externalContent: { enabled?: number; total?: number };
    excerpts: { enabled?: number; total?: number };
    files: { enabled?: number; total?: number };
}

const TrainingStats = (props: TrainingStatsProps) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.training-stats');

    const statItems = [
        ['brand-center', 'brandCenter'],
        ['external-content', 'externalContent'],
        ['excerpts', 'excerpts'],
        ['file-content', 'files'],
    ];

    return (
        <S.Container>
            <h1 className="training-stats-title">{t('title')}</h1>
            <S.StatItems>
                {statItems.map((statItem, key) => (
                    <S.StatItem key={key}>
                        <h1 className="used">
                            {props[statItem[1]].enabled || 0}
                        </h1>
                        <h2 className="training-type">{t(statItem[0])}</h2>
                        <p className="total">
                            {t('of')} {props[statItem[1]].total || 0}
                        </p>
                    </S.StatItem>
                ))}
            </S.StatItems>
        </S.Container>
    );
};

export default TrainingStats;
