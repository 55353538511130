import React from 'react';
import { Container } from 'app/presentation/components';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import Loading from 'app/presentation/components/common/Loading';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const RewardfulView: React.FC = () => {
    const intl = useIntl();
    const { settings } = useUserConfig();
    //@ts-ignore
    const rewardul = window.rewardful;
    const navigate = useNavigate();

    const homeUrl = `../../../${routeTranslate(intl, 'route.urlLang')}${routeTranslate(intl, 'route.agent.home')}`;

    const handleConvert = async () => {
        await rewardul('convert', { email: settings.email });
        navigate(homeUrl.concat('?startOnboard=true'));
    };

    React.useEffect(() => {
        if (window && rewardul) {
            handleConvert();
        }
    }, [rewardul]);

    return (
        <Container>
            <Loading open absolute />
        </Container>
    );
};

export default RewardfulView;
