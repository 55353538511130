import { ThemeVariants } from '../themes';
import {
    buttons,
    cardSelect,
    colorInput,
    expansionPanel,
    formField,
    gifPicker,
    imgInput,
    infoMessage,
    input,
    sideMenu,
    radioGroup,
    select,
    switchCheckbox,
    tooltip,
    selectDropdown,
    paginator,
} from './components';

export const getCommonsTheme = (variant: ThemeVariants) => {
    return {
        components: {
            buttons: buttons[variant],
            select: select[variant],
            infoMessage: infoMessage[variant],
            expansionPanel: expansionPanel[variant],
            cardSelect: cardSelect[variant],
            formField: formField[variant],
            imgInput: imgInput[variant],
            colorInput: colorInput[variant],
            gifPicker: gifPicker[variant],
            input: input[variant],
            radioGroup: radioGroup[variant],
            sideMenu: sideMenu[variant],
            switchCheckbox: switchCheckbox[variant],
            tooltip: tooltip[variant],
            selectDropdown: selectDropdown[variant],
            paginator: paginator[variant],
        },
    };
};
