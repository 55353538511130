import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { Button, Input, Select } from 'app/presentation/components';
import useUserConfig from '../../../hooks/useUserConfigs';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import { FiPlus } from 'react-icons/fi';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BrandServices } from 'services/brand';
import {
    Brands,
    OccupationList,
} from 'app/presentation/hooks/interfaces/brand';
import Resizer from 'react-image-file-resizer';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    callBack: () => void;
    editing: boolean;
    item_editing?: Brands | null;
}

const brandsService = new BrandServices();

const ModalNewbBrand: React.FC<ModalProps> = ({
    active,
    handleClose,
    callBack,
    editing = false,
    item_editing,
}) => {
    const { register, handleSubmit, setValue, watch } = useForm<FieldValues>({
        defaultValues: {
            name: editing ? item_editing?.name : '',
        },
    });
    const { enqueueSnackbar } = useSnackbar();
    const { workspace, brands, project } = useUserConfig();
    const [imgFile, setImgFile] = React.useState<File | undefined>();
    const [occupationList, setOccupationList] = React.useState<
        Array<OccupationList> | undefined
    >();
    const [loading, setLoading] = React.useState(false);
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);
    const selectedOccupation = watch('occupation');
    const nameField: string = watch('name');

    const handleResetInputValues = () => {
        setValue('name', '');
        setValue('occupation', 'select');
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the value of the file input
        }
        setImgFile(undefined);
    };

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            handleResetInputValues();
            handleClose();
        }
    };
    const { darkmode } = useDarkMode();
    const intl = useIntl();

    React.useEffect(() => {
        if (editing && item_editing) {
            setValue('name', item_editing.name);
        }
    }, [editing]);

    const getList = async () => {
        const result = await brandsService.getOccupationList(
            workspace.id,
            project.id,
        );

        if (result instanceof Error) {
            return;
        }

        setOccupationList(result);
    };

    React.useEffect(() => {
        getList();
    }, []);

    const handleSubmitForm: SubmitHandler<FieldValues> = async data => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('occupation_id', selectedOccupation);
            formData.append(
                'is_default',
                String(brands ? brands.length < 1 : false),
            );
            if (imgFile) {
                formData.append('logo', imgFile);
            }
            await brandsService.create(workspace.id, project.id, formData);
            setLoading(false);
            enqueueSnackbar(
                routeTranslate(intl, 'modal.brand.create.success'),
                {
                    variant: 'success',
                },
            );
            callBack();
            handleResetInputValues();
            handleClose();
        } catch (e: any) {
            enqueueSnackbar(routeTranslate(intl, 'modal.brand.create.error'), {
                variant: 'error',
            });
            setLoading(false);
        }
    };

    const handleSubmitFormEdit: SubmitHandler<FieldValues> = async data => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('occupation', data.occupation);
            formData.append('is_default', String(item_editing?.is_default));
            if (item_editing) {
                await brandsService.edit(
                    workspace.id,
                    project.id,
                    formData,
                    item_editing.id,
                );

                if (imgFile) {
                    const formImg = new FormData();

                    formImg.append('logo', imgFile);
                    await brandsService.editImg(
                        workspace.id,
                        project.id,
                        formImg,
                        item_editing.id,
                    );
                }
            }

            setLoading(false);
            enqueueSnackbar(routeTranslate(intl, 'modal.brand.edit.success'), {
                variant: 'success',
            });
            callBack();
            handleResetInputValues();
            handleClose();
        } catch (e: any) {
            enqueueSnackbar(routeTranslate(intl, 'modal.brand.editing.error'), {
                variant: 'error',
            });
            setLoading(false);
        }
    };

    const resizeFile = async (file: File) =>
        await new Promise(resolve => {
            Resizer.imageFileResizer(
                file,
                200,
                200,
                'PNG',
                100,
                0,
                uri => {
                    resolve(uri);
                },
                'File',
            );
        });

    function createFileFromBase64(
        base64String: string,
        fileName: string,
    ): File {
        const mimeType = base64String.split(';')[0].split(':')[1];
        const byteString = atob(base64String.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        const file = new File([arrayBuffer], fileName, { type: mimeType });
        return file;
    }

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const file = event.target.files?.[0];
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes

        if (file) {
            if (file.type.startsWith('image/') && file.size <= maxSize) {
                const resizedBase64 = await resizeFile(file);
                const resizedFile = createFileFromBase64(
                    resizedBase64 as string,
                    'brand_img',
                );
                setImgFile(resizedFile);
            } else {
                // Reset the state if the selected file is not an image or exceeds the size limit
                setImgFile(undefined);

                if (!file.type.startsWith('image/')) {
                    alert(routeTranslate(intl, 'modal.brand.fileType.error'));
                } else {
                    alert(routeTranslate(intl, 'modal.brand.fileSize.error'));
                }
            }
        }
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'modalNewProjectDark' : ''}
            >
                <div className="content-modal">
                    <header>
                        <IoMdClose size={25} onClick={handleClose} />
                    </header>
                    <div id="title">
                        <h1>
                            {routeTranslate(
                                intl,
                                'modal.brand.title'.concat(
                                    editing ? '.edit' : '',
                                ),
                            )}
                        </h1>
                        <p>{routeTranslate(intl, 'modal.brand.desc')}</p>
                    </div>
                    <p className="title-upload">
                        {routeTranslate(intl, 'modal.brand.logo')}
                    </p>
                    <div className="file-upload">
                        <label htmlFor="img-file">
                            <div className="img-container">
                                {imgFile ? (
                                    <img
                                        src={URL.createObjectURL(imgFile)}
                                        alt="Uploaded Image"
                                    />
                                ) : editing && item_editing?.logo_url !== '' ? (
                                    <img
                                        src={item_editing?.logo_url}
                                        alt="user Image"
                                    />
                                ) : (
                                    <FiPlus size={20} />
                                )}
                            </div>
                        </label>
                        <input
                            type="file"
                            name="img-file"
                            id="img-file"
                            onChange={handleFileChange}
                            accept="image/*"
                            ref={fileInputRef}
                        />
                    </div>
                    <form
                        onSubmit={handleSubmit(
                            editing ? handleSubmitFormEdit : handleSubmitForm,
                        )}
                    >
                        <Input
                            label={routeTranslate(intl, 'modal.brand.name')}
                            id="name"
                            type="text"
                            placeholder="Jarbas AI"
                            register={register}
                            required
                        />
                        {!editing && occupationList && (
                            <Select
                                label={routeTranslate(
                                    intl,
                                    'modal.brand.segment',
                                )}
                                id="occupation"
                                name="occupation"
                                placeholder="Marketing"
                                register={register}
                                required
                                options={[
                                    {
                                        name: routeTranslate(
                                            intl,
                                            'selectLabel',
                                        ),
                                        value: 'select',
                                    },
                                    ...occupationList.map(data => {
                                        return {
                                            name: data.translations[0].title,
                                            value: String(data.id),
                                        };
                                    }),
                                ]}
                            />
                        )}

                        <div className="container-submit">
                            <Button
                                type="submit"
                                loading={loading}
                                disabled={
                                    (selectedOccupation === 'select' &&
                                        !editing) ||
                                    nameField.length < 1
                                }
                            >
                                {routeTranslate(intl, 'button.continue')}
                                <span>
                                    <HiOutlineArrowNarrowRight size={16} />
                                </span>
                            </Button>
                        </div>
                    </form>
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalNewbBrand;
