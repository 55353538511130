import styled, { css } from 'styled-components';

export const Input = styled.input`
    ${({
        theme: {
            components: {
                input: { border, background, text, placeholder },
            },
        },
    }) => css`
        border: 1px solid ${border.color};
        color: ${text.color};
        background-color: ${background.color};
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.2px;
        border-radius: 8px;
        padding: 16px;
        width: 100%;

        :focus,
        :hover {
            border: 1px solid ${border.active};
        }

        ::placeholder {
            color: ${placeholder.color};
            opacity: 100%;
        }

        :-ms-input-placeholder {
            color: ${placeholder.color};
        }

        ::-ms-input-placeholder {
            color: ${placeholder.color};
        }
    `}
`;
