import styled from 'styled-components';

export const IntegrationsContent = styled.div`
    width: 100%;

    .toggle {
        width: 44px;
        height: 24px;
        background: #edf2f7;
        border-radius: 1000px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.active {
            justify-content: flex-end;
            background: #1a202c;

            > div {
                border: 2px solid #1a202c;
            }
        }

        > div {
            width: 24px;
            height: 24px;
            background: #ffffff;
            border-radius: 1000px;
            border: 2px solid #edf2f7;
        }
    }

    .box {
        min-height: 108px;

        header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            h1 {
                font-size: 1em;
            }
        }

        @media (min-width: 64em) {
            min-height: 88px;

            header {
                margin-bottom: 4px;
            }
        }

        p {
            font-weight: 400;
            font-size: 0.75em;
            color: #1a202c;
        }
    }
`;
