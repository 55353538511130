export type FileType = 'IMG' | 'GIF';

const SUPPORTED_TYPES = new Map<FileType, string[]>([
    [
        'IMG',
        [
            'image/png',
            'image/jpeg',
            'image/jpg',
            'image/bmp',
            'image/svg+xml',
            'image/webp',
        ],
    ],
    ['GIF', ['image/gif']],
]);

export type Image = { type: 'url'; url: string } | { type: 'file'; file: File };

export const validateFileType = (
    type: FileType,
    specificType: string,
): boolean => {
    const supported = SUPPORTED_TYPES.get(type);
    return (
        supported != undefined &&
        supported.find(t => t == specificType) != undefined
    );
};

export const acceptedTypes = (type: FileType): string => {
    const supported = SUPPORTED_TYPES.get(type);
    if (!supported) {
        return '';
    }
    return supported.join(',');
};

export const isSupportedType = (specificType: string): boolean => {
    const types = SUPPORTED_TYPES.keys();
    for (let type = types.next(); !type.done; type = types.next()) {
        if (validateFileType(type.value, specificType)) {
            return true;
        }
    }
    return false;
};

export const loadFile = (path: string): Promise<File> => {
    if (!path) {
        return new Promise<File>((resolve, reject) => {
            reject();
        });
    }
    return new Promise<File>((resolve, reject) => {
        fetch(path)
            .then(response => response.blob())
            .then(blob => {
                if (!isSupportedType(blob.type)) {
                    reject();
                }
                const fileName = path.split('/').pop();
                const file = new File(
                    [blob],
                    fileName != undefined ? fileName : '',
                    { type: blob.type },
                );
                resolve(file);
            })
            .catch(error => reject(error));
    });
};

export const loadPath = (file: File, setPath: (path: string) => void): void => {
    const reader = new FileReader();
    reader.onloadend = () => {
        setPath(reader.result as string);
    };
    reader.readAsDataURL(file);
};

export const createFileList = (files: File[]): FileList => {
    const dataTransfer = new DataTransfer();
    files.forEach(file => {
        dataTransfer.items.add(file);
    });
    return dataTransfer.files;
};

export const parseSVGToPNG = (svgFile: File): Promise<File> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = event => {
            const svgContent = event.target?.result as string;

            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const aspectRatio = img.width / img.height;
                canvas.width = 1000;
                canvas.height = 1000 / aspectRatio;
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    return reject(new Error('Failed to convert SVG to image'));
                }
                ctx.drawImage(img, 0, 0, 1000, 1000 / aspectRatio);
                canvas.toBlob(blob => {
                    if (!blob) {
                        return reject(
                            new Error('Failed to convert SVG to image'),
                        );
                    }
                    const imageFile = new File(
                        [blob],
                        `${svgFile.name.split('.')[0]}.png`,
                        {
                            type: 'image/png',
                            lastModified: Date.now(),
                        },
                    );
                    resolve(imageFile);
                }, 'image/png');
            };

            img.src = 'data:image/svg+xml;base64,' + btoa(svgContent);
        };

        reader.onerror = error => {
            reject(error);
        };

        reader.readAsText(svgFile);
    });
};
