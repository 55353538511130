import styled from 'styled-components';
import Menu from '@mui/material/Menu';

// table copied from documents
export const StyledTable = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    margin-top: 30px;

    tbody {
        .styled-svg {
            cursor: pointer;
            width: 22px;
            height: 22px;
            border: 1px solid #e2e8f0;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: '#718096';
            margin-left: 5px;
        }

        /*tr {
            :hover {
                background-color: rgba(241, 241, 241, 1);
            }
        }*/
    }

    tr {
        border-bottom: 1px solid #edf2f7;
        height: 80px;

        .action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    tr:last-of-type {
        border-bottom: 0;
    }

    .dots {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        :focus {
            background-color: red;
        }
    }

    th {
        font-weight: 700;
        white-space: nowrap;
        font-size: 16px;
        line-height: 24px;

        overflow: hidden;
        text-overflow: ellipsis;
        
        .order {
            position: relative;
            top: 3px;
            right: -5px;
        }
    }

    .th-collection {
        font-weight: 600;
        max-width: 330px;

    }

    td {
        color: #000000;
        font-weight: 500;
        text-align: left;
    }

    .icon {
        margin: 0 24px 0 0 !important;
    }

    .collection-row {
        display: flex;
        align-items: center;
        
        gap: 8px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    & .MuiTableHead-root .MuiTableCell-root {
        color: #a09fa5;
        font-size: 16px;
    }

    .span-title {
        cursor: pointer;

        :hover {
            color: blue;
            border-bottom: 1px solid blue;
        }
    }

    .empty {
        color: grey;
    }
`;

export const StyledMenu = styled(Menu)`
    .MuiPaper-root {
        width: 160px;
        border-radius: 12px;
        //box-shadow: none;
        box-shadow: 8px 12px 24px 0px rgba(93, 106, 131, 0.1);

        li {
            height: 32px;
        }
    }

    .MuiList-root {
        margin: 8px;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .actions-dropdown-btn {
        display: flex;
        gap: 12px;
        font-weight: 600;
        font-size: 12px;
        padding-left: 8px;
        padding-bottom: 7px;
        padding-top: 7px;
        height: 32px;
    }
`;

export const TablePagination = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 32px;

    .container-left {
        display: flex;
        gap: 16px;
        font-weight: 700;
        font-size: 14px;
        color: rgba(126, 126, 126, 1);

        align-items: center;
    }

    .container-right {
        margin-right: 10px;
        display: flex;

        align-items: center;

        svg {
            color: rgba(160, 159, 165, 1);
            margin: 10px;
        }
    }
`;

type PaginationBoxProps = {
    selected: boolean;
}

export const PaginationBox = styled.div<PaginationBoxProps>`
    width: 40px;
    height: 40px;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 700;

    display: flex;
    justify-content: center; 
    align-items: center; 
    
    border: ${props => props.selected ? '1px solid rgba(26, 32, 44, 1)' : '0'};
    color: ${props => props.selected ? 'black' : 'rgba(160, 159, 165, 1)'};

    cursor: ${props => props.selected ? 'default' : 'pointer'};

    :hover {

        background-color: ${props => props.selected ? '' : 'rgba(241, 241, 241, 1)'};
    }
`;
