import { Slider } from '@mui/material';
import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: rgba(17, 21, 29, 0.92);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .content-modal {
        background: #ffffff;
        border-radius: 16px;
        width: 98%;
        max-width: 851px;
        height: fit-content;
        max-height: 90vh; // TODO fix div focus when height changes
        display: block;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none !important;
        }

        &::-moz-scrollbar {
            display: none !important;
        }

        header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: right;
            margin-left: 32px;
            margin-top: 32px;
            margin-right: 32px;

            h1 {
                font-size: 1.5rem;
                font-weight: 700;
                color: #000;
            }

            svg {
                cursor: pointer;
            }
        }

        .buttons-insert-content {
            margin-top: 30px;
            p {
                font-weight: bold;
            }

            .buttons {
                display: flex;
                justify-content: space-between;
                gap: 24px;
                //width: 110%;
                margin-top: 12px;
                margin-bottom: 32px;
                div {
                    li {
                        list-style-type: none;
                        background: #edf2f7;
                        border-radius: 12px;
                        padding: 18px 20px;
                    }
                    width: 50%;
                    height: 180px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #edf2f7;
                    border-radius: 8px;
                    padding: 0px 16px 0px 20px;
                    cursor: pointer;

                    h6 {
                        margin-top: 16px;
                        margin-bottom: 4px;
                        font-size: 1rem;
                        font-weight: 700;
                        color: #000;
                    }
                    span {
                        font-size: 0.875rem;
                        color: #7e7e7e;
                        font-weight: 500;
                    }

                    &.active {
                        background: #f6ffdf;
                        border-color: #000;
                        li {
                            background: #d0ff61;
                        }
                    }
                }
            }

            .input-textarea-container {

                position: relative;
                p {
                    color: #7e7e7e;
                    font-size: 0.875rem;
                    margin-top: 12px;
                    margin-bottom: 40px;
                }

                textarea {
                    margin-bottom: 32px;
                }
                .counter {
                    position: absolute;
                    right: 0;
                    top: 12px;
                    color: #a09fa5;
                    font-size: 0.75rem;
                }
                .btn-container {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    button {
                        width: 160px;
                        height: 56px;
                        font-size: 1rem;
                        justify-self: flex-end;
                        align-self: flex-end;
                        margin-bottom: 32px;
                    }
                }
            }

            @media (max-width: 64em) {
                .buttons {
                    flex-direction: column;
                    .btn-text {
                        margin-top: 24px;
                        margin-left: 0;
                    }
                }
            }
        }

        .step2-container {
            //font-size: 0.875rem;
            margin-top: 30px;
            margin-left: 32px;
            margin-right: 32px;

            .generated {
                padding: 16px 18px;
                border: 1px solid #cbd5e0;
                border-radius: 8px;


                textarea {
                    min-height: 140px;
                    &::-webkit-scrollbar {
                        display: none !important;
                    }

                    &::-moz-scrollbar {
                        display: none !important;
                    }
                }

            }

            .form {
                margin-top: 30px;

                .checkbox-btn {
                    display: flex;
                    margin: 30px 0;
                    justify-content: space-between;
                    .checkbox {
                        display: flex;
                        align-items: center;

                        p {
                            color: #1a202c;
                            font-weight: 600;
                            font-size: 1rem;
                        }
                    }
                    button {
                        width: 160px;
                        height: 56px;
                        font-weight: 600;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    // TODO maybe remove this definitons of specific modal and move elsewhere, related to the modal
    &.modalNewToneDark {
        .content-modal {
            background: #1d1d1f !important;
            input,
            textarea {
                background: #3a3a3a;
                border-color: #3a3a3a !important;
                color: #fff;

                &:focus {
                    border-color: #fff !important;
                }
            }
            p {
                color: #a09fa5;
            }
            header {
                h1 {
                    color: #fff;
                }
            }

            .buttons-insert-content {
                p {
                    color: #fff;
                }

                .buttons {
                    background: #1d1d1f !important;

                    h6 {
                        color: #fff;
                    }
                    div {
                        li {
                            background: #000;
                        }

                        border: 1px solid #edf2f7;

                        &.active {
                            background: #d0ff61;
                            border-color: #000;
                            h6 {
                                color: #000;
                            }
                            span {
                                color: #1d1d1f;
                            }
                            li {
                                background: #000;
                            }
                        }
                    }
                }
            }

            .step2-container {

                .generated {
                    background: #000;
                    border-color: #000;
                    p {
                        color: #fff;
                    }

                    .input-btn {
                        display: flex;
                        form {
                        }
                        button {
                            background: #000000;
                            border-color: #fff;
                        }
                    }
                }

                .form {
                    .checkbox-btn {
                        .checkbox {
                            p {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .sub-header {
        font-size: 1rem;
        margin-top: 32px;
        margin-left: 32px;
        margin-right: 32px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        height: 60px;
    }

    .styled-hr {
        transform: rotate(90deg);
        border: 1px dashed #a09fa5;
    }

    .subheader-hr {
        margin-left: 32px;
        margin-right: 32px;
        border-bottom: 1px solid #e2e8f0;
    }

    .sub-header-item,
    .sub-header-item-current,
    .sub-header-item-completed,
    .sub-header-item-inactive {
        gap: 12px;
        display: flex;
        align-items: center;
    }

    .sub-header-item-current {
        font-weight: bold;
        .sub-header-item-ball {
            background-color: #000;
        }
    }

    .sub-header-item-completed {
        font-weight: bold;
        .sub-header-item-ball {
            background-color: #d0ff61;

            p {
                color: black;
            }

        }
    }

    .sub-header-item-inactive {
        color: #7e7e7e;
        .sub-header-item-ball {
            background-color: #a09fa5;
        }
    }


    .sub-header-item-ball {
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #000;
        align-items: center;
        justify-content: center;

        //margin-right: 10%;

        p {
            color: white;
        }
    }

    .step-1 {
        margin-top: 30px;
        margin-left: 32px;
        margin-right: 32px;
    }

    .teste {
        font-weight: bold;
    }

    .enhance-tone-form {
        display: flex;
        margin-top: 12px;
        justify-content: space-between;
        flex-basis: 10%;
        gap: 12px;
    }

    .enhance-tone-btn {
        padding-top: 8px;
        padding-right: 8px;
        padding-left: 8px;
        padding-bottom: 8px;
        font-weight: 700;
        font-size: 16px;
    }
`;

export const Step3and4Container = styled.div`
    margin-top: 30px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 32px;

    h1 {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 12px;
    }

    span {
        color: #7e7e7e;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        gap: 50%;
        margin-top: 30px;

        .buttons-child {
            padding-top: 12px;
            padding-right: 24px;
            padding-bottom: 12px;
            padding-left: 24px;
            font-size: 16px;
            font-weight: 700;
        }

        p {
            color: #000;
        }

        .left {
            background-color: #f1f1f1;
            width: 15%;
        }

        .right {
            background-color: #d0ff61;
            width: 20%;
        }
    }

    & .MuiAlert-root {
        background-color: #fbeeb6;
    }
`;

export const StyledSlider = styled(Slider)(({}) => ({
    marginTop: '15px',
    /*marginLeft: '5%',
    marginRight: '5%',
    width: '90% !important',*/

    '& .MuiSlider-markLabel[data-index="0"]': {
        transform: 'translateX(0%)',
    },

    '& .MuiSlider-markLabel[data-index="1"]': {
        transform: 'translateX(-100%)',
    },

    '& .MuiSlider-thumb': {
        backgroundColor: '#000',
    },

    '& .MuiSlider-rail': {
        color: '#e2e8f0',
    },

    '& .MuiSlider-track': {
        color: '#000',
    },
}));
