import React from 'react';
import { InputContainer, Label, SelectStyled } from './styled';
import { SelectProps } from '../../interfaces/select-props.interface';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

export const Select: React.FC<SelectProps> = ({
    id,
    label,
    register,
    options,
    ...rest
}) => {
    const intl = useIntl();
    const { darkmode } = useDarkMode();
    const isLanguages = options
        ? options[0].name === 'Portuguese' || options[0].name === 'pt-BR'
        : false;

    return (
        <InputContainer>
            {label && <Label htmlFor={id}>{label}</Label>}
            <SelectStyled
                {...register(id)}
                {...rest}
                className={darkmode ? 'darkModeSelect' : ''}
            >
                {options ? (
                    options.map((option, index) => {
                        const name = isLanguages
                            ? routeTranslate(intl, `languages.${option.name}`)
                            : option.name;
                        return (
                            <option key={index} value={option.value ?? index}>
                                {name}
                            </option>
                        );
                    })
                ) : (
                    <></>
                )}
            </SelectStyled>
        </InputContainer>
    );
};
