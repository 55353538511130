import styled from 'styled-components';

export const FadeBackground = styled.div`
    background-color: rgba(17, 21, 29, 0.5);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContainer = styled.div`
    width: 847px;    
    background-color: white;


    border-radius: 16px;
`;

export const ModalContent = styled.div`
    margin-top: 20px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 32px;

    button {
        cursor: pointer;
    }

    .header {
        display: flex;
        align-items: center;

        padding-bottom: 20px;

        border-bottom: 1px solid rgba(226, 232, 240, 1);

        span {
            font-weight: 700;
            font-size: 24px;
        }

        *:first-child {
            margin-right: auto;
        }

        *:last-child {
            margin-left: auto;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;

            border: 0;
            width: fit-content;
            height: fit-content;
            background-color: white;
            
            cursor: pointer;
            
        }
       
    }

    .table {
        height: fit-content;
        max-height: 70vh;
        overflow-y: scroll;

        .table-head {
            display: flex;
            align-items: center;

            height: 64px;
            border-bottom: 1px solid rgba(241, 241, 241, 1);

            p {
                font-size: 16px;
                color: rgba(160, 159, 165, 1);
                font-weight: 700;
            }
        }

        .table-body {
            .row {
                display: flex;
                align-items: center;

                height: 80px;
                border-bottom: 1px solid rgba(241, 241, 241, 1);
            
                p {
                    font-size: 14px;
                    font-weight: 700;
                    color: black;
                }
            
            }
        }

        .checkbox {
            width: 7%;
        }

        .title {
            width: 40%;
            
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 300px;

                cursor: pointer;
                
                font-size: 14px;
                font-weight: 700;
                
            }

            span:hover {
                color: blue;
                border-bottom: 1px solid blue;
            }
        }

        .empty {
            color: grey;
        }

        .status {
            width: 24%;

            display: flex;
            align-items: center;
            flex-shrink: 0;
            gap: 8px;
        }

    }

    .btn-line {
        margin-top: 30px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;

            width: 250px;
            height: 48px;

            font-size: 14px;
            border-radius: 6px;
        }
    }
`;

export const Ellipsis = styled.div`
    width: 10px;
    height: 10px;
    background-color: rgba(160, 159, 165, 1);

    border-radius: 50%;

    &.published {
        background-color: rgba(34, 197, 94, 1) ;
    }

    &.draft {
        background-color: rgba(255, 200, 55, 1);
    }
`;
