import React from 'react';
import * as S from './styles';
import { IconCloudUpload } from '@tabler/icons-react';
import LogoSVG from 'assets/images/png/logo-chat-branco.png';
import { FormFieldContainer } from '../FormField';
import { Button } from '../Button';
import {
    Image,
    acceptedTypes,
    loadFile,
    loadPath,
    validateFileType,
} from 'utils/files';

interface ImgInputProps {
    name: string;
    img: Image;
    onChangeIMG: (value: Image) => void;
    title: string;
    buttonText: string;
    imgOptions?: string[];
    color?: string;
}

export const ImgInput: React.FC<ImgInputProps> = ({
    name,
    img,
    onChangeIMG,
    title,
    buttonText,
    color,
    imgOptions = null,
}) => {
    const [imgUrl, setImgUrl] = React.useState<string>('');
    const [selectedOpt, setSelectedOpt] = React.useState<number>(-1);

    React.useEffect(() => {
        switch (img.type) {
            case 'file':
                if (img.file == null) return;
                loadPath(img.file, setImgUrl);
                break;
            case 'url':
                setImgUrl(img.url);
                break;
        }
    }, [img]);

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const openFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (
            files &&
            files.length > 0 &&
            validateFileType('IMG', files[0].type)
        ) {
            onChangeIMG({ file: files[0], type: 'file' });
        }
        setSelectedOpt(-1);
    };

    const handleOptionClick = (
        event: React.MouseEvent<HTMLDivElement>,
        index: number,
    ) => {
        const container = event.currentTarget;
        const imgElement = container.firstChild as HTMLImageElement;
        const path = imgElement.src;
        loadFile(path).then(file => onChangeIMG({ file: file, type: 'file' }));
        setSelectedOpt(index);
    };

    return (
        <S.ImgInput color={color}>
            <FormFieldContainer info={{ title }}>
                <div className="input-body-container">
                    <div className="input-container">
                        <div className="img-container">
                            <img src={imgUrl ? imgUrl : LogoSVG} />
                        </div>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={openFileInput}
                        >
                            <IconCloudUpload></IconCloudUpload>
                            <span>{buttonText}</span>
                        </Button>
                        <input
                            name={name}
                            type="file"
                            accept={acceptedTypes('IMG')}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                    </div>
                    {imgOptions && (
                        <div className="options-container">
                            {imgOptions.map((imgOption, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            'img-container options-button' +
                                            (index == selectedOpt
                                                ? ' active'
                                                : '')
                                        }
                                        onClick={event => {
                                            handleOptionClick(event, index);
                                        }}
                                    >
                                        <img src={imgOption} />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </FormFieldContainer>
        </S.ImgInput>
    );
};
