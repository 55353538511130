import { api } from '../api';

export class AudioService {
    private readonly path = '/audio';

    async getAll() {
        const { data } = await api.get(
            `${this.path}/transcriptions?page=1&limit=50`,
        );

        return data;
    }

    async getStatus(id: number) {
        const { data } = await api.get(`${this.path}/transcriptions/${id}`);

        return data;
    }

    async generate(formData: FormData) {
        const result = await api.post(`${this.path}/transcriptions`, formData);

        return result;
    }

    async delete(id: number) {
        const { data } = await api.delete(`${this.path}/transcriptions/${id}`);

        return data;
    }

    async rename(id: number, payload: { title: string }) {
        const { data } = await api.patch(
            `${this.path}/transcriptions/${id}`,
            payload,
        );

        return data;
    }

    getSocket = () => {
        return new WebSocket(
            `wss://jarbas-core-api-igvihsmleq-uc.a.run.app/v1/audio/ws/transcription/status`,
        );
    };
}
