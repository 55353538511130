import { Tooltip } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';

type IEye = {
    color?: string;
    darkmode?: boolean;
};

export const EyeLayout: React.FC<IEye> = ({
    darkmode = false,
    color = '#1A202C',
}) => {
    const intl = useIntl();

    return (
        <Tooltip
            title={routeTranslate(intl, 'tooltip.eye')}
            placement="top"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9 11C9.82843 11 10.5 10.3284 10.5 9.5C10.5 8.67157 9.82843 8 9 8C8.17157 8 7.5 8.67157 7.5 9.5C7.5 10.3284 8.17157 11 9 11Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.5 9.5C14.4997 13.0002 12 14.75 9 14.75C6 14.75 3.50025 13.0002 1.5 9.5C3.50025 5.99975 6 4.25 9 4.25C12 4.25 14.4997 5.99975 16.5 9.5Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Tooltip>
    );
};
