import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import { Button } from '../../common';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { PlansService } from 'services/plans';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
}

const plansService = new PlansService();

const ModalPaymentError: React.FC<ModalProps> = ({ active, handleClose }) => {
    const intl = useIntl();
    const [loading, setLoading] = React.useState(false);
    const { workspace } = useUserConfig();

    const handleOpenStripePortal = async () => {
        setLoading(true);
        const req = {
            workspace_id: workspace.id,
            return_url: window.location.href,
        };
        const data = await plansService.createPortalSession(req);

        if (data.url) {
            const urlRedirect = data.url as string;

            window.location.href = urlRedirect;
        } else {
            setLoading(false);
        }
    };

    const { darkmode } = useDarkMode();

    return (
        <Fade in={loading ? loading : active} timeout={400}>
            <ContainerModal
                onClick={handleClose}
                className={darkmode ? 'listProjectsDark' : ''}
            >
                <div className="content-modal">
                    <h2>{routeTranslate(intl, 'modal.paymetError.title')}</h2>
                    <p>
                        {routeTranslate(intl, 'modal.paymetError.description')}
                    </p>
                    <div className="action-buttons">
                        <Button
                            type="button"
                            onClick={handleOpenStripePortal}
                            loading={loading}
                        >
                            {routeTranslate(intl, 'modal.paymetError.button')}
                        </Button>
                    </div>
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalPaymentError;
