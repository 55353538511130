import { api } from '../api';
import {
    Generate,
    Report,
    TypeField,
    Variation,
} from '../../interfaces/arte-ai.interface';
import { Rate } from '../../interfaces/documents.interface';

export class ArteAiService {
    private readonly path = '/ai-art';

    async getAll(page: number, search?: string) {
        const { data } = await api.get(
            `${this.path}/history?page=${page}&limit=12${
                search ? '&prompt=' + search : ''
            }`,
        );

        return data;
    }

    async getOne(id: number) {
        const { data } = await api.get(`${this.path}/${id}`);

        return data;
    }

    async getFields(type: TypeField) {
        const { data } = await api.get(`${this.path}/fields/all?type=${type}`);

        return data;
    }

    async rateResponse(rate: Rate) {
        const { data } = await api.post(`${this.path}/rate`, rate);

        return data;
    }

    async create(request: Generate) {
        const { data } = await api.post(`${this.path}/generate-image`, request);

        return data;
    }

    async createVariation(request: Variation) {
        const { data } = await api.post(
            `${this.path}/generate-variations`,
            request,
        );

        return data;
    }

    async img2img(request: FormData) {
        const { data } = await api.post(
            `${this.path}/generate-img2img`,
            request,
        );

        return data;
    }

    async report(request: Report) {
        const { data } = await api.post(`${this.path}/report`, request);

        return data;
    }

    async reportTypes() {
        const { data } = await api.get(`${this.path}/report/types`);

        return data;
    }

    async getCategories() {
        const { data } = await api.get(`${this.path}/categories`);

        return data;
    }

    async getPrompt(id: number) {
        const { data } = await api.get(`${this.path}/prompts?category=${id}`);

        return data;
    }

    async getPromptSubCategories(id: number, subcategorieId: number) {
        const { data } = await api.get(
            `${this.path}/prompts?category=${id}&subcategory=${subcategorieId}`,
        );

        return data;
    }

    async getSubcategories(id: number) {
        const { data } = await api.get(
            `${this.path}/subcategories?category=${id}`,
        );

        return data;
    }

    async upscaleImage(formData: FormData) {
        const { data } = await api.post(
            `${this.path}/generate-upscale`,
            formData,
        );

        return data;
    }
}
