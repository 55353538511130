import React from 'react';
import MemoriesContext from 'app/presentation/contexts/MemoriesContext';
import { DeleteMemoryReq, Memory, MemoryContentTranslation } from 'services/brand/memories/types';
import { type Pagination } from "services/commons";
import {
    deleteMemory,
    getAllMemories,
    getMemory,
    getMemoryContentsPublishStatuses,
    putMemory
} from 'services/brand/memories';
import {
    type GetAllMemoriesReq,
    type GetMemoryReq,
    type MemoryContentStatus,
    type GetMemoryContentsPublishStatusesReq,
    type UpdateMemoryReq
} from 'services/brand/memories/types';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';

export interface ProviderProps {
    children: React.ReactNode;
}

const MemoriesProvider: React.FC<ProviderProps> = ({ children }) => {
    const { workspace, project, activeBrand: brand } = useUserConfig();

    const [selectedMemories, setSelectedMemories] = React.useState<Set<number>>(new Set());
    const [memories, setMemories] = React.useState<Pagination<Memory> | undefined>();
    const [currPage, setCurrPage] = React.useState<number>(1);
    const [currLimit, setCurrLimit] = React.useState<number>(10);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => { fetchMemories() }, [currPage, currLimit, brand]);

    const fetchMemories = async (page = currPage, limit = currLimit, onlyParentless = false, languageId = brand?.brand_config.default_language): Promise<void> => {
        if (!brand) return;

        setLoading(true);

        const req: GetAllMemoriesReq = {
            paginationParams: {
                page: page,
                limit: limit,
            },
            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id,
            },

            onlyParentless: onlyParentless,
            language_id: languageId!
        }

        try {
            const memories = await getAllMemories(req)
            setMemories(memories);
        } catch (err) {
            throw err;
        } finally {
            setLoading(false);
        }
    }

    const fetchMemory = async (memoryID: number, languageID: number): Promise<Memory | undefined> => {
        if (!brand) return;

        setLoading(true);

        const req: GetMemoryReq = {
            id: memoryID,
            language_id: languageID,
            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id,
            }
        }

        try {
            const memory = await getMemory(req)
            return memory
        } catch (err) {
            throw err
        } finally {
            setLoading(false);
        }
    }

    const fetchMemoryContentsPublishStatuses = async (memoryID: number): Promise<MemoryContentStatus[] | undefined> => {
        if (!brand) return;

        setLoading(true);

        const req: GetMemoryContentsPublishStatusesReq = {
            id: memoryID,
            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id,
            }
        }

        try {
            const publishStatuses = await getMemoryContentsPublishStatuses(req);
            return publishStatuses
        } catch (err) {
            throw err
        } finally {
            setLoading(false);
        }
    }

    const updateMemory = async (
        memoryID: number,
        translations: MemoryContentTranslation[],
        groupID?: number,
        visibility?: string,
    ): Promise<Memory | undefined> => {
        if (!brand) return;

        setLoading(true);

        const req: UpdateMemoryReq = {
            memoryID: memoryID,

            data: {
                translations: translations,
            },

            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id
            }
        }

        if (visibility) {
            req.data.visibility = visibility;
        }

        if (groupID) {
            req.data.brand_memory_group_id = groupID;
        }

        try {
            const memory = await putMemory(req);
            return memory;
        } catch (err) {
            throw err;
        } finally {
            setLoading(false);
        }
    }

    const removeMemory = async (memoryId: number): Promise<void> => {
        if (!brand) return;

        const req: DeleteMemoryReq = {
            memoryId: memoryId,

            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id
            }
        }

        return deleteMemory(req);
    }

    return (
        <MemoriesContext.Provider
            value={{
                memories: memories,
                selectedMemories: selectedMemories,
                setSelectedMemories: setSelectedMemories,

                currPaginationPage: currPage,
                setCurrPaginationPage: setCurrPage,

                currPaginationLimit: currLimit,
                setCurrPaginationLimit: setCurrLimit,

                loading: loading,
                setLoading: setLoading,

                fetchMemories: fetchMemories,
                fetchMemory: fetchMemory,
                fetchMemoryContentsPublishStatuses: fetchMemoryContentsPublishStatuses,
                updateMemory: updateMemory,
                removeMemory: removeMemory
            }}
        >
            {children}
        </MemoriesContext.Provider>
    );

}

export default MemoriesProvider;
