import styled from 'styled-components';

export const Content = styled.div`
    width: 100%;
    padding: 0 20px;

    .lineHrHeader {
        display: none;
    }

    @media (min-width: 64em) {
        padding: 0 40px;
        .lineHrHeader {
            display: block;
            margin-top: -25px;
            border: none;
            border-bottom: 1px solid #e2e8f0;
        }
    }
`;

export const Clear = styled.div`
    clear: both;
`;
