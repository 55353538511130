import React from 'react';
import TagContext from '../contexts/TagContext';
import { TagContextData } from '../contexts/interfaces/tag-context-props.interface';

function useTag(): TagContextData {
    const context = React.useContext(TagContext);

    if (!context) {
        throw new Error('useTag deve ser usado com o TagProvider');
    }

    return context;
}

export default useTag;
