import styled from 'styled-components';

interface HeaderProps {
    agentLayout: boolean
}

export const Header = styled.div<HeaderProps>`
    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.5em;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    .beta {
        min-width: 66px;
        min-height: 24px;
        background: #d0ff61;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-left: 12px;
    }

    @media (min-width: 64em) {
        padding: 0 40px;
        margin-bottom: ${({ agentLayout }) => agentLayout ? '0px' : '37px'};
        position: relative;
        display: flex;
        justify-content: space-between;

        h1 {
            position: absolute;
            top: ${({ agentLayout }) => agentLayout ? '-55px' : '-30px'};
        }

        .input {
            width: 295px;
        }
    }
    &.headerChatDark {
        border-color: #1d1d1f !important;

        h1 {
            color: #ffffff;
            span {
                color: #000;
            }
        }
    }
`;

interface ContentProps {
    agentLayout: boolean
}

export const ContainerContent = styled.div<ContentProps>`
    padding: 0px;
    display: flex;
    border-top: ${({ agentLayout }) => agentLayout ? '0px' : '1px'} solid #e2e8f0;
    overflow: hidden;
    margin: 0;
    padding: 0px;

    button {
        cursor: pointer;
    }

    .empty-brand-center {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-top: 56px;

        h3 {
            margin-top: 18px;
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 26px;
        }
        p {
            font-size: 0.875rem;
            font-weight: 500;
            color: #7e7e7e;
        }

        button {
            height: 48px;
            margin-top: 30px;
            font-size: 0.875rem;
        }
    }

    .content-route {
        width: 100%;
        height: 100vh;

        overflow: auto;

        padding-top: 40px;
        padding-right: 45px;
        padding-left: 36px;
    }

    &.contentDark {
        .selectors {
            .container-buttons {
                button {
                    color: #7e7e7e;
                }

                .selected {
                    color: #fff;
                    border-bottom: 2px solid #fff;
                    &:hover {
                        border-color: #fff;
                    }
                }
            }
        }
        .selected-desc {
            h3 {
                color: #fff;
            }
        }
    }
`;

export const BrandCenterMenu = styled.div`
    height: 100vh;
    width: 283px;
    min-width: 283px;
    max-width: 283px;
    border-right: 1px solid #e2e8f0;
    margin: 0;
    padding-top: 22px;

    .MuiListItemButton-root {
        display: flex;
        gap: 10px;

        .MuiListItemText-primary {
            font-size: 0.875rem;
            font-weight: 500;
            font-style: normal;
            line-height: 150%;
            letter-spacing: 0.2px;
        }

        .expandIcon {
            color: #7e7e7e;
        }
    }

    .active {
        .MuiListItemButton-root {
            border-left: 1px solid #000;
            background: #f1f1f1;

            .MuiListItemText-primary {
                font-weight: 600;
            }
        }
    }
`;
