import {
    Button,
    InfoMessage,
    SwitchCheckbox,
} from 'app/presentation/components/agent';
import Loading from 'app/presentation/components/common/Loading';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';
import { TbCirclePlus, TbPencil } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { TrainingService } from 'services/training';
import { GetEligibleInformationResponse } from 'services/training/interfaces';
import * as S from './styles';

interface BrandCenterPanelProps {
    eligibleInformation: GetEligibleInformationResponse | undefined;
    useMemories: boolean | undefined;
    setUseMemories: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    notify: () => void;
}

export const BrandCenterPanel = ({
    eligibleInformation,
    useMemories,
    setUseMemories,
    notify,
}: BrandCenterPanelProps) => {
    const { routeGetter, textGetter } = useText();
    const route = routeGetter('', true);

    const { agent } = useAgent();
    const navigate = useNavigate();
    const panelText = textGetter('agent.panel.sources');
    const commonsText = textGetter('agent.panel.commons');

    const service = new TrainingService();

    const handleUseMemories = (use: boolean) => {
        if (!agent) return;

        service.updateUseMemories(agent.id, use);
        if (use) notify();
    };

    return (
        <S.Container>
            {eligibleInformation !== undefined && useMemories !== undefined ? (
                <></>
            ) : (
                <Loading open />
            )}
            {eligibleInformation?.eligible === 0 && (
                <InfoMessage
                    type="yellow"
                    text={panelText('brand-center.zero-eligible.text')}
                />
            )}
            <SwitchCheckbox
                disabled={eligibleInformation?.eligible === 0}
                name="use-memories"
                value={useMemories || false}
                info={{
                    title: panelText('brand-center.use-memories-switch.text'),
                }}
                setValue={(v: boolean) => {
                    setUseMemories(v);
                    handleUseMemories(v);
                }}
            ></SwitchCheckbox>

            <p className="eligible-count">
                {panelText('brand-center.eligible.count', {
                    eligible: eligibleInformation?.eligible || 0,
                    total: eligibleInformation?.total || 0,
                })}
            </p>

            <p className="eligible-description">
                {' '}
                {panelText('brand-center.eligible.description')}
            </p>

            <div className="buttons-container">
                <Button
                    variant="primary"
                    type="button"
                    onClick={() =>
                        navigate(route(
                            'agent.home',
                            'brand-center',
                            'brand-center.knowledge',
                        ))
                    }
                >
                    <TbCirclePlus></TbCirclePlus>
                    {panelText('brand-center.button.add')}
                </Button>

                <Button
                    variant="primary"
                    onClick={() =>
                        navigate(
                            route('agent.home', {param: agent ? agent.id : '0'}, 'agent.training'),
                        )
                    }
                >
                    <TbPencil></TbPencil>
                    <span>{commonsText('button.manage')}</span>
                </Button>
            </div>
        </S.Container>
    );
};
