import React from 'react';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { ContainerImg, UploadedImgContainer } from './styles-img2img';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import { DropdownContent } from './styles';
import { FiChevronDown, FiUploadCloud } from 'react-icons/fi';
import { BiTrash, BiUpload } from 'react-icons/bi';
import Slider from 'app/presentation/components/dashboard/Slider';
import { resizeInit } from './utils/resizeImg';

type IImg2Img = {
    file: File | null;
    setFile: (value: File | null) => void;
    hasFile: boolean;
    sliderValue: number;
    setSliderValue: (value: number) => void;
    setImgCropped: (value: string) => void;
    h: number;
    w: number;
    ratioKey: string;
};

const ImgToImg: React.FC<IImg2Img> = ({
    file,
    setFile,
    hasFile,
    sliderValue,
    setSliderValue,
    h,
    w,
    ratioKey,
    setImgCropped,
}) => {
    const { darkmode } = useDarkMode();
    const [imgData, setImgData] = React.useState<any>(null);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(true);
    const imgCustomStyle = {
        height: '100%',
        width: '100%',
        maxWidth: 180,
        maxHeight: 100,
        minHeight: 100,
    };
    const [containerCustomStyle, setContainerCustomStyle] = React.useState({
        maxWidth: 100,
        maxHeight: 100,
        height: isDropdownOpen ? '100%' : 0,
        width: '100%',
    });
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (isDropdownOpen && dropdownRef.current) {
            dropdownRef.current.style.height = 'auto';
            dropdownRef.current.style.opacity = '1';
        } else if (!isDropdownOpen && dropdownRef.current) {
            dropdownRef.current.style.opacity = '0';
            dropdownRef.current.style.height = '0';
        }
    }, [isDropdownOpen]);

    React.useEffect(() => {
        if (file) {
            try {
            } catch (err) {
                console.log(err);
            }
        }
    }, [w, h]);

    const intl = useIntl();

    const handleChange = (e: any) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            setFile(e.target.files[0]);
            try {
            } catch (err) {
                console.log(err);
            }
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleCleanImage = () => {
        setFile(null);
    };
    const handleChangeValue = (value: number | number[]) => {
        setSliderValue(value as number);
    };

    const blurValue = (100 - sliderValue) / 40;

    const handleChangeImgSize = async (w: number, h: number, src: string) => {
        const props = {
            init: {
                base64: src,
            },
            height: h,
            width: w,
        };
        const result = await resizeInit(props);
        if (result) {
            setImgCropped(result);
        }
    };

    React.useEffect(() => {
        if (imgData && file) {
            handleChangeImgSize(w, h, imgData);
        }
    }, [h, w, imgData, file]);

    React.useEffect(() => {
        switch (ratioKey) {
            case '2/3':
                setContainerCustomStyle({
                    maxWidth: 90,
                    maxHeight: 100,
                    height: '100%',
                    width: '100%',
                });
                break;
            case '9/16':
                setContainerCustomStyle({
                    maxWidth: 70,
                    maxHeight: 100,
                    height: '100px',
                    width: '100%',
                });
                break;
            case '16/9':
                setContainerCustomStyle({
                    maxWidth: 180,
                    maxHeight: 100,
                    height: '100%',
                    width: '100%',
                });
                break;

            case '3/2':
                setContainerCustomStyle({
                    maxWidth: 150,
                    maxHeight: 100,
                    height: '100%',
                    width: '100%',
                });
                break;

            case '5/4':
                setContainerCustomStyle({
                    maxWidth: 120,
                    maxHeight: 100,
                    height: '100%',
                    width: '100%',
                });
                break;

            default:
                setContainerCustomStyle({
                    maxWidth: 100,
                    maxHeight: 100,
                    height: '100%',
                    width: '100%',
                });
                break;
        }
    }, [ratioKey]);

    const aspectRatio = React.useMemo(() => (w && h ? w / h : 1), [ratioKey]);

    return !hasFile ? (
        <ContainerImg className={darkmode ? 'btn-dark' : ''}>
            <div className="title-up">
                <p onClick={toggleDropdown}>
                    <span
                        className={`${isDropdownOpen && 'iconDropdownClose'}`}
                    >
                        <FiChevronDown />
                    </span>
                    {routeTranslate(intl, 'arteai.upload')}
                </p>
                <label htmlFor="file" className="upload-span">
                    <BiUpload
                        size={18}
                        onClick={() => setIsDropdownOpen(true)}
                    />
                </label>
            </div>

            <DropdownContent
                className="drop-content"
                isOpen={isDropdownOpen}
                ref={dropdownRef}
            >
                <div className="btn-file">
                    <label htmlFor="file">
                        <BiUpload size={24} color={darkmode ? '' : '#7e7e7e'} />
                        <p>{routeTranslate(intl, 'arteai.upload.text')}</p>
                    </label>
                    <input
                        id="file"
                        type="file"
                        accept="image/*"
                        onChange={handleChange}
                    />
                </div>
            </DropdownContent>
        </ContainerImg>
    ) : (
        <UploadedImgContainer
            className={darkmode ? 'UploadedImgContainerDark' : ''}
        >
            <div className="img-actions">
                <p onClick={toggleDropdown}>
                    <span
                        className={`${isDropdownOpen && 'iconDropdownClose'}`}
                    >
                        <FiChevronDown />
                    </span>
                    {routeTranslate(intl, 'arteai.upload.tile')}
                </p>
                <BiTrash size={18} onClick={handleCleanImage} />
            </div>

            <DropdownContent
                className="drop-content"
                isOpen={isDropdownOpen}
                ref={dropdownRef}
            >
                <div className="container-img">
                    <label
                        htmlFor="file"
                        style={{
                            ...containerCustomStyle,
                            overflow: 'hidden',
                            backgroundImage: imgData,
                            minHeight: 100,
                            aspectRatio,
                        }}
                    >
                        <img
                            src={imgData}
                            alt="Uploaded image"
                            style={{
                                filter: `blur(${
                                    sliderValue > 91
                                        ? 0
                                        : sliderValue < 20
                                        ? blurValue * 10
                                        : blurValue
                                }px)`,
                                ...imgCustomStyle,
                                aspectRatio,
                            }}
                        />
                        <FiUploadCloud size={25} color="#fff" />
                    </label>
                    <input
                        id="file"
                        type="file"
                        accept="image/*"
                        onChange={handleChange}
                        style={{ display: 'none' }}
                    />
                </div>
                <div className="slider-container">
                    <div className="text">
                        <p>
                            {routeTranslate(intl, 'arteai.upload.similarity')}
                        </p>
                        <p>{sliderValue}%</p>
                    </div>
                    <Slider
                        defaultValue={100}
                        onChange={value => handleChangeValue(value as number)}
                    />
                </div>
            </DropdownContent>
        </UploadedImgContainer>
    );
};

export default ImgToImg;
