import styled, { createGlobalStyle } from 'styled-components';

export const AgentToolbar = styled.footer`
    background-color: #ffffff;
    border-top: 1px solid #edf2f7;
    height: 53px;

    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;

    @media (min-width: 64em) {
        display: none;
    }

    &.containerToolbarDark {
        background: #1d1d1f;
        border-color: rgb(58, 58, 58);
    }
`;

export const BodyPadding = createGlobalStyle`
  .App {
    padding-bottom: 53px;
  }

  @media (min-width: 64em) {
      .App {
          padding-bottom: 0px;
      }
  }
`;

export const ToolbarContainer = styled.div`
    ul {
        list-style: none;
        display: flex;
        justify-content: space-between;

        li {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 93px;
            height: 53px;

            &.active {
                background-color: #f7fafc;
            }
        }
    }
    &.darkInside {
        ul {
            li {
                svg path {
                    stroke: #fff;
                }
                &.active {
                    background-color: #3a3a3a !important;
                }
            }
        }
    }

    .disabled {
        cursor: default;
        opacity: 0.5;
    }
`;

export const MenuMobile = styled.div`
    @media (min-width: 64em) {
        display: none;
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        height: calc(100vh - 53px);
        background: #fff;
        width: 100%;
        overflow: auto;
        padding: 21px 24px 0 24px;

        li {
            width: 100% !important;
        }

        #logo {
            text-align: left;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #e2e8f0;
        }

        #select-project {
            width: 100%;
        }
    }
`;
