import React from 'react';
import { Select } from 'app/presentation/components/agent';
import { SelectThemeVariants } from 'styles/themes/themes';
import useLanguage from 'app/presentation/hooks/useLanguage';
import useText from 'app/presentation/hooks/useText';

interface SelectProps {
    value: number;
    onChange: (value: number) => void;
    defaultLanguage?: number;
    variant?: SelectThemeVariants;
    width?: string;
}

export const LanguageSelect: React.FC<SelectProps> = ({
    value,
    onChange,
    variant = 'grey',
    defaultLanguage,
    width,
}) => {
    const { languages, getLanguages, getLanguageName } = useLanguage();
    const { textGetter } = useText();
    const t = textGetter('agent.components.language-select');

    React.useEffect(() => {
        if (!languages) {
            getLanguages();
            return;
        }

        if (languages.length > 0 && !languages.find(lang => lang.id == value)) {
            onChange(languages[0].id);
        }
    }, [languages]);

    const onSelectChange = (value: string) => {
        if (languages == undefined) {
            return;
        }
        const language = languages.find(lang => {
            return lang.id.toString() == value;
        });

        if (language == undefined) {
            return;
        }
        onChange(language.id);
    };

    return (
        <Select
            variant={variant}
            width={width}
            value={value.toString()}
            onSelectChange={onSelectChange}
            options={
                languages
                    ? languages.map(lang => {
                          return {
                              value: lang.id.toString(),
                              node: (
                                  <span>
                                      {getLanguageName(lang)}
                                      {lang.id == defaultLanguage
                                          ? t('default')
                                          : ''}
                                  </span>
                              ),
                          };
                      })
                    : []
            }
        ></Select>
    );
};
