import React from 'react';
import { AudioText } from './styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { CheckEnable, Search } from 'app/presentation/components/icons';
import { Input } from 'app/presentation/components';
import { routeTranslate } from 'utils/intl';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, useForm } from 'react-hook-form';
import { FiPlus } from 'react-icons/fi';
import { AudioService } from 'services/audio';
import { TbMessage } from 'react-icons/tb';
import { BsArrowRight } from 'react-icons/bs';
import NewTextTransformView from './new';
import TableAudios from 'app/presentation/components/dashboard/TableAudios';
import { Transcript } from 'interfaces/audio-ai.interface';
import EmptyImage from '../../../../assets/images/svg/documents-empty.svg';
import EmptyImageDark from '../../../../assets/images/svg/documents-empty-dark.svg';

const audioService = new AudioService();

export type IAudio = Transcript;

export type IAudioToText = {
    hasId: boolean;
    view: 'table' | 'new';
    setView: (value: 'table' | 'new') => void;
};

const AudioToText: React.FC<IAudioToText> = ({ hasId, view, setView }) => {
    const { darkmode } = useDarkMode();
    const intl = useIntl();
    const { register } = useForm<FieldValues>();
    const [audioList, setAudioList] = React.useState<Array<IAudio>>();

    const hasAudios = audioList && audioList.length > 0;

    const callApi = async () => {
        const result = await audioService.getAll();
        if (result instanceof Error) {
            return;
        }

        setAudioList(result.data);
    };

    React.useEffect(() => {
        if (hasId) {
            setView('new');
        } else {
            setView('table');
        }
    }, [hasId]);

    React.useEffect(() => {
        callApi();
    }, []);

    const handleCreateNew = () => {
        setView('new');
    };

    return (
        <AudioText className={darkmode ? 'darkAudio' : ''}>
            {view === 'new' ? (
                <NewTextTransformView hasId={hasId} />
            ) : (
                <>
                    <div className="header">
                        <form className={darkmode ? 'dark' : ''}>
                            <div
                                className="input"
                                style={{
                                    opacity: hasAudios ? undefined : 0,
                                    pointerEvents: hasAudios
                                        ? undefined
                                        : 'none',
                                }}
                            >
                                <div className="icon">
                                    <Search darkmode={darkmode} />
                                </div>
                                <Input
                                    id="search"
                                    placeholder={routeTranslate(
                                        intl,
                                        'audioai.search',
                                    )}
                                    register={register}
                                    style={{
                                        background: darkmode ? '#000' : '',
                                        color: darkmode ? '#fff' : '',
                                    }}
                                />
                            </div>
                        </form>

                        <button onClick={handleCreateNew}>
                            <FiPlus size={18} />
                            <FormattedMessage id="audioai.button.new" />
                        </button>
                    </div>
                    <div className="contentContainer">
                        {hasAudios ? (
                            <TableAudios data={audioList} getAll={callApi} />
                        ) : (
                            <>
                                <div className="emptyAudio">
                                    <img
                                        src={
                                            darkmode
                                                ? EmptyImageDark
                                                : EmptyImage
                                        }
                                        width={218}
                                        height={218}
                                    />
                                    <h1>
                                        {routeTranslate(
                                            intl,
                                            'audioai.tabel.empty',
                                        )}
                                    </h1>
                                    <p>
                                        {routeTranslate(
                                            intl,
                                            'audioai.tabel.empty.desc',
                                        )}
                                    </p>

                                    <button onClick={handleCreateNew}>
                                        <FiPlus size={18} />
                                        <FormattedMessage id="audioai.button.new" />
                                    </button>
                                </div>
                                <div
                                    className="createAudioContent"
                                    style={{ display: 'none' }}
                                >
                                    <div className="icon">
                                        <TbMessage size={30} />
                                    </div>
                                    <p className="title">
                                        <FormattedMessage id="audioai.new.transform.title" />
                                    </p>
                                    <span className="desc">
                                        <FormattedMessage id="audioai.new.transform.desc" />
                                    </span>

                                    <ul>
                                        <li>
                                            <CheckEnable />
                                            <FormattedMessage id="audioai.new.transform.1" />
                                        </li>
                                        <li>
                                            <CheckEnable />
                                            <FormattedMessage id="audioai.new.transform.2" />
                                        </li>
                                    </ul>

                                    <button onClick={handleCreateNew}>
                                        <FormattedMessage id="audioai.new.transform.btn" />
                                        <BsArrowRight />
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </AudioText>
    );
};

export default AudioToText;
