export const colors = {
    primary: '#D0FF61',
    secondary: '#1A202C',
    sucess: '#22C55E',
    warning: '#FACC15',
    error: '#FF4747',
    white: '#FFFFFF',
    orange: '#FF784B',
    amber: '#FFC837',
    yellow: '#FBEEB6',
    purple: '#9061FF',
    blue: '#194bfb',
    gs50: '#FAFAFA',
    gs100: '#F7FAFC',
    gs200: '#F1F1F1',
    gs300: '#E2E8F0',
    gs400: '#CBD5E0',
    gs500: '#CCCCCC',
    gs600: '#A09FA5',
    gs650: '#7E7E7E',
    gs700: '#3A3A3A',
    gs800: '#1D1D1F',
    gs900: '#000000',
    transparent: 'transparent',
    inherit: 'inherit',
    alerts: {
        error: {
            dark: '#DD3333',
            dark10: '#DD33331A',
            base: '#FF4747',
            light: '#FF7171',
        },
    },
};
