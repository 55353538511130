import styled, { css } from 'styled-components';

interface SwitchCheckboxContainerProps {
    disabled?: boolean;
}

interface SwitchCheckboxProps {
    width?: string;
    height?: string;
}

export const SwitchCheckboxContainer = styled.div<SwitchCheckboxContainerProps>`
    width: fit-content;
    height: 24px;
    display: flex;
    align-items: center;
    gap: 6px;
    ${({ disabled }) =>
        disabled && 'opacity: 0.2; div { cursor: not-allowed; }'}
`;

export const SwitchCheckbox = styled.div<SwitchCheckboxProps>`
    ${({
        width,
        height,
        theme: {
            components: {
                switchCheckbox: { circle, background },
            },
        },
    }) => css`
        min-width: ${width || '43.22px'};
        width: ${width || '43.22px'};
        height: ${height || '24px'};
        border-radius: 12px;
        background-color: ${background.color};
        transition: all linear 0.05s;
        cursor: pointer;

        .white-circle {
            height: ${height || '24px'};
            width: ${height || '24px'};
            background-color: ${circle.color};
            border-radius: 12px;
            border: 2px solid ${background.color};
            box-sizing: border-box;
            transition: transform linear 0.05s;
        }

        input {
            display: none;
        }

        &.active {
            background-color: ${background.active};
            .white-circle {
                transform: translateX(18.22px);
                border-color: ${background.active};
            }
        }
    `}
`;
