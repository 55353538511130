import styled from 'styled-components';

export const TeamContent = styled.div`
    width: 100%;

    .input {
        position: relative;
        display: flex;
        align-items: center;

        .icon {
            position: absolute;
            left: 18px;
        }

        input {
            padding-left: 58px;
        }
    }

    .header-team {
        border-bottom: 1px solid #edf2f7;
        padding-bottom: 26px;

        button {
            margin-top: 25px;
            padding: 0;
            height: 48px;
            font-size: 0.875em;
            border-radius: 6px;
            flex-direction: row-reverse;

            #icon {
                margin-left: 0 !important;
                margin-right: 4px;
            }
        }
    }

    @media (min-width: 64em) {
        .header-team {
            border-bottom: 0;
            padding-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                width: 275px;
            }

            button {
                margin-top: 0px;
                width: 180px;
                height: 48px;
                border-radius: 12px;
            }
        }
    }
`;

export const DocumentsContainer = styled.div`
    width: 100%;
    margin: 28px auto 25px auto;

    .box:first-of-type {
        margin: 0;
        padding-top: 0;
    }

    .box {
        margin-top: 28px;
        padding: 24px 0 36px 0;
        border-bottom: 2px solid #edf2f7;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .flex {
            flex: 1;

            h1 {
                font-size: 0.875em;
                font-weight: 700;
            }
        }
    }

    .box:last-of-type {
        border-top: 0;
        border-bottom: 0;
        margin: 0;
    }

    .dots {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon {
        width: 40px;
        height: 40px;
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        border-radius: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name-table {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 26px;
        }

        span {
            font-weight: 600;
            font-size: 1em;
        }
    }

    .infos {
        .info div {
            width: 130px;
            height: 56px;

            input {
                border-radius: 12px;
            }
        }
    }

    .infos .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;

        strong {
            font-size: 0.875em;
            color: #718096;
            font-weight: 600;
        }

        span {
            color: #232b38;
            font-size: 0.875em;
            font-weight: 600;
        }
    }

    #table {
        display: none;
    }

    @media (min-width: 64em) {
        border-radius: 16px;

        .box {
            display: none;
        }

        #table {
            margin-top: 25px;
            margin-bottom: 18px;
            display: block;
            width: 100%;
            height: 300px;
        }

        header {
            h1 {
                font-size: 1.125em;
            }

            button {
                width: 107.29px;
                height: 34px;
            }
        }
    }
`;
