import {
     AgentUsageResponse,
} from './interfaces';
import { HTTPClient } from '../client';
import { INSIGHTS_API_BASE_URL } from 'config/contants';

export class InsightsService {
    private readonly client = new HTTPClient(INSIGHTS_API_BASE_URL).addAuthHeader();
    

    async getAgentUsageData(
        brandID: number, startAt: Date, endAt: Date, agentID?: number
        ): Promise<AgentUsageResponse> {
        return this.client.getData<AgentUsageResponse>({
            path: 'agents',
            queryParams: {
                start_at: startAt.toISOString(),
                end_at: endAt.toISOString(),
                ... (agentID ? {agent_id: agentID} : {}) 
            },
            headers: {
                'X-Brand-Id': brandID
            },
        });
    }
}
