import styled from 'styled-components';

export const ContainerMenu = styled.div`
    display: none;
    position: relative;
    overflow: hidden;

    @media (min-width: 64em) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 75px;
        height: 100vh;
        padding: 0px 0px 40px 0px;
        padding-bottom: 0px !important;
        overflow: visible;
        border-right: 1px solid #0000001a;
    }

    #logo {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            margin-top: 30px;
        }
        padding-bottom: 28px;
        width: 40px;
        margin: auto;
        margin-bottom: 30px;
        height: 80px;
        border-bottom: 1px solid #e2e8f0;
    }

    .special-li {
        svg {
            path {
                &:first-of-type {
                    stroke: none !important;
                }
            }
        }
    }

    #select-brand {
        cursor: pointer;
        border: 1px solid #e2e8f0;
        border-radius: 12px;
        background: transparent;

        position: relative;
        margin-bottom: 30px;

        @media (min-width: 64em) {
            width: fit-content;
            margin-left: 12px;
            width: 48px;
            height: 48px;
        }

        .select-label {
            padding: 8px 12px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (min-width: 64em) {
                justify-content: center;
            }

            .img-div {
                min-height: 40px;
                height: 40px;
                min-width: 40px;
                width: 40px;
                border-radius: 9999px;
                margin-right: 12px;

                @media (min-width: 64em) {
                    min-height: 32px;
                    height: 32px;
                    min-width: 32px;
                    width: 32px;
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 9999px;

                    @media (min-width: 64em) {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            p {
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
                white-space: nowrap;
                width: 90px;

                @media (max-width: 64em) {
                    width: 100%;
                }
            }

            .icon-content {
                position: relative;
                margin-top: 4px;
                width: 100%;
                height: 100%;
                svg {
                    position: absolute;
                    top: -10px;
                    right: 0;
                }
                @media (max-width: 64em) {
                    margin-top: 0px;
                    svg {
                        right: 0;
                        top: -10px;
                    }
                }
            }
        }

        .modal-banner {
            display: none;

            &.active {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
            }
        }

        .modal-project {
            display: none;

            &.active {
                display: block;
            }

            position: absolute;
            z-index: 99;
            box-shadow: 0px 16px 32px rgba(113, 128, 150, 0.08);
            top: calc(100% + 20px);
            height: auto;
            left: 0;
            background: #ffffff;
            border: 1px solid #cbd5e0;
            border-radius: 8px;

            @media (min-width: 64em) {
                min-width: 250px;
                width: fit-content;
            }
        }

        span {
            color: #a0aec0;
            font-weight: 500;
            font-size: 0.625em;
        }

        p {
            color: #2a313c;
            font-size: 0.9375em;
            font-weight: 600;
        }

        .menu-mui-header {
            input {
                padding: 12px 16px;
                height: 42px;
            }
        }

        .list-project {
            .desc {
                span {
                    font-size: 10px;
                    font-weight: 600;
                    color: #7e7e7e;
                }
                padding-left: 10px;
                font-weight: 700;
                font-size: 0.875rem;
                margin-bottom: 12px;
            }
            .scroll-project {
                li {
                    span {
                        font-weight: 600 !important;
                        color: #000 !important;
                        font-size: 0.875rem !important;
                    }
                }
                .img-brand {
                    height: 32px;
                    width: 32px;
                    border-radius: 9999px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 9999px;
                        object-fit: cover;
                    }

                    p {
                        font-weight: 800;
                        font-size: 0.625rem;
                        color: #fff;
                        text-transform: capitalize;
                    }
                }
                .helper-container {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    span {
                        margin-left: 10px;
                        font-weight: 600 !important;
                        color: #000 !important;
                        font-size: 0.875rem !important;
                    }
                }
                .menu-open {
                    margin-right: 10px;
                    border: 1px solid #e2e8f0;
                    border-radius: 8px;
                    width: 32px !important;
                    height: 32px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                }
            }
        }
    }

    #trial-message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 12px;
        border: 1px solid #edf2f7;
        background: #f7fafc;
        border-radius: 8px;
        margin-bottom: 12px;

        div {
            display: flex;
            p {
                color: #000;
                font-weight: 600;
                font-size: 0.875rem;
                margin-left: 8px;
            }
        }

        span {
            margin-top: 8px;
            margin-bottom: 12px;
            color: #7e7e7e;
            font-weight: 500;
            font-size: 0.75rem;
        }
        a {
            width: 100%;
        }
        button {
            border-radius: 6px;
            border: 1px solid #e2e8f0;
            background: #fff;
            cursor: pointer;
            color: #000;
            font-weight: 600;
            font-size: 0.75rem;
            padding: 6px 0;
            width: 95%;
        }
    }

    #select-project {
        cursor: pointer;
        border: 1px solid #edf2f7;
        border-radius: 6px;
        background: #f7fafc;
        position: relative;
        margin-bottom: 12px;

        .select-label {
            padding: 0 16px;
            width: 100%;
            min-height: 63px;
            max-height: 63px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
                white-space: nowrap;
                width: 100px;
            }
        }

        .modal-banner {
            display: none;

            &.active {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
            }
        }

        .modal-project {
            display: none;

            &.active {
                display: block;
            }

            position: absolute;
            z-index: 99;
            box-shadow: 0px 16px 32px rgba(113, 128, 150, 0.08);
            bottom: calc(100% + 20px);
            height: auto;
            left: 0;
            background: #ffffff;
            border: 1px solid #cbd5e0;
            border-radius: 8px;
        }

        span {
            color: #a0aec0;
            font-weight: 500;
            font-size: 0.625em;
        }

        p {
            color: #2a313c;
            font-size: 0.9375em;
            font-weight: 600;
        }
    }

    .workspace-icon {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: #d0ff61;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 10px;
            color: #000000;
            font-weight: 800;
        }
    }

    .notify-number {
        background: #1a202c;
        border-radius: 1000px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: #ffffff;
            font-size: 10px;
            font-weight: 600;
        }
    }

    ul {
        list-style: none;

        li {
            width: 100%;
            height: 47px;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 12px;
            position: relative;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    pointer-events: none;
                }
            }

            > span {
                font-size: 0.875em;
                color: #1a202c;
                flex: 1;
                margin-left: 12px;
                font-weight: 500;
            }

            @media (min-width: 64em) {
                .title,
                .specialElement {
                    display: none;
                }
            }

            :hover {
                background: #f7fafc;

                &.disabled {
                    background-color: transparent;
                }
            }
        }

        a.active-route {
            & > li {
                background: #f1f1f1;
                width: 100%;
                border-radius: 8px;

                @media (min-width: 64em) {
                    border-left: 2px solid #000;
                    border-radius: 0;
                    border-radius: none;
                }

                span {
                    font-weight: 700;
                }

                svg {
                    margin-left: -2px;
                }
            }
        }
    }

    .betaIcon {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 150%;
        padding: 0.5px 10px;
        border-radius: 10px;
        background: #000000;
        color: #fff;
        max-width: 52px;
        height: 22px;
    }

    .workspaceLi {
        position: sticky;
        bottom: 0px;
        border-top: 1px solid #edf2f7;
        width: 100%;
        margin: 0 -24px;
        margin-bottom: 0 !important;
        padding: 0 20px 0 15px;
        li,
        a {
            width: 100% !important;
            margin-bottom: 0 !important;
        }

        @media (min-width: 64em) {
            padding: 0;
            margin: 0;
        }
    }

    &.dark {
        background-color: #1d1d1f;

        .liStrokeNone {
            svg {
                path {
                    &:first-of-type {
                        stroke: none;
                    }
                }
            }
        }

        #trial-message {
            border-color: #3a3a3a;
            background: #3a3a3a;

            div {
                p {
                    color: #fff;
                }
            }

            span {
                color: #cccccc;
            }
            button {
                background: #fff;
                color: #000;
            }
        }

        #select-brand {
            background-color: #3a3a3a;
            border: 1px solid #3a3a3a;

            p {
                color: #fff;
            }

            span {
                color: #cccccc;
            }

            .modal-banner {
                &.active {
                    display: block;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                }
            }

            .modal-project {
                display: none;

                &.active {
                    display: block;
                }

                background-color: #1d1d1f;
                border: 1px solid #3a3a3a;
                border-radius: 8px;

                .scroll-project {
                    max-height: 150px;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 0.5em;
                        height: 8px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #1d1d1f;
                        border-radius: 50px;
                    }

                    border-bottom: 1px solid #1d1d1f;
                }

                .menu-mui-header {
                    input {
                        background: #1d1d1f;
                        color: #fff;
                    }
                }

                .list-project {
                    li {
                        span {
                            color: #fff !important;
                            .labelPersonal {
                                color: #000 !important;
                                font-weight: 700;
                                background: #d0ff61;
                            }
                        }
                    }
                }
            }
        }

        #select-project {
            background-color: #3a3a3a;
            border: 1px solid #3a3a3a;

            p {
                color: #fff;
            }

            span {
                color: #cccccc;
            }

            .modal-banner {
                &.active {
                    display: block;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                }
            }

            .modal-project {
                display: none;

                &.active {
                    display: block;
                }

                background-color: #1d1d1f;
                border: 1px solid #3a3a3a;
                border-radius: 8px;

                .scroll-project {
                    max-height: 150px;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 0.5em;
                        height: 8px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #1d1d1f;
                        border-radius: 50px;
                    }

                    border-bottom: 1px solid #1d1d1f;
                }

                .menu-mui-header {
                    input {
                        background: #1d1d1f;
                        color: #fff;
                    }
                }

                .list-project {
                    li {
                        span {
                            color: #fff !important;
                            .labelPersonal {
                                color: #000 !important;
                                font-weight: 700;
                                background: #d0ff61;
                            }
                        }
                    }
                }
            }
        }

        #logo {
            border: 0;
        }

        li {
            span {
                color: #fff;
            }

            svg path {
                stroke: #fff;
            }

            .notify-number {
                background: #fff;

                span {
                    color: #000 !important;
                }
            }

            &:hover {
                background-color: #3a3a3a;
            }

            .betaIcon {
                background: #d0ff61;
                color: #000;
            }
        }

        a.active-route {
            & > li {
                background: #d0ff61;
                width: 100%;

                @media (min-width: 64em) {
                    border-left: 2px solid #fff;
                }

                span {
                    font-weight: 700;
                }

                svg {
                    margin-left: -2px;
                }

                .betaIcon {
                    background: #000;
                    color: #fff;
                }
                .notify-number {
                    background: #000;

                    span {
                        color: #fff !important;
                    }
                }
            }
            span {
                color: #000;
            }

            svg path {
                stroke: #000;
            }
        }

        a.active {
            & > li {
            }
        }

        .workspaceLi {
            border-color: #3a3a3a;
        }
    }

    .menu-dashboard-dark {
        li {
            .workspace-icon {
                span {
                    color: #000;
                    font-weight: 800;
                }
            }
        }
    }

    .disabled {
        opacity: 0.5;
        cursor: default;
    }
`;

export const MuiMenuHeader = styled.div`
    .list-project {
        li {
            padding: 0 11px 0 0 !important;
        }
    }

    .list-submenu-options {
        li {
            position: relative;
            padding: 0 11px 0 0 !important;
            color: #7e7e7e;
            font-weight: 600;
            svg {
                margin-right: 16px;
            }
            .loading-spinner {
                width: 100%;
                justify-content: center;
                display: flex;
                svg {
                    margin-right: 0;
                }
            }
        }
    }

    .scroll-project {
        max-height: 150px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0.5em;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: #edf2f7;
            border-radius: 50px;
        }

        li {
            border: 0;
            padding: 0 !important;

            span {
                color: #718096 !important;
                font-weight: 500 !important;
                .labelPersonal {
                    color: #fff !important;
                    font-weight: 500 !important;
                    font-size: 12px !important;
                    line-height: 150% !important;
                    background: #1a202c;
                    border-radius: 10px;
                    padding: 2px 7px;
                    margin-left: 8px;
                }
            }
        }

        border-bottom: 1px solid #edf2f7;
    }

    .menu-mui-header-workspace {
        margin-top: 9px;
        padding-bottom: 17px;
        border-bottom: 2px solid #edf2f7;
        margin-bottom: 8px;

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        a {
            font-weight: 500;
            font-size: 0.75em;
            color: #194bfb;
        }
    }

    .menu-mui-header {
        margin-top: 9px;
        padding: 0 9px 13px 9px;

        #search > div {
            margin: 0;
        }

        input {
            min-height: 42px;
            border: 1px solid #a0aec0;
            border-radius: 8px;
        }

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        p {
            font-weight: 500;
            font-size: 0.75em;
            color: #718096;
        }
    }

    ul.workspace {
        li {
            border-bottom: 0;
            padding: 0;
            cursor: pointer;

            span {
                font-weight: 600;
            }
        }
    }

    ul {
        list-style: none;

        li {
            cursor: pointer;
            height: 42px !important;
            display: flex;
            align-items: center;
            padding: 0 16px !important;
            border-bottom: 1px solid #edf2f7;
            margin: 0;

            &:hover {
                background-color: #f7fafc;
            }

            &.icon {
                justify-content: space-between;
            }
        }

        li:last-of-type {
            border-bottom: 0;
        }

        span {
            color: #1a202c !important;
            font-weight: 400;
            font-size: 0.75em !important;

            &.bold {
                font-weight: 600 !important;
            }
        }
    }

    .ws-dark {
        li {
            &:hover {
                background-color: #404f67;
                border-radius: 8px;
            }
        }

        span {
            color: #fff !important;
        }
    }
`;
