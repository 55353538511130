import React from 'react';
import { Button } from 'app/presentation/components';
import { HeaderPlan, ContainerPlans, ContainerModal, ContainerFaq } from './styles';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { routeTranslate as t } from 'utils/intl';
import { CheckEnable } from 'app/presentation/components/icons';
import { PlansService } from '../../../services/plans';
import { useMediaQuery } from '@mui/material';
import { Plans as IPlans, Session } from '../../../interfaces/plans.interface';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import parse from 'html-react-parser';
import Fade from '@mui/material/Fade';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import Loading from 'app/presentation/components/common/Loading';
import { useNavigate } from 'react-router-dom';
import { auth } from 'config/firebaseSetup';

enum Period {
    Year = 1,
    Month = 2,
}

const plansService = new PlansService();

interface IMark {
    plan_id: number;
    markValue: number | number[];
}

type PlansProps = {
    isOpen: boolean;
    handleClose: () => void;
};

const ModalPlans: React.FC<PlansProps> = ({ isOpen, handleClose }) => {
    const [plan, setPlan] = React.useState<Period>(Period.Month);
    const [plans, setPlans] = React.useState<IPlans[]>([]);
    const navigate = useNavigate();
    const [mark] = React.useState<IMark[]>([]);
    const [loading, setLoading] = React.useState('');
    const [loadingPlans, setLoadingPlans] = React.useState(false);
    const intl = useIntl();
    const { darkmode } = useDarkMode();
    const isDesktop = useMediaQuery('(min-width: 48em)');
    const { workspace } = useUserConfig();

    const asyncCall = async () => {
        setLoadingPlans(true);
        await plansService.getAll().then(async data => {
            setPlans(data);
            setLoadingPlans(false);
        }).catch(error => {
            console.log(error);
            setLoadingPlans(false);
        });
    };

    React.useEffect(() => { asyncCall(); }, []);

    const handleClick = async (request: Session) => {
        setLoading(request.price_id);

        const data = await plansService.createSession(request);
        window.location.href = data.url;
    };

    const handlePlan = (period: Period) => {
        setPlan(period);
    };

    const signOut = async () => {
        await auth.signOut();
        localStorage.clear();

        navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.signin')}`);
    };

    if (!workspace) return <></>;

    const plansArr = isDesktop
        ? [...plans].sort((a, b) => {
            if (a.plan.name.toLowerCase() === 'essencial') return -1; // a comes first
            if (b.plan.name.toLowerCase() === 'essencial') return 1; // b comes first
            return a.plan.name.localeCompare(b.plan.name); // if neither is "criador", sort alphabetically or any other criteria you want
        })
        : [...plans].reverse();

    const essentialFeatures = t(intl, 'plansPage.features') === 'BR'
        ? [
            '2.000 créditos de IA*',
            'Crie 1 Agente de IA',
            'Inclui 1 usuário',
            'Gerencie 1 marca',
            'Integre com Website',
        ]
        : [
            'Unlimited* AI-generated words',
            '60+ AI models',
            'Includes 1 user',
            'Manage 1 brand',
            '50 Knowledge assets',
            'Browser extension',
        ];

    const advancedFeatures = t(intl, 'plansPage.features') === 'BR'
        ? [
            '10.000 créditos de IA*',
            'Crie 5 Agentes de IA',
            'Inclui 5 usuários',
            'Gerencie até 3 marcas',
            'Integre com WhatsApp*',
        ]
        : [
            '10,000 AI credits*',
            'Create 5 AI Agents',
            'Includes 5 users',
            'Manage up to 3 brands',
            'Integrate with WhatsApp*',
        ];

    const specialistFeatures = t(intl, 'plansPage.features') === 'BR'
        ? [
            'Créditos de IA sob demanda*',
            'Agentes de IA ilimitados',
            'Equipes maiores de 5 usuários',
            'Onboarding e treinamento',
            'Gerente de sucesso dedicado',
            'Acesso a API*',
        ]
        : [
            'On-demand AI credits*',
            'Unlimited AI Agents',
            'Teams larger than 5 users',
            'Onboarding and training',
            'Dedicated success manager',
            'API access*',
        ];

    const unlimitedJarbasProductivityStr = "Jarbas Produtividade (Ilimitado*)"

    return (
        <Fade in={isOpen} timeout={400}>
            <ContainerModal onClick={handleClose}>
                <div className="modal-content">
                    <HeaderPlan className={darkmode ? 'headerPlansDark' : ''}>
                        <div className="box">
                            <h1>
                                {t(intl, 'plansPage.title.free')}
                            </h1>
                            <p>
                                {t(intl, 'plansPage.description.free')}
                            </p>
                        </div>
                        <div className="box">
                            <div className="toggle">
                                <span onClick={() => handlePlan(Period.Month)} className={plan === Period.Month ? 'active' : ''}>
                                    {t(intl, 'plansPage.month')}
                                </span>
                                <span onClick={() => handlePlan(Period.Year)} className={plan === Period.Year ? 'active' : ''}>
                                    {t(intl, 'plansPage.year')}
                                </span>
                            </div>
                            <p id="economy">
                                {parse(t(intl, 'plansPage.freeMessage'))}
                            </p>
                        </div>
                        <button className="button-back" onClick={signOut}>
                            Logout
                        </button>
                    </HeaderPlan>

                    <ContainerPlans
                        className={darkmode ? 'containerPlansDark' : ''}
                    >
                        {plansArr?.map(planItem => {
                            const isEssential = planItem.plan.name.toLowerCase() === 'essencial';
                            const marks = planItem.wordpacks.sort(function (a, b) {
                                return (a.wordpack.word_credits - b.wordpack.word_credits);
                            }).map((word, index) => ({
                                value: (100 / planItem.wordpacks.length) * (index + 1),
                                word: word.wordpack.word_credits,
                                unit_price_monthly: word.unit_price_monthly,
                                unit_price_yearly: word.unit_price_yearly,
                            }));

                            let period = '';
                            const findWork = mark.find(markItem => markItem.plan_id === planItem.plan.plan_id,);

                            let price = 0;

                            if (plan === Period.Year) {
                                price = marks[0].unit_price_yearly / 100 / 12;
                            } else {
                                price = marks[0].unit_price_monthly / 100;
                            }

                            if (findWork) {
                                const quantityWord = marks.find(markItem => markItem.value === findWork.markValue)?.word ?? 0;

                                if (plan === Period.Year) {
                                    price = marks.find(markItem => markItem.value === findWork.markValue)?.unit_price_yearly ?? 0;
                                    price = price / 100 / 12;
                                } else {
                                    price = marks.find(markItem => markItem.value === findWork.markValue)?.unit_price_monthly ?? 0;

                                    price = price / 100;
                                }

                                const wordpack = planItem.wordpacks.find(wordpack => wordpack.wordpack.word_credits === quantityWord &&
                                    planItem.plan.plan_id === wordpack.wordpack.plan_id,
                                );

                                if (wordpack) {
                                    period = plan === Period.Month ?
                                        wordpack.wordpack.stripe_price_monthly_id :
                                        wordpack.wordpack.stripe_price_yearly_id;
                                } else {
                                    period = plan === Period.Month ?
                                        planItem.wordpacks[0].wordpack.stripe_price_monthly_id : planItem.wordpacks[0].wordpack.stripe_price_yearly_id;
                                }
                            } else {
                                period = plan === Period.Month ?
                                    planItem.wordpacks[0].wordpack.stripe_price_monthly_id :
                                    planItem.wordpacks[0].wordpack.stripe_price_yearly_id;
                            }
                            const homeUrl =
                                `${process.env.REACT_APP_PUBLIC_URL}/${t(intl, 'route.urlLang',
                                )}${t(intl, 'route.backoffice',
                                )}${t(intl, 'route.agent.home')}` ??
                                'http://localhost:3000';

                            const rewardUrl =
                                `${process.env.REACT_APP_PUBLIC_URL
                                }/${t(intl, 'route.urlLang',
                                )}${t(intl, 'route.backoffice',
                                )}${t(intl, 'route.rewardful')}` ??
                                'http://localhost:3000';

                            const requestPlan: Session = {
                                cancel_url: homeUrl,
                                success_url: rewardUrl,
                                mode: 'subscription',
                                price_id: period,
                                workspace_id: workspace.id,

                                seats: planItem.plan.name === 'Avançado' ? 5 : 1,
                            };

                            return (
                                <div key={planItem.plan.plan_id} className={`box ${planItem.plan.name === 'Avançado' ? 'active' : ''}`}>

                                    <h1>{planItem.plan.name}</h1>
                                    <p>{planItem.plan.description}</p>
                                    <div className="price">
                                        <p>
                                            <span className="value">
                                                <span>
                                                    {price.toLocaleString('pt-br', {
                                                        style: 'currency',
                                                        currency: 'BRL',
                                                        maximumFractionDigits: 0,
                                                    },
                                                    )}
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                    <p className="words">
                                        {t(intl, 'plansPage.forMonth')}{' '}
                                        {plan === Period.Year ? t(intl, 'plansPage.year') : t(intl, 'plansPage.month')}
                                    </p>

                                    <Button
                                        onClick={() => handleClick(requestPlan)}
                                        type="submit"
                                        icon={<HiArrowNarrowRight />}
                                        loading={loading === period}
                                        style={{ marginTop: 30, marginBottom: 30 }}
                                    >
                                        {t(intl, 'plansPage.startNow.free')}
                                    </Button>

                                    <p className="description">
                                        {isEssential ? t(intl, 'plansPage.description.basic') : t(intl, 'plansPage.description.genius')}
                                    </p>
                                    {isDesktop ? (
                                        isEssential ? (
                                            <ul>
                                                {essentialFeatures.map(str => (
                                                    <li key={str}>
                                                        <CheckEnable />{' '}
                                                        {str}
                                                    </li>
                                                ))}
                                                <li style={{ height: "1px", backgroundColor: "rgba(35, 35, 35, 0.15)" }} />
                                                <li>
                                                    <CheckEnable version={'terciary'} />
                                                    {unlimitedJarbasProductivityStr}
                                                </li>
                                            </ul>
                                        ) : (
                                            <ul>
                                                <ul>
                                                    {advancedFeatures.map(str => (
                                                        <li key={str}>
                                                            <CheckEnable version="secondary" />
                                                            {str}
                                                        </li>
                                                    ))}

                                                    <li style={{ height: "1px", backgroundColor: "rgba(35, 35, 35, 0.15)" }} />

                                                    <li>
                                                        <CheckEnable version={'terciary'} />
                                                        {unlimitedJarbasProductivityStr}
                                                    </li>
                                                </ul>
                                            </ul>
                                        )
                                    ) : !isEssential ? (
                                        <ul>
                                            {essentialFeatures.map(str => (
                                                <li key={str}>
                                                    <CheckEnable />{' '}
                                                    {str}
                                                </li>
                                            ))}
                                            <li style={{ height: "1px", backgroundColor: "rgba(35, 35, 35, 0.15)" }} />
                                            <li>
                                                <CheckEnable version={'terciary'} />
                                                {unlimitedJarbasProductivityStr}
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul>
                                            {advancedFeatures.map(str => (
                                                <li>
                                                    <CheckEnable version="secondary" />
                                                    {str}
                                                </li>
                                            ))}

                                            <li style={{ height: "1px", backgroundColor: "rgba(35, 35, 35, 0.15)" }} />

                                            <li>
                                                <CheckEnable version={'terciary'} />
                                                {unlimitedJarbasProductivityStr}
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            );
                        })}

                        <div className="box">
                            <h1>
                                {t(intl, 'plansPage.specialist')}
                            </h1>
                            <p>
                                {t(intl, 'plansPage.specialist.description')}
                            </p>
                            <div className="price">
                                <span className="value">
                                    {t(intl, 'plansPage.specialist.check')}
                                </span>
                            </div>

                            <p className="words">
                                {t(intl, 'plansPage.specialist.info')}
                            </p>
                            <Button
                                type="submit"
                                icon={<HiArrowNarrowRight />}
                                style={{ marginTop: 30, marginBottom: 30 }}
                            >
                                {t(intl, 'plansPage.contactUs')}
                            </Button>
                            <p className="description">
                                {t(intl, 'plansPage.description.enterprise')}
                            </p>
                            <ul>
                                {specialistFeatures.map(str => (
                                    <li key={str}>
                                        <CheckEnable />
                                        {str}
                                    </li>))
                                }
                            </ul>
                        </div>
                    </ContainerPlans>
                    <ContainerFaq
                        className={darkmode ? 'containerPlansFaqDark' : ''}
                    >
                        <header>
                            <h1> {t(intl, 'plansPage.faq')}</h1>
                            <p>
                                {t(intl, 'plansPage.faq.1')}{' '}
                                <a href="#faq">
                                    {t(intl, 'plansPage.faq.2')}
                                </a>
                            </p>
                        </header>
                        <div>
                            <div className="box">
                                <h1>
                                    {t(intl, 'plansPage.faq.3')}
                                </h1>
                                <p>{t(intl, 'plansPage.faq.4')}</p>
                            </div>
                            <div className="box">
                                <h1>
                                    {t(intl, 'plansPage.faq.5')}
                                </h1>
                                <p>{t(intl, 'plansPage.faq.6')}</p>
                            </div>
                            <div className="box refund">
                                <h1>
                                    {t(intl, 'plansPage.faq.7')}
                                </h1>
                                <p>
                                    {parse(t(intl, 'plansPage.faq.8'))}
                                </p>
                            </div>

                            <div className="box">
                                <h1>
                                    {t(intl, 'plansPage.faq.9')}
                                </h1>
                                <p>
                                    {parse(t(intl, 'plansPage.faq.10'))}
                                </p>
                            </div>

                            <div className="box">
                                <h1>
                                    {t(intl, 'plansPage.faq.11')}
                                </h1>
                                <p>
                                    {parse(t(intl, 'plansPage.faq.12'))}
                                </p>
                            </div>

                            <div className="box">
                                <h1>
                                    {t(intl, 'plansPage.faq.13')}
                                </h1>
                                <p>
                                    {parse(t(intl, 'plansPage.faq.14'))}
                                </p>
                            </div>
                        </div>
                    </ContainerFaq>
                </div>
                <Loading open={loadingPlans} />
            </ContainerModal>
        </Fade>
    );
};

export default ModalPlans;
