import React from 'react';

function useDocumentTitle(title: string, prevailOnUnmount = false) {
    const defaultTitle = React.useRef(document.title);

    React.useEffect(() => {
        document.title = title + ' - Jarbas';
    }, [title]);

    React.useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        [],
    );
}

export default useDocumentTitle;
