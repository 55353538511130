import React from 'react';
import * as S from './styles';
import { Button } from '../Button';
import { IconCalendar, IconChevronDown, IconPencil } from '@tabler/icons-react';
import { Menu } from '@mui/material';
import { ThemeContext } from 'styled-components';
import { DateInterval, ModalDateInterval } from '../ModalDateInterval';
import { formatFullDate } from 'utils/time';
import { useIntl } from 'react-intl';
import useText from 'app/presentation/hooks/useText';

interface Props {
    dateInterval?: DateInterval,
    onDateIntervalChange: (date: DateInterval) => void,
}

export const DateIntervalSelectDropdown: React.FC<Props> = ({
    dateInterval,
    onDateIntervalChange,
}) => {
    const { textGetter } = useText()
    const t = textGetter('agent.components.date-interval-select-dropdown')

    const { locale } = useIntl();

    const [dateIntervalModal, setDateIntervalModal] 
        = React.useState<boolean>(false)

    React.useEffect(()=>{
        const now = new Date();
        const monthStart = new Date();
        monthStart.setDate(1)
        onRawDateIntervalChange(
            {
                start: monthStart,
                end: now,
            }
        )
    },[])

    const theme = React.useContext(ThemeContext);
    const [anchorOptsMenu, setAnchorOptsMenu] =
        React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorOptsMenu);

    const openOptsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorOptsMenu(event.currentTarget);
    };

    const closeOptsMenu = () => {
        setAnchorOptsMenu(null);
    };

    const paperPropsStyle: React.CSSProperties = {
        borderRadius: '12px',
        boxShadow: 'box-shadow: 0px 16px 32px 0px #71809614',
        background: theme.components.selectDropdown.menu.background.color,
        padding: '0px',
        width: '300px',
    };

    const menuListStyle: React.CSSProperties = {
        padding: '12px',
        boxShadow: '0',
        background: theme.components.selectDropdown.menu.background.color,
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    };

    const onRawDateIntervalChange = (dateInterval: DateInterval) => {
        const startAt = new Date(dateInterval.start)
        startAt.setHours(0,0,0)
        const endAt = new Date(dateInterval.end)
        endAt.setHours(23,59,59)
        onDateIntervalChange({
            end: endAt,
            start: startAt,
        })
    }

    return (
        <S.SelectDropdown>
            <Button
                variant="secondary"
                className="open-menu-button"
                onClick={openOptsMenu}
                type="button"
            >
                <IconCalendar className='calendar'/>
                {dateInterval &&
                    <span>
                        {formatFullDate(dateInterval.start,locale)}
                        {
                            formatFullDate(dateInterval.start,locale) != formatFullDate(dateInterval.end,locale)
                            && `- ${formatFullDate(dateInterval.end,locale)}`
                        }
                    </span>
                }
                <IconChevronDown/>
            </Button>
            <Menu
                anchorEl={anchorOptsMenu}
                open={open}
                onClose={closeOptsMenu}
                PaperProps={{
                    style: paperPropsStyle,
                }}
                MenuListProps={{
                    style: menuListStyle,
                }}
            >
                <S.MenuBody>
                    <S.MenuItem
                        type="button" 
                        onClick={()=>{
                            const now = new Date();
                            onRawDateIntervalChange(
                                {
                                    start: now,
                                    end: now,
                                }
                            )
                        }}
                    >
                        <IconCalendar />
                        <span>{t('today')}</span> 
                    </S.MenuItem>
                    <S.MenuItem
                        type="button" 
                        onClick={()=>{
                            const yesterday = new Date();
                            yesterday.setDate(yesterday.getDate() - 1)
                            onRawDateIntervalChange(
                                {
                                    start: yesterday,
                                    end: yesterday,
                                }
                            )
                        }}
                    >   
                        <IconCalendar />
                        <span>{t('yesterday')}</span>
                    </S.MenuItem>
                    <S.MenuItem
                        type="button" 
                        onClick={()=>{
                            const now = new Date();
                            const oneWeekAgo = new Date();
                            oneWeekAgo.setDate(oneWeekAgo.getDate() - 6)
                            onRawDateIntervalChange(
                                {
                                    start: oneWeekAgo,
                                    end: now,
                                }
                            )
                        }}
                    >   
                        <IconCalendar />
                        <span>{t('one-week-ago')}</span>
                    </S.MenuItem>
                    <S.MenuItem
                        type="button" 
                        onClick={()=>{
                            const now = new Date();
                            const monthStart = new Date();
                            monthStart.setDate(1)
                            onRawDateIntervalChange(
                                {
                                    start: monthStart,
                                    end: now,
                                }
                            )
                        }}
                    >  
                        <IconCalendar />
                        <span>{t('month-to-date')}</span>
                    </S.MenuItem>
                    <S.MenuItem
                        type="button" 
                        onClick={()=>{
                            const now = new Date();
                            const thirtyDaysAgo = new Date();
                            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 29)
                            onRawDateIntervalChange(
                                {
                                    start: thirtyDaysAgo,
                                    end: now,
                                }
                            )
                        }}
                    >
                        <IconCalendar />
                        <span>{t('thirty-days')}</span>
                    </S.MenuItem>
                    <S.MenuItem
                        type="button" 
                        onClick={()=>{
                            const now = new Date();
                            const oneHundredAndTwentyDaysAgo = new Date();
                            oneHundredAndTwentyDaysAgo.setDate(oneHundredAndTwentyDaysAgo.getDate() - 119)
                            onRawDateIntervalChange(
                                {
                                    start: oneHundredAndTwentyDaysAgo,
                                    end: now,
                                }
                            )
                        }}
                    >
                        <IconCalendar />
                        <span>{t('one-hundred-and-twenty-days')}</span>
                    </S.MenuItem>
                </S.MenuBody>
                <S.MenuFooter>
                    <S.MenuItem
                        type="button" 
                        onClick={()=>setDateIntervalModal(true)}
                    >
                        <IconPencil />
                        <span>{t('custom')}</span>
                    </S.MenuItem>
                </S.MenuFooter>
            </Menu>
            <ModalDateInterval
                active={dateIntervalModal}
                handleClose={() => setDateIntervalModal(false)}
                onDateIntervalChange={onRawDateIntervalChange}
            />
        </S.SelectDropdown>
    );
};
