import React from 'react';
import * as S from './styles';
import { IconInfoCircle } from '@tabler/icons-react';
import { Tooltip } from '@mui/material';
import { ThemeContext } from 'styled-components';

interface FormFieldInfoProps {
    title: string;
    text?: string;
    tooltip?: string;
}

export const FormFieldInfo: React.FC<FormFieldInfoProps> = ({
    title,
    tooltip,
    text,
}) => {
    const theme = React.useContext(ThemeContext);
    return (
        <S.FormFieldInfo>
            <div className="info-title-container">
                <p>{title}</p>
                {tooltip && (
                    <Tooltip
                        title={tooltip}
                        placement="right"
                        sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    backgroundColor:
                                        theme.components.tooltip.background
                                            .color,
                                    color: theme.components.tooltip.text.color,
                                    fontSize: 14,
                                },
                            },
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -10],
                                    },
                                },
                            ],
                        }}
                    >
                        <IconInfoCircle></IconInfoCircle>
                    </Tooltip>
                )}
            </div>
            {text && <p className="info-text">{text}</p>}
        </S.FormFieldInfo>
    );
};

interface ContainerProps extends React.InputHTMLAttributes<HTMLDivElement> {
    info: FormFieldInfoProps;
    children: JSX.Element;
}

export const FormFieldContainer: React.FC<ContainerProps> = ({
    info,
    children,
    ...rest
}) => {
    return (
        <S.FormFieldContainer {...rest}>
            <FormFieldInfo {...info}></FormFieldInfo>
            <div>{children}</div>
        </S.FormFieldContainer>
    );
};
