import styled from 'styled-components';

export const AgentFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: fit-content;
    .form-fields-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .form-field-container {
            display: flex;
            align-items: center;
            gap: 12px;
            .select-option {
                display: flex;
                gap: 6px;
                svg {
                    height: 20px;
                    width: 20px;
                }
            }
            .drag-icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .drag-icon,
            .delete-button {
                width: 18px;
                height: 18px;

                margin-left: 6px;
                background-color: transparent;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    .add-button {
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 21px;
        font-size: 14px;
        padding: 0px;
        margin-top: -12px;
        border: none;
        background-color: inherit;
        overflow-y: hidden;
        width: fit-content;
        cursor: pointer;
    }
`;
