import { api } from '../api';

export class ProjectsService {
    private readonly path = '/projects';

    async getAll(idWorkspace: number | undefined) {
        if (!idWorkspace) return;

        const { data } = await api.get(
            `${this.path}?workspaceId=${idWorkspace}`,
        );

        return data;
    }
}
