import * as icons from "@tabler/icons-react";
import React from "react";
import { type Memory } from "services/brand/memories/types";
import { type RequestHeaders, type PaginationParams } from "services/commons/types";

export enum Visibility {
    Public = "public",
    Private = "private"
}

export type Collection = {
    id: number;
    created_at: Date;
    updated_at: Date;
    icon: string;
    tag: string;
    visibility: Visibility;
    translations: CollectionTranslation[] | undefined;
    brand_memories: Memory[];
    parent: Collection | null;
}

export type CollectionTranslation = {
    title: string;
    description: string;
    language_id: number;
}

export type GetAllCollectionsReq = {
    paginationParams: PaginationParams;
    headers: RequestHeaders;
    collection_id?: number;
    all?: boolean;
}

export type UpdateCollectionReq = {
    data: {
        icon: string;
        visibility: Visibility;
        brand_memory_group_id?: number;
        translations?: {
            title: string;
            description: string;
            language_id: number;
        }[];
    }

    headers: RequestHeaders;
    collection_id: number;
}

export type CollectionTranslationReq = {
    title: string;
    description: string;
    language_id: number;
}

export type GetCollectionReq = {
    collection_id: number;
    language_id: number;
    headers: RequestHeaders;
}

export type CreateCollectionReq = {
    data: {
        icon: string;
        ordering: number;
        visibility: Visibility;
        brand_memory_group_id: number;
        translations: CollectionTranslationReq[];
    }

    headers: RequestHeaders;
}

export type DeleteCollectionReq = {
    collection_id: number;

    headers: RequestHeaders;
}

export type PutCollectionIconReq = {
    collection_id: number;

    data: {
        icon: string;
    }

    headers: RequestHeaders;
}


export const businessIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconCoinEuro,
    icons.IconMoneybag,
    icons.IconCoin,
    icons.IconCoinYen,
    icons.IconCreditCard,
    icons.IconWallet,
    icons.IconBuilding,
    icons.IconBuildingBank,
    icons.IconCash,
    icons.IconBuildingStore,
    icons.IconCoinRupee,
    icons.IconCoinTaka,
    icons.IconScale,
    icons.IconBriefcase,
];

export const graphsIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconChartBar,
    icons.IconChartPie,
    icons.IconPresentation,
    icons.IconPresentationAnalytics,
    icons.IconGraph,
];

export const communicationIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconInbox,
    icons.IconMail,
    icons.IconSend,
    icons.IconPhone,
    icons.IconBrandWhatsapp,
];

export const contentIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconBrush,
    icons.IconPaperclip,
    icons.IconBook,
    icons.IconEdit,
    icons.IconBookmark,
];

export const fileAndFoldersIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconFileText,
    icons.IconClipboardText,
    icons.IconFileCheck,
    icons.IconNews,
    icons.IconFile,
    icons.IconFolder,
    icons.IconFolderDown,
    icons.IconFileDownload,
    icons.IconFiles,
    icons.IconReportSearch,
];

export const natureIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconSparkles,
    icons.IconBolt,
    icons.IconFlame,
    icons.IconStar,
    icons.IconSun,
    icons.IconCloud,
    icons.IconMoon,
    icons.IconHeart,
    icons.IconSeeding,
];

export const otherIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconHome,
    icons.IconWorld,
    icons.IconTicket,
    icons.IconRocket,
    icons.IconMusic,
    icons.IconTruckDelivery,
    icons.IconGift,
    icons.IconGlobe,
    icons.IconKey,
    icons.IconMicroscope,
    icons.IconLanguage,
    icons.IconMap,
    icons.IconTrophy,
    icons.IconPuzzle,
    icons.IconPhoto,
    icons.IconDiscount2,
    icons.IconCake,
    icons.IconFlask,
    icons.IconBulb,
    icons.IconShoppingBag,
    icons.IconShirt,
];

export const deviceIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconDeviceDesktop,
    icons.IconRadio,
    icons.IconDeviceLaptop,
    icons.IconCalculator,
    icons.IconDeviceMobile,
    icons.IconVideo,
    icons.IconCamera,
    icons.IconPrinter,
];

export const conversationAndGesturesIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconThumbUp,
    icons.IconThumbDown,
    icons.IconMoodSmile,
    icons.IconMoodSad,
    icons.IconUserCircle,
    icons.IconUsers,
    icons.IconMessage,
    icons.IconMessageDots,
];

export const softwareIcons: React.ForwardRefExoticComponent<Omit<icons.IconProps, "ref"> & React.RefAttributes<icons.Icon>>[] = [
    icons.IconAdjustments,
    icons.IconCodeCircle,
    icons.IconTag,
    icons.IconClick,
    icons.IconMicrophone,
    icons.IconClockHour3,
    icons.IconBox,
    icons.IconFlag,
    icons.IconSpeakerphone,
    icons.IconShield,
    icons.IconSettings,
    icons.IconWifi,
    icons.IconApps,
    icons.IconBell,
    icons.IconSearch,
    icons.IconCloudDownload,
    icons.IconMapPin,
    icons.IconLayersSubtract,
    icons.IconEye,
    icons.IconCalendar,
    icons.IconColorSwatch,
    icons.IconArchive,
    icons.IconColorPicker,
    icons.IconLock,
    icons.IconFingerprint,
    icons.IconFilter,
    icons.IconIdBadge2,
    icons.IconLifebuoy,
];

export const CollectionIcons = Object.fromEntries([
    ...businessIcons,
    ...graphsIcons,
    ...communicationIcons,
    ...contentIcons,
    ...fileAndFoldersIcons,
    ...natureIcons,
    ...otherIcons,
    ...deviceIcons,
    ...conversationAndGesturesIcons,
    ...softwareIcons
].map(icon => [icon.displayName!, icon]));

