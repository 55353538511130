import styled from 'styled-components';

export const TableContainerContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

`;

export const Table = styled.div`
    width: 100%;
    height: 80%;
    overflow-y: auto;
    
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const TableHead = styled.div`
    display: flex;
    align-items: center;

    height: 64px;
    font-size: 16px;
    font-weight: 700;
    color: rgba(160, 159, 165, 1);

    border-bottom: 1px solid rgba(241, 241, 241, 1);

    .collection-name {
        width: 52%;
        min-width: 596px;
    }

    .collection-status {
        width: 19%;
        min-width: 150px;
        
        margin-left: 5%;
    }

    .articles-count {
        width: 13%;
        min-width: 150px;
    }

    .empty-space {
        width: 150px;
        visibility: hidden;
    }


`;

export const TableBody = styled.div`

`;

