import React from 'react';
import { SliderModal } from '../SliderModal/SliderModal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'app/presentation/components';
import { SubmitButtonOpts, AnimateButtonOpts } from './../ModalNewMemory/types';
import { FaCheck } from 'react-icons/fa6';
import { IoCloseSharp } from 'react-icons/io5';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { Select, Option, Ellipsis, PublishStatus } from '../ModalViewMemory/styles';
import { routeTranslate as t } from 'utils/intl';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextEditor } from '../TextEditor';
import {
    type Memory,
    type MemoryContentStatus,
    type MemoryContent,
    type MemoryContentTranslation
} from 'services/brand/memories/types';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import { useSnackbar } from 'notistack';
import useLanguage from 'app/presentation/hooks/useLanguage';

type ModalEditMemoryProps = {
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;

    animateSlide: boolean;
    setAnimateSlide: React.Dispatch<React.SetStateAction<boolean>>;

    memory: Memory;

    selectedLanguage: number;
    setSelectedLanguage: React.Dispatch<React.SetStateAction<number | undefined>>;
}

type FormFields = {
    title: string;
    description: string;
    language_id: string;
    content: string;
};

// All three of Edit, View and New Memory modals should be the same, but now it would give so much 
// work to refact it so im just copying them :/
export const ModalEditMemory: React.FC<ModalEditMemoryProps> = props => {
    const { register, handleSubmit, setValue } = useForm<FormFields>();
    const { activeBrand } = useUserConfig();
    const { memory, selectedLanguage, setSelectedLanguage } = props;
    const { enqueueSnackbar: toast } = useSnackbar();
    const { fetchMemory, fetchMemoryContentsPublishStatuses, updateMemory, fetchMemories } = useMemoriesContext();
    const { getLanguageName } = useLanguage();
    const intl = useIntl();


    const [currMemory, setCurrMemory] = React.useState<Memory>(memory);
    const content = currMemory.contents && currMemory.contents.find(e => e.language_id == selectedLanguage);
    const [editorContent, setEditorContent] = React.useState<string>(content ? content.content : "");
    const [submitButton, setSubmitButton] = React.useState<SubmitButtonOpts>(null);
    const [buttonLoadAnimation, setButtonLoadAnimation] = React.useState<AnimateButtonOpts>();
    const [contentsPublishStatuses, setContentsPublishStatuses] = React.useState<MemoryContentStatus[] | undefined>();
    const [selectedContent, setSelectedContent] = React.useState<MemoryContent | undefined>();
    const [initialContent, setInitialContent] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>(currMemory.title);
    const [description, setDescription] = React.useState<string>(currMemory.description);

    const onSubmit: SubmitHandler<FormFields> = async data => {
        if (!activeBrand || !submitButton) {
            console.log('something bad happened');
            return;
        }

        setButtonLoadAnimation({ animate: true, button: submitButton });

        const translations: MemoryContentTranslation[] = [{
            title: data.title,
            description: data.description,
            content: data.content,
            language_id: selectedLanguage,
            publish_status: submitButton,
        }]

        try {
            const mem = await updateMemory(memory.id, translations);
            mem && setCurrMemory(mem);
            const statuses = await fetchMemoryContentsPublishStatuses(currMemory.id);
            setContentsPublishStatuses(statuses);

            toast(<FormattedMessage id="brand-center.knowledge.edit.success" />, { variant: "success" });
        } catch (err) {
            console.log(err);
            err instanceof Error ?

                toast(<FormattedMessage id="brand-center.knowledge.edit.error" />, { variant: "error" }) :
                toast(<FormattedMessage id="commons.something-went-wrong" />, { variant: "error" })
        } finally {
            setButtonLoadAnimation({ animate: false, button: submitButton });
        }
    }

    React.useEffect(() => {
        if (!memory || !selectedLanguage) return

        const setMem = async () => {
            try {
                const mem = await fetchMemory(currMemory.id, selectedLanguage);
                mem && setCurrMemory(mem);
            } catch (err) {
                toast(<FormattedMessage id="commons.something-went-wrong" />, { variant: "error" })
            }

            try {
                const statuses = await fetchMemoryContentsPublishStatuses(currMemory.id);
                setContentsPublishStatuses(statuses);
            } catch (e) {
                toast(<FormattedMessage id="commons.something-went-wrong" />, { variant: "error" })
            }
        }

        setMem()
    }, [selectedLanguage]);

    React.useEffect(() => {
        currMemory.contents
            ? setSelectedContent(currMemory.contents.find(elem => elem.language_id == selectedLanguage))
            : setSelectedContent(undefined)
    }, [currMemory]);

    React.useEffect(() => {
        setTitle(currMemory.title);
        setDescription(currMemory.description);

        const content = currMemory.contents && currMemory.contents.find(e => e.language_id == selectedLanguage);
        if (content) {
            setEditorContent(content.content);
            setInitialContent(content.content);
            return;
        }

        setInitialContent("");
        setEditorContent("");
    }, [selectedContent]);

    React.useEffect(() => setValue('content', editorContent), [editorContent]);

    return (
        <SliderModal
            animateSlide={props.animateSlide}
            onExit={() => {
                fetchMemories();
                props.setShowEditModal(false);
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className="slide-content">
                        <div className="slide-header">
                            <input
                                value={title}
                                className="slide-input"
                                placeholder={t(intl, "brand-center.knowledge.modal-new.title-placeholder")}
                                {...register('title')}
                                onChange={(e) => setTitle(e.currentTarget.value)}
                            />
                            <Button
                                className="slide-btn save"
                                type="submit"
                                onClick={() => setSubmitButton("draft")}
                                loading={buttonLoadAnimation?.animate && buttonLoadAnimation.button == "draft"}
                            >
                                <FormattedMessage id="brand-center.knowledge.modal-new.save-draft" />
                            </Button>

                            <Button
                                className="slide-btn publish"
                                icon={<FaCheck />}
                                iconPositon="start"
                                onClick={() => setSubmitButton("published")}
                                loading={buttonLoadAnimation?.animate && buttonLoadAnimation.button == "published"}
                            >
                                <FormattedMessage id="brand-center.knowledge.modal-new.publish" />
                            </Button>

                            <button type="reset" className="slide-btn close" onClick={() => props.setAnimateSlide(false)}>
                                <IoCloseSharp size={20} />
                            </button>
                        </div>

                        <textarea
                            className="description-input"
                            value={description}
                            placeholder={t(intl, "brand-center.knowledge.modal-new.description-placeholder")}
                            {...register('description')}
                            onChange={(e) => setDescription(e.currentTarget.value)}
                        />


                        <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                            <Select
                                value={selectedLanguage}
                                onChange={e => setSelectedLanguage(e.target.value as number)}
                                // i do hate my life
                                MenuProps={{ PaperProps: { sx: { backgroundColor: '#f1f1f1' } } }}
                            >
                                {activeBrand?.supported_languages && activeBrand.supported_languages.map(elem => (
                                    <Option
                                        selected={elem.language_id == activeBrand.brand_config.default_language && true}
                                        value={elem.language.id}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>

                                            <Ellipsis className={contentsPublishStatuses &&
                                                contentsPublishStatuses.find(e => e.language_id == elem.language_id)?.publish_status}
                                            />

                                            <span>
                                                {getLanguageName(elem.language)}
                                                {elem.language_id == activeBrand.brand_config.default_language && (
                                                    <>
                                                        {` (${intl.formatMessage({ id: "language.default" })})`}
                                                    </>
                                                )}
                                            </span>

                                        </div>
                                    </Option>
                                ))}
                            </Select>

                            {selectedContent && selectedContent.publish_status && (
                                <PublishStatus className={selectedContent.publish_status} >
                                    <span> <FormattedMessage id={`memory.${selectedContent.publish_status}`} /> </span>
                                </PublishStatus>
                            )}

                        </div>

                        <TextEditor
                            initialContent={initialContent}
                            content={editorContent}
                            setContent={setEditorContent}
                            editable={true}
                            placeholder={t(intl, "brand-center.knowledge.modal-new.editor-placeholder")}
                        />
                    </div>
                </div>
            </form>
        </SliderModal>
    );
}
