import React from 'react';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import { LanguageService } from 'services/language';
import { Language } from 'services/language/interfaces';
import LanguageContext from '../contexts/LanguageContext';
import { useSnackbar } from 'notistack';
import useText from '../hooks/useText';

const LanguageProvider: React.FC<ProviderProps> = ({ children }) => {
    const languageService = new LanguageService();
    const { enqueueSnackbar } = useSnackbar();
    const [languages, setLanguages] = React.useState<Language[]>();
    const { textGetter } = useText();
    const t = textGetter('lang');

    const getLanguages = async (): Promise<Language[]> => {
        const result = languageService.getAll(true);
        return result.then(
            res => {
                setLanguages(res);
                return res;
            },
            err => {
                enqueueSnackbar('Erro ao buscar linguas', {
                    variant: 'error',
                });
                return err;
            },
        );
    };

    const getLanguageName = (language: Language): string => {
        return t(language.lang);
    };

    return (
        <LanguageContext.Provider
            value={{
                languages,
                getLanguages,
                getLanguageName,
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;
