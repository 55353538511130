import React from "react";
import { Accordion, Summary, Menu } from "./styles";
import { AccordionDetails, MenuItem } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Select, Option } from "pages/brand-center/knowledge/components/ModalViewMemory/styles";
import { IconTrash, IconPlus } from '@tabler/icons-react';
import useUserConfig from "app/presentation/hooks/useUserConfigs";
import { routeTranslate as t } from "utils/intl";
import { FormattedMessage, useIntl } from 'react-intl';
import useBrandsContext from "app/presentation/hooks/useBrandsContext";
import { useSnackbar } from "notistack";
import useLanguage from "app/presentation/hooks/useLanguage";
import { IconZoom } from '@tabler/icons-react';

type SectionProps = {
    disabled: boolean;
    text: string;
}

// TODO there's a bug where sometimes the search input does not work as intended
// the input idk loses focus? just the typing does not work but w/e

// TODO make variations of section content to render accordingly
export const Section: React.FC<SectionProps> = ({ disabled, text }) => {
    const { activeBrand: brand } = useUserConfig();
    const { updateBrandConfig, deleteBrandLanguage, addBrandLanguage } = useBrandsContext();
    const { enqueueSnackbar: toast } = useSnackbar();
    const { getLanguages, getLanguageName, languages } = useLanguage();
    const intl = useIntl();

    const [selectedLanguage, setSelectedLanguage] = React.useState<number>(brand ? brand.brand_config.default_language : 0);
    const [search, setSearch] = React.useState<string>("");
    const [anchor, setAnchor] = React.useState<HTMLElement | undefined>(undefined);

    const showLanguagesDropdown = Boolean(anchor);

    React.useEffect(() => {
        if (disabled) return;
        if (!languages) getLanguages();

    }, [languages]);

    const displayableLanguages = React.useMemo((): [string, number][] => {
        if (!brand || disabled) return [];
        if (!languages) {
            getLanguages();
            return [];
        }

        const brandLanguages = brand.supported_languages.map(language => language.language_id);

        return languages.reduce((acc: [string, number][], language) => {
            if (!brandLanguages.includes(language.id) && getLanguageName(language).toLowerCase().includes(search)) {
                acc.push([getLanguageName(language), language.id]);
            }
            return acc;
        }, []);
    }, [brand, languages, search]);


    const handleUpdateBrandConfig = async (languageId: number): Promise<void> => {
        if (!brand) return;

        try {
            await updateBrandConfig(brand.brand_config.background_color, brand.brand_config.action_color, languageId);
            setSelectedLanguage(languageId);
        } catch (err: any) {
            err instanceof Error ?
                toast(err.message, { variant: "error" }) :
                toast(t(intl, "commons.something-went-wrong"), { variant: "error" })
        }
    }

    const handleDeleteBrandLanguage = async (languageId: number): Promise<void> => {
        try {
            await deleteBrandLanguage(languageId);
        } catch (err: any) {
            err instanceof Error ?
                toast(err.message, { variant: "error" }) :
                toast(t(intl, "commons.something-went-wrong"), { variant: "error" })
        }
    }

    const handleAddBrandLanguage = async (languageId: number): Promise<void> => {
        setAnchor(undefined);

        try {
            await addBrandLanguage(languageId);
        } catch (err: any) {
            err instanceof Error ?
                toast(err.message, { variant: "error" }) :
                toast(t(intl, "commons.something-went-wrong"), { variant: "error" })
        }
    }

    return (
        <Accordion disabled={disabled}>
            <Summary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <p>{text}</p>
                {disabled && (
                    <div className="soon-container">
                        <p> <FormattedMessage id="commons.soon" /> </p>
                    </div>
                )}
            </Summary>

            <AccordionDetails>
                <div className="accordion-content">
                    <p> <FormattedMessage id="brand-center.settings.components.sections.supported-languages.default-language" /> </p>

                    {selectedLanguage && languages &&
                        <Select
                            defaultValue=''
                            value={selectedLanguage}
                            onChange={e => { handleUpdateBrandConfig(e.target.value as number); }}
                            style={{ backgroundColor: "white", marginTop: "8px" }}
                            MenuProps={{ sx: { height: "265px" } }}
                        >
                            {brand && languages && languages.map(language => (
                                <Option
                                    selected={language.id === brand.brand_config.default_language && true}
                                    value={language.id}
                                    key={language.id}
                                >
                                    <span>{getLanguageName(language)}</span>
                                </Option>
                            ))}
                        </Select>
                    }

                    <div className="additional-languages">
                        <p style={{ marginTop: "30px" }}>
                            <FormattedMessage id="brand-center.settings.components.sections.supported-languages.additional-languagues.title" />
                        </p>
                        <span>
                            <FormattedMessage id="brand-center.settings.components.sections.supported-languages.additional-languagues.description" />
                        </span>

                        <div className="options">
                            {brand && brand.supported_languages && brand.supported_languages.map((brandLanguage) => (
                                brandLanguage.language_id != brand.brand_config.default_language && (
                                    <div className="option" key={brandLanguage.language_id}>
                                        <p>{getLanguageName(brandLanguage.language)}</p>
                                        <p
                                            className="clickable-btn"
                                            onClick={() => { handleUpdateBrandConfig(brandLanguage.language_id); }}
                                        >
                                            <FormattedMessage id="brand-center.settings.components.sections.supported-languages.additional-languagues.make-default" />
                                        </p>
                                        <IconTrash
                                            stroke={1.5}
                                            color="red"
                                            size={16}
                                            onClick={() => handleDeleteBrandLanguage(brandLanguage.language_id)}
                                        />
                                    </div>
                                )
                            ))}

                        </div>

                        <div className="add-language">
                            <p className="clickable-btn" onClick={(evt) => { setAnchor(evt.currentTarget); }}>
                                <IconPlus stroke={1.5} size={16} />
                                <FormattedMessage id="brand-center.settings.components.sections.supported-languages.additional-languagues.add-language" />
                            </p>
                        </div>

                        <Menu
                            anchorEl={anchor}
                            open={showLanguagesDropdown}
                            onClose={() => {
                                setAnchor(undefined);
                                setSearch("");
                            }}
                            key="languages-menu"
                        >
                            <div className="menu">
                                <div className="menu-header">
                                    <IconZoom stroke={1.5} size={16} />
                                    <input
                                        autoFocus
                                        placeholder={t(intl, "brand-center.settings.components.sections.menu.placeholder")}
                                        type="text"
                                        onChange={(evt) => { setSearch(evt.currentTarget.value); }}
                                    />
                                </div>

                                <div className="menu-body">
                                    {displayableLanguages.map(([languageName, languageId]) => (
                                        <MenuItem key={languageName} onClick={() => handleAddBrandLanguage(languageId)}>{languageName}</MenuItem>
                                    ))}
                                </div>
                            </div>
                        </Menu>

                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}
