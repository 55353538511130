import React from 'react';
import UserConfigContext from '../contexts/UserConfigContext';
import { UserConfigContextData } from '../contexts/interfaces/userconfig-context-props.interface';

function useUserConfig(): UserConfigContextData {
    const context = React.useContext(UserConfigContext);

    if (!context) {
        throw new Error(
            'useUserConfig deve ser usado com o UserConfigProvider',
        );
    }

    return context;
}

export default useUserConfig;
