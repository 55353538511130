import styled from 'styled-components';
import { Accordion as MuiAccordion, AccordionSummary, Menu as MuiMenu } from "@mui/material";

export const Accordion = styled(MuiAccordion)`
    &.MuiAccordion-root	{
        border: 1px solid rgba(226, 232, 240, 1);
        box-shadow: none;
        border-radius: 8px; 

        :before {
            display: none;
        }

        .Mui-disabled {
            opacity: 1;
            background-color: white;
            border-radius: 8px; 

            div {
                opacity: 0.5;
            }
        }
    }

    &.Mui-expanded	{
        border: 1px solid black;
        height: fit-content;
    }

    .accordion-content {
        padding-top: 20px;
        border: 0;
        border-top: 1px solid rgba(226, 232, 240, 1);

        p {
            font-weight: 600;
            font-size: 14px;
        }

        span {
            font-weight: 500;
            font-size: 13px;
        }

        .additional-languages {
            .options {
                display: flex;
                flex-direction: column;
                gap: 21px;
            
                margin-top: 21px;

                .option {
                    width: 450px;
                    height: 21px;
                    display: flex;

                    align-items: center;
                    
                    p:first-child {
                        width: 313px;
                    }

                    svg {
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }
            }

            .clickable-btn {
                cursor: pointer;
                color: rgba(25, 75, 251, 1);

                display: flex;
                align-items: center;
            }

            .add-language {
                margin-top: 20px;
            }
        }
    }

    .soon-container {
        background-color: rgba(241, 241, 241, 1);
        width: 66px;
        border-radius: 3px;

        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-weight: 600;
            font-size: 13px;
            color: black;
        }
    }
`;

export const Summary = styled(AccordionSummary)`
    &.MuiAccordionSummary-root {
        height: 78px;
    }

    .MuiAccordionSummary-content{
        display: flex;
        gap: 10px;
    }

    p {
        font-size: 16;
        font-weight: 700;
    }
`;

export const Menu = styled(MuiMenu)`
    margin-top: 4px;

    .MuiPaper-root {
        width: 196px;
        border-radius: 12px;
        //box-shadow: none;
        box-shadow: 8px 12px 24px 0px rgba(93, 106, 131, 0.1);
        overflow: hidden;
        

        li {
            height: 32px;
        }
    }

    .MuiList-root {
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .actions-dropdown-btn {
        display: flex;
        gap: 12px;
        font-weight: 600;
        font-size: 12px;
        padding-left: 8px;
        padding-bottom: 7px;
        padding-top: 7px;
        height: 32px;
    }

    .MuiMenuItem-root {
        font-size: 14px;
        border-radius: 5px;
    }

    .menu-header {
        display: flex;
        align-items: center;
        gap: 5px;

        border-bottom: 1px solid #E5E5E5;

        svg {
            margin-left: 11px;
            flex-shrink: 0;
        }

        input {
            border: 0;
            font-size: 14px;
            
            padding-top: 9px;
            padding-bottom: 8px;
        }
    } 

    .menu-body {
        height: fit-content;
        max-height: 250px;
        overflow-y: auto;
    }
`;
