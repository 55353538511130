import { api } from '../api';

export class OnboardingService {
    private readonly path = '/user';
    private readonly path2 = '/onboarding';

    async save() {
        const { data } = await api.post(`${this.path}/onboard`);

        return data;
    }

    async getUsecases() {
        const { data } = await api.get(
            `${this.path2}/usecases?page=1&limit=50`,
        );

        return data.data;
    }

    async getInterests() {
        const { data } = await api.get(
            `${this.path2}/interests?page=1&limit=50`,
        );

        return data.data;
    }
}
