import useText from 'app/presentation/hooks/useText';
import Behavior from 'pages/agent/behavior';
import Config from 'pages/agent/config';
import Sources from 'pages/agent/sources';
import Training from 'pages/agent/training';
import { Route, Routes } from 'react-router-dom';
import AgentInfoLayout from '../app/presentation/layout/agent-info';
import Integrations from 'pages/agent/integrations';
import PageNotFound from 'pages/not-found/PageNotFound';
import Insights from 'pages/agent/insights';

export const AgentInfoRoutes = () => {
    const { routeGetter } = useText();
    const agentRoutes = routeGetter('agent', false);
    return (
        <AgentInfoLayout>
            <>
                <Routes>
                    <Route path="/" element={<Behavior />} />
                    <Route path={agentRoutes('sources')} element={<Sources />} />
                    <Route path={agentRoutes('behavior')} element={<Behavior />} />
                    <Route path={agentRoutes('config')} element={<Config />} />
                    <Route
                        path={agentRoutes('integrations')}
                        element={<Integrations />}
                    />
                    <Route path={agentRoutes('training')} element={<Training />} />
                    <Route path={agentRoutes('insights')} element={<Insights />} />
                    <Route path="*" element={<PageNotFound useAbsolutPath />} />
                </Routes>
            </>
        </AgentInfoLayout>
    );
};
