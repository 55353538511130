import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import useChat from 'app/presentation/hooks/useChat';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { useIntl } from 'react-intl';
import {
    ChatTitleInput,
    ContainerModal,
    ButtonsContainer,
    ContainerTitle,
} from './styles';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

export function ChangeChatTitleModal() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { chats, chat, updateChat } = useChat();
    const { register, handleSubmit, watch, reset } = useForm<FieldValues>();
    const intl = useIntl();

    const { darkmode } = useDarkMode();
    const activeChat = chats?.find(item => item.id === chat);

    const chatTitle = activeChat?.title
        ? activeChat.title
        : intl.formatMessage({ id: 'chat.newChat.title' });

    const checkChangeTitle = watch('chatTitle');

    const isSaveButtonDisabled =
        !checkChangeTitle || checkChangeTitle === chatTitle ? true : false;

    const handleCancelChangeTitle = () => {
        reset({ chatTitle: chatTitle });
        handleClose();
    };

    const handleSubmitForm: SubmitHandler<FieldValues> = async newTitle => {
        const { chatTitle } = newTitle;

        if (chat) {
            await updateChat(chatTitle, chat);
        }
        handleClose();
    };

    return (
        <ContainerTitle className={darkmode ? 'usingDarkMode' : ''}>
            <h1 onClick={handleOpen}>{chatTitle}</h1>
            <Modal
                style={{ background: darkmode ? '#11151deb' : undefined }}
                open={open}
                onClose={handleClose}
                aria-labelledby={intl.formatMessage({
                    id: 'modal.ChangeChatTitle.ariaLabel',
                })}
                aria-describedby={intl.formatMessage({
                    id: 'modal.ChangeChatTitle.ariaDescription',
                })}
            >
                <ContainerModal className={darkmode ? 'darkmode' : ''}>
                    <Fade in={open} timeout={500}>
                        <Box id="boxContainer">
                            <form onSubmit={handleSubmit(handleSubmitForm)}>
                                <label htmlFor="chatTitle">
                                    {intl.formatMessage({
                                        id: 'modal.ChangeChatTitle.labelForm',
                                    })}
                                </label>
                                <ChatTitleInput
                                    type="text"
                                    id="chatTitle"
                                    defaultValue={chatTitle}
                                    autoComplete="off"
                                    {...register('chatTitle')}
                                />
                                <ButtonsContainer>
                                    <button
                                        onClick={handleCancelChangeTitle}
                                        id="cancel"
                                        type="button"
                                    >
                                        {intl.formatMessage({
                                            id: 'modal.ChangeChatTitle.cancelButtonForm',
                                        })}
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={isSaveButtonDisabled}
                                    >
                                        {intl.formatMessage({
                                            id: 'modal.ChangeChatTitle.saveButtonForm',
                                        })}
                                    </button>
                                </ButtonsContainer>
                            </form>
                        </Box>
                    </Fade>
                </ContainerModal>
            </Modal>
        </ContainerTitle>
    );
}
