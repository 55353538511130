import React from 'react';
import { Tooltip as MUITooltip } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';
import * as S from './styles';
import { ThemeContext } from 'styled-components';

interface TooltipProps {
    text: string;
    width?: number;
    height?: number;
}

export const Tooltip: React.FC<TooltipProps> = ({text, width=14, height=14}) => {
    const theme = React.useContext(ThemeContext);
    return (
        <MUITooltip
            title={text}
            placement="right"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor:
                            theme.components.tooltip.background
                                .color,
                        color: theme.components.tooltip.text.color,
                        fontSize: 14,
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <S.Tooltip width={width} height={height}>
                <IconInfoCircle />
            </S.Tooltip>
        </MUITooltip>
    );
};
