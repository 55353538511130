import React from 'react';
import { TemplateContextData } from '../contexts/interfaces/template-context-props.interface';
import TemplateContext from '../contexts/TemplateContext';

function useTemplates(): TemplateContextData {
    const context = React.useContext(TemplateContext);

    if (!context) {
        throw new Error('useTemplates deve ser usado com o TemplatesProvider');
    }

    return context;
}

export default useTemplates;
