import React from 'react';
import { Header } from './styles';
import DefinitionsLayout from '../../../app/presentation/layout/definitions';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import useDocumentTitle from 'utils/useDocumentTitle';

const Definitions: React.FC = () => {
    const { darkmode } = useDarkMode();
    const intl = useIntl();

    const currentPageName = routeTranslate(intl, 'title.definitions').replace(
        '/',
        '',
    );
    const pageTitle =
        currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1);
    useDocumentTitle(pageTitle);
    return (
        <>
            <div className="container">
                <Header>
                    <h1 style={{ color: darkmode ? '#fff' : '' }}>
                        {routeTranslate(intl, 'navmenu.user.definitions')}
                    </h1>
                    <div className="ds-flex">
                        <div
                            className="hr"
                            style={{ background: darkmode ? '#1D1D1F' : '' }}
                        ></div>
                    </div>
                </Header>
                <div className="clear"></div>
            </div>
            <DefinitionsLayout />
        </>
    );
};

export default Definitions;
