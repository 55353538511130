import React from 'react';
import { MemoriesContextProps } from '../contexts/interfaces/memories-context-props-interface';
import MemoriesContext from '../contexts/MemoriesContext';

function useMemoriesContext(): MemoriesContextProps {
    const context = React.useContext(MemoriesContext);

    if (!context) {
        throw new Error('useMemoriesContext must be used within MemoriesContextProvider');
    }

    return context;
}

export default useMemoriesContext;
