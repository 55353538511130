import React from 'react';
import {
    Button,
    Container,
    Input,
    ModalArteAiReport,
    Select as DefaultSelect,
    TextArea,
} from 'app/presentation/components/';
import {
    Prompt,
    Subcategories,
    ratios,
    ImageHistoryInterface,
} from 'interfaces/arte-ai.interface';
import {
    Header,
    Form,
    Images,
    Responsive,
    ContainerPage,
    DropdownContent,
} from './styles';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Menu, MenuList, Paper, useMediaQuery } from '@mui/material';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { routeTranslate } from '../../../utils/intl';
import { History } from 'app/presentation/components/icons';
import ModalArteAiHistory from 'app/presentation/components/dashboard/ModalArteAiHistory';
import useArteAi from '../../../app/presentation/hooks/useArteAi';
import useUserConfigs from '../../../app/presentation/hooks/useUserConfigs';
import { Generate } from '../../../interfaces/arte-ai.interface';
import { MuiMenuHeader } from '../documents/styles';
import { useIntl } from 'react-intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import useDocumentTitle from 'utils/useDocumentTitle';
import { TbCheck, TbSquareOff } from 'react-icons/tb';
import { FiChevronDown } from 'react-icons/fi';
import { ArteAiService } from 'services/arte-ai';
import { MdOutlineCrop169, MdOutlineCropPortrait } from 'react-icons/md';
import Loading from 'app/presentation/components/common/Loading';
import EmptyArt from './empty-art';
import ModalArteAiImages from 'app/presentation/components/dashboard/ModalArteAiImages';
import Tooltip from '@mui/material/Tooltip';
import ImgToImg from './img2img';
import Slider from 'app/presentation/components/dashboard/Slider';
import { base64ToFile } from './utils/base64toFile';

const arteAiService = new ArteAiService();

const ArteAi: React.FC = () => {
    const [selectedImage, setSelectedImage] = React.useState<number | null>();
    const [selectedRatio, setSelectedRatio] = React.useState<
        (typeof ratios)[4]
    >(ratios[4]);
    const [storagedSelectedRatio, setStoragedSelectedRatio] = React.useState<
        (typeof ratios)[4]
    >(ratios[4]);
    const [selectedImageMenu, setSelectedImageMenu] = React.useState<
        number | null
    >();
    const [open, setOpen] = React.useState(false);
    const [openReport, setOpenReport] = React.useState(false);
    const [openModalImage, setOpenModalImage] = React.useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
    const [loadingGenerate, setLoadingGenerate] = React.useState(false);
    const [canChangeRatio, setCanChangeRatio] = React.useState(true);
    const [imageUploaded, setImageUploaded] = React.useState<File | null>(null);
    const [imgData, setImgData] = React.useState<any>(null);

    const [sliderValue, setSliderValue] = React.useState(100);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(true);
    const openMenu = Boolean(anchorEl);
    const intl = useIntl();
    const { darkmode } = useDarkMode();
    const hasFile = imageUploaded !== null;
    const { register, handleSubmit, setValue, watch } = useForm<FieldValues>();
    const isDesktop = useMediaQuery('(min-width: 64em)');
    const {
        handleGenerate,
        handleImg2Img,
        rateResponse,
        handleVariation,
        prompt,
        history,
        categories,
    } = useArteAi();

    const { project, workspace, handleModalAccess } = useUserConfigs();
    const [selectedStylesIds, setSelectedStylesIds] = React.useState<
        Array<number>
    >([]);
    const [selectedAngleId, setSelectedAngleId] = React.useState('0');
    const [selectedModifierId, setSelectedModifierId] = React.useState('0');
    const [selectedLightingId, setSelectedLightingId] = React.useState('0');
    const [styleFilterId, setStyleFilterId] = React.useState(0);
    const [selectedTypeId, setSelectedTypeId] = React.useState(0);
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const scrollRef2 = React.useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = React.useState(false);

    const [isLoadingStyles, setIsLoadingStyles] = React.useState(false);
    const [isLoadingUpscaling, setIsLoadingUpscaling] = React.useState(false);
    const [isUpscaled, setIsUpscaled] = React.useState(false);
    const [useHistory, setUseHistory] = React.useState(false);
    const [historyImage, setHistoryImage] = React.useState<
        Array<ImageHistoryInterface>
    >([]);
    const [startX, setStartX] = React.useState(0);
    const [scrollLeft, setScrollLeft] = React.useState(0);
    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const [contentTypePrompts, setContentTypePrompts] =
        React.useState<Array<Prompt>>();
    const [stylePrompts, setStylePrompts] = React.useState<Array<Prompt>>();
    const [styleFilters, setStyleFilters] =
        React.useState<Array<Subcategories>>();
    const [anglesPrompts, setAnglesPrompts] = React.useState<Array<Prompt>>();
    const [lightingPrompts, setLightingPrompts] =
        React.useState<Array<Prompt>>();
    const [modifiersPrompt, setModifiersPrompt] =
        React.useState<Array<Prompt>>();
    const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        setStartX(event.pageX - scrollRef.current!.offsetLeft);
        setScrollLeft(scrollRef.current!.scrollLeft);
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging) {
            return;
        }
        event.preventDefault();
        const x = event.pageX - scrollRef.current!.offsetLeft;
        const scroll = x - startX;
        scrollRef.current!.scrollLeft = scrollLeft - scroll;
    };

    const handleMouseUp2 = () => {
        setIsDragging(false);
    };

    const handleMouseDown2 = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        setStartX(event.pageX - scrollRef.current!.offsetLeft);
        setScrollLeft(scrollRef2.current!.scrollLeft);
    };

    const handleMouseMove2 = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging) return;
        event.preventDefault();
        const x = event.pageX - scrollRef2.current!.offsetLeft;
        const scroll = x - startX;
        scrollRef2.current!.scrollLeft = scrollLeft - scroll;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const currentPageName = routeTranslate(intl, 'route.arteAi').replace(
        '/',
        '',
    );
    const pageTitle =
        currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1);
    useDocumentTitle(pageTitle);

    React.useEffect(() => {
        setValue('quantityNumber', 4);

        if (!workspace.plan_features.find(feat => feat === 'art')) {
            handleModalAccess();
        }
    }, []);

    const handleSubmitGenerate: SubmitHandler<FieldValues> = async data => {
        setLoadingGenerate(true);

        const angleId = Number(selectedAngleId);
        const modifierId = Number(selectedModifierId);
        const lightingId = Number(selectedLightingId);

        const items_selected: Array<number> = [...selectedStylesIds];

        if (selectedTypeId !== 0) {
            items_selected.unshift(selectedTypeId);
        }

        if (angleId !== 0) {
            items_selected.push(angleId);
        }
        if (modifierId !== 0) {
            items_selected.push(modifierId);
        }
        if (lightingId !== 0) {
            items_selected.push(lightingId);
        }

        let ratioInfo = selectedRatio;

        if (!canChangeRatio) {
            ratioInfo = storagedSelectedRatio;
        }

        const request: Generate = {
            user_prompt: data.prompt,
            project_id: project.id,
            count: Number(data.quantityNumber),
            additional_prompts: items_selected,
            width: ratioInfo.width,
            height: ratioInfo.height,
        };

        if (hasFile && imgData) {
            const formData = new FormData();

            const stValue = sliderValue / 100 > 0.9 ? 0.9 : sliderValue / 100;
            const str = String(stValue);
            const sanitazedFile = base64ToFile(imgData);

            formData.append('project_id', String(project.id));
            formData.append('image', sanitazedFile);
            formData.append('count', String(data.quantityNumber));
            formData.append('user_prompt', data.prompt);
            formData.append('image_strength', str);
            formData.append('prompt_strength', '0.8');
            formData.append('width', String(ratioInfo.width));
            formData.append('height', String(ratioInfo.height));
            formData.append('additional_prompts', String(items_selected));

            await handleImg2Img(formData);
        } else {
            await handleGenerate(request);
        }
        setSelectedRatio(ratioInfo);
        setLoadingGenerate(false);
    };

    const handleUpscale = async (withId: boolean, id?: number) => {
        setIsLoadingUpscaling(true);
        const imgId = withId ? id : selectedImageMenu;
        const ratioInfo = selectedRatio;
        const formData = new FormData();
        formData.append('project_id', String(project.id));
        formData.append('image_id', String(imgId));
        formData.append('width', String(ratioInfo.width));
        formData.append('height', String(ratioInfo.height));

        const result = await arteAiService.upscaleImage(formData);

        setIsLoadingUpscaling(false);

        if (result instanceof Error) {
            setIsLoadingUpscaling(false);

            return;
        }

        if (result && result.image) {
            handleModalImagesHistory(result.image);
            setIsLoadingUpscaling(false);
            setIsUpscaled(true);
            handleClose();
        }
    };

    const handleCreateVariation = async (withId: boolean, id?: number) => {
        setLoadingGenerate(true);

        handleClose();
        setSelectedImage(null);

        if (withId) {
            await handleVariation({
                count: parseInt(watch('quantityNumber')),
                image_id: id as number,
                project_id: project.id,
                prompt_strength: 0.5,
                similarity: 0.6,
            });
        } else if (selectedImageMenu) {
            await handleVariation({
                count: parseInt(watch('quantityNumber')),
                image_id: selectedImageMenu,
                project_id: project.id,
                prompt_strength: 0.5,
                similarity: 0.6,
            });
        } else if (selectedImage) {
            await handleVariation({
                count: parseInt(watch('quantityNumber')),
                image_id: selectedImage,
                project_id: project.id,
                prompt_strength: 0.5,
                similarity: 0.6,
            });

            setSelectedImage(undefined);
        }

        setLoadingGenerate(false);
    };

    const handleModal = () => {
        setOpen(!open);
    };

    const handleModalReport = () => {
        setOpenReport(!openReport);
    };

    const getImgSelected = () => {
        const imageHistory = history?.data.find(
            image => image?.id === selectedImage,
        );

        if (imageHistory) {
            return imageHistory;
        }
        return;
    };

    const handleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
        id: number,
    ) => {
        if (!event || !id) return;
        setSelectedImageMenu(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedImageMenu(undefined);
    };

    const handleRate = async (is_good: boolean, id: number) => {
        if (!selectedImage) {
            const rate = {
                is_good: is_good,
                resource_usage_id: id,
                rewrite_proposal: '',
            };

            await rateResponse(rate);
        } else {
            const rate = {
                is_good: is_good,
                resource_usage_id: selectedImage,
                rewrite_proposal: '',
            };

            await rateResponse(rate);
        }
    };

    const exitQtd = Number(watch('quantityNumber'));
    const angleInputSelect = watch('angleInputSelect');
    const modifierInputSelect = watch('modifierInputSelect');
    const lightingInputSelect = watch('lightingInputSelect');

    React.useEffect(() => {
        if (angleInputSelect) {
            setSelectedAngleId(angleInputSelect);
        }
    }, [angleInputSelect]);

    React.useEffect(() => {
        if (modifierInputSelect) {
            setSelectedModifierId(modifierInputSelect);
        }
    }, [modifierInputSelect]);

    React.useEffect(() => {
        if (lightingInputSelect) {
            setSelectedLightingId(lightingInputSelect);
        }
    }, [lightingInputSelect]);

    React.useEffect(() => {
        if (isDropdownOpen && dropdownRef.current) {
            dropdownRef.current.style.opacity = '1';
        } else if (!isDropdownOpen && dropdownRef.current) {
            dropdownRef.current.style.opacity = '0';
        }
    }, [isDropdownOpen]);

    const downloadImage = (
        withId: boolean,
        id?: number,
        useLink?: boolean,
        link?: string,
    ) => {
        handleClose();

        const imgId = withId ? id : selectedImageMenu;

        if (useLink) {
            fetch(link as string, {
                method: 'GET',
                headers: {},
            })
                .then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(
                            new Blob([buffer]),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'image.png';
                        link.click();
                    });
                })
                .catch(err => {
                    console.log(err);
                });
            return;
        }

        if (!prompt && !history) {
            return;
        }

        let image = prompt?.images.find(image => image?.id === imgId);

        if (!image) {
            image = prompt?.images.find(image => image?.id === selectedImage);
        }

        if (!image) {
            image = history?.data.find(image => image?.id === selectedImage);

            if (image) {
                fetch(image.response, {
                    method: 'GET',
                    headers: {},
                })
                    .then(response => {
                        response.arrayBuffer().then(function (buffer) {
                            const url = window.URL.createObjectURL(
                                new Blob([buffer]),
                            );
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = 'image.png';
                            link.click();
                        });
                    })
                    .catch(err => {
                        console.log(err);
                    });
                return;
            }
        }

        if (image) {
            fetch(image.response, {
                method: 'GET',
                headers: {},
            })
                .then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(
                            new Blob([buffer]),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'image.png';
                        link.click();
                    });
                })
                .catch(err => {
                    console.log(err);
                });
            return;
        }
    };

    const handleSelectImage = (id: number) => {
        setSelectedImage(id);
        setOpen(false);
    };

    React.useEffect(() => {
        if (selectedImage) {
            const result = getImgSelected();
            handleModalImagesHistory(result);
        }
    }, [selectedImage]);

    const content_type = categories.filter(
        categorie => categorie.tag === 'content_type',
    )[0];

    const getContentTypePrompts = async (id: number) => {
        const result = await arteAiService.getPrompt(id);

        if (result instanceof Error) {
            return;
        }

        setContentTypePrompts(result);
    };

    React.useEffect(() => {
        if (content_type) {
            getContentTypePrompts(content_type?.id);
        }
    }, [content_type]);

    const selectedType = contentTypePrompts?.filter(
        type => type?.id === selectedTypeId,
    )[0];

    const styles = categories.filter(
        categorie => categorie.tag === 'styles',
    )[0];

    const getStylesPrompts = async (id: number) => {
        const result = await arteAiService.getPrompt(id);

        if (result instanceof Error) {
            return;
        }

        setStylePrompts(result);
    };

    const getStylesFilters = async (id: number) => {
        const result = await arteAiService.getSubcategories(id);

        if (result instanceof Error) {
            return;
        }

        setStyleFilters(result);

        const hasPopular = result.filter((r: any) => r.tag === 'popular');

        if (Boolean(hasPopular.length)) {
            setStyleFilterId(hasPopular[0].id);
        }
    };
    React.useEffect(() => {
        if (styles) {
            getStylesPrompts(styles?.id);
            getStylesFilters(styles?.id);
        }
    }, [styles]);

    const selectedStyles = stylePrompts?.filter(style =>
        selectedStylesIds.includes(style?.id),
    );

    const selectedFilter = styleFilters?.filter(
        style => style?.id === styleFilterId,
    )[0];

    const angles = categories.filter(
        categorie => categorie.tag === 'angles',
    )[0];

    const getAnglesPrompts = async (id: number) => {
        const result = await arteAiService.getPrompt(id);

        if (result instanceof Error) {
            return;
        }

        setAnglesPrompts(result);
    };
    React.useEffect(() => {
        if (angles) {
            getAnglesPrompts(angles?.id);
        }
    }, [angles]);

    const selectedAngle = anglesPrompts?.filter(
        angle => String(angle?.id) === selectedAngleId,
    )[0];

    const selectedModifier = modifiersPrompt?.filter(
        modifier => String(modifier?.id) === selectedModifierId,
    )[0];

    const selectedLighting = lightingPrompts?.filter(
        light => String(light?.id) === selectedLightingId,
    )[0];

    const handleItemClick = (item: number) => {
        // Verifica se o item já está selecionado
        const index = selectedStylesIds.indexOf(item);

        if (index >= 0) {
            // Remove o item da seleção
            const newSelectedItems = [...selectedStylesIds];
            newSelectedItems.splice(index, 1);
            setSelectedStylesIds(newSelectedItems);
        } else {
            // Adiciona ou substitui o item na seleção
            const newSelectedItems = [...selectedStylesIds];

            if (newSelectedItems.length >= 3) {
                // Remove o primeiro item
                newSelectedItems.splice(0, 1);
            }

            // Adiciona o novo item na última posição
            newSelectedItems.push(item);
            setSelectedStylesIds(newSelectedItems);
        }
    };

    const handleChange = (e: any) => {
        if (e) {
            if (canChangeRatio) {
                setSelectedRatio(ratios[e as keyof typeof ratios]);
            } else {
                setStoragedSelectedRatio(ratios[e as keyof typeof ratios]);
            }
        }
    };

    const getStylesByFilter = async (subId: number) => {
        setIsLoadingStyles(true);
        const result = await arteAiService.getPromptSubCategories(
            styles?.id,
            subId,
        );

        if (result instanceof Error) {
            setIsLoadingStyles(false);

            return;
        }
        setStylePrompts(result);
        setIsLoadingStyles(false);
    };

    React.useEffect(() => {
        if (styleFilterId !== 0) {
            getStylesByFilter(styleFilterId);
        } else if (styleFilterId === 0) {
            getStylesPrompts(styles?.id);
        }
    }, [styleFilterId]);

    const modifiers = categories.filter(
        categorie => categorie.tag === 'modifiers',
    )[0];

    const getModifiersPrompts = async (id: number) => {
        const result = await arteAiService.getPrompt(id);

        if (result instanceof Error) {
            return;
        }

        setModifiersPrompt(result);
    };
    React.useEffect(() => {
        if (modifiers) {
            getModifiersPrompts(modifiers?.id);
        }
    }, [modifiers]);

    const lighting = categories.filter(
        categorie => categorie.tag === 'lighting',
    )[0];

    const getLightingPrompts = async (id: number) => {
        const result = await arteAiService.getPrompt(id);

        if (result instanceof Error) {
            return;
        }

        setLightingPrompts(result);
    };
    React.useEffect(() => {
        if (lighting) {
            getLightingPrompts(lighting?.id);
        }
    }, [lighting]);

    React.useEffect(() => {
        if (prompt?.images && prompt.images.length > 0) {
            setCanChangeRatio(false);
        }
    }, [prompt?.images]);

    const handleClickClean = () => {
        setSelectedTypeId(0);
        setSelectedStylesIds([]);
        setSelectedAngleId('0');
        setSelectedModifierId('0');
        setSelectedLightingId('0');
        setValue('prompt', '');
        setValue('angleInputSelect', '0');
        setValue('modifierInputSelect', '0');
        setValue('lightingInputSelect', '0');
    };

    const handleCleanAngle = () => {
        setValue('angleInputSelect', '0');
        setSelectedAngleId('0');
    };

    const handleCleanModifier = () => {
        setValue('modifierInputSelect', '0');
        setSelectedModifierId('0');
    };

    const handleCleanLight = () => {
        setValue('lightingInputSelect', '0');
        setSelectedLightingId('0');
    };

    const handleModalImages = (index?: number) => {
        setUseHistory(false);
        setIsUpscaled(false);
        if (prompt?.images && prompt.images.length > 0) {
            setSelectedImageIndex(index || 0);
            setOpenModalImage(!openModalImage);
        }
    };

    const handleModalImagesHistory = (image: any) => {
        if (image) {
            setUseHistory(true);
            setHistoryImage([image]);
            setSelectedImageIndex(0);
        }
        setOpenModalImage(!openModalImage);
        setIsUpscaled(false);
    };

    React.useEffect(() => {
        if (!openModalImage) {
            setSelectedImage(null);
        }
    }, [openModalImage]);

    return (
        <Container
            bg={isDesktop ? '' : '#fafafa'}
            className={isDesktop ? 'content-full' : ''}
        >
            <Header className={darkmode ? 'headerDark' : ''}>
                <div>
                    <h1>
                        Arte AI <span className="beta">Beta</span>
                    </h1>
                </div>
                <Button
                    id="history"
                    onClick={handleModal}
                    icon={<History darkmode={darkmode} />}
                >
                    {routeTranslate(intl, 'button.history')}
                </Button>
            </Header>
            <hr
                className="lineHrHeader"
                style={{ borderColor: darkmode ? '#3a3a3a' : '' }}
            />

            <ContainerPage className={darkmode ? 'arteAiContainerDark' : ''}>
                <div>
                    <Responsive>
                        <Images
                            className={darkmode ? 'imagesContainerDark' : ''}
                        >
                            <div className="images">
                                {prompt || selectedImage ? (
                                    <EmptyArt
                                        qtd={exitQtd}
                                        ratio={selectedRatio}
                                        hasImages={true}
                                        images={prompt?.images}
                                        anchorEl={anchorEl}
                                        downloadImage={downloadImage}
                                        handleClick={handleClick}
                                        handleModalImages={handleModalImages}
                                        handleRate={handleRate}
                                        openMenu={openMenu}
                                    />
                                ) : (
                                    <div className={`empty`}>
                                        <EmptyArt
                                            qtd={exitQtd}
                                            ratio={selectedRatio}
                                            hasImages={false}
                                            anchorEl={anchorEl}
                                            downloadImage={downloadImage}
                                            handleClick={handleClick}
                                            handleModalImages={
                                                handleModalImages
                                            }
                                            handleRate={handleRate}
                                            openMenu={openMenu}
                                        />
                                    </div>
                                )}
                            </div>

                            <Form
                                onSubmit={handleSubmit(handleSubmitGenerate)}
                                className={darkmode ? 'formDark' : ''}
                            >
                                <div className="textarea-filters">
                                    <TextArea
                                        id="prompt"
                                        required
                                        placeholder={routeTranslate(
                                            intl,
                                            'arteai.description.placeholder',
                                        )}
                                        register={register}
                                        maxLength={300}
                                    />

                                    {isDesktop && (
                                        <div className="filters-list">
                                            <div
                                                className="scrollFilters"
                                                ref={scrollRef2}
                                                onMouseDown={handleMouseDown2}
                                                onMouseMove={handleMouseMove2}
                                                onMouseUp={handleMouseUp2}
                                                onMouseLeave={handleMouseUp2}
                                            >
                                                {selectedType &&
                                                    selectedType?.id !== 0 && (
                                                        <div className="filter-content">
                                                            <img
                                                                src={
                                                                    selectedType.example_url
                                                                }
                                                                alt={
                                                                    selectedType.display_name
                                                                }
                                                                width={20}
                                                                height={20}
                                                                className="img-invert"
                                                            />
                                                            {
                                                                selectedType?.display_name
                                                            }

                                                            <IoMdClose
                                                                className="icon"
                                                                onClick={() =>
                                                                    setSelectedTypeId(
                                                                        0,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                {selectedStyles &&
                                                    selectedStyles.length > 0 &&
                                                    selectedStyles.map(
                                                        style => {
                                                            return (
                                                                <div className="filter-content">
                                                                    <img
                                                                        src={
                                                                            style.example_url
                                                                        }
                                                                        alt={
                                                                            style.display_name
                                                                        }
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                    />
                                                                    {
                                                                        style.display_name
                                                                    }
                                                                    <IoMdClose
                                                                        className="icon"
                                                                        onClick={() =>
                                                                            setSelectedStylesIds(
                                                                                selectedStylesIds.filter(
                                                                                    s =>
                                                                                        s !==
                                                                                        style?.id,
                                                                                ),
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                {selectedAngleId &&
                                                    selectedAngleId !== '0' && (
                                                        <div className="filter-content">
                                                            <p>
                                                                {
                                                                    selectedAngle?.display_name
                                                                }
                                                            </p>
                                                            <IoMdClose
                                                                className="icon"
                                                                onClick={
                                                                    handleCleanAngle
                                                                }
                                                            />
                                                        </div>
                                                    )}

                                                {selectedModifierId &&
                                                    selectedModifierId !==
                                                        '0' && (
                                                        <div className="filter-content">
                                                            <p>
                                                                {
                                                                    selectedModifier?.display_name
                                                                }
                                                            </p>
                                                            <IoMdClose
                                                                className="icon"
                                                                onClick={
                                                                    handleCleanModifier
                                                                }
                                                            />
                                                        </div>
                                                    )}

                                                {selectedLightingId &&
                                                    selectedLightingId !==
                                                        '0' && (
                                                        <div className="filter-content">
                                                            <p>
                                                                {
                                                                    selectedLighting?.display_name
                                                                }
                                                            </p>
                                                            <IoMdClose
                                                                className="icon"
                                                                onClick={
                                                                    handleCleanLight
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="content-submit">
                                    <div className="buttons">
                                        <label
                                            data-domain={routeTranslate(
                                                intl,
                                                'label.exitQuantity',
                                            ).concat(exitQtd > 1 ? 's' : '')}
                                        >
                                            <Input
                                                id="quantityNumber"
                                                type="number"
                                                register={register}
                                                min={1}
                                                max={4}
                                            />
                                        </label>

                                        <Button
                                            type="submit"
                                            icon={<HiArrowNarrowRight />}
                                            loading={loadingGenerate}
                                        >
                                            {routeTranslate(
                                                intl,
                                                'button.create',
                                            )}
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            id="clean"
                                            type="button"
                                            icon={<IoMdClose id="icon" />}
                                            onClick={handleClickClean}
                                        >
                                            {isDesktop
                                                ? routeTranslate(
                                                      intl,
                                                      'button.clear.fields',
                                                  )
                                                : ''}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </Images>

                        <div className="image-options">
                            <ImgToImg
                                file={imageUploaded}
                                setFile={setImageUploaded}
                                hasFile={hasFile}
                                setSliderValue={setSliderValue}
                                sliderValue={sliderValue}
                                w={selectedRatio.width}
                                h={selectedRatio.height}
                                ratioKey={selectedRatio.aspect}
                                setImgCropped={setImgData}
                            />
                            <hr className="lineHr" />

                            <div className="ratio-section">
                                <p>
                                    {routeTranslate(intl, 'arteai.label.ratio')}
                                </p>

                                <div className="selectContainer">
                                    <div className="img-ratios">
                                        <MdOutlineCrop169 />

                                        <Tooltip
                                            title={`${
                                                canChangeRatio
                                                    ? selectedRatio.width
                                                    : storagedSelectedRatio.width
                                            } x ${
                                                canChangeRatio
                                                    ? selectedRatio.height
                                                    : storagedSelectedRatio.height
                                            }`}
                                            placement="top"
                                            arrow
                                            sx={{
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                            }}
                                            PopperProps={{
                                                sx: {
                                                    '& .MuiTooltip-tooltip': {
                                                        backgroundColor:
                                                            darkmode
                                                                ? '#fff'
                                                                : '#000',
                                                        color: darkmode
                                                            ? '#000 '
                                                            : '#fff',
                                                    },
                                                    '& .MuiTooltip-arrow': {
                                                        color: darkmode
                                                            ? '#fff '
                                                            : '#000',
                                                    },
                                                },
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -10],
                                                        },
                                                    },
                                                ],
                                            }}
                                            open={tooltipIsOpen}
                                            onOpen={() =>
                                                setTooltipIsOpen(true)
                                            }
                                            onClose={() =>
                                                setTooltipIsOpen(false)
                                            }
                                        >
                                            <p>
                                                {canChangeRatio
                                                    ? selectedRatio.aspect.replace(
                                                          '/',
                                                          ' : ',
                                                      )
                                                    : storagedSelectedRatio.aspect.replace(
                                                          '/',
                                                          ' : ',
                                                      )}
                                            </p>
                                        </Tooltip>
                                        <MdOutlineCropPortrait />
                                    </div>

                                    <div
                                        onMouseLeave={() =>
                                            setTooltipIsOpen(false)
                                        }
                                    >
                                        <Slider
                                            onChange={value => {
                                                handleChange(value);
                                                setTooltipIsOpen(true);
                                            }}
                                            marks={true}
                                            step={1}
                                            max={7}
                                            min={1}
                                            defaultValue={4}
                                            onSlideClick={() =>
                                                setTooltipIsOpen(true)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr className="lineHr" />

                            {content_type && (
                                <div className="type-section">
                                    <p>{content_type.display_name}</p>
                                    <div className="type-buttons">
                                        <div
                                            className={`type-item ${
                                                selectedTypeId === 0 &&
                                                'selected'
                                            }`}
                                            onClick={() => setSelectedTypeId(0)}
                                        >
                                            <div className="photo-name">
                                                <TbSquareOff
                                                    style={{
                                                        fontSize: 18,
                                                        color:
                                                            selectedTypeId === 0
                                                                ? '#000'
                                                                : '#a09fa5',
                                                    }}
                                                />
                                                <p>
                                                    {routeTranslate(
                                                        intl,
                                                        'arteai.none',
                                                    )}
                                                </p>
                                            </div>
                                            {selectedTypeId === 0 && (
                                                <TbCheck className="checked-icon" />
                                            )}
                                        </div>
                                        {contentTypePrompts &&
                                            contentTypePrompts.map(type => {
                                                return (
                                                    <div
                                                        className={`type-item ${
                                                            selectedTypeId ===
                                                                type?.id &&
                                                            'selected'
                                                        }`}
                                                        onClick={() =>
                                                            setSelectedTypeId(
                                                                type?.id,
                                                            )
                                                        }
                                                    >
                                                        <div className="photo-name">
                                                            <img
                                                                src={
                                                                    type.example_url
                                                                }
                                                                alt={
                                                                    type.display_name
                                                                }
                                                                width={20}
                                                                height={20}
                                                            />
                                                            <p>
                                                                {
                                                                    type.display_name
                                                                }
                                                            </p>
                                                        </div>
                                                        {selectedTypeId ===
                                                            type?.id &&
                                                            type?.id !== 0 && (
                                                                <TbCheck className="checked-icon" />
                                                            )}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}

                            <hr className="lineHr" />

                            {styles && (
                                <div className="style-section">
                                    <p onClick={toggleDropdown}>
                                        <span
                                            className={`${
                                                isDropdownOpen &&
                                                'iconDropdownClose'
                                            }`}
                                        >
                                            <FiChevronDown />
                                        </span>
                                        {styles.display_name}
                                    </p>

                                    {isDropdownOpen && (
                                        <DropdownContent
                                            isOpen={isDropdownOpen}
                                            ref={dropdownRef}
                                        >
                                            <div
                                                className="scrollFilters"
                                                ref={scrollRef}
                                                onMouseDown={handleMouseDown}
                                                onMouseMove={handleMouseMove}
                                                onMouseUp={handleMouseUp}
                                                onMouseLeave={handleMouseUp}
                                            >
                                                <div className="style-filters">
                                                    <button
                                                        className={`filterButton ${
                                                            styleFilterId ===
                                                                0 &&
                                                            'selectedFilter'
                                                        }`}
                                                        onClick={() =>
                                                            setStyleFilterId(0)
                                                        }
                                                    >
                                                        {routeTranslate(
                                                            intl,
                                                            'arteai.filters.all',
                                                        )}
                                                    </button>
                                                    {styleFilters &&
                                                        styleFilters.map(
                                                            filter => {
                                                                return (
                                                                    <button
                                                                        className={`filterButton ${
                                                                            styleFilterId ===
                                                                                filter?.id &&
                                                                            'selectedFilter'
                                                                        }`}
                                                                        onClick={() =>
                                                                            setStyleFilterId(
                                                                                filter?.id,
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            filter.display_name
                                                                        }
                                                                    </button>
                                                                );
                                                            },
                                                        )}
                                                </div>
                                            </div>

                                            {isLoadingStyles ? (
                                                <Loading
                                                    open={true}
                                                    absolute={false}
                                                />
                                            ) : (
                                                <div className="style-options">
                                                    <p>
                                                        {selectedFilter?.display_name ||
                                                            routeTranslate(
                                                                intl,
                                                                'arteai.filters.all',
                                                            )}
                                                    </p>
                                                    <div className="options">
                                                        {stylePrompts &&
                                                            stylePrompts.map(
                                                                style => {
                                                                    return (
                                                                        <div
                                                                            className={`style-option ${
                                                                                selectedStylesIds.includes(
                                                                                    style?.id,
                                                                                ) &&
                                                                                'styleSelected'
                                                                            }`}
                                                                            onClick={() =>
                                                                                handleItemClick(
                                                                                    style?.id,
                                                                                )
                                                                            }
                                                                        >
                                                                            {selectedStylesIds.includes(
                                                                                style?.id,
                                                                            ) && (
                                                                                <TbCheck className="checked-icon" />
                                                                            )}

                                                                            <img
                                                                                src={
                                                                                    style.example_url
                                                                                }
                                                                                alt={
                                                                                    style.display_name
                                                                                }
                                                                                width={
                                                                                    80
                                                                                }
                                                                                height={
                                                                                    80
                                                                                }
                                                                            />
                                                                            <p>
                                                                                {
                                                                                    style.display_name
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    );
                                                                },
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                        </DropdownContent>
                                    )}
                                </div>
                            )}

                            <hr className="lineHr" />

                            {anglesPrompts && (
                                <DefaultSelect
                                    label={angles.display_name}
                                    id="angleInputSelect"
                                    className={
                                        !watch('angleInputSelect')
                                            ? 'empty'
                                            : ''
                                    }
                                    register={register}
                                    options={[
                                        {
                                            name: routeTranslate(
                                                intl,
                                                'arteai.none',
                                            ),
                                            value: '0',
                                        },
                                        ...anglesPrompts.map(item => {
                                            return {
                                                name: item.display_name,
                                                value: String(item?.id),
                                            };
                                        }),
                                    ]}
                                />
                            )}

                            <hr className="lineHr" />

                            {modifiersPrompt && (
                                <DefaultSelect
                                    label={modifiers.display_name}
                                    id="modifierInputSelect"
                                    className={
                                        !watch('modifierInputSelect')
                                            ? 'empty'
                                            : ''
                                    }
                                    register={register}
                                    options={[
                                        {
                                            name: routeTranslate(
                                                intl,
                                                'arteai.none',
                                            ),
                                            value: '0',
                                        },
                                        ...modifiersPrompt.map(item => {
                                            return {
                                                name: item.display_name,
                                                value: String(item?.id),
                                            };
                                        }),
                                    ]}
                                />
                            )}

                            <hr className="lineHr" />

                            {lightingPrompts && (
                                <DefaultSelect
                                    label={lighting.display_name}
                                    id="lightingInputSelect"
                                    className={
                                        !watch('lightingInputSelect')
                                            ? 'empty'
                                            : ''
                                    }
                                    register={register}
                                    options={[
                                        {
                                            name: routeTranslate(
                                                intl,
                                                'arteai.none',
                                            ),
                                            value: '0',
                                        },
                                        ...lightingPrompts.map(item => {
                                            return {
                                                name: item.display_name,
                                                value: String(item?.id),
                                            };
                                        }),
                                    ]}
                                />
                            )}
                        </div>
                    </Responsive>
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                        width: '100%',
                        '& > .MuiPaper-root': {
                            borderRadius: '12px',
                            boxShadow: '8px 12px 24px rgba(93, 106, 131, 0.1)',
                            background: darkmode ? '#1D1D1F' : '',
                        },
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Paper
                        sx={{
                            width: 'auto',
                            boxShadow: 0,
                            background: darkmode ? '#1D1D1F' : '',
                        }}
                    >
                        <MenuList
                            sx={{
                                padding: 0,
                                boxShadow: 0,
                                background: darkmode ? '#1D1D1F' : '',
                            }}
                            disablePadding
                            dense
                        >
                            <MuiMenuHeader>
                                <ul>
                                    <li
                                        onClick={() => {
                                            handleClose();
                                            setSelectedImage(selectedImageMenu);
                                        }}
                                        onMouseEnter={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#151718' : '')
                                        }
                                        onMouseLeave={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#1D1D1F' : '')
                                        }
                                    >
                                        {routeTranslate(intl, 'tooltip.open')}
                                    </li>

                                    <Tooltip
                                        title={routeTranslate(
                                            intl,
                                            'tooltip.upscale',
                                        )}
                                        placement="top"
                                        sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        }}
                                        PopperProps={{
                                            sx: {
                                                '& .MuiTooltip-tooltip': {
                                                    backgroundColor: darkmode
                                                        ? '#fff'
                                                        : '#000',
                                                    color: darkmode
                                                        ? '#000 '
                                                        : '#fff',
                                                },
                                            },
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -10],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <li
                                            onClick={() => handleUpscale(false)}
                                            onMouseEnter={(e: any) =>
                                                (e.target.style.backgroundColor =
                                                    darkmode ? '#151718' : '')
                                            }
                                            onMouseLeave={(e: any) =>
                                                (e.target.style.backgroundColor =
                                                    darkmode ? '#1D1D1F' : '')
                                            }
                                        >
                                            <div
                                                style={{
                                                    width: 0,
                                                    marginLeft: '50%',
                                                    display: isLoadingUpscaling
                                                        ? undefined
                                                        : 'none',
                                                }}
                                            >
                                                <Loading
                                                    open={true}
                                                    absolute={false}
                                                    size={25}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    opacity: isLoadingUpscaling
                                                        ? 0
                                                        : 1,
                                                }}
                                            >
                                                {routeTranslate(
                                                    intl,
                                                    'navmenu.user.expand',
                                                )}
                                            </div>
                                        </li>
                                    </Tooltip>

                                    <li
                                        onClick={() =>
                                            handleCreateVariation(false)
                                        }
                                        style={{ paddingRight: 0 }}
                                        onMouseEnter={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#151718' : '')
                                        }
                                        onMouseLeave={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#1D1D1F' : '')
                                        }
                                    >
                                        {routeTranslate(
                                            intl,
                                            'tooltip.variation',
                                        )}
                                    </li>
                                    <li
                                        onMouseEnter={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#151718' : '')
                                        }
                                        onMouseLeave={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#1D1D1F' : '')
                                        }
                                        onClick={() => downloadImage(false)}
                                    >
                                        Download
                                    </li>
                                    <li
                                        onMouseEnter={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#151718' : '')
                                        }
                                        onMouseLeave={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#1D1D1F' : '')
                                        }
                                    >
                                        {routeTranslate(intl, 'tooltip.report')}
                                    </li>
                                </ul>
                            </MuiMenuHeader>
                        </MenuList>
                    </Paper>
                </Menu>
            </ContainerPage>
            <ModalArteAiHistory
                handleSelectImage={handleSelectImage}
                active={open}
                handleClose={handleModal}
            />
            <ModalArteAiReport
                active={openReport}
                selectedImage={selectedImage}
                handleClose={handleModalReport}
            />
            <ModalArteAiImages
                active={openModalImage}
                handleClose={
                    useHistory ? handleModalImagesHistory : handleModalImages
                }
                imagesData={useHistory ? historyImage : prompt?.images}
                initalImageIndex={selectedImageIndex}
                handleDownload={downloadImage}
                handleGenerateVarition={handleCreateVariation}
                h={
                    useHistory
                        ? historyImage[0].image_generation.height
                        : selectedRatio.height
                }
                w={
                    useHistory
                        ? historyImage[0].image_generation.width
                        : selectedRatio.width
                }
                project_id={project.id}
                setIsUpscaled={setIsUpscaled}
                isUpscaled={isUpscaled}
            />
        </Container>
    );
};

export default ArteAi;
