import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

interface ModalProps {
    active: boolean;
    showBack?: boolean;
}

const ModalMaintenance: React.FC<ModalProps> = ({
    active,
    showBack = false,
}) => {
    const intl = useIntl();

    const path =
        '../../' +
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.home');

    const { darkmode } = useDarkMode();

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal className={darkmode ? 'listProjectsDark' : ''}>
                <div className="content-modal">
                    <h2>{routeTranslate(intl, 'modal.maintenance.title')}</h2>
                    <p>
                        {routeTranslate(intl, 'modal.maintenance.description')}
                    </p>
                    {showBack && (
                        <Link to={path}>
                            <Button
                                className="backHome"
                                style={{
                                    color: darkmode ? '#000' : '',
                                    background: darkmode ? '#d0ff61' : '',
                                    borderColor: darkmode ? '#d0ff61' : '',
                                }}
                            >
                                {routeTranslate(intl, 'notFound.back')}
                            </Button>
                        </Link>
                    )}
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalMaintenance;
