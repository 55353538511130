import styled, { css } from 'styled-components';

export const BehaviorPanel = styled.form`
    ${({
        theme: {
            agent: {
                pages: {
                    behavior: {
                        behaviorPanel: { hr },
                    },
                },
            },
        },
    }) => css`
        .panel-desc {
            letter-spacing: 0.3px;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 15px;
        }

        .selected-behavior-desc-container {
            margin: 15px 0px;
            .selected-behavior-desc {
                letter-spacing: 0.3px;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
            }
        }

        .actions-container {
            margin-top: 12px;
            margin-left: 0px;

            @media (min-width: 64em) {
                margin-left: 36px;
            }
        }

        .add-button {
            margin-top: 12px;
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 21px;
            font-size: 14px;
            padding: 0px;
            margin-left: 0px;
            width: fit-content;
            cursor: pointer;

            @media (min-width: 64em) {
                margin-left: 36px;
            }
        }

        .ask-for-rating-container {
            margin-top: 15px;

            @media (min-width: 64em) {
                padding-top: 15px;
                padding-top: 15px;
                margin-top: 15px;
                padding-top: 15px;
                margin-top: 15px;
                border-top: 1px solid ${hr.color};
            }
        }

        .buttons-container {
            padding-top: 15px;
            border-top: 1px solid ${hr.color};
            margin-top: 15px;
            display: flex;
            gap: 8px;
            flex-direction: row-reverse;
            justify-content: start;

            @media (min-width: 64em) {
                flex-direction: row;
                justify-content: end;
            }

            button {
                height: 34px;
                padding: 8px 18px 8px 18px;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.2px;
                gap: 4px;
                border-radius: 6px;
                .desktop {
                    display: none;

                    @media (min-width: 64em) {
                        display: inline;
                    }
                }

                &.save-button {
                    width: 103px;
                    @media (min-width: 64em) {
                        width: 190px;
                    }
                }
            }
        }
    `}
`;

export const MenuTitleContainer = styled.div`
    ${({
        theme: {
            agent: {
                pages: {
                    behavior: {
                        behaviorPanel: { menu },
                    },
                },
            },
        },
    }) => css`
        height: 31.5px;
        display: flex;
        align-items: center;
        padding-left: 9px;
        border-bottom: 1px solid ${menu.hr.color};
        padding-bottom: 6px;
        span {
            margin-left: 10px;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0.2px;
            color: ${menu.text.color};
        }
    `}
`;

export const MenuButton = styled.button`
    ${({
        theme: {
            agent: {
                pages: {
                    behavior: {
                        behaviorPanel: { menu },
                    },
                },
            },
        },
    }) => css`
        display: flex;
        align-items: center;
        gap: 10px;
        height: 44px;
        border: none;
        background-color: ${menu.background.color};
        width: 100%;
        margin-bottom: 4px;
        transition: all linear 0.05s;
        padding: 0px 12px;
        cursor: pointer;

        p {
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0.2px;
            color: ${menu.text.color};
        }

        .icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: ${menu.icon.background.color};
            color: ${menu.icon.text.color};
            height: 28px;
            width: 28px;
            svg {
                height: 16px;
                width: 16px;
            }
        }

        :hover {
            background-color: ${menu.background.active};
        }

        :last-child {
            margin-bottom: 0px;
        }
    `}
`;
