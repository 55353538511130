export class Statistic {
    constructor(
        private value: number,
        private previousValue: number
    ) {}

    public hasPreviousValue = (): boolean => {
        return this.previousValue != 0;
    }

    public getValue = (): number => {
        return this.value;
    }

    public increasePercentage = (): number => {
        return this.previousValue != 0
            ? (this.value - this.previousValue) * 100 / this.previousValue
            : 0
    }

    public hasValueDecreased = (): boolean => {
        return this.increasePercentage() < 0
    }

    public increasePercentageModule = (): number => {
        return this.hasValueDecreased() ? -this.increasePercentage() : this.increasePercentage()
    }
}

export const percentage = (part: number, whole: number): number => {
    return whole != 0 ? part * 100 / whole : 0
}

export class PercentageStatistic extends Statistic {
    constructor(
        valuePart: number,
        valueWhole: number,
        previousValuePart: number,
        previousValueWhole: number
    ) {
        super(percentage(valuePart, valueWhole), percentage(previousValuePart, previousValueWhole))
    }
}