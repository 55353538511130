import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal, MuiMenuHeader } from './styles';
import { FieldValues, useForm } from 'react-hook-form';
import { TextArea } from 'app/presentation/components';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { Menu, MenuList, Paper } from '@mui/material';
import { Trash } from '../../icons';
import useTemplates from '../../../hooks/useTemplates';
import { AuthContext } from '../../../contexts/AuthContext';
import { getNameLetters } from '../../../../../utils/others';
import useDocuments from '../../../hooks/useDocuments';
import { routeTranslate } from '../../../../../utils/intl';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { copyToClipboard } from 'utils/copyToClipboard';
import Tooltip from '@mui/material/Tooltip';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    title: string | undefined;
    image: string | undefined;
    text: string;
    idHistory: number | undefined;
    idTemplate: number | undefined;
}

const ModalEditHistory: React.FC<ModalProps> = ({
    active,
    handleClose,
    title,
    image,
    text,
    idHistory,
    idTemplate,
}) => {
    const { register, setValue } = useForm<FieldValues>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { enqueueSnackbar } = useSnackbar();
    const textContent = text.trim();

    const user = React.useContext(AuthContext);
    const { darkmode } = useDarkMode();

    const { removeHistory } = useTemplates();
    const { handleSelectDocument, newDocument } = useDocuments();
    const intl = useIntl();
    const navigate = useNavigate();

    React.useEffect(() => {
        setValue('title', textContent);
    }, [active, text, textContent]);

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseOption = () => {
        setAnchorEl(null);
    };

    const handleRemoveHistory = async () => {
        if (!idHistory || !idTemplate) return;
        handleCloseOption();
        handleClose();
        await removeHistory([idHistory], idTemplate);
        enqueueSnackbar(routeTranslate(intl, 'removed.success'), {
            variant: 'success',
        });
    };

    const handleEdit = (id?: number, rtc_id?: string) => {
        if (!rtc_id || !id) return;

        const path =
            routeTranslate(intl, 'route.urlLang') +
            routeTranslate(intl, 'route.backoffice');

        navigate(
            `/${path}${routeTranslate(intl, 'route.documents')}${routeTranslate(
                intl,
                'route.document',
            )}/${rtc_id}/${id}`,
        );
    };

    const handleEditInDocument = async () => {
        const request = { title: 'Novo Documento', content: text };
        const documentResponse = await newDocument(request);

        if (documentResponse && documentResponse.id) {
            handleSelectDocument(documentResponse.id);
            handleEdit(documentResponse.id, documentResponse.rtc_id);
            handleClose();
        }
    };

    const copyContent = () => {
        const copy = copyToClipboard(textContent);
        if (copy) {
            enqueueSnackbar(routeTranslate(intl, 'copy.success'), {
                variant: 'success',
            });
        }
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal onClick={handleCloseContainer}>
                <div
                    className={`content-modal-history ${
                        darkmode && 'modalHistoryDark'
                    }`}
                >
                    <header>
                        <div className="name-template">
                            <div className="icon">
                                <img src={image} />
                            </div>
                            <h1>{title}</h1>
                        </div>
                        <div className="options-history">
                            <ul>
                                <li onClick={handleEditInDocument}>
                                    {routeTranslate(
                                        intl,
                                        'modal.history.openDocument',
                                    )}
                                </li>
                                <li onClick={copyContent}>
                                    {routeTranslate(intl, 'modal.history.copy')}
                                </li>
                                <Tooltip
                                    title={user?.displayName ?? ''}
                                    placement="bottom"
                                    sx={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                    PopperProps={{
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                backgroundColor: darkmode
                                                    ? '#fff'
                                                    : '#000',
                                                color: darkmode
                                                    ? '#000 '
                                                    : '#fff',
                                            },
                                        },
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -10],
                                                },
                                            },
                                        ],
                                    }}
                                >
                                    <li className="image">
                                        {getNameLetters(
                                            user?.displayName ?? 'User Name',
                                        )}
                                    </li>
                                </Tooltip>
                                <li>
                                    <div onClick={handleClick} className="dots">
                                        <HiOutlineDotsHorizontal size={26} />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseOption}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{
                            width: '100%',
                            '& > .MuiPaper-root': {
                                borderRadius: '12px',
                                boxShadow:
                                    '8px 12px 24px rgba(93, 106, 131, 0.1)',
                            },
                        }}
                        anchorOrigin={{
                            vertical: 100,
                            horizontal: -48,
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Paper
                            sx={{
                                width: 160,
                                boxShadow: 0,
                            }}
                        >
                            <MenuList
                                sx={{ padding: 0, boxShadow: 0 }}
                                disablePadding
                                dense
                            >
                                <MuiMenuHeader>
                                    <ul>
                                        <li
                                            onClick={() =>
                                                handleRemoveHistory()
                                            }
                                            className="icon"
                                        >
                                            <Trash />
                                            <span>
                                                {' '}
                                                {routeTranslate(
                                                    intl,
                                                    'modal.history.delete',
                                                )}
                                            </span>
                                        </li>
                                    </ul>
                                </MuiMenuHeader>
                            </MenuList>
                        </Paper>
                    </Menu>
                    <form>
                        <div className="input">
                            <TextArea
                                label=" "
                                id="title"
                                placeholder="Como criar..."
                                register={register}
                                maxLength={800}
                                disabled
                            />
                        </div>
                    </form>
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalEditHistory;
