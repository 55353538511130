import React from 'react';
import { ContainerInput } from './styles';
import { FieldValues, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Input } from '../../common';
import { VscAdd } from 'react-icons/vsc';
import { IoMdClose } from 'react-icons/io';
import { routeTranslate } from '../../../../../utils/intl';
import { useIntl } from 'react-intl';

interface Props {
    handleChange: (values: string[]) => void;
    max?: number;
}

const InputAdd: React.FC<Props> = ({ handleChange, max }) => {
    const { register, control, getValues } = useForm<FieldValues>();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'text',
    });
    const intl = useIntl();

    React.useEffect(() => {
        append({ example: '' });
    }, []);

    const watchExamples = useWatch({
        name: 'text',
        control,
    });

    React.useEffect(() => {
        const controlledFields = fields.map(index => {
            return getValues(`text[${index}].example`);
        });

        handleChange(controlledFields);
    }, [watchExamples]);

    const handleAdd = () => {
        append({ example: '' });
    };

    return (
        <ContainerInput>
            {max && fields.length < max && (
                <div className="icon-add" onClick={handleAdd}>
                    <VscAdd />
                </div>
            )}

            {!max && (
                <div className="icon-add" onClick={handleAdd}>
                    <VscAdd />
                </div>
            )}
            <div style={{ width: '100%' }}>
                {fields.map((field, index) => (
                    <div
                        key={field.id}
                        className={`content-input ${
                            index === 0 ? 'first' : ''
                        }`}
                    >
                        <div
                            className="icon-count"
                            onClick={() => remove(index)}
                        >
                            {index + 1}
                        </div>
                        <Input
                            key={field.id}
                            label={`${routeTranslate(intl, 'examples.label')}`}
                            placeholder={`${routeTranslate(
                                intl,
                                'examples.placeholder',
                            )}`}
                            register={register}
                            id={`text[${index}].example`}
                            name={`text[${index}].example`}
                        />
                        {fields.length > 1 && (
                            <div
                                className="icon-remove"
                                onClick={() => remove(index)}
                            >
                                <IoMdClose />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </ContainerInput>
    );
};

export default InputAdd;
