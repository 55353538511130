import React from "react";
import { FadeBackground, ModalContainer, ModalContent } from "./styles";
import { Fade } from '@mui/material';
import { IconTrash, IconX } from '@tabler/icons-react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import useCollectionsContext from 'app/presentation/hooks/useCollectionsContext';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import { RowContent } from "app/presentation/providers/CollectionsProvider";

type ModalDeleteRowProps = {
    animate: boolean;
    setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setAnimateDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    rowContent: RowContent;
    contentId: number;
}

export const ModalDeleteRow: React.FC<ModalDeleteRowProps> = (props) => {
    const { animate, setShowDeleteModal, setAnimateDeleteModal, rowContent, contentId } = props;
    const { enqueueSnackbar: toast } = useSnackbar();
    const { removeCollection, fetchAllAndSetCollections } = useCollectionsContext();
    const { updateMemory } = useMemoriesContext();

    const handleDelete = async () => {
        const handlerFunc = (() => {
            if (rowContent == RowContent.Collection) return removeCollection(contentId);
            return updateMemory(contentId, [], -1);
        })

        // TODO maybe not refetch everything and just delete internally
        try {
            await handlerFunc();
            toast("sucess", { variant: "success" });
            await fetchAllAndSetCollections();
        } catch (err: any) {
            err instanceof Error ? toast(err.message, { variant: "error" }) : toast(err.message, { variant: "error" })
        } finally {
            setAnimateDeleteModal(false);
        }

    }

    return (
        <>
            <Fade in={animate} timeout={300} onExited={() => setShowDeleteModal(false)}>
                <FadeBackground>
                    <ModalContainer>
                        <ModalContent>
                            <div className="header-line">
                                <h1>
                                    {rowContent == "collection" ?
                                        (<FormattedMessage id="brand-center.collections.modals.delete.remove?" />) :
                                        (<FormattedMessage id="brand-center.knowledge.modal-delete.delete?" />)
                                    }
                                </h1>

                                <button onClick={() => setAnimateDeleteModal(false)} >
                                    <IconX stroke={1.5} size={22} />
                                </button>
                            </div>

                            <div className="span-line">
                                <span>
                                    {rowContent == "collection" ?
                                        (<FormattedMessage id="brand-center.collections.modals.delete.body" />) :
                                        (<FormattedMessage id="brand-center.knowledge.modal-delete.text" />)
                                    }

                                </span>
                            </div>

                            <div className="btn-line">
                                <button className="cancel" onClick={() => setAnimateDeleteModal(false)}>
                                    <span>
                                        <FormattedMessage id="button.cancel" />
                                    </span>
                                </button>

                                <button className="danger" onClick={() => handleDelete()}>
                                    <IconTrash stroke={1.5} size={18} />
                                    <span>
                                        {rowContent == "collection" ?
                                            (<FormattedMessage id="brand-center.collections.modals.delete.btn-exclude" />) :
                                            (<FormattedMessage id="brand-center.knowledge.modal-delete.button-delete" />)
                                        }

                                    </span>

                                </button>
                            </div>
                        </ModalContent>
                    </ModalContainer>
                </FadeBackground>
            </Fade>
        </>
    )
}
