import React from 'react';
import { ContentContainer, MuiMenuHeader } from './styles';
import useUserConfig from '../../../hooks/useUserConfigs';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { FormattedMessage, useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import { MemoriesServices } from 'services/brand/memories';
import { Menu, MenuList, Paper } from '@mui/material';
import {
    MemoriesGroup,
    MemorieItemObj,
    MemorieItem,
} from 'app/presentation/hooks/interfaces/brand';
import { Search } from '../../icons';
import { BsCircle } from 'react-icons/bs';
import DoneIcon from '@mui/icons-material/Done';
import { CheckBox } from 'app/presentation/components/mui';
import { FiAlignLeft, FiChevronDown } from 'react-icons/fi';
import { TbDiscountCheck } from 'react-icons/tb';
import { Link } from 'react-router-dom';

interface Props {
    setContent: (value: MemorieItem, key?: string) => void;
    fieldKey?: string;
    setFieldKey?: (value: string) => void;
    positon?: 'top' | 'bottom';
    setItemsMemories?: (value: MemorieItemObj) => void;
}

const memoriesService = new MemoriesServices();

const DropdownMemories: React.FC<Props> = ({
    setContent,
    fieldKey,
    setFieldKey,
    positon = 'bottom',
    setItemsMemories,
}) => {
    const { workspace, project, activeBrand } = useUserConfig();
    const { darkmode } = useDarkMode();
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [memoriesGroup, setMemoriesGroup] = React.useState<
        Array<MemoriesGroup> | undefined
    >();
    const [memorieItem, setMemorieItem] = React.useState<
        MemorieItemObj | undefined
    >();
    const [opeMenuTable, setOpenMenuTable] = React.useState(false);
    const ids = memoriesGroup?.map(item => item.id);
    const [selectedOption, setSelectedOption] = React.useState<
        MemorieItem | undefined
    >();
    const [searchInputValue, setSearchInputValue] = React.useState('');
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = React.useState(false);
    const [startX, setStartX] = React.useState(0);
    const [scrollLeft, setScrollLeft] = React.useState(0);
    const [styleFilterId, setStyleFilterId] = React.useState(0);

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        setStartX(event.pageX - scrollRef.current!.offsetLeft);
        setScrollLeft(scrollRef.current!.scrollLeft);
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging) return;
        event.preventDefault();
        const x = event.pageX - scrollRef.current!.offsetLeft;
        const scroll = x - startX;
        scrollRef.current!.scrollLeft = scrollLeft - scroll;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const getList = async () => {
        const result = await memoriesService.getAllGroups(
            workspace.id,
            project.id,
        );

        if (result instanceof Error) {
            return;
        }

        setMemoriesGroup(result);
    };

    React.useEffect(() => {
        getList();
    }, [activeBrand]);

    React.useEffect(() => {
        if (fieldKey && setFieldKey) {
            setFieldKey(fieldKey);
        }
    }, [fieldKey]);

    const getMemoriesItems = async () => {
        if (activeBrand && ids) {
            const result = await memoriesService.getMemories(
                workspace.id,
                project.id,
                activeBrand.id,
                ids,
            );

            if (result instanceof Error) {
                return;
            }

            setMemorieItem(result);
            if (setItemsMemories) {
                setItemsMemories(result);
            }
        }
    };

    React.useEffect(() => {
        if (memoriesGroup && activeBrand) {
            getMemoriesItems();
        }
    }, [memoriesGroup, activeBrand]);

    const handleCloseMenuTable = () => {
        setAnchorEl(null);
        setOpenMenuTable(false);
        setSelectedOption(undefined);
        setSearchInputValue('');
        setStyleFilterId(0);
    };

    const handleClickMenuTable = (event: React.MouseEvent<HTMLLIElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuTable(true);
    };

    const handleCheckboxChange = (option: MemorieItem | undefined) => {
        setSelectedOption(prevOption =>
            prevOption?.id === option?.id ? undefined : option,
        );
    };

    const handleApply = () => {
        if (selectedOption) {
            setContent(selectedOption, fieldKey);
            handleCloseMenuTable();
        }
    };

    return (
        <ContentContainer className={darkmode ? 'dropdownDark' : ''}>
            {activeBrand ? (
                <li onClick={handleClickMenuTable}>
                    <TbDiscountCheck size={18} />
                    {routeTranslate(intl, 'brand.memories')}
                    <FiChevronDown
                        className={opeMenuTable ? 'btnInvert' : ''}
                    />
                </li>
            ) : (
                <p className="withoutBrandText">
                    <FormattedMessage id="brand.createnew.dropdown" />
                    <Link
                        to={`/${routeTranslate(
                            intl,
                            'route.urlLang',
                        )}${routeTranslate(
                            intl,
                            'route.backoffice',
                        )}${routeTranslate(intl, 'route.brand-center')}`}
                    >
                        <FormattedMessage id="brand.createnew.dropdown.span" />
                    </Link>
                </p>
            )}

            <Menu
                id="basic-menu-workspace"
                anchorEl={anchorEl}
                open={opeMenuTable}
                onClose={handleCloseMenuTable}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    width: '100%',
                    height: '100%',
                    maxHeight: '364px',
                    '& > .MuiPaper-root': {
                        boxShadow: '8px 16px 32px rgba(113, 128, 150, 0.08);',
                        borderRadius: '12px',
                        background: darkmode ? '#1d1d1f' : '',
                    },
                }}
                anchorOrigin={{
                    vertical: 40,
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: positon === 'bottom' ? 'top' : 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper
                    sx={{
                        width: 400,
                        boxShadow: 0,
                        padding: '0px 16px',
                        borderRadius: '12px',
                        background: darkmode ? '#1d1d1f' : '',
                    }}
                >
                    <MenuList
                        sx={{
                            padding: 0,
                            boxShadow: 0,
                            background: darkmode ? '#1d1d1f' : '',
                        }}
                        disablePadding
                        dense
                    >
                        <MuiMenuHeader className={darkmode ? 'muiDark' : ''}>
                            <div className="input">
                                <div className="icon">
                                    <Search darkmode={darkmode} />
                                </div>
                                <input
                                    id="search"
                                    type="text"
                                    placeholder={routeTranslate(
                                        intl,
                                        'label.search.nameCategory',
                                    )}
                                    onChange={e =>
                                        setSearchInputValue(e.target.value)
                                    }
                                />
                            </div>

                            <div
                                className="scrollFilters"
                                ref={scrollRef}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                            >
                                <div className="style-filters">
                                    <button
                                        className={`filterButton ${
                                            styleFilterId === 0 &&
                                            'selectedFilter'
                                        }`}
                                        onClick={() => setStyleFilterId(0)}
                                    >
                                        {routeTranslate(
                                            intl,
                                            'arteai.filters.all',
                                        )}
                                    </button>

                                    {memoriesGroup &&
                                        memoriesGroup.map(filter => {
                                            return (
                                                <button
                                                    className={`filterButton ${
                                                        styleFilterId ===
                                                            filter.id &&
                                                        'selectedFilter'
                                                    }`}
                                                    onClick={() =>
                                                        setStyleFilterId(
                                                            filter.id,
                                                        )
                                                    }
                                                >
                                                    {
                                                        filter.translations[0]
                                                            ?.title
                                                    }
                                                </button>
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="list-itens-container">
                                {memoriesGroup &&
                                    memorieItem &&
                                    memoriesGroup
                                        .filter(f =>
                                            styleFilterId === 0
                                                ? true
                                                : f.id === styleFilterId,
                                        )
                                        .map(item => {
                                            const memories =
                                                memorieItem[item.id];

                                            const hasItem =
                                                (memories &&
                                                    memories.filter(
                                                        f =>
                                                            typeof f.content ===
                                                                'string' &&
                                                            f.content?.trim() !==
                                                                '',
                                                    ).length > 0) ||
                                                false;

                                            return (
                                                <div
                                                    className="memorie-group-item"
                                                    style={{
                                                        display: hasItem
                                                            ? undefined
                                                            : 'none',
                                                    }}
                                                >
                                                    <p className="titleGroup">
                                                        {item.translations[0].title.toUpperCase()}
                                                    </p>

                                                    {memories &&
                                                        memories
                                                            .filter(
                                                                f =>
                                                                    typeof f.content ===
                                                                        'string' &&
                                                                    f.content?.trim() !==
                                                                        '',
                                                            )
                                                            .filter(f =>
                                                                f.title
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        searchInputValue.toLowerCase(),
                                                                    ),
                                                            )
                                                            .map(memorie => {
                                                                const isChecked =
                                                                    memorie.id ===
                                                                    selectedOption?.id;
                                                                return (
                                                                    <div
                                                                        className={`memorie-itens-list
                                                                ${
                                                                    isChecked &&
                                                                    'checkedItem'
                                                                }
                                                            `}
                                                                    >
                                                                        <CheckBox
                                                                            checked={
                                                                                isChecked
                                                                            }
                                                                            icon={
                                                                                <BsCircle
                                                                                    size={
                                                                                        22
                                                                                    }
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <DoneIcon
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            '#d0ff61',
                                                                                        borderRadius: 9999,
                                                                                    }}
                                                                                />
                                                                            }
                                                                            sx={{
                                                                                color: '#E2E8F0',
                                                                                '&.Mui-checked':
                                                                                    {
                                                                                        color: '#1A202C',
                                                                                    },
                                                                            }}
                                                                            onChange={() =>
                                                                                handleCheckboxChange(
                                                                                    memorie,
                                                                                )
                                                                            }
                                                                        />
                                                                        <FiAlignLeft
                                                                            size={
                                                                                18
                                                                            }
                                                                        />
                                                                        <p>
                                                                            {
                                                                                memorie.title
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                );
                                                            })}
                                                </div>
                                            );
                                        })}
                            </div>

                            <div className="footer">
                                <button
                                    onClick={handleApply}
                                    disabled={selectedOption === undefined}
                                >
                                    {routeTranslate(intl, 'button.apply')}
                                </button>
                            </div>
                        </MuiMenuHeader>
                    </MenuList>
                </Paper>
            </Menu>
        </ContentContainer>
    );
};

export default DropdownMemories;
