import { Button } from 'app/presentation/components';
import { ContainerContent } from './styles';
import { HiOutlinePlus } from 'react-icons/hi2';
import { CollectionsTable } from "./components";
import { FormattedMessage } from 'react-intl';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import React from 'react';
import { useIntl } from 'react-intl';
import { routeTranslate as t } from 'utils/intl';
import useCollectionsContext from 'app/presentation/hooks/useCollectionsContext';
import { enqueueSnackbar as toast } from 'notistack';
import { Language } from 'services/brand/types';
import useLanguage from 'app/presentation/hooks/useLanguage';

const Collections: React.FC = () => {
    const { activeBrand: brand } = useUserConfig();
    const { createCollection, selectedLanguage, setSelectedLanguage, focusedElement } = useCollectionsContext();
    const intl = useIntl();
    const { getLanguageName } = useLanguage();

    // maybe add load animation?

    const handleCreateCollection = async () => {
        if (!brand) return;

        const defaultTitle = t(intl, "brand-center.collections.default.collection-name");

        try {
            await createCollection(defaultTitle);
        } catch (err: any) {
            err instanceof Error ?
                toast(err.message, { variant: "error" }) :
                toast(t(intl, "commons.something-went-wrong"), { variant: "error" })
        }
    }

    const handleSupportedLanguages = (): Language[] => {
        if (!brand || !brand.supported_languages) return [];

        const idx = brand.supported_languages.findIndex(language => language.language_id == brand.brand_config.default_language);
        if (idx == 0) return brand.supported_languages.map(brandLanguage => brandLanguage.language);

        const [item] = brand.supported_languages.splice(idx, 1);
        brand.supported_languages.unshift(item);

        return brand.supported_languages.map(brandLanguage => brandLanguage.language);
    }

    return (
        <ContainerContent>
            <div className="title">
                <h3>
                    <FormattedMessage id="collections.title" />
                    <span>
                        <FormattedMessage id="collections.description" />
                    </span>
                </h3>
                <Button
                    onClick={handleCreateCollection}
                    iconPositon="start"
                    icon={<HiOutlinePlus size={20} />}
                >
                    <FormattedMessage id="collections.btn.new" />
                </Button>
            </div>

            <div className="language-selector">
                {handleSupportedLanguages().map(language => (
                    <p
                        className={language.id == selectedLanguage ? "selected" : ""}
                        onClick={() => setSelectedLanguage(language.id)}
                    >
                        {getLanguageName(language)}
                        {` (${language.lang})`}
                    </p>
                ))}
            </div>

            <CollectionsTable
                key={"collections-table"}
                selectedLanguage={selectedLanguage}
                focusedElement={focusedElement}
            />
        </ContainerContent>
    );
};

export default Collections;
