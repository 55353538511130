import styled from 'styled-components';

export const ContainerImg = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    .title-up {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            color: #000;
            font-weight: 700;
            display: flex;
            align-items: center;
            span {
                margin-right: 6px;
                transition-duration: 0.5s;
            }
        }
    }

    .iconDropdownClose {
        transform: rotate(-180deg);
    }

    .drop-content {
        transition: height 0.5s ease-in;
    }

    .btn-file {
        display: flex;
        margin-top: 12px;
        label {
            border-radius: 6px;
            border: 1px solid #e2e8f0;
            background: #f7fafc;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 16px;
            p {
                text-align: center;
                color: #7e7e7e;
                font-weight: 600;
                font-size: 14px;
            }
        }
        input {
            display: none;
        }
    }

    .upload-span {
        cursor: pointer;
    }

    &.btn-dark {
        .btn-file {
            label {
                background: #000;
            }
        }
    }
`;

export const UploadedImgContainer = styled.div`
    display: flex;
    flex-direction: column;

    .img-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
            cursor: pointer;
        }
    }

    .slider-container {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        .text {
            display: flex;
            justify-content: space-between;
            p {
                font-size: 0.75rem;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }
    }

    .container-img {
        width: 100%;
        max-height: 120px;
        overflow: hidden;
        margin-top: 12px;
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 0;

        label {
            cursor: pointer;
            position: relative;
            svg {
                position: absolute;
                z-index: 100;
                top: 42%;
                left: 42%;
                opacity: 0;
            }

            img {
                position: absolute;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            &:hover {
                svg {
                    opacity: 1;
                }
            }
        }
    }

    p {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #000000;

        span {
            display: flex;
            align-items: center;
            margin-right: 6px;
            transition-duration: 0.5s;
        }

        .iconDropdownClose {
            transform: rotate(-180deg);
        }
    }

    &.UploadedImgContainerDark {
        .container-img {
            background: #000;
            border-color: #000;
        }
    }
`;
