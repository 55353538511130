import React from 'react';
import { Send } from '../styles';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

import { FiUploadCloud, FiYoutube } from 'react-icons/fi';
import { routeTranslate } from 'utils/intl';
import { useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'app/presentation/components';
import { BsArrowRight } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';

interface ISendView {
    setStep: () => void;
    file: File | undefined;
    setFile: (value: File | undefined) => void;
    setVideoLink: (value: string) => void;
    selected: 'link' | 'file';
    setSelected: (value: 'link' | 'file') => void;
    videoLink: string;
}

const SendView: React.FC<ISendView> = ({
    setStep,
    file,
    setFile,
    setVideoLink,
    selected,
    setSelected,
    videoLink,
}) => {
    const { darkmode } = useDarkMode();

    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();

    const handleChange = (e: any) => {
        if (e.target.files[0]) {
            const selectedFile = e.target.files[0];
            const audioMimeTypes = [
                'audio/mpeg', // MP3
                'audio/wav', // WAV
                'audio/ogg', // OGG
                'audio/flac', // FLAC
                'audio/aac', // AAC
                'audio/x-m4a', // M4A
                'audio/webm', // WebM
                'audio/amr', // AMR
                'audio/x-ms-wma', // WMA (Windows Media Audio)
                'audio/x-aiff', // AIFF
                'audio/x-pn-realaudio', // RealAudio
            ];

            if (audioMimeTypes.includes(selectedFile.type)) {
                // It's an audio file
                setFile(selectedFile);
            } else {
                // It's not an audio file
                enqueueSnackbar(
                    routeTranslate(intl, 'modal.brand.fileType.error'),
                    {
                        variant: 'error',
                    },
                );
                // Optionally, you can clear the file input or handle the error in some way
                e.target.value = null;
            }
        }
    };

    const clearInputs = () => {
        const videoLinkInput = document.querySelector(
            'input[name="videoLink"]',
        ) as HTMLInputElement | null;
        const fileInput = document.querySelector(
            'input[name="audioUpload"]',
        ) as HTMLInputElement | null;

        if (videoLinkInput) {
            videoLinkInput.value = ''; // Clear the text input
            setVideoLink('');
        }

        if (fileInput) {
            fileInput.value = ''; // Clear the file input by setting it to an empty string
            setFile(undefined);
        }
    };

    return (
        <Send className={darkmode ? 'darkSend' : ''}>
            <div
                className={`bigButton ${selected === 'link' && 'activeBig'}`}
                onClick={() => setSelected('link')}
            >
                <div className="icon">
                    <FiYoutube size={24} />
                </div>
                <div className="textArea">
                    <p>
                        <FormattedMessage id="audioai.step1.url" />
                    </p>
                    <input
                        type="text"
                        name="videoLink"
                        onChange={e => setVideoLink(e.target.value)}
                        placeholder="https://www.youtube.com/watch?v=jd..."
                    />
                </div>
            </div>
            <label htmlFor="audioUpload">
                <div
                    className={`bigButton bigDashed ${
                        selected === 'file' && 'activeBig'
                    }`}
                    onClick={() => setSelected('file')}
                >
                    <div className="icon">
                        <FiUploadCloud size={24} />
                    </div>
                    <div className="textArea">
                        <p>
                            <FormattedMessage id="audioai.step1.file" />
                        </p>

                        <span>
                            {file && file.name ? (
                                `${file.name} / ${(
                                    file.size /
                                    (1024 * 1024)
                                ).toFixed(2)} MB`
                            ) : (
                                <FormattedMessage id="audioai.step1.file.desc" />
                            )}
                        </span>
                    </div>
                </div>
            </label>
            <input
                name="audioUpload"
                id="audioUpload"
                type="file"
                accept="audio/*"
                onChange={handleChange}
            />

            <div className="buttons">
                <Button
                    className="cleanField"
                    id="clean"
                    icon={<IoMdClose />}
                    iconPositon="start"
                    style={{
                        background: darkmode ? '#1d1d1f' : '',
                        borderColor: darkmode ? '#1d1d1f' : '',
                        color: darkmode ? '#fff' : '',
                    }}
                    onClick={clearInputs}
                >
                    <FormattedMessage id="button.clear.fields" />
                </Button>

                <Button
                    className="nextButton"
                    onClick={setStep}
                    icon={<BsArrowRight />}
                    disabled={
                        (selected === 'link' && videoLink.length === 0) ||
                        (selected === 'file' && file === undefined)
                    }
                >
                    <FormattedMessage id="button.next" />
                </Button>
            </div>
        </Send>
    );
};

export default SendView;
