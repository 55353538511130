import styled from 'styled-components';

export const YtPlayer = styled.div`
    width: 100%;
    position: relative;

    .buttonPlay,
    .buttonMute {
        position: absolute;
        z-index: 9999;
        border: none;
        background-color: #fff;
        cursor: pointer;
    }

    .buttonPlay {
        top: 85px;
        left: 40px;
    }

    .buttonMute {
        top: 85px;
        right: 60px;
    }

    .rhap_container {
        border-radius: 27px;
        border: 1px solid #cbd5e0;
        background: #fff;
        box-shadow: none;
        padding: 20px 34px;

        .rhap_main {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-around;
            padding: 0;
            position: relative;

            .rhap_controls-section {
                margin: 0;
                flex: 0;
            }

            .rhap_progress-section {
                flex: 1;
            }

            .rhap_play-pause-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                margin-right: 20px;
            }
            .rhap_additional-controls,
            .rhap_forward-button,
            .rhap_rewind-button {
                display: none;
            }

            .rhap_volume-container {
                position: absolute;
                right: 0 !important;
                svg {
                    color: #000;
                }

                .rhap_volume-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 18px;
                    height: 18px;
                }
                .rhap_volume-bar-area {
                    display: none;
                }
            }
        }

        .rhap_time {
            font-size: 0.75rem;
            font-weight: 600;
        }

        .rhap_current-time {
            color: #000;
        }

        .rhap_total-time {
            color: #a09fa5;
            margin-right: 71px;
        }

        .rhap_progress-container {
            margin: 0 19px;
            .rhap_progress-bar {
                .rhap_progress-filled {
                    background: #000;
                }
                .rhap_progress-indicator {
                    display: none;
                }
            }
        }
    }
`;
