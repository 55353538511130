import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneIcon from '@mui/icons-material/Done';
import { Copy, Receipt, Trash } from 'app/presentation/components/icons';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import useUserConfigs from 'app/presentation/hooks/useUserConfigs';
import React from 'react';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { TbArrowsDownUp } from 'react-icons/tb';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { routeTranslate } from '../../../../../utils/intl';
import useDocuments from '../../../hooks/useDocuments';
import { CheckBox } from '../../index';
import { Document } from '../../interfaces/document-props.interface';
import { TableStyle } from './styles';

const OrderIcon = () => {
    const { darkmode } = useDarkMode();

    return (
        <TbArrowsDownUp
            className="order"
            style={{ color: darkmode ? '#A09FA5' : '#718096' }}
            size={16}
        />
    );
};

const CheckBoxCustom = (props: any) => {
    const { darkmode } = useDarkMode();

    return (
        <CheckBox
            {...props}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={
                <DoneIcon
                    style={{
                        backgroundColor: '#d0ff61',
                        borderRadius: 1000,
                        color: darkmode ? '#1A202C' : '',
                    }}
                />
            }
            sx={{
                color: '#E2E8F0',
                '&.Mui-checked': {
                    color: '#1A202C',
                },
            }}
        />
    );
};

interface Props {
    data: Document[] | null[];
    menuOptions: (
        event?: React.MouseEvent<HTMLDivElement>,
        id?: number,
    ) => void;
    limit?: number;
    selectedDocuments: Array<{ id: number | undefined; title: string }>;
    SetSelectedDocuments: (
        data: Array<{ id: number | undefined; title: string }>,
    ) => void;
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const TableDocuments: React.FC<Props> = ({
    data,
    menuOptions,
    limit,
    selectedDocuments,
    SetSelectedDocuments,
}) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const { darkmode } = useDarkMode();
    const darkColor = '#A09FA5';

    const { documents, copyDocument, deleteDocument } = useDocuments();
    const { workspace } = useUserConfigs();

    const handleEdit = (id?: number) => {
        if (!documents) return;

        const document = documents.find(document => document.id === id);

        if (!document || !id) return;

        const path =
            routeTranslate(intl, 'route.urlLang') +
            routeTranslate(intl, 'route.backoffice');

        navigate(
            `/${path}${routeTranslate(intl, 'route.documents')}${routeTranslate(
                intl,
                'route.document',
            )}/${document.rtc_id}/${id}`,
        );
    };

    const handleCopy = async () => {
        const data = selectedDocuments.map(doc => {
            return {
                id: doc.id,
                title: `${routeTranslate(intl, 'document.copy.text')} ${doc.title
                    }`,
            };
        });
        await copyDocument(workspace.id, data);
    };

    const handleDelete = async () => {
        await selectedDocuments.map(doc => {
            deleteDocument(doc.id);
        });
        SetSelectedDocuments([]);
    };

    const handleSelectDoc = (props: any, isSelected: boolean) => {
        if (!isSelected) {
            SetSelectedDocuments([...selectedDocuments, props]);
        } else {
            const filteredData = selectedDocuments.filter(
                doc => doc.id !== props.id,
            );
            SetSelectedDocuments(filteredData);
        }
    };

    const handleSelectAllDocs = (e: any) => {
        const checked = e.target.checked;
        if (!checked) {
            SetSelectedDocuments([]);
        } else {
            const docs = data.map(doc => {
                return { id: doc?.id, title: doc?.title || '' };
            });
            SetSelectedDocuments(docs);
        }
    };

    return (
        <>
            <TableStyle>
                <thead>
                    <tr>
                        <th>
                            <CheckBoxCustom
                                onChange={(e: any) => handleSelectAllDocs(e)}
                            />
                        </th>

                        <th style={{ color: darkmode ? darkColor : '' }}>
                            {selectedDocuments.length === 0 ? (
                                <>
                                    {routeTranslate(
                                        intl,
                                        'home.documents.name',
                                    )}
                                    <OrderIcon />
                                </>
                            ) : (
                                <div className="optionsFromSelected">
                                    <p>
                                        {selectedDocuments.length}{' '}
                                        {routeTranslate(
                                            intl,
                                            'document.qt.selected',
                                        )}
                                    </p>

                                    <div
                                        onClick={() => handleCopy()}
                                        className="icon"
                                    >
                                        <Copy
                                            darkmode={darkmode}
                                            hideTooltip={false}
                                        />
                                    </div>

                                    <div
                                        onClick={() => handleDelete()}
                                        className="icon"
                                    >
                                        <Trash
                                            darkmode={darkmode}
                                            hideTooltip={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </th>
                        <th
                            style={{
                                color: darkmode ? darkColor : '',
                                opacity:
                                    selectedDocuments.length === 0 ? '' : 0,
                            }}
                        >
                            {routeTranslate(intl, 'home.documents.createBy')}
                            <OrderIcon />
                        </th>
                        <th
                            style={{
                                color: darkmode ? darkColor : '',
                                opacity:
                                    selectedDocuments.length === 0 ? '' : 0,
                            }}
                        >
                            {routeTranslate(intl, 'home.documents.modify')}{' '}
                            <OrderIcon />
                        </th>
                        <th
                            style={{
                                color: darkmode ? darkColor : '',
                                opacity:
                                    selectedDocuments.length === 0 ? '' : 0,
                            }}
                        >
                            <div className="action">
                                {routeTranslate(intl, 'home.documents.actions')}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((document, index) => {
                        if (!document) return;

                        const docData = {
                            id: document.id,
                            title: document.title,
                        };

                        const isSelected = Boolean(
                            selectedDocuments.filter(
                                doc => doc.id === document.id,
                            ).length,
                        );

                        if (limit && limit < index + 1) return;

                        const date = new Date(document.updated_at);
                        const month = date.toLocaleString('default', {
                            month: 'short',
                        });
                        const year = date.toLocaleString('default', {
                            year: 'numeric',
                        });
                        const day = date.toLocaleString('default', {
                            day: 'numeric',
                        });

                        const dateString = `${capitalizeFirstLetter(
                            month.replace(/\.$/, ''),
                        )} ${day}, ${year}`;

                        return (
                            <tr key={index}>
                                <td>
                                    <CheckBoxCustom
                                        onClick={() =>
                                            handleSelectDoc(docData, isSelected)
                                        }
                                        checked={isSelected}
                                    />
                                </td>
                                <td onClick={() => handleEdit(document.id)}>
                                    <div className="name-table">
                                        <div className="icon">
                                            <Receipt />
                                        </div>
                                        <span className="dark-text-white">
                                            {document.title}
                                        </span>
                                    </div>
                                </td>
                                <td
                                    className="dark-text-white"
                                    onClick={() => handleEdit(document.id)}
                                >
                                    {document.creator?.first_name}
                                </td>
                                <td
                                    className="dark-text-white"
                                    onClick={() => handleEdit(document.id)}
                                >
                                    {dateString}
                                </td>
                                <td>
                                    <div className="action">
                                        <div
                                            className="dots"
                                            onClick={e => { menuOptions(e, document.id); }}
                                        >
                                            <HiOutlineDotsHorizontal
                                                size={22}
                                                style={{ color: '#718096' }}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </TableStyle>
        </>
    );
};

export default TableDocuments;
