import React from 'react';
import { Button, Container, Input } from '../../../app/presentation/components';
import { Header, DocumentsContainer, ModelsContainer } from './styles';
import { FieldValues, useForm } from 'react-hook-form';
import {
    NewModel,
    Play,
    Receipt,
    Search,
} from '../../../app/presentation/components/icons';
import { HiArrowNarrowRight, HiOutlineDotsHorizontal } from 'react-icons/hi';
import TableModels from '../../../app/presentation/components/dashboard/TableModels';
import { routeTranslate } from '../../../utils/intl';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import useDocuments from '../../../app/presentation/hooks/useDocuments';

const Models: React.FC = () => {
    const { register } = useForm<FieldValues>();

    const { documents } = useDocuments();
    const intl = useIntl();

    return (
        <Container>
            <Header>
                <h1>Modelos</h1>
                <form>
                    <div className="input">
                        <div className="icon">
                            <Search />
                        </div>
                        <Input
                            id="search"
                            placeholder="Procurar modelo"
                            register={register}
                        />
                    </div>
                </form>
                <div id="desc">
                    <h2>Comece com um modelo em destaque</h2>
                    <p>
                        Copiar um modelo de exemplo pode ajudá-lo a criar seu
                        próprio modelo. Ou confira{' '}
                        <span>modelos da comunidade.</span>
                    </p>
                </div>
                <div id="options">
                    <Link
                        to={`/${routeTranslate(
                            intl,
                            'route.urlLang',
                        )}${routeTranslate(
                            intl,
                            'route.backoffice',
                        )}${routeTranslate(
                            intl,
                            'route.models',
                        )}${routeTranslate(intl, 'route.newModel')}`}
                    >
                        <Button icon={<NewModel />}>Novo modelo</Button>
                    </Link>
                </div>
            </Header>
            <ModelsContainer>
                <div className="box">
                    <div className="image"></div>
                    <h1>E-mail frio</h1>
                    <p>
                        Escreva o cold email perfeito usando o modelo AIDA
                        (Atenção, Interesse, Desej...
                    </p>
                    <Button icon={<HiArrowNarrowRight />}>Abrir Modelo</Button>
                </div>
                <div className="box">
                    <div className="image"></div>
                    <h1>Postagem do blog</h1>
                    <p>
                        Prepare um post rápido no blog em 5 minutos usando este
                        modelo.
                    </p>
                    <Button icon={<HiArrowNarrowRight />}>Abrir Modelo</Button>
                </div>
                <div className="box">
                    <div className="image"></div>
                    <h1>🏭 A Fábrica de Ideias</h1>
                    <p>
                        Comece cada projeto da maneira mais fácil, gerando
                        rapidamente ótimas ideias..
                    </p>
                    <Button icon={<HiArrowNarrowRight />}>Abrir Modelo</Button>
                </div>
                <div className="box">
                    <div className="image"></div>
                    <h1>Anúncio do Facebook</h1>
                    <p>
                        Escreva um anúncio atraente no Facebook usando a
                        estrutura PAS (Problema, Agita...
                    </p>
                    <Button icon={<HiArrowNarrowRight />}>Abrir Modelo</Button>
                </div>
                <div className="box">
                    <div className="image"></div>
                    <h1>E-mail frio</h1>
                    <p>
                        Escreva o cold email perfeito usando o modelo AIDA
                        (Atenção, Interesse, Desej...
                    </p>
                    <Button icon={<HiArrowNarrowRight />}>Abrir Modelo</Button>
                </div>
            </ModelsContainer>
            <DocumentsContainer>
                <header>
                    <h1>Seus Modelos</h1>
                </header>
                <div className="box">
                    <div className="header">
                        <div className="icon">
                            <Receipt />
                        </div>
                        <div className="flex">
                            <h1>Cópia de “🏭 A Fábric...</h1>
                        </div>
                        <div className="play mobile">
                            <button>
                                <Play />
                            </button>
                        </div>
                        <div className="dots">
                            <HiOutlineDotsHorizontal
                                size={22}
                                style={{ color: '#718096' }}
                            />
                        </div>
                    </div>
                    <div className="infos">
                        <div className="info">
                            <strong>Criado por</strong>
                            <span>Eu</span>
                        </div>
                        <div className="info">
                            <strong>Modificado</strong>
                            <span>Out 20, 2022</span>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="header">
                        <div className="icon">
                            <Receipt />
                        </div>
                        <div className="flex">
                            <h1>Cópia de “🏭 A Fábric...</h1>
                        </div>
                        <div className="play mobile">
                            <button>
                                <Play />
                            </button>
                        </div>
                        <div className="dots">
                            <HiOutlineDotsHorizontal
                                size={22}
                                style={{ color: '#718096' }}
                            />
                        </div>
                    </div>
                    <div className="infos">
                        <div className="info">
                            <strong>Criado por</strong>
                            <span>Eu</span>
                        </div>
                        <div className="info">
                            <strong>Modificado</strong>
                            <span>Out 20, 2022</span>
                        </div>
                    </div>
                </div>
                {documents && (
                    <article id="table">
                        <TableModels data={documents} limit={2} />
                    </article>
                )}
            </DocumentsContainer>
        </Container>
    );
};

export default Models;
