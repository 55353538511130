import React, { useMemo } from 'react';
import {
    ContainerModal,
    LabelCustom,
    MuiMenuHeader,
    FieldsTemplate,
    FieldsDocument,
} from './styles';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
    ArrowHistory,
    Document as DocumentIcon,
    EyeLayout,
    Like,
    Share,
    SidebarDocument,
    SpinSave,
    UnLike,
    Doc,
    Pdf,
    Copy,
} from 'app/presentation/components/icons';
import {
    Button,
    Input,
    TextArea,
    Select,
    InputTags,
    SizeOutput,
} from 'app/presentation/components';
import { Editor } from '@tinymce/tinymce-react';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { Menu, MenuList, Paper, useMediaQuery } from '@mui/material';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useSnackbar } from 'notistack';
import { BsCheck } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import EmptyImage from 'assets/images/svg/empty-entries.svg';
import EmptyImageDark from 'assets/images/svg/empty-entries-dark.svg';
import { useIntl } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {
    MethodPlugin,
    SendCompose,
    sizeOutputDocument,
} from 'interfaces/documents.interface';
import { useNavigate, useParams } from 'react-router-dom';
import { Empty } from 'pages/dashboard/library/model/styles';
import InputAdd from 'app/presentation/components/dashboard/InputAdd';
import { routeTranslate } from '../../../../utils/intl';
import useUserConfigs from '../../../../app/presentation/hooks/useUserConfigs';
import useDocuments from '../../../../app/presentation/hooks/useDocuments';
import { DocumentProps } from '../../../../app/presentation/components/interfaces/document-props.interface';
import useTemplates from '../../../../app/presentation/hooks/useTemplates';
import Loading from '../../../../app/presentation/components/common/Loading';
import { timeDifference } from '../../../../utils/time';
import ModalEditHistory from '../../../../app/presentation/components/dashboard/ModalEditHistory';
import { copyToClipboard } from '../../../../utils/copyToClipboard';
import { SaveTemplate } from '../../../../app/presentation/providers/interfaces/templates-props.interface';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { isMacOs } from 'react-device-detect';
import useDocumentTitle from 'utils/useDocumentTitle';

function removeByIndex(str: string, index: number) {
    return str.slice(0, index) + str.slice(index + 1);
}

const Document: React.FC = () => {
    const [enable, setEnabled] = React.useState({
        content: false,
        voice: false,
        word: false,
    });
    const [editor, setEditor] = React.useState<any>(undefined);
    const [htmlTiny, setHtmlTiny] = React.useState('');
    const [layout, setLayout] = React.useState(false);
    const [featureSelected, setFeature] = React.useState(1);
    const [save, setSave] = React.useState(false);
    const [blockCompose, setBlockCompose] = React.useState(false);
    const [blockEdit, setBlockEdit] = React.useState(false);
    const [activeModalHistory, setModalHistory] = React.useState(false);
    const [tags, setTags] = React.useState<string[]>();
    const [textHistory, setTextHistory] = React.useState('');
    const [idHistory, setIdHistory] = React.useState<number>();
    const [tab, setTabs] = React.useState<'history' | 'entries'>('entries');
    const [examples, setExamples] = React.useState<string[]>();
    const [sizeOutput, setSizeOutput] =
        React.useState<sizeOutputDocument>('short');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const editorRef = React.useRef<any>(null);
    const formRef = React.useRef<HTMLFormElement>(null);
    const formAllRef = React.useRef<HTMLFormElement>(null);
    const { darkmode } = useDarkMode();
    const { id: idDocument } = useParams();
    const navigate = useNavigate();

    const {
        document: doc,
        getDocument,
        newDocument,
        updateTitle,
        handleSelectDocument,
        updateContent,
        exportToMsWord,
        exportToPDF,
        getCompose,
        setDocument,
        loading: loadingDocument,
        showLoading: showLoadingDocument,
        closeLoading: closeLoadingDocument,
        saveExtraFields,
    } = useDocuments();

    const {
        getTemplate,
        getTemplates,
        template,
        templates,
        getHistory,
        history,
        entries,
        saveTemplate,
        cleanEntries,
        loading: loadingTemplate,
        languages,
        rateResponse,
    } = useTemplates();
    const { project, settings, setModalAntecipatePlan } = useUserConfigs();
    const { register, watch, reset, getValues, handleSubmit, setValue } =
        useForm<FieldValues>({
            defaultValues: useMemo(() => {
                return doc;
            }, [doc]),
        });

    const isDesktop = useMediaQuery('(min-width: 64em)');
    const { enqueueSnackbar } = useSnackbar();
    const timeout = React.useRef<any>(null);
    const intl = useIntl();

    const open = Boolean(anchorEl);
    const watchDescription = watch('content_description') ?? '';
    const watchVoice = watch('voice_tone') ?? '';
    const watchWord = watch('words') ?? '';
    const watchLanguage = watch('language') ?? '';

    const descriptionField = register('content_description');
    const voiceField = register('voice_tone');
    const tinyLocale = settings.language_id === 2 ? 'en_US' : 'pt_BR';

    const handleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
        id: number | undefined,
    ) => {
        if (!event || !id) return;

        handleSelectDocument(id);
        setAnchorEl(event.currentTarget);
    };

    const resetButton = () => {
        formRef.current?.reset();
    };

    React.useEffect(() => {
        if (editor && doc) {
            setHtmlTiny(doc.content);
        }
    }, [editor]);

    React.useEffect(() => {
        const saveData = async () => {
            const data = await handleNewDocument({
                title: routeTranslate(intl, 'document.new.name'),
            });

            if (!data) return;

            navigate(
                `/${path}${routeTranslate(
                    intl,
                    'route.documents',
                )}${routeTranslate(intl, 'route.document')}/${data.rtc_id}/${
                    data.id
                }`,
                { replace: true },
            );

            reset(data);
        };

        if (!idDocument) {
            saveData().catch(console.error);
        } else {
            setDocument(undefined);

            const fetchData = async (id: number) => {
                return handleGetDocument(id);
            };

            fetchData(+idDocument)
                .then(data => {
                    setTags(data.keywords.split(','));

                    reset(data);

                    setEnabled({
                        word: !!data.keywords,
                        content: !!data.content_description,
                        voice: !!data.voice_tone,
                    });

                    endCommand();
                })
                .catch(console.error);
        }

        const getData = async () => {
            await getTemplates(undefined, undefined);
        };

        getData().catch(() => console.log());

        document.addEventListener(
            'keydown',
            function (e) {
                e = e || window.event;
                const key = e.which || e.keyCode; // keyCode detection
                const ctrl = e.ctrlKey ? e.ctrlKey : key === 17;
                const cmd = e.metaKey ? e.metaKey : false; // Check for Command key on Mac

                if ((key == 13 && ctrl) || (key == 13 && cmd)) {
                    handleToCompose().catch(() => console.log('error'));
                    return false;
                }
            },
            false,
        );
    }, []);

    React.useEffect(() => {
        if (templates && templates.length > 0) {
            setFeature(templates[0].id);
            getTemplate(templates[0].id).catch(console.error);
        }
    }, [templates]);

    React.useEffect(() => {
        if (layout) {
            setValue('quantity-number', '1');
        }
    }, [layout]);

    const handleEditorChange = (content: string) => {
        setHtmlTiny(content);

        const hasTextInSelection = editorRef.current.selection.getContent({
            format: 'text',
        });

        if (hasTextInSelection) {
            setBlockCompose(true);
        } else {
            setBlockCompose(false);
        }

        setSave(true);

        clearTimeout(timeout.current);
        timeout.current = setTimeout(async () => {
            // Salvar
            setSave(false);

            if (!idDocument) return;

            await updateContent(+idDocument, content);
        }, 500);
    };

    const handleContent = () => {
        setEnabled({ ...enable, content: !enable.content });
    };

    const handleVoice = () => {
        setEnabled({ ...enable, voice: !enable.voice });
    };

    const saveFields = async (keywords?: string[]) => {
        if (!idDocument) return;

        const request = {
            content_description: getValues('content_description'),
            voice_tone: getValues('voice_tone'),
            keywords: keywords ? keywords.join(',') : undefined,
        };

        await saveExtraFields(request, +idDocument);
    };

    const handleWord = () => {
        setEnabled({ ...enable, word: !enable.word });
        setTags(undefined);
    };

    const handleNewDocument = async (document: DocumentProps) => {
        try {
            showLoadingDocument();

            if (!idDocument) {
                return await newDocument(document);
            }
        } catch (e: any) {
            enqueueSnackbar('Erro ao cadastrar documento!', {
                variant: 'error',
            });
        } finally {
            closeLoadingDocument();
        }
    };

    const handleGetDocument = async (id: number) => {
        return await getDocument(id);
    };

    const handleSubmitForm: SubmitHandler<FieldValues> = async data => {
        const contentEditor = editorRef.current.getContent();
        await handleNewDocument({ title: data.title, content: contentEditor });
    };

    const handleSubmitFormModel = async () => {
        if (!template || !languages) return;

        try {
            const template_fields_format = template?.template_fields.map(
                fields => ({
                    key: fields.key,
                    value: watch(fields.key),
                }),
            );

            const templateFormat: SaveTemplate = {
                template_id: template.template_id,
                count: Number(watch('quantity-number')),
                project_id: project.id,
                output_language: languages[watchLanguage],
                template_fields: template_fields_format,
            };

            if (template?.has_voice_tone) {
                templateFormat['voice_tone'] = watch('voice');
            }

            if (template?.has_examples) {
                templateFormat['examples'] = examples;
            }

            await saveTemplate(templateFormat);
            resetButton();
        } catch (e: any) {}
    };

    const handleRemoveTag = async (index: number) => {
        if (!tags) return;

        const tagsArray = tags;
        tagsArray.splice(index, 1);

        setTags([...tagsArray]);

        await saveFields([...tagsArray]);
    };
    const selectDocument = null;

    const handleChange = (values: string[]) => {
        setExamples(values);
    };

    const handleCloseModalHistory = () => {
        setModalHistory(false);
    };

    const handleModalHistory = (text: string, id?: number) => {
        setModalHistory(true);
        setTextHistory(text);

        if (id) {
            setIdHistory(id);
        }
    };

    const copyContent = (value: string) => {
        const copy = copyToClipboard(value);
        if (copy) {
            enqueueSnackbar(routeTranslate(intl, 'copy.success'), {
                variant: 'success',
            });
        }
    };

    const handleHistory = async () => {
        setTabs('history');
        await getHistory(Number(template?.template_id));
    };

    const startWebSocket = (websocket: WebSocket) => {
        websocket.onmessage = function (event) {
            setBlockEdit(true);
            try {
                const { data, type, error } = JSON.parse(event.data);
                let complement = '';

                if (error === 'not_enough_word_credits') {
                    setModalAntecipatePlan(true);
                    setBlockEdit(false);
                    return;
                }

                if (error === 'plan_not_enough_rank') {
                    setBlockEdit(false);
                    return;
                }

                if (data === undefined) {
                    console.log('Socket não retornou conteudo.');
                    return;
                }

                if (data === '<br>') {
                    complement = '<br />';
                }

                editorRef.current.insertContent(data + complement, {
                    format: 'raw',
                });

                if (type === 'end') {
                    setBlockEdit(false);
                    enqueueSnackbar('Finalizado!', {
                        variant: 'success',
                    });
                }
            } catch (error) {
                setBlockEdit(false);
            }
        };

        websocket.onclose = function () {
            setBlockEdit(false);
        };

        websocket.onerror = function () {
            setBlockEdit(false);
            enqueueSnackbar(routeTranslate(intl, 'document.error.ws'), {
                variant: 'error',
            });
        };
    };

    const handlePlugin = (
        method: MethodPlugin,
        id: number,
        language: string,
    ) => {
        const hasTextInSelection = editorRef.current.selection.getContent({
            format: 'text',
        });

        if (hasTextInSelection) {
            const requestCompose: SendCompose = {
                prompt: hasTextInSelection,
                document_id: id,
                output_size: sizeOutput,
                method: method,
                output_language: language,
            };

            getCompose(requestCompose).then(websocket => {
                startWebSocket(websocket);
            });
        }
    };

    const handleToCompose = async () => {
        if (!idDocument || !languages) return;

        let contentEditor = editorRef.current.getContent();
        let positionCursor: number;

        const hasText = contentEditor.length > 0;

        if (hasText) {
            const timestamp = new Date().getTime();
            const commandDetectCursor = `<span style="display: none;" data-mce-style="display: none;">:detected_cursor_${timestamp}</span>`;

            editorRef.current.execCommand(
                'mceInsertContent',
                false,
                commandDetectCursor,
            );

            contentEditor = editorRef.current.getContent({ format: 'raw' });

            positionCursor = contentEditor.indexOf(commandDetectCursor);

            const textToCursor = contentEditor.substring(0, positionCursor);
            const stripTags = document.createElement('div');
            stripTags.innerHTML = textToCursor;

            const textToCursorClean = stripTags.innerText;
            let splitContent = textToCursorClean.split(' ');

            splitContent = splitContent.slice(
                Math.max(splitContent.length - 999, 0),
            );

            const joinContent = splitContent.join(' ');

            const method = 'standard' as const;

            const requestCompose: SendCompose = {
                prompt: joinContent,
                output_size: sizeOutput,
                document_id: +idDocument,
                cursor_position: positionCursor,
                method: method,
                output_language: languages[watchLanguage],
            };

            getCompose(requestCompose).then(websocket => {
                startWebSocket(websocket);
            });
        }
    };

    function endCommand() {
        editorRef.current?.focus();
        editorRef.current?.selection.select(editorRef.current?.getBody(), true);
        editorRef.current?.selection.collapse(false);
    }

    const currentPageName = routeTranslate(intl, 'route.documents').replace(
        '/',
        '',
    );
    const pageTitle =
        currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1);
    useDocumentTitle(pageTitle);

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice');

    const handleBack = async () => {
        navigate(-1);
    };

    const checkTextSelected = () => {
        const hasTextInSelection = editorRef.current.selection.getContent({
            format: 'text',
        });

        if (hasTextInSelection) {
            setBlockCompose(true);
        } else {
            setBlockCompose(false);
        }
    };

    const handleSize = (size: sizeOutputDocument) => {
        setSizeOutput(size);
    };

    const handleEdit = (rtc_id?: string, id?: number) => {
        if (!rtc_id || !id) return;

        const path =
            routeTranslate(intl, 'route.urlLang') +
            routeTranslate(intl, 'route.backoffice');

        window.location.href = `/${path}${routeTranslate(
            intl,
            'route.documents',
        )}${routeTranslate(intl, 'route.document')}/${rtc_id}/${id}`;
    };

    const handleEditInDocument = async (text: string) => {
        const request = { title: 'Novo Documento', content: text };
        const documentResponse = await newDocument(request);

        if (documentResponse && documentResponse.id) {
            handleSelectDocument(documentResponse.id);
            handleEdit(documentResponse.rtc_id, documentResponse.id);
        }
    };

    const handleRate = async (resource_usage_id: number, is_good: boolean) => {
        const request = {
            resource_usage_id,
            is_good,
            rewrite_proposal: '',
        };

        await rateResponse(request);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const exportPDF = async () => {
        handleClose();

        if (!selectDocument) return;

        await exportToPDF(selectDocument);
    };

    const exportWord = async () => {
        handleClose();

        if (!selectDocument) return;

        const msWord = await exportToMsWord(selectDocument);

        const nav = window.navigator as any;
        const downloadLink = document.createElement('a');

        if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(msWord.blob, msWord.filename);
        } else {
            document.body.appendChild(downloadLink);
            downloadLink.href = msWord.url;
            downloadLink.download = msWord.filename;
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    };

    const handleAddTextTemplete = (text: string) => {
        const html = text.replace(/\n/g, '<br />');
        editorRef.current.execCommand('mceInsertContent', false, html);
    };

    const handleSearchTemplate = async (search: string) => {
        await getTemplates(undefined, search);
    };

    const handleTags = async (value: string) => {
        setValue('words', value.trim());

        const positionFirstCommomWord = value.indexOf(',');
        if (positionFirstCommomWord === 0) {
            setValue('words', removeByIndex(value, positionFirstCommomWord));
        }

        if (
            value[value.length - 1] === ',' &&
            value[value.length - 2] === ','
        ) {
            setValue('words', value.substring(0, value.length - 1));
        }

        if (value.split(',').length !== 1) {
            if ((tags && tags.length > 2) || value === ',') {
                setValue('words', '');
                return;
            }

            setValue('words', '');

            if (tags) {
                setTags([...tags, value.split(',')[0]]);

                await saveFields([...tags, value.split(',')[0]]);
            } else {
                setTags([value.split(',')[0]]);

                await saveFields([value.split(',')[0]]);
            }
        }
    };

    if (!idDocument || !doc) return <Loading open={true} />;

    return (
        <>
            <ContainerModal
                className={`${
                    blockCompose || blockEdit ? 'plugin-active' : ''
                }`}
            >
                <form
                    onSubmit={handleSubmit(handleSubmitForm)}
                    className={`content-modal ${darkmode && 'darkContent'}`}
                    ref={formAllRef}
                >
                    <input id="document-id" type="hidden" value={idDocument} />
                    <input
                        id="language"
                        type="hidden"
                        value={languages ? languages[watchLanguage] : ''}
                    />
                    <header>
                        <div id="titles">
                            <Tooltip
                                title={routeTranslate(
                                    intl,
                                    'tooltip.allDocuments',
                                )}
                                placement="bottom"
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }}
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-tooltip': {
                                            backgroundColor: darkmode
                                                ? '#fff'
                                                : '#000',
                                            color: darkmode ? '#000 ' : '#fff',
                                        },
                                    },
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10],
                                            },
                                        },
                                    ],
                                }}
                            >
                                <div id="back">
                                    {isDesktop ? (
                                        <HiOutlineArrowNarrowLeft
                                            size={25}
                                            onClick={handleBack}
                                            color={darkmode ? '#fff' : ''}
                                        />
                                    ) : (
                                        <IoIosArrowBack
                                            size={25}
                                            onClick={handleBack}
                                            color={darkmode ? '#fff' : ''}
                                        />
                                    )}
                                </div>
                            </Tooltip>
                            <div id="title-document">
                                <Input
                                    id="title"
                                    name="title"
                                    register={register}
                                    onChange={e => {
                                        register('title')
                                            .onChange(e)
                                            .then(() => {
                                                const saveData = async () => {
                                                    if (!idDocument) return;

                                                    await updateTitle(
                                                        +idDocument,
                                                        e.target.value,
                                                    );
                                                };

                                                setSave(true);

                                                clearTimeout(timeout.current);
                                                timeout.current = setTimeout(
                                                    async () => {
                                                        saveData().catch(
                                                            console.error,
                                                        );

                                                        setSave(false);
                                                    },
                                                    500,
                                                );
                                            });
                                    }}
                                />
                            </div>
                        </div>
                        <div id="change-layout">
                            <Tooltip
                                title={routeTranslate(
                                    intl,
                                    'tooltip.focusMode',
                                )}
                                placement="bottom"
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }}
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-tooltip': {
                                            backgroundColor: darkmode
                                                ? '#fff'
                                                : '#000',
                                            color: darkmode ? '#000 ' : '#fff',
                                        },
                                    },
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10],
                                            },
                                        },
                                    ],
                                }}
                            >
                                <div
                                    onClick={() => setLayout(false)}
                                    className={!layout ? 'active' : ''}
                                >
                                    <EyeLayout
                                        color={
                                            darkmode && layout
                                                ? '#A09FA5'
                                                : '#1A202C'
                                        }
                                    />
                                </div>
                            </Tooltip>

                            <Tooltip
                                title={routeTranslate(
                                    intl,
                                    'tooltip.powerMode',
                                )}
                                placement="bottom"
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }}
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-tooltip': {
                                            backgroundColor: darkmode
                                                ? '#fff'
                                                : '#000',
                                            color: darkmode ? '#000 ' : '#fff',
                                        },
                                    },
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10],
                                            },
                                        },
                                    ],
                                }}
                            >
                                <div
                                    onClick={() => setLayout(true)}
                                    className={layout ? 'active' : ''}
                                >
                                    <SidebarDocument
                                        color={
                                            darkmode && !layout
                                                ? '#A09FA5'
                                                : '#1A202C'
                                        }
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="icons">
                            <div
                                className={`icon save ${
                                    darkmode ? 'darkIcons' : ''
                                }`}
                            >
                                <FiUsers
                                    size={20}
                                    style={{
                                        color: darkmode ? '#CCCCCC' : '#A0AEC0',
                                    }}
                                />
                            </div>
                            <Tooltip
                                title={routeTranslate(
                                    intl,
                                    save ? 'tooltip.saving' : 'tooltip.saved',
                                )}
                                placement="bottom"
                                sx={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }}
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-tooltip': {
                                            backgroundColor: darkmode
                                                ? '#fff'
                                                : '#000',
                                            color: darkmode ? '#000 ' : '#fff',
                                        },
                                    },
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10],
                                            },
                                        },
                                    ],
                                }}
                            >
                                <div
                                    className={`icon save ${
                                        save ? 'animation-flip' : ''
                                    } ${darkmode ? 'darkIcons' : ''}`}
                                >
                                    {save ? (
                                        <SpinSave />
                                    ) : (
                                        <BsCheck
                                            style={{
                                                color: darkmode
                                                    ? '#22C55E'
                                                    : '#A0AEC0',
                                            }}
                                            size={26}
                                        />
                                    )}
                                </div>
                            </Tooltip>
                            <div className="icon-option">
                                <span>
                                    {editorRef.current?.plugins?.wordcount?.getCount() ??
                                        0}{' '}
                                    {routeTranslate(intl, 'document.words')}
                                </span>
                            </div>
                            <div
                                className={`"icon ${
                                    darkmode ? 'darkIcons' : ''
                                }`}
                                onClick={e => handleClick(e, doc.id)}
                            >
                                <Share darkmode={darkmode} />
                            </div>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                sx={{
                                    width: '100%',
                                    '& > .MuiPaper-root': {
                                        borderRadius: '12px',
                                        boxShadow:
                                            '8px 12px 24px rgba(93, 106, 131, 0.1)',
                                        background: darkmode ? '#1D1D1F' : '',
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: 140,
                                    horizontal: -50,
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <Paper
                                    sx={{
                                        width: 160,
                                        boxShadow: 0,
                                        background: darkmode ? '#1D1D1F' : '',
                                    }}
                                >
                                    <MenuList
                                        sx={{
                                            padding: 0,
                                            boxShadow: 0,
                                            background: darkmode
                                                ? '#1D1D1F'
                                                : '',
                                        }}
                                        disablePadding
                                        dense
                                    >
                                        <MuiMenuHeader
                                            className={
                                                darkmode ? 'muiHeaderDark' : ''
                                            }
                                        >
                                            <ul>
                                                <li
                                                    onClick={() => exportPDF()}
                                                    className="icon"
                                                    onMouseEnter={(e: any) =>
                                                        (e.target.style.backgroundColor =
                                                            darkmode
                                                                ? '#151718'
                                                                : '')
                                                    }
                                                    onMouseLeave={(e: any) =>
                                                        (e.target.style.backgroundColor =
                                                            darkmode
                                                                ? '#1D1D1F'
                                                                : '')
                                                    }
                                                >
                                                    <Pdf />
                                                    <span className="bold">
                                                        PDF
                                                    </span>
                                                </li>
                                                <li
                                                    onClick={() => exportWord()}
                                                    className="icon"
                                                    onMouseEnter={(e: any) =>
                                                        (e.target.style.backgroundColor =
                                                            darkmode
                                                                ? '#151718'
                                                                : '')
                                                    }
                                                    onMouseLeave={(e: any) =>
                                                        (e.target.style.backgroundColor =
                                                            darkmode
                                                                ? '#1D1D1F'
                                                                : '')
                                                    }
                                                >
                                                    <Doc />
                                                    <span className="bold">
                                                        Microsoft Word
                                                    </span>
                                                </li>
                                            </ul>
                                        </MuiMenuHeader>
                                    </MenuList>
                                </Paper>
                            </Menu>
                        </div>
                    </header>
                    <div className={`form ${darkmode && 'formFeatDark'}`}>
                        <div className={layout ? 'feature active' : 'feature'}>
                            <div className="form-feature">
                                <Input
                                    id="search"
                                    onChange={async e => {
                                        await register('search').onChange(e);
                                        await handleSearchTemplate(
                                            e.target.value,
                                        );
                                    }}
                                    placeholder="Pesquise um recurso"
                                    register={register}
                                />
                            </div>
                            <h1>Recursos</h1>
                            <ul>
                                {templates?.map(feature => (
                                    <li
                                        onClick={() => {
                                            getTemplate(feature.id);
                                            setFeature(feature.id);
                                            getHistory(feature.id);
                                        }}
                                        className={
                                            featureSelected === feature.id
                                                ? 'active'
                                                : ''
                                        }
                                        key={feature.id}
                                    >
                                        <div className="icon">
                                            <img src={feature?.image} />
                                        </div>
                                        <h1>{feature.name}</h1>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div
                            className={layout ? 'fields active' : 'fields'}
                            style={{ background: darkmode ? '#000' : '' }}
                        >
                            {loadingTemplate && (
                                <Loading open={loadingTemplate} />
                            )}
                            <div className="feature-header">
                                <div className="icon">
                                    <img src={template?.image_url} />
                                </div>
                                <div>
                                    <h1>{template?.name}</h1>
                                    <p>{template?.description}</p>
                                </div>
                            </div>
                            {layout && isDesktop ? (
                                <FieldsTemplate
                                    className={layout ? 'active' : ''}
                                >
                                    <form ref={formRef}>
                                        {template?.template_fields?.map(
                                            (fields, index) => {
                                                if (
                                                    fields.data_type ===
                                                    'input_text'
                                                ) {
                                                    return (
                                                        <Input
                                                            key={index}
                                                            label={
                                                                fields.translation
                                                            }
                                                            id={fields.key}
                                                            type="text"
                                                            placeholder={
                                                                fields.translation
                                                            }
                                                            register={register}
                                                            maxLength={60}
                                                            stepPosition="top"
                                                            step={
                                                                watch(
                                                                    fields.key,
                                                                )
                                                                    ? watch(
                                                                          fields.key,
                                                                      ).length.toString()
                                                                    : '0'
                                                            }
                                                            stepFinal="60"
                                                        />
                                                    );
                                                } else if (
                                                    fields.data_type ===
                                                    'textarea'
                                                ) {
                                                    return (
                                                        <TextArea
                                                            key={index}
                                                            label={
                                                                fields.translation
                                                            }
                                                            id={fields.key}
                                                            placeholder={
                                                                fields.translation
                                                            }
                                                            register={register}
                                                            maxLength={800}
                                                            stepPosition="top"
                                                            step={
                                                                watch(
                                                                    fields.key,
                                                                )
                                                                    ? watch(
                                                                          fields.key,
                                                                      ).length.toString()
                                                                    : '0'
                                                            }
                                                            stepFinal="800"
                                                        />
                                                    );
                                                }
                                            },
                                        )}
                                        {template?.has_voice_tone && (
                                            <Input
                                                label={
                                                    <>
                                                        {routeTranslate(
                                                            intl,
                                                            'voiceTone.label',
                                                        )}
                                                        <Tooltip
                                                            title="Add"
                                                            placement="bottom-start"
                                                            sx={{
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                            }}
                                                        >
                                                            <IconButton>
                                                                <AiOutlineExclamationCircle
                                                                    size={20}
                                                                    style={{
                                                                        color: '#A0AEC0',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                }
                                                id="voice_tone"
                                                type="text"
                                                placeholder={`${routeTranslate(
                                                    intl,
                                                    'voiceTone.placeholder',
                                                )}`}
                                                register={register}
                                                maxLength={60}
                                                stepPosition="top"
                                                step={
                                                    watchVoice
                                                        ? watchVoice.length.toString()
                                                        : '0'
                                                }
                                                stepFinal="60"
                                            />
                                        )}
                                        {template?.has_examples && (
                                            <InputAdd
                                                max={3}
                                                handleChange={handleChange}
                                            />
                                        )}
                                        {languages && (
                                            <Select
                                                label={routeTranslate(
                                                    intl,
                                                    'label.language',
                                                )}
                                                id="language"
                                                placeholder="Português (BR)"
                                                register={register}
                                                options={languages.map(
                                                    language => {
                                                        return {
                                                            name: language,
                                                        };
                                                    },
                                                )}
                                            />
                                        )}
                                        <div
                                            id="toolbar"
                                            style={{
                                                background: darkmode
                                                    ? '#000'
                                                    : '',
                                            }}
                                        >
                                            <div className="header">
                                                <Button
                                                    type="reset"
                                                    id="clean"
                                                    icon={<IoMdClose />}
                                                    style={{
                                                        background: darkmode
                                                            ? '#1d1d1f'
                                                            : '',
                                                        borderColor: darkmode
                                                            ? '#1d1d1f'
                                                            : '',
                                                        color: darkmode
                                                            ? '#fff'
                                                            : '',
                                                    }}
                                                >
                                                    {routeTranslate(
                                                        intl,
                                                        'button.clear.fields',
                                                    )}
                                                </Button>
                                                <div id="quatity">
                                                    <Input
                                                        id="quantity-number"
                                                        type="number"
                                                        placeholder="1"
                                                        register={register}
                                                        min={1}
                                                        max={5}
                                                    />
                                                    <Button
                                                        type="button"
                                                        icon={
                                                            <HiArrowNarrowRight
                                                                size={20}
                                                            />
                                                        }
                                                        onClick={
                                                            handleSubmitFormModel
                                                        }
                                                    >
                                                        {routeTranslate(
                                                            intl,
                                                            'button.create',
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="options">
                                                <span
                                                    className={
                                                        tab === 'entries'
                                                            ? 'link active'
                                                            : 'link'
                                                    }
                                                    onClick={() =>
                                                        setTabs('entries')
                                                    }
                                                >
                                                    {routeTranslate(
                                                        intl,
                                                        'button.entries',
                                                    )}
                                                </span>
                                                <span
                                                    className={
                                                        tab === 'history'
                                                            ? 'option active'
                                                            : 'option'
                                                    }
                                                    onClick={handleHistory}
                                                >
                                                    {routeTranslate(
                                                        intl,
                                                        'button.history',
                                                    )}
                                                </span>
                                                {tab === 'entries' &&
                                                entries &&
                                                entries.length > 0 ? (
                                                    <span
                                                        onClick={cleanEntries}
                                                        className="option"
                                                    >
                                                        Limpar
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                    <div
                                        id="appetizer"
                                        style={{
                                            background: darkmode ? '#000' : '',
                                        }}
                                    >
                                        {tab === 'history' && (
                                            <>
                                                {history &&
                                                history.length > 0 ? (
                                                    history.map(
                                                        (item, index) => {
                                                            const isLiked =
                                                                item.feedback_status ===
                                                                'good';
                                                            const isUnliked =
                                                                item.feedback_status ===
                                                                'bad';

                                                            return (
                                                                <div
                                                                    className="box"
                                                                    key={index}
                                                                >
                                                                    <div
                                                                        onClick={() =>
                                                                            handleModalHistory(
                                                                                item.response,
                                                                                item.id,
                                                                            )
                                                                        }
                                                                    >
                                                                        <p>
                                                                            {item.response.trim()}
                                                                        </p>
                                                                    </div>
                                                                    <div className="footer">
                                                                        <div>
                                                                            <span>
                                                                                {timeDifference(
                                                                                    new Date(),
                                                                                    new Date(
                                                                                        item.created_at,
                                                                                    ),
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <div className="option-box">
                                                                            <div
                                                                                onClick={() => {
                                                                                    return handleRate(
                                                                                        item.id,
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <Like
                                                                                    liked={
                                                                                        isLiked
                                                                                    }
                                                                                    darkmode={
                                                                                        darkmode
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                onClick={() => {
                                                                                    return handleRate(
                                                                                        item.id,
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <UnLike
                                                                                    unliked={
                                                                                        isUnliked
                                                                                    }
                                                                                    darkmode={
                                                                                        darkmode
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                onClick={() =>
                                                                                    copyContent(
                                                                                        item.response.trim(),
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Copy
                                                                                    hideTooltip={
                                                                                        false
                                                                                    }
                                                                                    darkmode={
                                                                                        darkmode
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleEditInDocument(
                                                                                        item.response,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <DocumentIcon
                                                                                    hideTooltip={
                                                                                        false
                                                                                    }
                                                                                    darkmode={
                                                                                        darkmode
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleAddTextTemplete(
                                                                                        item.response,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <ArrowHistory />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        },
                                                    )
                                                ) : (
                                                    <Empty
                                                        style={{
                                                            background: darkmode
                                                                ? '#000'
                                                                : '',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                darkmode
                                                                    ? EmptyImageDark
                                                                    : EmptyImage
                                                            }
                                                        />
                                                        <h1>
                                                            {routeTranslate(
                                                                intl,
                                                                'create.together',
                                                            )}
                                                        </h1>
                                                        <p>
                                                            {routeTranslate(
                                                                intl,
                                                                'create.together.description',
                                                            )}
                                                        </p>
                                                    </Empty>
                                                )}
                                            </>
                                        )}
                                        {tab === 'entries' && (
                                            <>
                                                {entries &&
                                                entries.length > 0 ? (
                                                    entries
                                                        .filter(
                                                            item =>
                                                                item.template_id ===
                                                                Number(
                                                                    template?.template_id,
                                                                ),
                                                        )
                                                        .reverse()
                                                        .map(item => {
                                                            return item.completions.map(
                                                                (
                                                                    completion,
                                                                    index,
                                                                ) => {
                                                                    const arrFilter =
                                                                        history?.filter(
                                                                            his =>
                                                                                his.id ===
                                                                                completion.id,
                                                                        )[0];

                                                                    const isLiked =
                                                                        arrFilter?.feedback_status ===
                                                                        'good';
                                                                    const isUnliked =
                                                                        arrFilter?.feedback_status ===
                                                                        'bad';

                                                                    return (
                                                                        <div
                                                                            className="box"
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <div
                                                                                onClick={() =>
                                                                                    handleModalHistory(
                                                                                        completion.completion,
                                                                                        completion.id,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <p>
                                                                                    {
                                                                                        completion.completion
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className="footer">
                                                                                <div>
                                                                                    <span>
                                                                                        {timeDifference(
                                                                                            new Date(),
                                                                                            new Date(
                                                                                                item.created_at,
                                                                                            ),
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="option-box">
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            return handleRate(
                                                                                                completion.id,
                                                                                                true,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <Like
                                                                                            liked={
                                                                                                isLiked
                                                                                            }
                                                                                            darkmode={
                                                                                                darkmode
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            return handleRate(
                                                                                                completion.id,
                                                                                                false,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <UnLike
                                                                                            unliked={
                                                                                                isUnliked
                                                                                            }
                                                                                            darkmode={
                                                                                                darkmode
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            copyContent(
                                                                                                completion.completion.trim(),
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <Copy
                                                                                            hideTooltip={
                                                                                                false
                                                                                            }
                                                                                            darkmode={
                                                                                                darkmode
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            handleEditInDocument(
                                                                                                completion.completion,
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <DocumentIcon
                                                                                            hideTooltip={
                                                                                                false
                                                                                            }
                                                                                            darkmode={
                                                                                                darkmode
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            handleAddTextTemplete(
                                                                                                completion.completion,
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <ArrowHistory />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                },
                                                            );
                                                        })
                                                ) : (
                                                    <Empty>
                                                        <img
                                                            src={
                                                                darkmode
                                                                    ? EmptyImageDark
                                                                    : EmptyImage
                                                            }
                                                        />
                                                        <h1>
                                                            {routeTranslate(
                                                                intl,
                                                                'create.together',
                                                            )}
                                                        </h1>
                                                        <p>
                                                            {routeTranslate(
                                                                intl,
                                                                'create.together.description',
                                                            )}
                                                        </p>
                                                    </Empty>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </FieldsTemplate>
                            ) : (
                                <FieldsDocument
                                    className={
                                        darkmode ? 'darkFieldsDocument' : ''
                                    }
                                >
                                    <TextArea
                                        label={
                                            <LabelCustom>
                                                <span>
                                                    {routeTranslate(
                                                        intl,
                                                        'document.editor.content',
                                                    )}
                                                    <Tooltip
                                                        title={routeTranslate(
                                                            intl,
                                                            'tooltip.Jarbas',
                                                        )}
                                                        placement="bottom"
                                                        sx={{
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                        }}
                                                        PopperProps={{
                                                            sx: {
                                                                '& .MuiTooltip-tooltip':
                                                                    {
                                                                        backgroundColor:
                                                                            darkmode
                                                                                ? '#fff'
                                                                                : '#000',
                                                                        color: darkmode
                                                                            ? '#000 '
                                                                            : '#fff',
                                                                    },
                                                            },
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [
                                                                            0,
                                                                            -10,
                                                                        ],
                                                                    },
                                                                },
                                                            ],
                                                        }}
                                                    >
                                                        <IconButton>
                                                            <AiOutlineExclamationCircle
                                                                size={20}
                                                                style={{
                                                                    color: '#A0AEC0',
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </span>
                                                <Tooltip
                                                    title={routeTranslate(
                                                        intl,
                                                        'tooltip.toggleOff',
                                                    )}
                                                    placement="bottom"
                                                    sx={{
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                    PopperProps={{
                                                        sx: {
                                                            '& .MuiTooltip-tooltip':
                                                                {
                                                                    backgroundColor:
                                                                        darkmode
                                                                            ? '#fff'
                                                                            : '#000',
                                                                    color: darkmode
                                                                        ? '#000 '
                                                                        : '#fff',
                                                                },
                                                        },
                                                        modifiers: [
                                                            {
                                                                name: 'offset',
                                                                options: {
                                                                    offset: [
                                                                        0, -10,
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    }}
                                                >
                                                    <div
                                                        className={`toggle ${
                                                            enable.content
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                        onClick={handleContent}
                                                    >
                                                        <div></div>
                                                    </div>
                                                </Tooltip>
                                            </LabelCustom>
                                        }
                                        id="content_description"
                                        placeholder={routeTranslate(
                                            intl,
                                            'document.editor.content.placeholder',
                                        )}
                                        register={register}
                                        disabled={!enable.content}
                                        maxLength={600}
                                        onChange={async e => {
                                            await descriptionField.onChange(e);
                                            await saveFields();
                                        }}
                                        step={watchDescription.length.toString()}
                                        stepFinal="600"
                                    />
                                    <Input
                                        label={
                                            <LabelCustom>
                                                <span>
                                                    {routeTranslate(
                                                        intl,
                                                        'voiceTone.label',
                                                    )}
                                                    <Tooltip
                                                        title="Add"
                                                        placement="bottom-start"
                                                        sx={{
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                        }}
                                                    >
                                                        <IconButton>
                                                            <AiOutlineExclamationCircle
                                                                size={20}
                                                                style={{
                                                                    color: '#A0AEC0',
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </span>
                                                <Tooltip
                                                    title={routeTranslate(
                                                        intl,
                                                        'tooltip.toggleOff',
                                                    )}
                                                    placement="bottom"
                                                    sx={{
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                    PopperProps={{
                                                        sx: {
                                                            '& .MuiTooltip-tooltip':
                                                                {
                                                                    backgroundColor:
                                                                        '#000',
                                                                },
                                                        },
                                                        modifiers: [
                                                            {
                                                                name: 'offset',
                                                                options: {
                                                                    offset: [
                                                                        0, -10,
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    }}
                                                >
                                                    <div
                                                        className={`toggle ${
                                                            enable.voice
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                        onClick={handleVoice}
                                                    >
                                                        <div></div>
                                                    </div>
                                                </Tooltip>
                                            </LabelCustom>
                                        }
                                        id="voice_tone"
                                        type="text"
                                        placeholder={`${routeTranslate(
                                            intl,
                                            'voiceTone.placeholder',
                                        )}`}
                                        register={register}
                                        disabled={!enable.voice}
                                        maxLength={60}
                                        onChange={async e => {
                                            await voiceField.onChange(e);
                                            await saveFields();
                                        }}
                                        step={watchVoice.length.toString()}
                                        stepFinal="60"
                                    />
                                    <InputTags
                                        label={
                                            <LabelCustom>
                                                <span>
                                                    {routeTranslate(
                                                        intl,
                                                        'document.editor.keyWords',
                                                    )}
                                                </span>
                                                <Tooltip
                                                    title={routeTranslate(
                                                        intl,
                                                        'tooltip.toggleOff',
                                                    )}
                                                    placement="bottom"
                                                    sx={{
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                    PopperProps={{
                                                        sx: {
                                                            '& .MuiTooltip-tooltip':
                                                                {
                                                                    backgroundColor:
                                                                        '#000',
                                                                },
                                                        },
                                                        modifiers: [
                                                            {
                                                                name: 'offset',
                                                                options: {
                                                                    offset: [
                                                                        0, -10,
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    }}
                                                >
                                                    <div
                                                        className={`toggle ${
                                                            enable.word
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                        onClick={handleWord}
                                                    >
                                                        <div></div>
                                                    </div>
                                                </Tooltip>
                                            </LabelCustom>
                                        }
                                        removeTag={handleRemoveTag}
                                        id="words"
                                        onChange={async e => {
                                            await register('words').onChange(e);
                                            await handleTags(e.target.value);
                                        }}
                                        tags={tags}
                                        type="text"
                                        placeholder=""
                                        register={register}
                                        disabled={!enable.word}
                                        maxLength={
                                            watchWord.split(',').length - 1 ===
                                            4
                                                ? watchWord.length
                                                : 'false'
                                        }
                                        step={(tags?.length ?? 0).toString()}
                                        stepFinal="3"
                                    />
                                    {languages && (
                                        <Select
                                            label={routeTranslate(
                                                intl,
                                                'label.language',
                                            )}
                                            id="language"
                                            placeholder="Português (BR)"
                                            register={register}
                                            options={languages.map(language => {
                                                return {
                                                    name: language,
                                                };
                                            })}
                                        />
                                    )}
                                    <SizeOutput
                                        selectSize={handleSize}
                                        size={sizeOutput}
                                        label={routeTranslate(
                                            intl,
                                            'document.editor.output',
                                        )}
                                    />
                                </FieldsDocument>
                            )}
                        </div>
                        <div className={layout ? 'active editor' : 'editor'}>
                            <div className="toolbar-editor">
                                <div className="icons">
                                    <div className="icon save">
                                        <FiUsers
                                            size={20}
                                            style={{ color: '#A0AEC0' }}
                                        />
                                    </div>
                                    <Tooltip
                                        title={routeTranslate(
                                            intl,
                                            save
                                                ? 'tooltip.saving'
                                                : 'tooltip.saved',
                                        )}
                                        placement="bottom"
                                        sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        }}
                                        PopperProps={{
                                            sx: {
                                                '& .MuiTooltip-tooltip': {
                                                    backgroundColor: '#000',
                                                },
                                            },
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -10],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <div
                                            className={`icon save ${
                                                save ? 'animation-flip' : ''
                                            }`}
                                        >
                                            {save ? (
                                                <SpinSave />
                                            ) : (
                                                <BsCheck
                                                    style={{ color: '#A0AEC0' }}
                                                    size={26}
                                                />
                                            )}
                                        </div>
                                    </Tooltip>
                                    <div className="icon-option">
                                        <span>
                                            {editorRef.current?.plugins?.wordcount?.getCount() ??
                                                0}{' '}
                                            {routeTranslate(
                                                intl,
                                                'document.words',
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <Editor
                                apiKey="7u5c9zt2spdi8q34x1hes3cnpeet8vhkw19pq0ccitacg364"
                                onInit={(evt: any, editor) => {
                                    editorRef.current = editor;
                                    setEditor(editor);
                                }}
                                value={htmlTiny}
                                onKeyDown={async e => {
                                    e = e || window.event;
                                    const key = e.which || e.keyCode; // keyCode detection
                                    const ctrl = e.ctrlKey
                                        ? e.ctrlKey
                                        : key === 17;
                                    const cmd = e.metaKey ? e.metaKey : false; // Check for Command key on Mac

                                    if (
                                        (key == 13 && ctrl) ||
                                        (key == 13 && cmd)
                                    ) {
                                        await handleToCompose();
                                        return false;
                                    }
                                }}
                                onEditorChange={handleEditorChange}
                                init={{
                                    height: '100%',
                                    preformatted: true,
                                    entity_encoding: 'raw',
                                    init_instance_callback: function (editor) {
                                        editor.on('click', function () {
                                            checkTextSelected();
                                        });
                                    },
                                    setup: function (editor) {
                                        editor.ui.registry.addIcon(
                                            'h1',
                                            '<svg class="plugin-active" xmlns="http://www.w3.org/2000/svg" width="16" height="13" fill="none"><path fill="#000" d="M9 1.5v10a1 1 0 1 1-2 0v-4H2v4a1 1 0 1 1-2 0v-10a1 1 0 1 1 2 0v4h5v-4a1 1 0 0 1 2 0Zm6.068 9.209H13.99v-5.61a.54.54 0 0 0-.605-.606h-.628a1.011 1.011 0 0 0-.748.297l-1.364 1.276a.544.544 0 0 0-.022.859l.286.307a.54.54 0 0 0 .847.034l.1-.088c.1-.103.182-.222.241-.353h.023s-.012.309-.012.606v3.278h-1.067a.541.541 0 0 0-.605.606v.439a.54.54 0 0 0 .605.605h4.027a.54.54 0 0 0 .605-.605v-.44a.54.54 0 0 0-.605-.605Z"/></svg>',
                                        );

                                        //
                                        editor.ui.registry.addIcon(
                                            'shine',
                                            '<svg class="plugin-active" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><g clipPath="url(#a)"><path fill="#A0AEC0" d="M8.614 7.028a.407.407 0 0 1 .772 0l.725 2.179a3.25 3.25 0 0 0 2.058 2.056l2.178.726a.407.407 0 0 1 0 .772l-2.18.726a3.25 3.25 0 0 0-2.056 2.057l-.725 2.178a.406.406 0 0 1-.772 0l-.726-2.179a3.252 3.252 0 0 0-2.056-2.056l-2.18-.726a.407.407 0 0 1 0-.772l2.18-.726a3.25 3.25 0 0 0 2.056-2.056l.726-2.18ZM4.268 1.292a.244.244 0 0 1 .464 0l.435 1.307c.195.583.651 1.04 1.234 1.234l1.307.435a.244.244 0 0 1 0 .464l-1.307.435a1.95 1.95 0 0 0-1.234 1.234L4.732 7.71a.244.244 0 0 1-.464 0L3.833 6.4a1.95 1.95 0 0 0-1.234-1.234L1.29 4.732a.244.244 0 0 1 0-.464L2.6 3.833a1.95 1.95 0 0 0 1.234-1.234l.435-1.307Zm7.953-1.18a.163.163 0 0 1 .308 0l.29.87c.13.39.434.694.824.824l.87.29a.163.163 0 0 1 0 .308l-.87.29a1.301 1.301 0 0 0-.824.824l-.29.87a.163.163 0 0 1-.308 0l-.29-.87a1.3 1.3 0 0 0-.824-.824l-.87-.29a.164.164 0 0 1 0-.308l.871-.29a1.3 1.3 0 0 0 .824-.824l.289-.87Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h18v18H0z"/></clipPath></defs></svg>',
                                        );

                                        editor.ui.registry.addButton(
                                            'rewrite',
                                            {
                                                icon: 'shine',
                                                tooltip: routeTranslate(
                                                    intl,
                                                    'tooltip.rephrase',
                                                ),
                                                onAction: () => {
                                                    const id = (
                                                        document.getElementById(
                                                            'document-id',
                                                        ) as HTMLInputElement
                                                    ).value;

                                                    const language = (
                                                        document.getElementById(
                                                            'language',
                                                        ) as HTMLInputElement
                                                    ).value;

                                                    handlePlugin(
                                                        'summarize',
                                                        +id,
                                                        language,
                                                    );
                                                },
                                            },
                                        );

                                        editor.ui.registry.addIcon(
                                            'face-smile',
                                            '<svg class="plugin-active" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path fill="#A0AEC0" d="M10.77 10.672a2.82 2.82 0 0 1-3.54 0 .75.75 0 1 0-.96 1.155 4.26 4.26 0 0 0 5.46 0 .75.75 0 1 0-.96-1.155ZM6.75 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm4.5-1.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM9 1.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM9 15A6 6 0 1 1 9 3a6 6 0 0 1 0 12Z"/></svg>',
                                        );

                                        editor.ui.registry.addButton('smile', {
                                            icon: 'face-smile',
                                            tooltip: routeTranslate(
                                                intl,
                                                'tooltip.explain',
                                            ),
                                            onAction: () => {
                                                const id = (
                                                    document.getElementById(
                                                        'document-id',
                                                    ) as HTMLInputElement
                                                ).value;

                                                const language = (
                                                    document.getElementById(
                                                        'language',
                                                    ) as HTMLInputElement
                                                ).value;

                                                handlePlugin(
                                                    '5th_grader',
                                                    +id,
                                                    language,
                                                );
                                            },
                                        });

                                        editor.ui.registry.addButton(
                                            'edit-plugin',
                                            {
                                                icon: 'edit',
                                                tooltip: routeTranslate(
                                                    intl,
                                                    'tooltip.fixGrammar',
                                                ),
                                                onAction: () => {
                                                    const id = (
                                                        document.getElementById(
                                                            'document-id',
                                                        ) as HTMLInputElement
                                                    ).value;

                                                    const language = (
                                                        document.getElementById(
                                                            'language',
                                                        ) as HTMLInputElement
                                                    ).value;

                                                    handlePlugin(
                                                        'correct_grammar',
                                                        +id,
                                                        language,
                                                    );
                                                },
                                            },
                                        );

                                        editor.ui.registry.addIcon(
                                            'edit',
                                            '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                                                '<path d="M9 15H15.75" stroke="#A0AEC0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                                                '<path d="M12.375 2.62499C12.6734 2.32662 13.078 2.159 13.5 2.159C13.7089 2.159 13.9158 2.20015 14.1088 2.2801C14.3019 2.36006 14.4773 2.47725 14.625 2.62499C14.7727 2.77272 14.8899 2.94811 14.9699 3.14114C15.0498 3.33417 15.091 3.54105 15.091 3.74999C15.091 3.95892 15.0498 4.1658 14.9699 4.35883C14.8899 4.55186 14.7727 4.72725 14.625 4.87499L5.25 14.25L2.25 15L3 12L12.375 2.62499Z" stroke="#A0AEC0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                                                '</svg>',
                                        );
                                    },
                                    placeholder: `Guie Jarbas no que escrever! Dê a ele uma direção e coloque o cursor no final da frase e pressione "${
                                        isMacOs ? '⌘' : 'Ctrl'
                                    } + enter"`,
                                    menu: {
                                        tc: {
                                            title: 'Comments',
                                            items: 'addcomment showcomments deleteallconversations',
                                        },
                                    },
                                    menubar: false,
                                    statusbar: false,

                                    content_style:
                                        'body { margin: 40px 48px; font-size: 14pt; line-height: 1.7;  }',
                                    toolbar:
                                        'undo redo | h1 h2 fontsize | underline italic bold | numlist bullist link image removeformat | rewrite edit-plugin smile',
                                    fontsize_formats:
                                        '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                                    autosave_ask_before_unload: true,
                                    autosave_interval: '30s',
                                    autosave_prefix: '{path}{query}-{id}-',
                                    autosave_restore_when_empty: false,
                                    autosave_retention: '2m',
                                    image_advtab: true,
                                    link_list: [
                                        {
                                            title: 'My page 1',
                                            value: 'https://www.tiny.cloud',
                                        },
                                        {
                                            title: 'My page 2',
                                            value: 'http://www.moxiecode.com',
                                        },
                                    ],
                                    image_list: [
                                        {
                                            title: 'My page 1',
                                            value: 'https://www.tiny.cloud',
                                        },
                                        {
                                            title: 'My page 2',
                                            value: 'http://www.moxiecode.com',
                                        },
                                    ],
                                    image_class_list: [
                                        { title: 'None', value: '' },
                                        {
                                            title: 'Some class',
                                            value: 'class-name',
                                        },
                                    ],
                                    importcss_append: true,
                                    plugins:
                                        'autosave lists link image preview wordcount',
                                    // rtc_snapshot: ({
                                    //     version,
                                    //     getContent,
                                    // }: any) => {
                                    //     console.log('Current version', version);
                                    //     console.log('HTML', getContent());
                                    // },
                                    // rtc_document_id: doc.rtc_id,
                                    // rtc_initial_content_provider: () =>
                                    //     Promise.resolve({
                                    //         content: doc.content,
                                    //     }),
                                    // rtc_user_details_provider: () =>
                                    //     Promise.resolve({
                                    //         fullName:
                                    //             settings.User.first_name +
                                    //             ' ' +
                                    //             settings.User.last_name,
                                    //     }),
                                    // rtc_encryption_provider: () =>
                                    //     Promise.resolve({
                                    //         key: 'a secret key',
                                    //     }),
                                    // rtc_token_provider: async () => {
                                    //     const documentsService =
                                    //         new DocumentsService();
                                    //     const tokenResponse =
                                    //         await documentsService.getJwt(
                                    //             idDocument,
                                    //         );
                                    //
                                    //     return {
                                    //         token: tokenResponse.token,
                                    //     };
                                    // },
                                    templates: [
                                        {
                                            title: 'New Table',
                                            description: 'creates a new table',
                                            content:
                                                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                                        },
                                        {
                                            title: 'Starting my story',
                                            description:
                                                'A cure for writers block',
                                            content: 'Once upon a time...',
                                        },
                                        {
                                            title: 'New list with dates',
                                            description: 'New List with dates',
                                            content:
                                                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                                        },
                                    ],
                                    template_cdate_format:
                                        '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                    template_mdate_format:
                                        '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                    // image_caption: true,
                                    quickbars_selection_toolbar:
                                        'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                    noneditable_noneditable_class:
                                        'mceNonEditable',
                                    toolbar_mode: 'sliding',
                                    spellchecker_ignore_list: [
                                        'Ephox',
                                        'Moxiecode',
                                    ],
                                    contextmenu:
                                        'link image imagetools table configure',
                                    a11y_advanced_options: true,
                                    mentions_selector: '.mymention',
                                    mentions_item_type: 'profile',
                                    resize: false,
                                    language: tinyLocale,
                                    mceInsertContent: true,
                                }}
                            />
                            <div
                                className={layout ? 'active' : ''}
                                id="submit"
                                style={{
                                    background: darkmode ? '#000' : '',
                                }}
                            >
                                <Button
                                    disabled={blockCompose || blockEdit}
                                    type="button"
                                    onClick={handleToCompose}
                                >
                                    {!loadingDocument ? (
                                        <>
                                            {routeTranslate(
                                                intl,
                                                'document.editor.button',
                                            )}{' '}
                                            <div
                                                className={
                                                    blockCompose || blockEdit
                                                        ? 'disabled'
                                                        : ''
                                                }
                                                id="shortcut"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: isMacOs
                                                            ? '0.875rem'
                                                            : '',
                                                    }}
                                                >
                                                    {isMacOs ? '⌘' : 'Ctrl'} +
                                                    enter
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <Loading
                                            absolute={false}
                                            open={loadingDocument}
                                        />
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
                <ModalEditHistory
                    idTemplate={template?.template_id}
                    idHistory={idHistory}
                    title={template?.name}
                    image={template?.image_url}
                    text={textHistory}
                    active={activeModalHistory}
                    handleClose={handleCloseModalHistory}
                />
            </ContainerModal>
        </>
    );
};

export default Document;
