import { client } from 'services/client';
import { Language } from 'services/language/interfaces';

export class LanguageService {
    private readonly path = '/languages';
    private readonly languagesClient = client
        .addPath(this.path)
        .addAuthHeader();

    async getAll(allSupportedLanguages = false): Promise<Language[]> {
        return this.languagesClient.getData<Language[]>({
            queryParams: { all_supported_languages: allSupportedLanguages },
        });
    }
}
