import React from 'react';
import { Container, Button } from '../../app/presentation/components';
import { ContainerContent, Header, BrandCenterMenu } from './styles';
import { routeTranslate as t } from '../../utils/intl';
import { useIntl } from 'react-intl';
import { Collapse, useMediaQuery } from '@mui/material';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import newBrand from '../../assets/images/svg/new-brand.svg';
import { BsPlusLg } from 'react-icons/bs';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import useTemplates from 'app/presentation/hooks/useTemplates';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { TbBook, TbSettings } from 'react-icons/tb';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import { basePath, pathStartsWith } from 'utils/routes';

const BrandCenter: React.FC = () => {
    const intl = useIntl();
    const { pathname } = useLocation();
    const isDesktop = useMediaQuery('(min-width: 64em)');
    const { darkmode } = useDarkMode();
    const { activeBrand, setModalNewBrand } = useUserConfig();
    const { setMenu } = useTemplates();
    const [open, setOpen] = React.useState(true);

    const handleOpenModalBrand = () => {
        setMenu(true);
        setModalNewBrand(true);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const isInTheAgentLayout = (): boolean => {
        return pathStartsWith(pathname, intl, 'route.urlLang', 'route.agent.home')
    }

    // TODO remove all these className and treat these context values in the component
    return (
        <Container className={isDesktop ? 'content-full' : ''}>
            <Header className={darkmode ? 'headerChatDark' : ''} agentLayout={isInTheAgentLayout()}>
                <h1>
                    {t(intl, 'brand.title')}{' '}
                    <span className="beta">Beta</span>
                </h1>
            </Header>
            <ContainerContent className={darkmode ? 'contentDark' : ''} agentLayout={isInTheAgentLayout()}>
                {activeBrand ? (
                    <>
                        <BrandCenterMenu>
                            <List>
                                {/*<NavLink
                                    to={`/${routeTranslate(
                                        intl,
                                        'route.urlLang',
                                    )}${routeTranslate(
                                        intl,
                                        'route.backoffice',
                                    )}${routeTranslate(
                                        intl,
                                        'route.brand-center',
                                    )}${routeTranslate(
                                        intl,
                                        'route.brand-center.voice-tone',
                                    )}`}
                                >
                                    <ListItemButton>
                                        <TbSpeakerphone size={22} />
                                        <ListItemText
                                            primary={routeTranslate(
                                                intl,
                                                'brand-center.button.voice-tone',
                                            )}
                                        />
                                        </ListItemButton>
                            </NavLink>*/}

                                <ListItemButton onClick={handleClick}>
                                    <TbBook size={22} />
                                    <ListItemText primary={t(intl, 'brand-center.button.knowledge-center')} />
                                    {open ? (<ExpandLess className="expandIcon" />) : (<ExpandMore className="expandIcon" />)}
                                </ListItemButton>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        <NavLink
                                            to={`${basePath(pathname,
                                                intl, 'route.brand-center'
                                            )}${t(
                                                intl,
                                                'route.brand-center.knowledge',
                                            )}`}
                                        >
                                            <ListItemButton sx={{ pl: 6 }}>
                                                <ListItemText primary={t(intl, 'brand-center.button.knowledge')}
                                                />
                                            </ListItemButton>
                                        </NavLink>
                                        <NavLink
                                            to={`${basePath(pathname,
                                                intl, 'route.brand-center'
                                            )}${t(
                                                intl,
                                                'route.brand-center.collections',
                                            )}`}
                                        >
                                            <ListItemButton sx={{ pl: 6 }}>
                                                <ListItemText primary={t(intl, 'brand-center.button.collections')} />
                                            </ListItemButton>
                                        </NavLink>
                                    </List>
                                </Collapse >
                                <NavLink
                                    to={`${basePath(pathname,
                                        intl, 'route.brand-center'
                                    )}${t(
                                        intl,
                                        'route.brand-center.settings',
                                    )}`}
                                >
                                    <ListItemButton>
                                        <TbSettings size={22} />
                                        <ListItemText primary={t(intl, 'brand-center.button.settings')} />
                                    </ListItemButton>
                                </NavLink>
                            </List >
                        </BrandCenterMenu >

                        <div className="content-route">
                            <Outlet />
                        </div>
                    </>
                ) : (
                    <div className="empty-brand-center">
                        <img src={newBrand} alt="new tone image" />
                        <div>
                            <h3>{t(intl, 'modal.brand.title')}</h3>
                            <p>{t(intl, 'brand.create.desc')}</p>
                        </div>

                        <div className="btn-container">
                            <Button icon={<BsPlusLg size={20} />} iconPositon="start" onClick={handleOpenModalBrand}>
                                {t(intl, 'navmenu.brand.new',).replace('+', '')}
                            </Button>
                        </div>
                    </div>
                )}
            </ContainerContent >
        </Container >
    );
};

export default BrandCenter;
