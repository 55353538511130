import styled from 'styled-components';

export const PlansContent = styled.div`
    width: 100%;

    .limits {
        margin-bottom: 20px;
        padding: 0;
        display: flex;
        justify-content: flex-start;

        .margin-l {
            margin-left: 16px;
        }

        .content {
            flex: 1;
            height: 133px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 18px;
            border-radius: 16px;
            border: 1px solid #edf2f7;
            background: #fff;
            p {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #a09fa5;
                font-weight: 500;
                svg {
                    margin-left: 5px;
                }
            }
            span {
                color: #000;
                font-size: 32px;
                font-weight: 700;
            }
        }

        @media (max-width: 64em) {
            flex-direction: column;
            .content {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 18px;
                margin-bottom: 8px;
            }
        }
    }

    #signatures {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .box {
            padding: 22px 20px 20px;
            border: 1px solid #edf2f7;
            border-radius: 16px;

            .credit {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
            }

            &#card {
                height: 167px;

                h1 {
                    font-weight: 500;
                    font-size: 1em;
                    color: #718096;
                }

                #points {
                    color: #1a202c;
                    font-size: 2em;
                    font-weight: 800;
                }
            }

            &#signature {
                height: 231px;

                h1 {
                    font-weight: 700;
                    font-size: 1.25em;
                }

                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;

                    #period {
                        width: 105.73px;
                        height: 30px;
                        background: #d0ff61;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-weight: 500;
                            font-size: 0.875em;
                            color: #1a202c;
                        }
                    }
                }

                #description {
                    p {
                        font-size: 1em;
                        color: #718096;
                        font-weight: 500;
                        margin-bottom: 15px;

                        strong {
                            color: #1a202c;
                        }
                    }

                    button {
                        color: #1a202c;
                        font-size: 0.875em;
                        border: 1px solid #e2e8f0;
                        border-radius: 8px;
                        background: #fff;
                        width: 151px;
                        height: 42px;
                        padding: 0;
                        margin-bottom: 15px;
                    }

                    .button-link {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 195px;
                        height: 42px;
                        background: #f7fafc;
                        border-radius: 8px;
                        color: #718096;
                        font-size: 0.875em;
                    }
                }
            }
        }
    }

    #account {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .box {
            padding: 22px 20px 20px;
            border: 1px solid #edf2f7;
            border-radius: 16px;

            &#fature {
                height: 168px;

                h1 {
                    font-weight: 700;
                    font-size: 1.25em;
                    margin-bottom: 12px;
                }

                p {
                    font-size: 1em;
                    color: #718096;
                    font-weight: 500;
                    margin-bottom: 0.875em;
                    margin-bottom: 15px;
                }

                button {
                    color: #1a202c;
                    font-size: 0.875em;
                    border: 1px solid #e2e8f0;
                    border-radius: 8px;
                    background: #fff;
                    width: 154px;
                    height: 42px;
                    padding: 0;
                }

                .buttonHistory {
                    &:disabled {
                        color: rgb(113, 128, 150);
                        background: rgb(247, 250, 252);
                    }
                }
            }

            &#account-cancel {
                height: 168px;

                h1 {
                    font-weight: 700;
                    font-size: 1.25em;
                    margin-bottom: 12px;
                }

                p {
                    font-size: 0.875em;
                    color: #718096;
                    font-weight: 500;
                    margin-bottom: 0.875em;
                    margin-bottom: 12px;
                }

                button {
                    color: #1a202c;
                    font-size: 0.875em;
                    border: 1px solid #e2e8f0;
                    border-radius: 12px;
                    background: #fff;
                    width: 94px;
                    height: 48px;
                    padding: 0;
                }
            }
        }

        .box:last-of-type {
            border: 0;
        }
    }

    @media (min-width: 64em) {
        #signatures {
            flex-direction: row;
            gap: 19px;

            > div {
                flex: 1;
            }

            #card {
                max-width: 250px;
            }

            .box {
                height: 167px !important;
            }

            #signature {
                #buttons {
                    display: flex;
                    gap: 12px;
                    align-items: center;

                    button {
                        margin: 0 !important;
                    }
                }
            }
        }

        #account {
            gap: 60px;
            margin-top: 24px;
        }

        #fature {
            display: flex;
            height: 111px !important;
            justify-content: space-between;
            align-items: center;
        }
    }
    .containerNotAuthorized {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 24px;

        .notAuthorized {
            width: 100%;
            max-width: 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2 {
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 130%;
                color: #000;
            }
            p {
                font-size: 0.875rem;
                font-weight: 500;
                color: #718096;
                margin-top: 14px;
                text-align: center;
            }

            @media (min-width: 64em) {
                margin-top: 100px;
            }
        }
    }

    &.plansDark {
        #card,
        #signature {
            border-color: #3a3a3a;
        }

        .credit {
            h1 {
                color: #a09fa5 !important;
            }
        }

        #points {
            color: #fff !important;
        }

        #signature {
            #description {
                p {
                    color: #a09fa5 !important;
                    strong {
                        color: #fff !important;
                    }
                }
            }
        }

        #fature {
            border: 1px solid #3a3a3a !important;
            border-radius: 16px;

            p {
                color: #a09fa5 !important;
            }

            .buttonHistory {
                background: '#000' !important;
                color: '#fff' !important;
                border-color: '#E2E8F0' !important;
                &:disabled {
                    background: rgb(58, 58, 58) !important;
                    color: rgb(160, 159, 165) !important;
                    border-color: rgb(58, 58, 58) !important;
                }
            }
        }
    }
`;
