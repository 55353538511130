import useDarkMode from 'app/presentation/hooks/useDarkMode';
import React from 'react';

export const Home: React.FC = () => {
    const { darkmode } = useDarkMode();

    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.3075 20.8541H5.69251C3.18084 20.8541 1.14584 18.8099 1.14584 16.2982V9.50573C1.14584 8.25906 1.91584 6.69156 2.90584 5.92156L7.84668 2.07156C9.33168 0.916562 11.7058 0.861561 13.2458 1.94323L18.9108 5.9124C20.0017 6.67323 20.8542 8.3049 20.8542 9.63406V16.3074C20.8542 18.8099 18.8192 20.8541 16.3075 20.8541ZM8.69001 3.15323L3.74918 7.00323C3.09834 7.51656 2.52084 8.68073 2.52084 9.50573V16.2982C2.52084 18.0491 3.94168 19.4791 5.69251 19.4791H16.3075C18.0583 19.4791 19.4792 18.0582 19.4792 16.3074V9.63406C19.4792 8.75406 18.8467 7.53489 18.1225 7.0399L12.4575 3.07073C11.4125 2.33739 9.68918 2.37406 8.69001 3.15323Z"
                fill={darkmode ? '#fff' : '#1A202C'}
            />
            <path
                d="M11 17.1875C10.6242 17.1875 10.3125 16.8758 10.3125 16.5V13.75C10.3125 13.3742 10.6242 13.0625 11 13.0625C11.3758 13.0625 11.6875 13.3742 11.6875 13.75V16.5C11.6875 16.8758 11.3758 17.1875 11 17.1875Z"
                fill={darkmode ? '#fff' : '#1A202C'}
            />
        </svg>
    );
};
