import React from 'react';
import { IntegrationsContent } from './styles';
import { HeaderModalDetails } from 'app/presentation/components';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';

const Integrations: React.FC = () => {
    const [enabled, setEnabled] = React.useState({
        option1: false,
        option2: false,
        option3: false,
    });
    const intl = useIntl();

    const handleOptionOne = () => {
        setEnabled({ ...enabled, option1: !enabled.option1 });
    };

    const handleOptionTwo = () => {
        setEnabled({ ...enabled, option2: !enabled.option2 });
    };

    const handleOptionThree = () => {
        setEnabled({ ...enabled, option3: !enabled.option3 });
    };

    return (
        <IntegrationsContent>
            <HeaderModalDetails
                title={routeTranslate(intl, 'definitions.integrations')}
            />
            <div className="box">
                <header>
                    <h1>{routeTranslate(intl, 'integrations.plagiarism')}</h1>
                    <div
                        className={enabled.option1 ? 'toggle active' : 'toggle'}
                        onClick={handleOptionOne}
                    >
                        <div></div>
                    </div>
                </header>
                <p>
                    {routeTranslate(
                        intl,
                        'integrations.plagiarism.description',
                    )}
                </p>
            </div>
            <div className="box">
                <header>
                    <h1>{routeTranslate(intl, 'integrations.deepl')}</h1>
                    <div
                        className={enabled.option2 ? 'toggle active' : 'toggle'}
                        onClick={handleOptionTwo}
                    >
                        <div></div>
                    </div>
                </header>
                <p>{routeTranslate(intl, 'integrations.deepl.description')}</p>
            </div>
            <div className="box">
                <header>
                    <h1>{routeTranslate(intl, 'integrations.ubersuggest')}</h1>
                    <div
                        className={enabled.option3 ? 'toggle active' : 'toggle'}
                        onClick={handleOptionThree}
                    >
                        <div></div>
                    </div>
                </header>
                <p>
                    {routeTranslate(
                        intl,
                        'integrations.ubersuggest.description',
                    )}
                </p>
            </div>
        </IntegrationsContent>
    );
};

export default Integrations;
