import styled from 'styled-components';

export const ContainerTitle = styled.div`
    width: 100%;
    height: 1.5rem;
    text-align: start;

    h1 {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.518rem;
        letter-spacing: 0.2px;
        color: #000;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 90%;
    }

    &.usingDarkMode {
        h1 {
            color: #fff;
        }

        #boxContainer {
            background: #000;
            border-radius: 8px;
            border: none;

            label {
                color: #fff;
            }

            input {
                background: #1d1d1f;
                color: #fff;
            }
        }
    }
`;

export const ContainerModal = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20.75rem;
    height: 12.06rem;
    border-radius: 8px;
    background: #fff;
    z-index: 9999;

    #boxContainer {
        height: 100%;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 1.25rem;
    }

    label {
        font-family: 'Manrope', sans-serif;
        font-size: 0.875rem;
        width: 100%;
        height: 1.81rem;
        color: #7e7e7e;
        border-bottom: 1px solid #f1f1f1;
    }

    &.darkmode {
        background: #000;

        input {
            background: #000;
            color: #fff;
            border-color: #a09fa5;
        }
    }
`;

export const ChatTitleInput = styled.input`
    font-size: 1rem;
    font-family: 'Manrope', sans-serif;
    letter-spacing: 0.2px;
    border: 1px solid #000;
    border-radius: 12px;
    padding: 1rem;
    width: 100%;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;

    button {
        font-size: 0.875rem;
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        letter-spacing: 0.2px;
        line-height: 1.3125rem;
        width: 6.375rem;
        height: 2.625rem;
        border-radius: 8px;
        padding: 0.5rem 1.25rem;
        text-align: center;
    }

    button[type='submit'] {
        background: #d0ff61;
        border: none;
        color: #000;
    }

    #cancel {
        color: #a09fa5;
        border: 1px solid #a09fa5;
        background: transparent;
    }

    button[type='submit']:disabled {
        background: transparent;
        /* border: 1px solid #a09fa5; */
        opacity: 50%;
        color: #a09fa5;
        cursor: not-allowed;
    }
`;
