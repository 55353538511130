import {
    IconBlockquote,
    IconCheck,
    IconChevronDown,
    IconChevronUp,
    IconPencil,
    IconRosetteDiscountCheck,
    IconTrash,
    IconWorld,
    IconX,
} from '@tabler/icons-react';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';
import {
    SliderModal,
    SliderModalProps,
} from 'pages/brand-center/knowledge/components';
import { useEffect, useState } from 'react';
import { ExcerptService } from 'services/excerpts';
import { Excerpt } from 'services/excerpts/interfaces';
import { TrainingService } from 'services/training';
import { TrainingResourceTypes } from 'services/training/interfaces';
import { URLContentsService } from 'services/url_contents';
import { URLContent } from 'services/url_contents/interfaces';
import { timeDifference } from 'utils/time';
import { Button } from '../../../Button';
import { InfoMessage } from '../../../InfoMessage';
import StatusLabel from '../../../StatusLabel';
import { SwitchCheckbox } from '../../../SwitchCheckbox';
import * as S from './styles';

interface TrainingDrawerProps extends SliderModalProps {
    handleClose: () => void;
    content?: Excerpt | URLContent;
    contentType?: TrainingResourceTypes;
    contentEnabled?: boolean;
    isEditing?: boolean;
    notifyAgentTrainingChanged: () => void;
}

const TrainingDrawer = ({ handleClose, ...props }: TrainingDrawerProps) => {
    const excerptService = new ExcerptService();
    const urlContentService = new URLContentsService();
    const { agent } = useAgent();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [isEditing, setIsEditing] = useState(props.isEditing ?? false);
    const [showDetails, setShowDetails] = useState(false);
    const [contentEnabled, setContentEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (props.contentEnabled) {
            setContentEnabled(props.contentEnabled);
        } else {
            setContentEnabled(false);
        }
    }, [props.contentEnabled]);

    const trainingService = new TrainingService();

    useEffect(() => {
        if (props.content) {
            setTitle(
                props.contentType == TrainingResourceTypes.Excerpt
                    ? props.content.title
                    : (props.content as URLContent).url,
            );
            setContent(props.content.content);
        }
    }, [props.content]);

    useEffect(() => {
        setIsEditing(props.isEditing ?? false);
    }, [props.isEditing]);

    const handleSave = async () => {
        if (!agent) return;

        if (!props.content) {
            await excerptService.createExcerpt(agent.id, title, content);
            props.notifyAgentTrainingChanged();
            handleClose();
            return;
        }

        if (props.contentType == TrainingResourceTypes.Excerpt) {
            await excerptService.update(props.content.id, title, content);
        } else {
            await urlContentService.update(props.content.id, content);
        }

        setIsEditing(false);
        props.notifyAgentTrainingChanged();
    };

    const handleDelete = async () => {
        if (!props.content) return;

        if (props.contentType == TrainingResourceTypes.Excerpt) {
            await excerptService.delete(props.content.id);
        } else {
            await urlContentService.delete(props.content.id);
        }

        props.notifyAgentTrainingChanged();
    };

    const handleEditorChange = (e: any) => {
        const text = e.currentTarget.innerText as string;
        setContent(text);
    };

    const { textGetter } = useText();
    const t = textGetter('agent.components.training.drawers.excerpts');

    const getButtons = () => {
        if (isEditing) {
            return (
                <>
                    <Button
                        variant="secondary"
                        fullStyled
                        onClick={() => {
                            if (!props.content && isEditing) {
                                return handleClose();
                            }

                            setIsEditing(false);
                        }}
                    >
                        {t('buttons.cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        fullStyled
                        onClick={async () => {
                            await handleSave();
                        }}
                    >
                        <IconCheck size={20} />
                        {t('buttons.save')}
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    <Button
                        variant="secondary"
                        fullStyled
                        onClick={async () => {
                            await handleDelete();
                            handleClose();
                        }}
                    >
                        <IconTrash size={20} />
                        {t('buttons.delete')}
                    </Button>
                    <Button
                        variant="primary"
                        fullStyled
                        onClick={() => setIsEditing(true)}
                    >
                        <IconPencil size={20} />
                        {t('buttons.edit')}
                    </Button>
                    <Button
                        variant="secondary"
                        fullStyled
                        onClick={handleClose}
                        style={{ height: '44px' }}
                    >
                        <IconX size={20} />
                    </Button>
                </>
            );
        }
    };

    const getTrainingTypeDisplay = () => {
        switch (props.contentType) {
            case TrainingResourceTypes.BrandCenter:
                return (
                    <>
                        <IconRosetteDiscountCheck />
                        {t('training-type.brandCenter')}
                    </>
                );
            case TrainingResourceTypes.URLContent:
                return (
                    <>
                        <IconWorld />
                        {t('training-type.urlContent')}
                    </>
                );
            case TrainingResourceTypes.Excerpt:
                return (
                    <>
                        <IconBlockquote />
                        {t('training-type.excerpt')}
                    </>
                );
            default:
                return <></>;
        }
    };

    const handleEnabledChange = () => {
        if (!agent || !props.content || !props.contentType) return;

        trainingService
            .updateTrainingEnabled(
                agent.id,
                props.content.id,
                props.contentType,
                !contentEnabled,
            )
            .then(() => {
                setTimeout(() => props.notifyAgentTrainingChanged(), 500);
            });
        setContentEnabled(current => !current);
    };

    const getTrainingMetadata = () => {
        if (isEditing) {
            return (
                props.content && (
                    <S.Status>
                        <label>{t('status.text')}</label>
                        <SwitchCheckbox
                            name="status"
                            value={contentEnabled}
                            setValue={handleEnabledChange}
                            width="32.41px"
                            height="18px"
                        ></SwitchCheckbox>
                        <StatusLabel
                            className="content-drawer-status-label"
                            status="success"
                            text={t('status.enabled')}
                        />
                    </S.Status>
                )
            );
        } else {
            return (
                <S.TrainingMetadata>
                    <S.TrainingMetadataItem>
                        <div className="content-drawer-metadata-title">
                            {t('source.text')}
                        </div>
                        <div className="content-drawer-metadata-title">
                            {getTrainingTypeDisplay()}
                        </div>
                    </S.TrainingMetadataItem>
                    <S.TrainingMetadataItem>
                        <div className="content-drawer-metadata-title">
                            {t('status.text')}
                        </div>
                        <SwitchCheckbox
                            name="status"
                            value={contentEnabled}
                            setValue={handleEnabledChange}
                            width="32.41px"
                            height="18px"
                        ></SwitchCheckbox>
                        <StatusLabel
                            className="content-drawer-status-label"
                            status="success"
                            text={t('status.enabled')}
                        />
                    </S.TrainingMetadataItem>
                    <S.TrainingMetadataItem>
                        <div className="content-drawer-metadata-title">
                            {t('updated_at.text')}
                        </div>
                        <div className="content-drawer-metadata-title">
                            {timeDifference(
                                new Date(),
                                new Date(
                                    props.content?.updated_at || new Date(),
                                ),
                            )}
                        </div>
                    </S.TrainingMetadataItem>
                    <S.TrainingMetadataItem>
                        <div className="content-drawer-metadata-title">
                            {t('edited_by.text')}
                        </div>
                        <div className="content-drawer-metadata-title">
                            {props.content?.last_edited_by}
                        </div>
                    </S.TrainingMetadataItem>
                </S.TrainingMetadata>
            );
        }
    };

    return (
        <SliderModal {...props} zIndex={9999}>
            <S.Container>
                <S.Header>
                    <input
                        type="text"
                        placeholder={t('inputs.title.placeholder')}
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        disabled={
                            !isEditing ||
                            props.contentType ===
                            TrainingResourceTypes.URLContent
                        }
                    ></input>
                    <div className="content-drawer-header-buttons">
                        {getButtons()}
                    </div>
                </S.Header>
                <S.Content>
                    {!props.content && isEditing && (
                        <InfoMessage
                            text={t('info-message.content')}
                            type="yellow"
                        ></InfoMessage>
                    )}
                    {getTrainingMetadata()}
                    {isEditing ? (
                        <S.Editor
                            onInput={handleEditorChange}
                            contentEditable
                            placeholder={t('inputs.content.placeholder')}
                            suppressContentEditableWarning={true}
                        >
                            {props.content?.content}
                        </S.Editor>
                    ) : (
                        <S.Readonly>
                            <div onClick={() => setShowDetails(!showDetails)}>
                                <label>{t('readonly.details')}</label>
                                {showDetails ? (
                                    <IconChevronUp size={20} />
                                ) : (
                                    <IconChevronDown size={20} />
                                )}
                            </div>
                            <p className={showDetails ? 'expanded' : ''}>
                                {props.content?.content}
                            </p>
                        </S.Readonly>
                    )}
                </S.Content>
            </S.Container>
        </SliderModal>
    );
};

export default TrainingDrawer;
