import { businessIcons, communicationIcons, contentIcons, conversationAndGesturesIcons, deviceIcons, fileAndFoldersIcons, graphsIcons, natureIcons, otherIcons, softwareIcons } from 'services/collections/types';
import { Popover } from './styles';
import { CustomIcon } from '../CustomIcon/CustomIcon';
import { FormattedMessage } from 'react-intl';

type IconsModalProps = {
    anchor: HTMLElement | null;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;

    setShowIconsDropdown: React.Dispatch<React.SetStateAction<boolean>>;

    open: boolean;
}

// TODO depth validation
export const IconsModal: React.FC<IconsModalProps> = ({ anchor, setAnchor, open, setShowIconsDropdown }) => {
    return (
        <Popover
            open={open}
            anchorEl={anchor}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            onClose={() => {
                setShowIconsDropdown(false);
                setAnchor(null);
            }}
        >
            <div className="modal-body">
                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.business-and-finances" />
                    </p>
                    <div className="section-content" >
                        {businessIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>

                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.graphs" />
                    </p>
                    <div className="section-content">
                        {graphsIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>

                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.communication" />
                    </p>
                    <div className="section-content">
                        {communicationIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>

                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.content" />
                    </p>
                    <div className="section-content">
                        {contentIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>

                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.files-and-folders" />
                    </p>
                    <div className="section-content">
                        {fileAndFoldersIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>

                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.nature" />
                    </p>
                    <div className="section-content">
                        {natureIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>

                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.others" />
                    </p>
                    <div className="section-content">
                        {otherIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>

                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.devices" />
                    </p>
                    <div className="section-content">
                        {deviceIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>

                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.conversations-and-gestures" />
                    </p>
                    <div className="section-content">
                        {conversationAndGesturesIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>

                <div className="section">
                    <p className="section-header">
                        <FormattedMessage id="brand-center.components.modals.icons.software" />
                    </p>
                    <div className="section-content">
                        {softwareIcons.map(Icon => <CustomIcon Icon={Icon} setShowIconsDropdown={setShowIconsDropdown} key={Icon.displayName} />)}
                    </div>
                </div>
            </div>
        </Popover>

    )
}
