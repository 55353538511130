import { Button } from 'app/presentation/components/agent';
import { TrainingDrawer } from 'app/presentation/components/agent/Training/Drawers';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';
import { useState } from 'react';
import { TbCirclePlus, TbPencil } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { TrainingService } from 'services/training';
import { TrainingResourceTypes } from 'services/training/interfaces';
import * as S from './styles';

interface ExcerptsProps {
    enabled: number;
    total: number;
}

export const ExcerptsPanel = ({ enabled, total }: ExcerptsProps) => {
    const { routeGetter, textGetter } = useText();
    const route = routeGetter('', true);
    const [trainingDrawerActive, setTrainingDrawerActive] = useState(false);
    const { agent, notifyAgentTrainingChanged } = useAgent();
    const navigate = useNavigate();
    const panelText = textGetter('agent.panel.excerpts');
    const commonsText = textGetter('agent.panel.commons');

    const service = new TrainingService();

    return (
        <S.Container>
            <TrainingDrawer
                animateSlide={trainingDrawerActive}
                handleClose={() => setTrainingDrawerActive(false)}
                isEditing={true}
                contentType={TrainingResourceTypes.Excerpt}
                notifyAgentTrainingChanged={notifyAgentTrainingChanged}
            />
            <p className="eligible-count">
                {panelText('used', {
                    eligible: enabled,
                    total: total,
                })}
            </p>

            <p className="eligible-description"> {panelText('description')}</p>

            <div className="buttons-container">
                <Button
                    variant="primary"
                    type="button"
                    onClick={() => setTrainingDrawerActive(true)}
                >
                    <TbCirclePlus></TbCirclePlus>
                    {panelText('add')}
                </Button>

                <Button
                    variant="primary"
                    onClick={() =>
                        navigate(
                            route(
                                'agent.home',
                                { param: agent ? agent.id : '0' },
                                'agent.training',
                            ),
                        )
                    }
                >
                    <TbPencil></TbPencil>
                    <span>{commonsText('button.manage')}</span>
                </Button>
            </div>
        </S.Container>
    );
};
