import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
`;

export const Input = styled.input`
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 21px;
    width: 100%;
    height: 100%;
    padding-left: 45px;

    :focus {
        border-color: black;
    }
`;

export const InputIcon = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;

    svg {
        width: 20px !important;
        height: 20px !important;
    }
`;
