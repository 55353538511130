import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';

type IconProps = {
    hideTooltip?: boolean;
    darkmode?: boolean;
};

export const Trash: React.FC<IconProps> = ({
    hideTooltip = true,
    darkmode = false,
}) => {
    const intl = useIntl();
    return (
        <Tooltip
            title={routeTranslate(intl, 'home.documents.delete')}
            disableHoverListener={hideTooltip}
            placement="top"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.5 5H4.16667H17.5"
                    stroke="#718096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.8332 5.00001V16.6667C15.8332 17.1087 15.6576 17.5326 15.345 17.8452C15.0325 18.1577 14.6085 18.3333 14.1665 18.3333H5.83317C5.39114 18.3333 4.96722 18.1577 4.65466 17.8452C4.3421 17.5326 4.1665 17.1087 4.1665 16.6667V5.00001M6.6665 5.00001V3.33334C6.6665 2.89131 6.8421 2.46739 7.15466 2.15483C7.46722 1.84227 7.89114 1.66667 8.33317 1.66667H11.6665C12.1085 1.66667 12.5325 1.84227 12.845 2.15483C13.1576 2.46739 13.3332 2.89131 13.3332 3.33334V5.00001"
                    stroke="#718096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.3335 9.16667V14.1667"
                    stroke="#718096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.6665 9.16667V14.1667"
                    stroke="#718096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Tooltip>
    );
};
