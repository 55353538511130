import React from 'react';
import { PlansContent } from './styles';
import { Button, HeaderModalDetails } from 'app/presentation/components';
import { FormattedMessage, useIntl } from 'react-intl';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import NotAut from 'assets/images/svg/plans-owner.svg';
import NotAutDark from 'assets/images/svg/plans-owner-dark.svg';
import { PlansService } from 'services/plans';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { routeTranslate } from 'utils/intl';
import { isDebug } from 'utils/isDebug';
import { churnkeyTranslation } from './i18nChurnkey';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import Tooltip from '@mui/material/Tooltip';
import { IoMdInfinite } from 'react-icons/io';

const plansService = new PlansService();
const CHURNKEY_APP_ID = process.env.REACT_APP_CHURNKEYAPP_ID as string;

const PlansAndSignatures: React.FC = () => {
    const intl = useIntl();
    const { settings, subscriptionDetails, workspace, limits } =
        useUserConfig();
    const isOwner = workspace.owner_id === settings.id;
    const [loading, setLoading] = React.useState(false);
    const [loadingCancel, setLoadingCancel] = React.useState(false);
    const { darkmode } = useDarkMode();
    const lang = settings.user_settings.language.lang.split('-')[0];

    if (isDebug()) {
        console.log('Debuging');
    }

    const isTrialPlan = subscriptionDetails?.status === 'trialing';

    const handleOpenStripePortal = async () => {
        setLoading(true);
        const req = {
            workspace_id: workspace.id,
            return_url: window.location.href,
        };
        const data = await plansService.createPortalSession(req);

        if (data.url) {
            const urlRedirect = data.url as string;

            window.location.href = urlRedirect;
        }
    };

    const handlePlans = async () => {
        if (isTrialPlan) {
            return await handleOpenStripePortal();
        } else {
            const req = {
                workspace_id: workspace.id,
                return_url: window.location.href,
            };
            const data = await plansService.createPortalSession(req);

            if (data.url && subscriptionDetails) {
                const id = subscriptionDetails.subscription_id;
                const urlRedirect = data.url as string;

                window.location.href = urlRedirect.concat(
                    `/subscriptions/${id}/update`,
                );
            }
        }
    };

    const handleCancelPlan = async () => {
        try {
            setLoadingCancel(true);

            const resultChurnkey: { jwt: string } =
                await plansService.createChurnkeySession();
            //@ts-ignore
            if (window && window.churnkey && subscriptionDetails && workspace) {
                //@ts-ignore
                await window.churnkey.init('show', {
                    customerId: workspace.stripe_customer_id,
                    subscriptionId: subscriptionDetails.subscription_id,
                    authHash: resultChurnkey.jwt,
                    appId: CHURNKEY_APP_ID,
                    mode: isDebug() ? 'test' : 'live',
                    provider: 'stripe',
                    i18n: { lang: lang, ...churnkeyTranslation },
                });
                setLoadingCancel(false);
            } else {
                throw new Error();
            }
        } catch (error) {
            await handleOpenStripePortal();
            setLoadingCancel(false);
        }
    };

    const limitsContent = [
        {
            label: routeTranslate(intl, 'billing.users'),
            tooltip: '',
            used: limits.used_seats,
            total: limits.total_seats,
            unique: false,
        },
        {
            label: routeTranslate(intl, 'billing.brands'),
            tooltip: '',
            used: limits.used_brands,
            total: limits.total_brands,
            unique: false,
        },
        {
            label: routeTranslate(intl, 'billing.tone'),
            tooltip: '',
            used: limits.used_brand_voices,
            total: limits.total_brand_voices,
            unique: true,
        },
        {
            label: routeTranslate(intl, 'billing.memories'),
            tooltip: '',
            used: limits.used_custom_brand_memories,
            total: limits.total_custom_brand_memories,
            unique: true,
        },
    ];

    return (
        <PlansContent className={darkmode ? 'plansDark' : ''}>
            {isOwner ? (
                <>
                    <HeaderModalDetails
                        title={routeTranslate(intl, 'definitions.billing')}
                    />

                    <div className="limits">
                        {limitsContent.map((item, i) => {
                            return (
                                <div
                                    className={`content ${i > 0 && 'margin-l'}`}
                                >
                                    <p>
                                        {item.label}{' '}
                                        <Tooltip
                                            title={item.tooltip}
                                            placement="bottom-start"
                                            sx={{
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                            }}
                                        >
                                            <AiOutlineExclamationCircle
                                                size={20}
                                                style={{
                                                    color: '#A0AEC0',
                                                }}
                                            />
                                        </Tooltip>
                                    </p>

                                    <span>
                                        {item.total === -1 ? (
                                            <IoMdInfinite />
                                        ) : (
                                            <>
                                                {!item.unique && (
                                                    <>
                                                        {item.used}{' '}
                                                        <FormattedMessage id="billing.expression" />{' '}
                                                    </>
                                                )}{' '}
                                                {item.total}
                                            </>
                                        )}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    <div id="signatures">
                        <div className="box" id="signature">
                            <div className="header">
                                <div>
                                    <h1>
                                        <FormattedMessage id="billing.subscription" />
                                    </h1>
                                </div>
                                <div id="period">
                                    <span>
                                        {routeTranslate(
                                            intl,
                                            `billing.plan.${
                                                subscriptionDetails?.period ===
                                                ''
                                                    ? 'test'
                                                    : subscriptionDetails?.period
                                            }`,
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div id="description">
                                <p>
                                    <FormattedMessage id="billing.plan.description" />{' '}
                                    <strong>
                                        {subscriptionDetails?.plan_name || ''}
                                    </strong>
                                </p>
                                <div id="buttons">
                                    <Button
                                        onClick={handlePlans}
                                        loading={loading}
                                        style={{
                                            background: darkmode ? '#000' : '',
                                            color: darkmode ? '#fff' : '',
                                            borderColor: darkmode
                                                ? '#E2E8F0'
                                                : '',
                                        }}
                                    >
                                        <FormattedMessage id="billing.plan.change" />
                                    </Button>
                                    <Button
                                        className="button-link"
                                        onClick={() => handleOpenStripePortal()}
                                        loading={loading}
                                        style={{
                                            background: darkmode
                                                ? '#3A3A3A'
                                                : '',
                                            color: darkmode ? '#A09FA5' : '',
                                            borderColor: darkmode
                                                ? '#3A3A3A'
                                                : '',
                                        }}
                                    >
                                        <FormattedMessage id="billing.plan.details" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="account">
                        <div className="box" id="fature">
                            <div>
                                <h1>
                                    <FormattedMessage id="billing.bills" />
                                </h1>
                                <p>
                                    <FormattedMessage id="billing.bills.description" />
                                </p>
                            </div>
                            <div>
                                <Button
                                    loading={loading}
                                    className="buttonHistory"
                                    onClick={() => handleOpenStripePortal()}
                                    style={
                                        isTrialPlan
                                            ? {}
                                            : {
                                                  background: darkmode
                                                      ? '#000'
                                                      : '',
                                                  color: darkmode ? '#fff' : '',
                                                  borderColor: darkmode
                                                      ? '#E2E8F0'
                                                      : '',
                                              }
                                    }
                                    disabled={isTrialPlan}
                                >
                                    <FormattedMessage id="billing.bills.verify" />
                                </Button>
                            </div>
                        </div>
                        <div className="box" id="fature">
                            <div>
                                <h1 style={{ fontSize: 18 }}>
                                    <FormattedMessage id="billing.terminateAccount" />
                                </h1>
                                <p style={{ fontSize: 14 }}>
                                    <FormattedMessage id="billing.terminateAccount.description" />
                                </p>

                                <Button
                                    loading={loadingCancel}
                                    className="buttonHistory"
                                    onClick={handleCancelPlan}
                                    style={{
                                        background: darkmode ? '#000' : '',
                                        color: darkmode ? '#fff' : '',
                                        borderColor: darkmode ? '#E2E8F0' : '',
                                        width: 94,
                                        height: 42,
                                        fontSize: 14,
                                    }}
                                >
                                    <FormattedMessage id="billing.terminateAccount.button" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="containerNotAuthorized">
                    <div className="notAuthorized">
                        <img
                            src={darkmode ? NotAutDark : NotAut}
                            width={174}
                            height={218}
                        />

                        <h2
                            style={{
                                color: darkmode ? '#fff' : '',
                            }}
                        >
                            <FormattedMessage id="billing.notAut.title" />
                        </h2>
                        <p>
                            <FormattedMessage id="billing.notAut.description" />
                        </p>
                    </div>
                </div>
            )}
        </PlansContent>
    );
};

export default PlansAndSignatures;
