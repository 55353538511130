import React from 'react';
import * as S from './styles';
import { FormFieldContainer } from '../FormField';
import { HexColorPicker } from 'react-colorful';

interface Props {
    color: string;
    onChangeColor: (value: string) => void;
    title: string;
    text: string;
}

export const ColorInput: React.FC<Props> = ({
    color,
    onChangeColor,
    title,
    text,
}) => {
    const presetColors = [
        '#058434',
        '#38BDF8',
        '#FF784B',
        '#FFFFFF',
        '#9061FF',
    ];
    const [colorPicker, setColorPicker] = React.useState<boolean>(false);

    const colorPickerContainerRef = React.useRef<HTMLDivElement>(null);
    const colorPickerBottonRef = React.useRef<HTMLButtonElement>(null);
    const handleClickOutsideColorPicker = (event: MouseEvent) => {
        if (!colorPicker) {
            return;
        }

        const colorPickerContainer = colorPickerContainerRef.current;
        const colorPickerBotton = colorPickerBottonRef.current;
        let element = event.target as HTMLElement | null;
        if (colorPickerContainer) {
            while (element) {
                if (
                    element == colorPickerContainer ||
                    element == colorPickerBotton
                ) {
                    return;
                }
                element = element.parentElement;
            }
            event.stopPropagation();
            closeColorPicker();
        }
    };

    React.useEffect(() => {
        if (!colorPicker) {
            return;
        }
        document.addEventListener('click', handleClickOutsideColorPicker);

        return () => {
            document.removeEventListener(
                'click',
                handleClickOutsideColorPicker,
            );
        };
    }, [colorPicker]);

    const handleColorPickerClick = () => {
        if (colorPicker) {
            setColorPicker(false);
        } else {
            setColorPicker(true);
        }
    };

    const closeColorPicker = () => {
        setColorPicker(false);
    };

    const onColorInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        if (color.length < 1 || color.length > 7) {
            return;
        }
        if (color[0] != '#') {
            return;
        }
        if (color.length > 1 && !/^[0-9a-fA-F]+$/.test(color.substring(1))) {
            return;
        }
        onChangeColor(color);
    };

    return (
        <S.ColorInput>
            <FormFieldContainer info={{ title, text }}>
                <div className="button-container">
                    <button
                        type="button"
                        onClick={handleColorPickerClick}
                        ref={colorPickerBottonRef}
                    >
                        <input
                            value={color}
                            onChange={onColorInputChange}
                            onClick={event => {
                                event.stopPropagation();
                            }}
                        />
                        <S.ColorCircle bgColor={color} />
                    </button>
                    {colorPicker && (
                        <div ref={colorPickerContainerRef}>
                            <ColorPicker
                                color={color}
                                onChange={onChangeColor}
                                presetColors={presetColors}
                            ></ColorPicker>
                        </div>
                    )}
                </div>
            </FormFieldContainer>
        </S.ColorInput>
    );
};

interface ColorPickerProps {
    color: string;
    onChange: (value: string) => void;
    presetColors: string[];
}

const ColorPicker: React.FC<ColorPickerProps> = ({
    color,
    onChange,
    presetColors,
}) => {
    return (
        <S.ColorPicker>
            <HexColorPicker color={color} onChange={onChange} />
            <div className="picker_footer">
                {presetColors.map(presetColor => (
                    <button
                        type="button"
                        key={presetColor}
                        className="footer_buttons"
                        style={{ background: presetColor }}
                        onClick={() => onChange(presetColor)}
                    />
                ))}
            </div>
        </S.ColorPicker>
    );
};
