export interface Team {
    id: number;
    user_id: number;
    user: {
        id: number;
        created_at: string;
        updated_at: string;
        deleted_at: any;
        firebase_id: string;
        first_name: string;
        last_name: string;
        email: string;
    };
    type: TypeUser;
    created_at: string;
    updated_at: string;
    deleted_at: any;
    firebase_id: string;
    first_name: string;
    last_name: string;
    word_limit?: string;
}

export enum TypeUser {
    VIEWER = 0,
    EDITOR = 1,
    ADMIN = 2,
    OWNER = 3,
}
