import { Menu, MenuList, Paper } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import TableDocuments from 'app/presentation/components/dashboard/TableDocuments';
import {
    Copy,
    Doc,
    Edit,
    NewModel,
    Pdf,
    Receipt,
    Search,
    Trash,
} from 'app/presentation/components/icons';
import useDocuments from 'app/presentation/hooks/useDocuments';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from 'utils/useDocumentTitle';
import {
    Button,
    Container,
    Input,
    Select,
} from '../../../app/presentation/components';
import Loading from '../../../app/presentation/components/common/Loading';
import { PaginationContainer } from '../../../app/presentation/components/dashboard/TableDocuments/styles';
import useDarkMode from '../../../app/presentation/hooks/useDarkMode';
import useUserConfigs from '../../../app/presentation/hooks/useUserConfigs';
import EmptyImageDark from '../../../assets/images/svg/documents-empty-dark.svg';
import EmptyImage from '../../../assets/images/svg/documents-empty.svg';
import { routeTranslate } from '../../../utils/intl';
import { DocumentsContainer, Empty, Header, MuiMenuHeader } from './styles';

const Documents: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
    const [selectedDocuments, SetSelectedDocuments] = React.useState<
        Array<{ id: number | undefined; title: string }>
    >([]);
    const open = Boolean(anchorEl);

    const { darkmode } = useDarkMode();
    const { register, watch } = useForm<FieldValues>();
    const {
        documents,
        selectDocument,
        deleteDocument,
        handleSelectDocument,
        getDocuments,
        pageInfo,
        exportToMsWord,
        exportToPDF,
        copyDocument,
        loading: loadingDocument,
    } = useDocuments();

    const { project, workspace } = useUserConfigs();
    const intl = useIntl();
    const navigate = useNavigate();

    const currentPageName = routeTranslate(intl, 'route.documents').replace(
        '/',
        '',
    );
    const pageTitle =
        currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1);
    useDocumentTitle(pageTitle);

    const watchSearch = watch('search') ?? '';
    const totalPages = pageInfo?.TotalPages ?? 0;
    const currentPage = pageInfo?.CurrentPage ?? 0;
    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.documents');

    React.useEffect(() => {
        const fetchData = async () => {
            await getDocuments(undefined, undefined, watchSearch);
        };

        fetchData().catch(console.error);
    }, [project, watchSearch]);

    const handleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
        id: number | undefined,
    ) => {
        if (!event || !id) return;

        handleSelectDocument(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCreateDocument = () => {
        navigate(`/${path}${routeTranslate(intl, 'route.document')}`);
    };

    const exportPDF = async () => {
        handleClose();

        if (!selectDocument) return;

        await exportToPDF(selectDocument);
    };

    const exportWord = async () => {
        handleClose();

        if (!selectDocument) return;

        const msWord = await exportToMsWord(selectDocument);

        const nav = window.navigator as any;
        const downloadLink = document.createElement('a');

        if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(msWord.blob, msWord.filename);
        } else {
            document.body.appendChild(downloadLink);
            downloadLink.href = msWord.url;
            downloadLink.download = msWord.filename;
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    };

    const handleDelete = async () => {
        handleClose();

        await deleteDocument(selectDocument);
    };

    const handleCopy = async () => {
        handleClose();
        const docSelectedTitle =
            documents?.filter(doc => doc.id === selectDocument)[0].title || '';
        const title = `${routeTranslate(
            intl,
            'document.copy.text',
        )} ${docSelectedTitle}`;

        const data = [
            {
                id: selectDocument,
                title: title,
            },
        ];
        await copyDocument(workspace.id, data);
    };

    const handlePage = async (pageNumber: number, limitPerpage?: number) => {
        await getDocuments(pageNumber, limitPerpage);
    };

    const handleChange = async (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        await handlePage(value, pageInfo?.PerPage ?? 0);
    };

    const handlePerPage = async (value: string) => {
        await handlePage(1, +value);
    };

    const handleEdit = (id?: number) => {
        if (!documents) return;

        const document = documents.find(document => document.id === id);

        if (!document || !id) return;

        const path =
            routeTranslate(intl, 'route.urlLang') +
            routeTranslate(intl, 'route.backoffice');

        navigate(
            `/${path}${routeTranslate(intl, 'route.documents')}${routeTranslate(
                intl,
                'route.document',
            )}/${document.rtc_id}/${id}`,
        );
    };

    return (
        <Container>
            <Header>
                <h1 className="dark-text-white">
                    {routeTranslate(intl, 'navmenu.documents')}
                </h1>
                <form className={darkmode ? 'dark' : ''}>
                    <div className="input">
                        <div className="icon">
                            <Search darkmode={darkmode} />
                        </div>
                        <Input
                            id="search"
                            placeholder={routeTranslate(
                                intl,
                                'label.search.document',
                            )}
                            register={register}
                        />
                    </div>
                </form>
                <div id="options">
                    <Button onClick={handleCreateDocument} icon={<NewModel />}>
                        {routeTranslate(intl, 'document.button.new')}
                    </Button>
                </div>
            </Header>
            {documents && documents.length === 0 ? (
                <Empty>
                    <img
                        src={darkmode ? EmptyImageDark : EmptyImage}
                        width={218}
                        height={218}
                    />
                    <h1>{routeTranslate(intl, 'document.empty.title')}</h1>
                    <p>{routeTranslate(intl, 'document.empty.description')}</p>
                </Empty>
            ) : (
                <>
                    <DocumentsContainer
                        className={`${darkmode ? 'dark' : ''} box-dark-mode`}
                    >
                        {loadingDocument && <Loading open={loadingDocument} />}
                        {!loadingDocument &&
                            documents &&
                            documents.map(document => {
                                const date = new Date(document.created_at);
                                const dateString = date.toLocaleString(
                                    'pt-BR',
                                    {
                                        month: 'long',
                                        year: 'numeric',
                                        day: 'numeric',
                                    },
                                );

                                return (
                                    <div key={document.id} className="box">
                                        <div className="header">
                                            <div className="icon">
                                                <Receipt />
                                            </div>
                                            <div className="flex">
                                                <h1>{document.title}</h1>
                                            </div>
                                            <div
                                                className="dots"
                                                onClick={e =>
                                                    handleClick(e, document.id)
                                                }
                                            >
                                                <HiOutlineDotsHorizontal
                                                    size={22}
                                                    style={{ color: '#718096' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="infos">
                                            <div className="info">
                                                <strong>
                                                    {routeTranslate(
                                                        intl,
                                                        'home.documents.createBy',
                                                    )}
                                                </strong>
                                                <span>
                                                    {
                                                        document.creator
                                                            ?.first_name
                                                    }
                                                </span>
                                            </div>
                                            <div className="info">
                                                <strong>
                                                    {routeTranslate(
                                                        intl,
                                                        'home.documents.modify',
                                                    )}
                                                </strong>
                                                <span>{dateString}</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        <article id="table">
                            {!loadingDocument && documents && (
                                <>
                                    <TableDocuments
                                        data={documents}
                                        menuOptions={handleClick}
                                        SetSelectedDocuments={
                                            SetSelectedDocuments
                                        }
                                        selectedDocuments={selectedDocuments}
                                    />
                                    <PaginationContainer
                                        className={darkmode ? 'dark' : ''}
                                    >
                                        <div className="per-page">
                                            <span>Mostrar resultado:</span>
                                            <Select
                                                name="perPage"
                                                id="perPage"
                                                register={register}
                                                onChange={async e => {
                                                    await register(
                                                        'perPage',
                                                    ).onChange(e);

                                                    await handlePerPage(
                                                        e.target.value,
                                                    );
                                                }}
                                                options={[
                                                    { name: '6', value: '6' },
                                                    { name: '1', value: '1' },
                                                ]}
                                            />
                                        </div>
                                        <div className="pages">
                                            <Pagination
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </PaginationContainer>
                                </>
                            )}
                        </article>
                    </DocumentsContainer>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{
                            width: '100%',
                            '& > .MuiPaper-root': {
                                borderRadius: '12px',
                                boxShadow:
                                    '8px 12px 24px rgba(93, 106, 131, 0.1)',
                                background: darkmode ? '#1D1D1F' : '',
                            },
                        }}
                        anchorOrigin={{
                            vertical: 260,
                            horizontal: -50,
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Paper
                            sx={{
                                width: 160,
                                boxShadow: 0,
                                background: darkmode ? '#1D1D1F' : '',
                            }}
                        >
                            <MenuList
                                sx={{
                                    padding: 0,
                                    boxShadow: 0,
                                    background: darkmode ? '#1D1D1F' : '',
                                }}
                                disablePadding
                                dense
                            >
                                <MuiMenuHeader
                                    className={darkmode ? 'muiHeaderDark' : ''}
                                >
                                    <ul>
                                        <li
                                            onClick={() =>
                                                handleEdit(selectDocument)
                                            }
                                            className="icon"
                                            onMouseEnter={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#151718' : '')
                                            }
                                            onMouseLeave={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#1D1D1F' : '')
                                            }
                                        >
                                            <Edit />
                                            <span>
                                                {routeTranslate(
                                                    intl,
                                                    'home.documents.edit',
                                                )}
                                            </span>
                                        </li>
                                        <li
                                            onClick={() => handleCopy()}
                                            className="icon"
                                            onMouseEnter={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#151718' : '')
                                            }
                                            onMouseLeave={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#1D1D1F' : '')
                                            }
                                        >
                                            <Copy darkmode={darkmode} />
                                            <span>
                                                {routeTranslate(
                                                    intl,
                                                    'home.documents.duplicate',
                                                )}
                                            </span>
                                        </li>
                                        <li
                                            onClick={() => exportPDF()}
                                            className="icon"
                                            onMouseEnter={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#151718' : '')
                                            }
                                            onMouseLeave={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#1D1D1F' : '')
                                            }
                                        >
                                            <Pdf />
                                            <span className="bold">PDF</span>
                                        </li>
                                        <li
                                            onClick={() => exportWord()}
                                            className="icon"
                                            onMouseEnter={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#151718' : '')
                                            }
                                            onMouseLeave={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#1D1D1F' : '')
                                            }
                                        >
                                            <Doc />
                                            <span className="bold">
                                                Microsoft Word
                                            </span>
                                        </li>
                                        <li
                                            onClick={() => handleDelete()}
                                            className="icon"
                                            onMouseEnter={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#151718' : '')
                                            }
                                            onMouseLeave={(e: any) =>
                                            (e.target.style.backgroundColor =
                                                darkmode ? '#1D1D1F' : '')
                                            }
                                        >
                                            <Trash />
                                            <span className="bold">
                                                {routeTranslate(
                                                    intl,
                                                    'home.documents.delete',
                                                )}
                                            </span>
                                        </li>
                                    </ul>
                                </MuiMenuHeader>
                            </MenuList>
                        </Paper>
                    </Menu>
                </>
            )}
        </Container>
    );
};

export default Documents;
