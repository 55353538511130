import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.footer`
    padding: 0 0 !important;
    background-color: #ffffff;
    height: var(--footer-height);

    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

export const BodyPadding = createGlobalStyle`
  .App {
    padding-bottom: var(--footer-height);
  }
`;

export const FooterContainer = styled.div`
    ul {
        list-style: none;
        display: flex;
        justify-content: space-between;

        li {
            color: #718096;
            font-size: 16px;
            font-weight: 600;
        }
    }
`;

export const PagSeguroIcon = styled.div`
    &#mobile {
        display: none;
    }

    &#desktop {
        font-weight: normal;

        img {
            margin-bottom: 10px;
        }
    }

    margin-top: 20px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 0.7em;

    img {
        max-width: 200px;
    }
`;

export const Copy = styled.div`
    margin-top: 50px;
    text-align: center;
    font-size: 0.8em;
`;

export const MethodPayments = styled.div`
    padding: 20px 0;

    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    grid-gap: 10px;

    > div {
        max-width: 80px;
    }

    img {
        width: 100%;
    }
`;
