import styled, { css } from 'styled-components';
import { CardSelectThemeVariants } from 'styles/themes/themes';

interface Props {
    height: string;
    iconGap: string;
    textGap: string;
    cardGap: string;
    padding: string;
    iconBGWidth?: string;
    iconBGHeight?: string;
    iconBGRadius?: string;
    align: 'left' | 'center';
    titleHeight: string;
    titleSize: string;
    variant: CardSelectThemeVariants;
}

export const CardSelect = styled.div<Props>`
    ${({
        variant,
        theme: {
            components: { cardSelect },
        },
        ...rest
    }) => css`
        width: 100%;
        .card-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: ${rest.cardGap};
            flex-direction: column;

            @media (min-width: 64em) {
                flex-direction: row;
            }
            .card {
                cursor: pointer;
                transition: all linear 0.15s;
                width: 100%;
                height: ${rest.height};
                padding: ${rest.padding};
                display: flex;
                align-items: ${rest.align};
                justify-content: center;
                flex-direction: column;
                text-align: ${rest.align};
                background-color: ${cardSelect[variant].background.color};
                border: 1px solid;
                border-color: ${cardSelect[variant].border.color};
                border-radius: 8px;

                .icon-container {
                    transition: all linear 0.15s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: ${rest.iconBGRadius};
                    justify-content: ${rest.iconGap};
                    width: ${rest.iconBGWidth ? rest.iconBGWidth : '32px'};
                    min-height: ${rest.iconBGHeight
                        ? rest.iconBGHeight
                        : '32px'};
                    height: ${rest.iconBGHeight ? rest.iconBGHeight : '32px'};
                    margin-bottom: ${rest.iconGap};
                    background-color: ${rest.iconBGHeight || rest.iconBGWidth
                        ? cardSelect[variant].iconBG.color
                        : 'transparent'};
                    border: ${rest.iconBGHeight || rest.iconBGWidth
                        ? '0.5px solid #0000001a'
                        : 'none'};
                    svg {
                        color: ${cardSelect[variant].title.color};
                        width: ${rest.iconBGWidth ? '24px' : '32px'};
                        height: ${rest.iconBGHeight ? '24px' : '32px'};
                    }
                }

                .card-title {
                    margin-bottom: ${rest.textGap};
                    color: ${cardSelect[variant].title.color};
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    font-size: ${rest.titleSize};
                    line-height: ${rest.titleHeight};
                    user-select: none;
                }

                .card-text {
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    font-size: 14px;
                    line-height: 21px;
                    color: ${cardSelect[variant].text.color};
                    user-select: none;
                }

                :hover,
                &.active-card {
                    background-color: ${cardSelect[variant].background.active};
                    border-color: ${cardSelect[variant].border.active};
                    .card-title {
                        color: ${cardSelect[variant].title.active};
                    }

                    .icon-container {
                        background-color: ${rest.iconBGHeight ||
                        rest.iconBGWidth
                            ? cardSelect[variant].iconBG.active
                            : 'transparent'};

                        svg {
                            color: ${cardSelect[variant].title.active};
                        }
                    }
                }
            }

            .card-disabled {
                opacity: 40%;
                cursor: auto;
            }

            .card-disabled:hover {
                background-color: ${cardSelect[variant].background.color};
                border-color: ${cardSelect[variant].border.color};
                .card-title {
                    color: ${cardSelect[variant].title.color};
                }

                .icon-container {
                    background-color: ${rest.iconBGHeight || rest.iconBGWidth
                        ? cardSelect[variant].iconBG.color
                        : 'transparent'};
                    svg {
                        color: ${cardSelect[variant].title.color};
                    }
                }
            }
        }

        select {
            display: none;
        }
    `}
`;
