import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .content-modal {
        background: #ffffff;
        border-radius: 16px;
        width: 98%;
        max-width: 544px;
        height: 100%;
        max-height: 586px;
        padding: 40px 33px 47px 33px;
        overflow: auto;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            background: #edf2f7;
            border-radius: 50px;
        }

        header {
            text-align: right;

            svg {
                cursor: pointer;
            }
        }

        #title {
            margin-bottom: 30px;

            h1 {
                text-align: center;
                font-size: 1.5em;
                color: #1a202c;
                font-weight: 700;
                margin-bottom: 12px;
            }

            p {
                text-align: center;
                font-weight: 500;
                font-size: 0.875em;
                color: #718096;
            }
        }

        form {
            > div {
                margin-bottom: 25px;
            }

            > div:last-of-type {
                margin-top: 5px;
            }
        }

        input {
            border-radius: 8px;
            border: 1px solid #e2e8f0;
        }

        .container-submit {
            display: flex;
            gap: 5px;

            button {
                width: 164px;
                height: 48px;
                font-size: 0.875em;
                border-radius: 12px;
            }

            button[type='submit'] {
                background: #d0ff61;
                color: #1a202c;
            }

            button[type='button'] {
                border: 1px solid #a0aec0;
                border-radius: 12px;
                color: #a0aec0;
                background-color: #fff;
            }
        }
    }
    &.modalNewProjectDark {
        .content-modal {
            background: #1d1d1f;
            input,
            textarea {
                background: #3a3a3a;
                border-color: #3a3a3a !important;
                color: #fff;

                &:focus {
                    border-color: #fff !important;
                }
            }
            p {
                color: #a09fa5;
            }
            #title {
                h1 {
                    color: #fff;
                }
            }

            button[type='button'] {
                border-color: #fff;
                color: #fff;
                background-color: #1d1d1f;
            }
        }
    }
`;
