import React from 'react';

export const Standard: React.FC = () => (
    <svg
        width="34"
        height="33"
        viewBox="0 0 34 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_13095_25402)">
            <path
                d="M31 0.5H3C1.89543 0.5 1 1.39543 1 2.5V30.5C1 31.6046 1.89543 32.5 3 32.5H31C32.1046 32.5 33 31.6046 33 30.5V2.5C33 1.39543 32.1046 0.5 31 0.5Z"
                fill="white"
                stroke="#A09FA5"
            />
            <path
                d="M27.5 10.5H6.5C5.94772 10.5 5.5 10.9477 5.5 11.5V21.5C5.5 22.0523 5.94772 22.5 6.5 22.5H27.5C28.0523 22.5 28.5 22.0523 28.5 21.5V11.5C28.5 10.9477 28.0523 10.5 27.5 10.5Z"
                fill="black"
            />
            <path d="M28.5 4H5.5V5H28.5V4Z" fill="#A09FA5" />
            <path d="M28.5 28H5.5V29H28.5V28Z" fill="#A09FA5" />
            <path d="M13.5 25H5.5V26H13.5V25Z" fill="#A09FA5" />
            <path d="M23.5 7H5.5V8H23.5V7Z" fill="#A09FA5" />
        </g>
        <defs>
            <clipPath id="clip0_13095_25402">
                <rect
                    width="33"
                    height="33"
                    fill="white"
                    transform="translate(0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
