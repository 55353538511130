import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AgentService } from 'services/agent';
import {
    Action,
    Agent,
    CreateTagAction,
    FormField,
    Message,
    MessageAsset,
    Question,
    WorkingHours,
} from 'services/agent/interfaces';
import { AssetType, AssetsService } from 'services/uploaded_files';
import { Image, parseSVGToPNG } from 'utils/files';
import { IMessage } from '../components/agent';
import AgentContext from '../contexts/AgentContext';
import useSnackbar from '../hooks/useSnackbar';
import useText from '../hooks/useText';
import useUserConfig from '../hooks/useUserConfigs';
import { ProviderProps } from './interfaces/provider-default-props.interface';

const AgentProvider: React.FC<ProviderProps> = ({ children }) => {
    const [changeAgentLoading, setChangeAgentLoading] =
        React.useState<boolean>(false);
    const [agent, setAgent] = React.useState<Agent>();
    const [actions, setActions] = React.useState<Action[]>();
    const [agents, setAgents] = React.useState<Agent[]>();
    const [agentsCurrentPage, setAgentsCurrentPage] = React.useState<number>(1);
    const [agentsTotalPages, setAgentsTotalPages] = React.useState<number>(0);
    const { activeBrand } = useUserConfig();
    const agentService = new AgentService();
    const assetsService = new AssetsService();
    const { error, success } = useSnackbar();
    const [agentsLoading, setAgentsLoading] = React.useState<boolean>(false);
    const { routeGetter } = useText();
    const [agentTrainingChanges, setAgentTrainingChanges] =
        React.useState<number>(0);
    const agentRoutes = routeGetter('agent');
    const navigate = useNavigate();

    React.useEffect(() => {
        getAgents();

        if (agent && activeBrand && agent.brand_id != activeBrand.id) {
            setAgent(undefined);
            navigate(agentRoutes('home'));
        }
    }, [activeBrand]);

    const getAgents = (page = 1): void => {
        if (!activeBrand) return;
        setAgentsLoading(true);

        const result = agentService.getAll(activeBrand.id, page);
        result
            .then(
                res => {
                    setAgents(res.data);
                    setAgentsCurrentPage(res.page);
                    setAgentsTotalPages(res.total_pages);
                },
                () => {
                    error('agent.get-agents');
                },
            )
            .finally(() => setAgentsLoading(false));
    };
    const changeAgent = (agentID: number) => {
        const storedID = localStorage.getItem('activeAgentId');
        if (storedID && parseInt(storedID) == agentID && agent) {
            return;
        }

        setChangeAgentLoading(true);
        localStorage.setItem('activeAgentId', String(agentID));
        getAgent()
            .catch(() => {
                navigate(agentRoutes('home'));
            })
            .finally(() => setChangeAgentLoading(false));
    };

    const getAgent = async (): Promise<void> => {
        const agentID = localStorage.getItem('activeAgentId');
        const brandID =
            (activeBrand && activeBrand.id.toString()) ||
            localStorage.getItem('activeBrandId');
        if (!agentID || !brandID) return Promise.reject();

        try {
            const result = await agentService.get(parseInt(agentID, 10));

            if (result.brand_id != parseInt(brandID, 10)) {
                error('agent.get-agent');
                return Promise.reject();
            }
            setAgent(result);
        } catch (err) {
            error('agent.get-agent');
            return Promise.reject();
        }

        try {
            const result = await agentService.getAllActions(
                parseInt(agentID, 10),
            );
            setActions(result.filter(action => action.type != 'message'));
        } catch (err) {
            error('agent.get-actions');
            return Promise.reject();
        }

        return Promise.resolve();
    };

    const createAgent = async (name: string, type: string): Promise<void> => {
        if (!activeBrand) return Promise.reject<void>();

        return agentService
            .create({
                name,
                type,
                brand_id: activeBrand.id,
            })
            .then(
                () => {
                    success('agent.create-agent');
                    getAgents();
                },
                () => error('agent.create-agent'),
            );
    };

    const deleteAgent = async (agenteID: number): Promise<void> => {
        return agentService.delete(agenteID).then(
            () => {
                success('agent.delete-agent');
                getAgents();
            },
            () => error('agent.delete-agent'),
        );
    };

    const addQuestion = async (
        answer: string,
        question: string,
        languageID: number,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();

        const questionList = agent.initial_questions.find(
            list => list.language.id == languageID,
        );
        const questions = questionList ? [...questionList.questions] : [];
        questions.push({
            answer: answer,
            question: question,
            order: questions.length + 1,
        });

        return agentService
            .upsertQuestions(agent.id, {
                language_id: languageID,
                questions: questions,
            })
            .then(
                () => {
                    success('agent.add-question');
                    getAgent();
                },
                () => error('agent.add-question'),
            );
    };

    const updateQuestion = async (
        questionID: number,
        answer: string,
        question: string,
        languageID: number,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();

        const questionList = agent.initial_questions.find(
            list => list.language.id == languageID,
        );
        if (!questionList) return Promise.reject<void>();

        let questions = [...questionList.questions];
        questions = questions.map(quest => {
            if (quest.id == questionID) {
                return {
                    id: questionID,
                    answer: answer,
                    question: question,
                    order: quest.order,
                };
            }
            return quest;
        });

        return agentService
            .upsertQuestions(agent.id, {
                language_id: languageID,
                questions: questions,
            })
            .then(
                () => {
                    success('agent.update-question');
                    getAgent();
                },
                () => error('agent.update-question'),
            );
    };

    const updateAgent = async (
        agentID: number,
        name: string,
    ): Promise<void> => {
        return agentService
            .updateAgent(agentID, {
                name: name,
            })
            .then(
                () => {
                    success('agent.update-agent');
                    getAgents();
                },
                () => error('agent.update-agent'),
            );
    };

    const updateQuestions = async (
        questions: Question[],
        languageID: number,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();

        questions.forEach((question, index) => {
            question.order = index + 1;
        });

        return agentService
            .upsertQuestions(agent.id, {
                language_id: languageID,
                questions: questions,
            })
            .then(
                () => {
                    success('agent.update-questions');
                    getAgent();
                },
                () => error('agent.update-questions'),
            );
    };

    const updateForm = async (
        fields: FormField[],
        frequency: string,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();

        fields.forEach((field, index) => {
            field.order = index + 1;
        });

        return agentService
            .updateForm(agent.id, {
                form_fields: fields,
                form_frequency: frequency,
            })
            .then(
                () => {
                    success('agent.update-form');
                    getAgent();
                },
                () => error('agent.update-form'),
            );
    };

    const updateLanguageConfig = async (
        languageID: number,
        multiLanguageSupport: boolean,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();

        return agentService
            .updateLanguageConfig(agent.id, {
                multi_language_support: multiLanguageSupport,
                additional_languages: [
                    {
                        is_default: true,
                        language_id: languageID,
                    },
                ],
            })
            .then(
                () => {
                    success('agent.update-language-config');
                    getAgent();
                },
                () => error('agent.update-language-config'),
            );
    };

    const updateSecurityConfig = async (
        authorizedDomains: string[],
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();

        return agentService
            .updateSecurityConfig(agent.id, {
                authorized_domains: authorizedDomains,
            })
            .then(
                () => {
                    success('agent.update-security-config');
                    getAgent();
                },
                () => error('agent.update-security-config'),
            );
    };

    const parseImage = async (
        image: Image,
        type: AssetType,
    ): Promise<string> => {
        if (!activeBrand) return Promise.reject<string>();
        switch (image.type) {
            case 'file':
                let imageFile = image.file;
                if (imageFile.type == 'image/svg+xml') {
                    try {
                        imageFile = await parseSVGToPNG(imageFile);
                    } catch (err) {
                        return Promise.reject(err);
                    }
                }
                return assetsService
                    .upload(imageFile, type, activeBrand.id)
                    .then(
                        res => {
                            return res.path;
                        },
                        err => {
                            error('agent.save-image');
                            return err;
                        },
                    );
            case 'url':
                return image.url;
        }
    };

    const parseMessage = async (
        message: IMessage,
        order: number,
    ): Promise<Message> => {
        if (!agent) return Promise.reject<Message>();

        const assets: MessageAsset[] = [];
        const { img, gif_url } = message;
        if (img) {
            try {
                assets.push({
                    type: 'image',
                    url: await parseImage(img, 'agent_message_asset'),
                });
            } catch (err) {
                error('agent.save-image');
                return Promise.reject<Message>();
            }
        }

        if (gif_url) {
            assets.push({
                type: 'gif',
                url: gif_url,
            });
        }

        return {
            assets: assets,
            message: message.text,
            order: order,
        };
    };

    const parseMessages = async (messages: IMessage[]): Promise<Message[]> => {
        return Promise.all(
            messages.map((message, index) => parseMessage(message, index + 1)),
        );
    };

    const updateWelcomeMessages = async (
        messages: IMessage[],
        showWelcomeMessage: boolean,
        languageID: number,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();

        try {
            const parsedMessages = await parseMessages(messages);
            return await agentService
                .upsertWelcomeMessages(agent.id, {
                    messages: parsedMessages,
                    language_id: languageID,
                    show_welcome_messages: showWelcomeMessage,
                })
                .then(() => {
                    success('agent.update-messages');
                    getAgent();
                });
        } catch (err) {
            error('agent.update-messages');
            return Promise.reject<void>();
        }
    };

    const updateIdentity = async (
        name: string,
        subheading: string,
        tone: string,
        backgroundColor: string,
        actionColor: string,
        logo: Image,
        avatar: Image,
        icon: Image,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();
        try {
            const avatarURL = await parseImage(avatar, 'agent_identity_avatar');
            const logoURL = await parseImage(logo, 'agent_identity_logo');
            const iconURL = await parseImage(icon, 'agent_identity_chat_icon');

            return await agentService
                .updateIdentity(agent.id, {
                    action_color: actionColor,
                    agent_name: name,
                    agent_subheading: subheading,
                    avatar_url: avatarURL,
                    background_color: backgroundColor,
                    icon_url: iconURL,
                    logo_url: logoURL,
                    tone: tone,
                })
                .then(() => {
                    success('agent.update-identity');
                    getAgent();
                });
        } catch (err) {
            error('agent.update-identity');
            return Promise.reject<void>();
        }
    };

    const updateConfig = async (
        messageLength: string,
        widgetPosition: string,
        showAnswersSources: boolean,
        messageSound: boolean,
        showJarbasLink: boolean,
        maxMessagesPerUser: number,
        shortBusinessDescription: string,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();
        return agentService
            .updateConfig(agent.id, {
                max_messages_per_user: maxMessagesPerUser,
                message_length: messageLength,
                message_sound: messageSound,
                show_answers_sources: showAnswersSources,
                show_jarbas_link: showJarbasLink,
                widget_position: widgetPosition,
                short_business_description: shortBusinessDescription,
            })
            .then(
                () => {
                    success('agent.update-config');
                    getAgent();
                },
                () => error('agent.update-config'),
            );
    };

    const updateWorkingHours = async (
        workingHours: WorkingHours[],
        replyTime: string,
        timeZone: string,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();
        return agentService
            .updateWorkingHours(agent.id, {
                working_hours: workingHours,
                reply_time: replyTime,
                time_zone: timeZone,
            })
            .then(
                () => {
                    success('agent.update-working-hours');
                    getAgent();
                },
                () => error('agent.update-working-hours'),
            );
    };

    const updateAdditionalMessages = async (
        errorMessage: string,
        noSourcesFound: string,
        maxMessagesPerUser: string,
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();
        return agentService
            .updateAdditionalMessages(agent.id, {
                error_message: errorMessage,
                max_messages_per_user_warning: maxMessagesPerUser,
                no_sources_found_message: noSourcesFound,
            })
            .then(
                () => {
                    success('agent.update-additional-messages');
                    getAgent();
                },
                () => error('agent.update-additional-messages'),
            );
    };

    const updateBehavior = async (
        passToATeammate: boolean,
        askForRating: boolean,
        helpMessages: IMessage[],
        actions: Action[],
    ): Promise<void> => {
        if (!agent) return Promise.reject<void>();

        const orderedActions = actions.map<Action>((action, index) => {
            const { order, ...rest } = action;
            return {
                order: index + 1,
                ...rest,
            };
        });

        const createTagActions = orderedActions
            .filter(action => {
                return action.type == 'add_tag' && action.fields.length == 2;
            })
            .map<CreateTagAction>(action => {
                return {
                    order: action.order,
                    tag: action.fields[1].value,
                };
            });

        const otherActions = orderedActions.filter(action => {
            return action.type != 'add_tag' || action.fields.length == 1;
        });

        let parsedMessages: Message[] | undefined;
        try {
            parsedMessages = await parseMessages(helpMessages);
        } catch (err) {
            error('agent.update-messages');
            return Promise.reject<void>();
        }

        return agentService
            .updateBehavior(agent.id, {
                actions: otherActions,
                ask_for_rating: askForRating,
                create_tag_actions: createTagActions,
                help_messages: parsedMessages,
                pass_to_a_teammate: passToATeammate,
            })
            .then(
                () => {
                    success('agent.update-behavior');
                    getAgent();
                },
                () => error('agent.update-behavior'),
            );
    };

    return (
        <AgentContext.Provider
            value={{
                agent,
                agents,
                actions,
                agentsCurrentPage,
                agentsTotalPages,
                agentsLoading,
                changeAgentLoading,
                getAgents,
                getAgent,
                changeAgent,
                createAgent,
                deleteAgent,
                addQuestion,
                updateQuestion,
                updateQuestions,
                updateAgent,
                updateForm,
                updateLanguageConfig,
                updateSecurityConfig,
                updateWelcomeMessages,
                updateIdentity,
                updateConfig,
                updateWorkingHours,
                updateAdditionalMessages,
                updateBehavior,
                notifyAgentTrainingChanged: () => {
                    setTimeout(
                        () => setAgentTrainingChanges(prev => prev + 1),
                        1500,
                    );
                },
                agentTrainingChangeCount: agentTrainingChanges,
            }}
        >
            {children}
        </AgentContext.Provider>
    );
};

export default AgentProvider;
