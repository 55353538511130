import React from 'react';

export const Bell: React.FC = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.6667 5.83333C11.6667 5.21449 11.9125 4.621 12.3501 4.18342C12.7877 3.74583 13.3812 3.5 14 3.5C14.6188 3.5 15.2123 3.74583 15.6499 4.18342C16.0875 4.621 16.3333 5.21449 16.3333 5.83333C17.6731 6.46687 18.8153 7.45305 19.6374 8.68618C20.4595 9.91932 20.9305 11.3529 21 12.8333V16.3333C21.0878 17.0587 21.3447 17.7532 21.7499 18.3611C22.1552 18.9691 22.6976 19.4733 23.3333 19.8333H4.66666C5.30242 19.4733 5.84477 18.9691 6.25004 18.3611C6.65532 17.7532 6.91219 17.0587 6.99999 16.3333V12.8333C7.06948 11.3529 7.54051 9.91932 8.3626 8.68618C9.18469 7.45305 10.3268 6.46687 11.6667 5.83333"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5 19.8333V21C10.5 21.9282 10.8687 22.8185 11.5251 23.4749C12.1815 24.1312 13.0717 24.5 14 24.5C14.9283 24.5 15.8185 24.1312 16.4749 23.4749C17.1313 22.8185 17.5 21.9282 17.5 21V19.8333"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
