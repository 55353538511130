import styled, { css } from 'styled-components';

export const ConfigPanel = styled.div`
    ${({
        theme: {
            agent: {
                pages: {
                    config: {
                        configPanel: { hr },
                    },
                },
            },
        },
    }) => css`
        .config-panel-content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .user-limit-field-container {
                border-top: 1px solid ${hr.color};
                padding-top: 20px;
            }
            .additional-messages-button {
                width: fit-content;
                border: none;
                gap: 8px;
                height: 30px;
                span {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.2px;
                }
                svg {
                    height: 16px;
                    width: 16px;
                }
            }
            .business-desc {
                width: 100%;

                textarea {
                    height: 130px;
                    width: 100%;
                }
            }
            .buttons-container {
                padding-top: 15px;
                border-top: 1px solid ${hr.color};
                margin-top: 15px;
                display: flex;
                gap: 8px;
                flex-direction: row-reverse;
                justify-content: start;

                @media (min-width: 64em) {
                    flex-direction: row;
                    justify-content: end;
                }

                button {
                    height: 34px;
                    padding: 8px 18px 8px 18px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    letter-spacing: 0.2px;
                    gap: 4px;
                    border-radius: 6px;
                    .desktop {
                        display: none;

                        @media (min-width: 64em) {
                            display: inline;
                        }
                    }

                    &.save-button {
                        width: 103px;
                        @media (min-width: 64em) {
                            width: 190px;
                        }
                    }
                }
            }
        }
    `}
`;
