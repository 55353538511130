import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// TODO theming
export const CustomCheckbox: React.FC<CheckboxProps> = (props) => {
    return (
        <Checkbox
            {...props}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<DoneIcon style={{ backgroundColor: '#d0ff61', borderRadius: 1000 }} />}
            sx={{ color: '#E2E8F0', '&.Mui-checked': { color: '#1A202C' } }}
        />
    )
}
