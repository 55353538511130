import { api } from '../api';
import { Answer, Invite } from '../../interfaces/notifications.interface';

export class WorkspacesService {
    private readonly path = '/workspaces';

    async getAll() {
        const { data } = await api.get(this.path);

        return data;
    }

    async update(id: number, name: string, company_domain: string) {
        return api.put(this.path, { id, name, company_domain });
    }

    async sendInvite(invite: Invite) {
        const { data } = await api.post(`${this.path}/invites`, invite);

        return data;
    }

    async answerInvitation(answer: Answer) {
        await api.get('/auth/me');

        const { data } = await api.get(
            `${this.path}/invites/${answer.token}?reply=${answer.answer}`,
        );

        return data;
    }

    async getWorkspaceUsage(workspaceId: number) {
        const data = await api.get(`${this.path}/${workspaceId}/usage/details`);

        return data;
    }

    async getSubscriptionDetails(workspaceId: number) {
        const data = await api.get(
            `${this.path}/${workspaceId}/subscription/details`,
        );

        return data;
    }
    async updateWordLimit(workspaceId: number, userId: number, limit: number) {
        const data = await api.put(
            `${this.path}/${workspaceId}/users/${userId}`,
            {
                word_limit: limit,
                type: 1,
            },
        );

        return data;
    }

    async getInviteLink(workspaceId: number) {
        const data = await api.get(`${this.path}/${workspaceId}/invites`);

        return data;
    }
}
