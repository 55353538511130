import { ThemeVariants } from '../themes';
import {
    actionContainer,
    messageContainer,
    modalAdditionalMessages,
    modalDeleteAgent,
    modalNewAgent,
    modalNewContent,
    modalNewQuestion,
    questionContainer,
    userLimitInput,
} from './components';
import { behavior, config, home, integration } from './pages';

export const getAgentTheme = (variant: ThemeVariants) => {
    return {
        pages: {
            home: home[variant],
            behavior: behavior[variant],
            config: config[variant],
            integration: integration[variant],
        },
        components: {
            messageContainer: messageContainer[variant],
            modalAdditionalMessages: modalAdditionalMessages[variant],
            modalDeleteAgent: modalDeleteAgent[variant],
            modalNewAgent: modalNewAgent[variant],
            modalNewQuestion: modalNewQuestion[variant],
            modalNewContent: modalNewContent[variant],
            questionContainer: questionContainer[variant],
            actionContainer: actionContainer[variant],
            userLimitInput: userLimitInput[variant],
        },
    };
};
