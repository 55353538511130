import React from 'react';
import * as S from './styles';
import {
    SwitchCheckbox,
    FormFieldContainer,
    InfoMessage,
    LanguageSelect,
    Button,
} from 'app/presentation/components/agent';
import useAgent from 'app/presentation/hooks/useAgent';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IconCheck } from '@tabler/icons-react';
import useText from 'app/presentation/hooks/useText';

export const MultilanguagePanel = () => {
    const { textGetter } = useText();
    const panelText = textGetter('agent.panel.multilanguage');
    const commonsText = textGetter('agent.panel.commons');

    const [defaultLanguage, setDefaultLanguage] = React.useState<number>(-1);
    const [multiLanguage, setMultiLanguage] = React.useState<boolean>(false);

    const { handleSubmit } = useForm<{}>();
    const { agent, updateLanguageConfig } = useAgent();
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    const loadForm = () => {
        if (!agent || !agent.config) return;
        setMultiLanguage(agent.config.multi_language_support);

        const agentLang = agent.config.additional_languages.find(
            lang => lang.is_default,
        );
        if (!agentLang || !agentLang.language || !agentLang.language.id) return;
        setDefaultLanguage(agentLang.language.id);
    };

    React.useEffect(() => {
        loadForm();
    }, [agent]);

    const onSubmit: SubmitHandler<{}> = async () => {
        setFormSubmitLoading(true);
        updateLanguageConfig(defaultLanguage, multiLanguage).finally(() =>
            setFormSubmitLoading(false),
        );
    };

    return (
        <S.MultilanguagePanel onSubmit={handleSubmit(onSubmit)}>
            <p className="panel-desc">{panelText('desc')}</p>
            <SwitchCheckbox
                name="multilanguage"
                setValue={setMultiLanguage}
                value={multiLanguage}
                info={{
                    title: panelText('multi-language.title'),
                }}
            ></SwitchCheckbox>
            {!multiLanguage && (
                <InfoMessage
                    warning
                    type="yellow"
                    text={panelText('info-message')}
                ></InfoMessage>
            )}
            {!multiLanguage && (
                <FormFieldContainer
                    info={{
                        title: panelText('default-language.title'),
                        text: panelText('default-language.subtitle'),
                    }}
                >
                    <LanguageSelect
                        variant="white"
                        value={defaultLanguage}
                        onChange={setDefaultLanguage}
                        width={'265px'}
                    ></LanguageSelect>
                </FormFieldContainer>
            )}
            <div className="buttons-container">
                <Button variant="secondary" type="button" onClick={loadForm}>
                    {commonsText('button.cancel')}
                </Button>
                <Button
                    variant="dark"
                    type="submit"
                    loading={formSubmitLoading}
                    className="save-button"
                >
                    <IconCheck></IconCheck>
                    <span>
                        {commonsText('button.save')}
                        <span className="desktop">
                            {' '}
                            {commonsText('button.save-suffix')}
                        </span>
                    </span>
                </Button>
            </div>
        </S.MultilanguagePanel>
    );
};
