import styled, { css } from 'styled-components';

export const QuestionsContainer = styled.div`
    ${({
        theme: {
            agent: {
                components: {
                    questionContainer: {
                        question: { background, border, text },
                    },
                },
            },
        },
    }) => css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        .questions-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .question-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .question {
                    border-radius: 8px;
                    width: 225px;
                    @media (min-width: 64em) {
                        width: 473px;
                    }
                    padding: 15px 12px;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    border: 1px solid ${border.color};
                    background-color: ${background.color};
                    .question-text,
                    .answer-text {
                        white-space: nowrap;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        color: ${text.color};
                    }
                    .question-text {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 0.2px;
                    }
                    .answer-text {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
                .drag-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .drag-icon,
                .delete-button,
                .edit-button {
                    width: 18px;
                    height: 18px;
                    margin-left: 6px;
                    background-color: transparent;
                    border: none;
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
        .add-button-container {
            display: flex;
            justify-content: right;
            margin-top: -5px;
            button {
                gap: 4px;
                width: 101px;
                height: 34px;
                padding: 8px 12px;
                border-radius: 6px;
                svg {
                    height: 18px;
                    width: 18px;
                }
                span {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.2px;
                }
            }
        }
    `}
`;
