import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import { VoiceTone } from 'app/presentation/hooks/interfaces/brand';
import { Step1, Step2, Step3, Step4 } from './steps';
import { gridColumnVisibilityModelSelector } from '@mui/x-data-grid';
import { CommentsDisabledOutlined } from '@mui/icons-material';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    callback: () => void;
    editing: boolean;
}

export type ExtractVoiceToneMethods = 'url' | 'text' | undefined;

const ModalNewVoiceTone: React.FC<ModalProps> = ({
    active,
    handleClose,
    callback,
}) => {
    const [currentStep, setCurrentStep] = React.useState(1);
    const [selectedExtractVoiceToneMethod, setSelectedExtractVoiceToneMethod] =
        React.useState<ExtractVoiceToneMethods>(undefined);
    const [voiceToneName, setVoiceToneName] = React.useState('');
    const [voiceToneURL, setVoiceToneURL] = React.useState('');
    const [voiceToneText, setVoiceToneText] = React.useState('');
    const [generatedVoiceTone, setGeneratedVoiceTone] = React.useState('');
    const [isGenerating, setIsGenerating] = React.useState<boolean>(false);
    const [voiceToneFormality, setVoiceToneFormality] = React.useState(0);
    const [voiceToneHumor, setVoiceToneHumor] = React.useState(0);

    const resetInputValues = () => {
        setGeneratedVoiceTone('');
        setVoiceToneName('');
        setVoiceToneURL('');
        setVoiceToneText('');
        setSelectedExtractVoiceToneMethod(undefined);
        setVoiceToneFormality(0);
    };

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            resetInputValues();
            handleClose();
        }
    };
    const { darkmode } = useDarkMode();
    const intl = useIntl();

    const handleCloseSuccess = () => {
        resetInputValues();

        handleClose();
    };

    const subHeaderTranslations = (): string[] => {
        const translationsArr: string[] = [];

        const translationPrefixes: string[] = [
            'new-voice-tone-modal.extract-voice',
            'new-voice-tone-modal.define-voice',
            'new-voice-tone-modal.formality',
            'new-voice-tone-modal.humor',
        ];

        translationPrefixes.forEach((elem, i) => {
            translationsArr[i] = routeTranslate(intl, elem);
        });

        return translationsArr;
    };

    const renderCurrentStep = (): JSX.Element | undefined => {
        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        selectedExtractVoiceToneMethod={
                            selectedExtractVoiceToneMethod
                        }
                        setSelectedExtractVoiceToneMethod={
                            setSelectedExtractVoiceToneMethod
                        }
                        setCurrentStep={setCurrentStep}
                        setGeneratedVoiceTone={setGeneratedVoiceTone}
                        generatedVoiceTone={generatedVoiceTone}
                        isGenerating={isGenerating}
                        setIsGenerating={setIsGenerating}
                        voiceToneName={voiceToneName}
                        setVoiceToneName={setVoiceToneName}
                        voiceToneURL={voiceToneURL}
                        setVoiceToneURL={setVoiceToneURL}
                        voiceToneText={voiceToneText}
                        setVoiceToneText={setVoiceToneText}
                    />
                );

            case 2:
                return (
                    <Step2
                        selectedMethod="url"
                        generatedVoiceTone={generatedVoiceTone}
                        setGeneratedVoiceTone={setGeneratedVoiceTone}
                        callback={() => {console.log()}}
                        handleClose={handleClose}
                        editing
                        isGenerating={isGenerating}
                        setGenerating={setIsGenerating}
                        setCurrentStep={setCurrentStep}
                    ></Step2>
            );

            case 3:
                return (
                    <Step3
                        setVoiceToneFormality={setVoiceToneFormality}
                        setCurrentStep={setCurrentStep}
                    />
                );

            case 4:
                return (
                    <Step4
                        setCurrentStep={setCurrentStep}
                        setVoiceToneHumor={setVoiceToneHumor}
                    ></Step4>
                )

            default:
                return undefined;
        }
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'modalNewToneDark' : ''}
            >
                <div className="content-modal">

                    { <button onClick={()=> setIsGenerating((prev) => !prev)}>SetGenerating</button> }

                    <header>
                        <h1>{routeTranslate(intl, 'modal.tone.title')}</h1>

                        <IoMdClose
                            size={25}
                            onClick={() => {
                                handleClose();
                                setCurrentStep(1);
                                resetInputValues();
                            }}
                        />
                    </header>

                    <>
                        <div className="sub-header">
                            {subHeaderTranslations().map((elem, i) => (
                                <>
                                    <div
                                        className={
                                            currentStep == i + 1
                                                ? 'sub-header-item-current'
                                                : currentStep > i + 1
                                                ? 'sub-header-item-completed'
                                                : 'sub-header-item-inactive'
                                        }
                                    >
                                        <div className="sub-header-item-ball">
                                            <p>{i + 1}</p>
                                        </div>
                                        <p>{elem}</p>
                                    </div>

                                    {i < 3 && <hr className="styled-hr" />}
                                </>
                            ))}
                        </div>

                        <div className="subheader-hr" />
                    </>

                    {renderCurrentStep()}
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalNewVoiceTone;
