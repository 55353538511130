import styled, { css } from 'styled-components';

export const Integrations = styled.div`
    ${({ theme }) => {
        const { background, panel } = theme.agent.pages.integration;
        const { agentLink, widgetScript } = panel;
        return css`
            position: relative;
            padding: 30px 0px;
            display: flex;
            justify-content: center;
            max-height: calc(100vh - 80px);
            overflow-y: scroll;
            background-color: ${background.color};
            @media (min-width: 64em) {
                padding: 30px 28px;
                justify-content: start;
            }

            .integrations-form-container {
                min-width: 331px;
                width: 331px;
                height: fit-content;

                @media (min-width: 64em) {
                    width: 100%;
                    max-width: 1014px;
                }

                .title {
                    letter-spacing: 0.2px;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    margin-bottom: 30px;
                }

                .panel {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    width: 100%;
                    border: 1px solid ${panel.border.color};
                    border-radius: 8px;
                    padding: 24px;
                    min-height: 200px;

                    .desc {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                        letter-spacing: 0.2px;
                        color: ${panel.text.color};
                    }

                    .share-your-agent {
                        display: flex;
                        gap: 8px;

                        .text-container {
                            padding: 0px 16px;
                            border: 1px solid ${agentLink.border.color};
                            background-color: ${agentLink.background.color};
                            border-radius: 8px;
                            height: 44px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            overflow-x: scroll;
                            overflow-y: hidden;
                            white-space: nowrap;

                            @media (min-width: 64em) {
                                width: 400px;
                            }

                            span {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 21px;
                                letter-spacing: 0.2px;
                            }
                        }

                        button {
                            width: 44px;
                            height: 44px;
                            border-radius: 8px;
                            border-color: ${agentLink.button.border.color};
                            background-color: ${agentLink.button.background
                                .color};

                            svg {
                                color: ${agentLink.button.icon.color};
                                width: 20px;
                                height: 20px;
                                min-width: 20px;
                                min-height: 20px;
                            }
                        }
                    }

                    .config-select-container {
                        padding-top: 20px;
                        border-top: 1px solid ${panel.hr.color};
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .config-select-title {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 21px;
                            letter-spacing: 0.2px;
                        }
                    }

                    .code-container {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        .title {
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                            letter-spacing: 0.2px;
                            margin-bottom: 0px;
                        }

                        .position-select-container {
                            width: 100%;

                            @media (min-width: 64em) {
                                width: 410px;
                            }
                        }

                        .widget-script-container {
                            display: flex;
                            flex-direction: column;
                            gap: 12px;

                            span {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 21px;
                                letter-spacing: 0.2px;
                            }

                            .text-container {
                                gap: 20px;
                                padding: 20px;
                                border: 1px solid ${widgetScript.border.color};
                                background-color: ${widgetScript.background
                                    .color};
                                border-radius: 8px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                overflow-x: scroll;
                                overflow-y: hidden;

                                span {
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 21px;
                                    letter-spacing: 0.2px;
                                }

                                button {
                                    padding: 0px 12px;
                                    height: 34px;
                                    border-radius: 6px;
                                    width: fit-content;
                                    gap: 7px;

                                    span {
                                        font-size: 12px;
                                        font-weight: 600;
                                        line-height: 18px;
                                    }

                                    svg {
                                        width: 18px;
                                        height: 18px;
                                        min-width: 18px;
                                        min-height: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `;
    }}
`;
