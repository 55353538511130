import { api } from '../api';

export class NotificationsService {
    private readonly path = '/user/notifications';

    async getNotifications() {
        const { data } = await api.get(this.path);

        return data;
    }

    async isRead(id: number) {
        const { data } = await api.get(`${this.path}/${id}/read`);

        return data;
    }
}
