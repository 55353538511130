import styled from 'styled-components';

type DotsProps = {
    selected: boolean;
}

export const Dots = styled.div<DotsProps>`
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.selected ? "rgba(241, 241, 241, 1)" : "white"};
`;
