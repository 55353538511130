import React from 'react';
import * as S from './styles';
import {
    IconTrash,
    IconPencil,
    IconGripVertical,
    IconCirclePlus,
} from '@tabler/icons-react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from '@hello-pangea/dnd';
import {
    LanguageSelect,
    ModalNewQuestion,
    InfoMessage,
    Button,
} from 'app/presentation/components/agent';
import useAgent from 'app/presentation/hooks/useAgent';
import { Question } from 'services/agent/interfaces';
import useText from 'app/presentation/hooks/useText';

export const QuestionsContainer: React.FC = () => {
    const [selectedQuestions, setSelectedQuestions] =
        React.useState<Question[]>();
    const [defaultLanguage, setDefaultLanguage] = React.useState<number>();
    const [languageID, setLanguageID] = React.useState<number>(1);
    const [editingQuestion, setEditingQuestion] = React.useState<Question>();
    const [modalNewQuestion, setModalNewQuestion] =
        React.useState<boolean>(false);

    const { agent, updateQuestions } = useAgent();
    React.useEffect(() => {
        if (!agent) {
            return;
        }

        const { config } = agent;
        if (!config) {
            return;
        }

        const defaultAgentLanguage = config.additional_languages.find(
            lang => lang.is_default,
        );

        if (defaultAgentLanguage) {
            setDefaultLanguage(defaultAgentLanguage.language.id);
        }
    }, [agent]);

    React.useEffect(() => {
        if (!agent) {
            return;
        }
        const { initial_questions } = agent;
        const questionList = initial_questions.find(
            list => list.language.id == languageID,
        );

        const questions = questionList ? questionList.questions : [];
        questions.sort((a, b) => a.order - b.order);

        setSelectedQuestions(questions);
    }, [languageID, agent]);

    const reorder = (
        list: Question[],
        startIndex: number,
        endIndex: number,
    ) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const openModalEditQuestion = (question: Question) => {
        setEditingQuestion(question);
        setModalNewQuestion(true);
    };
    const closeModalNewQuestion = () => {
        setEditingQuestion(undefined);
        setModalNewQuestion(false);
    };
    const openModalNewQuestion = () => {
        setEditingQuestion(undefined);
        setModalNewQuestion(true);
    };

    const onDragEnd = (e: DropResult) => {
        if (e.combine && selectedQuestions != undefined) {
            const items = [...selectedQuestions];
            items.splice(e.source.index, 1);
            setSelectedQuestions(items);
            return;
        }
        if (!e.destination) {
            return;
        }
        if (selectedQuestions != undefined) {
            const items = reorder(
                selectedQuestions,
                e.source.index,
                e.destination.index,
            );
            updateQuestions(items, languageID);
            setSelectedQuestions(items);
        }
    };

    const handleDeleteQuestion = async (question: Question) => {
        if (!selectedQuestions) {
            return Promise.reject();
        }

        const index = selectedQuestions.indexOf(question);
        if (index > -1) {
            const newData = selectedQuestions.filter((el, i) => {
                return i != index;
            });
            return updateQuestions(newData, languageID);
        } else {
            return Promise.resolve();
        }
    };

    const { textGetter } = useText();
    const t = textGetter('agent.components.questions-container');

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <S.QuestionsContainer>
                {languageID && (
                    <LanguageSelect
                        onChange={setLanguageID}
                        value={languageID}
                        defaultLanguage={defaultLanguage}
                    ></LanguageSelect>
                )}
                {selectedQuestions && selectedQuestions.length > 0 && (
                    <Droppable
                        droppableId="questionsDroppableZone"
                        direction="vertical"
                    >
                        {provided => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="questions-container"
                            >
                                {selectedQuestions.map((item, index) => {
                                    return (
                                        <Draggable
                                            draggableId={String(index)}
                                            key={index}
                                            index={index}
                                            isDragDisabled={
                                                selectedQuestions.length < 2
                                            }
                                        >
                                            {providedDraggable => (
                                                <div
                                                    ref={
                                                        providedDraggable.innerRef
                                                    }
                                                    className="question-container"
                                                    {...providedDraggable.draggableProps}
                                                >
                                                    {selectedQuestions.length >
                                                        1 && (
                                                        <div
                                                            className="drag-icon"
                                                            {...providedDraggable.dragHandleProps}
                                                        >
                                                            <IconGripVertical></IconGripVertical>
                                                        </div>
                                                    )}
                                                    <div className="question">
                                                        <p className="question-text">
                                                            {item.question}
                                                        </p>
                                                        <p className="answer-text">
                                                            {item.answer}
                                                        </p>
                                                    </div>
                                                    <Button
                                                        type="button"
                                                        variant="outline"
                                                        className="edit-button"
                                                        onClick={() => {
                                                            openModalEditQuestion(
                                                                item,
                                                            );
                                                        }}
                                                    >
                                                        <IconPencil></IconPencil>
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        variant="danger"
                                                        className="delete-button"
                                                        loadingSize="18px"
                                                        onClick={async () =>
                                                            handleDeleteQuestion(
                                                                item,
                                                            )
                                                        }
                                                    >
                                                        <IconTrash></IconTrash>
                                                    </Button>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                )}
                <InfoMessage
                    type="yellow"
                    text={t('info-message')}
                ></InfoMessage>
                <div className="add-button-container">
                    <Button
                        type="button"
                        variant="primary"
                        onClick={openModalNewQuestion}
                    >
                        <IconCirclePlus></IconCirclePlus>
                        <span>{t('add-question')}</span>
                    </Button>
                </div>
                <ModalNewQuestion
                    active={modalNewQuestion}
                    handleClose={closeModalNewQuestion}
                    item_editing={editingQuestion}
                    languageID={languageID}
                ></ModalNewQuestion>
            </S.QuestionsContainer>
        </DragDropContext>
    );
};
