import styled from "styled-components";

export const IconContainer = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 5px;

    cursor: pointer;

    :hover {
        background-color: #CFD6F7;
    }
`;
