import styled from 'styled-components';
import Menu from '@mui/material/Menu';

export const Row = styled.div`
    display: flex;

    width: 100%;
    min-width: 1057px;
    height: 80px;

    border-bottom: 1px solid rgba(241, 241, 241, 1);

    .collection-name {
        width: 52%;
        min-width: 596px;

        overflow: hidden;

        .collapse-icon {
            margin-left: 11px;
            margin-right: 11px;

            cursor: pointer;
        }

        .collection-icon-box {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            margin-left: 8px;
            margin-right: 15px;  

            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
        }

        .collection-icon {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            margin-left: 8px;
            margin-right: 15px;

            background-color: rgba(247, 250, 252, 1);
            border: 1px solid rgba(226, 232, 240, 1);
        
            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            :hover {
                background-color: #CFD6F7;
            }

            svg {
                background-color: inherit;
            }
        }

        .icons-popped {
            background-color: #CFD6F7;
            border: 2px solid #BAC2F6;
        }

        .inputs-container {
            width: 344px;
            height: 49px;

            display: flex;
            flex-direction: column;
            gap: 4px;

            input {
                background-color: inherit;
                :hover, :focus {
                    border-bottom: 1px dashed lightgray;
                }
            }
            
            .input-top {
                width: 193px;
                min-width: 193px;
                max-width: 385px;
                height: 24px;
                font-weight: 700;
                border: 0;
            
                margin-right: auto;
                
                ::placeholder {
                    color: rgba(126, 126, 126, 1);
                }
            }

            .input-bottom {
                width: 161px;
                min-width: 161px;
                max-width: 386px;
                height: 24px;
                border: 0;

                color: #7e7e7e;
                margin-right: auto;

                ::placeholder {
                    color: rgba(126, 126, 126, 0.5);
                }
            }
        }

    }

    .collection-status {
        display: flex;
        align-items: center;
        margin-right: auto;
        gap: 8px;

        width: 19%;
        min-width: 150px;
        margin-left: 5%;


        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;

          
        }

    }

    .articles-count {
        width: 13%;
        min-width: 150px;
    }

    .row-actions {
        display: flex;
        flex-shrink: 0;
        align-items: center; 
        justify-content: end;

        margin-left: auto;

        min-width: 105px;
        gap: 12px; 
    }

    .memory-title {
        font-weight: 700;
        size: 16px;

        span:hover {
            color: blue;
            border-bottom: 1px solid blue;
        }
    }

    div {
        display: flex;
        align-items: center;
    }

    .empty {
        color: grey;
    }
`;

// this styled div should be a separated component
export const Ellipsis = styled.div`
    width: 10px;
    height: 10px;
    background-color: rgba(160, 159, 165, 1);

    border-radius: 50%;

    &.published {
        background-color: rgba(34, 197, 94, 1) ;
    }

    &.draft {
        background-color: rgba(255, 200, 55, 1);
    }

    &.missing-title {
        background-color: rgba(221, 51, 51, 1);
    }
`;

export const StyledMenu = styled(Menu)`
    .MuiPaper-root {
        width: 185px;
        border-radius: 12px;
        box-shadow: 8px 12px 24px 0px rgba(93, 106, 131, 0.1);

        li {
            height: 32px;
        }
    }

    .MuiList-root {
        margin: 8px;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .actions-dropdown-btn {
        display: flex;
        gap: 12px;
        font-weight: 600;
        font-size: 12px;
        padding-left: 8px;
        padding-bottom: 7px;
        padding-top: 7px;
        height: 32px;
    }
`;
