import React from 'react';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import Tooltip from '@mui/material/Tooltip';

type ITooltip = {
    title: string;
    children?: any;
};

const CustomTooltip: React.FC<ITooltip> = ({ title, children }) => {
    const { darkmode } = useDarkMode();

    return (
        <Tooltip
            title={title}
            placement="top"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            {children}
        </Tooltip>
    );
};

export default CustomTooltip;
