import { getAgentTheme } from './agent/themes';
import { getCommonsTheme } from './commons/themes';
export * from './commons/components';

export type ThemeVariants = 'light' | 'dark';

export const getTheme = (variant: ThemeVariants) => {
    return {
        agent: getAgentTheme(variant),
        ...getCommonsTheme(variant),
    };
};
