import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    videoLink: string | undefined;
}

const ModalTutorialVideo: React.FC<ModalProps> = ({
    active,
    handleClose,
    videoLink,
}) => {
    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal onClick={handleCloseContainer}>
                {active && (
                    <div>
                        <div className="modal">
                            <div className="modal-content">
                                <iframe
                                    src={videoLink}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                )}
            </ContainerModal>
        </Fade>
    );
};

export default ModalTutorialVideo;
