import { Excerpt } from 'services/excerpts/interfaces';
import { URLContent } from 'services/url_contents/interfaces';

export enum TrainingResourceTypes {
    BrandCenter,
    URLContent,
    Excerpt,
}

export enum TrainingStatus {
    None,
    InProgress,
    Completed,
}

export interface BrandMemoryContent {
    id: number;
    created_at: string;
    updated_at: string;
    content: string;
    publish_status: 'published' | ' draft';
    last_editor: any | null;
}

export interface Training {
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
    resource_type: TrainingResourceTypes;
    status: TrainingStatus;
    enabled: boolean;
    brand_memory_content: BrandMemoryContent | null;
    url_content: URLContent | null;
    excerpt: Excerpt | null;
    getResourceID: () => number;
    getLastUpdatedAt: () => string;
}

export interface UpdateUseMemoriesRequest {
    use: boolean;
}

export interface GetUseMemoriesResponse {
    use: boolean;
}

export interface GetEligibleInformationResponse {
    eligible: number;
    total: number;
}

type EnabledInformation = {
    enabled: number;
    total: number;
};

export interface GetEnabledTrainingsInformationResponse {
    brand_center: EnabledInformation;
    external_content: EnabledInformation;
    excerpts: EnabledInformation;
    files: EnabledInformation;
}

export interface GetTrainingStatusReponse {
    map: {
        excerpts: string;
        memories: string;
        urls: string;
    };
    brand_id: number;
}

export interface UpdateTrainingEnabledRequest {
    resource_id: number;
    resource_type: TrainingResourceTypes;
    enabled: boolean;
}
