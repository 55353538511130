import styled from 'styled-components';

export const Header = styled.div`
    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.5em;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    .beta {
        min-width: 66px;
        min-height: 24px;
        background: #d0ff61;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-left: 12px;
    }

    #desc {
        display: none;
    }

    .input > div {
        margin: 0;
    }

    .input {
        position: relative;
        display: flex;
        align-items: center;

        .icon {
            position: absolute;
            left: 18px;
        }

        input {
            padding-left: 58px;
        }
    }

    #options {
        button {
            margin-top: 25px;
            display: flex;
            flex-direction: row-reverse;
            height: 48px;
            font-size: 0.875em;

            #icon {
                margin-left: 0 !important;
                margin-right: 4px;
            }
        }
    }

    @media (min-width: 64em) {
        padding: 0 40px 0px 40px;
        margin-bottom: 37px;
        position: relative;
        display: flex;
        justify-content: space-between;

        #options {
            display: none;
        }

        #desc {
            display: block;

            h2 {
                font-size: 1.5em;
                margin-bottom: 8px;
            }

            p {
                font-size: 0.875em;
                color: #718096;

                span {
                    color: #1a202c;
                    font-weight: 500;
                }
            }
        }

        form {
            display: none;
        }

        .option:first-of-type {
            margin-right: 19px;
        }

        .option {
            cursor: pointer;
            width: 52px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #e2e8f0;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #718096;

            &.active {
                background: #fafafa;
                border: 1px solid #cbd5e0;
            }
        }

        h1 {
            position: absolute;
            top: -30px;
        }

        .input {
            width: 295px;
        }
    }
    &.headerChatDark {
        border-color: #1d1d1f !important;

        h1 {
            color: #ffffff;
            span {
                color: #000;
            }
        }

        #options {
            a {
                button {
                    background: #000 !important;
                    color: #fff;
                    border: 1px solid #ffffff;
                }
            }
        }
    }
`;

export const DocumentsContainer = styled.div`
    margin-top: 25px;
    margin-bottom: 25px;

    .flex {
        display: flex;

        .options-icons {
            display: flex;
            align-items: center;

            .changeTitleButton {
                height: 100%;
                background: transparent;
                border: none;
                width: 30px;
                cursor: pointer;

                &:disabled {
                    opacity: 20%;
                    cursor: not-allowed;
                }
            }

            .changeTitleButtonDark {
                border: none !important;
                background: transparent !important;
            }
        }
    }

    header {
        h1 {
            font-size: 1.25em;
        }
    }

    .box:first-of-type {
        border-top: 1px solid #edf2f7;
    }

    .box {
        margin-top: 28px;
        padding: 24px 0 36px 0;
        border-bottom: 2px solid #edf2f7;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .flex {
            flex: 1;
            margin: 0 24px;

            h1 {
                font-size: 0.875em;
                font-weight: 700;
            }
        }
    }

    .box:last-of-type {
        border-top: 0;
        border-bottom: 0;
        margin: 0;
    }

    .play {
        button {
            cursor: pointer;
            background: #d0ff61;
            border-radius: 6px;
            width: 82px;
            height: 32px;
            font-size: 14px;
            border: 0;
            font-weight: 600;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-right: 11px;
            }
        }

        &.mobile {
            button {
                width: 32px;
                height: 32px;

                svg {
                    margin: 0;
                }
            }
        }
    }

    .chatTitle {
        font-size: 0.875rem;
        font-weight: normal;
        width: 152px;
        line-height: 21px;
        letter-spacing: 0.2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dots {
        cursor: pointer;
        min-width: 32px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon {
        min-width: 40px;
        min-height: 40px;
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        border-radius: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name-table {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 26px;
        }

        span {
            font-weight: 600;
            font-size: 1em;
        }
    }

    .infos .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;

        strong {
            font-size: 0.875em;
            color: #718096;
            font-weight: 600;
        }

        span {
            color: #232b38;
            font-size: 0.875em;
            font-weight: 600;
        }
    }

    #table {
        display: none;
    }

    @media (min-width: 64em) {
        border-radius: 16px;
        margin-top: 0px;
        margin-bottom: 0px;

        .box {
            display: none;
        }

        #table {
            display: flex;
            width: 100%;
            border-top: 1px solid #e2e8f0;

            .sidebar {
                min-width: 283px;
                height: calc(100vh - 109px);
                border-right: 1px solid #e2e8f0;

                button {
                    border: 1px solid #000000;
                    border-radius: 12px;
                    width: 247px;
                    height: 48px;
                    background-color: #fff;
                    font-weight: 700;
                    font-size: 14px;
                    margin: 19px auto;
                    max-width: calc(100% - 19px);
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    padding-left: 10px;

                    svg {
                        margin-right: 10px;
                    }
                }

                li {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    border-left: 3px solid #fff;
                    padding-left: 10px;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 14px;
                    color: #1a202c;

                    > svg {
                        margin-right: 13px;
                        min-width: 20px;
                        max-width: 20px;
                    }

                    [name='editChat'] {
                        border: 1px solid #000;
                        background-color: transparent;
                    }

                    .options-icons {
                        display: none;
                        margin-left: auto;
                        margin-right: 10px;
                        align-items: center;
                        padding-left: 5px;

                        .changeTitleButton {
                            display: block;
                            height: 100%;
                            background: transparent;
                            width: 30px;
                            border: none;
                            margin: 0;
                            padding: 0;
                            cursor: pointer;

                            &:disabled {
                                opacity: 20%;
                                cursor: not-allowed;
                            }
                        }

                        .changeTitleButtonDark {
                            border: none !important;
                            background: transparent !important;
                        }
                    }

                    :hover {
                        background: #fafafa;
                        border-left: 3px solid #000;

                        .options-icons {
                            display: flex;
                        }
                    }

                    &.active {
                        background: #fafafa;
                        border-left: 3px solid #000;
                    }
                }
            }

            > div:last-of-type > div {
                margin: 0;

                > div {
                    height: calc(100vh - 109px);
                }
            }
        }

        header {
            h1 {
                font-size: 1.5em;
            }
        }
    }

    &.containerChatDark {
        article {
            border-color: #1d1d1f !important;

            .sidebar {
                border-color: #1d1d1f !important;

                button {
                    background: #000 !important;
                    color: #fff;
                    border: 1px solid #ffffff !important;
                }

                ul {
                    li {
                        svg path {
                            stroke: #fff;
                        }

                        border-left: 3px solid #000 !important;

                        color: #fff !important;
                        &:hover {
                            background: #1d1d1f !important;
                            border-left: 3px solid #d0ff61 !important;
                        }

                        &.active {
                            background: #1d1d1f !important;
                            border-left: 3px solid #d0ff61 !important;
                        }
                    }
                }
            }
        }
    }
`;

export const ModelsContainer = styled.div`
    margin-top: 30px;
    width: 100%;
    overflow: auto;
    display: flex;
    gap: 25px;
    padding-bottom: 25px;

    &::-webkit-scrollbar {
        width: 1em;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #edf2f7;
        border-radius: 50px;
    }

    .box {
        .image {
            width: 100%;
            height: 160px;
            background: #1a202c;
            border-radius: 8px;
        }

        min-width: 308.75px;
        height: 350px;
        border: 1px solid rgba(26, 32, 44, 0.1);
        border-radius: 16px;
        padding: 12px 10px 30px 12px;

        h1 {
            font-size: 1.125em;
            padding-top: 15px;
        }

        p {
            font-size: 0.875em;
            color: #718096;
            padding-top: 8px;
            padding-bottom: 15px;
        }

        button {
            width: 140px;
            height: 44px;
            color: #1a202c;
            font-size: 0.875em;
            border: 1px solid #e2e8f0;
            border-radius: 8px;
            background: #fff;
            padding: 0;
        }
    }
`;
