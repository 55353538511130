import React from 'react';
import * as S from './styles';
import {
    MessagesContainer,
    IMessage,
    SwitchCheckbox,
    LanguageSelect,
    Button,
    parseAgentMessages,
} from 'app/presentation/components/agent';
import useAgent from 'app/presentation/hooks/useAgent';
import { IconCheck } from '@tabler/icons-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useText from 'app/presentation/hooks/useText';

export const WelcomeMessagePanel = () => {
    const { textGetter } = useText();
    const panelText = textGetter('agent.panel.welcome-messages');
    const commonsText = textGetter('agent.panel.commons');

    const [welcomeMessages, setWelcomeMessages] = React.useState<
        Array<IMessage>
    >([]);
    const [showWelcomeMessage, setShowWelcomeMessage] =
        React.useState<boolean>(false);
    const [languageID, setLanguageID] = React.useState<number>(0);
    const { agent, updateWelcomeMessages } = useAgent();
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    const { handleSubmit } = useForm<{}>();

    const loadConfig = () => {
        if (!agent) return;
        setShowWelcomeMessage(agent.show_welcome_message);

        if (!agent.config || languageID != 0) return;
        const agentLang = agent.config.additional_languages.find(
            lang => lang.is_default,
        );
        if (!agentLang || !agentLang.language || !agentLang.language.id) return;
        setLanguageID(agentLang.language.id);
    };

    const loadMessages = () => {
        if (!agent) return;

        const messageList = agent.welcome_message_lists.find(
            list => list.language.id == languageID,
        );

        if (messageList) {
            setWelcomeMessages(parseAgentMessages(messageList.messages));
        } else {
            setWelcomeMessages([]);
        }
    };

    React.useEffect(() => {
        loadConfig();
    }, [agent]);

    React.useEffect(() => {
        loadMessages();
    }, [agent, languageID]);

    const onSubmit: SubmitHandler<{}> = async () => {
        setFormSubmitLoading(true);
        updateWelcomeMessages(
            welcomeMessages,
            showWelcomeMessage,
            languageID,
        ).finally(() => setFormSubmitLoading(false));
    };

    return (
        <S.WelcomeMessagePanel onSubmit={handleSubmit(onSubmit)}>
            <div className="show-welcome-message-container">
                <SwitchCheckbox
                    name="show-welcome-message"
                    info={{
                        title: panelText('show-welcome-message'),
                    }}
                    value={showWelcomeMessage}
                    setValue={setShowWelcomeMessage}
                ></SwitchCheckbox>
            </div>
            <p className="desc">{panelText('desc')}</p>
            <LanguageSelect
                value={languageID}
                onChange={setLanguageID}
            ></LanguageSelect>
            <MessagesContainer
                messages={welcomeMessages}
                setMessages={setWelcomeMessages}
            ></MessagesContainer>
            <div className="buttons-container">
                <Button
                    variant="secondary"
                    type="button"
                    onClick={() => {
                        loadConfig();
                        loadMessages();
                    }}
                >
                    {commonsText('button.cancel')}
                </Button>
                <Button
                    variant="dark"
                    type="submit"
                    loading={formSubmitLoading}
                    className="save-button"
                >
                    <IconCheck></IconCheck>
                    <span>
                        {commonsText('button.save')}
                        <span className="desktop">
                            {' '}
                            {commonsText('button.save-suffix')}
                        </span>
                    </span>
                </Button>
            </div>
        </S.WelcomeMessagePanel>
    );
};
