import styled, { css } from 'styled-components';

import AgentHomeBG from 'assets/images/png/agent-home-bg.png';

export const Header = styled.div`
    ${({
        theme: {
            agent: {
                pages: {
                    home: {
                        header: {
                            background,
                            title,
                            text,
                            userAgreement,
                            chat,
                        },
                    },
                },
            },
        },
    }) => css`
        position: relative;
        display: flex;
        justify-content: space-between;
        background-color: ${background.color};
        background-image: url(${AgentHomeBG});
        padding: 20px 20px 0px 20px;
        background-repeat: no-repeat;
        background-size: 1200px 1000px;
        background-position: -225px 225px;

        @media (min-width: 64em) {
            padding: 0px 40px 0px 40px;
            min-height: 418px;
            background-size: 1200px 1000px;
            background-position: 440px -50px;
        }

        h1 {
            display: none;
            font-weight: 700;
            color: ${title.color};
            font-size: 1.5em;
            align-items: center;
            margin-bottom: 24px;

            @media (min-width: 64em) {
                display: flex;
                position: absolute;
                top: -55px;
            }
        }

        .beta {
            min-width: 66px;
            min-height: 24px;
            background: #d0ff61;
            border-radius: 100px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            margin-left: 12px;
        }

        .header-content {
            overflow-y: hidden;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            @media (min-width: 64em) {
                align-items: end;
                flex-direction: row;
                padding: 65px 0px 0px 90px;
                gap: 50px;
            }

            @media (min-width: 1200px) {
                gap: 150px;
            }

            .header-card {
                width: 335px;
                margin-bottom: 60px;

                @media (min-width: 64em) {
                    width: 453px;
                }

                .card-title {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 31.2px;
                    letter-spacing: 0.2px;
                    margin-bottom: 12px;
                    color: ${title.color};

                    @media (min-width: 64em) {
                        font-weight: 800;
                        font-size: 32px;
                        line-height: 40px;
                    }
                }

                .card-text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.2px;
                    color: ${text.color};
                    margin-bottom: 35px;
                }

                .button-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 12px;
                    margin-bottom: 40px;

                    @media (min-width: 64em) {
                        width: 312px;
                        justify-content: space-between;
                        margin-bottom: 30px;
                        flex-direction: row;
                    }

                    .header-button {
                        height: 44px;
                        border-radius: 8px;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 21px;
                        padding: 8px 15px;
                        gap: 4px;
                        min-width: fit-content;
                    }
                }

                .user-agreement {
                    display: none;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 19.2px;
                    letter-spacing: 0.2px;
                    color: ${userAgreement.color};

                    @media (min-width: 64em) {
                        display: block;
                    }
                }
            }
            .header-chat {
                background-color: ${chat.background.color};
                border-radius: 14px 14px 0px 0px;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                padding: 15px 25px;
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 280px;

                @media (min-width: 64em) {
                    display: block;
                    width: 360px;
                }

                .chat-title {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19.6px;
                    letter-spacing: 0.2px;
                    text-align: center;
                    margin-bottom: 10px;
                    color: ${chat.text.color};

                    @media (min-width: 64em) {
                        font-size: 18px;
                        line-height: 25.2px;
                    }
                }

                .chat-img-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 15px;

                    .user-img {
                        width: 35px;
                        height: 35px;
                        border-radius: 35px;

                        @media (min-width: 64em) {
                            width: 45px;
                            height: 45px;
                            border-radius: 45px;
                        }
                    }
                    .logo-img-container {
                        width: 49.78px;
                        height: 49.78px;
                        border-radius: 9.33px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid ${chat.logo.border.color};
                        margin: 0px -10px 0px -10px;
                        background-color: ${chat.background.color};
                        z-index: 2;

                        @media (min-width: 64em) {
                            width: 64px;
                            height: 64px;
                            border-radius: 12px;
                        }

                        .logo-img {
                            width: 28px;
                            height: 24.2px;

                            @media (min-widthagent-card 64em) {
                                width: 36px;
                                height: 32px;
                            }
                        }
                    }
                }

                .chat-subtitle {
                    font-weight: 700;
                    font-size: 10.89px;
                    line-height: 16.33px;
                    letter-spacing: 0.2px;
                    text-align: center;
                    margin-bottom: 30px;
                    color: ${chat.text.color};

                    @media (min-width: 64em) {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }

                .chat-body {
                    display: flex;
                    align-items: end;
                    gap: 8px;
                    width: 248.89px;

                    @media (min-width: 64em) {
                        width: 320px;
                    }

                    .chat-logo-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid ${chat.logo.border.color};

                        width: 21.78px;
                        height: 21.78px;
                        border-radius: 3.11px;

                        @media (min-width: 64em) {
                            width: 28px;
                            height: 28px;
                            border-radius: 4px;
                        }
                        .chat-logo {
                            width: 18px;
                            height: 18px;

                            img {
                                width: 19px;
                                height: 18px;
                            }
                        }
                    }

                    .chat-message-container {
                        font-weight: 500;
                        font-size: 10.89px;
                        line-height: 16.33px;
                        letter-spacing: 0.2px;

                        @media (min-width: 64em) {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                        }
                        p {
                            border-radius: 6.22px;
                            background-color: ${chat.message.background.color};
                            color: ${chat.message.text.color};

                            @media (min-width: 64em) {
                                border-radius: 8px;
                            }
                        }

                        .chat-first-message {
                            width: 220.11px;
                            height: 82.67px;
                            padding: 9.33px;
                            gap: 7.78px;
                            margin-bottom: 10px;

                            @media (min-width: 64em) {
                                width: 283px;
                                height: 103px;
                                margin-bottom: 10px;
                                padding: 10px 15px;
                            }
                        }

                        .chat-last-message {
                            width: 164.67px;
                            height: 34.67px;
                            padding: 9.33px;
                            gap: 6.22px;

                            @media (min-width: 64em) {
                                width: 206px;
                                height: 45px;
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    `}
`;

export const Content = styled.div`
    ${({
        theme: {
            agent: {
                pages: {
                    home: {
                        content: { title, subtitle },
                    },
                },
            },
        },
    }) => css`
        padding: 20px 20px;
        position: relative;
        max-width: 1600px;

        @media (min-width: 64em) {
            padding: 25px 50px;
        }

        .content-header {
            margin-bottom: 20px;
            .content-title {
                font-weight: 700;
                font-size: 20px;
                height: 27px;
                letter-spacing: 0.2px;
                margin-bottom: 10px;
                color: ${title.color};
            }
            .content-subtitle {
                font-weight: 500;
                font-size: 13px;
                height: 19.5px;
                letter-spacing: 0.2px;
                color: ${subtitle.color};
            }
        }
        .progress-container {
            padding-top: 60px;
            width: fit-content;
            margin: auto;
        }
        
        .create-your-first-agent {
            padding-top: 35px;
            width: fit-content;
            margin: auto;
            display: flex;
            align-items: center;
            flex-direction: column;

            .title {
                margin-top: 28px;
                font-size: 24px;
                font-weight: 700;
                line-height: 31.2px;
            }

            .text {
                margin-top: 20px;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0.2px;
                color: #7E7E7E;
            }
        }

        .agent-card-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
            grid-row-gap: 20px;
            align-items: center;

            @media (min-width: 64em) {
                grid-row-gap: 30px;
            }
        }
        .paginator-container {
            padding-top: 30px;
            display: flex;
            justify-content: center;
        }
    `}
`;

interface Props {
    actionColor: string;
}

export const Card = styled.div<Props>`
    ${({
        actionColor,
        theme: {
            agent: {
                pages: {
                    home: {
                        content: { card },
                    },
                },
            },
        },
    }) => css`
        justify-self: center;

        @media (min-width: 64em) {
            justify-self: start;
        }

        padding: 16px;
        width: 335px;
        height: 240px;
        border: 1px solid ${card.border.color};
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        background-color: ${card.background.color};

        @media (min-width: 64em) {
            width: 307px;
        }

        .agent-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .agent-img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid;
                border-color: ${card.icon.border.color};
                border-radius: 6px;
                height: 42px;
                width: 42px;
                background-color: ${actionColor};
                img {
                    max-height: 100%;
                    width: 23.62px;
                }
            }
            .agent-card-opts-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid;
                border-color: ${card.icon.border.color};
                border-radius: 6px;
                width: 32px;
                height: 32px;
                padding: 0px;
                margin: 0px;
                background-color: inherit;
                cursor: pointer;

                svg {
                    width: 20px;
                    height: 20px;
                    color: #7e7e7e;
                    margin: 0px;
                    display: block;
                }
            }
        }

        .agent-card-title {
            font-weight: 700;
            font-size: 16px;
            height: 24px;
            letter-spacing: 0.2px;
            margin-bottom: 12px;
            color: ${card.title.color};
        }
        .agent-card-time {
            font-weight: 500;
            font-size: 13px;
            height: 19.5px;
            letter-spacing: 0.4px;
            color: ${card.timeText.color};
            margin-bottom: 6px;
        }
        .agent-card-type {
            font-weight: 500;
            font-size: 14px;
            height: 21px;
            letter-spacing: 0.2px;
            color: ${card.text.color};
        }
        .agent-card-btn {
            width: 303px;
            height: 42px;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0.2px;
            border-radius: 8px;

            @media (min-width: 64em) {
                width: 275px;
            }
        }
    `}
`;

export const MenuButton = styled.button`
    ${({
        theme: {
            agent: {
                pages: {
                    home: {
                        content: {
                            menu: { background, text },
                        },
                    },
                },
            },
        },
    }) => css`
        display: flex;
        gap: 12px;
        align-items: center;
        height: 32px;
        border: none;
        background-color: ${background.color};
        color: ${text.color};
        width: 100%;
        margin-bottom: 4px;
        transition: all linear 0.05s;
        padding: 0px 12px;
        cursor: pointer;

        p {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: ${text.color};
        }

        svg {
            height: 18px;
            width: 18px;
        }

        :hover {
            background-color: ${background.active};
        }

        :last-child {
            margin-bottom: 0px;
        }
    `}
`;
