import React from 'react';
import { ButtonStyled } from './styles';
import { ButtonPropsElement } from '../../interfaces/button-props.interface';
import CircularProgress from '@mui/material/CircularProgress';
import CustomTooltip from '../../dashboard/Tooltip';

export const Button: React.FC<ButtonPropsElement> = ({
    children,
    color = 'primary',
    icon,
    loading,
    iconPositon = 'end',
    titleTooltip = '',
    ...props
}) => {
    return (
        <CustomTooltip title={titleTooltip}>
            <ButtonStyled
                className={loading ? 'disabled-event' : ''}
                color={color}
                {...props}
            >
                {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                ) : iconPositon === 'end' ? (
                    <>
                        <span>{children}</span>
                        {icon && <div id="iconEnd">{icon}</div>}
                    </>
                ) : (
                    <>
                        {icon && <div id="iconStart">{icon}</div>}
                        <span>{children}</span>
                    </>
                )}
            </ButtonStyled>
        </CustomTooltip>
    );
};
