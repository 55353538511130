import React from 'react';
import { CardSelectThemeVariants } from 'styles/themes/themes';
import * as S from './styles';

interface CardProp {
    value?: string;
    text?: string;
    title: string;
    icon: React.ReactNode;
    iconBackgroundColor?: string;
    disable?: boolean;
    onClick?: () => void;
    selected?: boolean;
}

interface TitleProps {
    height: string;
    size: string;
}

interface IconBGSize {
    width: string;
    height: string;
    radius: string;
}

interface Props {
    value?: string;
    onChange?: (value: string) => void;
    height: string;
    cards: CardProp[];
    iconGap?: string;
    textGap?: string;
    cardGap?: string;
    padding?: string;
    iconBG?: IconBGSize;
    title?: TitleProps;
    variant: CardSelectThemeVariants;
    align?: 'left' | 'center';
}

export const CardSelect: React.FC<Props> = ({
    value,
    height,
    onChange,
    cards,
    iconGap = '8px',
    textGap = '4px',
    cardGap = '10px',
    padding = '20px 15px',
    iconBG = null,
    align = 'center',
    title = { height: '24px', size: '16px' },
    variant,
}) => {
    const selectCard = (value: string, disable?: boolean) => {
        if (disable || !onChange) {
            return;
        }

        onChange(value);
    };
    return (
        <S.CardSelect
            height={height}
            align={align}
            iconGap={iconGap}
            padding={padding}
            textGap={textGap}
            cardGap={cardGap}
            iconBGHeight={iconBG ? iconBG.height : undefined}
            iconBGWidth={iconBG ? iconBG.width : undefined}
            iconBGRadius={iconBG ? iconBG.radius : undefined}
            titleHeight={title.height}
            titleSize={title.size}
            variant={variant}
        >
            <div className="card-container">
                {cards.map(card => {
                    return (
                        <div
                            key={card.value}
                            className={
                                card.disable
                                    ? 'card card-disabled'
                                    : value == card.value
                                    ? 'card active-card'
                                    : 'card'
                            }
                            onClick={() => {
                                if (card.onClick) {
                                    card.onClick();
                                }
                                selectCard(card.value || '', card.disable);
                            }}
                        >
                            <div
                                className="icon-container"
                                style={{
                                    backgroundColor: card.iconBackgroundColor,
                                }}
                            >
                                {' '}
                                {card.icon}
                            </div>
                            <p className="card-title">{card.title}</p>
                            {card.text && (
                                <p className="card-text">{card.text}</p>
                            )}
                        </div>
                    );
                })}
            </div>
        </S.CardSelect>
    );
};
