import React from 'react';
import { CollectionsContextProps } from '../contexts/interfaces/collections-context-props-interface';
import CollectionsContext from '../contexts/CollectionsContext';

function useCollectionsContext(): CollectionsContextProps {
    const context = React.useContext(CollectionsContext);

    if (!context) {
        throw new Error('useMemoriesContext must be used within MemoriesContextProvider');
    }

    return context;
}

export default useCollectionsContext;
