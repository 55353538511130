import styled, { css } from 'styled-components';

export const MultilanguagePanel = styled.form`
    ${({
        theme: {
            agent: {
                pages: {
                    config: {
                        multilanguagePanel: { hr },
                    },
                },
            },
        },
    }) => css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        .panel-desc {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.2px;
        }
        .buttons-container {
            padding-top: 15px;
            border-top: 1px solid ${hr.color};
            margin-top: 15px;
            display: flex;
            gap: 8px;
            flex-direction: row-reverse;
            justify-content: start;

            @media (min-width: 64em) {
                flex-direction: row;
                justify-content: end;
            }

            button {
                height: 34px;
                padding: 8px 18px 8px 18px;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.2px;
                gap: 4px;
                border-radius: 6px;
                .desktop {
                    display: none;

                    @media (min-width: 64em) {
                        display: inline;
                    }
                }

                &.save-button {
                    width: 103px;
                    @media (min-width: 64em) {
                        width: 190px;
                    }
                }
            }
        }
    `}
`;
