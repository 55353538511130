import React from 'react';
import NotificationContext from '../contexts/NotificationContext';
import { NotificationContextData } from '../contexts/interfaces/notification-context-props.interface';

function useNotifications(): NotificationContextData {
    const context = React.useContext(NotificationContext);

    if (!context) {
        throw new Error(
            'useNotifications deve ser usado com o NotificationProvider',
        );
    }

    return context;
}

export default useNotifications;
