import styled from 'styled-components';

export const Label = styled.label`
    font-weight: bold;
    display: block;
    margin-top: 9px;
    margin-bottom: 15px;
`;

export const InputContainer = styled.div`
    font-size: 0.9em;
    width: 100%;
    margin-top: 32px;

    .container-sizes {
        display: flex;
        gap: 20px;

        .box {
            border: 1px solid #edf2f7;
            border-radius: 12px;
            width: 50px;
            height: 50px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            span {
                font-weight: 600;
                font-size: 16px;
                color: #232b38;
            }

            &.active {
                background: #000000;
                border: 1px solid #000000;

                span {
                    color: #ffffff;
                }
            }
        }
    }

    &.inputContainerDark {
        .container-sizes {
            display: flex;
            gap: 20px;

            .box {
                border-color: #1d1d1f;
                background: #1d1d1f;

                span {
                    color: #fff;
                }

                &.active {
                    background: #fff;
                    border: 1px solid #fff;

                    span {
                        color: #000;
                    }
                }
            }
        }
    }
`;
