import React from 'react';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import Tooltip from '@mui/material/Tooltip';

type DocumentProps = {
    hideTooltip?: boolean;
    darkmode?: boolean;
    color?: string;
};

export const Document: React.FC<DocumentProps> = ({
    hideTooltip = true,
    darkmode = false,
    color,
}) => {
    const intl = useIntl();

    return (
        <Tooltip
            title={routeTranslate(intl, 'modal.history.openDocument')}
            disableHoverListener={hideTooltip}
            placement="top"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <svg
                width="16"
                height="19"
                viewBox="0 0 16 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.83334 1.25V4.91667C9.83334 5.15978 9.92991 5.39294 10.1018 5.56485C10.2737 5.73676 10.5069 5.83333 10.75 5.83333H14.4167"
                    stroke={color ?? '#2A313C'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5833 17.75H3.41667C2.93044 17.75 2.46412 17.5568 2.12031 17.213C1.77649 16.8692 1.58334 16.4029 1.58334 15.9167V3.08333C1.58334 2.5971 1.77649 2.13079 2.12031 1.78697C2.46412 1.44315 2.93044 1.25 3.41667 1.25H9.83333L14.4167 5.83333V15.9167C14.4167 16.4029 14.2235 16.8692 13.8797 17.213C13.5359 17.5568 13.0696 17.75 12.5833 17.75Z"
                    stroke={color ?? '#2A313C'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.25 6.75H6.16667"
                    stroke={color ?? '#2A313C'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.25 10.4167H10.75"
                    stroke={color ?? '#2A313C'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.25 14.0833H10.75"
                    stroke={color ?? '#2A313C'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Tooltip>
    );
};
