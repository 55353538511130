import React from 'react';
import { Image } from '../../../interfaces/arte-ai.interface';
import { GridContainer, GridItem, GridSquare } from './styles-images';

import { TfiMoreAlt } from 'react-icons/tfi';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { Download, Like, UnLike } from 'app/presentation/components/icons';

type IEmptyArt = {
    qtd: number;
    ratio: {
        key: string;
        width: number;
        height: number;
        aspect: string;
    };
    hasImages: boolean;
    images?: Array<Image>;
    handleModalImages: (i: number) => void;
    openMenu: boolean;
    anchorEl: any;
    handleRate: (value: boolean, id: number) => void;
    downloadImage: (value: boolean, id: number) => void;
    handleClick: (event: any, id: number) => void;
};

const EmptyArt: React.FC<IEmptyArt> = ({
    qtd,
    ratio,
    hasImages,
    images,
    handleModalImages,
    anchorEl,
    openMenu,
    handleRate,
    downloadImage,
    handleClick,
}) => {
    const qtdArr = [];
    const { darkmode } = useDarkMode();

    for (let i = 0; i < qtd; i++) {
        qtdArr.push(i);
    }

    const aspect = `${ratio.width} / ${ratio.height}`;

    return (
        <GridContainer className={`qtd${qtdArr.length}`}>
            {hasImages && images
                ? images.map((image, i) => {
                      return (
                          <GridItem
                              key={image.id}
                              style={{ aspectRatio: aspect }}
                          >
                              <GridSquare>
                                  <div className="box">
                                      <img
                                          onClick={() => handleModalImages(i)}
                                          src={image.response}
                                          style={{ aspectRatio: aspect }}
                                      />

                                      <div
                                          className="rate"
                                          style={{
                                              opacity:
                                                  openMenu &&
                                                  anchorEl?.id ===
                                                      String(image.id).concat(
                                                          'icon',
                                                      )
                                                      ? 1
                                                      : undefined,
                                          }}
                                      >
                                          <div
                                              onClick={() =>
                                                  handleRate(true, image.id)
                                              }
                                              className="likeRate"
                                          >
                                              <Like
                                                  darkmode={darkmode}
                                                  liked={
                                                      image.feedback_status ===
                                                      'good'
                                                  }
                                              />
                                          </div>
                                          <div
                                              onClick={() =>
                                                  handleRate(false, image.id)
                                              }
                                          >
                                              <UnLike
                                                  darkmode={darkmode}
                                                  unliked={
                                                      image.feedback_status ===
                                                      'bad'
                                                  }
                                              />
                                          </div>
                                      </div>

                                      <div
                                          onClick={() =>
                                              downloadImage(true, image.id)
                                          }
                                          className="iconDownload"
                                          style={{
                                              opacity:
                                                  openMenu &&
                                                  anchorEl?.id ===
                                                      String(image.id).concat(
                                                          'icon',
                                                      )
                                                      ? 1
                                                      : undefined,
                                          }}
                                      >
                                          <Download
                                              hideTooltip={false}
                                              color={darkmode ? '#fff' : '#000'}
                                          />
                                      </div>
                                      <div
                                          id={String(image.id).concat('icon')}
                                          onClick={e =>
                                              handleClick(e, image?.id)
                                          }
                                          className="icon"
                                          style={{
                                              opacity:
                                                  openMenu &&
                                                  anchorEl?.id ===
                                                      String(image.id).concat(
                                                          'icon',
                                                      )
                                                      ? 1
                                                      : undefined,
                                          }}
                                      >
                                          <TfiMoreAlt
                                              size={14}
                                              style={{
                                                  color: darkmode
                                                      ? '#fff'
                                                      : '#000',
                                              }}
                                          />
                                      </div>
                                  </div>
                              </GridSquare>
                          </GridItem>
                      );
                  })
                : qtdArr.length > 0 &&
                  qtdArr.map((data, i) => {
                      if (qtdArr.length && i < 4) {
                          return (
                              <GridItem
                                  className=""
                                  style={{ aspectRatio: aspect }}
                              >
                                  <GridSquare
                                      style={{ aspectRatio: aspect }}
                                      className={darkmode ? 'contentDark' : ''}
                                  ></GridSquare>
                              </GridItem>
                          );
                      } else {
                          return <></>;
                      }
                  })}
        </GridContainer>
    );
};

export default EmptyArt;
