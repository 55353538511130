import React from "react";
import { IconProps as TablerIconProps, Icon as TablerIcon } from "@tabler/icons-react";
import { IconContainer } from "./styles";
import useCollectionsContext from "app/presentation/hooks/useCollectionsContext";
import { RowContent } from "app/presentation/providers/CollectionsProvider";
import { useSnackbar } from "notistack";

type CustomIconProps = {
    Icon: React.ForwardRefExoticComponent<Omit<TablerIconProps, "ref"> & React.RefAttributes<TablerIcon>>;
    setShowIconsDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomIcon: React.FC<CustomIconProps> = ({ Icon, setShowIconsDropdown }) => {
    const { updateCollectionIcon, selectedCollection, tree, updateTreeItem } = useCollectionsContext();
    const { enqueueSnackbar: toast } = useSnackbar();

    return (
        <IconContainer
            onClick={async () => {
                if (!selectedCollection) return;

                try {
                    setShowIconsDropdown(false);

                    const collection = await updateCollectionIcon(selectedCollection, Icon.displayName!);
                    if (!collection) return;

                    const nodeId = `${RowContent.Collection},${collection.id}`;
                    const collectionNode = tree.get(nodeId);
                    updateTreeItem(nodeId, { ...collectionNode, obj: collection });
                } catch (err: any) {
                    err instanceof Error ?
                        toast(err.message, { variant: "error" }) :
                        toast(err.message, { variant: "error" })
                }
            }}>
            <Icon stroke={1.5} size={32} />
        </IconContainer>
    )
}
