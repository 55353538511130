import { create } from 'zustand';

interface Ids {
    workspace_id: number;
    project_id: number;
}

interface StoreProps {
    data: Ids;
    setData: (workspace_id: number, project_id: number) => void;
    cleanData: () => void;
}

const initalData: Ids = {
    project_id: 0,
    workspace_id: 0,
};

export const idsStore = create<StoreProps>(set => ({
    data: initalData,
    setData: (workspace_id: number, project_id: number) =>
        set(() => ({
            data: { project_id, workspace_id },
        })),
    cleanData: () =>
        set(() => ({
            data: initalData,
        })),
}));
