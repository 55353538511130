import styled, { css } from 'styled-components';

export const ContainerModal = styled.div`
    ${({
        theme: {
            agent: {
                components: {
                    modalNewContent: { background },
                },
            },
        },
    }) => css`
        background-color: #11151deb;
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9998 !important;
        .content-modal {
            background: ${background.color};
            border-radius: 16px;
            min-width: 328px;
            max-width: 328px;
            padding: 20px 32px 32px 32px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            height: fit-content;
            max-height: 80vh;

            @media (min-width: 64em) {
                min-width: 847px;
                max-width: 847px;
            }

            .modal-header {
                display: flex;
                align-items: start;
                justify-content: space-between;
                min-height: 80px;

                @media (min-width: 64em) {
                    min-height: 51px;
                }

                p {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 31.2px;
                }

                svg {
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    `}
`;
