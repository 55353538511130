import styled from 'styled-components';

export const Container = styled.div`
    height: 1024px;
    background-color: white;
    padding: 32px;

    @media (min-width: 64em) {
        width: 898px;
    }
`;

export const Header = styled.div`
    input {
        ::placeholder {
            color: #a09fa5;
        }

        &:disabled {
            background-color: white;
        }

        min-width: 50%;
        font-size: 24px;
        font-weight: 700;
        border: none;
        border-bottom: 1px dashed #cbd5e0;
    }

    .content-drawer-header-buttons {
        display: flex;
        gap: 16px;
    }

    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e8f0;

    display: flex;
    justify-content: space-between;
`;

export const Content = styled.div``;

export const Status = styled.div`
    margin-top: 30px;
    display: flex;
    gap: 8px;
    align-items: center;

    label {
        font-size: 14px;
        font-weight: 600;
    }

    .content-drawer-switch-checkbox {
        height: 18px;
        width: 32.41px;
    }

    .content-drawer-status-label {
        margin-left: 20px;
        width: 125px;
        height: 21px;
        font-size: 14px;
        font-weight: 600;
    }
`;

export const Editor = styled.div`
    &[contenteditable='true']:empty:before {
        content: attr(placeholder);
        pointer-events: none;
        display: block;
        color: #a09fa5;
    }

    font-size: 16px;
    line-height: 27.2px;
    font-weight: 500;
    letter-spacing: 0cap.2px;
    margin-top: 30px;
    max-height: 680px;
    overflow-y: auto;
`;

export const Readonly = styled.div`
    &[contenteditable='true']:empty:before {
        content: attr(placeholder);
        pointer-events: none;
        display: block;
        color: #a09fa5;
    }

    font-size: 16px;
    line-height: 27.2px;
    font-weight: 500;
    letter-spacing: 0cap.2px;
    margin-top: 30px;
    max-height: 680px;
    overflow-y: auto;

    label {
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
    }

    div {
        display: flex;
        align-items: center;
        gap: 8px;
        user-select: none;
        cursor: pointer;
        margin-bottom: 25px;
    }

    p {
        opacity: 0;
        overflow: hidden;
        transition: opacity 0.1s ease;

        &.expanded {
            opacity: 1;
            transition: opacity 0.1s ease;
        }
    }
`;

export const TrainingMetadata = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .content-drawer-metadata-title {
        width: 140px;
        color: #a09fa5;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;

        &:nth-of-type(2) {
            color: black;
        }
    }
`;

export const TrainingMetadataItem = styled.div`
    display: flex;
    gap: 8px;
`;
