import styled from 'styled-components';

interface ChartProps {
    width: string;
    height: string;
}

export const Chart = styled.canvas<ChartProps>`
    max-width: ${({width}) => width};
    max-height:  ${({height}) => height};
`;