import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;

    .content-modal {
        background-color: transparent;
        width: 95%;
        border-radius: 12px;
        position: relative;

        button {
            display: none;
        }

        img {
            border-radius: 12px;
        }
        .btn-prev {
            left: -60px;
        }

        .btn-next {
            right: -60px;
        }

        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }

        .swiper-slide {
            width: 95%;
            border-radius: 12px;
        }

        .actions {
            position: absolute;
            top: 15px;
            display: flex;
            justify-content: space-between;
            width: 100%;

            section {
                display: flex;
                margin-right: 16px;

                .download {
                    margin-right: 7px;
                }
            }

            .copy {
                margin-left: 21px;
            }

            div {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px !important;
                height: 32px !important;
                background: #fff;
                border-radius: 100%;
            }
        }
        .upscaleLabel {
            z-index: 1000;
            position: absolute;
            bottom: 7%;
            left: 20px;
            background-color: #fff;
            border-radius: 9999px;
            padding: 4px 12px;
            p {
                color: #000;
                font-weight: 600;
            }
        }
    }

    @media (min-width: 64em) {
        .content-modal {
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50%;
                z-index: 100000;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background: #fff;
                border: none;
                cursor: pointer;
            }
        }
    }

    &.modalArteDark {
        .content-modal {
            background: #000;
            button {
                background: #000;
                color: #fff;
            }
            .actions {
                div {
                    background: #000;
                }
            }

            .upscaleLabel {
                background-color: #000;
                p {
                    color: #fff;
                }
            }
        }
    }
`;
