import styled from 'styled-components';

export const Box = styled.div`
    text-align: center;

    > h1,
    > p {
        text-align: center;
    }

    > h1 {
        margin-bottom: 12px;
    }

    p {
        color: #7e7e7e;
        font-weight: 500;
        font-size: 1.125rem;
    }

    @media (min-width: 768px) {
        > h1 {
            font-size: 40px !important;
        }

        > p {
            font-size: 18px;
        }
    }

    #grid {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin-top: 40px;

        .itemSelected {
            background: #f6ffdf;
            border-color: #000;
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 30px 16px;
            height: 200px;
            width: 100%;
            border: 1px solid #e2e8f0;
            border-radius: 12px;
            cursor: pointer;

            &:hover {
                background: #f6ffdf;
                border-color: #000;
            }

            .icon {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin: 0;

                img {
                    width: 60px;
                    height: 60px;
                }
            }

            > h1 {
                font-weight: 600;
                font-size: 12px;
                margin-top: 12px;
                margin-bottom: 6px;
            }

            > p {
                color: #718096;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
            }

            @media (min-width: 768px) {
                > h1 {
                    font-size: 18px;
                }
            }
        }

        @media (min-width: 768px) {
            margin-top: 80px;
            grid-template-columns: 275px 275px 275px;

            #grid {
                > div {
                    width: 275px;
                }
            }
        }
    }
    &.containerReasonDark {
        background: #000;

        #grid {
            display: grid;
            align-items: center;
            justify-content: center;
            grid-template-columns: 1fr;
            grid-gap: 20px;
            margin-top: 40px;

            .itemSelected {
                background: #1d1d1f;
                border-color: #000;
            }

            > div {
                &:hover {
                    background: #1d1d1f;
                }

                > h1 {
                    font-weight: 600;
                    font-size: 12px;
                    margin-top: 16px;
                }

                > p {
                    color: #718096;
                }

                @media (min-width: 768px) {
                    > h1 {
                        font-size: 18px;
                    }
                }
            }

            @media (min-width: 768px) {
                margin-top: 80px;
                grid-template-columns: 275px 275px 275px;

                #grid {
                    > div {
                        width: 275px;
                    }
                }
            }
        }
    }
`;

export const StageContainer = styled.div`
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
`;
