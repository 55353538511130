import styled from 'styled-components';

export const Header = styled.div`
    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.5em;
        margin-bottom: 24px;
    }

    .input > div {
        margin: 0;
    }

    .input {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 12px;

        .icon {
            position: absolute;
            left: 18px;
        }

        input {
            padding-left: 58px;
            height: 48px;
            border-radius: 12px;
        }
    }

    #options {
        display: none;
    }

    @media (min-width: 64em) {
        position: relative;
        padding-top: 49px;
        display: flex;
        justify-content: space-between;

        #options {
            display: flex;
            align-items: center;
        }

        .option:first-of-type {
            margin-right: 19px;
        }

        .option {
            cursor: pointer;
            width: 52px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #e2e8f0;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #718096;

            &.active {
                background: #fafafa;
                border: 1px solid #cbd5e0;
            }
        }

        h1 {
            position: absolute;
            top: -30px;
        }

        .input {
            width: 295px;
        }
    }

    &.headerLibDark {
        .input {
            input {
                background: #1d1d1f;
                color: #fff;
                border-color: #1d1d1f;

                &:focus {
                    border: 1px solid #fff !important;
                }
            }
        }

        #options {
            div {
                background: #1d1d1f;
                border-color: #1d1d1f;
                color: #a09fa5;
                &.active {
                    background: #3a3a3a;
                    color: #fff !important;
                }
            }
        }
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    padding: 25px 0 27px 0;
    gap: 24px;

    .box {
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-radius: 12px;
        height: 240px;
        margin-bottom: 24px;
        padding: 25px 15px;
        position: relative;
        cursor: pointer;

        &:hover {
            .star {
                opacity: 1;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .icon {
            width: 64px;
            height: 64px;
            background: #f7fafc;
            border: 1px solid #e2e8f0;
            border-radius: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            overflow: hidden;

            img {
                min-width: 64px;
                min-height: 64px;
            }
        }

        h1 {
            font-weight: 700;
            margin: 15px 0;
            font-size: 1em;
        }

        p {
            font-size: 0.8125em;
            color: #718096;
        }
    }

    .box:last-of-type {
        margin-bottom: 0;
    }

    @media (min-width: 64em) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 24px;
        padding-top: 0;

        &.list {
            display: block !important;

            .star {
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
            }

            .box {
                height: auto;
                display: flex;
                align-items: center;
                margin: 0;
                border-left: 0;
                border-right: 0;
                border-top: 0;
                border-radius: 0;
                border-bottom: 1px solid #edf2f7 !important;
                padding-left: 10;
                padding-right: 0;

                .icon {
                    margin-right: 16px;
                }

                h1 {
                    margin-top: 0;
                    margin-bottom: 4px;
                }
            }

            a:last-of-type > div {
                border-bottom: 0 !important;
            }
        }

        &.gridLayoutDark {
            &.list {
                .box {
                    border-bottom-color: #3a3a3a !important;
                }
            }
        }
    }
`;

export const Tags = styled.nav`
    display: none;

    flex-wrap: wrap;
    margin-top: 32px;
    margin-bottom: 40px;
    gap: 10px;

    .tag {
        cursor: pointer;
        padding: 7px 15px;
        border: 1px solid #e2e8f0;
        border-radius: 30px;
        color: #1a202c;
        font-weight: 600;
        font-size: 0.875em;

        &.active {
            background: #d0ff61 !important;
            border: 1px solid #d0ff61 !important;
            color: #000 !important;
        }
    }

    @media (min-width: 64em) {
        display: flex;
    }
`;

export const IconStar = styled.div`
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.2s;
    position: absolute;
    right: 21px;
    top: 28px;
`;
