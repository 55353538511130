import axios, { AxiosHeaders } from 'axios';
import {
    API_BASE_URL,
    API_BASE_URL_STRIPE,
    STRIPE_API_KEY,
} from '../../config/contants';
import firebase from 'firebase/compat/app';
import { idsStore } from 'app/presentation/stores/idsStores';

export const getToken = async () => {
    return firebase.auth()?.currentUser?.getIdToken();
};

export const apiStripe = axios.create({
    baseURL: API_BASE_URL_STRIPE,
});

apiStripe.interceptors.request.use(async config => {
    //@ts-ignore
    config.headers = {
        Authorization: `Bearer ${STRIPE_API_KEY}`,
        Accept: 'application/json',
    };

    return config;
});

export const api = axios.create({
    baseURL: API_BASE_URL,
});

api.interceptors.request.use(async config => {
    const token = await getToken();
    const ids = idsStore.getState().data;
    const localStorageBrandId = localStorage.getItem('activeBrandId');

    const headers = {
        Authorization: token ?? "",
        Accept: "application/json",
        "X-Workspace-Id": ids.workspace_id,
        "X-Project-Id": ids.project_id,
        "X-Brand-Id": localStorageBrandId ?? ""
    }

    config.headers = new AxiosHeaders(Object.fromEntries(Object.entries(headers).filter(([, value]) => value !== "" && value !== 0 && value !== undefined)));

    return config;
});

