import React from 'react';
import useLoading from '../hooks/useLoading';
import useUserConfig from '../hooks/useUserConfigs';
import { TeamContext } from '../contexts/TeamContext';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import { Team } from '../components/interfaces/team-props.interface';
import { useSnackbar } from 'notistack';
import { TeamService } from 'services/team';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';

export interface PageProps {
    CurrentPage: number;
    PerPage: number;
    TotalPages: number;
    prev: number;
    next: number;
    totalEntries: number;
}

const teamService = new TeamService();

const TeamProvider: React.FC<ProviderProps> = ({ children }) => {
    const [team, setTeam] = React.useState<Team[]>();
    const [page, setPage] = React.useState<PageProps>();
    const intl = useIntl();

    const { showLoading, closeLoading } = useLoading();
    const { workspace, getLimits } = useUserConfig();
    const { enqueueSnackbar } = useSnackbar();

    const getTeam = async (
        pageNumber?: number,
        limit?: number,
        search?: string,
    ) => {
        try {
            showLoading();

            const data = await teamService.getAll(
                workspace.id,
                pageNumber,
                limit,
                search,
            );

            setPage({
                CurrentPage: data.page,
                PerPage: data.limit,
                TotalPages: data.total_pages,
                next: data.next,
                prev: data.prev,
                totalEntries: data.total,
            });
            setTeam(data.data);
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const removeMember = async (id: number) => {
        try {
            showLoading();

            await teamService.delete(id, workspace.id);
            await getTeam(1, page?.PerPage);

            enqueueSnackbar(routeTranslate(intl, 'team.users.remove'), {
                variant: 'success',
            });
            await getLimits();

            closeLoading();
        } catch (e) {
            enqueueSnackbar(routeTranslate(intl, 'team.users.remove.error'), {
                variant: 'error',
            });
            closeLoading();
        }
    };

    return (
        <TeamContext.Provider
            value={{
                getTeam,
                removeMember,
                team,
                pageInfo: page,
            }}
        >
            {children}
        </TeamContext.Provider>
    );
};

export default TeamProvider;
