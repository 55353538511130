import React from 'react';

export const SpinSave: React.FC = () => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1774_61010)">
            <path
                d="M16.5 9C16.5 13.5 13.2075 16.5 9.1455 16.5C5.844 16.5 3.18 14.7487 2.25 12M1.5 9C1.5 4.5 4.7925 1.5 8.8545 1.5C12.1567 1.5 14.82 3.25125 15.75 6"
                stroke="#A0AEC0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.25 12.75L2.25 12L1.5 15M12.75 5.25L15.75 6L16.5 3"
                stroke="#A0AEC0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1774_61010">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
