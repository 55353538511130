import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { IconCheck } from '@tabler/icons-react';
import {
    LanguageSelect,
    Button,
    Input,
    TextArea,
} from 'app/presentation/components/agent';
import { Question } from 'services/agent/interfaces';
import useAgent from 'app/presentation/hooks/useAgent';
import { SubmitHandler, useForm } from 'react-hook-form';
import useText from 'app/presentation/hooks/useText';

type QuestionData = {
    question: string;
    answer: string;
};

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    item_editing?: Question;
    languageID: number;
}

export const ModalNewQuestion: React.FC<ModalProps> = ({
    active,
    handleClose,
    item_editing = undefined,
    languageID,
}) => {
    const [language, setLanguage] = React.useState<number>(-1);
    const [defaultLanguage, setDefaultLanguage] = React.useState<number>();
    const { agent, addQuestion, updateQuestion } = useAgent();
    const { register, handleSubmit, setValue } = useForm<QuestionData>();
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    React.useEffect(() => {
        if (!agent) {
            return;
        }

        const { config } = agent;
        if (!config) {
            return;
        }

        const defaultAgentLanguage = config.additional_languages.find(
            lang => lang.is_default,
        );

        if (defaultAgentLanguage) {
            setDefaultLanguage(defaultAgentLanguage.language.id);
        }
    }, [agent]);

    React.useEffect(() => {
        setLanguage(languageID);
        if (!item_editing) {
            setValue('answer', '');
            setValue('question', '');
            return;
        } else {
            setValue('answer', item_editing.answer);
            setValue('question', item_editing.question);
        }
    }, [active]);

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            handleClose();
        }
    };

    const onSubmit: SubmitHandler<QuestionData> = async data => {
        if (item_editing) {
            if (!item_editing.id) {
                return;
            }
            setFormSubmitLoading(true);
            await updateQuestion(
                item_editing.id,
                data.answer,
                data.question,
                language,
            ).then(() => {
                handleClose();
            });
            setFormSubmitLoading(false);
        } else {
            setFormSubmitLoading(true);
            addQuestion(data.answer, data.question, language)
                .then(() => {
                    handleClose();
                })
                .finally(() => setFormSubmitLoading(false));
        }
    };

    const { textGetter } = useText();
    const modalText = textGetter('agent.components.modal.new-question');
    const commonsText = textGetter('agent.components.modal.commons');

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal onClick={handleCloseContainer}>
                <form
                    className="content-modal"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="modal-header">
                        <p>{modalText('title')}</p>
                        <IoMdClose size={25} onClick={handleClose} />
                    </div>
                    {language && !item_editing && (
                        <LanguageSelect
                            onChange={setLanguage}
                            value={language}
                            defaultLanguage={defaultLanguage}
                        ></LanguageSelect>
                    )}
                    <div className="input-container">
                        <p>{modalText('question')}</p>
                        <Input
                            registerValues={register('question')}
                            required
                        ></Input>
                    </div>
                    <div className="input-container">
                        <p>{modalText('answer')}</p>
                        <TextArea
                            registerValues={register('answer')}
                            required
                        ></TextArea>
                    </div>
                    <div className="button-container">
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={handleCloseContainer}
                        >
                            {commonsText('button.cancel')}
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            loading={formSubmitLoading}
                            loadingSize="20px"
                            className="save-button"
                        >
                            <IconCheck></IconCheck>{' '}
                            <span>{commonsText('button.save')}</span>
                        </Button>
                    </div>
                </form>
            </ContainerModal>
        </Fade>
    );
};
