import React from 'react';
import { useIntl } from 'react-intl';
import Error404 from 'assets/images/svg/404.svg';
import Error404Dark from 'assets/images/svg/404-dark.svg';
import { NotFoundContainer } from './styles';
import { routeTranslate } from 'utils/intl';
import { Button } from 'app/presentation/components';
import { Link, useLocation } from 'react-router-dom';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { pathStartsWith } from 'utils/routes';

type INotFound = {
    fromRoot?: boolean;
    useAbsolutPath?: boolean;
};

const PageNotFound: React.FC<INotFound> = ({ fromRoot = false, useAbsolutPath = false }) => {
    const intl = useIntl();
    const { darkmode } = useDarkMode();
    const { pathname } = useLocation();

    const path =
        '../../' +
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.home');

    const absolutPath = () => {
        if (pathStartsWith(pathname, intl, 'route.urlLang', 'route.agent.home')) {
            return `/${routeTranslate(intl, 'route.urlLang')
            }${routeTranslate(intl, 'route.agent.home')}`
        } else {
            return `/${routeTranslate(intl, 'route.urlLang')
            }${routeTranslate(intl, 'route.backoffice')
            }${routeTranslate(intl, 'route.home')}`
        }
    }

    return (
        <NotFoundContainer>
            <img
                src={darkmode ? Error404Dark : Error404}
                className={fromRoot ? 'marginTop' : ''}
            />
            <div>
                <h2 style={{ color: darkmode ? '#fff' : '' }}>
                    {routeTranslate(intl, 'notFound.title')}
                </h2>
                <p>{routeTranslate(intl, 'notFound.description')}</p>
                <Link to={ useAbsolutPath ? absolutPath() : path}>
                    <Button
                        className="backHome"
                        style={{
                            color: darkmode ? '#000' : '',
                            background: darkmode ? '#d0ff61' : '',
                            borderColor: darkmode ? '#d0ff61' : '',
                        }}
                    >
                        {routeTranslate(intl, 'notFound.back')}
                    </Button>
                </Link>
            </div>
        </NotFoundContainer>
    );
};

export default PageNotFound;
