import React from 'react';
import * as S from './styles';
import { IconChevronDown } from '@tabler/icons-react';
import { NavButton } from '../NavButton';
import { useLocation } from 'react-router-dom';
import { Menu } from '@mui/material';
import { Button } from '../Button';
import { ThemeContext } from 'styled-components';

export interface SideMenuItem {
    content: React.ReactNode;
    route: string;
    activeRoutes?: string[];
}

interface SideMenuProps {
    expansionPanelButton: React.ReactNode;
    expansionPanelItems: SideMenuItem[];
    menuItems: SideMenuItem[];
}

export const SideMenu: React.FC<SideMenuProps> = props => {
    return (
        <S.SideMenu>
            <MobileMenu {...props}></MobileMenu>
            <DesktopMenu {...props}></DesktopMenu>
        </S.SideMenu>
    );
};

const isItemActive = (item: SideMenuItem, pathname: string): boolean => {
    const { route, activeRoutes = [] } = item;
    return (
        route == pathname ||
        activeRoutes
            .map(route => pathname.startsWith(route))
            .reduce((prev, current) => prev || current, false)
    );
};

const DesktopMenu: React.FC<SideMenuProps> = ({
    expansionPanelButton,
    expansionPanelItems,
    menuItems,
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const panelRef = React.useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    React.useEffect(() => {
        expansionPanelItems.forEach(item => {
            if (isItemActive(item, pathname)) {
                expand();
            }
        });
    }, [pathname]);

    const expand = () => {
        const panel = panelRef.current;
        if (panel == null) {
            return;
        }

        panel.style.maxHeight = panel.scrollHeight + 'px';
        setExpanded(true);
    };

    const contract = () => {
        const panel = panelRef.current;
        if (panel == null) {
            return;
        }

        panel.style.maxHeight = '0px';
        setExpanded(false);
    };

    const expansionButtonClick = () => {
        if (expanded) {
            contract();
        } else {
            expand();
        }
    };

    return (
        <S.DesktopMenu>
            <button
                type="button"
                onClick={expansionButtonClick}
                className="menu-item"
            >
                {expansionPanelButton}
                <div
                    className={
                        'expansion-button-icon' +
                        (expanded ? ' expanded-icon' : '')
                    }
                >
                    <IconChevronDown></IconChevronDown>
                </div>
            </button>
            <div className="panel-content" ref={panelRef}>
                {expansionPanelItems.map(item => {
                    return (
                        <div className="menu-panel-item-container">
                            <NavButton to={item.route}>
                                {item.content}
                            </NavButton>
                        </div>
                    );
                })}
            </div>
            {menuItems.map(item => {
                return (
                    <NavButton to={item.route} className={'menu-link'}>
                        <div className="item-content">{item.content}</div>
                    </NavButton>
                );
            })}
        </S.DesktopMenu>
    );
};

const MobileMenu: React.FC<SideMenuProps> = props => {
    const theme = React.useContext(ThemeContext);
    const [anchorOptsMenu, setAnchorOptsMenu] =
        React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorOptsMenu);
    const [buttonContent, setButtonContent] =
        React.useState<React.ReactNode>('');
    const { pathname } = useLocation();

    const closeOptsMenu = () => {
        setAnchorOptsMenu(null);
    };

    React.useEffect(() => {
        const { menuItems, expansionPanelItems } = props;
        [...menuItems, ...expansionPanelItems].forEach(item => {
            if (isItemActive(item, pathname)) {
                setButtonContent(item.content);
            }
        });
    }, [pathname]);

    const paperPropsStyle: React.CSSProperties = {
        borderRadius: '12px',
        boxShadow: 'box-shadow: 0px 16px 32px 0px #71809614',
        background:
            theme.components.sideMenu.mobile.dropdown.menu.background.color,
        padding: '0px',
        width: '331px',
        marginTop: '15px',
    };

    const menuListStyle: React.CSSProperties = {
        padding: '8px',
        boxShadow: '0',
        background:
            theme.components.sideMenu.mobile.dropdown.menu.background.color,
        height: 'fit-content',
    };

    return (
        <S.MobileMenu>
            <Button
                type="button"
                variant="secondary"
                className="menu-button"
                onClick={event => {
                    setAnchorOptsMenu(event.currentTarget);
                }}
            >
                {buttonContent}
                <IconChevronDown />
            </Button>
            <Menu
                anchorEl={anchorOptsMenu}
                open={open}
                onClose={closeOptsMenu}
                PaperProps={{
                    style: paperPropsStyle,
                }}
                MenuListProps={{
                    style: menuListStyle,
                }}
            >
                <MobileDropdownMenu {...props}></MobileDropdownMenu>
            </Menu>
        </S.MobileMenu>
    );
};

const MobileDropdownMenu: React.FC<SideMenuProps> = ({
    expansionPanelButton,
    expansionPanelItems,
    menuItems,
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const panelRef = React.useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    React.useEffect(() => {
        expansionPanelItems.forEach(item => {
            if (isItemActive(item, pathname)) {
                expand();
                return;
            }
        });
    }, [pathname]);

    const expand = () => {
        const panel = panelRef.current;
        if (panel == null) {
            return;
        }

        panel.style.maxHeight = panel.scrollHeight + 'px';
        setExpanded(true);
    };

    const contract = () => {
        const panel = panelRef.current;
        if (panel == null) {
            return;
        }

        panel.style.maxHeight = '0px';
        setExpanded(false);
    };

    const expansionButtonClick = () => {
        if (expanded) {
            contract();
        } else {
            expand();
        }
    };

    return (
        <S.MobileDropdownMenu>
            <button
                type="button"
                onClick={expansionButtonClick}
                className="menu-item"
            >
                {expansionPanelButton}
                <div
                    className={
                        'expansion-button-icon' +
                        (expanded ? ' expanded-icon' : '')
                    }
                >
                    <IconChevronDown></IconChevronDown>
                </div>
            </button>
            <div className="panel-content" ref={panelRef}>
                {expansionPanelItems.map(item => {
                    return (
                        <div className="menu-panel-item-container">
                            <NavButton to={item.route}>
                                {item.content}
                            </NavButton>
                        </div>
                    );
                })}
            </div>
            {menuItems.map(item => {
                return (
                    <NavButton to={item.route} className={'menu-link'}>
                        <div className="item-content">{item.content}</div>
                    </NavButton>
                );
            })}
        </S.MobileDropdownMenu>
    );
};
