export type Time = number;

export const SECOND: Time = 1;
export const MINUTE: Time = SECOND * 60;
export const HOUR: Time = MINUTE * 60;
export const DAY: Time = HOUR * 24;
export const MONTH: Time = DAY * 30;
export const YEAR: Time = MONTH * 12;


export const timeTextMap = {
    [SECOND]: 'second',
    [MINUTE]: 'minute',
    [HOUR]: 'hour',
    [DAY]: 'day',
    [MONTH]: 'month',
    [YEAR]: 'year',
}

