import { createGlobalStyle } from 'styled-components';

export const DarkMode = createGlobalStyle`
    p, h1 {
        color: #fff;
    }

    body {
        color: #fff;
        background-color: #000000;
    }

    input, textarea, select {
        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: #7E7E7E !important;
        }
        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: #7E7E7E !important;
            opacity:  1;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: #7E7E7E !important;
            opacity:  1;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #7E7E7E !important;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: #7E7E7E !important;

        }

        ::placeholder { /* Most modern browsers support this now. */
            color: #7E7E7E !important;
        }

        &:disabled {
            background: #3A3A3A !important;
            color: #7E7E7E !important;

        }

        &:focus {
            border-color: #fff !important;
        }
    }

    .background {
        background-color: #1A202C;
        color: #fff;
    }

    .dark-text-white {
        color: #fff !important;
    }

    .dark-text-black {
        color: #000 !important;
    }

    .box-dark-mode {
        background: #1D1D1F !important;
        border: 1px solid #1D1D1F !important;
        border-radius: 16px;
        color: #fff !important;

        span {
            color: #fff !important;
        }

        &.favoriteDark {
            background: #FFE168 !important;
            span {
            color: #000 !important;
        }
        }
    }

    a, h1, li, li span {
        color: #fff;
    }
`;
