import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .content-modal {
        background: #ffffff;
        border-radius: 16px;
        width: 98%;
        max-width: 420px;
        height: 100%;
        max-height: 287px;
        padding: 0px 33px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        position: relative;
        header {
            position: absolute;
            top: 24px;
            right: 24px;

            svg {
                cursor: pointer;
            }
        }
        h2 {
            color: #000;
            font-weight: 700;
            font-size: 1.5rem;
            margin-top: 58px;
            margin-bottom: 12px;
        }

        p {
            color: #a09fa5;
            font-size: 0.875rem;
            font-weight: 500;
            text-align: center;
        }
        a {
            width: 100%;

            button {
                border: none;
                background-color: #d0ff61;
                margin-top: 20px;
                padding: 14px 0;
                width: 100%;
                border-radius: 12px;
                color: #000;
                font-weight: 700;
                font-size: 0.875rem;
                cursor: pointer;
            }
        }

        .action-buttons {
            display: flex;
            margin-top: 34px;
            button {
                max-height: 48px;
                width: 185.43px;
                font-size: 0.875rem;
            }

            .close {
                background: transparent;
                border: 1px solid #a09fa5;
                color: #a09fa5;
                margin-left: 15px;
                max-width: 158px;
            }
        }
    }
    &.listProjectsDark {
        .content-modal {
            background: #1d1d1f;
            h2 {
                color: #fff;
            }
        }
    }
`;
