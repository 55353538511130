import styled from 'styled-components';
import { Popover as MuiPopover } from '@mui/material';

export const Popover = styled(MuiPopover)`
    .MuiPaper-root {
        width: 309px;
        height: 327px;
        border-radius: 12px;

        overflow: auto;
    }

    .modal-body {
        margin-top: 12px;
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 12px;

        .section {
            margin-top: 17px;

            .section-header {
                font-weight: 600;
                font-size: 14px;
                color: rgba(126, 126, 126, 1);
                padding-bottom: 8px;
                border-bottom: 1px solid rgba(226, 232, 240, 1);
            }

            .section-content {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;

                margin-top: 8px;
            }
        }
    }


`;
