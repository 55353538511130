import { useContext } from 'react';
import { TeamContext } from '../contexts/TeamContext';
import { TeamContextData } from '../contexts/interfaces/team-context-props.interface';

function useTeam(): TeamContextData {
    const context = useContext(TeamContext);

    if (!context) {
        throw new Error('useTeam deve ser usado com o TeamProvider');
    }

    return context;
}

export default useTeam;
