import styled, { css } from 'styled-components';

export const SideMenu = styled.div`
    position: relative;
    width: 100%;

    @media (min-width: 64em) {
        width: fit-content;
    }
`;

export const DesktopMenu = styled.div`
    ${({
        theme: {
            components: {
                sideMenu: {
                    desktop: { border, background, icon },
                },
            },
        },
    }) => css`
        display: none;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        height: calc(100vh - 80px);
        padding: 0px 0px 40px 0px;
        border-right: 1px solid ${border.color};
        width: 283px;

        @media (min-width: 64em) {
            display: flex;
        }

        button {
            width: 100%;
            background-color: inherit;
            border: none;
        }

        .menu-item,
        .menu-link {
            cursor: pointer;
            height: 38px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100% !important;
            padding: 11px 18px 11px 18px;
            border-left: 1px solid transparent;
            padding-left: 17px;
            transition: all linear 0.05s;
            background-color: ${background.color};

            .expansion-button-icon {
                height: fit-content;
                width: fit-content;
                transition: transform linear 0.05s;
                padding: 0px;
            }

            .expansion-button-icon svg {
                display: block;
                width: 16px;
                height: 16px;
                margin: 0px;
                color: ${icon.color};
            }

            .expanded-icon {
                transform: rotate(-180deg);
            }

            :first-child {
                margin-top: 30px;
            }
        }

        .menu-link {
            :hover,
            &.active-route {
                background-color: ${background.active};
                border-color: ${border.active};
            }
        }

        .panel-content {
            max-height: 0px;
            overflow-y: hidden;
            transition: max-height linear 0.05s;
        }

        .menu-panel-item-container {
            height: 38px;
            padding-left: 45px;
            display: flex;
            align-items: center;

            a {
                display: block;
                height: 30px !important;
                width: 220px !important;
                display: flex;
                align-items: center;
                justify-content: left;
                border-radius: 6px;
                padding: 0px 6px;
                transition: all linear 0.05s;
                background-color: ${background.color};

                :hover,
                &.active-route {
                    background-color: ${background.active};
                    font-weight: 700;
                }
            }
        }
    `}
`;

export const MobileMenu = styled.div`
    ${({
        theme: {
            components: {
                sideMenu: {
                    mobile: {
                        dropdown: {
                            button: { text, icon, border, background },
                        },
                    },
                },
            },
        },
    }) => css`
        display: block;
        margin-top: 27px;
        @media (min-width: 64em) {
            display: none;
        }

        .menu-button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 48px;
            padding: 12px;
            gap: 0px;
            border-radius: 6px;
            border-color: ${border.color};
            background-color: ${background.color};
            color: ${text.color};

            svg {
                min-width: 16px;
                width: 16px;
                min-height: 16px;
                height: 16px;
                color: ${icon.color};
            }
        }
    `}
`;

export const MobileDropdownMenu = styled.div`
    ${({
        theme: {
            components: {
                sideMenu: {
                    mobile: { background, icon },
                },
            },
        },
    }) => css`
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;

        button {
            width: 100%;
            background-color: inherit;
            border: none;
        }

        .menu-item,
        .menu-link {
            cursor: pointer;
            height: 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100% !important;
            padding: 0px 8px;
            transition: all linear 0.05s;
            background-color: ${background.color};
            border-radius: 6px;

            .expansion-button-icon {
                height: fit-content;
                width: fit-content;
                transition: transform linear 0.05s;
                padding: 0px;
            }

            .expansion-button-icon svg {
                display: block;
                width: 16px;
                height: 16px;
                margin: 0px;
                color: ${icon.color};
            }

            .expanded-icon {
                transform: rotate(-180deg);
            }
        }

        .menu-link {
            :hover,
            &.active-route {
                background-color: ${background.active};
            }
        }

        .item-content {
            display: flex;
            width: fit-content;
            align-items: center;
            justify-content: start;
        }

        .panel-content {
            max-height: 0px;
            overflow-y: hidden;
            transition: max-height linear 0.05s;
        }

        .menu-panel-item-container {
            height: 38px;
            padding-left: 45px;
            display: flex;
            align-items: center;

            a {
                display: block;
                height: 32px !important;
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: left;
                border-radius: 6px;
                padding: 0px 8px;
                transition: all linear 0.05s;
                background-color: ${background.color};

                :hover,
                &.active-route {
                    background-color: ${background.active};
                    font-weight: 700;
                }
            }
        }
    `}
`;

export const SideMenuExpansionPanelItemContent = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.2px;
`;

export const SideMenuExpansionPanelButtonContent = styled.div`
    ${({
        theme: {
            components: {
                sideMenu: {
                    desktop: { icon },
                },
            },
        },
    }) => css`
        display: flex;
        width: fit-content;
        align-items: center;
        justify-content: start;

        color: ${icon.color};
        svg,
        img {
            height: 22px;
            width: 22px;
            margin-right: 10px;
            display: block;
        }

        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.2px;

            @media (min-width: 64em) {
                font-weight: 600;
            }
        }
    `}
`;

export const SideMenuItemContent = styled.div`
    ${({
        theme: {
            components: {
                sideMenu: {
                    desktop: { icon },
                },
            },
        },
    }) => css`
        display: flex;
        width: fit-content;
        align-items: center;
        justify-content: start;

        svg,
        img {
            min-height: 22px !important;
            min-width: 22px !important;
            margin-right: 10px;
            display: block;

            color: ${icon.color} !important;
        }

        p,
        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.2px;
        }
    `}
`;
