import React from 'react';
import { useIntl } from 'react-intl';
import { routeTranslate as t } from 'utils/intl';
import { AiOutlineLink } from 'react-icons/ai';
import { TbTextPlus } from 'react-icons/tb';
import { Button, Input, TextArea } from 'app/presentation/components/common';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { VoiceToneServices } from 'services/brand/voice_tone';
import { isValidURL, addHttpsToUrl } from 'utils/validatedUrl';
import { ExtractVoiceToneMethods } from '../index';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

// TODO maybe create a "context" to get this props as context, already prop hell
interface Step1Props {
    selectedExtractVoiceToneMethod: ExtractVoiceToneMethods;
    setSelectedExtractVoiceToneMethod: (value: ExtractVoiceToneMethods) => void;

    generatedVoiceTone: string;
    setGeneratedVoiceTone: (value: string) => void;

    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;

    isGenerating: boolean;
    setIsGenerating: (value: boolean) => void;

    voiceToneName: string;
    setVoiceToneName: (value: string) => void;

    voiceToneURL: string;
    setVoiceToneURL: (value: string) => void;

    voiceToneText: string;
    setVoiceToneText: React.Dispatch<React.SetStateAction<string>>;
}

// TODO change input component so can be used custom props
type Step1FormFields = {
    voiceToneName: string;
    voiceToneURL: string | undefined;
    voiceToneText: string | undefined;
};

const voiceToneService = new VoiceToneServices();

export const Step1: React.FC<Step1Props> = (props: Step1Props) => {
    const intl = useIntl();
    const { workspace, project } = useUserConfig();
    const [loading, setLoading] = React.useState(false);
    const { register, handleSubmit, setValue } = useForm<FieldValues>(); // can't use custom type whyy
    const [customLoading, setCustomLoading] = React.useState(false);
    const ref = React.useRef();

    const onSubmitFormStep1: SubmitHandler<FieldValues> = async (formData) => {
        const data = formData as Step1FormFields;

        console.log(data);
        props.setCurrentStep(prev => prev + 1);
        props.setIsGenerating(true);

        /*const req: GenerateVoiceToneRequest = {

        }

        try {

        }

        voiceToneService.generateVoiceTone()*/
    };

    React.useEffect(() => {
        setValue('voiceToneURL', props.voiceToneURL);
    }, [props.voiceToneURL]);

    React.useEffect(() => {
        setValue('voiceToneName', props.voiceToneName);
    }, [props.voiceToneName]);

    React.useEffect(() => {
        setValue('voiceToneText', props.voiceToneText);
    }, [props.voiceToneText]);

    /*const handleDiv = () => {
        if (divRef.current) {
            divRef.current.focus();
        }
    };*/

    /*const handleGenerateTone = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        try {
            if (selectedMethod) {
                if (selectedMethod === 'text' && textAreaContent === '') {
                    return;
                }
                if (selectedMethod === 'url' && urlContent === '') {
                    return;
                }
                setLoading(true);
                let data = {};

                if (selectedMethod === 'url') {
                    const urlSanitazed = addHttpsToUrl(urlContent);
                    if (!isValidURL(urlSanitazed)) {
                        alert(routeTranslate(intl, 'modal.brand.url.error'));
                        setLoading(false);
                        return;
                    }
                    data = {
                        url: urlSanitazed,
                    };
                } else {
                    data = {
                        text: textAreaContent,
                    };
                }

                const result = await voiceToneService.generateTone(
                    workspace.id,
                    project.id,
                    data,
                );

                if (result.content) {
                    setGeneratedContent(result.content);
                } else {
                    throw new Error();
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            alert(routeTranslate(intl, 'modal.tone.error.generic'));
        }
    };*/

    /*React.useEffect(() => {
        if (selectedMethod !== undefined) {
            handleDiv();
        }
    }, [selectedMethod]);*/

    return (
        <div className="step-1">
            <form onSubmit={handleSubmit(onSubmitFormStep1)}>
                <>
                    <label className="teste">Dê um nome a essa voz*</label>
                    <Input
                        value={props.voiceToneName}
                        id="voiceToneName"
                        type="text"
                        required
                        placeholder="Ex: Inteligente e engraçada"
                        style={{ marginTop: '12px' }}
                        onChange={e => {
                            props.setVoiceToneName(e.target.value);
                        }}
                        register={register}
                    ></Input>
                </>

                <div className="buttons-insert-content">
                    <p className="">{t(intl, 'modal.tone.insert')}</p>

                    <div className="buttons">
                        <div
                            className={`${
                                props.selectedExtractVoiceToneMethod ===
                                    'url' && 'active'
                            }`}
                            onClick={() => {
                                props.setSelectedExtractVoiceToneMethod('url');
                            }}
                        >
                            <li>
                                <AiOutlineLink size={22} />
                            </li>
                            <h6>{t(intl, 'modal.tone.insert.url')}</h6>
                            <span>{t(intl, 'modal.tone.insert.url.desc')}</span>
                        </div>
                        <div
                            className={`${
                                props.selectedExtractVoiceToneMethod ===
                                    'text' && 'active'
                            }`}
                            onClick={() => {
                                props.setSelectedExtractVoiceToneMethod('text')
                            }}
                        >
                            <li>
                                <TbTextPlus size={22} />
                            </li>
                            <h6>{t(intl, 'modal.tone.insert.text')}</h6>
                            <span>
                                {t(intl, 'modal.tone.insert.text.desc')}
                            </span>
                        </div>
                    </div>

                    <div className="input-textarea-container">
                        <TransitionGroup>
                            {props.selectedExtractVoiceToneMethod === 'url' && (
                                <Collapse>
                                    {/*TODO fix collapsing*/}
                                    {/*<CSSTransition timeout={500} classNames={"my-node"}> */}
                                    <>
                                        <label className="teste">
                                            {t(
                                                intl,
                                                'modal.tone.insert.url.paste',
                                            )}
                                        </label>
                                        <Input
                                            id="voiceToneURL"
                                            value={props.voiceToneURL}
                                            register={register}
                                            type="text"
                                            placeholder="ex: https://www.jarbas.ai"
                                            onChange={e => {
                                                props.setVoiceToneURL(
                                                    e.target.value,
                                                );
                                            }}
                                            required
                                            style={{ marginTop: '12px' }}
                                        />
                                        <p>
                                            {t(
                                                intl,
                                                'modal.tone.insert.url.paste.desc',
                                            )}
                                        </p>
                                    </>
                                </Collapse>
                            )}

                            {props.selectedExtractVoiceToneMethod ===
                                'text' && (
                                <Collapse>
                                    <>
                                        <TextArea
                                            id="voiceToneText"
                                            value={props.voiceToneText}
                                            label={t(
                                                intl,
                                                'modal.tone.insert.text.paste',
                                            )}
                                            placeholder={t(
                                                intl,
                                                'modal.tone.insert.text.paste.desc',
                                            )}
                                            minLength={1}
                                            maxLength={2000}
                                            onChange={e => {
                                                props.setVoiceToneText(
                                                    e.target.value,
                                                );
                                            }}
                                            register={register}
                                            required
                                        />
                                        <span className="counter">
                                            {props.voiceToneText.length}/2000
                                        </span>
                                    </>
                                </Collapse>
                            )}

                            {props.selectedExtractVoiceToneMethod && (
                                <Collapse>
                                    <>
                                        <div
                                            className="btn-container"
                                            tabIndex={0}
                                        >
                                            <Button
                                                loading={loading}
                                                type="submit"
                                                disabled={
                                                    (props.selectedExtractVoiceToneMethod ===
                                                        'url' &&
                                                        props.voiceToneURL
                                                            .length < 1) ||
                                                    (props.selectedExtractVoiceToneMethod ===
                                                        'text' &&
                                                        props.voiceToneText
                                                            .length < 1) ||
                                                    props.voiceToneName.length <
                                                        1
                                                }
                                            >
                                                {t(intl, 'button.continue')}
                                            </Button>
                                        </div>
                                    </>
                                </Collapse>
                            )}
                        </TransitionGroup>
                    </div>
                </div>
            </form>
        </div>
    );
};
