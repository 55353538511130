import axios from 'axios';
import { API_BASE_URL } from 'config/contants';
import { getToken } from 'services/api';
import { GenerateVoiceToneRequest, GenerateVoiceToneResponse } from './types';

const api = axios.create({
    baseURL: API_BASE_URL,
});

/* 
    type generateBrandVoiceRequest struct {
    URL                     string `json:"url" validate:"url"`
    Text                    string `json:"text" validate:"text"`
    RegenerationInformation string `json:"regeneration_information"`
    HumorLevel              int    `json:"humor_level" validate:"required,min=1,max=3"`
    FormalityLevel          int    `json:"formality_level" validate:"required,min=1,max=3"`
    AllowJokesAndMemes      bool   `json:"allow_jokes_and_memes" validate:"required"`
}
*/

export class VoiceToneServices {
    private readonly path = '/brand-voices';

    async getAll(workspace_id: number, project_id: number, brand_id: number) {
        const token = await getToken();
        const { data } = await api.get(
            this.path.concat(`?brand_id=${brand_id}&page=1&limit=50`),
            {
                headers: {
                    Authorization: token,
                    'X-Workspace-Id': workspace_id,
                    'X-Project-Id': project_id,
                },
            },
        );

        return data.data;
    }

    async getAllVoiceTones(workspace_id: number, project_id: number, brand_id: number) {
        const token = await getToken();
        const { data } = await api.get(
            this.path.concat(`?brand_id=${brand_id}&page=1&limit=50`),
            {
                headers: {
                    Authorization: token,
                    'X-Workspace-Id': workspace_id,
                    'X-Project-Id': project_id,
                },
            },
        );

        return data.data;
    }

    async create(workspace_id: number, project_id: number, dataObj: any) {
        const token = await getToken();
        const { data } = await api.post(this.path, dataObj, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        return data;
    }

    async generateVoiceTone(
        req: GenerateVoiceToneRequest,
    ): Promise<GenerateVoiceToneResponse> {
        const token = await getToken();
        const { data } = await api.post(
            this.path.concat('/generate'),
            req.data,
            {
                headers: {
                    Authorization: token,
                    'X-Workspace-Id': req.workspaceID,
                    'X-Project-Id': req.projectID,
                },
            },
        );

        return data as GenerateVoiceToneResponse;
    }

    async edit(
        workspace_id: number,
        project_id: number,
        dataObj: any,
        voice_id: number,
    ) {
        const token = await getToken();
        const { data } = await api.put(`${this.path}/${voice_id}`, dataObj, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        return data;
    }

    async generateTone(workspace_id: number, project_id: number, dataObj: any) {
        const token = await getToken();
        const { data } = await api.post(
            this.path.concat('/generate'),
            dataObj,
            {
                headers: {
                    Authorization: token,
                    'X-Workspace-Id': workspace_id,
                    'X-Project-Id': project_id,
                },
            },
        );

        return data;
    }

    async delete(workspace_id: number, project_id: number, voice_id: number) {
        const token = await getToken();
        const { data } = await api.delete(`${this.path}/${voice_id}`, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        return data;
    }
}
