import React from 'react';
import { Workspace } from '../hooks/interfaces/workspace';
import { Project } from '../hooks/interfaces/project';
import { Brands } from '../hooks/interfaces/brand';
import { Settings, Subscription, Limits } from '../components/interfaces/user-settings-props.interface';
import useLoading from '../hooks/useLoading';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../contexts/AuthContext';
import UserConfigContext from '../contexts/UserConfigContext';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import { Languages } from './interfaces/languages-props.interface';
import { UsersService } from '../../../services/users';
import { WorkspacesService } from '../../../services/workspaces';
import { LanguagesService } from '../../../services/languages';
import { ProjectsService } from '../../../services/projects';
import Loading from '../components/common/Loading';
import { InvitePublic } from '../../../interfaces/workspace.interface';
import { routeTranslate } from '../../../utils/intl';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ModalAccess from '../components/dashboard/ModalAccess';
import ModalAntecipatePlan from '../components/dashboard/ModalAntecipatePlan';
import ModalPlanExpired from '../components/dashboard/ModalPlanExpired';
import ModalPaymentError from '../components/dashboard/ModalPaymentError';
import ModalMaintenance from '../components/dashboard/ModalMaintenance';
import mixpanel from 'mixpanel-browser';
import { BrandServices } from 'services/brand';
import ModalPlans from 'pages/onboarding/ModalPlans';
import { idsStore } from 'app/presentation/stores/idsStores';

const usersService = new UsersService();
const workspacesService = new WorkspacesService();
const projectsService = new ProjectsService();
const languagesService = new LanguagesService();
const brandsService = new BrandServices();

const UserConfigProvider: React.FC<ProviderProps> = ({ children }) => {
    const [workspaces, setWorkspaces] = React.useState<Workspace[]>();
    const [brands, setBrands] = React.useState<Brands[]>();
    const [activeBrand, setActiveBrand] = React.useState<Brands>();
    const [projects, setProjects] = React.useState<Project[]>();
    const [subscriptionDetails, setSubscriptionDetails] = React.useState<Subscription>();
    const [project, setProject] = React.useState<Project>();
    const [workspace, setWorkspace] = React.useState<Workspace>();
    const { setData, data: idsData } = idsStore();
    const [limits, setLimits] = React.useState<Limits>({
        total_words: 0,
        used_words: 0,
        total_brands: 0,
        used_brands: 0,
        total_brand_voices: 0,
        used_brand_voices: 0,
        total_custom_brand_memories: 0,
        used_custom_brand_memories: 0,
        total_seats: 0,
        used_seats: 0,
        total_images: 0,
        used_images: 0,
        total_websearches: 0,
        used_websearches: 0,
    });
    const [modalNewBrand, setModalNewBrand] = React.useState(false);
    const [showModalPaymentError, setShowModalPaymentError] = React.useState(false);
    const [settings, setSettings] = React.useState<Settings>();
    const [languages, setLanguages] = React.useState<Languages[]>();
    const [inviteLink, setInviteLink] = React.useState<InvitePublic>();
    const [modalAccess, setModalAccess] = React.useState(false);
    const [modalPlans, setModalPlans] = React.useState(false);
    const [modalPlanExpired, setModalPlanExpired] = React.useState(false);
    const [modalAntecipatePlan, setModalAntecipatePlan] = React.useState(false);
    const [hasActivePlan, setHasActivePlan] = React.useState(false);
    const { showLoading, closeLoading } = useLoading();
    const { enqueueSnackbar } = useSnackbar();
    const user = React.useContext(AuthContext);
    const navigate = useNavigate();
    const intl = useIntl();

    const getInviteLink = async (id: number) => {
        const result = await workspacesService.getInviteLink(id);
        if (result instanceof Error) {
            return;
        }
        setInviteLink(result.data);
    };

    React.useEffect(() => {
        if (project && workspace) {
            setData(workspace.id, project.id);
            getBrands(workspace.id, project.id);
            getLimits();
            getSubscriptionDetails();
            getInviteLink(workspace.id);
        }
    }, [project, workspace]);

    React.useEffect(() => {
        const pastDue = subscriptionDetails?.status === 'past_due';

        setShowModalPaymentError(pastDue);
    }, [window.location.pathname, workspace, subscriptionDetails]);

    React.useEffect(() => {
        const fetchData = async () => {
            await getWorkspacesNow();
            const dataSetting = await getUserSetting();
            await getUserLanguage();

            checkOnboarding(dataSetting.is_onboarded);
            await checkIfHasPlanActive();
        };

        fetchData().catch(console.error);
        mixpanel.init('768d6c9c5cec179ad70544a18a887536');
    }, []);

    React.useEffect(() => {
        if (workspaces && workspace && !hasActivePlan) {
            const fetchData = async () => {
                await checkIfHasPlanActive();
            };

            fetchData().catch(console.error);
        }
    }, [window.location.pathname, workspace, workspaces, hasActivePlan]);

    React.useEffect(() => {
        if (settings) {
            mixpanel.identify(String(settings.id));
            mixpanel.people.set({
                $first_name: settings.first_name,
                $last_name: settings.last_name,
            });
        }

        if (workspace && user && settings && subscriptionDetails) {
            const startCycleDate = new Date(
                subscriptionDetails.active_plan?.cycle_start || '',
            );
            const endCycleDate = new Date(
                subscriptionDetails.active_plan?.cycle_end || '',
            );
            mixpanel.track('userData', {
                email: user.email,
                stripe_customer_id: workspace.stripe_customer_id,
                is_owner: workspace.owner_id === settings.id,
                plan_name: subscriptionDetails.plan_name,
                total_words: subscriptionDetails.total_words,
                remaining_words: subscriptionDetails.remaining_words,
                cycle_start_at: startCycleDate.toISOString(),
                cycle_end_at: endCycleDate.toISOString(),
                current_workspace_id: workspace.id,
                credits_out: subscriptionDetails.remaining_words === 0,
            });
        }
    }, [settings]);

    const successMessage = (message: string) => {
        enqueueSnackbar(message, {
            variant: 'success',
        });
    };

    const checkOnboarding = (is_onboarded: boolean) => {
        if (!is_onboarded) {
            if (user && !user.emailVerified) {
                navigate(`/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(intl, 'route.emailVerify')}`);
            } else {
                navigate(`/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(intl, 'route.begin')}${routeTranslate(intl, 'route.reason')}`);
            }
        }
    };

    const checkIfHasPlanActive = async () => {
        const data: Workspace[] = await workspacesService.getAll();

        if (data.length > 0) {
            const hasPlan = data.find(workspace => workspace.has_active_plan);

            setHasActivePlan(Boolean(hasPlan));

            if (!hasPlan) {
                setModalPlans(true);
            } else {
                setModalPlans(false);
            }

            return;
        }

        navigate(
            `/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(
                intl,
                'route.begin',
            )}${routeTranslate(intl, 'route.reason')}`,
        );
    };

    const updateWorkspace = async (
        id: number,
        name: string,
        company_domain: string,
    ) => {
        try {
            showLoading();

            await workspacesService.update(id, name, company_domain);
            await getWorkspaces();

            successMessage('Workspace atualizado com sucesso!');
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const getWorkspaces = async () => {
        try {
            showLoading();

            const data = await workspacesService.getAll();

            const main = getWorkspaceMain(data);
            setData(main?.id || 0, idsData.project_id);

            const workspacesFormat = data.map((item: Workspace) => {
                const conditionDefault = main?.id === item?.id;

                if (conditionDefault) {
                    setWorkspace(item);
                }

                return { ...item, default: conditionDefault };
            });

            setWorkspaces(workspacesFormat);

            await getProjects(data).catch(() => {
                setProjects(undefined);
                setProject(undefined);
            });
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const getUserSetting = async () => {
        try {
            showLoading();

            const data = await usersService.getOne();

            setSettings(data);

            return data;
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const getUserLanguage = async () => {
        try {
            showLoading();

            const data = await languagesService.getAll();

            setLanguages(data);
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const updateUserSetting = async (id: number) => {
        try {
            showLoading();

            await usersService.update(id);
            await getUserSetting();
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const getSubscriptionDetails = async () => {
        if (workspace && project) {
            const data: any = await workspacesService.getSubscriptionDetails(
                workspace.id,
            );

            setSubscriptionDetails(data.data);
        }
    };

    const getLimits = async () => {
        if (workspace) {
            const result = await brandsService.getLimits(workspace.id);

            if (result instanceof Error) {
                return;
            }

            setLimits(result);
        }
    };

    const getBrands = async (w_id: number, p_id: number) => {
        const result = await brandsService.getAll(w_id, p_id);

        if (result instanceof Error) {
            return;
        } else {
            setBrands(result);

            const localStorageBrandId = localStorage.getItem('activeBrandId');

            if (localStorageBrandId) {
                const active = result.find(
                    (res: Brands) => res.id === Number(localStorageBrandId),
                );
                if (active) {
                    setActiveBrand(active);
                    return;
                }
            }

            const active = result.filter((res: Brands) => res.is_default)[0];

            setActiveBrand(active);
            localStorage.setItem('activeBrandId', String(active.id));
        }
    };

    React.useEffect(() => {
        if (limits) {
            if (
                limits.used_words >= limits.total_words &&
                limits.total_words !== -1 &&
                limits.total_words !== 0
            ) {
                setModalAntecipatePlan(true);
            }
        }
    }, [limits]);

    const getProjects = async (dataWorkspace: Workspace[]) => {
        const idWorkspace = getWorkspaceMain(dataWorkspace)?.id;
        const data = await projectsService.getAll(idWorkspace);
        const main = getProjectMain(data);
        setData(idsData.workspace_id, main.id || 0);
        const projectFormat = data.map((item: Project) => {
            const conditionDefault = main?.id === item?.id;

            if (conditionDefault) {
                setProject(item);
            }

            return {
                ...item,
                default: conditionDefault,
            };
        });

        setProjects(projectFormat);
    };

    const getWorkspaceMain = (workspacesData: Workspace[]) => {
        const workspaceId = localStorage.getItem(
            `${user?.uid}-workspaceSelected`,
        );
        const findProject = workspacesData.find(
            item => item.id.toString() === workspaceId,
        );

        return workspaceId ? findProject : workspacesData[0];
    };

    const changeWorkspace = async (id: number) => {
        localStorage.setItem(`${user?.uid}-workspaceSelected`, id.toString());

        await getWorkspacesNow();
    };

    const getProjectMain = (dataProject: Project[]) => {
        const projectId = localStorage.getItem(`${user?.uid}-projectSelected`);
        const findProject = dataProject.find(
            project => project.id.toString() === projectId,
        );

        return findProject ? findProject : dataProject[0];
    };

    const changeProject = async (id: number) => {
        localStorage.setItem(`${user?.uid}-projectSelected`, id.toString());

        await getWorkspacesNow();
    };

    const getWorkspacesNow = async () => {
        await getWorkspaces();
    };

    const getWorkspaceUsage = async () => {
        if (workspace) {
            const data = await workspacesService.getWorkspaceUsage(
                workspace.id,
            );
            return data;
        }
        return null;
    };

    const handleModalAccess = () => {
        setModalAccess(!modalAccess);
    };
    const handleModalExpired = () => {
        setModalPlanExpired(!modalPlanExpired);
    };
    const closeModalPlan = () => {
        setModalAntecipatePlan(false);
    };

    const closeModalPlans = () => {
        if (hasActivePlan) {
            setModalPlans(false);
        }
    };

    const handleCloseModalPaymentError = () => {
        if (showModalPaymentError) {
            setShowModalPaymentError(false);
        }
    };

    if (!project || !workspace || !settings || (settings && !settings.is_onboarded)) return <Loading open={true} />;

    return (
        <UserConfigContext.Provider
            value={{
                getWorkspacesNow,
                getWorkspaceUsage,
                updateWorkspace,
                changeWorkspace,
                changeProject,
                updateUserSetting,
                setModalAntecipatePlan,
                setModalNewBrand,
                modalNewBrand,
                modalAntecipatePlan,
                workspaces,
                workspace,
                brands,
                activeBrand,
                setActiveBrand,
                getBrands,
                projects,
                project,
                settings,
                languages,
                inviteLink,
                subscriptionDetails,
                handleModalAccess,
                getLimits,
                limits,
            }}
        >
            {children}
            {modalAccess && (
                <ModalAccess
                    handleCloseModal={handleModalAccess}
                    active={modalAccess}
                />
            )}

            {modalPlanExpired && !modalPlans && (
                <ModalPlanExpired
                    handleCloseModal={handleModalExpired}
                    active={modalPlanExpired}
                />
            )}

            <ModalPlans isOpen={modalPlans} handleClose={closeModalPlans} />

            <ModalAntecipatePlan
                active={modalAntecipatePlan}
                handleClose={() => closeModalPlan()}
                workspace_id={workspace.id}
                workspaceUsage={getWorkspaceUsage}
            />

            <ModalPaymentError
                active={showModalPaymentError}
                handleClose={handleCloseModalPaymentError}
            />
            <ModalMaintenance active={false} />
        </UserConfigContext.Provider>
    );
};

export default UserConfigProvider;
