import React from 'react';

export const Chat: React.FC = () => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.66675 19.25V7.33331C3.66675 6.60397 3.95648 5.90449 4.4722 5.38877C4.98793 4.87304 5.6874 4.58331 6.41675 4.58331H15.5834C16.3128 4.58331 17.0122 4.87304 17.528 5.38877C18.0437 5.90449 18.3334 6.60397 18.3334 7.33331V12.8333C18.3334 13.5627 18.0437 14.2621 17.528 14.7779C17.0122 15.2936 16.3128 15.5833 15.5834 15.5833H7.33341L3.66675 19.25Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.3335 8.25H14.6668"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.3335 11.9166H12.8335"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
