import styled from 'styled-components';

export const TableStyle = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    tbody {
        tr {
            cursor: pointer;
        }
    }

    tr {
        border-bottom: 1px solid #edf2f7;
        height: 80px;

        .action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    tr:last-of-type {
        border-bottom: 0;
    }

    th {
        color: #718096;
        font-weight: 700;
        vertical-align: middle;

        .order {
            position: relative;
            top: 3px;
            right: -5px;
        }
    }

    td {
        color: #000000;
        font-weight: 500;
        text-align: left;
    }

    .icon {
        margin: 0 24px 0 0 !important;
    }

    .optionsFromSelected {
        display: flex;
        align-items: center;
        .icon {
            margin: 0 !important;
            background: transparent !important;
            border: none !important;
            cursor: pointer;
        }
    }
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 34px;
    border-top: 1px solid #edf2f7;

    &.dark {
        border-top: 1px solid #2a313c !important;
    }

    button {
        color: #718096;
    }

    .Mui-selected {
        border: 1px solid #1a202c;
        border-radius: 8px;
        min-width: 40px;
        height: 40px;
        color: #000000;
        font-weight: 700;
        background: #fff !important;
    }

    .per-page {
        display: flex;
        align-items: center;
        gap: 16px;
        min-width: 214px;

        span {
            color: #a0aec0;
            font-size: 0.875em;
            font-weight: 700;
            flex: 1;
        }

        > div {
            width: 68px;
            min-height: 37px;

            select {
                font-weight: 700;
                font-size: 0.875em;
                padding: 0 12px;
                width: 68px;
                min-height: 37px;
                height: 37px;
                border-radius: 8px;
            }
        }
    }
`;
