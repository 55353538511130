import { LayoutProps } from 'app/presentation/layout/interfaces/layout-default-props.interface';
import React from 'react';
import UserConfigProvider from './app/presentation/providers/UserConfigProvider';
import NotificationProvider from './app/presentation/providers/NotificationProvider';
import DocumentsProvider from './app/presentation/providers/DocumentProvider';
import TemplateProvider from './app/presentation/providers/TemplateProvider';
import TeamProvider from './app/presentation/providers/TeamProvider';
import ArteAiProvider from './app/presentation/providers/ArteAiProvider';
import ChatProvider from 'app/presentation/providers/ChatProvider';


const DashboardProviders: React.FC<LayoutProps> = ({ children }) => {
    return (
        <UserConfigProvider>
            <NotificationProvider>
                <ArteAiProvider>
                    <TeamProvider>
                        <ChatProvider>
                            <TemplateProvider>
                                <DocumentsProvider>
                                    {children}
                                </DocumentsProvider>
                            </TemplateProvider>
                        </ChatProvider>
                    </TeamProvider>
                </ArteAiProvider>
            </NotificationProvider>
        </UserConfigProvider>
    );
};

export default DashboardProviders;
