import React from 'react';
import { KnowledgeContainer } from './styles';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { FormattedMessage } from 'react-intl';
import { ModalNewMemory, Table } from './components';
import Loading from 'app/presentation/components/common/Loading';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import { IconPlus } from '@tabler/icons-react';

const Knowledge = () => {
    const { activeBrand } = useUserConfig();
    const { loading } = useMemoriesContext();

    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
    const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false);
    const [animateSlide, setAnimateSlide] = React.useState<boolean>(false);

    return (
        <KnowledgeContainer>

            {loading && <Loading open={loading} />}

            <div className="knowledge-header">
                <div>
                    <h1> <FormattedMessage id="brand-center.knowledge.title" /> </h1>
                    <p> <FormattedMessage id="brand-center.knowledge.description" /> </p>
                </div>

                <button className="styled-button"
                    onClick={() => {
                        setShowCreateModal(prev => !prev);
                        setAnimateSlide(prev => !prev);
                    }}
                >
                    <IconPlus stroke={1.5} size={20} />
                    <span> <FormattedMessage id="brand-center.knowledge.new" /> </span>
                </button>
            </div>

            {activeBrand && <Table anchor={anchor} setAnchor={setAnchor} />}

            {showCreateModal && (
                <ModalNewMemory
                    setShowCreateModal={setShowCreateModal}
                    animateSlide={animateSlide}
                    setAnimateSlide={setAnimateSlide}
                />
            )}

        </KnowledgeContainer>
    );
};

export default Knowledge;
