import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { auth } from '../../../../config/firebaseSetup';
import { routeTranslate } from '../../../../utils/intl';
import { useIntl } from 'react-intl';
import { WorkspacesService } from '../../../../services/workspaces';

const workspacesService = new WorkspacesService();

export const AcceptInvite: React.FC = () => {
    const { token } = useParams();
    const [search] = useSearchParams();

    const intl = useIntl();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!token) return;

        auth.onAuthStateChanged(user => {
            const reply = search.get('reply');
            if (!reply) return;

            if (!user) {
                localStorage.setItem('inviteToken', token);
                localStorage.setItem('inviteReply', reply);

                navigate(`/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(intl, 'route.signin')}`);
                return;
            }

            const req = { answer: reply, token: token };

            workspacesService.answerInvitation(req)
                .then(() => {
                    if (!user.emailVerified) {
                        navigate(`/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(intl, 'route.emailVerify')}`);
                        return;
                    }

                }).catch(() => {
                    localStorage.removeItem('inviteReply');
                    localStorage.removeItem('inviteToken');

                    if (!user.emailVerified) {
                        navigate(`/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(intl, 'route.emailVerify')}`);
                        return;
                    }

                    navigate(`/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(intl, 'route.backoffice')}`);
                }).finally(() => {
                    localStorage.removeItem('inviteReply');
                    localStorage.removeItem('inviteToken');
                });
        });
    }, []);

    return <>Carregando...</>;
};
