import styled from 'styled-components';

export const Header = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid #edf2f7;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.125em;
    }

    .title-desktop {
        display: none;
    }

    @media (min-width: 64em) {
        //position: relative;
        //padding-top: 49px;
        //display: flex;
        //justify-content: space-between;
        //
        //h1 {
        //    position: absolute;
        //    top: -30px;
        //}

        .title-desktop {
            display: flex;
            align-items: center;

            h1 {
                font-size: 2em;
            }
        }

        .title-mobile {
            display: none;
        }

        display: block;
        padding: 0;
        border-bottom: 0;
        margin-bottom: 48px;
        margin-top: 16px;
        height: auto;

        > svg {
            display: none;
        }

        p {
            margin-top: 8px;
            color: #718096;
            font-size: 1em;
        }
    }
`;

export const ContainerModel = styled.div`
    margin-bottom: 20px;
    padding: 24px;

    form {
        button {
            width: 109px;
            height: 48px;

            margin-top: -6px;
            font-size: 0.875em;
        }

        > div {
            margin-bottom: 33px;
        }
    }

    #accordion {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 16px;

        .header-accordion {
            cursor: pointer;
            margin: 0;

            &.active {
                margin-bottom: 21px;
            }

            h1 {
                font-size: 0.875em;
            }

            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .body-accordion {
            display: none;
            gap: 21px;

            &.active {
                display: flex;
                flex-direction: column;
            }

            .text {
                h1 {
                    font-size: 0.875em;
                    font-weight: 700;
                    margin-bottom: 4px;
                }

                p {
                    font-size: 0.875em;
                }
            }

            .label {
                background: #d0ff61;
                border-radius: 8px;
                height: 40px;
                padding: 8px 13px;
                display: flex;
                align-items: center;

                max-width: 425px;

                p {
                    font-size: 0.875em;
                    font-weight: 500;
                }
            }
        }
    }

    @media (min-width: 64em) {
        border: 1px solid #edf2f7;
        border-radius: 16px;
        padding: 28px 24px;
    }
`;

export const Responsive = styled.div`
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 59px);
    max-height: calc(100vh - 59px);
    overflow: auto;

    @media (min-width: 64em) {
        max-width: 880px;
        margin: 0 auto;
        position: static;
        height: auto;
        max-height: inherit;
    }
`;
