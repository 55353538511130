import React from 'react';
import { CheckBox } from '../../index';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneIcon from '@mui/icons-material/Done';
import { TbArrowsDownUp, TbCursorText, TbMessage } from 'react-icons/tb';
import { TableStyle, MuiMenuHeader } from './styles';
import { routeTranslate } from '../../../../../utils/intl';
import { useIntl } from 'react-intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { IAudio } from 'pages/dashboard/audio-ai/audioToText';
import { useNavigate } from 'react-router-dom';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { Edit, Trash } from '../../icons';
import { Menu, MenuList, Paper, useMediaQuery } from '@mui/material';
import { AudioService } from 'services/audio';
import Loading from '../../common/Loading';
import { useSnackbar } from 'notistack';
import { BsCheck } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';

const OrderIcon = () => {
    const { darkmode } = useDarkMode();

    return (
        <TbArrowsDownUp
            className="order"
            style={{ color: darkmode ? '#A09FA5' : '#718096' }}
            size={16}
        />
    );
};

const CheckBoxCustom = (props: any) => {
    const { darkmode } = useDarkMode();

    return (
        <CheckBox
            {...props}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={
                <DoneIcon
                    style={{
                        backgroundColor: '#d0ff61',
                        borderRadius: 1000,
                        color: darkmode ? '#1A202C' : '',
                    }}
                />
            }
            sx={{
                color: '#E2E8F0',
                '&.Mui-checked': {
                    color: '#1A202C',
                },
            }}
        />
    );
};

interface Props {
    data: IAudio[] | undefined;
    limit?: number;
    getAll: () => Promise<void>;
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const audioService = new AudioService();

const TableAudios: React.FC<Props> = ({ data, limit, getAll }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { darkmode } = useDarkMode();
    const darkColor = '#A09FA5';
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
    const [audioId, setAudioId] = React.useState<number>();
    const [loading, setLoading] = React.useState(false);
    const [editingId, setEditingId] = React.useState<number>();
    const [inputValue, setInputValue] = React.useState<string>('');
    const open = Boolean(anchorEl);
    const isDesktop = useMediaQuery('(min-width: 64em)');

    const { enqueueSnackbar } = useSnackbar();

    const handleNavigate = (id: number) => {
        const path =
            routeTranslate(intl, 'route.urlLang') +
            routeTranslate(intl, 'route.backoffice');

        navigate(`/${path}${routeTranslate(intl, 'route.audioAi')}/${id}`);
    };

    const handleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
        id: number | undefined,
    ) => {
        if (!event || !id) return;

        setAudioId(id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAudioId(undefined);
        setAnchorEl(null);
    };

    const handleEdit = () => {
        if (audioId && data) {
            setEditingId(audioId);
            const item = data.find(i => i.id === audioId);

            if (item) {
                setInputValue(item.title);
            }
        }
        handleClose();
    };

    const handleCancelEdit = () => {
        setEditingId(undefined);
        setInputValue('');
        handleClose();
        setLoading(false);
    };

    const handleDelete = async () => {
        if (audioId) {
            try {
                setLoading(true);
                const result = await audioService.delete(audioId);

                if (result instanceof Error) {
                    throw new Error();
                }

                await getAll();
                setLoading(false);
                handleClose();
                enqueueSnackbar(
                    routeTranslate(intl, 'audioai.actions.delete.success'),
                    {
                        variant: 'success',
                    },
                );
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(
                    routeTranslate(intl, 'modal.tone.error.generic'),
                    {
                        variant: 'error',
                    },
                );
            }
        }
    };

    const handleSubmitRename = async (id: number) => {
        try {
            if (inputValue.trim() !== '') {
                setLoading(true);

                const payload = {
                    title: inputValue,
                };
                const result = await audioService.rename(id, payload);

                if (result instanceof Error) {
                    throw new Error();
                }

                await getAll();
                handleCancelEdit();
            }
        } catch (error) {
            setLoading(false);

            enqueueSnackbar(routeTranslate(intl, 'modal.tone.error.generic'), {
                variant: 'error',
            });
        }
    };

    return (
        <>
            <TableStyle className={darkmode ? 'darkTable' : ''}>
                <thead>
                    <tr>
                        <th>
                            <CheckBoxCustom />
                        </th>

                        <th style={{ color: darkmode ? darkColor : '' }}>
                            {routeTranslate(intl, 'home.documents.name')}
                            <OrderIcon />
                        </th>

                        {isDesktop && (
                            <>
                                <th
                                    style={{
                                        color: darkmode ? darkColor : '',
                                    }}
                                >
                                    {routeTranslate(
                                        intl,
                                        'home.documents.createBy',
                                    )}
                                    <OrderIcon />
                                </th>
                                <th
                                    style={{
                                        color: darkmode ? darkColor : '',
                                    }}
                                >
                                    {routeTranslate(
                                        intl,
                                        'home.documents.modify',
                                    )}{' '}
                                    <OrderIcon />
                                </th>
                            </>
                        )}

                        <th
                            style={{
                                color: darkmode ? darkColor : '',
                            }}
                        >
                            <div className="action">
                                {routeTranslate(intl, 'home.documents.actions')}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.map((document, index) => {
                            if (!document) return;

                            if (limit && limit < index + 1) return;

                            const date = new Date(document.updated_at);
                            const month = date.toLocaleString('default', {
                                month: 'short',
                            });
                            const year = date.toLocaleString('default', {
                                year: 'numeric',
                            });
                            const day = date.toLocaleString('default', {
                                day: 'numeric',
                            });

                            const dateString = `${capitalizeFirstLetter(
                                month.replace(/\.$/, ''),
                            )} ${day}, ${year}`;

                            const isEditing = editingId === document.id;

                            return (
                                <tr key={index}>
                                    <td>
                                        <CheckBoxCustom />
                                    </td>
                                    <td
                                        onClick={() =>
                                            isEditing
                                                ? undefined
                                                : handleNavigate(document.id)
                                        }
                                    >
                                        <div className="name-table">
                                            <div className="icon">
                                                <TbMessage
                                                    size={24}
                                                    color={
                                                        darkmode ? '#fff' : ''
                                                    }
                                                />
                                            </div>

                                            {isEditing ? (
                                                <div className="containerRenameInput">
                                                    <input
                                                        type="text"
                                                        value={inputValue}
                                                        onChange={e =>
                                                            setInputValue(
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                    <button
                                                        onClick={() =>
                                                            handleSubmitRename(
                                                                document.id,
                                                            )
                                                        }
                                                        disabled={loading}
                                                    >
                                                        {loading ? (
                                                            <Loading
                                                                open
                                                                absolute={false}
                                                                size={25}
                                                            />
                                                        ) : (
                                                            <BsCheck
                                                                style={{
                                                                    color: '#718096',
                                                                }}
                                                                size={25}
                                                            />
                                                        )}
                                                    </button>
                                                    <button
                                                        onClick={
                                                            handleCancelEdit
                                                        }
                                                        disabled={loading}
                                                    >
                                                        <RxCross2
                                                            style={{
                                                                color: '#718096',
                                                            }}
                                                            size={20}
                                                        />
                                                    </button>
                                                </div>
                                            ) : (
                                                <span className="dark-text-white">
                                                    {document.title.trim() ===
                                                    ''
                                                        ? 'Transcrição sem título'
                                                        : document.title}
                                                </span>
                                            )}
                                        </div>
                                    </td>

                                    {isDesktop && (
                                        <>
                                            <td
                                                onClick={() =>
                                                    handleNavigate(document.id)
                                                }
                                                className="dark-text-white"
                                            >
                                                -
                                            </td>
                                            <td
                                                onClick={() =>
                                                    handleNavigate(document.id)
                                                }
                                                className="dark-text-white"
                                            >
                                                {dateString}
                                            </td>
                                        </>
                                    )}

                                    {!isEditing && (
                                        <td className="dark-text-white">
                                            <div className="action">
                                                <div
                                                    className="dots"
                                                    onClick={e => {
                                                        handleClick(
                                                            e,
                                                            document.id,
                                                        );
                                                    }}
                                                >
                                                    <HiOutlineDotsHorizontal
                                                        size={22}
                                                        style={{
                                                            color: '#718096',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{
                            width: '100%',
                            '& > .MuiPaper-root': {
                                borderRadius: '12px',
                                boxShadow:
                                    '8px 12px 24px rgba(93, 106, 131, 0.1)',
                                background: darkmode ? '#1D1D1F' : '',
                            },
                        }}
                        anchorOrigin={{
                            vertical: 140,
                            horizontal: -50,
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Paper
                            sx={{
                                width: 160,
                                boxShadow: 0,
                                background: darkmode ? '#1D1D1F' : '',
                            }}
                        >
                            <MenuList
                                sx={{
                                    padding: 0,
                                    boxShadow: 0,
                                    background: darkmode ? '#1D1D1F' : '',
                                }}
                                disablePadding
                                dense
                            >
                                <MuiMenuHeader
                                    className={darkmode ? 'muiHeaderDark' : ''}
                                >
                                    <ul>
                                        <li
                                            className="icon"
                                            onMouseEnter={(e: any) =>
                                                (e.target.style.backgroundColor =
                                                    darkmode ? '#151718' : '')
                                            }
                                            onMouseLeave={(e: any) =>
                                                (e.target.style.backgroundColor =
                                                    darkmode ? '#1D1D1F' : '')
                                            }
                                            onClick={() =>
                                                handleNavigate(
                                                    audioId as number,
                                                )
                                            }
                                        >
                                            <Edit />
                                            <span>
                                                {routeTranslate(
                                                    intl,
                                                    'home.documents.edit',
                                                )}
                                            </span>
                                        </li>

                                        <li
                                            className="icon"
                                            onMouseEnter={(e: any) =>
                                                (e.target.style.backgroundColor =
                                                    darkmode ? '#151718' : '')
                                            }
                                            onMouseLeave={(e: any) =>
                                                (e.target.style.backgroundColor =
                                                    darkmode ? '#1D1D1F' : '')
                                            }
                                            onClick={handleEdit}
                                        >
                                            <TbCursorText
                                                color="#718096"
                                                size={20}
                                            />
                                            <span>
                                                {routeTranslate(
                                                    intl,
                                                    'audioai.actions.rename',
                                                )}
                                            </span>
                                        </li>

                                        <li
                                            className="icon"
                                            onClick={handleDelete}
                                            onMouseEnter={(e: any) =>
                                                (e.target.style.backgroundColor =
                                                    darkmode ? '#151718' : '')
                                            }
                                            onMouseLeave={(e: any) =>
                                                (e.target.style.backgroundColor =
                                                    darkmode ? '#1D1D1F' : '')
                                            }
                                        >
                                            {loading ? (
                                                <Loading
                                                    open
                                                    absolute={false}
                                                    size={26}
                                                />
                                            ) : (
                                                <>
                                                    <Trash />
                                                    <span className="bold">
                                                        {routeTranslate(
                                                            intl,
                                                            'home.documents.delete',
                                                        )}
                                                    </span>
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                </MuiMenuHeader>
                            </MenuList>
                        </Paper>
                    </Menu>
                </tbody>
            </TableStyle>
        </>
    );
};

export default TableAudios;
