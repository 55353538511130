import { IconBook, IconMessageBolt } from '@tabler/icons-react';
import { ExpansionPanelContainer } from 'app/presentation/components/agent';
import StatusLabel from 'app/presentation/components/agent/StatusLabel';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';
import { useEffect, useState } from 'react';
import { TbBlockquote, TbPaperclip, TbWorld } from 'react-icons/tb';
import { TrainingService } from 'services/training';
import {
    GetEligibleInformationResponse,
    GetEnabledTrainingsInformationResponse,
} from 'services/training/interfaces';
import { BrandCenterPanel } from './brand-center';
import { ExcerptsPanel } from './excerpts';
import * as S from './styles';
import { UrlContentPanel } from './urls';

const Sources = () => {
    const { textGetter } = useText();
    const { agent, agentTrainingChangeCount } = useAgent();
    const t = textGetter('agent.pages.sources');
    const service = new TrainingService();

    const [eligibleInformation, setEligibleInformation] = useState<
        GetEligibleInformationResponse | undefined
    >();

    const [enabledInformation, setEnabledInformation] = useState<
        GetEnabledTrainingsInformationResponse | undefined
    >();

    const [useMemories, setUseMemories] = useState<boolean | undefined>();
    const [trainingStatus, setTrainingStatus] = useState<string | undefined>();
    const [excerptStatus, setExcerptStatus] = useState<string | undefined>();
    const [urlContentStatus, setUrlContentStatus] = useState<
        string | undefined
    >();

    const notify = () => {
        if (!agent) return;

        setTrainingStatus('in_progress');

        const interval = setInterval(() => {
            service.getTrainingStatus(agent.id).then(response => {
                setTrainingStatus(response.map.memories);
                setExcerptStatus(response.map.excerpts);
                setUrlContentStatus(response.map.urls);

                if (response.map.memories === 'completed')
                    clearInterval(interval);
            });
        }, 500);
    };

    useEffect(() => {
        if (!agent) return;

        service.getUseMemories(agent.id).then(response => {
            setUseMemories(response.use);
        });
    }, []);

    useEffect(() => {
        if (!agent) return;

        service.getEnabledTrainingsInformation(agent.id).then(response => {
            setEnabledInformation(response);
        });
    }, [agentTrainingChangeCount]);

    useEffect(() => {
        if (!agent) return;

        service.getTrainingStatus(agent.id).then(response => {
            setTrainingStatus(response.map.memories);
            setExcerptStatus(response.map.excerpts);
            setUrlContentStatus(response.map.urls);
        });

        const interval = setInterval(() => {
            service.getTrainingStatus(agent.id).then(response => {
                setTrainingStatus(response.map.memories);
                setExcerptStatus(response.map.excerpts);
                setUrlContentStatus(response.map.urls);
            });
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!agent) return;

        service.getEligibleInformation(agent.id).then(response => {
            setEligibleInformation(response);
        });
    }, []);

    const getStatusLabel = (): React.ReactNode => {
        if (!trainingStatus || trainingStatus === 'none') return <></>;

        if (trainingStatus === 'completed') {
            return (
                <StatusLabel
                    status="success"
                    text={t('panel.status-text.success')}
                ></StatusLabel>
            );
        }

        return (
            <StatusLabel
                status="progress"
                text={t('panel.status-text.in-progress')}
            ></StatusLabel>
        );
    };

    const getStatusLabel2 = (): React.ReactNode => {
        if (!urlContentStatus || urlContentStatus === 'none') return <></>;

        if (urlContentStatus === 'completed') {
            return (
                <StatusLabel
                    status="success"
                    text={t('panel.status-text.success')}
                ></StatusLabel>
            );
        }

        return (
            <StatusLabel
                status="progress"
                text={t('panel.status-text.in-progress')}
            ></StatusLabel>
        );
    };

    const getStatusLabel3 = (): React.ReactNode => {
        if (!excerptStatus || excerptStatus === 'none') return <></>;

        if (excerptStatus === 'completed') {
            return (
                <StatusLabel
                    status="success"
                    text={t('panel.status-text.success')}
                ></StatusLabel>
            );
        }

        return (
            <StatusLabel
                status="progress"
                text={t('panel.status-text.in-progress')}
            ></StatusLabel>
        );
    };

    return (
        <S.Sources>
            <div className="sources-container">
                <div className="metadata">
                    <h2 className="title">{t('title')}</h2>
                    <h3 className="description">{t('description')}</h3>
                </div>
                <div className="expansion-panel-container">
                    <ExpansionPanelContainer
                        id="config-expansion-panel-container"
                        panels={[
                            {
                                buttonTitle: (
                                    <div className="status-labeled-panel-title">
                                        {t('panel.brand-center.title')}{' '}
                                        {useMemories && getStatusLabel()}
                                    </div>
                                ),
                                buttonSubtitle: t(
                                    'panel.brand-center.description',
                                ),
                                icon: <IconBook></IconBook>,
                                panelContent: (
                                    <BrandCenterPanel
                                        eligibleInformation={
                                            eligibleInformation
                                        }
                                        useMemories={useMemories}
                                        setUseMemories={setUseMemories}
                                        notify={notify}
                                    ></BrandCenterPanel>
                                ),
                            },
                            {
                                buttonTitle: (
                                    <div className="status-labeled-panel-title">
                                        {t('panel.external-content.title')}{' '}
                                        {getStatusLabel2()}
                                    </div>
                                ),
                                buttonSubtitle: t(
                                    'panel.external-content.description',
                                ),
                                icon: <TbWorld></TbWorld>,
                                panelContent: (
                                    <UrlContentPanel
                                        isReady={
                                            urlContentStatus === 'completed'
                                        }
                                        enabled={
                                            enabledInformation?.external_content
                                                .enabled || 0
                                        }
                                        total={
                                            enabledInformation?.external_content
                                                .total || 0
                                        }
                                    ></UrlContentPanel>
                                ),
                            },
                            {
                                buttonTitle: (
                                    <div className="status-labeled-panel-title">
                                        {t('panel.excerpts.title')}{' '}
                                        {getStatusLabel3()}
                                    </div>
                                ),
                                buttonSubtitle: t('panel.excerpts.description'),
                                icon: <TbBlockquote></TbBlockquote>,
                                panelContent: (
                                    <ExcerptsPanel
                                        enabled={
                                            enabledInformation?.excerpts
                                                .enabled || 0
                                        }
                                        total={
                                            enabledInformation?.excerpts
                                                .total || 0
                                        }
                                    />
                                ),
                            },
                            {
                                buttonTitle: (
                                    <div className="status-labeled-panel-title">
                                        {t('panel.file-content.title')}{' '}
                                        <StatusLabel
                                            status="greyed-out"
                                            text={t('panel.status-text.soon')}
                                        />
                                    </div>
                                ),
                                buttonSubtitle: t(
                                    'panel.file-content.description',
                                ),
                                icon: <TbPaperclip></TbPaperclip>,
                                panelContent: <></>,
                                disabled: true,
                            },
                            {
                                buttonTitle: (
                                    <div className="status-labeled-panel-title">
                                        {t('panel.custom-responses.title')}{' '}
                                        <StatusLabel
                                            status="greyed-out"
                                            text={t('panel.status-text.soon')}
                                        />
                                    </div>
                                ),
                                buttonSubtitle: t(
                                    'panel.custom-responses.description',
                                ),
                                icon: <IconMessageBolt></IconMessageBolt>,
                                panelContent: <></>,
                                disabled: true,
                            },
                        ]}
                    ></ExpansionPanelContainer>
                </div>
            </div>
            {false && <div className="sources-chat-container"></div>}
        </S.Sources>
    );
};

export default Sources;
