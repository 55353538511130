import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { IconCheck } from '@tabler/icons-react';
import { Input } from 'app/presentation/components/agent';
import { Button } from '../Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
}

interface AdditionalMessagesData {
    errorMessage: string;
    noSourcesFound: string;
    maxMessagesPerUser: string;
}

export const ModalAdditionalMessages: React.FC<ModalProps> = ({
    active,
    handleClose,
}) => {
    const { agent, updateAdditionalMessages } = useAgent();
    const { textGetter } = useText();
    const modalText = textGetter('agent.components.modal.additional-messages');
    const commonsText = textGetter('agent.components.modal.commons');
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    React.useEffect(() => {
        if (!agent) return;
        const {
            error_message_lists: errorMessageList,
            no_sources_found_message_lists: noSourcesFoundList,
            max_messages_per_user_warning_lists: maxMessagesPerUserList,
        } = agent;

        if (
            errorMessageList.length > 0 &&
            errorMessageList[0].messages.length > 0
        ) {
            setValue('errorMessage', errorMessageList[0].messages[0].message);
        }

        if (
            noSourcesFoundList.length > 0 &&
            noSourcesFoundList[0].messages.length > 0
        ) {
            setValue(
                'noSourcesFound',
                noSourcesFoundList[0].messages[0].message,
            );
        }

        if (
            maxMessagesPerUserList.length > 0 &&
            maxMessagesPerUserList[0].messages.length > 0
        ) {
            setValue(
                'maxMessagesPerUser',
                maxMessagesPerUserList[0].messages[0].message,
            );
        }
    }, [agent]);

    const { handleSubmit, register, setValue } =
        useForm<AdditionalMessagesData>();

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            handleClose();
        }
    };

    const onSubmit: SubmitHandler<AdditionalMessagesData> = async data => {
        setFormSubmitLoading(true);
        updateAdditionalMessages(
            data.errorMessage,
            data.noSourcesFound,
            data.maxMessagesPerUser,
        )
            .then(() => handleClose())
            .finally(() => setFormSubmitLoading(false));
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal onClick={handleCloseContainer}>
                <form
                    className="content-modal"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="modal-header">
                        <p>{modalText('title')}</p>
                        <IoMdClose size={25} onClick={handleClose} />
                    </div>
                    <div className="input-container">
                        <p>{modalText('max-message-per-user')}</p>
                        <Input
                            id="max-message-per-user"
                            registerValues={register('maxMessagesPerUser')}
                        ></Input>
                    </div>
                    <div className="input-container">
                        <p>{modalText('no-sources-found')}</p>
                        <Input
                            id="no-sources-found"
                            registerValues={register('noSourcesFound')}
                        ></Input>
                    </div>

                    <div className="input-container">
                        <p>{modalText('error-message')}</p>
                        <Input
                            id="error-message"
                            registerValues={register('errorMessage')}
                        ></Input>
                    </div>
                    <div className="button-container">
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={handleCloseContainer}
                        >
                            {commonsText('button.cancel')}
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            loading={formSubmitLoading}
                            loadingSize="20px"
                            className="save-button"
                        >
                            <IconCheck></IconCheck> {commonsText('button.save')}
                        </Button>
                    </div>
                </form>
            </ContainerModal>
        </Fade>
    );
};
