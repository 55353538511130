import React from 'react';
import { InlineMenu } from './../';
import { StyledEditorContent, FixedMenu, FixedButton } from './styles';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { common, createLowlight } from 'lowlight';
import StarterKit from '@tiptap/starter-kit';
import { useEditor } from '@tiptap/react';
import Image from '@tiptap/extension-image';
import Youtube from '@tiptap/extension-youtube';
import 'highlight.js/styles/atom-one-dark.css';

import {
    IconPhoto,
    IconBrandYoutube,
    //IconTable,
    IconQuote,
    IconSourceCode,
    IconList,
    IconListNumbers,
    //IconListDetails,
    IconFold,
} from '@tabler/icons-react';

type TextEditorProps = {
    content: string;
    setContent?: React.Dispatch<React.SetStateAction<string>>;
    editable: boolean;

    initialContent: string;
    placeholder: string;
};

export const TextEditor: React.FC<TextEditorProps> = props => {
    const extensions = [
        StarterKit,
        Placeholder.configure({ placeholder: props.placeholder }),
        CodeBlockLowlight.configure({
            lowlight: createLowlight(common),
        }),
        Link,
        Underline,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
        Typography,
        Image, // TODO image features
        Youtube,
    ];

    const inputFileRef = React.useRef<HTMLInputElement>(null);

    const editor = useEditor({
        extensions,
        editable: props.editable,
        onUpdate({ editor }) {
            props.setContent!(editor.getHTML());
        },
        content: props.content,
    });

    React.useEffect(() => {
        editor?.commands.setContent(props.initialContent);
    }, [props.initialContent]);


    const addYoutubeVideo = () => {
        const url = prompt('Enter YouTube URL');

        if (url) {
            editor?.commands.setYoutubeVideo({
                src: url,
                width: 600,
                height: 350,
            });
        }
    };

    async function fileToBase64(
        file: File,
    ): Promise<string | ArrayBuffer | null> {
        return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                if (typeof base64String === 'string') {
                    resolve(base64String);
                } else {
                    reject(new Error('Failed to convert file to base64'));
                }
            };
            reader.onerror = () => {
                reject(new Error('Error reading file'));
            };
            reader.readAsDataURL(file);
        });
    }

    const handleInputImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const image = e.target.files?.[0];

        if (image) {
            try {
                const base64Image = await fileToBase64(image);

                console.log(base64Image);

                editor
                    ?.chain()
                    .focus()
                    .setImage({ src: base64Image as string })
                    .run();
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <>
            {editor && props.editable && <InlineMenu editor={editor} />}

            <StyledEditorContent editor={editor} content={props.content} />

            {props.editable && (
                <FixedMenu>
                    <FixedButton onClick={() => inputFileRef.current?.click()}>
                        <IconPhoto stroke={1.5} />
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={inputFileRef}
                            onChange={e => handleInputImage(e)}
                        ></input>
                    </FixedButton>

                    <FixedButton onClick={() => addYoutubeVideo()}>
                        <IconBrandYoutube stroke={1.5} />
                    </FixedButton>

                    {/*<FixedButton>
                        <IconTable stroke={1.5} />
                    </FixedButton>*/}

                    <FixedButton onClick={() => editor?.chain().focus().setHorizontalRule().run()}>
                        <IconFold stroke={1.5} />
                    </FixedButton>

                    <FixedButton onClick={() => editor?.chain().focus().toggleBlockquote().run()}>
                        <IconQuote stroke={1.5} />
                    </FixedButton>

                    <FixedButton onClick={() => editor?.chain().focus().toggleCodeBlock().run()}>
                        <IconSourceCode stroke={1.5} />
                    </FixedButton>

                    <FixedButton onClick={() => editor?.chain().focus().toggleBulletList().run()}>
                        <IconList stroke={1.5} />
                    </FixedButton>

                    <FixedButton onClick={() => editor?.chain().focus().toggleOrderedList().run()}>
                        <IconListNumbers stroke={1.5} />
                    </FixedButton>

                    {/*<FixedButton>
                        <IconListDetails stroke={1.5} />
                    </FixedButton>*/}
                </FixedMenu>
            )}
        </>
    );
};
