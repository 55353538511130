import styled from 'styled-components';
import { BubbleMenu } from '@tiptap/react';

export const BubbleButton = styled.button`
    display: flex;
    align-items: center;

    background-color: inherit;
    color: black;
    border: 0;
    //padding: 0.3rem;

    border-radius: 5.82px;

    svg {
        width: 18px;
        height: 18px;
        margin-top: 7px;
        margin-bottom: 7px;
        margin-left: 7px;
        margin-right: 7px;
    }

    &[data-active='true'] {
        color: blue;
    }

    :hover {
        background-color: rgba(55, 53, 47, 0.08);
    }
`;

export const StyledBubbleMenu = styled(BubbleMenu)`
    display: flex;
    gap: 7px;
    border-radius: 0.4rem;
    height: 40px;
    width: fit-content;

    box-shadow: 0px 2px 8px 0px #00000040;

    background-color: white;

    button:first-child {
        margin-left: 4px;
    }

    * {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    button:last-child {
        margin-right: 4px;
    }

    hr {
        width: 1px;
        border: 0;
        background-color: #e2e8f0;
    }

    .popper {
        background-color: white;
        color: black;
    }
`;
