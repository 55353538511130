import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .models {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: auto;
        max-height: 234px;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            background: #edf2f7;
            border-radius: 50px;
        }

        .box {
            display: flex;
            justify-content: space-between;
            ul {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                align-items: center;
                gap: 5px;
                list-style: none;

                li {
                    background: #d0ff61;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    cursor: pointer;

                    img {
                        flex-shrink: 0;
                        min-width: 100%;
                        min-height: 100%;
                    }
                }
            }

            .next {
                width: 55px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .content-modal {
        background: #ffffff;
        border-radius: 16px;
        width: 98%;
        max-width: 420px;
        height: 100%;
        max-height: 536px;
        padding: 40px 24px 33px 24px;
        overflow: auto;

        header {
            text-align: right;

            svg {
                cursor: pointer;
            }
        }

        #title {
            margin-bottom: 30px;

            h1 {
                text-align: center;
                font-size: 1.5em;
                color: #1a202c;
                font-weight: 700;
                margin-top: 24px;
                margin-bottom: 12px;
            }

            p {
                text-align: center;
                font-weight: 500;
                font-size: 0.875em;
                color: #718096;
            }
        }

        form {
            > div {
                margin-bottom: 25px;
            }

            > div:last-of-type {
                margin-top: 5px;
            }

            .input {
                position: relative;
                display: flex;
                align-items: center;

                .icon {
                    position: absolute;
                    left: 18px;
                }

                input {
                    padding-left: 58px;
                }
            }
        }

        input {
            border-radius: 12px;
            border: 1px solid #e2e8f0;
        }
    }
    &.modalArteDark {
        .content-modal {
            background: #000;

            #title {
                h1 {
                    color: #fff;
                }
                p {
                    color: #a09fa5;
                }
            }

            form {
                input {
                    background: #1d1d1f !important;
                    border-color: #1d1d1f;
                    color: #ffffff !important;

                    &:focus {
                        border: 1px solid #fff !important;
                    }
                }
            }
        }
    }
`;
