import React from 'react';

export const Image: React.FC = () => (
    <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.4167 3.25H4.58333C3.57081 3.25 2.75 4.07081 2.75 5.08333V17.9167C2.75 18.9292 3.57081 19.75 4.58333 19.75H17.4167C18.4292 19.75 19.25 18.9292 19.25 17.9167V5.08333C19.25 4.07081 18.4292 3.25 17.4167 3.25Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.79166 9.66669C8.55106 9.66669 9.16666 9.05108 9.16666 8.29169C9.16666 7.5323 8.55106 6.91669 7.79166 6.91669C7.03227 6.91669 6.41666 7.5323 6.41666 8.29169C6.41666 9.05108 7.03227 9.66669 7.79166 9.66669Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.25 14.25L14.6667 9.66669L4.58334 19.75"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
