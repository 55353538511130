import { Route, Routes } from 'react-router-dom';
import Details from '../pages/dashboard/definitions/details';
import { routeTranslate } from '../utils/intl';
import { useIntl } from 'react-intl';
import General from '../pages/dashboard/definitions/general';
import PlansAndSignatures from '../pages/dashboard/definitions/plans-and-signatures';
import Integrations from 'pages/dashboard/definitions/integrations';
import Team from '../pages/dashboard/definitions/team';
import Data from '../pages/dashboard/definitions/data';

export const definitionsRoutes = () => {
    const intl = useIntl();

    return (
        <Routes>
            <Route
                path={routeTranslate(intl, 'route.details')}
                element={<Details />}
            />
            <Route
                path={routeTranslate(intl, 'route.general')}
                element={<General />}
            />
            <Route
                path={routeTranslate(intl, 'route.plans')}
                element={<PlansAndSignatures />}
            />
            <Route
                path={routeTranslate(intl, 'route.team')}
                element={<Team />}
            />
            <Route
                path={routeTranslate(intl, 'route.data')}
                element={<Data />}
            />
            <Route
                path={routeTranslate(intl, 'route.integrations')}
                element={<Integrations />}
            />
        </Routes>
    );
};
