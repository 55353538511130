import React from 'react';
import { TbArrowsDownUp } from 'react-icons/tb';
import { FieldValues, useForm } from 'react-hook-form';
import { Team, TypeUser } from '../../interfaces/team-props.interface';
import { Select } from '../../common';
import { CheckBox } from '../../mui';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneIcon from '@mui/icons-material/Done';
import { Trash } from '../../icons';
import Pagination from '@mui/material/Pagination';
import { PaginationContainer } from '../TableDocuments/styles';
import { TableStyle } from './styles';
import useTeam from '../../../hooks/useTeam';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

const OrderIcon = () => {
    return <TbArrowsDownUp style={{ color: '#718096' }} size={16} />;
};

interface Props {
    data: Team[];
    isOwner: boolean;
    handleUpdateWordLimit: (userId: number, limit: number) => void;
}

const CheckBoxCustom = (props: any) => {
    return (
        <CheckBox
            {...props}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={
                <DoneIcon
                    style={{
                        backgroundColor: '#d0ff61',
                        borderRadius: 4,
                    }}
                />
            }
            sx={{
                color: '#E2E8F0',
                '&.Mui-checked': {
                    color: '#1A202C',
                },
            }}
        />
    );
};

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFunction(typeUser: TypeUser) {
    switch (typeUser) {
        case TypeUser.VIEWER:
            return 'Viewer';
        case TypeUser.EDITOR:
            return 'Editor';
        case TypeUser.ADMIN:
            return 'Administrador';
        case TypeUser.OWNER:
            return 'Criador';
    }
}

export const TableTeam: React.FC<Props> = ({ data, isOwner }) => {
    const { register, watch } = useForm<FieldValues>();
    const { pageInfo, getTeam, removeMember } = useTeam();
    const intl = useIntl();
    const { darkmode } = useDarkMode();

    const watchPerPage = watch('perPage') ?? '';

    const totalPages = pageInfo?.TotalPages ?? 0;
    const currentPage = pageInfo?.CurrentPage ?? 0;
    const perPage = pageInfo?.PerPage ?? 0;

    React.useEffect(() => {
        const fetchData = async () => {
            await handlePage(1, watchPerPage);
        };
        if (watchPerPage) {
            fetchData().catch(console.error);
        }
    }, [watchPerPage]);

    const handlePage = async (pageNumber: number, limitPerPage?: number) => {
        await getTeam(pageNumber, limitPerPage);
    };

    const handleChange = async (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        await handlePage(value, pageInfo?.PerPage ?? 0);
    };

    return (
        <>
            <TableStyle className={darkmode ? 'tableTeamDark' : ''}>
                <tr>
                    <th>
                        <CheckBoxCustom />
                    </th>
                    <th>
                        {routeTranslate(intl, 'team.member')} <OrderIcon />
                    </th>
                    <th>
                        {routeTranslate(intl, 'team.role')} <OrderIcon />
                    </th>
                    <th>
                        {routeTranslate(intl, 'team.joined')} <OrderIcon />
                    </th>
                    <th>
                        <div className="action">
                            {routeTranslate(intl, 'team.action')}
                        </div>
                    </th>
                </tr>
                {data.map(member => {
                    const date = new Date(member.created_at);
                    const month = date.toLocaleString('default', {
                        month: 'short',
                    });
                    const year = date.toLocaleString('default', {
                        year: 'numeric',
                    });
                    const day = date.toLocaleString('default', {
                        day: 'numeric',
                    });

                    const dateString = `${capitalizeFirstLetter(
                        month.replace(/\.$/, ''),
                    )} ${day}, ${year}`;

                    return (
                        <tr key={member.id}>
                            <td>
                                <CheckBoxCustom />
                            </td>
                            <td>
                                <div>
                                    <div>
                                        <p className="name-user">
                                            {member.user.first_name}{' '}
                                            {member.user.last_name}
                                        </p>
                                    </div>
                                    <div className="email-user">
                                        <p>{member.user.email}</p>
                                    </div>
                                </div>
                            </td>
                            <td>{getFunction(member.type)}</td>
                            <td>
                                <span>{dateString}</span>
                            </td>
                            <td>
                                <div className="action">
                                    {member.type !== TypeUser.OWNER &&
                                        isOwner && (
                                            <div
                                                onClick={() =>
                                                    removeMember(member.user_id)
                                                }
                                                className="dots"
                                            >
                                                <Trash />
                                            </div>
                                        )}
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </TableStyle>
            <PaginationContainer
                style={{
                    borderTopColor: darkmode ? '#3a3a3a' : '',
                }}
            >
                <div className="per-page">
                    <span>{routeTranslate(intl, 'team.showResults')}</span>
                    <Select
                        name="perPage"
                        id="perPage"
                        value={perPage}
                        register={register}
                        options={[
                            { name: '6', value: '6' },
                            { name: '1', value: '1' },
                        ]}
                    />
                </div>
                <div className="pages">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handleChange}
                    />
                </div>
            </PaginationContainer>
        </>
    );
};
