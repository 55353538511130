import { client } from '../client';
import {
    CreateExcerptRequest,
    Excerpt,
    UpdateExcerptRequest,
} from './interfaces';

export class ExcerptService {
    private readonly excerptClient = client
        .addPath('/excerpts')
        .addBrandHeaders();

    async createExcerpt(
        agentId: number,
        title: string,
        content: string,
    ): Promise<Excerpt> {
        return this.excerptClient.postData<Excerpt, CreateExcerptRequest>({
            agent_id: agentId,
            title,
            content,
        });
    }

    async delete(id: number): Promise<void> {
        return this.excerptClient.deleteData<void>({
            pathParams: [id],
        });
    }

    async update(id: number, title: string, content: string): Promise<void> {
        return this.excerptClient.patchData<void, UpdateExcerptRequest>(
            {
                title: title,
                content: content,
            },
            {
                pathParams: [id],
            },
        );
    }
}
