import React from "react";
import { StyledSelect } from "./styles";
import useMemoriesContext from "app/presentation/hooks/useMemoriesContext";

type SelectOption = {
    value: number | string;
    text: string;
}

type SelectProps = React.HTMLAttributes<HTMLSelectElement> & {
    options: SelectOption[];
}
export const Select: React.FC<SelectProps> = props => {
    // TODO transform this <select> into a div and use as a form this functionality, mainly 
    // to create the dropdown as a popover and control better it's state

    const { ...rest } = props;
    const { currPaginationLimit } = useMemoriesContext();

    return (
        <StyledSelect {...rest}>
            {props.options.map(opt => (
                <option value={opt.value} key={opt.value} selected={opt.value == currPaginationLimit}>
                    {opt.text}
                </option>
            ))}
        </StyledSelect>
    )
}
