import styled from 'styled-components';
import { ButtonProps } from '../../interfaces/button-props.interface';

export const ButtonStyled = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
        props.color === 'primary' ? '#d0ff61' : '#fff'};
    color: ${props => (props.color !== 'primary' ? '#1A202C' : '#000')};
    cursor: pointer;
    font-size: 1.2em;
    font-weight: bold;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
    border: 0;

    &.disabled-event {
        pointer-events: none;
    }

    #iconEnd {
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #iconStart {
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:disabled {
        background-color: #ccc !important;
        cursor: not-allowed !important;
    }
`;
