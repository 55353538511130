import axios from 'axios';
import { API_BASE_URL } from 'config/contants';
import { getToken } from 'services/api';
import { AddSupportedBrandLanguageReq, Brand, DeleteSupportedBrandLanguageReq, PutBrandConfigReq } from './types';
import { axiosConfig } from 'services/commons';

function generateRandomHexColor(): string {
    while (true) {
        // Generate random RGB values
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);

        // Convert RGB to hex
        const hexColor = `#${red.toString(16).padStart(2, '0')}${green
            .toString(16)
            .padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;

        // Exclude black and white colors
        if (hexColor !== '#000000' && hexColor !== '#ffffff') {
            return hexColor;
        }
    }
}

export class BrandServices {
    private readonly path = '/brands';
    private readonly path2 = '/onboarding';

    async getAll(workspace_id: number, project_id: number) {
        const token = await getToken();
        const { data } = await api.get(this.path.concat('?page=1&limit=50'), {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        const dataColor = data.data.map((d: any) => {
            const obj = { ...d, color: generateRandomHexColor() };
            return obj;
        });

        return dataColor;
    }

    async create(workspace_id: number, project_id: number, formData: FormData) {
        const token = await getToken();
        const { data } = await api.post(this.path, formData, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        return data;
    }

    async edit(
        workspace_id: number,
        project_id: number,
        formData: FormData,
        brand_id: number,
    ) {
        const token = await getToken();
        const { data } = await api.put(`${this.path}/${brand_id}`, formData, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        return data;
    }

    async editImg(
        workspace_id: number,
        project_id: number,
        formData: FormData,
        brand_id: number,
    ) {
        const token = await getToken();
        const { data } = await api.post(
            `${this.path}/${brand_id}/logo`,
            formData,
            {
                headers: {
                    Authorization: token,
                    'X-Workspace-Id': workspace_id,
                    'X-Project-Id': project_id,
                },
            },
        );

        return data;
    }

    async delete(workspace_id: number, project_id: number, brand_id: number) {
        const token = await getToken();
        const { data } = await api.delete(`${this.path}/${brand_id}`, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        return data;
    }

    async getOccupationList(workspace_id: number, project_id: number) {
        const token = await getToken();
        const { data } = await api.get('brand-occupations', {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
                'X-Project-Id': project_id,
            },
        });

        return data;
    }

    async getLimits(workspace_id: number) {
        const token = await getToken();
        const { data } = await api.get(`workspaces/${workspace_id}/limits`, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
            },
        });

        return data;
    }

    async createOnboarding(workspace_id: number, form: any) {
        const token = await getToken();
        const { data } = await api.post(this.path2, form, {
            headers: {
                Authorization: token,
                'X-Workspace-Id': workspace_id,
            },
        });

        return data;
    }
}

const brandsPath = (): string => {
    return "/brands";
}

const api = axios.create({
    baseURL: API_BASE_URL,
});

export const putBrandConfig = async (req: PutBrandConfigReq): Promise<Brand> => {
    const path = `${brandsPath()}/config/${req.headers.brand_id}`;
    const headers = await axiosConfig(req.headers);
    const formData = new FormData();

    for (const [key, value] of Object.entries(req.data)) {
        formData.append(key, value.toString());
    }

    return (await api.put<Brand>(path, formData, headers)).data;
}

export const deleteSupportedBrandLanguage = async (req: DeleteSupportedBrandLanguageReq): Promise<Brand> => {
    const path = `${brandsPath()}/${req.headers.brand_id}/languages/${req.languageId}`;
    const headers = await axiosConfig(req.headers);

    return (await api.delete<Brand>(path, headers)).data;
}

export const addSupportedBrandLanguage = async (req: AddSupportedBrandLanguageReq): Promise<Brand> => {
    const path = `${brandsPath()}/${req.headers.brand_id}/languages/${req.languageId}`;
    const headers = await axiosConfig(req.headers);

    return (await api.put<Brand>(path, undefined, headers)).data;
}
