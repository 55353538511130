import axios from 'axios';
import { Collection } from './types';
import { API_BASE_URL } from 'config/contants';
import { type Pagination, axiosConfig, applyQuerystringParams } from "services/commons";
import {
    type GetAllCollectionsReq,
    type UpdateCollectionReq,
    type GetCollectionReq,
    type CreateCollectionReq,
    type DeleteCollectionReq,
    type PutCollectionIconReq
} from "./types";


const collectionsPath = (): string => {
    return "/brand-memories/groups";
}

const api = axios.create({
    baseURL: API_BASE_URL,
});

export const getAllCollections = async (req: GetAllCollectionsReq): Promise<Pagination<Collection>> => {
    const headers = await axiosConfig(req.headers);
    const path = applyQuerystringParams(collectionsPath(), req.paginationParams,
        req.collection_id ? { "brand_memory_group_id": req.collection_id } : {},
        req.all ? { "a": req.all } : {},
    );

    return (await api.get<Pagination<Collection>>(path, headers)).data
}

export const putCollection = async (req: UpdateCollectionReq): Promise<Collection> => {
    const headers = await axiosConfig(req.headers);
    const path = collectionsPath().concat(`/${req.collection_id}`)

    return (await api.put<Collection>(path, req.data, headers)).data
}

export const getCollection = async (req: GetCollectionReq): Promise<Collection> => {
    const headers = await axiosConfig(req.headers);
    const path = collectionsPath().concat(`/${req.collection_id}?l=${req.language_id}`)

    return (await api.get<Collection>(path, headers)).data
}

export const postCollection = async (req: CreateCollectionReq): Promise<Collection> => {
    const headers = await axiosConfig(req.headers);
    const path = collectionsPath();

    return (await api.post<Collection>(path, req.data, headers)).data
}

export const deleteCollection = async (req: DeleteCollectionReq): Promise<void> => {
    const headers = await axiosConfig(req.headers);
    const path = collectionsPath().concat(`/${req.collection_id}`);

    return (await api.delete<void>(path, headers)).data
}

export const putCollectionIcon = async (req: PutCollectionIconReq): Promise<Collection> => {
    const headers = await axiosConfig(req.headers);
    const path = collectionsPath().concat(`/${req.collection_id}/icon`);


    return (await api.put<Collection>(path, req.data, headers)).data
}
