import styled from 'styled-components';

export const ContainerContent = styled.div`
    width: 100%;
    height: 100%;
    /* border: 2px solid green; */

    .voiceToneHeader {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        /* background: pink; */

        h3 {
            color: #000;
            font-weight: 700;
            font-size: 1.25rem;
            margin-bottom: 10px;
        }

        p {
            font-weight: 500;
            font-size: 0.875rem;
            color: #7e7e7e;
        }
    }

    .table {
        padding: 0 30px;
        /* border: 2px solid blue; */
    }

    .empty-voice-tone {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;

        h3 {
            margin-top: 18px;
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 26px;
        }
        p {
            font-size: 0.875rem;
            font-weight: 500;
            color: #7e7e7e;
        }

        button {
            height: 48px;
            margin-top: 30px;
            font-size: 0.875rem;
        }
    }
`;

export const TableStyle = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    tbody {
        tr {
            cursor: pointer;
        }
    }
    thead {
        border-bottom: 1px solid #edf2f7;
    }
    .nameField {
        width: 70%;

        @media (max-width: 64em) {
            max-width: 50%;
        }
        p {
            display: flex;
            align-items: center;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 12px;
                background: #d0ff61;
                border-radius: 10px;
                font-size: 0.75rem;
                font-weight: 600;
                color: #000000;
                max-width: 60px;
                padding: 2px;
                margin-top: 0;
            }
        }
        span {
            display: inline-block;
            font-size: 0.875rem;
            color: #a09fa5;
            font-weight: 500;
            white-space: nowrap;
            width: 100%;
            max-width: 50vw;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 4px;

            @media (max-width: 64em) {
                max-width: 35vw;
            }
        }
    }

    tr {
        border-bottom: 1px solid #edf2f7;
        height: 80px;

        .action {
            text-align: end;
        }
        .actionTd {
            position: relative;
            li {
                position: absolute;
                right: 0;
                bottom: 30px;
                border: 1px solid #e2e8f0;
                border-radius: 8px;
                width: 32px !important;
                height: 32px !important;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    tr:last-of-type {
        border-bottom: 0;
    }

    th {
        color: #718096;
        font-weight: 700;
        vertical-align: middle;
    }

    td {
        color: #000000;
        font-weight: 500;
        text-align: left;
    }

    &.tableDark {
        thead {
            border-color: #3a3a3a;
        }

        tr {
            border-color: #3a3a3a;
        }
    }
`;

export const MuiMenuHeader = styled.div`
    .list-project {
        li {
            padding: 0 11px 0 0 !important;
        }
    }

    .list-submenu-options {
        li {
            position: relative;
            padding: 0 11px 0 0 !important;
            color: #7e7e7e;
            font-weight: 600;
            svg {
                margin-right: 16px;
            }
            .loading-spinner {
                width: 100%;
                justify-content: center;
                display: flex;
                svg {
                    margin-right: 0;
                }
            }
        }
    }

    .scroll-project {
        max-height: 150px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0.5em;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: #edf2f7;
            border-radius: 50px;
        }

        li {
            border: 0;
            padding: 0 !important;

            span {
                color: #718096 !important;
                font-weight: 500 !important;
                .labelPersonal {
                    color: #fff !important;
                    font-weight: 500 !important;
                    font-size: 12px !important;
                    line-height: 150% !important;
                    background: #1a202c;
                    border-radius: 10px;
                    padding: 2px 7px;
                    margin-left: 8px;
                }
            }
        }

        border-bottom: 1px solid #edf2f7;
    }

    .menu-mui-header-workspace {
        margin-top: 9px;
        padding-bottom: 17px;
        border-bottom: 2px solid #edf2f7;
        margin-bottom: 8px;

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        a {
            font-weight: 500;
            font-size: 0.75em;
            color: #194bfb;
        }
    }

    .menu-mui-header {
        margin-top: 9px;
        padding: 0 9px 13px 9px;

        #search > div {
            margin: 0;
        }

        input {
            min-height: 42px;
            border: 1px solid #a0aec0;
            border-radius: 8px;
        }

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        p {
            font-weight: 500;
            font-size: 0.75em;
            color: #718096;
        }
    }

    ul.workspace {
        li {
            border-bottom: 0;
            padding: 0;
            cursor: pointer;

            span {
                font-weight: 600;
            }
        }
    }

    ul {
        list-style: none;

        li {
            cursor: pointer;
            height: 42px !important;
            display: flex;
            align-items: center;
            padding: 0 16px !important;
            border-bottom: 1px solid #edf2f7;
            margin: 0;

            &:hover {
                background-color: #f7fafc;
            }

            &.icon {
                justify-content: space-between;
            }
        }

        li:last-of-type {
            border-bottom: 0;
        }

        span {
            color: #1a202c !important;
            font-weight: 400;
            font-size: 0.75em !important;

            &.bold {
                font-weight: 600 !important;
            }
        }
    }

    .ws-dark {
        li {
            &:hover {
                background-color: #404f67;
                border-radius: 8px;
            }
        }

        span {
            color: #fff !important;
        }
    }
`;
