import React from 'react';
import { MenuContextData } from '../contexts/interfaces/menu-context-props.interface';
import MenuContext from '../contexts/MenuContext';

function useMenu(): MenuContextData {
    const context = React.useContext(MenuContext);

    if (!context) {
        throw new Error('useMenu deve ser usado com o MenuProvider');
    }

    return context;
}

export default useMenu;
