import React from 'react';
import AgentContext from '../contexts/AgentContext';
import { AgentContextData } from '../contexts/interfaces/agent-context-props.interface';

function useAgent(): AgentContextData {
    const context = React.useContext(AgentContext);

    if (!context) {
        throw new Error('useAgent deve ser usado com o AgentProvider');
    }

    return context;
}

export default useAgent;
