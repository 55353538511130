import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { FieldValues, useForm } from 'react-hook-form';
import { Input } from 'app/presentation/components';
import useUserConfig from '../../../hooks/useUserConfigs';
import { Search } from '../../icons';
import { MdArrowForwardIos } from 'react-icons/md';
import { Project } from '../../../hooks/interfaces/project';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
}

const ModalListProject: React.FC<ModalProps> = ({ active, handleClose }) => {
    const [projectSearch, setProjectSearch] = React.useState<Project[]>();
    const intl = useIntl();

    const { register, watch } = useForm<FieldValues>();
    const { projects, changeProject } = useUserConfig();

    const watchSearch = watch('search');
    const { darkmode } = useDarkMode();

    React.useEffect(() => {
        if (projects) {
            const search = new RegExp(watchSearch, 'i'); // prepare a regex object
            const result = projects.filter(item => search.test(item.name));

            setProjectSearch(result);
        } else {
            setProjectSearch(undefined);
        }
    }, [watchSearch, projects]);

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };

    const handleChangeProject = (id: number) => {
        changeProject(id);
        handleClose();
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'listProjectsDark' : ''}
            >
                <div className="content-modal">
                    <header>
                        <IoMdClose size={25} onClick={handleClose} />
                    </header>
                    <div id="title">
                        <h1>
                            {routeTranslate(intl, 'listProjects.modal.title')}
                        </h1>
                        <p>
                            {routeTranslate(
                                intl,
                                'listProjects.modal.description',
                            )}
                        </p>
                    </div>
                    <form>
                        <div className="input">
                            <div className="icon">
                                <Search darkmode={darkmode} />
                            </div>
                            <Input
                                id="search"
                                placeholder={routeTranslate(
                                    intl,
                                    'label.search',
                                )}
                                register={register}
                            />
                        </div>
                    </form>
                    <ul>
                        {projectSearch && projectSearch.length > 0 ? (
                            projectSearch?.map((project, index) => (
                                <li
                                    key={index}
                                    onClick={() =>
                                        handleChangeProject(project.id)
                                    }
                                >
                                    <span>{project.name}</span>
                                    <MdArrowForwardIos />
                                </li>
                            ))
                        ) : (
                            <li>Sem resultados...</li>
                        )}
                    </ul>
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalListProject;
