import React from 'react';
import { HeaderModalDetails } from 'app/presentation/components';
import { DataContent, DocumentsContainer } from './styles';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    Tooltip as TTP,
    TooltipYAlignment,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
    TableData,
    UserUsage,
} from '../../../../app/presentation/components/dashboard/TableData';
import Tooltip from '@mui/material/Tooltip';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { format, compareAsc } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

ChartJS.defaults.scale.grid.display = false;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, TTP);

type Statistics = {
    date: string;
    credits: number;
};

type BillingCycle = {
    start: string;
    end: string;
};

export type BillingInfo = {
    billing_cycle: BillingCycle;
    total_credits: number;
    active_users: number;
    statistics: Statistics[];
    user_usage: UserUsage[];
};

const Team: React.FC = () => {
    const { getWorkspaceUsage, workspaces, workspace, settings } =
        useUserConfig();
    const [usageData, setUsageData] = React.useState<BillingInfo>({
        billing_cycle: {
            start: '',
            end: '',
        },
        total_credits: 0,
        active_users: 0,
        statistics: [],
        user_usage: [],
    });
    const intl = useIntl();
    const { darkmode } = useDarkMode();
    ChartJS.defaults.color = darkmode ? '#fff' : '#000';

    const callApi = async () => {
        const { data } = await getWorkspaceUsage();

        if (data) {
            setUsageData(data);
        }
    };

    React.useEffect(() => {
        callApi();
    }, [workspaces, workspace]);

    const startDate = usageData.billing_cycle.start;
    const endDate = usageData.billing_cycle.end;
    const locale = settings.language_id === 2 ? enUS : ptBR;
    const formatStyle = locale === ptBR ? "dd 'de' MMMM" : 'dd MMMM';

    let billingCycleLabel = '';

    if (startDate !== '' && endDate !== '') {
        billingCycleLabel = `${format(new Date(startDate), formatStyle, {
            locale: locale,
        })} - ${format(new Date(endDate), formatStyle, {
            locale: locale,
        })}`;
    }

    const options = {
        showTooltips: true,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 15,
                bottom: 0,
            },
        },
        plugins: {
            legend: {
                display: false,
                responsive: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                enabled: true,
                yAlign: 'bottom' as TooltipYAlignment,
                backgroundColor: '#000',
                callbacks: {
                    title: function () {
                        return '';
                    },
                    label: function (context: any) {
                        const label =
                            context.formattedValue +
                            ' ' +
                            routeTranslate(intl, 'document.words');
                        return label;
                    },
                },
            },
        },
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value: any) {
                        const formattedValue = (value / 1000).toLocaleString(
                            undefined,
                            { maximumFractionDigits: 1 },
                        );
                        return value >= 1000 ? formattedValue + 'K' : value;
                    },
                    maxTicksLimit: 4,
                    padding: 5,
                    fontColor: 'green',
                },
            },
        },
        tooltip: {
            enabled: true,
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    const titleCase = (string: string) => {
        return string[0].toUpperCase() + string.slice(1).toLowerCase();
    };

    const todayISO = new Date(Date.now()).toISOString();
    const today = todayISO.split('T')[0].concat('T03:00:00Z');
    const filter = (value: string) => {
        const date = value.split('T')[0].concat('T03:00:00Z');
        return compareAsc(new Date(date), new Date(today)) < 1;
    };

    const labelsArr = usageData.statistics
        .filter(stats => filter(stats.date))
        .map(stats => {
            const date = stats.date.split('T')[0].concat('T03:00:00Z');
            const day = `${format(new Date(date), 'MMM d', {
                locale: locale,
            })}`;

            return titleCase(day);
        });

    const labels = labelsArr;

    const dataArr =
        usageData?.statistics
            .filter(stats => filter(stats.date))
            .map(stats => {
                return stats.credits;
            }) || [];

    const data = {
        labels,
        datasets: [
            {
                barThickness: 40,
                categoryPercentage: 1,
                data: dataArr,
                backgroundColor: darkmode ? '#131314' : '#F2F6FF',
                width: '40px',
                hoverBackgroundColor: '#d0ff61',
                borderRadius: 6,
                label: 'Usage',
            },
        ],
    };

    return (
        <DataContent className={darkmode ? 'dataContentDark' : ''}>
            <div className="header-data">
                <HeaderModalDetails
                    title={routeTranslate(intl, 'definitions.useData')}
                />
                <div className="desc">
                    <p>{routeTranslate(intl, 'data.billingCycle')}</p>
                    <span>{billingCycleLabel}</span>
                </div>
            </div>
            <div className="general">
                <div className="box">
                    <div>
                        <span>{routeTranslate(intl, 'data.creditsUsed')}</span>
                        <Tooltip
                            title={routeTranslate(intl, 'tooltip.usedCredits')}
                            placement="top"
                            sx={{
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: darkmode
                                            ? '#fff'
                                            : '#000',
                                        color: darkmode ? '#000 ' : '#fff',
                                    },
                                },
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            }}
                        >
                            <IconButton>
                                <AiOutlineExclamationCircle
                                    size={20}
                                    style={{ color: '#A0AEC0' }}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <p>{usageData.total_credits}</p>
                </div>
                <div className="box">
                    <div>
                        <span>{routeTranslate(intl, 'data.activeUsers')}</span>
                        <Tooltip
                            title={routeTranslate(intl, 'tooltip.activeUsers')}
                            placement="top"
                            sx={{
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: darkmode
                                            ? '#fff'
                                            : '#000',
                                        color: darkmode ? '#000 ' : '#fff',
                                    },
                                },
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            }}
                        >
                            <IconButton>
                                <AiOutlineExclamationCircle
                                    size={20}
                                    style={{ color: '#A0AEC0' }}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <p>{usageData.active_users}</p>
                </div>
            </div>
            <div className="chart">
                <h1>{routeTranslate(intl, 'data.stats')}</h1>
                <div id="chart">
                    <Bar options={options} data={data} />
                </div>
            </div>
            <DocumentsContainer>
                <article id="table">
                    <TableData data={usageData?.user_usage || []} />
                </article>
            </DocumentsContainer>
        </DataContent>
    );
};

export default Team;
