import React from 'react';
import { Button, Container, Input } from 'app/presentation/components';
import { Header, ContainerModel, Responsive } from './styles';
import { FieldValues, useForm } from 'react-hook-form';
import {
    MdClose,
    MdKeyboardArrowRight,
    MdOutlineKeyboardArrowDown,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { routeTranslate } from '../../../../utils/intl';

const NewModel: React.FC = () => {
    const [openAccordion, setOpenAccordion] = React.useState(true);
    const { register } = useForm<FieldValues>();

    const navigate = useNavigate();
    const intl = useIntl();

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice');

    const handleClose = () => {
        return navigate(`/${path}${routeTranslate(intl, 'route.models')}`);
    };

    const handleAccordion = () => {
        setOpenAccordion(!openAccordion);
    };

    return (
        <Container>
            <Responsive>
                <Header>
                    <h1 className="title-mobile">Novo modelo</h1>
                    <div className="title-desktop">
                        <h1>Modelo</h1>
                        <MdKeyboardArrowRight
                            style={{ color: '#CBD5E0' }}
                            size={26}
                        />
                        <h1>Novo modelo</h1>
                    </div>
                    <p className="title-desktop">
                        Crie um modelo para utilizar sempre que for necessário
                    </p>
                    <MdClose onClick={handleClose} size={26} />
                </Header>
                <div>
                    <ContainerModel>
                        <form>
                            <Input
                                label="Título"
                                id="title"
                                placeholder="Como criar..."
                                register={register}
                            />
                            <div id="accordion">
                                <div
                                    className={
                                        openAccordion
                                            ? 'header-accordion active'
                                            : 'header-accordion'
                                    }
                                    onClick={handleAccordion}
                                >
                                    <h1>Aprenda a formatação correta</h1>
                                    <MdOutlineKeyboardArrowDown
                                        size={21}
                                        style={{ color: '#6E6B7B' }}
                                    />
                                </div>
                                <div
                                    className={
                                        openAccordion
                                            ? 'body-accordion active'
                                            : 'body-accordion'
                                    }
                                >
                                    <div className="text">
                                        <h1>Comandos</h1>
                                        <p>
                                            Use o caractere {'“>”'} antes do
                                            texto para solicitar a ajuda do
                                            Jarbas. Isso ajudará a separar
                                            visualmente os
                                            <br />
                                            comandos de outros textos. Dessa
                                            forma:
                                        </p>
                                    </div>
                                    <div className="label">
                                        <p>
                                            {'>'}escreva uma lista de motivos
                                            para comprar um playstation
                                        </p>
                                    </div>
                                    <div className="text">
                                        <h1>Variáveis</h1>
                                        <p>
                                            Atribua quais partes do seu modelo
                                            são variáveis e precisam da entrada
                                            do usuário, utilizado colchetes
                                            simples
                                            <br />
                                            como este:
                                        </p>
                                    </div>
                                    <div className="label">
                                        <p>
                                            {'>'}escreva um artigo breve sobre{' '}
                                            {'{ BLOG_POST }'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <Input
                                label="Descrição (Opcional)"
                                id="title"
                                placeholder=""
                                register={register}
                            />
                            <Input
                                label="Comandos para o Jarbas"
                                id="title"
                                placeholder=""
                                register={register}
                            />
                            <Input
                                label="Link do vídeo (opcional)"
                                id="title"
                                placeholder=""
                                register={register}
                            />
                            <Button>Salvar</Button>
                        </form>
                    </ContainerModel>
                </div>
            </Responsive>
        </Container>
    );
};

export default NewModel;
