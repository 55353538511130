import React from 'react';
import * as S from './styles';
import { Chart as ChartJS } from 'chart.js';
import { externalTooltip } from 'utils/chart';

export interface BarChartValue {
    x: string;
    y: number;
}

interface ChartProps {
    width: string;
    height: string;
    label: string;
    data: BarChartValue[];
    color?: string;
    hoverColor?: string;
}

export const BarChart: React.FC<ChartProps> = ({color = '#BFCDFF', hoverColor = '#194BFB', data, label, ...rest}) => {
    const chartRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(()=>{
        const container = chartRef.current;
        if (!container) {
            return;
        }
        
        ChartJS.defaults.font.size = 12;
        ChartJS.defaults.font.lineHeight = '18px';
        ChartJS.defaults.font.weight = '500';
        ChartJS.defaults.font.family = 'Manrope';
        
        const chart = new ChartJS(
            container,
            {
                type: 'bar',
                data: {
                    labels: data.map(row => row.x),
                    datasets: [
                        {
                            borderSkipped: false,
                            borderRadius: 6,
                            barThickness: 38,
                            backgroundColor: color,
                            hoverBackgroundColor: hoverColor,
                            label: label,
                            data: data.map(row => row.y)
                        }
                    ]
                },
                options: {
                    plugins: {
                        legend: {
                            display:false
                        },
                        tooltip: {
                            enabled: false,
                            external: externalTooltip()
                        }
                    },
                    scales: {
                        y: {
                            border: {
                                display:false,
                            },
                            ticks: {
                                color: '#7E7E7E',
                                crossAlign: 'far',
                                callback: (context) => {
                                    switch (typeof context) {
                                        case 'number':
                                            if (context >= 1000000000) return `${context/1000000000}B`
                                            else if (context >= 1000000) return `${context/1000000}M`
                                            else if (context >= 1000) return `${context/1000}k`
                                            else return context.toString()
                                            break;
                                        default:
                                            return context
                                    }
                                }
                            }
                        },
                        x: {
                            border: {
                                display:false,
                            },
                            ticks: {
                                color: '#7E7E7E'
                            }
                        },
                    },
                }
            });

        return () => {
            chart.destroy();
        }
    },[data, rest.width, rest.height]);

    return <S.Chart ref={chartRef} {...rest}/>;
};

export default BarChart;
