import { putBrandConfig, deleteSupportedBrandLanguage, addSupportedBrandLanguage } from "services/brand";
import { AddSupportedBrandLanguageReq, type DeleteSupportedBrandLanguageReq, type PutBrandConfigReq } from "services/brand/types";
import BrandsContext from "../contexts/BrandsContext";
import useUserConfig from "../hooks/useUserConfigs";

type ProviderProps = {
    children: React.ReactNode;
}

// considering that all brand stuff is inside useUserConfig anomaly, this one will be for now just a wrapper 
// for related brand methods
const BrandsProvider: React.FC<ProviderProps> = ({ children }) => {
    const { activeBrand: brand, setActiveBrand: setBrand, workspace, project } = useUserConfig();

    const updateBrandConfig = async (backgroundColor: string, actionColor: string, defaultLanguageId?: number): Promise<void> => {
        if (!brand) return;

        const req: PutBrandConfigReq = {
            data: {
                background_color: backgroundColor,
                action_color: actionColor
            },

            headers: {
                brand_id: brand.id,
                workspace_id: workspace.id,
                project_id: project.id
            }
        }

        if (defaultLanguageId) req.data.default_language_id = defaultLanguageId;

        try {
            const brand = await putBrandConfig(req);
            setBrand(brand);
        } catch (err) {
            throw err
        }
    }

    const deleteBrandLanguage = async (languageId: number): Promise<void> => {
        if (!brand) return;

        const req: DeleteSupportedBrandLanguageReq = {
            languageId: languageId,

            headers: {
                brand_id: brand.id,
                workspace_id: workspace.id,
                project_id: project.id
            }
        }

        try {
            const brand = await deleteSupportedBrandLanguage(req);
            brand.supported_languages = brand.supported_languages.filter(language => language.language_id != req.languageId);

            setBrand(brand);
        } catch (err) {
            throw err
        }

    }

    const addBrandLanguage = async (languageId: number): Promise<void> => {
        if (!brand) return;

        const req: AddSupportedBrandLanguageReq = {
            languageId: languageId,

            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: brand.id
            }
        }

        try {
            const brand = await addSupportedBrandLanguage(req);
            setBrand(brand);
        } catch (err) {
            throw err;
        }
    }

    return <BrandsContext.Provider
        value={{
            updateBrandConfig: updateBrandConfig,
            deleteBrandLanguage: deleteBrandLanguage,
            addBrandLanguage: addBrandLanguage,
        }}
    >
        {children}
    </BrandsContext.Provider>

}

export default BrandsProvider;
