import styled from 'styled-components';

export const DataContent = styled.div`
    width: 100%;

    .general {
        display: flex;
        flex-direction: column;
        gap: 17px;
        margin-bottom: 29px;

        .box {
            background: #ffffff;
            border: 1px solid #edf2f7;
            border-radius: 16px;
            height: 133px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 32px;

            > div {
                display: flex;
                align-items: center;
            }

            span {
                color: #718096;
                font-weight: 500;
            }

            p {
                font-size: 2em;
                font-weight: 800;
                color: #1a202c;
            }
        }
    }

    .chart {
        margin: 0 auto;
        background: #ffffff;
        border: 1px solid #edf2f7;
        border-radius: 16px;
        padding: 29px 24px;

        h1 {
            margin-bottom: 43px;
        }

        #chart {
            height: 214px;
        }
    }

    .header-data {
        .desc {
            display: none;
        }
    }

    @media (min-width: 64em) {
        .general {
            margin-top: 42px;
            display: flex;
            flex-direction: row;

            .box {
                flex: 1;
            }
        }

        .header-data {
            display: flex;
            justify-content: space-between;
            align-items: center;

            header {
                margin: 0 !important;
            }

            .desc {
                display: block;
                text-align: right;

                p {
                    font-weight: 500;
                    font-size: 14px;
                    color: #718096;
                    margin-bottom: 4px;
                }

                span {
                    font-weight: 600;
                    font-size: 14px;
                }
            }
        }
    }

    &.dataContentDark {
        .general {
            .box {
                background: #000;
                border-color: #3a3a3a;

                span {
                    color: #a09fa5;
                }
                p {
                    color: #fff;
                }
            }
        }

        .chart {
            background: #1d1d1f;
            border-color: #1d1d1f;
            color: red !important;
        }
    }
`;

export const DocumentsContainer = styled.div`
    width: 100%;
    margin: 28px auto 25px auto;

    .box:first-of-type {
        margin: 0;
        padding-top: 0;
    }

    .box {
        margin-top: 28px;
        padding: 24px 0 36px 0;
        border-bottom: 2px solid #edf2f7;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .flex {
            flex: 1;

            h1 {
                font-size: 0.875em;
                font-weight: 700;
            }
        }
    }

    .box:last-of-type {
        border-top: 0;
        border-bottom: 0;
        margin: 0;
    }

    .dots {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon {
        width: 40px;
        height: 40px;
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        border-radius: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name-table {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 26px;
        }

        span {
            font-weight: 600;
            font-size: 1em;
        }
    }

    .infos {
        .info div {
            width: 130px;
            height: 56px;

            input {
                border-radius: 12px;
            }
        }
    }

    .infos .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;

        strong {
            font-size: 0.875em;
            color: #718096;
            font-weight: 600;
        }

        span {
            color: #232b38;
            font-size: 0.875em;
            font-weight: 600;
        }
    }

    @media (min-width: 64em) {
        border-radius: 16px;

        .box {
            display: none;
        }

        #table {
            margin-top: 25px;
            margin-bottom: 18px;
            display: block;
            width: 100%;
            height: 300px;
        }

        header {
            h1 {
                font-size: 1.125em;
            }

            button {
                width: 107.29px;
                height: 34px;
            }
        }
    }
`;
