import React from 'react';

type ISidebarDocument = {
    color?: string;
};

export const SidebarDocument: React.FC<ISidebarDocument> = ({
    color = '#1A202C',
}) => (
    <svg
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.1875 0.3125H1.8125C1.51413 0.3125 1.22798 0.431026 1.017 0.642005C0.806026 0.852983 0.6875 1.13913 0.6875 1.4375V11.5625C0.6875 11.8609 0.806026 12.147 1.017 12.358C1.22798 12.569 1.51413 12.6875 1.8125 12.6875H14.1875C14.4859 12.6875 14.772 12.569 14.983 12.358C15.194 12.147 15.3125 11.8609 15.3125 11.5625V1.4375C15.3125 1.13913 15.194 0.852983 14.983 0.642005C14.772 0.431026 14.4859 0.3125 14.1875 0.3125ZM3.5 8.1875H2.375C2.22582 8.1875 2.08274 8.12824 1.97725 8.02275C1.87176 7.91726 1.8125 7.77418 1.8125 7.625C1.8125 7.47582 1.87176 7.33274 1.97725 7.22725C2.08274 7.12176 2.22582 7.0625 2.375 7.0625H3.5C3.64918 7.0625 3.79226 7.12176 3.89775 7.22725C4.00324 7.33274 4.0625 7.47582 4.0625 7.625C4.0625 7.77418 4.00324 7.91726 3.89775 8.02275C3.79226 8.12824 3.64918 8.1875 3.5 8.1875ZM3.5 5.9375H2.375C2.22582 5.9375 2.08274 5.87824 1.97725 5.77275C1.87176 5.66726 1.8125 5.52418 1.8125 5.375C1.8125 5.22582 1.87176 5.08274 1.97725 4.97725C2.08274 4.87176 2.22582 4.8125 2.375 4.8125H3.5C3.64918 4.8125 3.79226 4.87176 3.89775 4.97725C4.00324 5.08274 4.0625 5.22582 4.0625 5.375C4.0625 5.52418 4.00324 5.66726 3.89775 5.77275C3.79226 5.87824 3.64918 5.9375 3.5 5.9375ZM3.5 3.6875H2.375C2.22582 3.6875 2.08274 3.62824 1.97725 3.52275C1.87176 3.41726 1.8125 3.27418 1.8125 3.125C1.8125 2.97582 1.87176 2.83274 1.97725 2.72725C2.08274 2.62176 2.22582 2.5625 2.375 2.5625H3.5C3.64918 2.5625 3.79226 2.62176 3.89775 2.72725C4.00324 2.83274 4.0625 2.97582 4.0625 3.125C4.0625 3.27418 4.00324 3.41726 3.89775 3.52275C3.79226 3.62824 3.64918 3.6875 3.5 3.6875ZM14.1875 11.5625H5.1875V1.4375H14.1875V11.5625Z"
            fill={color}
        />
    </svg>
);
