import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .content-modal {
        background: #ffffff;
        border-radius: 16px;
        width: 98%;
        max-width: 544px;
        padding: 40px 33px 47px 33px;

        header {
            text-align: right;

            svg {
                cursor: pointer;
            }
        }

        #title {
            margin-bottom: 30px;

            h1 {
                text-align: center;
                font-size: 1.25em;
                color: #1a202c;
                font-weight: 700;
                margin-bottom: 12px;
            }

            p {
                text-align: center;
                font-weight: 500;
                font-size: 0.875em;
                color: #718096;
            }
        }

        .icon {
            display: none;
        }

        .box:last-of-type {
            margin-bottom: 0;

            .icon {
                background: #fcff61;
            }
        }

        .box.active {
            border: 1px solid #1a202c;
        }

        .box {
            cursor: pointer;
            padding: 16px 20px;
            background: #ffffff;
            border: 1px solid #edf2f7;
            border-radius: 16px;
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 24px;

            .icon {
                min-width: 55px;
                min-height: 55px;
                background: rgba(97, 236, 255, 0.7);
                border-radius: 12px;
            }

            h1 {
                font-size: 1em;
                margin-bottom: 4px;
            }

            p {
                font-size: 0.75em;
                color: #718096;
            }

            .desc {
                flex: 1;
            }
        }

        @media (min-width: 64em) {
            .box.active {
                border: 1px solid #edf2f7;
            }

            .box {
                h1 {
                    font-size: 0.9375em;
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            #title {
                h1 {
                    font-size: 1.5em;
                }
            }
        }
    }
`;
