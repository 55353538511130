import React, { ChangeEvent } from 'react';
import {
    Header,
    ContainerModel,
    Responsive,
    Form,
    ModalMore,
    MuiMenuHeader,
} from './styles';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Send } from '../../../../app/presentation/components/icons/send';
import { AuthContext } from '../../../../app/presentation/contexts/AuthContext';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import { BsCheck, BsMic } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import { TbPlayerStop } from 'react-icons/tb';
import {
    CircularProgress,
    Menu,
    Paper,
    Tooltip,
    useMediaQuery,
    MenuList,
} from '@mui/material';
import {
    Copy,
    EyeLayout,
    Like,
    Search,
    UnLike,
    Document as DocumentIcon,
    EyeBlock,
    Websearch,
    Webgeo,
} from '../../../../app/presentation/components/icons';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { FiImage, FiYoutube } from 'react-icons/fi';
import { RiPencilRuler2Line } from 'react-icons/ri';
import { AiOutlineLink } from 'react-icons/ai';
import LogoChat from 'assets/images/svg/logo-chat.svg';
import LogoChatWhite from 'assets/images/png/logo-chat-branco.png';
import { routeTranslate } from '../../../../utils/intl';
import {
    getNameLetters,
    replaceTitlesWithContent,
} from '../../../../utils/others';
import LogoSvg from 'assets/images/svg/logo.svg';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import useChat from 'app/presentation/hooks/useChat';
import { SaveChat } from 'app/presentation/providers/interfaces/chat-props.interface';
import { useSnackbar } from 'notistack';
import useDocuments from 'app/presentation/hooks/useDocuments';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    MdAdd,
    MdOutlineKeyboardArrowDown,
    MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import { GrRotateRight } from 'react-icons/gr';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import ModalPersonalities from 'app/presentation/components/dashboard/ModalPersonalities';
import ModalExternalLink from 'app/presentation/components/dashboard/ModalExternalLink';
import { InfoGenerate } from 'interfaces/chat.interface';
import { TbClick } from 'react-icons/tb';
import DropdownMemories from 'app/presentation/components/dashboard/DropdownMemories';
import { ChangeChatTitleModal } from 'app/presentation/components/dashboard/ModalChangeChatTitle';
import {
    MemorieItem,
    MemorieItemObj,
} from 'app/presentation/hooks/interfaces/brand';
import HighlightWithinTextarea from 'react-highlight-within-textarea';

const Typing = () => (
    <div className="typing">
        <div className="typing__dot"></div>
        <div className="typing__dot"></div>
        <div className="typing__dot"></div>
    </div>
);

const NewChat: React.FC = () => {
    const [moreSelect, setMoreSelect] = React.useState<
        undefined | 'image' | 'youtube' | 'link'
    >();
    const [activeWebsearchCarousel, setActiveWebsearchCarousel] =
        React.useState<number | undefined>();
    const [activeWebsearchCarouselLoading, setActiveWebsearchCarouselLoading] =
        React.useState(true);
    const [prompt, setPrompt] = React.useState('');
    const [loadingSendMessage, setLoadingSendMessage] = React.useState(false);
    const [googleData, setGoogleData] = React.useState(false);
    const [link, setLink] = React.useState('');
    const [modalExternalLink, setModalExternalLink] = React.useState(false);
    const [modalSelectMore, setModalSelectMore] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [valueTextArea, setValueTextArea] = React.useState('');
    const [arrMemoriesHighlight, setArrMemoriesHighlight] =
        React.useState<any>();
    const [allMemories, setAllMemories] = React.useState<MemorieItemObj>();

    const [anchorElMobile, setAnchorElMobile] =
        React.useState<null | HTMLDivElement>(null);

    const [anchorElMore, setAnchorElMore] = React.useState<null | HTMLElement>(
        null,
    );
    const open = Boolean(anchorEl);
    const openMore = Boolean(anchorElMore);
    const [modalPersonality, setModalPersonality] = React.useState(false);
    const { handleSubmit, setValue } = useForm<FieldValues>();
    const { darkmode } = useDarkMode();
    const {
        personalities,
        personality,
        chatData,
        saveChat,
        chat,
        getChat,
        getTranscript,
        rateResponse,
        writing,
        hideResponse,
        regenerateChat,
        generateImage,
        statusWebSearch,
        stopChat,
    } = useChat();
    const { project, limits, subscriptionDetails } = useUserConfig();
    const openMenu = Boolean(anchorElMobile);

    const { newDocument, handleSelectDocument } = useDocuments();

    const { enqueueSnackbar } = useSnackbar();

    const chatScroll = React.useRef<any>();

    const navigate = useNavigate();
    const intl = useIntl();

    const user = React.useContext(AuthContext);
    const isDesktop = useMediaQuery('(min-width: 64em)');

    const handleBack = async () => {
        navigate(-1);
    };

    React.useEffect(() => {
        setLoadingSendMessage(false);
    }, [chatData]);

    const handlePersonality = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
        setModalPersonality(!modalPersonality);
    };

    const handleClickMobile = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorElMobile(event.currentTarget);
    };

    const handleMore = (event: React.MouseEvent<HTMLDivElement>) => {
        if (moreSelect) return;

        setAnchorElMore(event.currentTarget);
        setModalSelectMore(!modalSelectMore);
    };

    const disableAudioElement =
        valueTextArea.length > 0 || loadingSendMessage
            ? { display: 'none' }
            : {};

    const addAudioElement = async (blob: Blob) => {
        if (!chat) return;

        const url = URL.createObjectURL(blob);
        const audio = document.createElement('audio');
        audio.src = url;
        audio.controls = true;

        const file = new File([blob], 'audio');
        const { text } = await getTranscript(chat, file);
        setValue('prompt', text);
    };

    const recorderControls = useAudioRecorder();

    const [disableSubmitButton, setDisableSubmitButton] = React.useState(true);

    const handleSubmitForm: SubmitHandler<FieldValues> = async () => {
        if (!chat || !personality) return;

        try {
            let outputString = valueTextArea;

            if (allMemories) {
                // Flatten the object into an array
                const flatArray = Object.values(allMemories).flat();

                // Map over the array and pick only the id and title
                const simplifiedArray = flatArray.map(({ title, content }) => ({
                    title: '@'.concat(title),
                    content,
                }));

                outputString = replaceTitlesWithContent(
                    valueTextArea,
                    simplifiedArray,
                );
            }
            setPrompt(outputString);
            setLoadingSendMessage(true);

            setTimeout(async () => {
                chatScroll.current.scrollTop = chatScroll.current.scrollHeight;

                if (moreSelect === 'image') {
                    const imageFormat: InfoGenerate = {
                        count: 2,
                        user_prompt: outputString,
                        project_id: project.id,
                        additional_prompts: [62],
                        width: 512,
                        height: 512,
                    };

                    await generateImage(imageFormat);
                    await generateImage(imageFormat);
                } else {
                    const chatFormat: SaveChat = {
                        chat_id: chat,
                        prompt: outputString,
                        personality: personality.id,
                        mode: googleData || link ? 'websearch' : 'normal',
                    };

                    if (link) {
                        chatFormat.urls = [link];
                    }

                    await saveChat(chatFormat, () => {
                        chatScroll.current.scrollTop =
                            chatScroll.current.scrollHeight;
                    });
                }

                setValueTextArea('');
                setPrompt('');
            }, 2000);
        } catch (e: any) {}
    };

    const handleRate = async (is_good: boolean, id?: number) => {
        if (!id) return;

        const rate = {
            is_good: is_good,
            chat_message_id: id,
            rewrite_proposal: '',
        };

        await rateResponse(rate);
    };

    const handleHide = async (hide: boolean, id?: number) => {
        if (!id) return;

        const rate = {
            hide: hide,
            message_id: id,
        };

        await hideResponse(rate);
    };

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        enqueueSnackbar(routeTranslate(intl, 'copy.success'), {
            variant: 'success',
        });
    };

    const handleExternalLink = () => {
        setModalExternalLink(true);
    };

    const handleCloseExternalLink = () => {
        setModalExternalLink(false);
    };

    const handleEdit = (id?: number, rtc_id?: string) => {
        if (!rtc_id || !id) return;

        const path =
            routeTranslate(intl, 'route.urlLang') +
            routeTranslate(intl, 'route.backoffice');

        navigate(
            `/${path}${routeTranslate(intl, 'route.documents')}${routeTranslate(
                intl,
                'route.document',
            )}/${rtc_id}/${id}`,
        );
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseMore = () => {
        setAnchorElMore(null);
    };

    const handleEditInDocument = async (message: string) => {
        const request = { title: 'Novo Documento', content: message };
        const documentResponse = await newDocument(request);

        if (documentResponse && documentResponse.id) {
            handleSelectDocument(documentResponse.id);
            handleEdit(documentResponse.id, documentResponse.rtc_id);
        }
    };

    const handleModalMore = (option: 'image' | 'youtube' | 'link') => {
        handleCloseMore();
        setMoreSelect(option);
    };

    const handleCloseMenu = () => {
        setAnchorElMobile(null);
    };

    const onChange = (value: string) => setValueTextArea(value);

    React.useEffect(() => {
        if (allMemories) {
            // Flatten the object into an array
            const flatArray = Object.values(allMemories).flat();

            // Map over the array and pick only the id and title
            const simplifiedArray = flatArray.map(({ title }) => ({
                highlight: '@'.concat(title),
                className: 'highlightedText',
            }));
            setArrMemoriesHighlight(simplifiedArray);
        }
    }, [allMemories]);

    const handleSetTextAreaValue = (item: MemorieItem) => {
        setValueTextArea(valueTextArea.concat(` @${item.title} `));
    };
    React.useEffect(() => {
        if (valueTextArea.length > 0) {
            setDisableSubmitButton(false);
        } else {
            setDisableSubmitButton(true);
        }
    }, [valueTextArea]);

    const handleInputChatMobile = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value.length > 0) {
            e.target.style.height = e.target.scrollHeight + 'px';
        } else {
            e.target.style.height = '20px';
        }
    };

    return (
        <Responsive>
            <Header
                style={{
                    background: darkmode ? '#000' : undefined,
                    borderBottomColor: darkmode ? '#3a3a3a' : undefined,
                }}
            >
                {!isDesktop && (
                    <div
                        id="back"
                        className={`headerIcons ${
                            darkmode ? 'headerNewChatDark' : ''
                        }`}
                    >
                        <IoIosArrowBack size={25} onClick={handleBack} />
                    </div>
                )}

                {!isDesktop && (
                    <div
                        style={{
                            width: '100%',
                            cursor: 'pointer',
                            overflow: 'hidden',
                        }}
                    >
                        <ChangeChatTitleModal />
                    </div>
                )}

                {!isDesktop && (
                    <>
                        <div
                            id="more"
                            className={`headerIcons ${
                                darkmode ? 'headerNewChatDark' : ''
                            }`}
                            onClick={handleClickMobile}
                        >
                            <HiOutlineDotsHorizontal size={25} />
                        </div>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElMobile}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{
                                width: '100%',
                                '& > .MuiPaper-root': {
                                    width: '100%',
                                    ul: {
                                        padding: '0',
                                    },
                                    visibility: 'hidden',
                                },
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Paper
                                sx={{
                                    width: '100%',
                                    boxShadow: 0,
                                }}
                            >
                                <MenuList
                                    sx={{
                                        padding: 0,
                                        boxShadow: 0,
                                        '@media (min-width: 26.56rem)': {
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        },
                                    }}
                                >
                                    <MuiMenuHeader
                                        style={{
                                            background: darkmode
                                                ? '#1d1d1f'
                                                : '#fff',
                                            borderColor: darkmode
                                                ? '#000'
                                                : '#e2e8f0',
                                        }}
                                    >
                                        <ul
                                            className={
                                                darkmode ? 'usingDarkMode' : ''
                                            }
                                        >
                                            <li
                                                style={{ paddingRight: 0 }}
                                                onMouseEnter={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#151718'
                                                            : '')
                                                }
                                                onMouseLeave={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#1D1D1F'
                                                            : '')
                                                }
                                                className="liTitle"
                                            >
                                                <p>Personalize seu chat</p>
                                            </li>

                                            <li
                                                style={{
                                                    paddingRight: 0,
                                                }}
                                                onMouseEnter={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#151718'
                                                            : '')
                                                }
                                                onMouseLeave={(e: any) =>
                                                    (e.target.style.backgroundColor =
                                                        darkmode
                                                            ? '#1D1D1F'
                                                            : '')
                                                }
                                            >
                                                <div className="clean-button">
                                                    <div
                                                        id="toggle"
                                                        onClick={() => {
                                                            if (
                                                                limits.used_websearches >=
                                                                    limits.total_websearches ||
                                                                (subscriptionDetails &&
                                                                    [
                                                                        'Criador',
                                                                        'Creator',
                                                                    ].includes(
                                                                        subscriptionDetails?.plan_name,
                                                                    ))
                                                            ) {
                                                                return;
                                                            }

                                                            setGoogleData(
                                                                !googleData,
                                                            );
                                                        }}
                                                        className={
                                                            googleData || link
                                                                ? 'active'
                                                                : ''
                                                        }
                                                    >
                                                        <div></div>
                                                    </div>
                                                    <span
                                                        className={
                                                            darkmode
                                                                ? 'personalizeDarkChat'
                                                                : ''
                                                        }
                                                    >
                                                        {routeTranslate(
                                                            intl,
                                                            'chat.googleData',
                                                        )}
                                                    </span>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="select">
                                                    <span
                                                        className={
                                                            darkmode
                                                                ? 'personalizeDarkChat'
                                                                : ''
                                                        }
                                                    >
                                                        {routeTranslate(
                                                            intl,
                                                            'chat.personalities',
                                                        )}
                                                        :
                                                    </span>
                                                    <div
                                                        onClick={
                                                            handlePersonality
                                                        }
                                                        className="select-personality"
                                                    >
                                                        <span>
                                                            {personality &&
                                                                personality
                                                                    .translation[0]
                                                                    .display_name}
                                                        </span>
                                                        <IoIosArrowDown
                                                            style={{
                                                                color: '#000',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </MuiMenuHeader>
                                </MenuList>
                            </Paper>
                        </Menu>
                    </>
                )}
            </Header>
            <div>
                <ContainerModel
                    ref={chatScroll}
                    id="scrollableDiv"
                    className={darkmode ? 'containerChat' : ''}
                >
                    <InfiniteScroll
                        inverse={true}
                        dataLength={chatData?.data?.length ?? 0}
                        style={{ height: '100%' }}
                        next={async () => {
                            if (!chat) return;

                            if (
                                chatData?.page &&
                                chatData?.total_pages !== chatData?.page
                            ) {
                                const curScrollPos =
                                    chatScroll.current.scrollTop;
                                const oldScroll =
                                    chatScroll.current.scrollHeight -
                                    chatScroll.current.clientHeight;

                                await getChat(chat, chatData?.page + 1);

                                setTimeout(() => {
                                    const newScroll =
                                        chatScroll.current.scrollHeight -
                                        chatScroll.current.clientHeight;
                                    chatScroll.current.scrollTop =
                                        curScrollPos + (newScroll - oldScroll);
                                });
                            }
                        }}
                        hasMore={
                            chatData?.page &&
                            chatData?.total_pages !== chatData?.page
                                ? true
                                : false
                        }
                        loader={<></>}
                        scrollableTarget="scrollableDiv"
                    >
                        {!chatData?.data && !writing && !loadingSendMessage && (
                            <div className="empty">
                                <div className="logo-chat">
                                    <img
                                        src={
                                            darkmode ? LogoChatWhite : LogoChat
                                        }
                                    />
                                </div>
                                <div className="grid-feature">
                                    <div className="feature">
                                        <div className="icon">
                                            <Search />
                                        </div>
                                        <div>
                                            <h1>
                                                Escreva conteúdo factual e
                                                atualizado
                                            </h1>
                                            <p>
                                                Tópicos recentes, incluindo as
                                                notícias de hoje.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="feature"
                                        style={{
                                            display: !isDesktop
                                                ? 'none'
                                                : undefined,
                                        }}
                                    >
                                        <div className="icon">
                                            <FiImage
                                                size={22}
                                                color={darkmode ? '#000' : ''}
                                            />
                                        </div>
                                        <div>
                                            <h1>
                                                Crie artes únicas para qualquer
                                                nicho
                                            </h1>
                                            <p>
                                                Gere imagens a partir de
                                                descrições
                                            </p>
                                        </div>
                                    </div>
                                    <div className="feature">
                                        <div className="icon">
                                            <BsMic
                                                size={22}
                                                color={darkmode ? '#000' : ''}
                                            />
                                        </div>
                                        <div>
                                            <h1>
                                                Dê comando de voz em vez de
                                                digitar
                                            </h1>
                                            <p>
                                                Facilidade para descrever melhor
                                                seus pedidos
                                            </p>
                                        </div>
                                    </div>
                                    <div className="feature">
                                        <div className="icon">
                                            <RiPencilRuler2Line
                                                size={22}
                                                color={darkmode ? '#000' : ''}
                                            />
                                        </div>
                                        <div>
                                            <h1>
                                                Escolha uma personalidade do
                                                Jarbas
                                            </h1>
                                            <p>
                                                De profissional de marketing a
                                                cozinheiro...
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {chatData &&
                            chatData.data &&
                            chatData.data.map(data => {
                                if (!data.message) return;

                                const find = '\n';
                                const re = new RegExp(find, 'g');

                                data.message = data.message.replace(re, '<br>');

                                const urls = data.reference_urls
                                    ? JSON.parse(data.reference_urls)
                                    : [];

                                if (data.sender === 'user') {
                                    return (
                                        <div className="message user">
                                            <div
                                                className={`text ${
                                                    darkmode ? 'darkmode' : ''
                                                }`}
                                            >
                                                <div
                                                    className={
                                                        urls && urls.length > 0
                                                            ? 'ds-flex'
                                                            : ''
                                                    }
                                                >
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.message,
                                                        }}
                                                    ></p>
                                                </div>
                                            </div>
                                            <div className="image">
                                                {data.sender === 'user' ? (
                                                    getNameLetters(
                                                        user?.displayName ??
                                                            'User Name',
                                                    )
                                                ) : (
                                                    <img
                                                        src={
                                                            darkmode
                                                                ? LogoChatWhite
                                                                : LogoSvg
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                }

                                return (
                                    <>
                                        {data.type === 'websearch' && (
                                            <div
                                                className={`message websearch ${
                                                    darkmode ? 'darkmode' : ''
                                                }`}
                                            >
                                                <div className={`text`}>
                                                    <div
                                                        className="main"
                                                        onClick={() => {
                                                            if (
                                                                activeWebsearchCarousel ===
                                                                data.id
                                                            ) {
                                                                setActiveWebsearchCarousel(
                                                                    undefined,
                                                                );
                                                                return;
                                                            }
                                                            setActiveWebsearchCarousel(
                                                                data.id,
                                                            );
                                                        }}
                                                    >
                                                        <Websearch />
                                                        <span>
                                                            Buscando dados
                                                            recentes
                                                        </span>
                                                        {activeWebsearchCarousel !==
                                                        data.id ? (
                                                            <MdOutlineKeyboardArrowDown
                                                                size={22}
                                                                style={{
                                                                    color: '#718096',
                                                                }}
                                                            />
                                                        ) : (
                                                            <MdOutlineKeyboardArrowUp
                                                                size={22}
                                                                style={{
                                                                    color: '#718096',
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {activeWebsearchCarousel ===
                                                        data.id && (
                                                        <ul>
                                                            <li>
                                                                <Webgeo />
                                                                Entendendo sua
                                                                pergunta...
                                                            </li>
                                                            <li>
                                                                <Search />
                                                                Pesquisa na
                                                                web...
                                                            </li>
                                                            <li>
                                                                <TbClick
                                                                    size={20}
                                                                />
                                                                Considerando
                                                                resultados...
                                                            </li>
                                                            <li>
                                                                <BsCheck
                                                                    size={20}
                                                                />
                                                                Processando e
                                                                resumindo...
                                                            </li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            className={`message ${
                                                data.hidden_from_context
                                                    ? 'hide'
                                                    : ''
                                            }`}
                                        >
                                            <div
                                                className={`text ${
                                                    darkmode ? 'darkmode' : ''
                                                }`}
                                            >
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.message,
                                                    }}
                                                ></p>
                                                {data.created_at !== '' && (
                                                    <div className="options">
                                                        {urls &&
                                                            urls.length > 0 && (
                                                                <ul className="references">
                                                                    <span>
                                                                        Ler
                                                                        Mais:{' '}
                                                                    </span>
                                                                    {urls.map(
                                                                        (
                                                                            url: any,
                                                                            index: any,
                                                                        ) => {
                                                                            if (
                                                                                index >
                                                                                1
                                                                            )
                                                                                return;

                                                                            const domain =
                                                                                new URL(
                                                                                    url,
                                                                                );
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    onClick={() => {
                                                                                        window.open(
                                                                                            url,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {index +
                                                                                        1}
                                                                                    {
                                                                                        '. '
                                                                                    }
                                                                                    {
                                                                                        domain.hostname
                                                                                    }
                                                                                </li>
                                                                            );
                                                                        },
                                                                    )}
                                                                    {urls.length >
                                                                        2 && (
                                                                        <li>
                                                                            +
                                                                            {urls.length -
                                                                                2}{' '}
                                                                            Outros
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            )}
                                                        <div className="icons">
                                                            <div
                                                                onClick={async () =>
                                                                    handleHide(
                                                                        !data.hidden_from_context,
                                                                        data.id,
                                                                    )
                                                                }
                                                            >
                                                                {data.hidden_from_context ? (
                                                                    <EyeBlock color="#A0AEC0" />
                                                                ) : (
                                                                    <EyeLayout color="#A0AEC0" />
                                                                )}
                                                            </div>
                                                            <div
                                                                onClick={async () =>
                                                                    handleRate(
                                                                        true,
                                                                        data.id,
                                                                    )
                                                                }
                                                            >
                                                                <Like
                                                                    darkmode={
                                                                        darkmode
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                onClick={async () =>
                                                                    handleRate(
                                                                        false,
                                                                        data.id,
                                                                    )
                                                                }
                                                            >
                                                                <UnLike
                                                                    darkmode={
                                                                        darkmode
                                                                    }
                                                                />
                                                            </div>
                                                            <Tooltip
                                                                title={routeTranslate(
                                                                    intl,
                                                                    'tooltip.rewrite',
                                                                )}
                                                                placement="top"
                                                                sx={{
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                }}
                                                                PopperProps={{
                                                                    sx: {
                                                                        '& .MuiTooltip-tooltip':
                                                                            {
                                                                                backgroundColor:
                                                                                    darkmode
                                                                                        ? '#fff'
                                                                                        : '#000',
                                                                                color: darkmode
                                                                                    ? '#000 '
                                                                                    : '#fff',
                                                                            },
                                                                    },
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options:
                                                                                {
                                                                                    offset: [
                                                                                        0,
                                                                                        -10,
                                                                                    ],
                                                                                },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <div
                                                                    onClick={() => {
                                                                        if (
                                                                            !chat ||
                                                                            !data.id
                                                                        )
                                                                            return;

                                                                        regenerateChat(
                                                                            {
                                                                                message_id:
                                                                                    data.id,
                                                                            },
                                                                        );
                                                                    }}
                                                                    className="box-invite"
                                                                >
                                                                    <GrRotateRight
                                                                        size={
                                                                            20
                                                                        }
                                                                        style={{
                                                                            color: '#A0AEC0',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                            <div
                                                                onClick={() => {
                                                                    copyText(
                                                                        data.message,
                                                                    );
                                                                }}
                                                                className="box-invite"
                                                            >
                                                                <Copy
                                                                    hideTooltip={
                                                                        false
                                                                    }
                                                                    darkmode={
                                                                        darkmode
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                onClick={() =>
                                                                    handleEditInDocument(
                                                                        data.message,
                                                                    )
                                                                }
                                                            >
                                                                <DocumentIcon
                                                                    color="#A0AEC0"
                                                                    hideTooltip={
                                                                        false
                                                                    }
                                                                    darkmode={
                                                                        darkmode
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="image">
                                                <img
                                                    src={
                                                        darkmode
                                                            ? LogoChatWhite
                                                            : LogoSvg
                                                    }
                                                />{' '}
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        {loadingSendMessage && (
                            <div className="message user">
                                <div
                                    className={`text ${
                                        darkmode ? 'darkmode' : ''
                                    }`}
                                >
                                    {prompt}
                                </div>
                                <div className="image">
                                    {getNameLetters(
                                        user?.displayName ?? 'User Name',
                                    )}
                                </div>
                            </div>
                        )}
                        {loadingSendMessage && !link && !googleData && (
                            <>
                                <div className="message">
                                    <div className="text loading-dot">
                                        <Typing />
                                    </div>
                                    <div className="image">
                                        <img
                                            src={
                                                darkmode
                                                    ? LogoChatWhite
                                                    : LogoSvg
                                            }
                                        />{' '}
                                    </div>
                                </div>
                            </>
                        )}
                        {loadingSendMessage && (link || googleData) && (
                            <div className={`message websearch`}>
                                <div className={`text`}>
                                    <div
                                        onClick={() => {
                                            setActiveWebsearchCarouselLoading(
                                                !activeWebsearchCarouselLoading,
                                            );
                                        }}
                                        className="main"
                                    >
                                        <Websearch />
                                        <span>Buscando dados recentes</span>
                                        {activeWebsearchCarouselLoading ? (
                                            <MdOutlineKeyboardArrowUp
                                                size={22}
                                                style={{ color: '#718096' }}
                                            />
                                        ) : (
                                            <MdOutlineKeyboardArrowDown
                                                size={22}
                                                style={{ color: '#718096' }}
                                            />
                                        )}
                                    </div>
                                    {activeWebsearchCarouselLoading && (
                                        <ul>
                                            {statusWebSearch.includes(
                                                'processing_message',
                                            ) && (
                                                <li
                                                    className={
                                                        statusWebSearch[
                                                            statusWebSearch.length -
                                                                1
                                                        ] ===
                                                        'processing_message'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    {statusWebSearch[
                                                        statusWebSearch.length -
                                                            1
                                                    ] ===
                                                    'processing_message' ? (
                                                        <CircularProgress
                                                            sx={{
                                                                color: '#ccc',
                                                            }}
                                                            size={20}
                                                        />
                                                    ) : (
                                                        <Webgeo />
                                                    )}
                                                    Entendendo sua pergunta...
                                                </li>
                                            )}
                                            {statusWebSearch.includes(
                                                'searching_web',
                                            ) && (
                                                <li
                                                    className={
                                                        statusWebSearch[
                                                            statusWebSearch.length -
                                                                1
                                                        ] === 'searching_web'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    {statusWebSearch[
                                                        statusWebSearch.length -
                                                            1
                                                    ] === 'searching_web' ? (
                                                        <CircularProgress
                                                            sx={{
                                                                color: '#ccc',
                                                            }}
                                                            size={20}
                                                        />
                                                    ) : (
                                                        <Search />
                                                    )}
                                                    Pesquisa na web...
                                                </li>
                                            )}
                                            {statusWebSearch.includes(
                                                'fetching_pages',
                                            ) && (
                                                <li
                                                    className={
                                                        statusWebSearch[
                                                            statusWebSearch.length -
                                                                1
                                                        ] === 'fetching_pages'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    {statusWebSearch[
                                                        statusWebSearch.length -
                                                            1
                                                    ] === 'fetching_pages' ? (
                                                        <CircularProgress
                                                            sx={{
                                                                color: '#ccc',
                                                            }}
                                                            size={20}
                                                        />
                                                    ) : (
                                                        <TbClick size={20} />
                                                    )}
                                                    Considerando resultados...
                                                </li>
                                            )}
                                            {statusWebSearch.includes(
                                                'processing_page_content',
                                            ) && (
                                                <li
                                                    className={
                                                        statusWebSearch[
                                                            statusWebSearch.length -
                                                                1
                                                        ] ===
                                                        'processing_page_content'
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    {statusWebSearch[
                                                        statusWebSearch.length -
                                                            1
                                                    ] ===
                                                    'processing_page_content' ? (
                                                        <CircularProgress
                                                            sx={{
                                                                color: '#ccc',
                                                            }}
                                                            size={20}
                                                        />
                                                    ) : (
                                                        <BsCheck size={20} />
                                                    )}
                                                    Processando e resumindo...
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                                <div className="image">
                                    <img
                                        src={darkmode ? LogoChatWhite : LogoSvg}
                                    />{' '}
                                </div>
                            </div>
                        )}
                    </InfiniteScroll>
                </ContainerModel>
                <Form
                    className={darkmode ? 'dark' : ''}
                    onSubmit={handleSubmit(handleSubmitForm)}
                >
                    <div className="options-desktop">
                        {personalities && personalities.data.length > 0 && (
                            <>
                                <div className="select">
                                    <span>
                                        {routeTranslate(
                                            intl,
                                            'chat.personalities',
                                        )}
                                        :
                                    </span>
                                    <div
                                        onClick={handlePersonality}
                                        className="select-personality"
                                        style={{
                                            background: darkmode
                                                ? '#000'
                                                : undefined,
                                            borderColor: darkmode
                                                ? '#fff'
                                                : undefined,
                                        }}
                                    >
                                        <span>
                                            {personality &&
                                                personality.translation[0]
                                                    .display_name}
                                        </span>
                                        <IoIosArrowDown />
                                    </div>
                                </div>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    transitionDuration={500}
                                    sx={{
                                        width: '100%',
                                        '.MuiPaper-root': {
                                            boxShadow:
                                                '8px 16px 32px 0px #71809614',
                                            borderRadius: '12px',
                                            padding: '6px 12px 6px 12px',
                                            background: darkmode
                                                ? '#1D1D1F'
                                                : '',
                                        },
                                        '@media (max-width: 63.9375rem)': {
                                            background: darkmode
                                                ? '#11151deb'
                                                : 'rgba(191, 191, 191, 0.5)',
                                            '.MuiPaper-root': {
                                                overflow: 'hidden',
                                                height: '50%',
                                                padding: '20px 20px 0 20px',
                                                left: '0 !important',
                                                borderRadius: '20px 20px 0 0',
                                                marginTop: '20px',
                                                animation: `${
                                                    open
                                                        ? 'fadeInUp'
                                                        : 'fadeOutDown'
                                                } 0.5s ease forwards`,

                                                ul: {
                                                    marginBlockStart: '0',
                                                    marginBlockEnd: '0',
                                                },
                                                minWidth: '100%',
                                            },

                                            '.MuiList-root': {
                                                height: '100%',
                                                overflow: 'hidden',
                                            },
                                        },
                                    }}
                                    anchorOrigin={{
                                        vertical: !isDesktop ? innerHeight : 0, //375 with all options
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <ModalPersonalities
                                        active={open}
                                        handleClose={handleClose}
                                    />
                                </Menu>

                                <div className="select others-options">
                                    <DropdownMemories
                                        setContent={handleSetTextAreaValue}
                                        positon="top"
                                        setItemsMemories={setAllMemories}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div
                        className={`input-chat ${
                            recorderControls.isRecording ||
                            recorderControls.isPaused
                                ? 'record'
                                : ''
                        } ${moreSelect ? 'more-input' : ''}`}
                    >
                        <div
                            id="add-option"
                            className={`add-option ${
                                moreSelect ? `more ${moreSelect}` : ''
                            }`}
                            onClick={handleMore}
                        >
                            {moreSelect ? (
                                <>
                                    {moreSelect === 'link'
                                        ? 'Link Externo'
                                        : moreSelect}
                                    <IoMdClose
                                        onClick={() => {
                                            setMoreSelect(undefined);
                                            setLink('');
                                        }}
                                    />
                                </>
                            ) : (
                                <MdAdd style={{ color: '#000' }} />
                            )}
                        </div>
                        {!recorderControls.isRecording &&
                            !recorderControls.isPaused && (
                                <div className="textAreaContainer">
                                    <div
                                        className="textAreaContent"
                                        style={{
                                            paddingLeft:
                                                moreSelect === 'link'
                                                    ? 150
                                                    : undefined,
                                        }}
                                    >
                                        {isDesktop ? (
                                            <HighlightWithinTextarea
                                                value={valueTextArea}
                                                onChange={onChange}
                                                highlight={arrMemoriesHighlight}
                                                placeholder=""
                                            />
                                        ) : (
                                            <textarea
                                                className="inputMobile"
                                                value={valueTextArea}
                                                onChange={e =>
                                                    setValueTextArea(
                                                        e.target.value,
                                                    )
                                                }
                                                onInput={handleInputChatMobile}
                                                maxLength={2000}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        <div
                            id="audio-record"
                            style={disableAudioElement}
                            onClick={e => {
                                const activeElement = e.target as HTMLElement;
                                const checkDataTest =
                                    activeElement.getAttribute('data-testid');

                                checkDataTest !== 'ar_cancel'
                                    ? setDisableSubmitButton(false)
                                    : setDisableSubmitButton(true);
                            }}
                        >
                            <AudioRecorder
                                onRecordingComplete={blob =>
                                    addAudioElement(blob)
                                }
                                recorderControls={recorderControls}
                            />
                        </div>
                        {loadingSendMessage && (
                            <div className="loadingMessage">
                                <CircularProgress
                                    style={{
                                        color: darkmode ? '#fff' : '#000',
                                    }}
                                    size={24}
                                />
                            </div>
                        )}
                    </div>

                    <div id="send">
                        {isDesktop && (
                            <div className="clean-button">
                                <div
                                    id="toggle"
                                    onClick={() => {
                                        if (
                                            limits.used_websearches >=
                                                limits.total_websearches ||
                                            (subscriptionDetails &&
                                                ['Criador', 'Creator'].includes(
                                                    subscriptionDetails?.plan_name,
                                                ))
                                        ) {
                                            return;
                                        }

                                        setGoogleData(!googleData);
                                    }}
                                    className={
                                        googleData || link ? 'active' : ''
                                    }
                                >
                                    <div></div>
                                </div>
                                <span>
                                    {routeTranslate(intl, 'chat.googleData')}
                                </span>
                            </div>
                        )}

                        <span id="count">
                            {valueTextArea ? valueTextArea.length : 0}
                            /2000
                        </span>
                        {writing && !googleData && !link ? (
                            <button
                                type="button"
                                className="stop-button"
                                onClick={() => stopChat()}
                                style={{
                                    background: darkmode ? '#1d1d1f' : '#000',
                                }}
                            >
                                <TbPlayerStop
                                    style={{
                                        background: darkmode
                                            ? '#1d1d1f'
                                            : '#000',
                                        color: '#fff',
                                    }}
                                    size={24}
                                />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="submit-button"
                                disabled={disableSubmitButton}
                            >
                                <Send
                                    strokeColor={
                                        darkmode &&
                                        (valueTextArea.length > 0 ||
                                            recorderControls.isRecording)
                                            ? '#000'
                                            : '#fff'
                                    }
                                />
                            </button>
                        )}
                    </div>
                </Form>
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorElMore}
                open={openMore}
                onClose={handleCloseMore}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    width: '100%',
                    '.MuiPaper-root': {
                        boxShadow: '8px 16px 32px 0px #71809614',
                        borderRadius: '12px',
                        background: darkmode ? '#1D1D1F' : '',
                    },
                }}
                anchorOrigin={{
                    vertical: 0, //375 with all options
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper
                    sx={{
                        boxShadow: 0,
                        padding: '0px 16px',
                    }}
                >
                    <ModalMore>
                        {/* <div
                            className={`item ${
                                moreSelect === 'image' ? 'active' : ''
                            }`}
                            onClick={() => handleModalMore('image')}
                        >
                            <div>
                                <FiImage
                                    size={22}
                                    color={darkmode ? '#000' : '#936DFF'}
                                />
                            </div>
                            <div>
                                <h1>Gerar imagens</h1>
                                <p>
                                    Crie imagens únicas com base em uma
                                    descrição
                                </p>
                            </div>
                        </div> */}
                        <div
                            className={`item ${
                                moreSelect === 'link' ? 'active' : ''
                            }`}
                            onClick={() => {
                                handleExternalLink();
                                handleCloseMore();
                            }}
                        >
                            <div>
                                <AiOutlineLink
                                    size={22}
                                    color={darkmode ? '#000' : '#194BFB'}
                                />
                            </div>
                            <div>
                                <h1>Link externo</h1>
                                <p>Extraia informações de site específico</p>
                            </div>
                        </div>
                        <div
                            className={`item disabled ${
                                moreSelect === 'youtube' ? 'active' : ''
                            }`}
                        >
                            <div>
                                <FiYoutube
                                    size={22}
                                    color={darkmode ? '#000' : '#7E7E7E'}
                                />
                            </div>
                            <div>
                                <h1>Vídeo do Youtube</h1>
                                <p>Resuma e explore um vídeo do Youtube</p>
                            </div>
                        </div>
                    </ModalMore>
                </Paper>
            </Menu>
            <ModalExternalLink
                active={modalExternalLink}
                handleConfirm={(linkExternal: string) => {
                    handleCloseExternalLink();

                    if (
                        limits.used_websearches >= limits.total_websearches ||
                        (subscriptionDetails &&
                            ['Criador', 'Creator'].includes(
                                subscriptionDetails?.plan_name,
                            ))
                    ) {
                        return;
                    }

                    setLink(linkExternal);
                    handleModalMore('link');
                }}
                handleClose={handleCloseExternalLink}
            />
        </Responsive>
    );
};

export default NewChat;
