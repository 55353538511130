import styled from 'styled-components';

export const SelectStyled = styled.select`
    height: 45px;
    padding: 0 16px;
    border: 1px solid #e2e8f0;
    color: #1a202c;
    font-weight: 500;
    width: 100%;
    border-radius: 6px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: right;
    background-size: 10px;
    -webkit-appearance: none;
    background-position-x: calc(100% - 16px);

    :focus {
        border: 1px solid #1a202c;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a0aec0;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a0aec0;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a0aec0;
    }

    &.darkModeSelect {
        background: #1d1d1f !important;
        border-color: #1d1d1f;
        color: #ffffff !important;
        background: #1d1d1f
            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>")
            right 0.75rem center/8px 10px no-repeat !important;
        &:focus {
            border: 1px solid #fff !important;
        }
    }
`;

export const Label = styled.label`
    font-weight: bold;
    display: block;
    margin-top: 9px;
    margin-bottom: 15px;
`;

export const InputContainer = styled.div`
    font-size: 0.9em;
    width: 100%;

    .step {
        text-align: right;
        padding-top: 8px;

        span {
            color: #718096;
            font-size: 0.75em;
        }
    }
`;
