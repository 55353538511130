import React from 'react';
import { Button, Container } from '../../../app/presentation/components';
import { Header, DocumentsContainer } from './styles';
import { FieldValues, useForm } from 'react-hook-form';
import {
    Chat as ChatIcon,
    Edit,
    Trash,
} from '../../../app/presentation/components/icons';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { routeTranslate } from '../../../utils/intl';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { BsCheck } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { TbTrash } from 'react-icons/tb';
import { Menu, MenuList, Paper, useMediaQuery } from '@mui/material';
import NewChat from './new-chat';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import useDocumentTitle from 'utils/useDocumentTitle';
import useChat from 'app/presentation/hooks/useChat';
import { MuiMenuHeader } from '../documents/styles';
import { FiEdit3 } from 'react-icons/fi';
import { BiTrash } from 'react-icons/bi';

const Chat: React.FC = () => {
    const [edit, setEdit] = React.useState<undefined | number>();
    const [editMobile, setEditMobile] = React.useState<undefined | number>();
    const [deleteId, setDelete] = React.useState<undefined | number>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openMenuTable, setOpenMenuTable] = React.useState(false);
    const [newChatTitle, setNewChatTitle] = React.useState('');
    const [editID, setEditID] = React.useState<number>();

    const {
        chats,
        chat: chatSelect,
        newChat,
        deleteChat,
        updateChat,
        setChat,
    } = useChat();
    const intl = useIntl();
    const { register, setValue } = useForm<FieldValues>();
    const isDesktop = useMediaQuery('(min-width: 64em)');

    const currentPageName = routeTranslate(intl, 'route.chat').replace('/', '');
    const pageTitle =
        currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1);
    useDocumentTitle(pageTitle);

    const { darkmode } = useDarkMode();
    const navigate = useNavigate();

    const handleEdit = (id?: number) => {
        if (!id) return;
        setEdit(id);
        setEditID(id);
        setDelete(undefined);

        const titleChat = chats?.find(chat => chat.id === id);
        if (titleChat) setValue('editChat', titleChat.title);
        handleCloseMenuTable(true);
    };

    const handleResetStats = () => {
        setDelete(undefined);
        setEdit(undefined);
        setEditID(undefined);
        setEditMobile(undefined);
    };

    const handleClickMenuTable = (
        event: React.MouseEvent<HTMLDivElement>,
        id: number,
    ) => {
        setEditMobile(id);
        setAnchorEl(event.currentTarget);
        setOpenMenuTable(true);
    };

    const handleCloseMenuTable = (stats?: boolean) => {
        setAnchorEl(null);
        setOpenMenuTable(false);
        if (!stats) {
            handleResetStats();
        }
    };

    const handleDelete = (id?: number) => {
        if (!id) return;
        setDelete(id);
        setEdit(undefined);
        const titleChat = chats?.find(chat => chat.id === id);
        if (titleChat) setValue('editChat', titleChat.title);
        handleCloseMenuTable(true);
    };

    const editChat = register('editChat');

    const handleCloseChangeTitleChat = () => {
        handleResetStats();
        setNewChatTitle('');
    };

    const handleEditChat = async (id: number) => {
        if (id) {
            await updateChat(newChatTitle, id);
            handleResetStats();
            setNewChatTitle('');
        }
    };

    return (
        <Container className={isDesktop ? 'content-full' : ''}>
            <Header className={darkmode ? 'headerChatDark' : ''}>
                <h1>
                    Chat <span className="beta">Beta</span>
                </h1>
                <div id="options">
                    <Button
                        onClick={async () => {
                            await newChat(
                                routeTranslate(intl, 'chat.newChat.title'),
                            );

                            navigate(
                                `/${routeTranslate(
                                    intl,
                                    'route.urlLang',
                                )}${routeTranslate(
                                    intl,
                                    'route.backoffice',
                                )}${routeTranslate(
                                    intl,
                                    'route.chat',
                                )}${routeTranslate(intl, 'route.newChat')}`,
                            );
                        }}
                        icon={<MdAdd />}
                    >
                        {routeTranslate(intl, 'chat.newChat')}
                    </Button>
                </div>
            </Header>
            <DocumentsContainer className={darkmode ? 'containerChatDark' : ''}>
                {chats &&
                    chats.map(chat => {
                        const updated_date = new Date(chat.updated_at);

                        return (
                            <div className="box" key={chat.id}>
                                <div className="header">
                                    <div
                                        onClick={() => {
                                            if (deleteId || editMobile) return;

                                            setChat(chat.id);
                                            navigate(
                                                `/${routeTranslate(
                                                    intl,
                                                    'route.urlLang',
                                                )}${routeTranslate(
                                                    intl,
                                                    'route.backoffice',
                                                )}${routeTranslate(
                                                    intl,
                                                    'route.chat',
                                                )}${routeTranslate(
                                                    intl,
                                                    'route.newChat',
                                                )}`,
                                            );
                                        }}
                                        className="icon"
                                    >
                                        {deleteId === chat.id ? (
                                            <TbTrash size={22} />
                                        ) : (
                                            <ChatIcon />
                                        )}
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (deleteId || editMobile) return;

                                            setChat(chat.id);
                                            navigate(
                                                `/${routeTranslate(
                                                    intl,
                                                    'route.urlLang',
                                                )}${routeTranslate(
                                                    intl,
                                                    'route.backoffice',
                                                )}${routeTranslate(
                                                    intl,
                                                    'route.chat',
                                                )}${routeTranslate(
                                                    intl,
                                                    'route.newChat',
                                                )}`,
                                            );
                                        }}
                                        className="flex"
                                    >
                                        {edit === chat.id ? (
                                            <>
                                                <input
                                                    defaultValue={chat.title}
                                                    onChange={e =>
                                                        setNewChatTitle(
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                                <div className="options-icons">
                                                    <button
                                                        className={`changeTitleButton ${
                                                            darkmode
                                                                ? 'changeTitleButtonDark'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            handleEditChat(
                                                                edit,
                                                            );
                                                        }}
                                                        disabled={
                                                            newChatTitle.length ===
                                                                0 ||
                                                            chat.title ===
                                                                newChatTitle
                                                        }
                                                    >
                                                        <BsCheck
                                                            style={{
                                                                color: '#718096',
                                                            }}
                                                            size={25}
                                                        />
                                                    </button>
                                                    <button
                                                        className={`changeTitleButton ${
                                                            darkmode
                                                                ? 'changeTitleButtonDark'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            handleCloseChangeTitleChat()
                                                        }
                                                    >
                                                        <RxCross2
                                                            style={{
                                                                color: '#718096',
                                                            }}
                                                            size={20}
                                                        />
                                                    </button>
                                                </div>
                                            </>
                                        ) : deleteId === chat.id ? (
                                            <>
                                                <h1 className="chatTitle">
                                                    {chat.title}
                                                </h1>
                                                <div className="options-icons">
                                                    <div
                                                        onClick={() => {
                                                            deleteChat(
                                                                deleteId,
                                                            );
                                                            setDelete(
                                                                undefined,
                                                            );
                                                        }}
                                                    >
                                                        <BsCheck
                                                            style={{
                                                                color: '#718096',
                                                            }}
                                                            size={25}
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            handleResetStats()
                                                        }
                                                    >
                                                        <RxCross2
                                                            style={{
                                                                color: '#718096',
                                                            }}
                                                            size={20}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <h1 className="chatTitle">
                                                {chat.title}
                                            </h1>
                                        )}
                                    </div>
                                    {editID !== chat.id && (
                                        <div
                                            className="dots"
                                            onClick={e =>
                                                handleClickMenuTable(e, chat.id)
                                            }
                                        >
                                            <HiOutlineDotsHorizontal
                                                size={22}
                                                style={{ color: '#718096' }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="infos">
                                    <div className="info">
                                        <strong>Criado por</strong>
                                        <span>
                                            {chat.user.first_name}{' '}
                                            {chat.user.last_name}
                                        </span>
                                    </div>
                                    <div className="info">
                                        <strong>Modificado</strong>
                                        <span>
                                            {updated_date.toLocaleString()}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                <Menu
                    id="basic-menu-workspace"
                    anchorEl={anchorEl}
                    open={openMenuTable}
                    onClose={() => handleCloseMenuTable(false)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                        width: '100%',
                        '& > .MuiPaper-root': {
                            boxShadow:
                                '8px 16px 32px rgba(113, 128, 150, 0.08);',
                            borderRadius: '12px',
                            background: darkmode ? '#1d1d1f' : '',
                        },
                    }}
                    anchorOrigin={{
                        vertical: 40,
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Paper
                        sx={{
                            width: 202,
                            boxShadow: 0,
                            padding: '0px 16px',
                            background: darkmode ? '#1d1d1f' : '',
                        }}
                    >
                        <MenuList
                            sx={{
                                padding: 0,
                                boxShadow: 0,
                                background: darkmode ? '#1d1d1f' : '',
                            }}
                            disablePadding
                            dense
                        >
                            <MuiMenuHeader>
                                <ul className="list-submenu-options">
                                    <li
                                        style={{
                                            gap: '10px',
                                        }}
                                        onClick={() => handleEdit(editMobile)}
                                    >
                                        <FiEdit3 size={18} />
                                        {routeTranslate(
                                            intl,
                                            'home.documents.edit',
                                        )}
                                    </li>
                                    <li
                                        style={{
                                            gap: '10px',
                                        }}
                                        onClick={() => handleDelete(editMobile)}
                                    >
                                        <BiTrash size={18} />
                                        {routeTranslate(
                                            intl,
                                            'home.documents.delete',
                                        )}
                                    </li>
                                </ul>
                            </MuiMenuHeader>
                        </MenuList>
                    </Paper>
                </Menu>
                <article id="table">
                    <div className={`sidebar ${darkmode ? 'dark' : ''}`}>
                        <Button
                            onClick={() =>
                                newChat(
                                    routeTranslate(intl, 'chat.newChat.title'),
                                )
                            }
                            icon={<MdAdd size={26} />}
                        >
                            {routeTranslate(intl, 'chat.newChat')}
                        </Button>
                        <ul>
                            {chats &&
                                chats.map(chat => (
                                    <li
                                        onClick={() => {
                                            setChat(chat.id);
                                        }}
                                        className={
                                            chatSelect === chat.id
                                                ? 'active'
                                                : ''
                                        }
                                    >
                                        {edit === chat.id ? (
                                            <>
                                                <ChatIcon />
                                                <input
                                                    defaultValue={chat.title}
                                                    onChange={e =>
                                                        setNewChatTitle(
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                                <div className="options-icons">
                                                    <button
                                                        className={`changeTitleButton ${
                                                            darkmode
                                                                ? 'changeTitleButtonDark'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            handleEditChat(
                                                                edit,
                                                            );
                                                        }}
                                                        disabled={
                                                            newChatTitle.length ===
                                                                0 ||
                                                            newChatTitle ===
                                                                chat.title
                                                        }
                                                    >
                                                        <BsCheck
                                                            style={{
                                                                color: '#718096',
                                                            }}
                                                            size={25}
                                                        />
                                                    </button>
                                                    <button
                                                        className={`changeTitleButton ${
                                                            darkmode
                                                                ? 'changeTitleButtonDark'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            handleCloseChangeTitleChat()
                                                        }
                                                    >
                                                        <RxCross2
                                                            style={{
                                                                color: '#718096',
                                                            }}
                                                            size={20}
                                                        />
                                                    </button>
                                                </div>
                                            </>
                                        ) : deleteId === chat.id ? (
                                            <>
                                                <TbTrash size={22} />
                                                <input {...editChat} />
                                                <div className="options-icons">
                                                    <div
                                                        onClick={() => {
                                                            deleteChat(
                                                                deleteId,
                                                            );
                                                            setDelete(
                                                                undefined,
                                                            );
                                                        }}
                                                    >
                                                        <BsCheck
                                                            style={{
                                                                color: '#718096',
                                                            }}
                                                            size={25}
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={
                                                            handleResetStats
                                                        }
                                                    >
                                                        <RxCross2
                                                            style={{
                                                                color: '#718096',
                                                            }}
                                                            size={20}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <ChatIcon />
                                                <h1 className="chatTitle">
                                                    {chat.title}
                                                </h1>
                                                <div
                                                    className="options-icons"
                                                    style={{
                                                        gap: '10px',
                                                    }}
                                                >
                                                    <div
                                                        onClick={() => {
                                                            handleEdit(chat.id);
                                                        }}
                                                    >
                                                        <Edit />
                                                    </div>
                                                    <div
                                                        onClick={() =>
                                                            handleDelete(
                                                                chat.id,
                                                            )
                                                        }
                                                    >
                                                        <Trash />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <NewChat />
                </article>
            </DocumentsContainer>
        </Container>
    );
};

export default Chat;
