import styled from 'styled-components';

export const ContainerContent = styled.div`
    width: 100%;
    min-width: 1060px;
    height: 90%;
    display: flex;
    flex-direction: column;

    .language-selector {
        display: flex;
        gap: 20px;
        margin-right: auto;

        margin-top: 30px;
        p {
            color: #7E7E7E;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            height: 32px;
            cursor: pointer;
        }

        .selected {
            color: black;
            border-bottom: 2px solid black;
        }
    }

    .title {
        display: flex;
        width: 100%;
        justify-content: space-between;

        h3 {
            display: flex;
            flex-direction: column;
            font-size: 1.25rem;
            font-weight: 700;
            color: #000;
            span {
                font-size: 0.875rem;
                font-weight: 500;
                color: #7e7e7e;
                margin-top: 12px;
            }
        }

        button {
            height: 48px;
            width: 165px;
            font-size: 0.875rem;
        }
    }
`;



