import React from 'react';
import * as S from './styles';
import { Button } from '../Button';
import { IconChevronDown } from '@tabler/icons-react';
import { Menu } from '@mui/material';
import { ThemeContext } from 'styled-components';
import { Input } from '../Input';

interface Option {
    value: string;
    filterValue?: string;
    node: React.ReactNode;
}

interface Props {
    options: Option[];
    button: string;
    menu: {
        title: string;
        placeholder: string;
    };
    value: string;
    onChange: (value: string) => void;
    createOption?: {
        prefix: React.ReactNode;
        onChange: (value: string) => void;
    };
}

export const SelectDropdown: React.FC<Props> = ({
    value,
    onChange,
    button,
    options,
    menu,
    createOption: createOptionProps,
}) => {
    const [filterString, setFilterString] = React.useState<string>('');
    const [buttonContent, setButtonContent] =
        React.useState<React.ReactNode>(button);
    const [anchorOptsMenu, setAnchorOptsMenu] =
        React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorOptsMenu);
    const theme = React.useContext(ThemeContext);

    React.useEffect(() => {
        const selected = options.find(option => option.value == value);
        if (value != '0' || !createOptionProps) {
            setButtonContent(selected ? selected.node : button);
        } else {
            setButtonContent(filterString ? filterString : button);
        }
    }, [value, filterString, options]);

    const openOptsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorOptsMenu(event.currentTarget);
    };

    const closeOptsMenu = () => {
        setAnchorOptsMenu(null);
    };

    const paperPropsStyle: React.CSSProperties = {
        borderRadius: '12px',
        boxShadow: 'box-shadow: 0px 16px 32px 0px #71809614',
        background: theme.components.selectDropdown.menu.background.color,
        padding: '0px',
        width: '260px',
        marginTop: '-26px',
    };

    const menuListStyle: React.CSSProperties = {
        padding: '12px',
        boxShadow: '0',
        background: theme.components.selectDropdown.menu.background.color,
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterString(event.target.value);
        event.preventDefault();
        if (!createOptionProps) {
            return;
        }
        createOptionProps.onChange(event.target.value);
    };

    return (
        <S.SelectDropdown>
            <Button
                variant="secondary"
                className="open-menu-button"
                onClick={openOptsMenu}
                type="button"
            >
                <span>{buttonContent}</span>
                <IconChevronDown></IconChevronDown>
            </Button>
            {value == '0' && createOptionProps && (
                <Input
                    className="create-option-input"
                    value={filterString}
                    required
                ></Input>
            )}

            <Menu
                anchorEl={anchorOptsMenu}
                open={open}
                onClose={closeOptsMenu}
                PaperProps={{
                    style: paperPropsStyle,
                }}
                MenuListProps={{
                    style: menuListStyle,
                }}
            >
                <S.MenuHeader
                    onKeyDown={(event: any) => {
                        event.stopPropagation();
                    }}
                >
                    <Input
                        placeholder={menu.placeholder}
                        value={filterString}
                        onChange={onInputChange}
                    ></Input>
                    <div className="title-container">
                        <span>{menu.title}</span>
                    </div>
                </S.MenuHeader>
                <S.MenuBody>
                    {options
                        .filter(item =>
                            (item.filterValue ? item.filterValue : item.value)
                                .toLowerCase()
                                .includes(filterString.toLowerCase()),
                        )
                        .map((item, index) => (
                            <button
                                className={
                                    'menu-item-container ' +
                                    (item.value == value ? 'active' : '')
                                }
                                key={index}
                                type="button"
                                onClick={() => onChange(item.value)}
                            >
                                {item.node}
                            </button>
                        ))}
                </S.MenuBody>
                {createOptionProps && (
                    <S.MenuFooter>
                        <button
                            className={
                                'menu-item-container ' +
                                (value == '0' ? 'active' : '')
                            }
                            onClick={() => onChange('0')}
                            type="button"
                        >
                            <span className="create-option-text">
                                {createOptionProps.prefix}
                                <span>
                                    ”
                                    <span className="new-option">
                                        {filterString}
                                    </span>
                                    ”
                                </span>
                            </span>
                        </button>
                    </S.MenuFooter>
                )}
            </Menu>
        </S.SelectDropdown>
    );
};
