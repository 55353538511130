import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { FieldValues, useForm } from 'react-hook-form';
import useArteAi from '../../../hooks/useArteAi';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Search } from '../../icons';
import { Input } from '../../common';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    handleSelectImage: (id: number) => void;
}

const ModalArteAiHistory: React.FC<ModalProps> = ({
    active,
    handleClose,
    handleSelectImage,
}) => {
    const intl = useIntl();
    const { darkmode } = useDarkMode();

    const { register, watch } = useForm<FieldValues>();
    const { history, getHistory } = useArteAi();

    const watchSearch = watch('search');

    React.useEffect(() => {
        getHistory(1, watchSearch);
    }, [watchSearch]);

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'modalArteDark' : ''}
            >
                <div className="content-modal">
                    <header>
                        <IoMdClose size={25} onClick={handleClose} />
                    </header>
                    <div id="title">
                        <h1>{routeTranslate(intl, 'arteai.recents')}</h1>
                        <p>
                            {routeTranslate(intl, 'arteai.recents.description')}
                        </p>
                    </div>
                    <form>
                        <div className="input">
                            <div className="icon">
                                <Search darkmode={darkmode} />
                            </div>
                            <Input
                                id="search"
                                placeholder={routeTranslate(
                                    intl,
                                    'label.search',
                                )}
                                register={register}
                            />
                        </div>
                    </form>
                    {history && history.data && (
                        <InfiniteScroll
                            dataLength={history.data.length}
                            next={() => {
                                getHistory(history.next);
                            }}
                            hasMore={history.page !== history.total_pages}
                            loader={<></>}
                            scrollableTarget="scrollableDiv"
                        >
                            <div className="models" id="scrollableDiv">
                                <div className="box">
                                    <ul className="list">
                                        {history.data.map(items => (
                                            <li key={items.id}>
                                                <img
                                                    onClick={() =>
                                                        handleSelectImage(
                                                            items.id,
                                                        )
                                                    }
                                                    src={items.thumbnail}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </InfiniteScroll>
                    )}
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalArteAiHistory;
