import { IntlShape } from "react-intl";
import { routeTranslate as t } from "./intl";

export const pathStartsWith = (path: string, intl: IntlShape, ...ids: string[]): boolean => {
    return path.startsWith(
        ids
        .map(id => t(intl, id))
        .reduce((prev, current)=> `${prev}${current}`, '/')
    )
};

export const basePath = (path: string, intl: IntlShape, lastPathPartId: string): string => {
    const pathParts = path.split('/')
    const lastPathPart = t(intl, lastPathPartId).replace('/','')
    const lastPathPartIndex = pathParts.findIndex(part => part == lastPathPart)
    return lastPathPartIndex == -1 
        ? path 
        : pathParts
            .filter((part, index) => index <= lastPathPartIndex && part)
            .reduce((prev, current)=> `${prev}/${current}`, '')
};