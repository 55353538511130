import styled from 'styled-components';

export const ContentContainer = styled.div`
    display: flex;

    .withoutBrandText {
        color: #000;
        font-weight: 500;
        font-size: 0.75rem;
        a {
            font-weight: 700;
            color: #194bfb;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    li {
        border-radius: 6px;
        border: 1px solid #e2e8f0;
        padding: 5px 10px;
        background: #f7fafc;
        display: flex;
        align-items: center;
        list-style-type: none;
        cursor: pointer;
        font-weight: 500;
        font-size: 0.875rem;
        svg {
            transition-duration: 0.5s;

            :first-of-type {
                margin-right: 8px;
            }
            :last-of-type {
                margin-left: 8px;
            }
        }

        .btnInvert {
            transform: rotate(-180deg);
        }
    }

    &.dropdownDark {
        li {
            border-color: #fff;
            background: #000;
        }
    }
`;

export const MuiMenuHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 363px;
    min-width: 363px;
    height: 100%;
    border-radius: 12px;
    position: relative;

    .input > div {
        margin: 0;
    }

    .input {
        display: flex;
        align-items: center;
        border-radius: 12px;
        width: 100%;

        .icon {
            position: absolute;
            left: 18px;
        }

        input[type='text'] {
            padding-left: 58px;
            height: 42px;
            border-radius: 12px;
            width: 100%;
            border: 1px solid #cbd5e0;

            &:focus {
                border-color: #000;
            }
        }
    }

    .scrollFilters {
        overflow-x: scroll !important;
        font-size: 0.75rem;
        &::-webkit-scrollbar {
            display: none !important;
        }

        &::-moz-scrollbar {
            display: none !important;
        }
        .style-filters {
            margin: 15px 0 5px 0;
            width: max-content;
            .filterButton {
                cursor: pointer;
                color: #000;
                background: #fff;
                border: 1px solid #e2e8f0;
                border-radius: 30px;
                padding: 5px 16px;
                font-weight: 500;
                margin-right: 6px;
            }

            .selectedFilter {
                background: #d0ff61;
                font-weight: 700;
                border-color: #d0ff61;
            }
        }
    }

    .list-itens-container {
        .memorie-group-item {
            .titleGroup {
                border-bottom: 1px solid #edf2f7;
                padding-bottom: 8px;
                font-size: 0.75rem;
                font-weight: 600;
                color: #a09fa5;
                margin-top: 10px;
            }
            .memorie-itens-list {
                margin-top: 5px;
                display: flex;
                align-items: center;
                height: 43px;

                p {
                    font-weight: 500;
                    color: #3a3a3a;
                    margin-left: 8px;
                }
            }
            .checkedItem {
                background-color: #f7fafc;
                border-radius: 4px;
                p {
                    color: #000;
                }
            }
        }
    }

    .footer {
        position: sticky;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid #edf2f7;
        width: 100%;
        height: fit-content;
        background-color: #fff;

        button {
            border: none;
            border-radius: 8px;
            background: #d0ff61;
            width: 72px;
            height: 34px;
            font-size: 0.875rem;
            font-weight: 600;
            cursor: pointer;
            margin: 10px 0;

            &:disabled {
                background-color: #ccc;
                cursor: not-allowed;
            }
        }
    }

    &.muiDark {
        .input {
            input[type='text'] {
                border-color: #000;
                background: #000;
                color: #fff;

                &:focus {
                    border-color: #000;
                }
            }
        }
        .list-itens-container {
            .memorie-group-item {
                .titleGroup {
                    border-color: rgb(58, 58, 58);

                    color: #ccc;
                }
                .memorie-itens-list {
                    p {
                        font-weight: 500;
                        color: #ccc;
                    }
                }
                .checkedItem {
                    background-color: #000;
                    p {
                        color: #fff;
                    }
                }
            }
        }

        .scrollFilters {
            .style-filters {
                .filterButton {
                    color: #fff;
                    background: #000;
                    border: 1px solid #e2e8f0;
                }

                .selectedFilter {
                    background: #d0ff61;
                    font-weight: 700;
                    border-color: #d0ff61;
                    color: #000;
                }
            }
        }

        .footer {
            background: #1d1d1f;
            border-color: rgb(58, 58, 58);
        }
    }
`;
