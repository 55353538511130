import React from 'react';
import { FormFieldInfo } from '../FormField';
import * as S from './styles';

interface InfoProps {
    title: string;
    tooltip?: string;
}

interface Props {
    name: string;
    value: boolean;
    info?: InfoProps;
    setValue: (value: boolean) => void;
    disabled?: boolean;
    width?: string;
    height?: string;
}
export const SwitchCheckbox: React.FC<Props> = ({
    name,
    value,
    setValue,
    info,
    disabled = false,
    width,
    height,
}) => {
    const handleClick = () => {
        const newValue = !value;
        setValue(newValue);
    };

    return (
        <S.SwitchCheckboxContainer disabled={disabled}>
            <S.SwitchCheckbox
                onClick={() => !disabled && handleClick()}
                className={value ? 'active ' : ''}
                width={width}
                height={height}
            >
                <div className="white-circle"></div>
                <input type="checkbox" name={name} checked={value} disabled />
            </S.SwitchCheckbox>
            {info && <FormFieldInfo {...info}></FormFieldInfo>}
        </S.SwitchCheckboxContainer>
    );
};
