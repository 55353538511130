import styled, { css } from 'styled-components';

export const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Action = styled.div`
    ${({
        theme: {
            agent: {
                components: {
                    actionContainer: {
                        action: { border },
                    },
                },
            },
        },
    }) => css`
        display: flex;
        align-items: center;
        padding: 6px 8px;
        height: 41px;
        border-radius: 6px;
        border: 1px solid ${border.color};
        gap: 12px;
        width: fit-content;
        max-width: 100%;
        @media (min-width: 64em) {
            width: fit-content;
        }

        svg {
            width: 18px;
            height: 18px;
        }

        .delete-button {
            background-color: transparent;
            width: 16px;
            height: 16px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
    `}
`;

export const NewTagPrefix = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        width: 20px;
        height: 20px;
    }

    span {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.2px;
    }
`;
