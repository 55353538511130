import React from 'react';
import { DetailsContent } from './styles';
import {
    Button,
    HeaderModalDetails,
    Input,
    Select,
} from 'app/presentation/components';

import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { AuthContext } from 'app/presentation/contexts/AuthContext';
import { updateEmail, updateProfile } from 'firebase/auth';
import { useSnackbar } from 'notistack';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import translations from '../../../../i18n/translations.json';
import useIntlConfig from '../../../../app/presentation/hooks/useIntlConfig';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { routeTranslate as t } from 'utils/intl';
import { getNameLetters } from 'utils/others';
import Loading from 'app/presentation/components/common/Loading';
import { sleep } from 'utils/sleep';

const Details: React.FC = () => {
    const { register, watch, handleSubmit, setValue } = useForm<FieldValues>();

    const user = React.useContext(AuthContext);
    const name = user?.displayName?.split(' ');
    const firstName = name ? name[0] : 'Carregando';
    const lastName = name ? name[1] : 'Carregando';
    const email = user?.email;
    const watchLanguage = watch('language') ?? '';
    const [loading, setLoading] = React.useState(false);
    const [loadingPage, setLoadingPage] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { settings, languages, updateUserSetting } = useUserConfig();
    const { setLanguage } = useIntlConfig();
    const navigate = useNavigate();
    const intl = useIntl();

    React.useEffect(() => {
        setValue('firstName', firstName);
        setValue('lastName', lastName);
        setValue('email', email);
    }, [firstName, lastName]);

    const change = async () => {
        if (watchLanguage) {
            setLoadingPage(true);

            await updateUserSetting(parseInt(watchLanguage));

            if (!languages) return;
            const language = languages.find(
                lang => lang.id === Number(watchLanguage),
            );
            const keyTyped = (language?.lang ??
                'pt-BR') as keyof typeof translations;

            setLanguage(keyTyped);

            navigate(
                `/${translations[keyTyped]['route.urlLang']}${translations[keyTyped]['route.backoffice']}${translations[keyTyped]['route.definitions']}${translations[keyTyped]['route.details']}`,
            );
            setLoadingPage(false);
        }
    };

    React.useEffect(() => {
        change();
    }, [watchLanguage]);

    const successMessage = (message: string) => {
        enqueueSnackbar(message, {
            variant: 'success',
        });
    };

    const erroMessage = (message: string) => {
        enqueueSnackbar(message, {
            variant: 'error',
        });
    };

    const handleSubmitForm: SubmitHandler<FieldValues> = async data => {
        setLoading(true);

        try {
            if (user) {
                await updateProfile(user, {
                    displayName: data.firstName + ' ' + data.lastName,
                });

                if (user.email !== data.email) {
                    await updateEmail(user, data.email);
                }
            }
            successMessage(t(intl, 'toast.updateData.success'));
            sleep(0.5);
            window.location.reload();
            setLoading(false);
        } catch (e: any) {
            setLoading(false);
            erroMessage(t(intl, 'toast.updateData.error'));
        }
        setLoading(false);
    };

    const optionsLanguage = languages?.map(language => {
        return {
            name: language.lang,
            value: language.id.toString(),
        };
    });

    React.useEffect(() => {
        console.log(optionsLanguage);
    }, [])

    return (
        <DetailsContent>
            <Loading open={loadingPage} />
            <HeaderModalDetails
                title={t(intl, 'definitions.profileDetails')}
            />
            <form id="change-img">
                {user?.photoURL ? (
                    <div id="image">
                        <div className="border">
                            <img src={user?.photoURL} />
                        </div>
                    </div>
                ) : (
                    <div id="imageText">
                        <div className="borderText">
                            <li className="imageText">
                                {getNameLetters(user?.displayName ?? 'JB')}
                            </li>
                        </div>
                    </div>
                )}
                {/* <div id="submit-image">
                    <Button type="submit" icon={<Share />}>
                        {routeTranslate(intl, 'personalData.upload')}
                    </Button>
                    <Button id="remove">
                        {routeTranslate(intl, 'personalData.remove')}
                    </Button>
                </div>*/}
            </form>
            {/*<form onSubmit={handleUpload}>*/}
            {/*    <input type="file" onChange={handleChange} />*/}
            {/*    <button disabled={!file}>upload to firebase</button>*/}
            {/*</form>*/}
            {/*{url}*/}
            <form onSubmit={handleSubmit(handleSubmitForm)} id="form">
                <div className="content-form">
                    <div>
                        <Input
                            id="firstName"
                            register={register}
                            label={t(intl, 'personalData.name')}
                        />
                        <Input
                            id="lastName"
                            register={register}
                            label={t(intl, 'personalData.surname')}
                        />
                    </div>
                    <div>
                        <Input
                            id="email"
                            register={register}
                            label="E-mail"
                            disabled
                        />
                        <Select
                            label={t(intl, 'personalData.language')}
                            id="language"
                            register={register}
                            value={settings?.user_settings.language_id}
                            options={optionsLanguage}
                        />
                    </div>
                </div>
                <Button type="submit" loading={loading}>
                    {t(intl, 'button.save')}
                </Button>
            </form>
        </DetailsContent>
    );
};

export default Details;
