import { IconPlus } from '@tabler/icons-react';
import { Select } from 'app/presentation/components';
import { Button, Paginator } from 'app/presentation/components/agent';
import IconInput from 'app/presentation/components/agent/IconInput';
import { TrainingDrawer } from 'app/presentation/components/agent/Training/Drawers';
import { ModalNewContent } from 'app/presentation/components/agent/Training/Modals/ModalNewContent';
import { ModalUrlContent } from 'app/presentation/components/agent/Training/Modals/ModalUrlContent';
import TrainingTable from 'app/presentation/components/agent/Training/TrainingTable';
import TrainingStats from 'app/presentation/components/agent/TrainingStats';
import Loading from 'app/presentation/components/common/Loading';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { TbSearch } from 'react-icons/tb';
import { Page } from 'services/client';
import { TrainingService } from 'services/training';
import {
    GetEnabledTrainingsInformationResponse,
    Training as TrainingModel,
    TrainingResourceTypes,
} from 'services/training/interfaces';
import * as S from './styles';

const Training = () => {
    const { textGetter } = useText();
    const t = textGetter('agent.pages.trainings');
    const { agent, notifyAgentTrainingChanged, agentTrainingChangeCount } =
        useAgent();
    const service = new TrainingService();
    const { register } = useForm<FieldValues>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [total, setTotal] = useState(0);
    const [titleFilter, setTitleFilter] = useState<string>('');
    const [enabledTrainingsInformation, setEnabledTrainingsInformation] =
        useState<GetEnabledTrainingsInformationResponse>();
    const [trainings, setTrainings] = useState<Page<TrainingModel>>();

    const [modalNewContentActive, setModalNewContentActive] = useState(false);
    const [trainingDrawerActive, setExcerptDrawerActive] = useState(false);
    const [modalUrlContentActive, setModalUrlContentActive] = useState(false);

    const handleTitleFilter = (title: string) => {
        if (!agent) return;
        setTrainings(undefined);
        setTitleFilter(title);
        setPage(1);
        service.list(agent.id, page, limit, title).then(page => {
            setTrainings(page);
            setTotal(page.total_pages);
        });
    };

    const handleLimitChange = (newValue: number) => {
        if (!agent) return;
        setTrainings(undefined);
        setLimit(newValue);
        setPage(1);
        service.list(agent.id, page, newValue, titleFilter).then(page => {
            setTrainings(page);
            setPage(1);
            setTotal(page.total_pages);
        });
    };

    const handlePageChange = (newValue: number) => {
        if (!agent) return;
        setTrainings(undefined);
        setPage(newValue);
        service.list(agent.id, newValue, limit, titleFilter).then(page => {
            setTrainings(page);
            setTotal(page.total_pages);
        });
    };

    useEffect(() => {
        if (!agent) return;

        service.list(agent.id, page, limit).then(page => {
            setTrainings(page);
            setTotal(page.total_pages);
        });
    }, [agentTrainingChangeCount]);

    useEffect(() => {
        if (!agent) return;

        service.getEnabledTrainingsInformation(agent.id).then(information => {
            setEnabledTrainingsInformation(information);
        });
    }, [agentTrainingChangeCount]);

    return (
        <S.Trainings>
            <ModalNewContent
                active={modalNewContentActive}
                handleClose={() => setModalNewContentActive(false)}
                setExcerptDrawerActive={setExcerptDrawerActive}
                setModalUrlContentActive={setModalUrlContentActive}
            />
            <ModalUrlContent
                active={modalUrlContentActive}
                handleClose={() => setModalUrlContentActive(false)}
            />
            <TrainingDrawer
                animateSlide={trainingDrawerActive}
                handleClose={() => setExcerptDrawerActive(false)}
                isEditing={true}
                contentType={TrainingResourceTypes.Excerpt}
                notifyAgentTrainingChanged={notifyAgentTrainingChanged}
            />

            <div className="trainings-container">
                <div className="metadata">
                    <div>
                        <h2 className="title">{t('title')}</h2>
                        <Button
                            variant="primary"
                            fullStyled
                            onClick={() => setModalNewContentActive(true)}
                            className="trainings-new-content-button"
                        >
                            <IconPlus />
                            {t('buttons.new')}
                        </Button>
                    </div>
                    <TrainingStats
                        brandCenter={
                            enabledTrainingsInformation?.brand_center || {}
                        }
                        externalContent={
                            enabledTrainingsInformation?.external_content || {}
                        }
                        excerpts={enabledTrainingsInformation?.excerpts || {}}
                        files={enabledTrainingsInformation?.files || {}}
                    />
                </div>
                <IconInput
                    className="training-search-input"
                    icon={TbSearch}
                    placeholder="Procurar"
                    onChange={e => handleTitleFilter(e.target.value)}
                />
                {trainings ? (
                    <TrainingTable page={trainings} />
                ) : (
                    <div className="table-loader">
                        <Loading open />
                    </div>
                )}
                <S.PaginatorContainer
                    className={!trainings ? 'paginator-hidden' : ''}
                >
                    <S.PaginatorPerPage>
                        <label>Mostrar resultado:</label>
                        <Select
                            id="per-page"
                            register={register}
                            value={String(limit)}
                            onChange={e => {
                                if (Number(e.target.value) === limit) return;
                                handleLimitChange(Number(e.target.value));
                            }}
                            options={Array.from({ length: 10 }).map(
                                (_, perPage) => ({
                                    name: String(perPage + 1),
                                    value: String(perPage + 1),
                                }),
                            )}
                        />
                    </S.PaginatorPerPage>
                    <Paginator
                        onChange={handlePageChange}
                        total={total}
                        current={page}
                    />
                </S.PaginatorContainer>
            </div>
        </S.Trainings>
    );
};

export default Training;
