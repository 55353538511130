import styled, { css } from 'styled-components';

export const Trainings = styled.div`
    ${({
        theme: {
            agent: {
                pages: {
                    config: { background },
                },
            },
        },
    }) => css`
        position: relative;
        padding: 30px 0px;
        display: flex;
        justify-content: center;

        overflow-y: scroll;
        background-color: ${background.color};
        @media (min-width: 64em) {
            padding: 30px 28px;
            justify-content: start;
        }

        width: 100%;

        .table-loader {
            position: relative;
            display: flex;
            width: 100%;
            height: 500px;
            align-items: center;
            justify-content: center;
        }

        .trainings-chat-container {
            width: 360px;
            height: 630px;
            border-radius: 14px;
            box-shadow: 0px 5px 30px 0px #0000001a;
            background-color: white;
            margin-left: 48px;
            display: none;

            @media (min-width: 1400px) {
                display: block;
            }
        }

        .trainings-container {
            min-width: 100% !important;
            width: 100%;
            height: fit-content;

            @media (max-width: 64em) {
                .trainings-new-content-button {
                    display: none;
                }
            }

            @media (min-width: 64em) {
                max-width: 1014px;
            }

            .metadata {
                margin-bottom: 30px;

                & > div:first-child {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .status-labeled-panel-title {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }

            .title {
                letter-spacing: 0.2px;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                margin-bottom: 10px;
            }

            .description {
                letter-spacing: 0.2px;
                font-weight: 500;
                font-size: 13px;
                line-height: 19.5px;
            }

            .expansion-panel-container {
                display: grid;
                grid-row-gap: 15px;
            }

            .training-search-input {
                width: 267px;
                height: 44px;
                margin-top: 12px;
                margin-bottom: 12px;
                z-index: 0 !important;

                @media (max-width: 64em) {
                    width: 100%;
                }
            }
        }
    `}
`;

export const PaginatorContainer = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 64em) {
        justify-content: center;
    }

    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
`;

export const PaginatorPerPage = styled.div`
    display: flex;
    width: 130px;
    color: #cccccc;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.2px;
    justify-content: center;

    label {
        margin-right: 10px;
    }

    div {
        min-width: 68px;
        height: 37px;
    }
`;
