import { colors } from './colors';

export type ButtonThemeVariants =
    | 'primary'
    | 'secondary'
    | 'dark'
    | 'danger'
    | 'link'
    | 'outline';

export const buttons = {
    dark: {
        primary: {
            color: colors.gs900,
            background: colors.primary,
            border: { color: colors.transparent },
        },
        secondary: {
            color: colors.gs900,
            background: colors.white,
            border: { color: colors.transparent },
        },
        outline: {
            color: colors.white,
            background: colors.gs900,
            border: { color: colors.white },
        },
        danger: {
            color: colors.alerts.error.dark,
            background: colors.alerts.error.dark10,
            border: { color: colors.transparent },
        },
        link: {
            color: colors.blue,
            background: colors.inherit,
            border: { color: colors.transparent },
        },
        dark: {
            color: colors.gs900,
            background: colors.primary,
            border: { color: colors.transparent },
        },
    },
    light: {
        primary: {
            color: colors.gs900,
            background: colors.primary,
            border: { color: colors.transparent },
        },
        secondary: {
            color: colors.gs900,
            background: colors.gs200,
            border: { color: colors.transparent },
        },
        danger: {
            color: colors.alerts.error.dark,
            background: colors.alerts.error.dark10,
            border: { color: colors.transparent },
        },
        outline: {
            color: colors.gs900,
            background: colors.white,
            border: { color: colors.gs900 },
        },
        link: {
            color: colors.blue,
            background: colors.inherit,
            border: { color: colors.transparent },
        },
        dark: {
            color: colors.white,
            background: colors.gs900,
            border: { color: colors.transparent },
        },
    },
};

export type SelectThemeVariants = 'white' | 'grey';

export const select = {
    dark: {
        white: {
            height: '44px',
            padding: '0px 16px',
            borderRadius: '8px',
            fontWeight: '500',
            text: { color: colors.white },
            background: { color: colors.gs900 },
            border: { color: colors.gs300, active: colors.white },
        },
        grey: {
            height: '32px',
            padding: '0px 12px',
            borderRadius: '4px',
            fontWeight: '600',
            text: { color: colors.gs900 },
            background: { color: colors.white },
            border: { color: colors.transparent, active: colors.transparent },
        },
    },
    light: {
        white: {
            height: '44px',
            padding: '0px 16px',
            borderRadius: '8px',
            fontWeight: '500',
            text: { color: colors.gs900 },
            background: { color: colors.white },
            border: { color: colors.gs300, active: colors.gs900 },
        },
        grey: {
            height: '32px',
            padding: '0px 12px',
            borderRadius: '4px',
            fontWeight: '600',
            text: { color: colors.gs900 },
            background: { color: colors.gs200 },
            border: { color: colors.transparent, active: colors.transparent },
        },
    },
};

export type InfoMessageVariants = 'yellow' | 'grey';

export const infoMessage = {
    dark: {
        yellow: {
            text: { color: colors.gs900 },
            background: { color: colors.yellow },
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '21px',
        },
        grey: {
            text: { color: colors.gs900 },
            background: { color: colors.gs200 },
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '19.5px',
        },
    },
    light: {
        yellow: {
            text: { color: colors.gs900 },
            background: { color: colors.yellow },
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '21px',
        },
        grey: {
            text: { color: colors.gs900 },
            background: { color: colors.gs200 },
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '19.5px',
        },
    },
};

export const expansionPanel = {
    dark: {
        background: { color: colors.gs900 },
        border: { color: colors.gs700, active: colors.white },
        text: { color: colors.white },
    },
    light: {
        background: { color: colors.white },
        border: { color: colors.gs300, active: colors.gs900 },
        text: { color: colors.gs900 },
    },
};

export type CardSelectThemeVariants = 'primary' | 'lightGrey';

const cardSelectCommons = {
    dark: {
        border: { color: colors.gs700, active: colors.white },
        title: { color: colors.white, active: colors.gs900 },
        iconBG: { color: colors.gs700, active: colors.primary },
        text: { color: colors.secondary },
    },
    light: {
        border: { color: colors.gs300, active: colors.gs900 },
        title: { color: colors.gs900, active: colors.gs900 },
        iconBG: { color: colors.gs200, active: colors.primary },
        text: { color: colors.gs650 },
    },
};

export const cardSelect = {
    dark: {
        primary: {
            background: { color: colors.gs900, active: '#F6FFDF' },
            ...cardSelectCommons.dark,
        },
        lightGrey: {
            background: { color: colors.gs900, active: colors.gs200 },
            ...cardSelectCommons.dark,
        },
    },
    light: {
        primary: {
            background: { color: colors.white, active: '#F6FFDF' },
            ...cardSelectCommons.light,
        },
        lightGrey: {
            background: { color: colors.white, active: colors.gs200 },
            ...cardSelectCommons.light,
        },
    },
};

export const tooltip = {
    dark: {
        background: { color: colors.white },
        text: { color: colors.gs900 },
    },
    light: {
        background: { color: colors.gs900 },
        text: { color: colors.white },
    },
};

export const formField = {
    dark: {
        title: { color: colors.white },
        text: { color: colors.gs600 },
        icon: { color: colors.white },
    },
    light: {
        title: { color: colors.gs900 },
        text: { color: colors.gs600 },
        icon: { color: colors.secondary },
    },
};

export const imgInput = {
    dark: {
        border: { color: colors.gs700, active: colors.white },
        icon: { color: colors.gs900 },
        background: { color: colors.gs800 },
    },
    light: {
        border: { color: colors.gs300, active: colors.gs900 },
        icon: { color: colors.gs900 },
        background: { color: colors.white },
    },
};

export const colorInput = {
    dark: {
        border: { color: colors.gs700, active: colors.white },
        text: { color: colors.white },
        background: { color: colors.gs800 },
    },
    light: {
        border: { color: colors.gs400, active: colors.gs900 },
        text: { color: colors.gs900 },
        background: { color: colors.white },
    },
};

export const switchCheckbox = {
    dark: {
        circle: { color: colors.white },
        background: { color: '#EDF2F7', active: colors.primary },
    },
    light: {
        circle: { color: colors.white },
        background: { color: '#EDF2F7', active: colors.gs900 },
    },
};

export const modal = {
    dark: {
        border: { color: colors.gs700 },
        background: { color: colors.gs800 },
    },
    light: {
        border: { color: colors.gs300 },
        background: { color: colors.white },
    },
};

export const gifPicker = {
    ...modal,
};

export const input = {
    dark: {
        border: { color: colors.gs700, active: colors.white },
        background: { color: colors.gs800 },
        text: { color: colors.white },
        placeholder: { color: colors.gs600 },
    },
    light: {
        border: { color: colors.gs300, active: colors.gs900 },
        background: { color: colors.white },
        text: { color: colors.gs900 },
        placeholder: { color: colors.gs600 },
    },
};

export const radioGroup = {
    dark: {
        border: { color: colors.white },
        circle: { color: colors.transparent, active: colors.primary },
    },
    light: {
        border: { color: colors.gs300 },
        circle: { color: colors.transparent, active: colors.primary },
    },
};

export const paginator = {
    dark: {
        text: { color: '#718096', active: colors.gs900 },
        background: { color: colors.white },
        border: { active: colors.secondary },
    },
    light: {
        text: { color: '#718096', active: colors.gs900 },
        background: { color: colors.white },
        border: { active: colors.secondary },
    },
};

export const selectDropdown = {
    dark: {
        button: {
            border: { color: colors.gs300 },
            icon: { color: colors.gs650 },
        },
        menu: {
            hr: { color: colors.gs700 },
            title: { color: colors.gs650 },
            background: { color: colors.gs800 },
            item: {
                background: { active: '#151718' },
                text: { color: colors.white },
            },
            text: { color: colors.secondary },
        },
    },
    light: {
        button: {
            border: { color: colors.gs300 },
            icon: { color: colors.gs650 },
        },
        menu: {
            hr: { color: colors.gs300 },
            title: { color: colors.gs650 },
            background: { color: colors.white },
            item: {
                background: { active: colors.gs200 },
                text: { color: colors.gs900 },
            },
            text: { color: colors.secondary },
        },
    },
};

export const sideMenu = {
    dark: {
        desktop: {
            background: { color: colors.gs900, active: '#151718' },
            border: { color: colors.white, active: colors.primary },
            icon: { color: colors.white },
        },
        mobile: {
            background: { color: colors.gs800, active: colors.gs900 },
            icon: { color: colors.white },
            dropdown: {
                menu: {
                    background: { color: colors.gs800, active: '#151718' },
                    text: { color: colors.secondary },
                },
                button: {
                    text: { color: colors.white },
                    icon: { color: colors.white },
                    border: { color: colors.gs700 },
                    background: { color: colors.gs800 },
                },
            },
        },
    },
    light: {
        desktop: {
            background: { color: colors.white, active: colors.gs200 },
            border: { color: colors.gs300, active: colors.gs900 },
            icon: { color: colors.gs900 },
        },
        mobile: {
            background: { color: colors.white, active: colors.gs200 },
            icon: { color: colors.gs900 },
            dropdown: {
                menu: {
                    background: { color: colors.white, active: '#f7fafc' },
                    text: { color: colors.secondary },
                },
                button: {
                    icon: { color: colors.gs650 },
                    text: { color: colors.gs900 },
                    border: { color: colors.transparent },
                    background: { color: colors.gs200 },
                },
            },
        },
    },
};
