import styled, { css } from 'styled-components';

export const SelectDropdown = styled.div`
    .open-menu-button {
        gap: 6px;
        padding: 8px 12px;
        border-radius: 8px;
        width: fit-content;

        span {
            overflow-x: scroll;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0.2px;
        }

        svg {
            min-width: 14px;
            min-height: 14px;
            width: 14px;
            height: 14px;
            color: #7E7E7E;
        }

        svg.calendar {
            min-width: 18px;
            min-height: 18px;
            width: 18px;
            height: 18px;
            color: #000000;
        }
    }
`;

export const MenuBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const MenuFooter = styled.div`
    ${({
        theme: {
            components: {
                selectDropdown: {
                    menu: {
                        hr,
                    },
                },
            },
        },
    }) => css`
        display: flex;
        flex-direction: column;
        border-top: 1px solid ${hr.color};
        padding-top: 4px;
    `}
`;

export const MenuItem = styled.button`
    ${({
        theme: {
            components: {
                selectDropdown: {
                    menu: {
                        item: { text, background },
                    },
                },
            },
        },
    }) => css`
        transition: all linear 0.05s;
        display: flex;
        gap: 6px;
        align-items: center;
        min-height: 34px;
        padding: 0px 8px;
        border-radius: 6px;
        color: ${text.color};
        background-color: inherit;
        border: none;
        cursor: pointer;
        
        span {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.2px;
        }

        svg {
            min-width: 18px;
            min-height: 18px;
            width: 18px;
            height: 18px;
        }

        :hover,
        &.active {
            background-color: ${background.active};
        }
    `}
`;