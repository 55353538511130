import React from 'react';

export const Play: React.FC = () => (
    <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.7066 6.99988L2.00009 1.94768V12.0521L10.7066 6.99988ZM11.8947 5.95553C12.0786 6.06085 12.2314 6.2129 12.3377 6.39629C12.444 6.57968 12.5 6.7879 12.5 6.99988C12.5 7.21186 12.444 7.42008 12.3377 7.60347C12.2314 7.78686 12.0786 7.93891 11.8947 8.04423L2.34961 13.5841C1.56956 14.0372 0.5 13.5196 0.5 12.5397V1.46001C0.5 0.480182 1.56956 -0.0374921 2.34961 0.415661L11.8947 5.95553Z"
            fill="#1A202C"
        />
    </svg>
);
