import React from 'react';
import { Container } from './styles';
import { LayoutProps } from '../interfaces/layout-default-props.interface';

const MessageLayout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <Container>
            <div id="content">{children}</div>
        </Container>
    );
};

export default MessageLayout;
