import React from 'react';
import { Select } from 'app/presentation/components/agent';
import useText from 'app/presentation/hooks/useText';

interface SelectProps {
    value: string;
    onChange: (value: string) => void;
}

const timeZones = [
    { node: 'GMT-11', value: '-11' },
    { node: 'GMT-10', value: '-10' },
    { node: 'GMT-9', value: '-9' },
    { node: 'GMT-8', value: '-8' },
    { node: 'GMT-7', value: '-7' },
    { node: 'GMT-6', value: '-6' },
    { node: 'GMT-5', value: '-5' },
    { node: 'GMT-4', value: '-4' },
    { node: 'GMT-3', value: '-3' },
    { node: 'GMT-2', value: '-2' },
    { node: 'GMT-1', value: '-1' },
    { node: 'GMT+0', value: '+0' },
    { node: 'GMT+1', value: '+1' },
    { node: 'GMT+2', value: '+2' },
    { node: 'GMT+3', value: '+3' },
    { node: 'GMT+4', value: '+4' },
    { node: 'GMT+5', value: '+5' },
    { node: 'GMT+6', value: '+6' },
    { node: 'GMT+7', value: '+7' },
    { node: 'GMT+8', value: '+8' },
    { node: 'GMT+9', value: '+9' },
    { node: 'GMT+10', value: '+10' },
    { node: 'GMT+11', value: '+11' },
    { node: 'GMT+12', value: '+12' },
];

export const TimeZoneSelect: React.FC<SelectProps> = ({ value, onChange }) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.timezone-select');
    return (
        <Select
            variant="white"
            value={value}
            onSelectChange={onChange}
            options={timeZones.map(tz => {
                const parsedTZ = { ...tz };
                parsedTZ.node = t(tz.node);
                return parsedTZ;
            })}
        ></Select>
    );
};
