import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .content-modal {
        background: #ffffff;
        border-radius: 16px;
        width: 98%;
        height: 100%;
        max-width: 420px;
        max-height: 598px;
        padding: 40px 24px 47px 24px;
        overflow: auto;

        header {
            text-align: right;

            svg {
                cursor: pointer;
            }
        }

        #title {
            margin-bottom: 40px;

            h1 {
                text-align: center;
                font-size: 1.5em;
                color: #1a202c;
                font-weight: 700;
                margin-top: 33px;
                margin-bottom: 12px;
            }

            p {
                text-align: center;
                font-weight: 500;
                font-size: 0.875em;
                color: #718096;
            }
        }

        ul {
            display: flex;
            gap: 4px;
            flex-direction: column;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            ::-webkit-scrollbar-thumb {
                background: #edf2f7;
                border-radius: 50px;
            }

            li {
                width: 100%;
                margin: 0;
                padding: 0;
                min-height: 56px;

                display: flex;
                justify-content: space-between;
                align-content: center;

                cursor: pointer;

                span {
                    color: #1a202c;
                    font-weight: 600;
                }

                .radio {
                    width: 24px;
                    height: 24px;
                    border: 1px solid #e2e8f0;
                    border-radius: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        display: none;
                    }

                    &.active {
                        background: #d0ff61;

                        svg {
                            display: block;
                        }
                    }
                }
            }
        }

        input {
            border-radius: 12px;
            border: 1px solid #e2e8f0;
        }

        .container-submit {
            display: flex;
            justify-content: center;
            gap: 5px;

            button {
                width: 164px;
                height: 48px;
                font-size: 0.875em;
                border-radius: 12px;
            }

            button[type='submit'] {
                background: #d0ff61;
                color: #1a202c;
            }

            button[type='button'] {
                border: 1px solid #a0aec0;
                border-radius: 12px;
                color: #a0aec0;
                background-color: #fff;
            }
        }
    }

    &.containerCancelDark {
        .content-modal {
            background: #1d1d1f;
            #title {
                h1 {
                    color: #fff;
                }
                p {
                    color: #a09fa5;
                }
            }

            ul {
                li {
                    span {
                        color: #a09fa5;
                    }
                }
            }
            .container-submit {
                button[type='button'] {
                    background: #1d1d1f;
                    color: #fff;
                }
            }
        }
    }
`;
