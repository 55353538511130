import styled from 'styled-components';
import { EditorContent } from '@tiptap/react';

export const StyledEditorContent = styled(EditorContent)`
    margin-top: 30px;
    padding-top: 30px;
    border: 0;
    border-top: 1px solid #e2e8f0;

    p {
        color: #000;
    }

    ::-webkit-scrollbar {
        //display: none;
    }

    p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: #a09fa5;
        height: 0;
        font-size: 16px;
    }

    blockquote {
        margin-left: 1rem;
        border-left: 3px solid rgba(#0d0d0d, 0.1);
        padding-left: 1rem;
    }
`;

export const FixedMenu = styled.div`
    display: flex;
    justify-content: center;
    background-color: black;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    gap: 7px;
    border-radius: 0.5rem;

    position: fixed;
    bottom: 1%;
    right: 449px;
    transform: translateX(50%);


    button {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    button:first-child {
        margin-left: 4px;
    }

    button:last-child {
        margin-right: 4px;
    }
`;

export const FixedButton = styled.button`
    display: flex;
    align-items: center;

    background-color: inherit;
    color: white;
    border: 0;
    padding: 0.5rem;
    width: fit-content;
    height: fit-content;

    border-radius: 0.5rem;

    :hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;
