import { RequestHeaders } from './types'
import { getToken } from 'services/api';
import { type AxiosRequestConfig } from 'axios';


export const axiosConfig = async (headers: RequestHeaders): Promise<AxiosRequestConfig> => {
    const token = await getToken();

    return {
        headers: {
            'Authorization': token,
            'X-Workspace-Id': headers.workspace_id,
            'X-Project-Id': headers.project_id,
            'X-Brand-Id': headers.brand_id,
        },
    };
}

export const applyQuerystringParams = (url: string, ...params: Record<string, any>[]): string => {
    const queryString = Object.entries(Object.assign({}, ...params) as Record<string, any>).map(([key, value]) => `${key}=${value.toString()}`).flat().join("&");

    return url.charAt(url.length) == "?" ? `${url}${queryString}` : `${url}?${queryString}`
}


export * from './types'
