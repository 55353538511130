import { api } from '../api';
import { Session, PortalSession } from '../../interfaces/plans.interface';

export class PlansService {
    private readonly path = '/plans';

    async getAll() {
        const { data } = await api.get(`${this.path}`);

        return data;
    }

    async createSession(request: Session) {
        const { data } = await api.post('/billing/checkout-session', request);

        return data;
    }

    async createPortalSession(request: PortalSession) {
        const { data } = await api.post('/billing/portal-session', request);

        return data;
    }

    async finishTrial(request: { workspace_id: number }) {
        const { data } = await api.post('/billing/finish-trial', request);

        return data;
    }

    async createChurnkeySession() {
        const { data } = await api.get('/billing/churn-key');

        return data;
    }
}
