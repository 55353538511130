import React from "react";
import { SliderModal } from '../SliderModal/SliderModal';
import { IoCloseSharp } from 'react-icons/io5';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { FormattedMessage, useIntl } from 'react-intl';
import { routeTranslate as t } from 'utils/intl';
import { Memory, MemoryContent, MemoryContentStatus } from 'services/brand/memories/types';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import { TextEditor } from '../TextEditor';
import { Ellipsis, Select, Option, PublishStatus, Details } from './styles';
import { useSnackbar } from 'notistack';
import { IconPencil, IconEyeOff } from '@tabler/icons-react';
import { handleLastEdit } from "../../utils";

import { CollectionIcons } from "services/collections/types";
import useLanguage from "app/presentation/hooks/useLanguage";

type ModalViewMemoryProps = {
    animate: boolean;

    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setAnimateSlide: React.Dispatch<React.SetStateAction<boolean>>;

    memory: Memory;
    setMemory: React.Dispatch<React.SetStateAction<Memory | undefined>>;

    handleShowEditModal: () => void;
    setSelectedMemory: React.Dispatch<React.SetStateAction<Memory | undefined>>;
    setSelectedEditLanguage: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export const ModalViewMemory: React.FC<ModalViewMemoryProps> = (props) => {
    const { setShowModal, animate, memory, setMemory } = props;
    const { activeBrand } = useUserConfig();
    const intl = useIntl();
    const { fetchMemory, fetchMemoryContentsPublishStatuses, updateMemory, setLoading, fetchMemories } = useMemoriesContext();
    const { enqueueSnackbar: toast } = useSnackbar();
    const { getLanguageName } = useLanguage();

    const [selectedLanguage, setSelectedLanguage] = React.useState<number | undefined>(activeBrand?.brand_config.default_language)
    const [selectedContent, setSelectedContent] = React.useState<MemoryContent | undefined>();
    const [contentsPublishStatuses, setContentsPublishStatuses] = React.useState<MemoryContentStatus[] | undefined>();
    const [edit, setEdit] = React.useState<boolean>();

    // this can be a hook eg 'useFirstRender'
    //const isInitialRender = React.useRef(true);

    React.useEffect(() => {
        if (!memory || !selectedLanguage) return

        // avoid running on first render, but not satisfied with this approach
        /*if (isInitialRender) {
            isInitialRender.current = false;
            return;
        }*/

        const setMem = async () => {
            try {
                const mem = await fetchMemory(memory.id, selectedLanguage);
                setMemory(mem);
            } catch (e) {
                toast(<FormattedMessage id="commons.something-went-wrong" />, { variant: "error" })
            }

            try {
                const statuses = await fetchMemoryContentsPublishStatuses(memory.id);
                setContentsPublishStatuses(statuses);
            } catch (e) {
                toast(<FormattedMessage id="commons.something-went-wrong" />, { variant: "error" })
            }
        }

        setMem()
    }, [selectedLanguage]);

    React.useEffect(() => {
        memory.contents
            ? setSelectedContent(memory.contents.find(elem => elem.language_id == selectedLanguage))
            : setSelectedContent(undefined);
    }, [memory]);

    // this may be a provider function
    const unpublishMemory = async () => {
        if (!selectedContent || !activeBrand) {
            return
        }

        if (selectedContent.publish_status == "draft") return;

        setLoading(true);

        const cont = selectedContent.content !== "" ? selectedContent.content : ""

        try {
            const updatedMemory = await updateMemory(memory.id, [
                {
                    title: memory.title,
                    description: memory.description,
                    content: cont,
                    language_id: selectedContent.language_id,
                    publish_status: "draft"
                },
            ])

            if (updatedMemory) {
                setMemory(updatedMemory);
                const statuses = await fetchMemoryContentsPublishStatuses(updatedMemory.id);
                setContentsPublishStatuses(statuses);
            }

            toast(<FormattedMessage id="brand-center.knowledge.modal-view.unpublish.success" />, { variant: "success" })

        } catch (err) {
            err instanceof Error ?

                toast(<FormattedMessage id="brand-center.knowledge.modal-view.unpublish.error" />, { variant: "error" }) :
                toast(<FormattedMessage id="commons.something-went-wrong" />, { variant: "error" })
        } finally {
            setLoading(false);
        }
    }

    const publishMemory = async () => {
        if (!selectedContent || !activeBrand) {
            return
        }

        if (selectedContent.publish_status == "publish") return;

        setLoading(true);

        const content = selectedContent.content !== "" ? selectedContent.content : ""

        try {
            const updatedMemory = await updateMemory(memory.id, [
                {
                    title: memory.title,
                    description: memory.description,
                    content: content,
                    language_id: selectedContent.language_id,
                    publish_status: "published"
                },
            ])

            if (updatedMemory) {
                setMemory(updatedMemory);
                const statuses = await fetchMemoryContentsPublishStatuses(updatedMemory.id);
                setContentsPublishStatuses(statuses);
            }

            toast(<FormattedMessage id="brand-center.knowledge.modal-view.publish.success" />, { variant: "success" })

        } catch (err) {
            err instanceof Error ?
                toast(<FormattedMessage id="brand-center.knowledge.modal-view.publish.error" />, { variant: "error" }) :
                toast(<FormattedMessage id="commons.something-went-wrong" />, { variant: "error" })
        } finally {
            setLoading(false);
        }
    }

    const handleCollectionIcon = (memory: Memory): React.ReactElement => {
        if (!memory.brand_memory_group) return <IconEyeOff stroke={1.5} size={20} color="black" />;

        const CollectionIcon = CollectionIcons[memory.brand_memory_group.icon] as React.ElementType;
        if (!CollectionIcon) return <IconEyeOff stroke={1.5} size={20} />

        return <CollectionIcon stroke={1.5} size={20} color="black" />;
    }


    return (
        <SliderModal
            animateSlide={animate}
            onExit={() => {
                setShowModal(false);
                fetchMemories();
                if (edit) {
                    props.setSelectedMemory(memory);
                    props.setSelectedEditLanguage(selectedLanguage);
                    props.handleShowEditModal();
                }
            }}
        >
            <div>
                <div className="slide-content">
                    <div className="slide-header">
                        <div className="slide-title">
                            <span className={memory.title === "" ? "no-text" : ""}>
                                {memory.title ?
                                    memory.title :
                                    <FormattedMessage id="brand-center.knowledge.modal-view.no-title" />
                                }
                            </span>
                        </div>
                        <button
                            className="slide-btn unpublish"
                            type="submit"
                            onClick={(selectedContent && selectedContent.publish_status == "published") ?
                                () => unpublishMemory() :
                                () => publishMemory()
                            }
                        >
                            {selectedContent && selectedContent.publish_status == "published" && (
                                <IconEyeOff stroke={1.5} size={20} />
                            )}
                            <span>
                                {selectedContent && selectedContent.publish_status == "published" ?
                                    (<FormattedMessage id="brand-center.knowledge.modal-edit.unpublish" />) :
                                    (<FormattedMessage id="brand-center.knowledge.modal-edit.publish" />)
                                }

                                {activeBrand?.supported_languages && activeBrand.supported_languages.map(elem => (
                                    elem.language_id == selectedLanguage && getLanguageName(elem.language)
                                ))}
                            </span>
                        </button>

                        <button
                            className="slide-btn edit"
                            onClick={() => {
                                setEdit(true);
                                props.setAnimateSlide(false);
                            }}
                        >
                            <IconPencil stroke={1.5} size={20} />

                            <span> <FormattedMessage id="brand-center.knowledge.table.actions.edit" /> </span>
                        </button>

                        <button type="reset" className="slide-btn close" onClick={() => props.setAnimateSlide(false)}>
                            <IoCloseSharp size={20} />
                        </button>
                    </div>

                    <div className="description-input">
                        <span className={memory.title === "" ? "no-text" : ""}>
                            {memory.description ?
                                memory.description :
                                <FormattedMessage id="brand-center.knowledge.modal-view.no-description" />
                            }
                        </span>
                    </div>


                    <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                        <Select
                            value={selectedLanguage}
                            onChange={e => setSelectedLanguage(e.target.value as number)}
                            MenuProps={{ PaperProps: { sx: { backgroundColor: '#f1f1f1' } } }}
                        >
                            {activeBrand?.supported_languages && activeBrand.supported_languages.map(elem => (
                                <Option
                                    selected={elem.language_id == activeBrand.brand_config.default_language && true}
                                    value={elem.language.id}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <Ellipsis className={contentsPublishStatuses &&
                                            contentsPublishStatuses.find(e => e.language_id == elem.language_id)?.publish_status}
                                        />
                                        <span>
                                            {getLanguageName(elem.language)}
                                            {elem.language_id == activeBrand.brand_config.default_language && (
                                                <>
                                                    {` (${intl.formatMessage({ id: "language.default" })})`}
                                                </>
                                            )}
                                        </span>

                                    </div>
                                </Option>
                            ))}
                        </Select>

                        {selectedContent && selectedContent.publish_status && (
                            <PublishStatus className={selectedContent.publish_status} >
                                <span> <FormattedMessage id={`memory.${selectedContent.publish_status}`} /> </span>
                            </PublishStatus>
                        )}

                    </div>

                    <Details>
                        <span className="details-header">
                            <FormattedMessage id="brand-center.knowledge.modal-view.details" />
                        </span>

                        <div className="details-body">
                            {/*<span>URL Pública</span>*/}
                            <div className="details-item">
                                <span> <FormattedMessage id="brand-center.knowledge.table.collection" /> </span>
                                <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                    {handleCollectionIcon(memory)}

                                    <span>{(memory.brand_memory_group &&
                                        memory.brand_memory_group.translations && memory.brand_memory_group.translations[0]) ? (
                                        memory.brand_memory_group.translations[0].title
                                    ) : (
                                        "Nenhuma coleção"
                                    )}
                                    </span>
                                </div>

                            </div>
                            <div className="details-item">
                                <span> <FormattedMessage id="brand-center.knowledge.table.last-edit" /> </span>

                                <span>
                                    {memory.contents && memory.contents[0] ? handleLastEdit(new Date(memory.contents[0].updated_at), intl) : "-"}
                                </span>
                            </div>
                            <div className="details-item">

                                <span> <FormattedMessage id="brand-center.knowledge.modal-view.last-edit-by" /> </span>
                                <span>{memory.contents && memory.contents[0] ? memory.contents[0].last_edited_by : "-"}</span>
                            </div >
                            <div className="details-item">
                                <span> <FormattedMessage id="brand-center.knowledge.modal-view.visualizations" /> </span>
                                <span>{memory.access_count}</span>
                            </div>
                        </div >

                    </Details >

                    {selectedContent && selectedContent.content !== "" ?
                        <TextEditor
                            initialContent={selectedContent.content}
                            content={selectedContent.content}
                            editable={false}
                            placeholder={t(intl, "brand-center.knowledge.modal-new.editor-placeholder")}
                        /> :
                        <p
                            className="no-text"
                            style={{
                                marginTop: '30px',
                                paddingTop: '30px',
                                border: '0',
                                borderTop: '1px solid #e2e8f0',
                            }}
                        >
                            <FormattedMessage id="brand-center.knowledge.modal-view.no-content" />
                        </p>
                    }
                </div >
            </div >
        </SliderModal >
    );
}
