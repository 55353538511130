import React from 'react';

export const ArrowHistory: React.FC = () => (
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.05 0.75H0.75L4.95 6L0.75 11.25H7.05L11.25 6L7.05 0.75Z"
            stroke="#A0AEC0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
