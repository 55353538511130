import { api, getToken } from '../api';
import {
    CreateDocument,
    ExtraFields,
    GetDocuments,
    SendCompose,
    TitleDocument,
    WriteDocument,
} from '../../interfaces/documents.interface';
import { generateUUID } from '../../utils/uuid';

export class DocumentsService {
    private readonly path = '/documents';

    async getAll(request: GetDocuments) {
        const pageCondition = request.page ?? 1;
        const limitCondition = request.limit ?? 6;
        const search = request.search ?? undefined;

        const { data } = await api.get(
            `${this.path}?projectID=${
                request.projectId
            }&page=${pageCondition}&limit=${limitCondition}${
                search ? `&filter=${search}` : ''
            }`,
        );

        return data;
    }

    async getOne(id: number) {
        const { data } = await api.get(`${this.path}/${id}`);

        return data;
    }

    async getCompose(
        websocket: WebSocket,
        request: SendCompose,
        workspace_id: number,
        project_id: number,
    ) {
        const token = await getToken();

        websocket.send(
            JSON.stringify({
                authorization: {
                    token: token,
                    workspace_id: workspace_id,
                    project_id: project_id,
                },
                input: request,
            }),
        );
    }

    async write(write: WriteDocument) {
        const { data } = await api.post(`${this.path}/${write.id}/write`, {
            content: write.content,
        });

        return data;
    }

    async title(title: TitleDocument) {
        const { data } = await api.patch(`${this.path}/${title.id}/title`, {
            title: title.title,
        });

        return data;
    }

    async getJwt(idDocument: string | undefined) {
        if (!idDocument) return;

        const { data } = await api.get(`${this.path}/${idDocument}/jwt`);

        return data;
    }

    async create(create: CreateDocument) {
        const { data } = await api.post(this.path, create);

        return data;
    }

    async extraFields(fields: ExtraFields, id: number) {
        const { data } = await api.patch(
            `${this.path}/${id}/extra-fields`,
            fields,
        );

        return data;
    }

    async delete(id: number) {
        const { data } = await api.delete(`${this.path}/${id}`);

        return data;
    }

    async copy(
        workspaceId: number,
        documentData: Array<{ id: number | undefined; title: string }>,
    ) {
        const { data } = await api.post(`${this.path}/clone`, {
            workspace_id: workspaceId,
            documents: documentData,
        });

        return data;
    }

    getSocket = () => {
        const uuid = generateUUID();

        return new WebSocket(`${process.env.REACT_APP_WEBSOCKET}/${uuid}`);
    };
}
