import React from 'react';
import { ContainerNotification } from './styles';
import { Button } from 'app/presentation/components';
import { FiUsers } from 'react-icons/fi';
import { BsCheck2All } from 'react-icons/bs';
import useNotifications from 'app/presentation/hooks/useNotifications';
import { Notification } from '../../../interfaces/notifications.interface';
import { timeDifference } from '../../../utils/time';
import NotificationEmpty from 'assets/images/svg/notification-empty.svg';
import NotificationEmptyDark from 'assets/images/svg/notification-empty-dark.svg';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

function getTitle(notification: Notification) {
    switch (notification.type) {
        case 'workspace_invitation':
            return 'Novo convite para uma workspace';
        default:
            return 'Sem título';
    }
}

function getDescription(notification: Notification) {
    switch (notification.type) {
        case 'workspace_invitation':
            return `Você foi convidado por ${notification.data?.InvitedBy} para participar de sua workspace “${notification.data?.Workspace}”`;
        default:
            return '';
    }
}

const getIcon = (notification: Notification) => {
    switch (notification.type) {
        case 'workspace_invitation':
            return <FiUsers size={20} style={{ color: '#000' }} />;
        default:
            return '';
    }
};

const Notifications: React.FC = () => {
    const { notifications, answerInvitation, isRead } = useNotifications();
    const intl = useIntl();

    const { darkmode } = useDarkMode();

    const handleAnswerInvitation = async (
        token: string,
        answer: string,
        id: number,
    ) => {
        await answerInvitation({
            token,
            answer,
        });

        await isRead(id);
    };

    const getButtons = (notification: Notification) => {
        switch (notification.type) {
            case 'workspace_invitation':
                return (
                    <>
                        {!['accepted', 'declined'].includes(
                            notification.Invite.status,
                        ) && (
                            <>
                                <Button
                                    onClick={() =>
                                        handleAnswerInvitation(
                                            notification.data.InviteToken,
                                            'declined',
                                            notification.id,
                                        )
                                    }
                                >
                                    {routeTranslate(
                                        intl,
                                        'notifications.decline',
                                    )}
                                </Button>
                                <Button
                                    onClick={() =>
                                        handleAnswerInvitation(
                                            notification.data.InviteToken,
                                            'accepted',
                                            notification.id,
                                        )
                                    }
                                >
                                    {routeTranslate(
                                        intl,
                                        'notifications.accept',
                                    )}
                                </Button>
                            </>
                        )}
                    </>
                );
                break;
            default:
                return '';
        }
    };

    return (
        <ContainerNotification className="box-dark-mode">
            <header className={darkmode ? 'usingDark' : ''}>
                <h1>{routeTranslate(intl, 'notifications.title')}</h1>
                {notifications && notifications.length > 0 && (
                    <span>
                        <BsCheck2All size={18} />{' '}
                        {routeTranslate(intl, 'notifications.markAsRead')}
                    </span>
                )}
            </header>
            <div id="body">
                {notifications?.map(notification => {
                    return (
                        <div key={notification.id} className="notification">
                            <div className="icon">{getIcon(notification)}</div>
                            <div className="content-notification">
                                <h1>{getTitle(notification)}</h1>
                                <p>{getDescription(notification)}</p>
                                <p className="time">
                                    {timeDifference(
                                        new Date(),
                                        new Date(notification.created_at),
                                    )}
                                </p>
                                <div className="buttons">
                                    {getButtons(notification)}
                                </div>
                            </div>
                        </div>
                    );
                })}
                {notifications?.length === 0 && (
                    <div className="notification">
                        <div id="empty">
                            <img
                                src={
                                    darkmode
                                        ? NotificationEmptyDark
                                        : NotificationEmpty
                                }
                            />
                            <h1>
                                {routeTranslate(intl, 'notifications.empty')}
                            </h1>
                            <p>
                                {routeTranslate(
                                    intl,
                                    'notifications.empty.description',
                                )}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </ContainerNotification>
    );
};

export default Notifications;
