import React from 'react';
import * as S from './styles';
import { UseFormRegisterReturn } from 'react-hook-form';
import useText from 'app/presentation/hooks/useText';

interface Validation {
    validate: (value: string) => boolean;
    message: string;
}

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    registerValues?: UseFormRegisterReturn;
    autoResize?: boolean;
    customValidation?: Validation;
}

export const TextArea: React.FC<Props> = ({
    registerValues,
    customValidation,
    required,
    placeholder = '',
    autoResize = false,
    ...props
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.input');

    const {
        onChange: registerOnChange = undefined,
        ref: registerRef,
        ...registerRest
    } = registerValues ? registerValues : { ref: undefined };

    const ref = React.useRef<HTMLTextAreaElement>(null);
    React.useEffect(() => {
        const el = ref.current;
        if (!el || !registerRef) {
            return;
        }

        registerRef(el);
    }, [registerRef, ref.current]);

    const validateValue = () => {
        const el = ref.current;
        if (!el) {
            return;
        }

        if (required && !el.value) {
            el.setCustomValidity(t('required'));
        } else if (customValidation && !customValidation.validate(el.value)) {
            el.setCustomValidity(customValidation.message);
        } else {
            el.setCustomValidity('');
        }
    };

    React.useEffect(() => {
        validateValue();

        if (autoResize) {
            autoResizeTextarea();
        }
    }, [ref.current, required, customValidation, props.value]);

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        if (registerOnChange) {
            registerOnChange(event);
        }

        if (props.onChange) {
            props.onChange(event);
        }

        validateValue();
    };

    const autoResizeTextarea = () => {
        const textarea = ref.current;
        if (!textarea) {
            return;
        }
        if (textarea.value == '' && placeholder == '') {
            textarea.style.height = '21px';
        } else if (textarea.value == '') {
            textarea.style.height = 'auto';
            textarea.value = placeholder;
            textarea.style.height = textarea.scrollHeight + 'px';
            textarea.value = '';
        } else {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };

    const onInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
        if (props.onInput) {
            props.onInput(event);
        }
        if (autoResize) {
            autoResizeTextarea();
        }
    };

    return (
        <S.TextArea
            {...registerRest}
            {...props}
            ref={ref}
            placeholder={placeholder}
            onChange={onChange}
            onInput={onInput}
        ></S.TextArea>
    );
};
