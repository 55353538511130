import styled from 'styled-components';

export const ContainerModalLib = styled.div`
    .content-lib-model {
        background: #ffffff;
        width: 100%;
        overflow: auto;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            background: #edf2f7;
            border-radius: 50px;
        }

        header {
            display: flex;
            align-items: center;
            //height: 72px;
            padding: 0 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            #title {
                display: flex;
                align-items: center;
                width: 50%;

                div {
                    display: flex;
                    align-items: center;
                }
            }

            #back {
                min-width: 40px;
                min-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
            }

            #change-layout {
                display: none;
            }

            svg {
                cursor: pointer;
            }

            input {
                font-size: 1.125em;
                margin-right: 37px;
                border-radius: 0;
                border: 1px solid #fff;
                padding-right: 16px;
                padding-left: 16px;

                &:focus {
                    border: 1px solid #1a202c;
                }
            }

            .icons {
                display: flex;
                align-items: center;
                margin-left: auto;
                gap: 10px;

                .icon-option {
                    display: block;
                    width: 70px;
                    font-size: 0.75em;
                    color: #a0aec0;
                }

                @-webkit-keyframes rotating /* Safari and Chrome */ {
                    from {
                        -webkit-transform: rotate(0deg);
                        -o-transform: rotate(0deg);
                        transform: rotate(0deg);
                    }
                    to {
                        -webkit-transform: rotate(360deg);
                        -o-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }
                @keyframes rotating {
                    from {
                        -ms-transform: rotate(0deg);
                        -moz-transform: rotate(0deg);
                        -webkit-transform: rotate(0deg);
                        -o-transform: rotate(0deg);
                        transform: rotate(0deg);
                    }
                    to {
                        -ms-transform: rotate(360deg);
                        -moz-transform: rotate(360deg);
                        -webkit-transform: rotate(360deg);
                        -o-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }

                .icon {
                    margin-right: 9px;
                    width: 40px;
                    height: 40px;
                    border: 1px solid #e2e8f0;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg path {
                        stroke: #a0aec0;
                    }

                    &.save {
                        border: 0;
                        width: 26px;
                        height: 26px;
                    }

                    &.animation-flip {
                        -webkit-animation: rotating 2s linear infinite;
                        -moz-animation: rotating 2s linear infinite;
                        -ms-animation: rotating 2s linear infinite;
                        -o-animation: rotating 2s linear infinite;
                        animation: rotating 2s linear infinite;
                        border: 0;
                    }
                }
            }
        }

        .form {
            .fields {
                background: #fafafa;
                padding-top: 19px;
                padding-bottom: 29px;

                .feature-header {
                    display: none;
                }
            }

            .feature {
                display: none;
            }

            input {
                border-radius: 8px;
            }
        }

        #submit {
            position: fixed;
            z-index: 99;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 24px;
            height: 84px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            #shortcut {
                display: none;
            }

            button {
                width: 100%;
                height: 48px;
                font-size: 0.875em;
                border-radius: 8px;
            }
        }
    }

    .toolbar {
        background-color: transparent;
        margin-bottom: 0 !important;
        @media (max-width: 64em) {
            margin-top: 1rem !important;
        }
        padding: 0 24px;

        &.first {
            margin-bottom: 0 !important;
            border-bottom: 0;
        }

        .header {
            padding-top: 16px;
            padding-bottom: 16px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 8px;

            #clean {
                width: 147px;
                height: 34px;
                border: 1px solid #e2e8f0;
                border-radius: 6px;
                font-size: 0.875em;
                background: #ffffff;
                display: flex;
                flex-direction: row-reverse;
                padding: 0;

                #icon {
                    margin-left: 0 !important;
                    margin-right: 4px;
                }
            }

            #quatity {
                display: flex;
                label,
                input {
                    position: relative;
                    display: block;
                    width: 130px;
                    box-sizing: border-box;
                }

                label::after {
                    content: attr(data-domain);
                    position: absolute;
                    top: 33%;
                    left: 35%;
                    font-size: 0.875rem;
                    display: block;
                    color: #a09fa5;
                    font-weight: bold;
                }

                > div {
                    min-width: 70px;
                    max-width: 70px;
                    min-height: 48px;
                    max-height: 48px;
                    margin: 0;

                    input {
                        min-height: 48px;
                        max-height: 48px;
                    }
                }

                button {
                    margin-left: 12px;
                    width: 95px;
                    min-height: 48px;
                    padding: 0;
                    font-size: 0.875em;
                }
            }
        }

        .options {
            padding: 9px 0;
            display: flex;
            align-items: center;

            @media (max-width: 64em) {
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            .link {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 108px;
                height: 32px;
                border-radius: 8px;
                font-size: 0.75em;
                font-weight: 600;
                margin-right: 19px;
                cursor: pointer;
                color: #718096;

                &.active {
                    background: #f7fafc;
                    color: #1a202c;
                }
            }

            .option {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 108px;
                height: 32px;
                border-radius: 8px;
                font-size: 0.75em;
                font-weight: 600;
                color: #718096;
                cursor: pointer;

                &.active {
                    background: #f7fafc;
                    color: #1a202c;
                }
            }

            .option:last-of-type {
                margin-left: auto;
            }
        }
    }

    .editor {
        overflow: hidden !important;
    }

    #appetizer {
        background-color: #fff;
        padding: 30px 27px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-bottom: 0 !important;
        position: relative;

        .box {
            cursor: pointer;
            border: 1px solid #edf2f7;
            border-radius: 16px;
            padding: 24px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &.entry {
                min-height: auto;
            }

            p {
                font-size: 0.875em;
                line-height: 150%;
                font-weight: 500;
                white-space: pre-line;
            }
        }

        .footer {
            margin-top: 32px;
            display: flex;
            justify-content: space-between;

            span {
                font-size: 0.75em;
                font-weight: 500;
                color: #718096;
            }

            .option-box {
                display: flex;
                align-items: center;
                gap: 16px;

                div {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                div:nth-last-of-type(1) svg,
                div:nth-last-of-type(2) svg {
                    path {
                        stroke: #a0aec0;
                    }
                }
            }
        }
    }

    #title {
        width: 40%;
        max-width: 40%;
    }

    #change-layout {
        display: flex !important;
        width: 20%;
        max-width: 20%;
        justify-content: center;
        align-items: center;

        div:first-of-type {
            border-radius: 6px 0px 0px 6px;
        }

        div:last-of-type {
            border-radius: 0px 6px 6px 0px;
        }

        div {
            width: 40px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #d0ff61;
            border: 1px solid #d0ff61;
        }
    }

    .icons {
        width: 40%;
        max-width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .form {
        display: flex;
        flex-direction: column;

        > div {
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            ::-webkit-scrollbar-thumb {
                background: #edf2f7;
                border-radius: 50px;
            }
        }
    }

    .fields {
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        position: relative;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .feature-header {
            display: flex !important;
            align-items: center;
            min-height: 124px;
            padding: 20px 25px;
            background-color: #fff;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            h1 {
                font-size: 1.125em;
                margin-bottom: 8px;
                font-weight: 700;
            }

            p {
                color: #718096;
                font-size: 0.875em;
                font-weight: 500;
            }

            #back {
                margin-left: -1rem;
                margin-right: 20px;
            }

            .icon {
                min-width: 50px;
                min-height: 50px;
                border: 1px solid #e2e8f0;
                border-radius: 1000px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1em;
                margin-right: 13px;

                overflow: hidden;

                img {
                    min-width: 50px;
                    min-height: 50px;
                }
            }
        }

        .textAreaContainer {
            display: flex;
            flex-direction: column;

            @media (max-width: 64em) {
                margin-bottom: 15px;
            }

            .titleTextArea {
                font-size: 0.875rem;
                color: #000;
                font-weight: bold;
                margin-bottom: 15px;
                @media (max-width: 64em) {
                    margin-top: 15px;
                }
            }

            .textAreaContent {
                width: '100%';
                min-height: 112px;
                border-radius: 8px;
                border: 1px solid #e2e8f0;
                background: #fff;
                padding: 16px;
                color: #000;

                /* body/medium/medium */
                font-family: Manrope;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 21px */
                letter-spacing: 0.2px;

                .highlightedText {
                    width: fit-content;
                    padding: 8px 6px;
                    border-radius: 6px;
                    background: #e8edff;
                    color: #436cff;
                    text-align: right;

                    /* body/medium/semibold */
                    font-family: Manrope;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 300%; /* 21px */
                    letter-spacing: 0.2px;
                }
            }
            .btn {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                margin-top: 8px;
            }

            .flexStartText {
                justify-content: flex-start;
            }
        }
    }

    #submit {
        position: absolute;
        bottom: 0;
        z-index: 99;

        button {
            width: 100%;
            min-width: 279px;
            min-height: 60px;
            font-size: 1em;
            border-radius: 8px;
        }

        #shortcut {
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 32px;
            background: #98df50;
            border-radius: 8px;
            padding: 8px;
            margin-left: 10px;

            span {
                font-size: 0.75em;
                font-weight: bold !important;
            }
        }

        min-width: 50%;
        max-width: 50%;
        left: inherit;
        right: 0;
        border: 0;

        button {
            width: 100%;
            min-width: 279px;
            max-width: 279px;
            height: 48px;
            font-size: 1em;
            border-radius: 8px;
        }
    }

    .content-modal {
        padding-bottom: 0 !important;

        header {
            input {
                font-size: 20px;
                font-weight: 500;
            }
        }
    }

    @media (min-width: 64em) {
        .toolbar {
            background: #fff;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .content-modal {
            height: 100vh;
        }
        #appetizer {
            overflow: auto;
            height: calc(100% - 50px);
            max-height: calc(100% - 50px);

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            ::-webkit-scrollbar-thumb {
                background: #edf2f7;
                border-radius: 50px;
            }
        }

        .form {
            flex-direction: row;
        }

        .fields {
            max-width: 50%;
        }

        .form {
            > div {
                flex: 1;
                height: 100vh;
            }
        }
    }

    &.containerModalLibDark {
        .active,
        .editor {
            background: #000;
        }
        .fields {
            border-color: #3a3a3a !important;
        }
        .feature-header {
            background: #000;
            border-bottom: 1px solid #3a3a3a;
            p {
                color: #cccccc;
            }
        }
        .fieldsDark {
            background: #000;
            textarea {
                background: #1d1d1f;
                color: #fff;
                border-color: #1d1d1f;

                &:focus {
                    border: 1px solid #fff !important;
                }
            }
            input {
                background: #1d1d1f;
                color: #fff;
                border-color: #1d1d1f;

                &:focus {
                    border: 1px solid #fff !important;
                }
            }
        }

        .textAreaContainer {
            .textAreaContent {
                background: #3a3a3a;
                border: none;
                span {
                    color: #fff !important;
                }
                .highlightedText {
                    background: #000;
                }
            }
        }

        .toolbar {
            background: #000;
            border-top: 1px solid #3a3a3a;
            border-bottom: 1px solid #3a3a3a;

            button[type='reset'] {
                background: #000 !important;
                color: #fff;
                border: 1px solid #e2e8f0;
            }

            button {
                background: #d0ff61 !important;
                color: #000;
                border: 1px solid #d0ff61;
            }

            #quatity {
                input {
                    background: #1d1d1f;
                    color: #fff;
                    border-color: #1d1d1f;

                    &:focus {
                        border: 1px solid #fff !important;
                    }
                }
            }
        }

        #appetizer {
            background: #000;
        }

        .box {
            background: #1d1d1f;
            border-color: #1d1d1f !important;
        }

        .form {
            .fields {
                background: #000;
            }
        }
    }
`;

export const Empty = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 442px;
    margin: 0 auto;

    .container-submit {
        display: flex;
        justify-content: center;
        gap: 12px;

        button {
            width: 164px;
            height: 48px;
            font-size: 0.875em;
            border-radius: 8px;

            :first-child {
                background: #d0ff61;
                color: #1a202c;
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                padding: 0;

                svg {
                    margin-right: 6px;
                    margin-left: 0px !important;
                }
            }
        }
    }

    .image {
        width: 100%;
        max-width: 218px;
    }

    h1 {
        font-size: 2em;
        font-weight: 700;
        margin-top: 14px;
        margin-bottom: 10px;
        text-align: center;
    }

    p {
        font-size: 0.875em;
        color: #718096;
        text-align: center;
        margin-bottom: 30px;
    }
`;

export const FieldsTemplate = styled.div`
    /* height: calc(100vh - 124px - 81px); */

    width: 100%;

    .tone-section {
        p {
            margin-bottom: 4px;
            font-size: 0.875rem;
            color: #000;
            font-weight: 700;
        }

        .an-simple-select__option.an-simple-select__option--is-selected {
            background-color: #fff;
            color: #000;
            .checked-icon {
                display: block;
            }
        }

        .an-simple-select__control {
            p {
                font-weight: 500 !important;
            }
        }
        .toneOptions {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
                display: flex;
                align-items: center;
                p {
                    margin-left: 8px;
                    font-weight: 500;
                    font-size: 0.875rem;
                }

                span {
                    border-radius: 10px;
                    background: #d0ff61;
                    font-size: 0.75rem;
                    font-weight: 600;
                    padding: 0 8px;
                    height: 18px;
                    margin-left: 8px;
                }
            }
        }
    }

    > div,
    form > div {
        padding-right: 24px;
        padding-left: 24px;
    }

    > div:last-of-type {
        margin-bottom: 0;
    }

    @media (min-width: 64em) {
        height: calc(100vh - 124px - 81px);

        > div,
        form > div {
            margin-bottom: 12px;
        }

        overflow: auto !important;
        padding-top: 40px;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            background: #edf2f7;
            border-radius: 50px;
        }

        > div,
        form > div {
            margin-bottom: 32px !important;
        }
    }
`;
