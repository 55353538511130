import styled, { css } from 'styled-components';

export const RadioGroup = styled.div`
    ${({
        theme: {
            components: {
                radioGroup: { circle, border },
            },
        },
    }) => css`
        width: fit-content;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .radio-button {
            display: flex;
            gap: 8px;
            align-items: center;
            border: none;
            background-color: inherit;
            cursor: pointer;
            .circle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                border: 1px solid ${border.color};
                transition: all linear 0.05s;
                border-radius: 9px;

                img {
                    transition: all linear 0.05s;
                    opacity: 0%;
                }
            }
            .radio-input {
                opacity: 0;
                z-index: -1;
            }
            :hover,
            &.active {
                .circle {
                    border-color: ${circle.active};
                    background-color: ${circle.active};
                    img {
                        opacity: 100%;
                    }
                }
            }
        }
    `}
`;
