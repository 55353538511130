import React from 'react';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import Tooltip from '@mui/material/Tooltip';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

type IconProps = {
    hideTooltip?: boolean;
    color?: string;
};

export const Download: React.FC<IconProps> = ({
    hideTooltip = true,
    color = '#A0AEC0',
}) => {
    const intl = useIntl();
    const { darkmode } = useDarkMode();

    return (
        <Tooltip
            title={routeTranslate(intl, 'tooltip.download')}
            disableHoverListener={hideTooltip}
            placement="top"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.20996 1.6875V12.375M4.70996 7.875L9.20996 12.375L13.71 7.875"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M2.45996 15.9375C2.45996 15.7386 2.54886 15.5478 2.70709 15.4072C2.86532 15.2665 3.07993 15.1875 3.30371 15.1875H15.1162C15.34 15.1875 15.5546 15.2665 15.7128 15.4072C15.8711 15.5478 15.96 15.7386 15.96 15.9375C15.96 16.1364 15.8711 16.3272 15.7128 16.4678C15.5546 16.6085 15.34 16.6875 15.1162 16.6875H3.30371C3.07993 16.6875 2.86532 16.6085 2.70709 16.4678C2.54886 16.3272 2.45996 16.1364 2.45996 15.9375Z"
                    fill={color}
                />
            </svg>
        </Tooltip>
    );
};
