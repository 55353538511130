import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ContainerFull, Form } from './styles';
import { Button, Container, Input, Footer } from 'app/presentation/components';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { routeTranslate } from 'utils/intl';
import Header from '../../../app/presentation/components/forgot-password/Header';
import sendEmailIcon from 'assets/images/svg/send-email.svg';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { UsersService } from 'services/users';
import { auth } from 'config/firebaseSetup';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useSnackbar } from 'notistack';
import { sleep } from 'utils/sleep';

const usersService = new UsersService();

const SendEmail: React.FC = () => {
    const navigate = useNavigate();
    const [send, setSend] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [isCodeValid, setIsCodeValid] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const [loadedInfos, setLoadedInfos] = React.useState(false);
    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');
    const oobCode = searchParams.get('oobCode') as string;
    const { enqueueSnackbar } = useSnackbar();

    const { register, handleSubmit } = useForm<FieldValues>();
    const { darkmode } = useDarkMode();

    const intl = useIntl();

    const handleSubmitForm: SubmitHandler<FieldValues> = async data => {
        try {
            setLoading(true);
            await usersService.sendEmailPassword(data.email);

            setSend(true);
        } catch (e) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitNewPassword: SubmitHandler<FieldValues> = async data => {
        if (data.password !== data.passwordConfirm) {
            enqueueSnackbar(routeTranslate(intl, 'sendEmail.error.match'), {
                variant: 'error',
            });
            return;
        }
        try {
            setLoading(true);
            await confirmPasswordReset(auth, oobCode, data.password);
            enqueueSnackbar(routeTranslate(intl, 'sendEmail.success.change'), {
                variant: 'success',
            });
            await sleep(2);
            navigate(
                `/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(
                    intl,
                    'route.signin',
                )}`,
            );
        } catch (e) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const getUserEmail = async () => {
        await verifyPasswordResetCode(auth, oobCode)
            .then(() => {
                setIsCodeValid(true);
            })
            .catch(() => {
                setIsCodeValid(false);

                enqueueSnackbar(
                    routeTranslate(intl, 'sendEmail.error.expired'),
                    {
                        variant: 'error',
                    },
                );
            });

        setLoadedInfos(true);
    };

    React.useEffect(() => {
        if (mode && oobCode) {
            getUserEmail();
        } else {
            setLoadedInfos(true);
        }
    }, []);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    return (
        <>
            <Header />
            <ContainerFull className={darkmode ? 'containerDark' : ''}>
                {loadedInfos ? (
                    <Container>
                        {mode === 'resetPassword' && isCodeValid ? (
                            <Form
                                onSubmit={handleSubmit(handleSubmitNewPassword)}
                            >
                                <FormattedMessage
                                    tagName={'h1'}
                                    id="sendEmail.reset.title"
                                />
                                <p className="tagline">
                                    <FormattedMessage id="sendEmail.reset.desc" />
                                </p>

                                <div id="password-field">
                                    <Input
                                        id="password"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        placeholder={routeTranslate(
                                            intl,
                                            'signin.placeholderPassword',
                                        )}
                                        required
                                        register={register}
                                    />
                                    {showPassword ? (
                                        <AiOutlineEye
                                            style={{
                                                color: darkmode
                                                    ? '#A09FA5'
                                                    : '#718096',
                                            }}
                                            size={22}
                                            onClick={handleShowPassword}
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            style={{
                                                color: darkmode
                                                    ? '#A09FA5'
                                                    : '#718096',
                                            }}
                                            size={22}
                                            onClick={handleShowPassword}
                                        />
                                    )}
                                </div>

                                <div
                                    id="password-field"
                                    className="inputConfirmPass"
                                >
                                    <Input
                                        id="passwordConfirm"
                                        type={
                                            showPassword2 ? 'text' : 'password'
                                        }
                                        placeholder={routeTranslate(
                                            intl,
                                            'signin.placeholderPassword',
                                        )}
                                        required
                                        register={register}
                                    />
                                    {showPassword2 ? (
                                        <AiOutlineEye
                                            style={{
                                                color: darkmode
                                                    ? '#A09FA5'
                                                    : '#718096',
                                            }}
                                            size={22}
                                            onClick={handleShowPassword2}
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            style={{
                                                color: darkmode
                                                    ? '#A09FA5'
                                                    : '#718096',
                                            }}
                                            size={22}
                                            onClick={handleShowPassword2}
                                        />
                                    )}
                                </div>
                                <Button type="submit" loading={loading}>
                                    <FormattedMessage id="sendEmail.reset.button" />
                                </Button>
                            </Form>
                        ) : (
                            <Form onSubmit={handleSubmit(handleSubmitForm)}>
                                {!send ? (
                                    <>
                                        <FormattedMessage
                                            tagName={'h1'}
                                            id="sendEmail.title"
                                        />
                                        <p className="tagline">
                                            <FormattedMessage id="sendEmail.subtitle" />
                                        </p>
                                        <Input
                                            id="email"
                                            type="email"
                                            placeholder={routeTranslate(
                                                intl,
                                                'signin.placeholderEmail',
                                            )}
                                            required
                                            register={register}
                                        />
                                        <Button type="submit" loading={loading}>
                                            <FormattedMessage id="sendEmail.submitButton" />
                                        </Button>
                                    </>
                                ) : (
                                    <div className="sendEmail">
                                        <img src={sendEmailIcon} />
                                        <FormattedMessage
                                            tagName={'h1'}
                                            id="sendEmail.checkEmail"
                                        />
                                        <p className="tagline">
                                            <FormattedMessage id="sendEmail.checkEmailText" />
                                        </p>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Container>
                ) : (
                    <></>
                )}
            </ContainerFull>
            <Footer />
        </>
    );
};

export default SendEmail;
