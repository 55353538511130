import React from 'react';
import { ContainerContent, Header, ProgressContainer } from './styles';
import { LayoutProps } from '../interfaces/layout-default-props.interface';
import { AgentInfoMenu } from 'app/presentation/components/agent';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { Container } from '../../../../app/presentation/components';
import useDarkMode from '../../hooks/useDarkMode';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';
import { useNavigate, useParams } from 'react-router-dom';

const AgentInfoLayout: React.FC<LayoutProps> = ({ children }) => {
    const isDesktop = useMediaQuery('(min-width: 64em)');
    const { darkmode } = useDarkMode();
    const { routeGetter, textGetter } = useText();
    const t = textGetter('agent.layout.info');
    const agentRoutes = routeGetter('agent');
    const navigate = useNavigate();
    const { id } = useParams();

    const { changeAgent, changeAgentLoading } = useAgent();

    React.useEffect(() => {
        if(id && isNaN(Number(id))) {
            navigate(agentRoutes('home'));
        }
        if (!changeAgentLoading) {
            changeAgent(Number(id));
        }
    });

    return changeAgentLoading ? (
        <ProgressContainer>
            <CircularProgress size={36} color="inherit" />
        </ProgressContainer>
    ) : (
        <div>
            <Container className={isDesktop ? 'content-full' : ''}>
                <Header className={darkmode ? 'headerChatDark' : ''}>
                    <h1>
                        {t('title') + ' '}
                        {false && <span className="beta">Pendente</span>}
                    </h1>
                </Header>
                <ContainerContent className={isDesktop ? 'content-full' : ''}>
                    <AgentInfoMenu />
                    <div id="content">{children}</div>
                </ContainerContent>
            </Container>
        </div>
    );
};

export default AgentInfoLayout;
