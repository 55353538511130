import React from 'react';
import * as S from './styles';
import { Button } from '../Button';
import {
    IconCheck,
    IconClockHour3,
    IconStar,
    IconTag,
    IconTrash,
    IconUserCircle,
} from '@tabler/icons-react';
import { SelectDropdown } from '../SelectDropdown';
import useTeam from 'app/presentation/hooks/useTeam';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import useTag from 'app/presentation/hooks/useTag';
import { Action } from 'services/agent/interfaces';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';

interface ActionsContainerProps {
    actions: Action[];
    setActions: (actions: Action[]) => void;
}

export const ActionsContainer: React.FC<ActionsContainerProps> = ({
    actions,
    setActions,
}) => {
    const deleteQuestion = (index: number): (() => void) => {
        return () => {
            if (actions != undefined) {
                if (index !== -1) {
                    const newData = actions.filter((el, i) => {
                        return i != index;
                    });
                    setActions(newData);
                }
            }
        };
    };

    const setAction = (index: number): ((action: Action) => void) => {
        return (action: Action) => {
            const newActions = [...actions];
            newActions[index] = action;
            setActions(newActions);
        };
    };

    const { textGetter } = useText();
    const t = textGetter('agent.components.actions-container');

    return (
        <S.ActionsContainer>
            {actions.map((action, index) => {
                switch (action.type) {
                    case 'add_tag':
                        return (
                            <AddTagAction
                                key={index}
                                action={action}
                                deleteAction={deleteQuestion(index)}
                                setAction={setAction(index)}
                            ></AddTagAction>
                        );
                        break;
                    case 'assign_to_member':
                        return (
                            <AssignToMemberAction
                                key={index}
                                action={action}
                                deleteAction={deleteQuestion(index)}
                                setAction={setAction(index)}
                            ></AssignToMemberAction>
                        );
                        break;
                    case 'change_priority':
                        return (
                            <DefaultAction
                                key={index}
                                icon={<IconStar></IconStar>}
                                text={t('change-priority.text')}
                                deleteAction={deleteQuestion(index)}
                            ></DefaultAction>
                        );
                        break;
                    case 'show_wait_time':
                        return (
                            <DefaultAction
                                key={index}
                                icon={<IconClockHour3></IconClockHour3>}
                                text={t('show-wait-time.text')}
                                deleteAction={deleteQuestion(index)}
                            ></DefaultAction>
                        );
                        break;
                    case 'close':
                        return (
                            <DefaultAction
                                key={index}
                                icon={<IconCheck></IconCheck>}
                                text={t('close.text')}
                                deleteAction={deleteQuestion(index)}
                            ></DefaultAction>
                        );
                        break;
                    default:
                        return null;
                        break;
                }
            })}
        </S.ActionsContainer>
    );
};

interface ActionsProps {
    action: Action;
    setAction: (actions: Action) => void;
    deleteAction: () => void;
}

interface IMembers {
    name: string;
    id: string;
}

const AssignToMemberAction: React.FC<ActionsProps> = ({
    action,
    setAction,
    deleteAction,
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.actions-container.assign-to-member');
    const NOT_ASSIGNED = { id: '0', name: t('select.not-assigned') };
    const [members, setMembers] = React.useState<IMembers[]>([
        { ...NOT_ASSIGNED },
    ]);
    const { team, getTeam } = useTeam();
    const { workspace } = useUserConfig();

    React.useEffect(() => {
        getTeam();
    }, [workspace]);

    React.useEffect(() => {
        if (!team) {
            return;
        }
        setMembers([
            { ...NOT_ASSIGNED },
            ...team?.map(member => {
                return {
                    id: member.user.id.toString(),
                    name: member.user.first_name + ' ' + member.user.last_name,
                };
            }),
        ]);
    }, [team]);

    const MEMBER_ID = 0;
    const onSelectChange = (value: string) => {
        const newMemberID = { ...action.fields[MEMBER_ID] };
        newMemberID.value = value;
        action.fields[MEMBER_ID] = newMemberID;
        setAction(action);
    };

    return (
        <DefaultAction
            icon={<IconUserCircle></IconUserCircle>}
            text={t('text')}
            deleteAction={deleteAction}
            fieldsForm={
                <SelectDropdown
                    button={t('select.not-assigned')}
                    onChange={onSelectChange}
                    value={action.fields[MEMBER_ID].value}
                    menu={{
                        placeholder: t('select.placeholder'),
                        title: t('select.title'),
                    }}
                    options={members.map(member => {
                        return {
                            value: member.id,
                            node: member.name,
                            filterValue: member.name,
                        };
                    })}
                ></SelectDropdown>
            }
        ></DefaultAction>
    );
};

const AddTagAction: React.FC<ActionsProps> = ({
    action,
    setAction,
    deleteAction,
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.actions-container.add-tag');

    const { tags, getTags } = useTag();
    const [newTag, setNewTag] = React.useState<string>('');
    const { actions } = useAgent();

    React.useEffect(() => {
        getTags();
    }, [actions]);

    const TAG_ID = 0;
    const NEW_TAG = 1;
    React.useEffect(() => {
        if (action.fields[TAG_ID].value == '0') {
            action.fields[NEW_TAG] = { name: 'new_tag', value: newTag };
            setAction(action);
        }
    }, [newTag]);

    const onSelectChange = (value: string) => {
        action.fields = [{ name: 'tag_id', value: value }];
        if (action.fields[TAG_ID].value == '0') {
            action.fields[NEW_TAG] = { name: 'new_tag', value: newTag };
        }
        setAction(action);
    };

    return (
        <DefaultAction
            icon={<IconTag></IconTag>}
            text={t('text')}
            deleteAction={deleteAction}
            fieldsForm={
                <SelectDropdown
                    button={t('select.button')}
                    onChange={onSelectChange}
                    value={action.fields[0].value}
                    menu={{
                        placeholder: t('select.placeholder'),
                        title: t('select.title'),
                    }}
                    options={tags.map(tag => {
                        return {
                            value: tag.id.toString(),
                            node: tag.name,
                            filterValue: tag.name,
                        };
                    })}
                    createOption={{
                        onChange: setNewTag,
                        prefix: (
                            <S.NewTagPrefix>
                                <IconTag></IconTag>
                                <span>{t('select.create')} </span>
                            </S.NewTagPrefix>
                        ),
                    }}
                ></SelectDropdown>
            }
        ></DefaultAction>
    );
};

interface DefaultActionProps {
    icon: React.ReactNode;
    text: string;
    fieldsForm?: React.ReactNode;
    deleteAction: () => void;
}

const DefaultAction: React.FC<DefaultActionProps> = ({
    icon,
    text,
    fieldsForm = null,
    deleteAction,
}) => {
    return (
        <S.Action>
            {icon}
            {text}
            {fieldsForm}
            <Button
                type="button"
                variant="danger"
                className="delete-button"
                onClick={deleteAction}
            >
                <IconTrash></IconTrash>
            </Button>
        </S.Action>
    );
};
