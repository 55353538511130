import React from 'react';
import { InputStyle, Label, InputContainer } from './styles';
import { InputProps } from '../../interfaces/input-props.interface';
import { IoMdClose } from 'react-icons/io';

interface InputTags extends InputProps {
    tags: string[] | undefined;
    removeTag: (index: number) => void;
}

export const InputTags: React.FC<InputTags> = ({
    id,
    label,
    register,
    step,
    stepFinal,
    stepPosition,
    tags,
    removeTag,
    ...rest
}) => {
    const [focus, setFocus] = React.useState(false);
    const objectRegister = register
        ? register(id)
        : { onBlur: undefined, register: undefined };
    const { onBlur, ...another } = objectRegister;

    return (
        <InputContainer>
            {stepPosition === 'top' && step && stepFinal ? (
                <div className="label-custom">
                    {label && <Label htmlFor={id}>{label}</Label>}
                    <div className="step">
                        <span>
                            {step.toString()}/{stepFinal.toString()}
                        </span>
                    </div>
                </div>
            ) : (
                <>{label && <Label htmlFor={id}>{label}</Label>}</>
            )}
            <div className={`content-tags input ${focus ? 'focus' : ''}`}>
                {tags &&
                    tags.map((tag, index) => {
                        if (tag.trim() !== '') {
                            return (
                                <div key={index} className="tag">
                                    <span>{tag}</span>
                                    <IoMdClose
                                        size={21}
                                        onClick={() => removeTag(index)}
                                    />
                                </div>
                            );
                        }
                    })}
                {register ? (
                    <InputStyle
                        onFocus={() => setFocus(true)}
                        onBlur={async e => {
                            if (onBlur) await onBlur(e);
                            setFocus(false);
                        }}
                        {...another}
                        {...rest}
                    />
                ) : (
                    <InputStyle
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        {...rest}
                    />
                )}
            </div>
            {step && stepFinal && stepPosition !== 'top' && (
                <div className="step">
                    <span>
                        {step.toString()}/{stepFinal.toString()}
                    </span>
                </div>
            )}
        </InputContainer>
    );
};
