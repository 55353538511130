import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface Props {
    to: string;
    children: React.ReactNode;
    className?: string;
    activePaths?: string[];
}

export const NavButton: React.FC<Props> = ({
    children,
    to,
    activePaths,
    className = '',
}) => {
    const { pathname } = useLocation();
    return (
        <NavLink
            to={to}
            className={
                className +
                (to == pathname ||
                activePaths
                    ?.map(path => {
                        return pathname.startsWith(path);
                    })
                    .reduce((prev, current) => prev || current)
                    ? ' active-route'
                    : '')
            }
        >
            {children}
        </NavLink>
    );
};
