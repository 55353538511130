import styled from 'styled-components';

export const ContainerFull = styled.div`
    float: left;
    width: 100%;
    min-height: calc(100vh - 80px - var(--footer-height));
    display: flex;
    align-items: center;
    background-color: #fafafa;

    &.containerDark {
        background-color: #000;
    }
`;

export const Message = styled.div`
    width: 100%;
    max-width: 427px;
    margin: 0 auto;
    background-color: #fff;
    padding: 32px 24px 32px 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        max-height: 48px;
        font-size: 1rem;
    }

    #link {
        color: #1a202c;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    > h1,
    > p {
        text-align: center;
    }

    .tagline strong {
        color: #1a202c;
    }

    > h1 {
        margin-bottom: 12px;
    }

    button {
        margin-bottom: 24px;
    }

    a {
        display: block;
        text-align: center;
    }

    > p {
        margin-bottom: 24px;
        color: #718096;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.2px;
    }

    #password-field {
        position: relative;

        svg {
            position: absolute;
            right: 18px;
            top: 20px;
            cursor: pointer;
        }
    }

    #remember {
        display: flex;
        align-items: center;
    }

    #options {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        #remember-text {
            font-weight: 500;
        }
    }

    &.messageDark {
        background-color: #1d1d1f;

        p {
            color: #a09fa5;

            strong {
                color: #fff;
            }
            #link {
                color: #a09fa5;
            }
        }
    }
`;
