import { Pagination, PaginationItem } from '@mui/material';
import React from 'react';
import * as S from './styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props {
    onChange: (value: number) => void;
    total: number;
    current: number;
}

export const Paginator: React.FC<Props> = ({ onChange, total, current }) => {
    return (
        <S.Paginator>
            <Pagination
                count={total}
                page={current}
                onChange={(event, number) => {
                    onChange(number);
                }}
                renderItem={item => (
                    <PaginationItem
                        className="page-item"
                        slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                        }}
                        {...item}
                    />
                )}
            />
        </S.Paginator>
    );
};
