import React, { useRef } from "react";
import { Collapse } from "@mui/material";
import { IconChevronRight, IconChevronDown } from '@tabler/icons-react';
import { Row, Ellipsis, StyledMenu } from "./styles";
import { type Memory } from "services/brand/memories/types";
import { type Collection } from 'services/collections/types';
import { useSnackbar } from 'notistack';
import {
    handleOnDragEnd,
    handleOnDragOver,
    handleOnDragLeave,
    handleOnDragStart,
    handleOnKeyDown,
    handleOnDrop,
    getTextWidth,
    handleRowInputBlur
} from "./handlers";
import { IconTrash, IconNotes, IconFolder } from '@tabler/icons-react';
import useCollectionsContext from 'app/presentation/hooks/useCollectionsContext';
import { INITIAL_TOP_INPUT_WIDTH, INITIAL_BOT_INPUT_WIDTH, EMPTY } from './constants';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import { ModalDeleteRow } from "../ModalDeleteRow/ModalDeleteRow";
import { RowContent } from "app/presentation/providers/CollectionsProvider";
import { Fade, MenuItem } from '@mui/material';
import { IconBook } from '@tabler/icons-react';
import { routeTranslate as t } from "utils/intl";
import { FormattedMessage, useIntl } from 'react-intl';
import { ModalAddMemory } from "../ModalAddMemory/ModalAddMemory";
import { IconsModal } from "../IconsModal/IconsModal";
import * as icons from '@tabler/icons-react';
import { useLocation, useNavigate } from "react-router-dom";

type TableRowProps = {
    collect?: Collection;
    memory?: Memory;
    selectedLanguage: number;
    rowContent: RowContent;
    depth: number;
    isFocused: boolean;

    anchor: HTMLElement | null;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

// TODO check collection depth
// TODO validate fields when memory creation

// TODO? fade animation added, but check if its possible to make it vanish when rerendering? maybe a variable of in/off as parameter
// TODO? this component is very bloated, look to make it less bloated

export const TableRow: React.FC<TableRowProps> = (props) => {
    const {
        collect,
        selectedLanguage,
        depth, rowContent,
        memory, isFocused,
        anchor, setAnchor
    } = props;

    const {
        tree,
        setTree,
        updateCollection,
        updateTreeItem,
        /*checkCollectionDepth,*/
        updateCollectionParent,
        createCollection,
        selectedCollection,
        setSelectedCollection
    } = useCollectionsContext();

    const { updateMemory } = useMemoriesContext();
    const { enqueueSnackbar: toast } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();

    const [inputTopW, setInputTopW] = React.useState<number>(INITIAL_TOP_INPUT_WIDTH);
    const [inputBotW, setInputBotW] = React.useState<number>(INITIAL_BOT_INPUT_WIDTH);

    const [beingDragged, setBeingDragged] = React.useState<boolean>(false);
    const [collection] = React.useState<Collection>(collect!);
    const [children, setChildren] = React.useState<React.ReactNode>();

    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [animateModal, setAnimateModal] = React.useState<boolean>(false);

    const [showActionsDropdown, setShowActionsDropdown] = React.useState<boolean>(false);
    const [showIconsDropdown, setShowIconsDropdown] = React.useState<boolean>(false);

    const [showAddMemoryModal, setShowAddMemoryModal] = React.useState<boolean>(false);
    const [animateAddMemoryModal, setAnimateAddMemoryModal] = React.useState<boolean>(false);

    const [title, setTitle] = React.useState<string>(collection && collection.translations &&
        collection.translations[0] ? collection.translations[0].title : EMPTY
    );
    const [description, setDescription] = React.useState<string>(collection && collection.translations &&
        collection.translations[0] ? collection.translations[0].description : EMPTY
    );

    const intl = useIntl();
    const parent = useRef<HTMLDivElement>(null);
    const contentID = rowContent == RowContent.Collection ? collection!.id : memory!.id;

    const selfNode = tree.get(`${rowContent},${contentID}`);

    const [open, setOpen] = React.useState(selfNode ? selfNode.open : false);

    const articlesCount = React.useMemo(() => {
        if (!tree) return 0;

        const self = tree.get(`${rowContent},${contentID}`);
        if (!self) return 0;

        let countMemories = 0;
        self.children.forEach(childrenNodeID => {
            const node = tree.get(childrenNodeID);
            if (node && node.type === RowContent.Memory) {
                countMemories++;
            }
        });

        return countMemories;
    }, [tree]);

    React.useEffect(() => {
        setInputTopW(Math.ceil(getTextWidth(title, "t")));
        setInputBotW(Math.ceil(getTextWidth(description, "b")));
    }, []);

    const handleCollectionIcon = (collection: Collection): React.ReactElement => {
        const collectionNode = tree.get(`${RowContent.Collection},${collection.id}`);
        if (!collectionNode) return (<></>);

        const CollectionIcon = icons[(collectionNode.obj as Collection).icon as keyof typeof icons] as React.ElementType;
        if (!CollectionIcon) return <icons.IconFolder stroke={1.5} size={24} />

        return <CollectionIcon stroke={1.5} size={24} />;
    }

    const handleNavigate = (memoryId: number | undefined) => {
        if (!memoryId) return;

        const url = `${location.pathname.split("/").slice(0, -1).join("/")}${t(intl, 'route.brand-center.knowledge')}?memid=${memoryId}`;
        navigate(url);
    }

    // this probably is better as component by itself instead of a function that returns one
    const TitleCell = (): JSX.Element => {
        if (rowContent && rowContent == RowContent.Collection) {
            return (
                <>
                    <div className="collapse-icon" >
                        {!open ?
                            <IconChevronRight stroke={1.5} size={16} /> :
                            <IconChevronDown stroke={1.5} size={16} />
                        }
                    </div>

                    <div
                        className={`${depth == 0 ? "collection-icon" : "collection-icon-box"} ${showIconsDropdown ? "icons-popped" : ""}`}
                        onClick={(e) => {
                            e.stopPropagation();

                            if (depth > 0) return;

                            setAnchor(e.currentTarget);
                            setShowIconsDropdown(true);
                            setSelectedCollection(collect!.id);
                        }}>

                        {handleCollectionIcon(collection)}
                    </div>

                    <div className="inputs-container">
                        <input
                            autoFocus={isFocused}
                            style={{ width: `${inputTopW}px` }}
                            className="input-top"
                            placeholder={t(intl, "brand-center.components.table-row.title-placeholder")}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.currentTarget.value);
                                setInputTopW(Math.ceil(getTextWidth(e.currentTarget.value, "t")));
                            }}
                            onClick={(e) => e.stopPropagation()}
                            onBlur={() => { handleRowInputBlur(collection, updateCollection, title, description, selectedLanguage, toast); }}
                            onKeyDown={handleOnKeyDown}
                            onFocus={(evt) => evt.target.select()}
                        />

                        <input
                            style={{ width: `${inputBotW}px` }}
                            className="input-bottom"
                            placeholder={t(intl, "brand-center.components.table-row.description-placeholder")}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.currentTarget.value);
                                setInputBotW(Math.ceil(getTextWidth(e.currentTarget.value, "b")));
                            }}
                            onClick={(e) => e.stopPropagation()}
                            onBlur={() => {
                                handleRowInputBlur(collection, updateCollection, title, description, selectedLanguage, toast);
                            }}
                            onKeyDown={handleOnKeyDown}
                        />
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="collapse-icon" style={{ visibility: "hidden" }}>
                    {!open ?
                        <IconChevronRight stroke={1.5} size={16} /> :
                        <IconChevronDown stroke={1.5} size={16} />
                    }
                </div>
                <div className="collection-icon-box" onClick={(e) => e.stopPropagation()}>
                    <IconNotes stroke={1.5} size={24} />
                </div>
                <div
                    className={`memory-title ${memory?.title == "" ? "empty" : ""}`}
                    onClick={() => { handleNavigate(memory?.id) }}
                >
                    <span>
                        {memory?.title != "" ?
                            memory?.title :
                            <FormattedMessage id={"brand-center.collections.table.row.no-title"} />
                        }
                    </span>
                </div>
            </>
        )
    }

    const handleMemoryStatus = (memory: Memory | undefined): string => {
        if (!memory || !memory.contents || !memory.contents[0]) return "";

        if (memory.contents[0].publish_status) return memory.contents[0].publish_status;

        return "";
    }

    const handleEllipsisColor = (): string => {
        if (title == "") return "missing-title";
        return collection!.visibility == "public" ? "published" : "draft";
    }

    const constHandleStatusTextId = (): string => {
        if (title == "") return "brand-center.collections.table.row.needs-title";
        return collection!.visibility == "public" ? "memory.published" : "memory.draft"
    }

    const handleMemoryStatusTextId = (): string => {
        if (!memory || !memory.contents) return "memory.missing";
        return memory.contents[0].publish_status == "published" ? "memory.published" : "memory.draft";
    }


    const InfoCells = (): JSX.Element => {
        if (rowContent && rowContent == RowContent.Collection) {
            return (
                <>
                    <div className="collection-status" >
                        <Ellipsis className={handleEllipsisColor()} />
                        {<span><FormattedMessage id={constHandleStatusTextId()} /></span>}
                    </div>

                    <div className="articles-count" >{articlesCount}</div>
                </>
            )
        }

        return (
            <>
                <div className="collection-status">
                    {<Ellipsis className={handleMemoryStatus(memory)} />}
                    <FormattedMessage id={handleMemoryStatusTextId()} />
                </div>

                <div className="articles-count">-</div>
            </>
        )
    }

    const onDropHandler = (evt: React.DragEvent<HTMLDivElement>) => {
        return handleOnDrop(evt, rowContent, contentID, tree, setTree, toast, updateTreeItem, updateMemory, updateCollectionParent);
    }

    const handleAddCollectionOnClick = async () => {
        try {
            await createCollection(t(intl, "brand-center.collections.default.collection-name"), selectedCollection);
        } catch (err: any) {
            //err instanceof Error ?
            //       toast(err.message, { variant: "error" }) :
            toast(<FormattedMessage id="commons.something-went-wrong" />, { variant: "error" })
        } finally {
            setAnchor(null);
            setShowActionsDropdown(false);
        }
    };

    React.useEffect(() => {
        const self = tree.get(`${rowContent},${contentID}`);
        if (!self) return;

        const childrenNodes: JSX.Element[] = [];

        self.children.map(childrenNodeID => {
            const node = tree.get(childrenNodeID)!;

            const childrenObj = (() => {
                switch (node.type) {
                    case RowContent.Collection:
                        return { collect: node.obj as Collection }

                    case RowContent.Memory:
                        return { memory: node.obj as Memory }
                }
            })

            const childrenContent = node.type == RowContent.Collection ? RowContent.Collection : RowContent.Memory;

            childrenNodes.push(
                <TableRow
                    key={`${node.type},${node.obj.id}`}
                    selectedLanguage={selectedLanguage}
                    rowContent={childrenContent}
                    depth={depth + 1}
                    {...childrenObj()}
                    isFocused={false}
                    anchor={anchor}
                    setAnchor={setAnchor}
                />
            )
        });

        const childrenNode = React.cloneElement(<></>, {
            children: (
                <>
                    {childrenNodes}
                </>
            ),
        });

        setChildren(childrenNode);
        setOpen(self.open);

        if (collection) {
            const translation = (self.obj as Collection).translations?.find(translation => translation.language_id == selectedLanguage);

            if (!translation) {
                setTitle("");
                setDescription("");

                return;
            }


            setTitle(translation.title);
            setDescription(translation.description);

        }
    }, [tree]);

    return (
        <>
            <Fade in={true} unmountOnExit>
                <Row
                    ref={parent}
                    draggable
                    onDragStart={(evt) => handleOnDragStart(evt, contentID, rowContent, setBeingDragged)}
                    onDragEnd={(e) => handleOnDragEnd(e, setBeingDragged)}
                    onDragOver={rowContent == RowContent.Collection ? (e) => handleOnDragOver(parent, e) : () => null}
                    onClick={() => { updateTreeItem(`${rowContent},${contentID}`, { open: !selfNode!.open }); }}
                    onDragLeave={(e) => handleOnDragLeave(e, parent)}
                    onDrop={onDropHandler}
                >
                    <div className="collection-name" style={{ paddingLeft: `min(${depth * 5}%, ${50 * depth}px)` }}>
                        {TitleCell()}
                    </div>

                    {InfoCells()}

                    <div className="row-actions">
                        {rowContent == RowContent.Collection && (
                            <p
                                style={{ color: "rgba(25, 75, 251, 1)", fontWeight: "600", fontSize: "14px", cursor: "pointer" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedCollection(contentID);
                                    setAnchor(e.currentTarget);
                                    setShowActionsDropdown(true);
                                }}
                            >
                                <FormattedMessage id="brand-center.components.table-row.row-actions.add" />
                            </p>
                        )}

                        <IconTrash
                            stroke={1.5} size={18}
                            color="rgba(160, 159, 165, 1)"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setAnimateModal(true);
                                setShowDeleteModal(true);
                            }}
                        />
                    </div>

                </Row >
            </Fade>

            <Collapse in={open} timeout="auto" style={beingDragged ? { opacity: "0.1", pointerEvents: "none" } : {}}>
                {children && children}
            </Collapse >

            {showDeleteModal && (
                <ModalDeleteRow
                    animate={animateModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setAnimateDeleteModal={setAnimateModal}
                    rowContent={rowContent}
                    contentId={contentID}
                />
            )}

            {/* this can be seen as wrong, because contentID can be from a memory too, but this will never happen
                because the AddMemoryModal is impossible to be shown if rowContent is not Collection */}
            {showAddMemoryModal && (
                <ModalAddMemory
                    animate={animateAddMemoryModal}
                    setShowAddMemoryModal={setShowAddMemoryModal}
                    setAnimateAddMemoryModal={setAnimateAddMemoryModal}
                />
            )}

            {/* TODO make this a generic component */}
            <StyledMenu
                anchorEl={anchor}
                open={showActionsDropdown}
                onClose={() => {
                    setAnchor(null);
                    setShowActionsDropdown(false);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            >
                <MenuItem
                    className="actions-dropdown-btn"
                    onClick={() => {
                        setAnimateAddMemoryModal(true);
                        setShowAddMemoryModal(true);
                        setAnchor(null);
                        setShowActionsDropdown(false);
                    }}
                >
                    <IconBook stroke={1.5} size={18} />
                    <p> <FormattedMessage id="brand-center.components.table-row.row-actions.menu.add-knowledge" /> </p>
                </MenuItem>
                <MenuItem
                    className="actions-dropdown-btn"
                    onClick={handleAddCollectionOnClick}
                >
                    <IconFolder stroke={1.5} size={18} />
                    <p> <FormattedMessage id="brand-center.components.table-row.row-actions.menu.add-collection" /> </p>
                </MenuItem>
            </StyledMenu>

            {showIconsDropdown && (
                <IconsModal
                    open={showIconsDropdown}
                    anchor={anchor}
                    setAnchor={setAnchor}
                    setShowIconsDropdown={setShowIconsDropdown}
                />
            )}

        </>
    )
}


