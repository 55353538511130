import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .content-modal {
        background: #ffffff;
        border-radius: 16px;
        width: 98%;
        max-width: 544px;
        height: auto !important;
        max-height: 100%;
        padding: 39px 33px 46px 33px;
        overflow: auto;

        header {
            text-align: right;

            svg {
                cursor: pointer;
            }
        }

        .message {
            color: #718096;
            font-size: 0.875em;
            margin-bottom: 25px;
        }

        .invite {
            display: flex;
            gap: 16px;

            .box-invite {
                height: 50px;
                min-width: 54px;
                border: 1px solid #e2e8f0;
                border-radius: 12px;
                padding: 13px 16px;
                overflow: hidden;
                cursor: pointer;

                span {
                    font-weight: 400;
                    font-size: 1em;
                    color: #000000;
                    white-space: nowrap;
                }
            }
        }

        #title {
            margin-bottom: 30px;

            h1 {
                text-align: center;
                font-size: 1.5em;
                color: #1a202c;
                font-weight: 700;
                margin-bottom: 12px;
            }

            p {
                text-align: center;
                font-weight: 500;
                font-size: 0.875em;
                color: #718096;
            }
        }

        form {
            > div {
                margin-bottom: 25px;
            }

            > div:last-of-type {
                margin-top: 5px;
            }

            button {
                width: 164px;
                height: 48px;
                font-size: 1em;
            }
        }

        ul {
            display: flex;
            gap: 4px;
            flex-direction: column;
            max-height: 176px;
            overflow: auto;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            ::-webkit-scrollbar-thumb {
                background: #edf2f7;
                border-radius: 50px;
            }

            li {
                width: 100%;
                margin: 0;
                padding: 0;
                min-height: 56px;

                display: flex;
                justify-content: space-between;
                align-content: center;

                cursor: pointer;

                span {
                    color: #1a202c;
                    font-weight: 600;
                }
            }
        }

        input {
            border-radius: 12px;
            border: 1px solid #e2e8f0;
        }

        .container-submit {
            display: flex;
            gap: 5px;

            button {
                width: 164px;
                height: 48px;
                font-size: 0.875em;
                border-radius: 12px;
            }

            button[type='submit'] {
                background: #d0ff61;
                color: #1a202c;
            }

            button[type='button'] {
                border: 1px solid #a0aec0;
                border-radius: 12px;
                color: #a0aec0;
                background-color: #fff;
            }
        }
    }
    &.inviteMemberDark {
        .content-modal {
            background: #1d1d1f;

            #title {
                h1 {
                    color: #fff;
                }
                p {
                    color: #a09fa5;
                }
            }

            input {
                background: #3a3a3a !important;
                color: #a09fa5 !important;
                border-color: #3a3a3a;

                &:focus {
                    border: 1px solid #fff !important;
                }
            }

            .message {
                color: #a09fa5;
            }

            .box-invite {
                background: #3a3a3a !important;
                border-color: #3a3a3a;

                span {
                    color: #fff !important;
                }
            }
        }
    }
`;
