import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { api } from 'services/api';
import { useSnackbar } from 'notistack';
import { Button, Input, TextArea } from 'app/presentation/components';
import useUserConfig from '../../../hooks/useUserConfigs';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    callBack: () => void;
}

const ModalNewProject: React.FC<ModalProps> = ({
    active,
    handleClose,
    callBack,
}) => {
    const { register, handleSubmit } = useForm<FieldValues>();
    const { enqueueSnackbar } = useSnackbar();
    const { workspace } = useUserConfig();
    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };
    const { darkmode } = useDarkMode();
    const intl = useIntl();

    const handleSubmitForm: SubmitHandler<FieldValues> = async data => {
        try {
            const idWorkspace = workspace?.id;

            await api.post('/projects', {
                workspace_id: idWorkspace,
                name: data.name,
                type: 'shared',
            });

            callBack();

            enqueueSnackbar('Projeto criado!', {
                variant: 'success',
            });
        } catch (e: any) {
            enqueueSnackbar('Erro ao criar Projeto.', {
                variant: 'error',
            });
        } finally {
            handleClose();
        }
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'modalNewProjectDark' : ''}
            >
                <div className="content-modal">
                    <header>
                        <IoMdClose size={25} onClick={handleClose} />
                    </header>
                    <div id="title">
                        <h1>
                            {routeTranslate(
                                intl,
                                'navmenu.project.new',
                            ).replace('+ ', '')}
                        </h1>
                        <p>
                            {routeTranslate(
                                intl,
                                'navmenu.project.new.description',
                            )}
                        </p>
                    </div>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Input
                            label={routeTranslate(intl, 'home.documents.name')}
                            id="name"
                            type="text"
                            placeholder="Campanha da marca..."
                            register={register}
                        />
                        <TextArea
                            label={routeTranslate(
                                intl,
                                'navmenu.project.description',
                            )}
                            id="description"
                            placeholder="Ajude outros colaboradores a saber do que se trata esta campanha"
                            register={register}
                        />
                        <div className="container-submit">
                            <Button type="submit">
                                {routeTranslate(intl, 'button.create')}
                            </Button>
                            <Button type="button" onClick={handleClose}>
                                {routeTranslate(intl, 'button.cancel')}
                            </Button>
                        </div>
                    </form>
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalNewProject;
