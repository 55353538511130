import styled from 'styled-components';

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const GridSquare = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e2e8f0;
    border-radius: 6px;
    position: relative;

    .box {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 8px;
        cursor: pointer;

        .rate,
        .icon,
        .iconDownload {
            opacity: 0;
            transition: 0.5s;
        }

        &:hover {
            .rate,
            .icon,
            .iconDownload {
                opacity: 1;
            }
        }

        .rate {
            display: flex;
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: #fff;
            border-radius: 21px;
            justify-content: space-between;
            align-items: center;
            padding: 6px 10px;
            width: 68px;
            height: 28px;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: min-content;
            }
        }

        .icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 28px;
            height: 28px;
            border-radius: 24px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .iconDownload {
            position: absolute;
            top: 10px;
            right: 45px;
            width: 28px;
            height: 28px;
            border-radius: 24px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            flex-shrink: 0;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            transition: 0.5s;
        }

        &:hover {
            img {
                filter: brightness(0.6);
            }
        }
    }

    &.contentDark {
        background: #1d1d1f;

        .rate,
        .iconDownload,
        .icon {
            background: #000;
        }
    }
`;

export const GridItem = styled.div`
    margin: 2.5px;

    img {
        flex: 1 1 100%;
        border-radius: 12px;
    }
`;
