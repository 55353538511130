import { useEffect, useRef, useState } from 'react';
import { YtPlayer } from './styles';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { TbPlayerPauseFilled, TbPlayerPlayFilled } from 'react-icons/tb';
import { RiVolumeMuteFill, RiVolumeUpFill } from 'react-icons/ri';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

const YouTubePlayer = ({ youtubeUrl }: { youtubeUrl: string }) => {
    const youtubePlayerRef = useRef<any>(null);
    const [isMuted, setIsMuted] = useState(false);
    const [isPlayed, setIsPlayed] = useState(false);
    const darkmode = useDarkMode();

    useEffect(() => {
        // Load the IFrame Player API code asynchronously.
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

        // When API is ready, create the player
        //@ts-ignore
        window.onYouTubeIframeAPIReady = () => {
            //@ts-ignore

            youtubePlayerRef.current = new window.YT.Player('youtube-iframe', {
                height: '58',
                width: '104',
                videoId: extractVideoID(youtubeUrl),
            });
        };
    }, [youtubeUrl]);

    const toggleMute = () => {
        if (youtubePlayerRef.current) {
            if (isMuted) {
                youtubePlayerRef.current.unMute();
            } else {
                youtubePlayerRef.current.mute();
            }
            setIsMuted(!isMuted);
        }
    };

    const extractVideoID = (url: string): string => {
        const regExp =
            /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|watch\?v=|watch\?.+&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);

        if (match && match[2].length === 11) {
            return match[2];
        } else {
            // If the URL does not contain a valid YouTube video ID, return an empty string or handle the error appropriately
            console.error('Invalid YouTube URL');
            return '';
        }
    };

    const togglePlay = () => {
        if (isPlayed) {
            youtubePlayerRef.current.pauseVideo();
        } else {
            youtubePlayerRef.current.playVideo();
        }
        setIsPlayed(!isPlayed);
    };

    return (
        <YtPlayer>
            <div
                style={{ pointerEvents: 'none', opacity: 0 }}
                id="youtube-iframe"
            ></div>

            <button className="buttonPlay" onClick={togglePlay}>
                {isPlayed ? (
                    <TbPlayerPauseFilled
                        color={darkmode ? '#000' : '#fff'}
                        size={18}
                    />
                ) : (
                    <TbPlayerPlayFilled
                        color={darkmode ? '#000' : '#fff'}
                        size={18}
                    />
                )}
            </button>
            <button className="buttonMute" onClick={toggleMute}>
                {isMuted ? (
                    <RiVolumeMuteFill
                        color={darkmode ? '#000' : '#fff'}
                        size={18}
                    />
                ) : (
                    <RiVolumeUpFill
                        color={darkmode ? '#000' : '#fff'}
                        size={18}
                    />
                )}
            </button>

            <AudioPlayer
                src={''}
                customIcons={{
                    play: (
                        <TbPlayerPlayFilled
                            color={darkmode ? '#000' : '#fff'}
                            size={1}
                        />
                    ),
                    pause: (
                        <TbPlayerPauseFilled
                            color={darkmode ? '#000' : '#fff'}
                            size={1}
                        />
                    ),
                    volume: (
                        <RiVolumeUpFill
                            color={darkmode ? '#000' : '#fff'}
                            size={1}
                        />
                    ),
                    volumeMute: (
                        <RiVolumeMuteFill
                            color={darkmode ? '#000' : '#fff'}
                            size={1}
                        />
                    ),
                }}
            />
        </YtPlayer>
    );
};

export default YouTubePlayer;
