import React from 'react';
import { ContainerFull } from './styles';
import AnswersRoutes from '../../routes/answersRoutes';
import { Header } from '../../app/presentation/components/onboarding/components/Header';
import { Footer } from 'app/presentation/components';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'app/presentation/contexts/AuthContext';
import { routeTranslate as t } from 'utils/intl';
import { useIntl } from 'react-intl';

const Answers: React.FC = () => {
    const { darkmode } = useDarkMode();
    const user = React.useContext(AuthContext);
    const navigate = useNavigate();
    const intl = useIntl();

    React.useEffect(() => {
        if (user && !user.emailVerified) {
            navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.emailVerify')}`);
        }
    }, [user]);

    return (
        <>
            <Header />
            <ContainerFull style={{ background: darkmode ? '#000' : '' }}>
                <div className="content">
                    <AnswersRoutes />
                </div>
            </ContainerFull>
            <Footer />
        </>
    );
};

export default Answers;
