import styled from 'styled-components';

export const Header = styled.div`
    padding-bottom: 32px;
    position: relative;

    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.5em;
        margin-left: 20px;
    }

    .ds-flex {
        display: none;
    }

    @media (min-width: 64em) {
        .ds-flex {
            position: relative;
            display: flex;
            justify-content: center;

            .hr {
                margin: 0 auto;
                height: 1px;
                background-color: #e2e8f0;
                width: 100%;
                bottom: -32px;
                position: absolute;
            }
        }

        h1 {
            position: absolute;
            top: -30px;
            left: 40px;
            margin-left: 0;
        }
    }
`;
