import React from 'react';
import * as S from './styles';
import {
    IconTrash,
    IconMoodHappy,
    IconGif,
    IconPhoto,
    IconGripVertical,
    IconX,
} from '@tabler/icons-react';
import LogoSVG from 'assets/images/png/logo-chat-branco.png';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from '@hello-pangea/dnd';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { Button } from '../Button';
import { Image, acceptedTypes, loadPath, validateFileType } from 'utils/files';
import { GifPicker } from '../GifPicker';
import { Message as AgentMessage } from 'services/agent/interfaces';
import useText from 'app/presentation/hooks/useText';
import { TextArea } from '../TextArea';

export interface IMessage {
    id?: number;
    text: string;
    img?: Image;
    gif_url?: string;
}

interface Props {
    messages: Array<IMessage>;
    setMessages: (value: Array<IMessage>) => void;
    placeholder?: string;
    addMessageButton?: boolean;
}

export const parseAgentMessages = (
    agentMessages: AgentMessage[],
): IMessage[] => {
    return agentMessages.map(message => {
        const image_asset = message.assets.find(asset => asset.type == 'image');
        const image = image_asset
            ? ({ type: 'url', url: image_asset.url } as Image)
            : undefined;
        const gif_asset = message.assets.find(asset => asset.type == 'gif');

        return {
            id: message.id,
            text: message.message,
            img: image,
            gif_url: gif_asset?.url,
        };
    });
};

export const MessagesContainer: React.FC<Props> = ({
    messages,
    setMessages,
    placeholder = '',
    addMessageButton = true,
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.message-container');

    const reorder = (
        list: typeof messages,
        startIndex: number,
        endIndex: number,
    ) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (e: DropResult) => {
        if (e.combine) {
            const items = [...messages];
            items.splice(e.source.index, 1);
            setMessages(items);
            return;
        }
        if (!e.destination) {
            return;
        }

        const items = reorder(messages, e.source.index, e.destination.index);
        setMessages(items);
    };

    const setMessage = (index: number): ((message: IMessage) => void) => {
        return (message: IMessage) => {
            const newData = [...messages];
            newData[index] = message;
            setMessages(newData);
        };
    };

    const handleDeleteMessageChange = (message: IMessage) => {
        const index = messages.indexOf(message);
        if (index !== -1) {
            const newData = messages.filter((el, i) => {
                return i != index;
            });
            setMessages(newData);
        }
    };

    const handleAddMessage = () => {
        const newData = [...messages];
        newData.push({ text: '' });
        setMessages(newData);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <S.MessagesContainer>
                <Droppable
                    droppableId="messagesDroppableZone"
                    direction="vertical"
                >
                    {provided =>
                        messages.length > 0 && (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="messages-container"
                            >
                                {messages.map((item, index) => {
                                    return (
                                        <div className="messages-row">
                                            {index == messages.length - 1 && (
                                                <div className="jarbas-logo-container">
                                                    <img src={LogoSVG} />
                                                </div>
                                            )}
                                            <Draggable
                                                draggableId={String(index)}
                                                key={index}
                                                index={index}
                                                isDragDisabled={
                                                    messages.length < 2
                                                }
                                            >
                                                {providedDraggable => (
                                                    <div
                                                        ref={
                                                            providedDraggable.innerRef
                                                        }
                                                        {...providedDraggable.draggableProps}
                                                        className="message-container"
                                                    >
                                                        <Message
                                                            message={item}
                                                            setMessage={setMessage(
                                                                index,
                                                            )}
                                                            placeholder={
                                                                placeholder
                                                            }
                                                        ></Message>
                                                        {messages.length >
                                                            1 && (
                                                            <div
                                                                className="message-icons"
                                                                {...providedDraggable.dragHandleProps}
                                                            >
                                                                <IconGripVertical></IconGripVertical>
                                                            </div>
                                                        )}
                                                        <Button
                                                            type="button"
                                                            variant="danger"
                                                            className="message-icons"
                                                            onClick={() =>
                                                                handleDeleteMessageChange(
                                                                    item,
                                                                )
                                                            }
                                                        >
                                                            <IconTrash></IconTrash>
                                                        </Button>
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )
                    }
                </Droppable>
                {addMessageButton && (
                    <Button
                        type="button"
                        variant="link"
                        className="add-button"
                        onClick={() => handleAddMessage()}
                    >
                        + {t('add-message')}
                    </Button>
                )}
            </S.MessagesContainer>
        </DragDropContext>
    );
};

interface MessageProps {
    message: IMessage;
    setMessage: (value: IMessage) => void;
    placeholder?: string;
}

export const Message: React.FC<MessageProps> = ({
    message,
    setMessage,
    placeholder = '',
}) => {
    const [emojiPicker, setEmojiPicker] = React.useState<boolean>(false);
    const [gifPicker, setGifPicker] = React.useState<boolean>(false);
    const [imgURL, setImgUrl] = React.useState<string>('');

    const onFocusTextarea = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const textarea = event.currentTarget as HTMLTextAreaElement;
        const message = textarea.parentElement;
        if (message !== null) {
            message.classList.add('active-message');
        }
    };

    const onBlurTextarea = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const textarea = event.currentTarget as HTMLTextAreaElement;
        const message = textarea.parentElement;
        if (message !== null) {
            message.classList.remove('active-message');
        }
    };

    const handleInputMessageChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const newMessage = { ...message };
        newMessage.text = event.target.value;
        setMessage(newMessage);
    };

    const handleEmojiPickerClick = () => {
        if (emojiPicker) {
            setEmojiPicker(false);
        } else {
            setEmojiPicker(true);
        }
    };

    const handleEmoji = (emojiData: EmojiClickData) => {
        const newMessage = { ...message };
        newMessage.text = newMessage.text + emojiData.emoji;
        setMessage(newMessage);
    };

    const closeEmojiPicker = () => {
        setEmojiPicker(false);
    };

    const emojiPickerContainerRef = React.useRef<HTMLDivElement>(null);
    const emojiPickerButtonRef = React.useRef<HTMLButtonElement>(null);
    const handleClickOutsideEmojiPicker = (event: MouseEvent) => {
        if (!emojiPicker) {
            return;
        }
        const emojiPickerContainer = emojiPickerContainerRef.current;
        const emojiPickerButton = emojiPickerButtonRef.current;
        let element = event.target as HTMLElement | null;
        if (emojiPickerContainer && emojiPickerButton) {
            while (element) {
                if (
                    element == emojiPickerContainer ||
                    element == emojiPickerButton
                ) {
                    return;
                }
                element = element.parentElement;
            }
            event.stopPropagation();
            closeEmojiPicker();
        }
    };

    React.useEffect(() => {
        if (!emojiPicker) {
            return;
        }

        document.addEventListener('click', handleClickOutsideEmojiPicker);

        return () => {
            document.removeEventListener(
                'click',
                handleClickOutsideEmojiPicker,
            );
        };
    }, [emojiPicker]);

    React.useEffect(() => {
        if (!message.img) {
            setImgUrl('');
            return;
        }
        switch (message.img.type) {
            case 'file':
                loadPath(message.img.file, setImgUrl);
                break;
            case 'url':
                setImgUrl(message.img.url);
                break;
        }
    }, [message.img]);

    const imgInputRef = React.useRef<HTMLInputElement>(null);

    const openImgInput = () => {
        if (imgInputRef.current) {
            imgInputRef.current.click();
        }
    };

    const handleImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (
            files &&
            files.length > 0 &&
            validateFileType('IMG', files[0].type)
        ) {
            const newMessage = { ...message };
            newMessage.img = { type: 'file', file: files[0] };
            setMessage(newMessage);
        }
    };

    const onDeleteIMGClick = () => {
        const newMessage = { ...message };
        newMessage.img = undefined;
        setMessage(newMessage);
    };

    const onDeleteGIFClick = () => {
        const newMessage = { ...message };
        newMessage.gif_url = '';
        setMessage(newMessage);
    };

    const openGifPicker = () => {
        setGifPicker(true);
    };

    const closeGifPicker = () => {
        setGifPicker(false);
    };

    const onPickGif = (gif: string) => {
        const newMessage = { ...message };
        newMessage.gif_url = gif;
        setMessage(newMessage);
        closeGifPicker();
    };

    return (
        <S.Message>
            <TextArea
                spellCheck="false"
                value={message.text}
                onChange={handleInputMessageChange}
                onFocus={event => onFocusTextarea(event)}
                onBlur={event => onBlurTextarea(event)}
                placeholder={placeholder}
                required={!imgURL && !message.gif_url}
                autoResize
            ></TextArea>
            <div className="assets-container">
                {imgURL && (
                    <div className="asset-container">
                        <Button
                            type="button"
                            variant="dark"
                            onClick={onDeleteIMGClick}
                        >
                            <IconX></IconX>
                        </Button>
                        <img src={imgURL} />
                    </div>
                )}
                {message.gif_url && (
                    <div className="asset-container">
                        <Button
                            type="button"
                            variant="dark"
                            onClick={onDeleteGIFClick}
                        >
                            <IconX></IconX>
                        </Button>
                        <img src={message.gif_url} />
                    </div>
                )}
                <input
                    type="file"
                    accept={acceptedTypes('IMG')}
                    ref={imgInputRef}
                    onChange={handleImgChange}
                />
            </div>
            <div className="message-footer">
                <div className="message-buttons-container">
                    <button
                        type="button"
                        ref={emojiPickerButtonRef}
                        className="icon-button"
                        onClick={handleEmojiPickerClick}
                    >
                        <IconMoodHappy></IconMoodHappy>
                    </button>
                    {false && (
                        <button
                            type="button"
                            className="icon-button"
                            onClick={openGifPicker}
                        >
                            <IconGif></IconGif>
                        </button>
                    )}
                    {false && (
                        <button
                            type="button"
                            className="icon-button"
                            onClick={openImgInput}
                        >
                            <IconPhoto></IconPhoto>
                        </button>
                    )}
                </div>
                {emojiPicker && (
                    <div
                        className="emoji-picker-container"
                        ref={emojiPickerContainerRef}
                    >
                        <EmojiPicker
                            onEmojiClick={emoji => handleEmoji(emoji)}
                            height="400px"
                            width="300px"
                        />
                    </div>
                )}
            </div>
            <GifPicker
                active={gifPicker}
                handleClose={closeGifPicker}
                onPickGif={onPickGif}
            ></GifPicker>
        </S.Message>
    );
};
