import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, StageContainer } from './styles';
import { Button, Container, Input, Stage } from 'app/presentation/components';
import { routeTranslate as t } from 'utils/intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../services/api';
import { useSnackbar } from 'notistack';
import { sleep } from 'utils/sleep';
import { WorkspacesService } from '../../../services/workspaces';
import { Workspace } from 'app/presentation/hooks/interfaces/workspace';
import { AuthContext } from '../../../app/presentation/contexts/AuthContext';
import { OnboardingService } from '../../../services/onboarding';
import { UsersService } from '../../../services/users';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import Select, { components } from 'react-select';
import {
    getCountryCodes,
    isValidE164PhoneNumber,
    removeNonNumericChars,
    validarNumeroCelularBrasil,
    maskPhone,
} from 'utils/validatePhoneNumber';

type IBusiness = {
    createOnboarding: (id: number) => Promise<void>;
};

Select;

const YourBusiness: React.FC<IBusiness> = ({ createOnboarding }) => {
    const { register, handleSubmit, watch } = useForm<FieldValues>();
    const [loading, setLoading] = React.useState(false);
    const [fadeEffect, setFadeEffect] = React.useState('fade-in');
    const [codeSelected, setSelected] = React.useState('+55');
    const { darkmode } = useDarkMode();
    const nameValue: string = watch('name') || '';
    const isBrNumber = codeSelected === '+55';
    const [phoneNum, setPhone] = React.useState('');
    const intl = useIntl();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const user = React.useContext(AuthContext);
    const countryCodes = getCountryCodes();

    const handlePhoneChange = (value: string) => {
        // Remove non-numeric characters from the input value

        if (isBrNumber) {
            let phone = value.replace(/\s/g, '').replace(/\(|\)/g, '').replace(/-/g, '').replace(/^\+/g, '');

            if (phone.length === 13) phone = phone.slice(-11);

            if (phone.length === 12) phone = phone.slice(-10);

            const withoutNonDigit = phone.replace(/\D/g, '');

            const maskValue = maskPhone(phone, withoutNonDigit.length <= 10);

            if (phone.length <= 11) {
                setPhone(maskValue);
            }
        } else {
            const numericValue = value.replace(/\D/g, '');
            setPhone(numericValue);
        }
    };
    const { Option } = components;
    const IconOption = (props: any) => (
        <Option {...props}>
            <div className="toneOptions">
                <div>
                    <p style={{ display: 'flex', alignItems: 'center', color: darkmode ? '#fff' : '#000' }}>
                        {props.data.value}
                        <div style={{ marginLeft: 8, borderRadius: 9999, width: 24, height: 24, }}>
                            <img
                                alt={props.data.name}
                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.data.code}.svg`}
                                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 9999 }}
                            />
                        </div>
                    </p>
                </div>
            </div>
        </Option>
    );

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            background: 'transparent',
            borderColor: 'transparent',
            height: 56,
            minHeight: 56,
            border: 'none',
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: 6,
            padding: 0,
        }),
        menuList: (base: any) => ({
            ...base,
            background: darkmode ? '#000' : '',
        }),
    };

    const SingleValue = ({ ...props }: any) => (
        <components.SingleValue {...props}>
            <section style={{ display: 'flex', alignItems: 'center', marginTop: 3 }}>
                <p style={{
                    fontWeight: 500,
                    fontSize: 14,
                    marginTop: -2,
                    display: 'flex',
                    alignItems: 'center',
                    color: darkmode ? '#fff' : '#000',
                }}
                >
                    {props.data.value}
                    <div style={{ marginLeft: 8, borderRadius: 9999, width: 24, height: 24 }}>
                        <img
                            alt={props.data.name}
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${props.data.code}.svg`}
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 9999 }}
                        />
                    </div>
                </p>
            </section>
        </components.SingleValue >
    );

    const handleCountryCode = (e: any) => {
        if (e && e.value) {
            setSelected(e.value);
        }
    };

    React.useEffect(() => { getWorkspace(); }, []);

    const viewedOnboarding = async () => {
        const onboardingService = new OnboardingService();
        await onboardingService.save();
    };

    const getWorkspace = async () => {
        const workspacesService = new WorkspacesService();
        const data: Workspace[] = await workspacesService.getAll();

        if (data.length > 0) {
            const hasPlan = data.find(workspace => workspace.has_active_plan);

            if (hasPlan) {
                localStorage.setItem(`${user?.uid}-workspaceSelected`, hasPlan.id.toString());

                await viewedOnboarding();

                navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.agent.home')}`);
                return;
            }

            const usersService = new UsersService();
            const settings = await usersService.getOne();

            if (data[0].owner_id === settings.id) {
                handleClick();

                return;
            }

            navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.messages')}${t(intl, 'route.messages.access_denied')}`);
        }
    };

    const numberError = () => { enqueueSnackbar('Confira o formato do numero de telefone!', { variant: 'error' }); };
    const domainFormatError = () => { enqueueSnackbar('Confira o formato do dominio!', { variant: 'error' }); };
    const internalError = () => { enqueueSnackbar('Erro interno!', { variant: 'error' }); };

    const nameFormatError = () => { enqueueSnackbar('O nome é obrigatório!', { variant: 'error' }); };

    const handleClick = async () => {
        setFadeEffect('fade-out');
        await sleep(0.4);
        navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.agent.home')}`);
        setLoading(false);
    };

    const createWorkspace = async (name: string, company_domain: string, phone_number: string) => {
        const result = await api.post('/workspaces', { name, company_domain, phone_number });

        return result;
    };

    const handleSubmitForm: SubmitHandler<FieldValues> = async data => {
        try {
            setLoading(true);

            if (data.name.trim() === '') {
                nameFormatError();
                setLoading(false);

                return;
            }

            const domain = data.company_domain.trim();
            const phoneNumber = codeSelected.concat(removeNonNumericChars(data.phone_number));

            if (isBrNumber && !validarNumeroCelularBrasil(phoneNumber)) {
                numberError();
                setLoading(false);
                return;
            }

            if (!isValidE164PhoneNumber(phoneNumber)) {
                numberError();
                setLoading(false);

                return;
            }

            if (domain !== '') {
                if (!/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain)) {
                    domainFormatError();
                    setLoading(false);

                    return;
                }
            }

            const ws = await createWorkspace(data.name, data.company_domain, phoneNumber);
            await createOnboarding(ws.data.id);
            await viewedOnboarding();

            handleClick();
        } catch (e: any) {
            setLoading(false);

            internalError();
        }
    };

    const handleBack = async () => {
        setFadeEffect('fade-out');
        await sleep(0.4);
        navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.begin')}${t(intl, 'route.reason')}`);
    };

    return (
        <Container className={fadeEffect}>
            <StageContainer>
                <Stage current={3} total={3} />
            </StageContainer>
            <Form
                onSubmit={handleSubmit(handleSubmitForm)}
                className={darkmode ? 'formDarkMode' : ''}
            >
                <FormattedMessage tagName={'h1'} id="yourBusiness.title" />
                <p className="tagline">
                    <FormattedMessage id="yourBusiness.subtitle" />
                </p>
                <div id="two-fields">
                    <Input
                        label={t(intl, 'yourBusiness.labelCompany')}
                        id="name"
                        type="text"
                        placeholder={t(intl, 'yourBusiness.placeholderCompany')}
                        required
                        register={register}
                    />
                    <p className="label-phone">
                        <FormattedMessage id="yourBusiness.phoneNumber" />
                    </p>
                    <div className="input-icon">
                        <Input
                            id="phone_number"
                            inputMode="numeric"
                            placeholder={t(intl, 'yourBusiness.placeholderPhone')}
                            register={register}
                            minLength={isBrNumber ? 15 : 1}
                            maxLength={15}
                            onChange={e => handlePhoneChange(e.target.value)}
                            value={phoneNum}
                        />

                        <i>
                            <Select
                                isSearchable
                                id="selectCode"
                                classNamePrefix="an-simple-select"
                                placeholder={t(intl, 'selectortype')}
                                options={countryCodes}
                                components={{
                                    Option: IconOption,
                                    SingleValue,
                                    IndicatorSeparator: () => null,
                                }}
                                styles={customStyles}
                                onChange={handleCountryCode}
                                noOptionsMessage={() => t(intl, 'lib.select.noOption')}
                                defaultValue={{
                                    name: 'Brazil',
                                    value: '+55',
                                    code: 'BR',
                                }}
                            />
                        </i>
                    </div>

                    <Input
                        label={t(intl, 'yourBusiness.labelDomain')}
                        id="company_domain"
                        type="text"
                        placeholder={t(intl, 'yourBusiness.placeholderDomain')}
                        register={register}
                    />
                </div>
                <div className="buttons">
                    <button
                        onClick={handleBack}
                        className="buttonBack"
                        type="button"
                        style={{ color: darkmode ? '#fff' : undefined }}
                    >
                        <FormattedMessage id="previous" />
                    </button>
                    <Button
                        loading={loading}
                        type="submit"
                        icon={<HiArrowNarrowRight />}
                        className="buttonContinue"
                        disabled={nameValue.length < 1 || phoneNum.length < 1}
                    >
                        <FormattedMessage id="yourBusiness.submitButton" />
                    </Button>
                </div>
            </Form>
        </Container>
    );
};

export default YourBusiness;
