import { Fade } from '@mui/material';
import { FadeBackground, ModalContainer, ModalContent, Ellipsis } from './styles';
import { IconX } from '@tabler/icons-react';
import { CustomCheckbox } from 'pages/brand-center/knowledge/components';
import { Button } from 'app/presentation/components';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import React from 'react';
import { handleLastEdit } from 'pages/brand-center/knowledge/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { enqueueSnackbar as toast } from 'notistack';
import useCollectionsContext from 'app/presentation/hooks/useCollectionsContext';
import { RowContent } from 'app/presentation/providers/CollectionsProvider';

type ModalAddMemoryProps = {
    animate: boolean;

    setShowAddMemoryModal: React.Dispatch<React.SetStateAction<boolean>>;
    setAnimateAddMemoryModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalAddMemory: React.FC<ModalAddMemoryProps> = (props) => {
    const { animate, setShowAddMemoryModal, setAnimateAddMemoryModal } = props;
    const { tree, setTree, selectedCollection, selectedLanguage } = useCollectionsContext();

    const { fetchMemories, memories, selectedMemories, setSelectedMemories, updateMemory } = useMemoriesContext();
    const intl = useIntl();

    React.useEffect(() => {
        fetchMemories(undefined, 500, true, selectedLanguage);
        setSelectedMemories(new Set());
    }, []);

    const handleCheckboxOnChange = (evt: React.ChangeEvent<HTMLInputElement>, memoryId: number) => {
        const tempSet = new Set(selectedMemories);

        if (evt.currentTarget.checked) {
            tempSet.add(memoryId);
        } else {
            tempSet.delete(memoryId);
        }

        setSelectedMemories(tempSet);
    }

    const handleSelectAll = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.currentTarget.checked) {
            const memIds = memories?.data.map(memory => memory.id);
            setSelectedMemories(new Set(memIds));
            return;
        }

        setSelectedMemories(new Set());
    }

    const handleAddMemories = async () => {
        const tempTree = new Map(tree);
        const parentNodeTreeId = `${RowContent.Collection},${selectedCollection}`;

        const parentNode = tempTree.get(parentNodeTreeId);
        if (!parentNode) return;

        parentNode.open = true;

        try {
            for (const memoryId of Array.from(selectedMemories)) {
                const memory = await updateMemory(memoryId, [], selectedCollection);
                if (!memory) continue;

                tempTree.set(`${RowContent.Memory},${memory.id}`, {
                    type: RowContent.Memory,
                    obj: memory,
                    children: [],
                    parentId: parentNodeTreeId,
                    open: false,
                });

                parentNode.children.push(`${RowContent.Memory},${memory.id}`);
            }

            tempTree.set(parentNodeTreeId, parentNode);
            setTree(tempTree);
        } catch (err: any) {
            toast(err, { variant: "error" });
        } finally {
            setAnimateAddMemoryModal(false);
        }
    };

    return (
        <Fade in={animate} timeout={300} onExited={() => setShowAddMemoryModal(false)}>
            <FadeBackground>
                <ModalContainer>
                    <ModalContent>
                        <div className="header">
                            <span>
                                <FormattedMessage id="brand-center.components.modals.add-memory.header" />
                            </span>
                            <button className="btn-exit" onClick={() => setAnimateAddMemoryModal(false)}>
                                <IconX stroke={1.5} size={24}></IconX>
                            </button>
                        </div>

                        <div className="table">
                            <div className="table-head">
                                <div className="checkbox">
                                    <CustomCheckbox
                                        checked={selectedMemories.size == memories?.data.length}
                                        onChange={handleSelectAll}
                                    />
                                </div>

                                <div className="title">
                                    <p> <FormattedMessage id="brand-center.components.modals.add-memory.table.title" /> </p>
                                </div>

                                <div className="status">
                                    <p> <FormattedMessage id="brand-center.components.modals.add-memory.table.status" /> </p>
                                </div>

                                <div className="last-edit">
                                    <p> <FormattedMessage id="brand-center.components.modals.add-memory.table.last-edit" /> </p>
                                </div>

                            </div>

                            <div className="table-body">
                                {memories && memories.data.map(memory => (
                                    <div className="row" key={memory.id}>
                                        <div className="checkbox">
                                            <CustomCheckbox
                                                checked={selectedMemories.has(memory.id)}
                                                onChange={(evt) => handleCheckboxOnChange(evt, memory.id)}
                                            />
                                        </div>

                                        <div className={`title ${memory.title == "" ? "empty" : ""}`}>
                                            <span>
                                                {memory.title != "" ?
                                                    memory.title :
                                                    <FormattedMessage id="commons.no-title" />
                                                }
                                            </span>
                                        </div>

                                        <div className="status">
                                            <Ellipsis className={memory.contents && memory.contents[0].publish_status} />
                                            <p>
                                                <FormattedMessage id={`memory.${memory.contents
                                                    ? memory.contents[0].publish_status :
                                                    <FormattedMessage id="commons.missing" />}`}
                                                />
                                            </p>
                                        </div>

                                        <div className="last-edit">
                                            <p>
                                                {memory.contents ? handleLastEdit(new Date(memory.contents[0].updated_at), intl) : "-"}
                                            </p>
                                        </div>
                                    </div>
                                )
                                )}


                            </div>
                        </div>

                        <div className="btn-line">
                            <Button
                                disabled={selectedMemories.size == 0}
                                onClick={handleAddMemories}
                            >
                                <span>
                                    <FormattedMessage id="commons.add" />
                                    {selectedMemories.size != 0 ? selectedMemories.size : ""}
                                    <FormattedMessage id="commons.knowledge" />
                                </span>

                                {/*this is a workaround, maybe some languages dont write their plural like this, so
                                this can be changed to different translation based on plural */}
                                <span>{selectedMemories.size > 1 ? "s" : ""}</span>
                            </Button>
                        </div>

                    </ModalContent>
                </ModalContainer>
            </FadeBackground>
        </Fade >
    )
}
