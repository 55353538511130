import { Route, Routes } from 'react-router-dom';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import VoiceToneView from 'pages/brand-center/voice-tone';
import Knowledge from 'pages/brand-center/knowledge';
import CollectionsProvider from 'app/presentation/providers/CollectionsProvider';

import BrandCenter from 'pages/brand-center';
import Settings from 'pages/brand-center/settings';
import Collections from 'pages/brand-center/collections';
import PageNotFound from 'pages/not-found/PageNotFound';
import LanguageProvider from 'app/presentation/providers/LanguageProvider';
import BrandsProvider from 'app/presentation/providers/BrandsProvider';
import MemoriesProvider from 'app/presentation/providers/MemoriesProvider';
import TemplateProvider from 'app/presentation/providers/TemplateProvider';

export const BrandCenterRoutes = (): React.ReactElement => {
    const intl = useIntl();
    return (
        <LanguageProvider>
            <MemoriesProvider>
                <TemplateProvider>
                    <Routes>
                        <Route
                            path='/'
                            element={<BrandCenter />}
                        >
                            <Route
                                path={`${routeTranslate(
                                    intl,
                                    'route.brand-center.voice-tone',
                                )}`}
                                element={<VoiceToneView />}
                            />
                            <Route
                                path={`${routeTranslate(intl, 'route.brand-center.knowledge')}`}
                                element={<Knowledge />}
                            />
                            <Route
                                path={`${routeTranslate(
                                    intl,
                                    'route.brand-center.collections',
                                )}`}
                                element={
                                    <CollectionsProvider>
                                        <Collections />
                                    </CollectionsProvider>
                                }
                            />
                            <Route
                                path={`${routeTranslate(intl, 'route.brand-center.settings')}`}
                                element={
                                    <BrandsProvider>
                                        <Settings />
                                    </BrandsProvider>
                                }
                            />
                        </Route>
                        <Route path="*" element={<PageNotFound useAbsolutPath />} />
                    </Routes>
                </TemplateProvider>
            </MemoriesProvider>
        </LanguageProvider>
    );
};
