import React from 'react';
import * as S from './styles';
import {
    CardSelect,
    MessagesContainer,
    IMessage,
    SwitchCheckbox,
    Button,
    ActionsContainer,
    parseAgentMessages,
} from 'app/presentation/components/agent';
import {
    IconCheck,
    IconClockHour3,
    IconInbox,
    IconMessage,
    IconSquareArrowDown,
    IconStar,
    IconTag,
    IconUserCircle,
} from '@tabler/icons-react';
import { ThemeContext } from 'styled-components';
import { Menu, MenuItem } from '@mui/material';
import { Action, ActionType } from 'services/agent/interfaces';
import useAgent from 'app/presentation/hooks/useAgent';
import { SubmitHandler, useForm } from 'react-hook-form';
import useText from 'app/presentation/hooks/useText';

export const BehaviorPanel = () => {
    const { textGetter } = useText();
    const panelText = textGetter('agent.panel.behavior');
    const commonsText = textGetter('agent.panel.commons');

    const [helpMessages, setHelpMessages] = React.useState<Array<IMessage>>([]);
    const [askForRating, setAskForRating] = React.useState<boolean>(true);
    const [passToATeammate, setPassToATeammate] = React.useState<string>('');
    const theme = React.useContext(ThemeContext);
    const [anchorOptsMenu, setAnchorOptsMenu] =
        React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorOptsMenu);
    const [transferActions, setTransferActions] = React.useState<Action[]>([]);

    const { agent, actions, updateBehavior } = useAgent();
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    const loadBehavior = () => {
        if (!agent || !agent.behavior) return;

        if (agent.help_message_lists.length > 0) {
            setHelpMessages(
                parseAgentMessages(agent.help_message_lists[0].messages),
            );
        }

        setAskForRating(agent.behavior.ask_for_rating);
        setPassToATeammate(agent.behavior.pass_to_a_teammate.toString());
    };

    const loadActions = () => {
        if (!actions) return;
        setTransferActions(actions.sort((a, b) => a.order - b.order));
    };

    React.useEffect(() => {
        loadBehavior();
    }, [agent]);

    React.useEffect(() => {
        loadActions();
    }, [actions]);

    const { handleSubmit } = useForm<{}>();

    const onSubmit: SubmitHandler<{}> = async () => {
        setFormSubmitLoading(true);
        updateBehavior(
            passToATeammate == 'true',
            askForRating,
            passToATeammate == 'true' ? helpMessages : [],
            passToATeammate == 'true' ? transferActions : [],
        ).finally(() => setFormSubmitLoading(false));
    };

    const openOptsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorOptsMenu(event.currentTarget);
    };

    const closeOptsMenu = () => {
        setAnchorOptsMenu(null);
    };

    const paperPropsStyle: React.CSSProperties = {
        borderRadius: '12px',
        boxShadow: 'box-shadow: 0px 16px 32px 0px #71809614',
        background:
            theme.agent.pages.behavior.behaviorPanel.menu.background.color,
        padding: '0px',
        width: '260px',
        marginTop: '-26px',
    };

    const menuListStyle: React.CSSProperties = {
        padding: '8px 0px',
        boxShadow: '0',
        background:
            theme.agent.pages.behavior.behaviorPanel.menu.background.color,
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    };

    const menuItemStyle: React.CSSProperties = {
        backgroundColor: 'transparent',
        padding: '0px',
    };

    const addTransferAction = (type: ActionType): (() => void) => {
        return () => {
            const newAction: Action = {
                order: transferActions.length + 1,
                type: type,
                fields: [],
            };
            switch (type) {
                case 'assign_to_member':
                    newAction.fields.push({ name: 'member_id', value: '0' });
                    break;
                case 'add_tag':
                    newAction.fields.push({ name: 'tag_id', value: '0' });
                    break;
            }
            const newActions = [...transferActions];
            newActions.push(newAction);
            setTransferActions(newActions);
        };
    };

    const actionAlreadyAdded = (type: ActionType): boolean => {
        return transferActions.find(action => action.type == type) != undefined;
    };

    const addNewMessage = () => {
        if (helpMessages) {
            const newMessages = [...helpMessages];
            newMessages.push({ text: '', gif_url: '', img: undefined });
            setHelpMessages(newMessages);
        }
    };

    const onAddButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (passToATeammate == 'true') {
            openOptsMenu(event);
        } else {
            addNewMessage();
        }
    };

    return (
        <S.BehaviorPanel onSubmit={handleSubmit(onSubmit)}>
            <div className="behavior-expansion-panel">
                <p className="panel-desc">{panelText('desc')}</p>
                <CardSelect
                    variant="primary"
                    value={passToATeammate}
                    onChange={setPassToATeammate}
                    height="188px"
                    cards={[
                        {
                            value: 'true',
                            icon: <IconInbox></IconInbox>,
                            title: panelText('pass-to-a-teammate.true.title'),
                            text: panelText('pass-to-a-teammate.true.text'),
                        },
                        {
                            value: 'false',
                            icon: <IconSquareArrowDown></IconSquareArrowDown>,
                            title: panelText('pass-to-a-teammate.false.title'),
                            text: panelText('pass-to-a-teammate.false.text'),
                        },
                    ]}
                ></CardSelect>
                <div className="selected-behavior-desc-container">
                    {passToATeammate == 'true' && (
                        <p className="selected-behavior-desc">
                            {panelText('pass-to-a-teammate.true.desc')}
                        </p>
                    )}
                    {passToATeammate == 'false' && (
                        <p className="selected-behavior-desc">
                            {panelText('pass-to-a-teammate.false.desc')}
                        </p>
                    )}
                </div>
                {passToATeammate == 'true' && (
                    <MessagesContainer
                        messages={helpMessages}
                        setMessages={setHelpMessages}
                        placeholder={panelText('help-messages.placeholder')}
                        addMessageButton={false}
                    ></MessagesContainer>
                )}
                {passToATeammate == 'true' && transferActions.length > 0 && (
                    <div className="actions-container">
                        <ActionsContainer
                            actions={transferActions}
                            setActions={setTransferActions}
                        ></ActionsContainer>
                    </div>
                )}
                {passToATeammate == 'true' && (
                    <Button
                        type="button"
                        variant="link"
                        className="add-button"
                        onClick={onAddButtonClick}
                    >
                        + {panelText('help-messages.button.add')}
                    </Button>
                )}
                <Menu
                    anchorEl={anchorOptsMenu}
                    open={open}
                    onClose={closeOptsMenu}
                    PaperProps={{
                        style: paperPropsStyle,
                    }}
                    MenuListProps={{
                        style: menuListStyle,
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <S.MenuTitleContainer>
                        <span>{panelText('menu.title')}</span>
                    </S.MenuTitleContainer>
                    <MenuItem style={menuItemStyle} onClick={addNewMessage}>
                        <S.MenuButton>
                            <div className="icon-container">
                                <IconMessage></IconMessage>
                            </div>
                            <p>{panelText('menu.message')}</p>
                        </S.MenuButton>
                    </MenuItem>
                    {!actionAlreadyAdded('show_wait_time') && (
                        <MenuItem style={menuItemStyle}>
                            <S.MenuButton
                                onClick={addTransferAction('show_wait_time')}
                            >
                                <div className="icon-container">
                                    <IconClockHour3></IconClockHour3>
                                </div>
                                <p>{panelText('menu.reply-time')}</p>
                            </S.MenuButton>
                        </MenuItem>
                    )}
                    {!actionAlreadyAdded('assign_to_member') && (
                        <MenuItem style={menuItemStyle}>
                            <S.MenuButton
                                onClick={addTransferAction('assign_to_member')}
                            >
                                <div className="icon-container">
                                    <IconUserCircle></IconUserCircle>
                                </div>
                                <p>{panelText('menu.assign-member')}</p>
                            </S.MenuButton>
                        </MenuItem>
                    )}
                    {!actionAlreadyAdded('change_priority') && (
                        <MenuItem style={menuItemStyle}>
                            <S.MenuButton
                                onClick={addTransferAction('change_priority')}
                            >
                                <div className="icon-container">
                                    <IconStar></IconStar>
                                </div>
                                <p>{panelText('menu.change-priority')}</p>
                            </S.MenuButton>
                        </MenuItem>
                    )}
                    <MenuItem style={menuItemStyle}>
                        <S.MenuButton onClick={addTransferAction('add_tag')}>
                            <div className="icon-container">
                                <IconTag></IconTag>
                            </div>
                            <p>{panelText('menu.add-tag')}</p>
                        </S.MenuButton>
                    </MenuItem>
                    {!actionAlreadyAdded('close') && (
                        <MenuItem style={menuItemStyle}>
                            <S.MenuButton onClick={addTransferAction('close')}>
                                <div className="icon-container">
                                    <IconCheck></IconCheck>
                                </div>
                                <p>{panelText('menu.close')}</p>
                            </S.MenuButton>
                        </MenuItem>
                    )}
                </Menu>
                <div className="ask-for-rating-container">
                    {askForRating != undefined && (
                        <SwitchCheckbox
                            name="ask-fot-rating"
                            value={askForRating}
                            info={{
                                title: panelText('ask-for-rating.title'),
                            }}
                            setValue={setAskForRating}
                        ></SwitchCheckbox>
                    )}
                </div>
                <div className="buttons-container">
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={() => {
                            loadBehavior();
                            loadActions();
                        }}
                    >
                        {commonsText('button.cancel')}
                    </Button>
                    <Button
                        variant="dark"
                        type="submit"
                        className="save-button"
                        loading={formSubmitLoading}
                    >
                        <IconCheck></IconCheck>
                        <span>
                            {commonsText('button.save')}
                            <span className="desktop">
                                {' '}
                                {commonsText('button.save-suffix')}
                            </span>
                        </span>
                    </Button>
                </div>
            </div>
        </S.BehaviorPanel>
    );
};
