import styled, { css } from 'styled-components';

export const FormFieldInfo = styled.div`
    ${({
        theme: {
            components: {
                formField: { title, icon, text },
            },
        },
    }) => css`
        width: fit-content;
        display: flex;
        flex-direction: column;
        gap: 4px;
        text-align: start;
        .info-title-container {
            width: fit-content;
            display: flex;
            justify-content: left;
            gap: 4px;
            align-items: center;
            p {
                color: ${title.color};
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.2px;
            }

            svg {
                width: 14px;
                height: 14px;
                min-width: 14px;
                min-height: 14px;
                color: ${icon.color};
            }
        }
        .info-text {
            font-weight: 500;
            font-size: 13px;
            line-height: 19.5px;
            letter-spacing: 0.2px;
            color: ${text.color};
        }
    `}
`;

export const FormFieldContainer = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
