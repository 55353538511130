import styled from 'styled-components';

export const InsightsCard = styled.div`
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    width: fit-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
`;

export const InsightsCardTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    span {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.2px;
    }
`;

interface InsightsCardStatisticProps {
    statisticFontSize: number; 
}

export const InsightsCardStatistic = styled.div<InsightsCardStatisticProps>`
    display: flex;
    flex-direction: column;
    gap: 8px;

    .desc {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.2px;
        color: #7E7E7E;
    }

    .statistic {
        display: flex;
        align-items: end;
        gap: 8px;
        width: fit-content;
        
        .statistic-value {
            font-size: ${({statisticFontSize})=>statisticFontSize}px;
            font-weight: 600;
            line-height: 35.2px;
            text-align: left;
        }

        .percentage-increase {
            display: flex;
            align-items: center;
            gap: 4px;
            width: fit-content;
            color: #16A34A;

            svg {
                max-width: 14px;
                max-height: 14px;
                min-width: 14px;
                min-height: 14px;
            }

            .percentage-value {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.2px;
                text-align: left;
            }

            .last-interval {
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.2px;
                color: #7E7E7E;
            }
        }

        .percentage-decrease {
            color: #FF4747;
            svg {
                transform: rotate(180deg);
            }
        }
    }
`;