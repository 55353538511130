import React from 'react';
import * as S from './styles';
import { Bell } from 'app/presentation/components/icons';
import Jarbas from 'assets/images/svg/logo.svg';
import { AuthContext } from 'app/presentation/contexts/AuthContext';
import { AiOutlineUser } from 'react-icons/ai';
import { Menu, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { auth } from 'config/firebaseSetup';
import { routeTranslate } from 'utils/intl';
import { Paper, MenuList } from '@mui/material';
import { FiSettings } from 'react-icons/fi';
import { BiLogOut } from 'react-icons/bi';
import useDarkMode from '../../../hooks/useDarkMode';
import Notifications from 'pages/dashboard/notifications';
import useNotifications from '../../../hooks/useNotifications';
import { IconLayoutGrid } from '@tabler/icons-react';

function userName(name: string | null | undefined) {
    const name_user = name ?? '';
    return name_user.length > 6 ? `${name_user.slice(0, 6)}...` : name;
}

export const AgentHeader: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElNotification, setAnchorElNotification] =
        React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openNotification = Boolean(anchorElNotification);

    const navigate = useNavigate();
    const intl = useIntl();
    const { activate, deactivate, darkmode } = useDarkMode();
    const isDesktop = useMediaQuery('(min-width: 64em)');

    const user = React.useContext(AuthContext);
    const { newNotifications } = useNotifications();

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.definitions');

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickNotification = (
        event: React.MouseEvent<HTMLDivElement>,
    ) => {
        if (!isDesktop) {
            return navigate(
                `/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(
                    intl,
                    'route.agent.home',
                )}${routeTranslate(intl, 'route.agent.notifications')}`,
            );
        }

        setAnchorElNotification(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseNotification = () => {
        setAnchorElNotification(null);
    };

    const signOut = async () => {
        await auth.signOut();
        localStorage.clear();

        navigate(
            `/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(
                intl,
                'route.signin',
            )}`,
        );
    };

    const handleDarkMode = () => {
        if (!darkmode) {
            activate();
        } else {
            deactivate();
        }
    };

    return (
        <S.HeaderContainer className={darkmode ? 'dark' : ''}>
            <nav className="content">
                <div className="ds-flex">
                    <Link
                        to={`/${routeTranslate(
                            intl,
                            'route.urlLang',
                        )}${routeTranslate(intl, 'route.agent.home')}`}
                    >
                        {darkmode ? <S.IconLogoDark /> : <S.IconLogo />}
                    </Link>
                    <div id="options">
                        <div
                            onClick={handleClickNotification}
                            className="option"
                        >
                            {newNotifications && (
                                <div id="new-notification"></div>
                            )}
                            <Bell />
                        </div>
                        <div className="option">
                            <Link
                                to={`/${routeTranslate(
                                    intl,
                                    'route.urlLang',
                                )}${routeTranslate(
                                    intl,
                                    'route.backoffice',
                                )}${routeTranslate(intl, 'route.home')}`}
                            >
                                <IconLayoutGrid />
                            </Link>
                        </div>
                        <Menu
                            id="basic-menu-notification"
                            anchorEl={anchorElNotification}
                            open={openNotification}
                            onClose={handleCloseNotification}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{
                                width: '100%',
                                padding: 0,
                                overflow: 'hidden',
                                '.MuiList-root': {
                                    padding: '0 !important',
                                },
                                '.MuiPaper-root': {
                                    boxShadow:
                                        '4px 8px 50px 1px rgba(74, 85, 104, 0.12)',
                                    borderRadius: '16px',
                                    width: '396px',
                                    padding: '0px',
                                    overflow: 'hidden',
                                },
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: -10,
                                horizontal: 'right',
                            }}
                        >
                            <Paper>
                                <Notifications />
                            </Paper>
                        </Menu>
                        <button
                            id="basic-menu"
                            onClick={handleClick}
                            className="option perfil"
                            type="button"
                        >
                            <div className="image">
                                {user?.photoURL ? (
                                    <img
                                        src={user?.photoURL ?? Jarbas}
                                        title="Perfil Imagem"
                                    />
                                ) : (
                                    <AiOutlineUser
                                        color={darkmode ? '#fff' : ''}
                                        size={25}
                                    />
                                )}
                            </div>
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{
                                width: '100%',
                                '.MuiPaper-root': {
                                    boxShadow:
                                        '8px 16px 32px rgba(113, 128, 150, 0.08);',
                                    borderRadius: '12px',
                                    background: darkmode ? '#1D1D1F' : '',
                                },
                            }}
                            anchorOrigin={{
                                vertical: 290, //375 with all options
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Paper
                                sx={{
                                    width: 232,
                                    boxShadow: 0,
                                    padding: '0px 16px',
                                }}
                            >
                                <MenuList
                                    sx={{ padding: 0, boxShadow: 0 }}
                                    disablePadding
                                    dense
                                >
                                    <S.MuiMenuHeader>
                                        <div
                                            className={`menu-mui-header ${
                                                darkmode && 'usingDarkHeader'
                                            }`}
                                        >
                                            <h1>
                                                {userName(user?.displayName)}
                                            </h1>
                                            <p
                                                style={{
                                                    color: darkmode
                                                        ? '#A09FA5'
                                                        : '',
                                                }}
                                            >
                                                {user?.email}
                                            </p>
                                        </div>
                                        <ul>
                                            <Link
                                                to={`/${path}${routeTranslate(
                                                    intl,
                                                    'route.details',
                                                )}`}
                                                onClick={handleClose}
                                            >
                                                <li>
                                                    <FiSettings
                                                        size={22}
                                                        style={{
                                                            color: darkmode
                                                                ? '#fff'
                                                                : '#A0AEC0',
                                                        }}
                                                    />
                                                    <span
                                                        style={{
                                                            color: darkmode
                                                                ? '#fff'
                                                                : '',
                                                        }}
                                                    >
                                                        {routeTranslate(
                                                            intl,
                                                            'navmenu.user.definitions',
                                                        )}
                                                    </span>
                                                </li>
                                            </Link>
                                            <li onClick={signOut}>
                                                <BiLogOut
                                                    size={22}
                                                    style={{
                                                        color: darkmode
                                                            ? '#fff'
                                                            : '#A0AEC0',
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        color: darkmode
                                                            ? '#fff'
                                                            : '',
                                                    }}
                                                >
                                                    {routeTranslate(
                                                        intl,
                                                        'navmenu.user.logout',
                                                    )}
                                                </span>
                                            </li>
                                            <li
                                                id="dark-mode"
                                                className={`${
                                                    darkmode && 'usingDarkMode'
                                                }`}
                                            >
                                                <span
                                                    style={{
                                                        color: darkmode
                                                            ? '#fff'
                                                            : '',
                                                    }}
                                                >
                                                    Dark mode
                                                </span>
                                                <div
                                                    id="toggle"
                                                    onClick={handleDarkMode}
                                                    className={
                                                        darkmode ? 'active' : ''
                                                    }
                                                >
                                                    <div></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </S.MuiMenuHeader>
                                </MenuList>
                            </Paper>
                        </Menu>
                    </div>
                </div>
                <div className="clear"></div>
            </nav>
        </S.HeaderContainer>
    );
};
