import React from 'react';

export const NewModel: React.FC = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.0832 9.41668V5.86669C17.0832 2.50836 16.2998 1.66669 13.1498 1.66669H6.84984C3.69984 1.66669 2.9165 2.50836 2.9165 5.86669V15.25C2.9165 17.4667 4.13318 17.9917 5.60818 16.4084L5.6165 16.4C6.29983 15.675 7.3415 15.7333 7.93316 16.525L8.77484 17.65"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.6665 5.83331H13.3332"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.5 9.16669H12.5"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.176 12.3086L12.226 15.2586C12.1094 15.3752 12.001 15.5919 11.976 15.7502L11.8177 16.8752C11.7593 17.2836 12.0427 17.5669 12.451 17.5086L13.576 17.3502C13.7343 17.3252 13.9593 17.2169 14.0677 17.1002L17.0177 14.1502C17.526 13.6419 17.7677 13.0502 17.0177 12.3002C16.276 11.5586 15.6843 11.8002 15.176 12.3086Z"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.7495 12.7336C14.9995 13.6336 15.6995 14.3336 16.5995 14.5836"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
