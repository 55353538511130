import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { Header } from './styles';
import { routeTranslate } from '../../../../../utils/intl';
import { useIntl } from 'react-intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface Props {
    title: string;
    hasAd?: boolean;
    adElement?: any;
}

export const HeaderModalDetails: React.FC<Props> = ({
    title,
    hasAd = false,
    adElement = <></>,
}) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const { darkmode } = useDarkMode();

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.definitions');

    const handleBack = () => {
        return navigate(`/${path}`);
    };

    return (
        <Header>
            <div id="back" style={{ background: darkmode ? '#000' : '' }}>
                <IoIosArrowBack size={25} onClick={handleBack} />
            </div>
            <div className="message">
                <h1>{title}</h1>
                {hasAd && adElement && <>{adElement}</>}
            </div>
        </Header>
    );
};
