import React from 'react';
import { ContainerFull, Message } from './styles';
import { Button, Container, Footer } from 'app/presentation/components';
import { Header } from '../../app/presentation/components/email-verify/Header';
import { AuthContext } from 'app/presentation/contexts/AuthContext';
import { useSnackbar } from 'notistack';
import useLoading from '../../app/presentation/hooks/useLoading';
import { routeTranslate as t } from '../../utils/intl';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useUserConfig from '../../app/presentation/hooks/useUserConfigs';
import EmailIcon from 'assets/images/svg/email-icon.svg';
import EmailIconDark from 'assets/images/svg/email-icon-dark.svg';
import { UsersService } from 'services/users';
import { applyActionCode } from 'firebase/auth';
import { auth } from 'config/firebaseSetup';
import Loading from 'app/presentation/components/common/Loading';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

const usersService = new UsersService();

const EmailVerify: React.FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { showLoading } = useLoading();
    const { settings } = useUserConfig();
    const intl = useIntl();
    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');
    const oobCode = searchParams.get('oobCode');
    const user = React.useContext(AuthContext);
    const [verifyFirebase, setVerifyFirebase] = React.useState(false);
    const { darkmode } = useDarkMode();

    const sendMail = async () => {
        showLoading();
        if (user && user.email) {
            const result = await usersService.sendEmailVerify(user.email);

            if (result instanceof Error) {
                sendFail();
            } else {
                sendSuccess();
            }
        }
    };

    React.useEffect(() => {
        if (mode && oobCode) {
            const verifyEmailFirebase = async () => {
                await applyActionCode(auth, oobCode).then(() => {
                    setVerifyFirebase(false);
                    window.location.search = '';
                    window.location.pathname = `/${t(intl, 'route.urlLang')}${t(intl, 'route.signin')}`;
                }).catch(() => {
                    setVerifyFirebase(false);
                    enqueueSnackbar('Erro ao verificar o e-mail, tente novamente!', { variant: 'error', });
                });
            };

            if (mode === 'verifyEmail') {
                setVerifyFirebase(true);
                verifyEmailFirebase();
            }
        } else {
            sendMail();
        }
    }, []);

    /*React.useEffect(() => {
        accessRights();
    }, [user, settings]);

    const accessRights = () => {
        if (user && user.emailVerified) {
            if (settings && !settings.is_onboarded) {
                const inviteReply = localStorage.getItem('inviteReply');
                const inviteToken = localStorage.getItem('inviteToken');

                if (inviteReply && inviteToken) {
                    navigate(`${t(intl, 'route.urlLang')}/workspaces/invites/${inviteToken}?reply=${inviteReply}`);
                    return;
                }
                navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.begin')}${t(intl, 'route.reason')}`);
            } else {
                navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.agent.home')}`);
            }
        }
    };*/

    const sendSuccess = () => { enqueueSnackbar('E-mail enviado com sucesso!', { variant: 'success' }); };
    const sendFail = () => { enqueueSnackbar('Aguarde alguns minutos e tente novamente!', { variant: 'error', }); };

    if (!user || (user && user.emailVerified)) return <></>;

    return (
        <>
            <Header />
            <ContainerFull className={darkmode ? 'containerDark' : ''}>
                <Container>
                    <Message className={darkmode ? 'messageDark' : ''}>
                        <img
                            src={darkmode ? EmailIconDark : EmailIcon}
                            width={218}
                            height={218}
                        />
                        <h1>{t(intl, 'emailVerify.title')}</h1>
                        <p className="tagline">
                            {t(intl, 'emailVerify.desc1')}
                            <br />
                            <strong>{user?.email}</strong>
                            <br />
                            {t(intl, 'emailVerify.desc2')}
                        </p>
                        <p>
                            {t(intl, 'emailVerify.sendEmail')}{' '}
                            <span id="link" onClick={sendMail}>
                                {t(intl, 'emailVerify.sendEmail.click')}
                            </span>
                        </p>

                        <Button onClick={() => window.location.reload()}>
                            {t(intl, 'emailVerify.alreadyConfirmed')}
                        </Button>
                    </Message>
                </Container>
                <Loading open={verifyFirebase} />
            </ContainerFull>
            <Footer />
        </>
    );
};

export default EmailVerify;
