import styled, { css } from 'styled-components';
import { InfoMessageVariants } from 'styles/themes/themes';

interface Props {
    variant: InfoMessageVariants;
}

export const InfoMessage = styled.div<Props>`
    ${({
        theme: {
            components: { infoMessage },
        },
        variant,
    }) => css`
        border-radius: 6px;
        padding: 6px 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        min-height: 52px;
        letter-spacing: 0.2px;
        background-color: ${infoMessage[variant].background.color};
        p {
            color: ${infoMessage[variant].text.color};
            font-weight: ${infoMessage[variant].fontWeight};
            font-size: ${infoMessage[variant].fontSize};
            line-height: ${infoMessage[variant].lineHeight};
        }
        svg {
            color: ${infoMessage[variant].text.color};
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
        }
        .warning {
            font-weight: 700;
        }
    `}
`;
