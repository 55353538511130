import React from 'react';

type IconInterface = {
    darkmode?: boolean;
};

export const Global: React.FC<IconInterface> = ({ darkmode = false }) => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.0002 25.6667C20.4435 25.6667 25.6668 20.4434 25.6668 14C25.6668 7.55672 20.4435 2.33337 14.0002 2.33337C7.55684 2.33337 2.3335 7.55672 2.3335 14C2.3335 20.4434 7.55684 25.6667 14.0002 25.6667Z"
            stroke={darkmode ? '#fff' : '#1A202C'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.33353 3.5H10.5002C8.2252 10.3133 8.2252 17.6867 10.5002 24.5H9.33353"
            stroke={darkmode ? '#fff' : '#1A202C'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.5 3.5C19.775 10.3133 19.775 17.6867 17.5 24.5"
            stroke={darkmode ? '#fff' : '#1A202C'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.5 18.6667V17.5C10.3133 19.775 17.6867 19.775 24.5 17.5V18.6667"
            stroke={darkmode ? '#fff' : '#1A202C'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.5 10.5C10.3133 8.22495 17.6867 8.22495 24.5 10.5"
            stroke={darkmode ? '#fff' : '#1A202C'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
