import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_BASE_URL } from 'config/contants';
import { getToken } from 'services/api';

export interface Page<T> {
    data: T[];
    limit: number;
    next: number;
    page: number;
    prev: number;
    total: number;
    total_pages: number;
}

type Options = {
    path?: string;
    queryParams?: any;
    pathParams?: (number | string)[];
    headers?: any;
    customOptions?: any;
};

const parseQueryParams = (queryParams: any): string => {
    const queryParamsString = '?'.concat(
        Object.keys(queryParams)
            .map(key => `${key.toString()}=${queryParams[key].toString()}`)
            .join('&'),
    );
    return queryParamsString;
};

const parsePathParams = (pathParams: (number | string)[]): string => {
    return '/'.concat(
        pathParams
            .reduce(
                (previus, current) =>
                    `${previus.toString()}/${current.toString()}`,
            )
            .toString(),
    );
};

export enum DefaultHeader {
    AUTH_TOKEN,
    WORKSPACE_ID,
    PROJECT_ID,
    BRAND_ID,
}

export class HTTPClient {
    api: AxiosInstance;
    basePath = '';

    constructor(baseURL?: string, basePath?: string) {
        this.api = axios.create({
            baseURL: baseURL,
        });
        this.basePath = basePath || this.basePath;
    }

    addAuthHeader(customHeaders?: any): HTTPClient {
        return this.addHeaders([DefaultHeader.AUTH_TOKEN], customHeaders);
    }

    addWorkspaceHeaders(customHeaders?: any): HTTPClient {
        return this.addHeaders(
            [
                DefaultHeader.AUTH_TOKEN,
                DefaultHeader.WORKSPACE_ID,
                DefaultHeader.PROJECT_ID,
            ],
            customHeaders,
        );
    }

    addBrandHeaders(customHeaders?: any): HTTPClient {
        return this.addHeaders(
            [
                DefaultHeader.AUTH_TOKEN,
                DefaultHeader.WORKSPACE_ID,
                DefaultHeader.PROJECT_ID,
                DefaultHeader.BRAND_ID,
            ],
            customHeaders,
        );
    }

    addHeaders(
        defaultHeaders: DefaultHeader[],
        customHeaders?: any,
    ): HTTPClient {
        const { workspace, project, activeBrand } = useUserConfig();
        const newAPI = new HTTPClient(this.api.defaults.baseURL, this.basePath);
        newAPI.api.interceptors.request.use(async config => {
            config.headers.concat(customHeaders);
            if (defaultHeaders.includes(DefaultHeader.WORKSPACE_ID)) {
                config.headers = config.headers.concat({
                    'X-Workspace-Id': workspace.id,
                });
            }

            if (defaultHeaders.includes(DefaultHeader.PROJECT_ID)) {
                config.headers = config.headers.concat({
                    'X-Project-Id': project.id,
                });
            }

            if (defaultHeaders.includes(DefaultHeader.AUTH_TOKEN)) {
                config.headers.Authorization = await getToken();
            }

            if (defaultHeaders.includes(DefaultHeader.BRAND_ID)) {
                config.headers = config.headers.concat({
                    'X-Brand-Id': activeBrand?.id || 0,
                });
            }

            return config;
        });

        return newAPI;
    }

    addPath(path: string): HTTPClient {
        return new HTTPClient(
            this.api.defaults.baseURL,
            this.basePath.concat(path),
        );
    }

    get = async <T>(
        options: Options | undefined = {},
    ): Promise<AxiosResponse<T>> => {
        const {
            path = '',
            headers,
            customOptions,
            queryParams,
            pathParams,
        } = options;
        const pathParamsString = pathParams ? parsePathParams(pathParams) : '';
        const queryParamsString = queryParams
            ? parseQueryParams(queryParams)
            : '';
        return this.api.get<T>(
            `${this.basePath}${path}${pathParamsString}${queryParamsString}`,
            {
                headers: {
                    ...headers,
                },
                ...customOptions,
            },
        );
    };

    getData = async <T>(options?: Options): Promise<T> => {
        const { data } = await this.get<T>(options);
        return data;
    };

    post = async <T, R>(
        body: R,
        options: Options | undefined = {},
    ): Promise<AxiosResponse<T>> => {
        const {
            path = '',
            headers,
            customOptions,
            queryParams,
            pathParams,
        } = options;
        const pathParamsString = pathParams ? parsePathParams(pathParams) : '';
        const queryParamsString = queryParams
            ? parseQueryParams(queryParams)
            : '';
        return this.api.post<T, AxiosResponse<T>, R>(
            `${this.basePath}${path}${pathParamsString}${queryParamsString}`,
            body,
            {
                headers: {
                    ...headers,
                },
                ...customOptions,
            },
        );
    };

    postData = async <T, R>(
        body: R,
        options: Options | undefined = {},
    ): Promise<T> => {
        const { data } = await this.post<T, R>(body, options);
        return data;
    };

    put = async <T, R>(
        body: R,
        options: Options | undefined = {},
    ): Promise<AxiosResponse<T>> => {
        const {
            path = '',
            headers,
            customOptions,
            queryParams,
            pathParams,
        } = options;
        const pathParamsString = pathParams ? parsePathParams(pathParams) : '';
        const queryParamsString = queryParams
            ? parseQueryParams(queryParams)
            : '';
        return this.api.put<T, AxiosResponse<T>, R>(
            `${this.basePath}${path}${pathParamsString}${queryParamsString}`,
            body,
            {
                headers: {
                    ...headers,
                },
                ...customOptions,
            },
        );
    };

    putData = async <T, R>(
        body: R,
        options: Options | undefined = {},
    ): Promise<T> => {
        const { data } = await this.put<T, R>(body, options);
        return data;
    };

    delete = async <T>(
        options: Options | undefined = {},
    ): Promise<AxiosResponse<T>> => {
        const {
            path = '',
            headers,
            customOptions,
            queryParams,
            pathParams,
        } = options;
        const pathParamsString = pathParams ? parsePathParams(pathParams) : '';
        const queryParamsString = queryParams
            ? parseQueryParams(queryParams)
            : '';
        return this.api.delete<T>(
            `${this.basePath}${path}${pathParamsString}${queryParamsString}`,
            {
                headers: {
                    ...headers,
                },
                ...customOptions,
            },
        );
    };

    deleteData = async <T>(options: Options | undefined = {}): Promise<T> => {
        const { data } = await this.delete<T>(options);
        return data;
    };

    patch = async <T, R>(
        body: R,
        options: Options | undefined = {},
    ): Promise<AxiosResponse<T>> => {
        const {
            path = '',
            headers,
            customOptions,
            queryParams,
            pathParams,
        } = options;
        const pathParamsString = pathParams ? parsePathParams(pathParams) : '';
        const queryParamsString = queryParams
            ? parseQueryParams(queryParams)
            : '';
        return this.api.patch<T, AxiosResponse<T>, R>(
            `${this.basePath}${path}${pathParamsString}${queryParamsString}`,
            body,
            {
                headers: {
                    ...headers,
                },
                ...customOptions,
            },
        );
    };

    patchData = async <T, R>(
        body: R,
        options: Options | undefined = {},
    ): Promise<T> => {
        const { data } = await this.patch<T, R>(body, options);
        return data;
    };
}

export const client = new HTTPClient(API_BASE_URL);
