import styled, { css } from 'styled-components';

export const Sources = styled.div`
    ${({
        theme: {
            agent: {
                pages: {
                    config: { background },
                },
            },
        },
    }) => css`
        position: relative;
        padding: 30px 0px;
        display: flex;
        justify-content: center;
        max-height: calc(100vh - 80px);
        overflow-y: scroll;
        background-color: ${background.color};
        @media (min-width: 64em) {
            padding: 30px 28px;
            justify-content: start;
        }

        .sources-chat-container {
            width: 360px;
            height: 630px;
            border-radius: 14px;
            box-shadow: 0px 5px 30px 0px #0000001a;
            background-color: white;
            margin-left: 48px;
            display: none;

            @media (min-width: 1400px) {
                display: block;
            }
        }

        .sources-container {
            min-width: 331px !important;
            width: 594px;
            height: fit-content;

            @media (min-width: 64em) {
                max-width: 1014px;
            }

            .metadata {
                margin-bottom: 30px;
            }

            .status-labeled-panel-title {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }

            .title {
                letter-spacing: 0.2px;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                margin-bottom: 10px;
            }

            .description {
                letter-spacing: 0.2px;
                font-weight: 500;
                font-size: 13px;
                line-height: 19.5px;
            }

            .expansion-panel-container {
                display: grid;
                grid-row-gap: 15px;
            }
        }
    `}
`;
