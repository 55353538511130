import styled from 'styled-components';

export const SettingsContainer = styled.div`
    .page-title {
        p {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .switch {
        display: flex;
        gap: 20px;
        margin-right: auto;

        margin-top: 30px;
        p {
            color: #7E7E7E;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            height: 32px;
            cursor: pointer;
        }

        .selected {
            color: black;
            border-bottom: 2px solid black;
        }
    }

    .sections {
        margin-top: 30px;

        display: flex;
        flex-direction: column;
        gap: 15px


    }


`;

