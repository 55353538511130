import React from 'react';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import { Tag } from 'services/agent/interfaces';
import TagContext from '../contexts/TagContext';
import useUserConfig from '../hooks/useUserConfigs';
import useSnackbar from '../hooks/useSnackbar';
import { AgentService } from 'services/agent';

const TagProvider: React.FC<ProviderProps> = ({ children }) => {
    const [tags, setTags] = React.useState<Tag[]>([]);
    const agentService = new AgentService();

    const { activeBrand } = useUserConfig();
    const { error } = useSnackbar();

    React.useEffect(() => {
        getTags();
    }, [activeBrand]);

    const getTags = (): void => {
        if (!activeBrand) return;

        const result = agentService.getAllTags(activeBrand.id);
        result.then(
            res => setTags(res),
            () => error('tag.get-tags'),
        );
    };

    return (
        <TagContext.Provider
            value={{
                tags,
                getTags,
            }}
        >
            {children}
        </TagContext.Provider>
    );
};

export default TagProvider;
