import styled from 'styled-components';

export const InputStyle = styled.input`
    flex: 1;
    height: 56px;
    padding: 16px;
    color: #1a202c;
    font-weight: 500;
    border: 0;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a0aec0;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a0aec0;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a0aec0;
    }
`;

export const Label = styled.label`
    font-weight: bold;
    display: block;
    margin-top: 9px;
    margin-bottom: 15px;
`;

export const InputContainer = styled.div`
    font-size: 0.9em;
    width: 100%;

    .input {
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 56px;
        border: 1px solid #e2e8f0;
        color: #1a202c;
        width: 100%;
        border-radius: 12px;
        overflow: hidden;

        .tag {
            margin: 5px;
            background: #eee;
            padding: 5px;
            border-radius: 8px;
            display: flex;
            align-items: center;

            span {
                color: #666;
                display: inline-block;
                font-weight: bold;
                margin-right: 10px;
            }

            svg {
                cursor: pointer;
            }
        }

        &.focus {
            border: 1px solid #1a202c;
        }
    }

    .step {
        text-align: right;
        padding-top: 8px;

        span {
            color: #718096;
            font-size: 0.75em;
        }
    }

    .label-custom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 9px;
        margin-bottom: 15px;

        label {
            margin: 0;
        }

        .step {
            display: flex;
            align-items: center;
            padding: 0;
        }
    }
`;
