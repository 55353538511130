import React from 'react';
import IntlContext from '../contexts/IntlContext';
import { IntlContextData } from '../contexts/interfaces/intl-context-props.interface';

function useIntlConfig(): IntlContextData {
    const context = React.useContext(IntlContext);

    if (!context) {
        throw new Error('useIntlConfig deve ser usado com o IntlProvider');
    }

    return context;
}

export default useIntlConfig;
