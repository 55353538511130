import React from 'react';
import { BodyPadding, Container, FooterContainer } from './styles';
import { matchRoutes, useLocation } from 'react-router-dom';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

const routes = [{ path: '/login-dashboard' }];

const useCurrentPath = () => {
    const location = useLocation();

    const router = matchRoutes(routes, location);

    return !!router;
};

export const Footer: React.FC = () => {
    const currentPath = useCurrentPath();
    const { darkmode } = useDarkMode();

    if (currentPath) {
        return <></>;
    }

    const currentYear = new Date(Date.now()).getFullYear();

    return (
        <>
            <Container
                className="container"
                style={{ background: darkmode ? '#000' : '' }}
            >
                <div className="content">
                    <FooterContainer>
                        <ul>
                            <li style={{ color: darkmode ? '#A09FA5' : '' }}>
                                Política de Privacidade
                            </li>
                            <li style={{ color: darkmode ? '#A09FA5' : '' }}>
                                © {currentYear} Jarbas.AI
                            </li>
                        </ul>
                    </FooterContainer>
                    <div className="clear"></div>
                </div>
            </Container>
            <BodyPadding />
        </>
    );
};
