import styled, { css } from 'styled-components';

export const ColorInput = styled.div`
    ${({
        theme: {
            components: {
                colorInput: { border, background, text },
            },
        },
    }) => css`
        width: 100%;
        position: relative;
        width: fit-content;

        .button-container {
            position: relative;
            width: fit-content;
            button {
                border: 1px solid ${border.color};
                border-radius: 4px;
                width: 121px;
                height: 32px;
                padding: 8px;
                display: flex;
                justify-content: space-between;
                background-color: ${background.color};
                transition: all linear 0.05s;
                align-items: center;

                input {
                    width: 70px;
                    background-color: transparent;
                    border: none;

                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.2px;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: ${text.color};
                }

                :hover {
                    border-color: ${border.active};
                }
            }
        }
    `}
`;

interface ColorCircleProp {
    bgColor: string;
}

export const ColorCircle = styled.div<ColorCircleProp>`
    width: 22px;
    height: 22px;
    background-color: ${props => props.bgColor};
    border-radius: 11px;
`;

export const ColorPicker = styled.div`
    width: 200px;
    background: #f1f1f1;
    border-radius: 6px;
    position: absolute;
    bottom: 0px;
    right: -210px;

    .react-colorful {
        width: auto;
    }

    .picker_footer {
        display: flex;
        padding: 8px;
        flex-wrap: wrap;
        border: 4px;

        .footer_buttons {
            width: 24px;
            height: 24px;
            margin: 4px;
            border: none;
            padding: 0;
            border-radius: 0px;
            cursor: pointer;
            background: none;
            border: 0px solid #e2e8f0;
            border-radius: 4px;
        }
    }
`;
