import { useContext } from 'react';
import SnackbarContext from '../contexts/SnackbarContext';
import { SnackbarContextData } from '../contexts/interfaces/snackbar-context-props.interface';

function useSnackbar(): SnackbarContextData {
    const context = useContext(SnackbarContext);

    if (!context) {
        throw new Error('useSnackbar deve ser usado com o SnackbarProvider');
    }

    return context;
}

export default useSnackbar;
