import React from 'react';
import {
    Button,
    Container,
    Input,
    Select,
    TextArea,
} from 'app/presentation/components';
import { Header, ContainerModel, Responsive } from './styles';
import { FieldValues, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import { FiCheck } from 'react-icons/fi';
import { Lightning, Pencil } from 'app/presentation/components/icons';

const NewPostBlog: React.FC = () => {
    const { register } = useForm<FieldValues>();

    const navigate = useNavigate();
    const intl = useIntl();

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice');

    const handleClose = () => {
        return navigate(`/${path}${routeTranslate(intl, 'route.documents')}`);
    };

    return (
        <Container>
            <Responsive>
                <Header>
                    <div className="header-content">
                        <h1 className="title-mobile">
                            Nova postagem para blog
                        </h1>
                        <div className="title-desktop">
                            <h1>Nova postagem para blog</h1>
                        </div>
                        <p className="title-desktop">
                            Siga as etapas abaixo para iniciar seu conteúdo
                        </p>
                        <MdClose onClick={handleClose} size={26} />
                    </div>
                    <div className="select">
                        <Select
                            id="language"
                            placeholder="Português (BR)"
                            register={register}
                            options={[{ name: 'Português (BR)' }]}
                        />
                    </div>
                </Header>
                <div>
                    <ContainerModel className="desc-content">
                        <div className="header">
                            <div className="icon success">
                                <FiCheck size={21} />
                            </div>
                            <div>
                                <h1>
                                    Descreva o conteúdo que você deseja criar
                                </h1>
                                <p>
                                    Este é o passo mais importante para ajudar
                                    Jarbas a entender sobre o que você quer
                                    escrever. Quanto melhor você descrever o que
                                    deseja escrever, mais conteúdo de qualidade
                                    Jarbas ajudará a produzir.
                                </p>
                            </div>
                        </div>
                        <form>
                            <TextArea
                                label=" "
                                id="title"
                                placeholder="Como criar..."
                                register={register}
                                maxLength={800}
                                stepPosition="top"
                                step={'1'}
                                stepFinal="800"
                            />
                            <Input
                                label="Palavras chave (Opcional)"
                                id="title"
                                placeholder="Digite o valor e aperte enter"
                                register={register}
                            />
                            <Button icon={<FiCheck size={20} />}>Salvar</Button>
                        </form>
                    </ContainerModel>
                    <ContainerModel className="title">
                        <div className="header">
                            <div className="icon active">
                                <span>2</span>
                            </div>
                            <div>
                                <h1>Título</h1>
                                <p>
                                    Escreva seu próprio título ou deixe Jasper
                                    ajudá-lo a gerar ideias.
                                </p>
                            </div>
                        </div>
                        <form>
                            <div className="input-content">
                                <Input
                                    id="title"
                                    placeholder="Digite o valor e aperte enter"
                                    register={register}
                                />
                                <Button icon={<FiCheck size={21} />}>
                                    Usar este
                                </Button>
                            </div>
                            <Button icon={<Lightning />}>Gerar 3 ideias</Button>
                        </form>
                    </ContainerModel>
                    <ContainerModel className="introdution inative">
                        <div className="header">
                            <div className="icon">
                                <span>3</span>
                            </div>
                            <div>
                                <h1>Parágrafo de introdução</h1>
                                <p>
                                    Escreva sua própria introdução ou deixe
                                    Jasper ajudá-lo a gerar ideias.
                                </p>
                            </div>
                        </div>
                        <form>
                            <div className="input-content">
                                <TextArea
                                    label=" "
                                    id="title"
                                    placeholder="Como criar..."
                                    register={register}
                                    maxLength={800}
                                    stepPosition="top"
                                    step={'1'}
                                    stepFinal="800"
                                />
                                <Button icon={<FiCheck size={21} />}>
                                    Usar este
                                </Button>
                            </div>
                            <Button icon={<Lightning />}>Gerar 3 ideias</Button>
                        </form>
                    </ContainerModel>
                    <ContainerModel className="final inative">
                        <div className="header">
                            <div className="icon">
                                <span>4</span>
                            </div>
                            <div>
                                <h1>A configuração está concluída 👍</h1>
                                <p>
                                    Você sempre pode alterar esses valores, se
                                    necessário.
                                </p>
                            </div>
                        </div>
                        <div>
                            <Button icon={<Pencil />}>Abrir Editor</Button>
                        </div>
                    </ContainerModel>
                </div>
            </Responsive>
        </Container>
    );
};

export default NewPostBlog;
