import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { Button, Input } from 'app/presentation/components';
import useUserConfig from '../../../hooks/useUserConfigs';
import { WorkspacesService } from 'services/workspaces';
import { Copy } from '../../icons';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
}

const workspacesService = new WorkspacesService();

const ModalInviteMember: React.FC<ModalProps> = ({ active, handleClose }) => {
    const { register, handleSubmit } = useForm<FieldValues>();
    const { workspace, inviteLink } = useUserConfig();
    const { enqueueSnackbar } = useSnackbar();
    const { darkmode } = useDarkMode();
    const intl = useIntl();

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };

    const sendInvite = async (email: string, id_workspace: number) => {
        try {
            const request = {
                type: 'private',
                invited_email: email,
                workspace_id: id_workspace,
            };

            const invite = await workspacesService.sendInvite(request);

            enqueueSnackbar(routeTranslate(intl, 'team.modal.toastSuccess'), {
                variant: 'success',
            });

            return invite;
        } catch (e: any) {
            enqueueSnackbar(routeTranslate(intl, 'team.modal.toastError'), {
                variant: 'error',
            });
        } finally {
            handleClose();
        }
    };

    const handleSubmitForm: SubmitHandler<FieldValues> = async data => {
        await sendInvite(data.email, workspace.id);
    };

    const getDataFormat = (dateString: string) => {
        const isoString = new Date(dateString).toISOString();

        return new Intl.DateTimeFormat('pt-BR', {
            day: 'numeric',
            month: 'long',
        }).format(new Date(isoString));
    };

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        enqueueSnackbar(routeTranslate(intl, 'team.modal.copied'), {
            variant: 'success',
        });
    };

    const link = process.env.REACT_APP_PUBLIC_URL;

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'inviteMemberDark' : ''}
            >
                <div className="content-modal">
                    <header>
                        <IoMdClose size={25} onClick={handleClose} />
                    </header>
                    <div id="title">
                        <h1>{routeTranslate(intl, 'team.modal.title')}</h1>
                        <p>{routeTranslate(intl, 'team.modal.description')}</p>
                    </div>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Input
                            id="email"
                            type="email"
                            required
                            placeholder="nome@email.com"
                            register={register}
                        />
                        <Button>
                            {routeTranslate(intl, 'team.modal.sendMail')}
                        </Button>
                    </form>
                    {/*inviteLink && (
                        <>
                            <p className="message">
                                {routeTranslate(intl, 'team.modal.linkDesc')}{' '}
                                {getDataFormat(inviteLink.expires_at)}
                            </p>
                            <div className="invite">
                                <div className="box-invite">
                                    <span>
                                        {link}/pt/workspaces/invites/
                                        {inviteLink.invite_token}&reply=accept
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        copyText(
                                            `${link}/pt/workspaces/invites/${inviteLink.invite_token}&reply=accept`,
                                        );
                                    }}
                                    className="box-invite"
                                >
                                    <Copy
                                        hideTooltip={false}
                                        darkmode={darkmode}
                                    />
                                </div>
                            </div>
                        </>
                    )*/}
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalInviteMember;
