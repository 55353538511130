import { Asset } from './interfaces';
import { client } from '../client';
export type AssetType =
    | 'brand_memory_group_icon'
    | 'brand_memory_content_resource'
    | 'agent_identity_logo'
    | 'agent_identity_avatar'
    | 'agent_identity_chat_icon'
    | 'agent_message_asset'
    | 'agent_interaction_audio'
    | 'agent_interaction_attachment'
    | 'agent_interaction_image';

export class AssetsService {
    private readonly assetClient = client
        .addPath('/assets')
        .addWorkspaceHeaders();

    async upload(
        asset: File,
        type: AssetType,
        brandID: number,
    ): Promise<Asset> {
        const data = new FormData();
        data.append('brand_id', brandID.toString());
        data.append('type', type);
        data.append('file', asset);
        return this.assetClient.postData<Asset, FormData>(data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }
}
