import { RowContent, TreeNode } from "app/presentation/providers/CollectionsProvider";
import React from "react";
import { Collection } from "services/collections/types";
import { type EnqueueSnackbar } from "notistack";
import { Memory, MemoryContentTranslation } from "services/brand/memories/types";

type EventDTO = {
    outerRowContent: RowContent;
    outerRowContentID: number;
}

export const handleOnKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
    if (evt.key === "Enter") evt.currentTarget.blur();
}

export const handleOnDragStart = (
    evt: React.DragEvent<HTMLElement>,
    contentID: number,
    rowContent: RowContent,
    setBeingDragged: React.Dispatch<React.SetStateAction<boolean>>
): void => {
    evt.currentTarget.style.opacity = "0.1";
    setBeingDragged(prev => !prev);

    const dto: EventDTO = { outerRowContentID: contentID, outerRowContent: rowContent };

    evt.dataTransfer.setData("application/json", JSON.stringify(dto));
}

export const handleOnDragEnd = (
    evt: React.DragEvent<HTMLElement>,
    setBeingDragged: React.Dispatch<React.SetStateAction<boolean>>
): void => {
    evt.currentTarget.style.opacity = "1";
    setBeingDragged(prev => !prev);
}

export const handleOnDragOver = (parent: React.RefObject<HTMLElement>, evt: React.DragEvent): void => {
    evt.preventDefault();

    parent.current!.style.backgroundColor = "#CFD6F7";
}

export const handleOnDrop = async (
    evt: React.DragEvent<HTMLElement>,
    innerRowContent: RowContent,
    innerRowcontentID: number,
    tree: Map<string, TreeNode>,
    setTree: (map: Map<string, TreeNode>) => void,
    toast: EnqueueSnackbar,
    //checkCollectionDepth: (collectionID: number) => number,
    updateTreeItem: (id: string, fieldsToUpdate: Partial<TreeNode>) => void,
    updateMemory?: ((memoryID: number, translations: MemoryContentTranslation[], groupID?: number, visibility?: string) => Promise<Memory | undefined>),
    updateCollectionParent?: (collection: Collection, groupID: number) => Promise<Collection | undefined>,
) => {
    evt.preventDefault();
    evt.currentTarget.style.backgroundColor = "white";

    const evtData: EventDTO = JSON.parse(evt.dataTransfer.getData("application/json"));


    // outer = row that is being dropped on inner
    // inner = row that is receveing drop event
    const outerRowTreeID = `${evtData.outerRowContent},${evtData.outerRowContentID}`
    const innerRowTreeID = `${innerRowContent},${innerRowcontentID}`

    if (innerRowTreeID == outerRowTreeID) return;

    // depth validations
    /*if (eventData.contentType == "collection" && checkCollectionDepth(eventData.contentID) + depth > 2) {
        toast("invalid depth", { variant: "error" });
        return;
    }*/

    const outerNode = tree.get(outerRowTreeID);
    if (!outerNode) return;

    const tempTree = new Map(tree);

    switch (evtData.outerRowContent) {
        case RowContent.Collection:
            try {
                const updatedCollection = await updateCollectionParent!((outerNode.obj as Collection), innerRowcontentID);
                if (!updatedCollection) return;

                const id = `${RowContent.Collection},${updatedCollection.id}`;
                const node = tempTree.get(id);
                if (!node) return;

                node.obj = updatedCollection;

                tempTree.set(id, { ...node });
            } catch (err: any) {
                toast("an error occurred", { variant: "error" })
                return;
            }

            break;

        case RowContent.Memory:
            const mem = outerNode.obj as Memory;

            try {
                await updateMemory!(mem.id, [], innerRowcontentID);
            } catch (err: any) {
                toast(err, { variant: "error" })
                return;
            }

            break;
    }


    const outerTreeItem = tree.get(outerRowTreeID)!;

    if (outerTreeItem.parentId) {
        const parentNode = tree.get(outerTreeItem.parentId)!;

        parentNode.children.forEach((child, idx) => {
            if (child === outerRowTreeID) {
                parentNode.children.splice(idx, 1);
                tempTree.set(outerTreeItem.parentId!, { ...parentNode })
                return;
            }
        })
    }

    // change outerTreeItem parentID to the new one
    const outerRow = tree.get(outerRowTreeID)!;
    outerRow.parentId = innerRowTreeID;

    tempTree.set(outerRowTreeID, { ...outerRow })

    // add to the new parent's children list
    const newParentNode = tree.get(innerRowTreeID)!;
    newParentNode.children.push(outerRowTreeID);
    newParentNode.open = true;


    tempTree.set(innerRowTreeID, { ...newParentNode });

    setTree(tempTree);
}

// when trying to check if a e.currentTarget contains a e.relatedTarget of type <input>
// it only returns an empty div so i needed to use the target for this verification(?)
// this shit is taking away my lifespan
export const handleOnDragLeave = (
    evt: React.DragEvent<HTMLDivElement>,
    parent: React.RefObject<HTMLDivElement>
): void => {
    if ((evt.relatedTarget as HTMLElement) !== null &&
        (evt.relatedTarget as HTMLElement).tagName.toLocaleLowerCase() === "div" &&
        (evt.relatedTarget as HTMLElement).children.length == 0) {
        return;
    }

    if ((evt.relatedTarget as HTMLElement) !== null && (evt.relatedTarget as HTMLElement).innerHTML === "") {
        if (!evt.currentTarget.contains((evt.target as HTMLElement))) {
            parent.current!.style.backgroundColor = "white";
        }
    } else {
        if (!evt.currentTarget.contains((evt.relatedTarget as HTMLElement))) {
            parent.current!.style.backgroundColor = "white";
        }
    }
}

const getCssStyle = (element: any, prop: any) => {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
};

const getCanvasFont = (el = document.body) => {
    const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
    const fontSize = getCssStyle(el, 'font-size') || '16px';
    const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';
    return `${fontWeight} ${fontSize} ${fontFamily}`;
};

export const getTextWidth = (text: string, input: "t" | "b"): number => {
    const font = getCanvasFont();

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (context) {
        context.font = font;
        const metrics = context.measureText(text);

        if (metrics.width == 0) {
            switch (input) {
                case "t":
                    return context.measureText("Adicione um Título").width;

                case "b":
                    return context.measureText("Descreva esta coleção para ajudar as pessoas...").width;
            }
        }

        return metrics.width;
    }

    return 0
}

export const handleRowInputBlur = async (
    collection: Collection | undefined,
    updateCollection: (collection: Collection, title: string, description: string, language_id: number, groupID?: number) => Promise<Collection | undefined>,
    title: string,
    description: string,
    selectedLanguage: number,
    toast: EnqueueSnackbar,
) => {
    if (!collection) return;

    try {
        await updateCollection(collection, title, description, selectedLanguage);
    } catch (err: any) {
        err instanceof Error ?
            toast(err.message, { variant: "error" }) :
            toast(err.message, { variant: "error" })
    }
}
