import styled, { css } from 'styled-components';

export const ExpansionPanel = styled.div`
    ${({
        theme: {
            components: {
                expansionPanel: { background, border, text },
            },
        },
    }) => css`
        width: 100%;
        padding: 0px 15px;
        border-radius: 8px;
        border: 1px solid;
        min-height: 78px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all linear 0.05s;
        border-color: ${border.color};
        background-color: ${background.color};

        :hover {
            border-color: ${border.active};

            &.disabled {
                border-color: ${border.color};
            }
        }

        &.expanded-panel {
            border-color: ${border.active};

            .expansion-button-icon {
                transform: rotate(90deg);
            }

            .panel {
                overflow-y: visible;
            }
        }

        .panel-button {
            :disabled {
                opacity: 0.5;
                cursor: default;
            }

            width: 100%;
            height: fit-content;
            background-color: inherit;
            border: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0px;
            cursor: pointer;
            color: ${text.color};

            svg {
                min-height: 20px;
                min-width: 20px;
                height: 20px;
                width: 20px;
                margin-right: 12px;
                display: block;
            }
            .panel-button-title {
                display: block;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.2px;
                text-align: left;
            }

            .panel-button-subtitle {
                display: block;
                margin-top: 5px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.2px;
                text-align: left;
            }

            .button-content {
                display: flex;
                width: fit-content;
                align-items: center;
                justify-content: start;
            }

            .expansion-button-icon {
                height: fit-content;
                width: fit-content;
                padding: 0px;
                margin: 0px;
                display: none;

                @media (min-width: 64em) {
                    display: none;
                }
            }

            .expansion-button-icon svg {
                display: block;
                width: 16px;
                height: 16px;
                margin: 0px;
            }
        }

        .panel {
            max-height: 0px;
            overflow-y: hidden;
            transition: max-height linear 0.1s;
        }

        .panel-content {
            padding: 15px 0px;
            border-top: 1px solid;
            color: ${text.color};
            border-color: ${border.color};
        }
    `}
`;
export const ExpansionPanelContainer = styled.div`
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
