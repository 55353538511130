import { createGlobalStyle } from 'styled-components';

export const DefaultStyle = createGlobalStyle`
    body {
        color: #1A202C;
        background-color: #fff;
    }

    .background {
        background-color: #fff;
        color: #1A202C;
    }

    .bg-gray {
        background: #FAFAFA;
        color: #1A202C;
    }

    a {
        color: #1A202C;
    }
`;
