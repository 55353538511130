import styled from 'styled-components';

export const Header = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid #edf2f7;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .select {
        display: none;
    }

    .header-content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.125em;
    }

    .title-desktop {
        display: none;
    }

    @media (min-width: 64em) {
        .select {
            display: block;
            min-width: 210px;

            select {
                height: 48px;
                padding: 0 16px;
            }
        }

        .title-desktop {
            display: flex;
            align-items: center;

            h1 {
                font-size: 2em;
            }
        }

        .title-mobile {
            display: none;
        }

        .header-content {
            display: block;

            > svg {
                display: none;
            }
        }

        padding: 0;
        border-bottom: 0;
        margin-bottom: 48px;
        margin-top: 16px;
        height: auto;

        p {
            margin-top: 8px;
            color: #718096;
            font-size: 1em;
        }
    }
`;

export const ContainerModel = styled.div`
    padding: 26px 15px;

    border: 1px solid #edf2f7;
    border-radius: 16px;
    margin: 24px;

    &.inative {
        button {
            background: #f7fafc;
            color: #a0aec0;
        }

        svg {
            path {
                stroke: #a0aec0;
            }
        }
    }

    &.desc-content {
        button {
            width: 98px;
            height: 48px;
        }
    }

    &.title {
        form > button {
            width: 165px;
            height: 48px;
        }

        .input-content {
            position: relative;
            display: flex;
            align-items: center;

            button {
                position: absolute;
                right: 10px;
                width: 33px;
                height: 33px;
                border-radius: 6px;

                span {
                    display: none;
                }
            }
        }
    }

    &.introdution {
        form > button {
            width: 165px;
            height: 48px;
        }

        .input-content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            button {
                width: 33px;
                height: 33px;
                border-radius: 6px;

                span {
                    display: none;
                }
            }
        }
    }

    &.final {
        button {
            font-size: 0.75em;
            border-radius: 12px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            min-width: 149px;
            max-width: 149px;
            min-height: 48px;
            padding: 0;

            svg {
                margin-right: 7px;
                margin-left: 0px !important;
            }
        }
    }

    .header {
        display: flex;
        gap: 10px;
        margin-bottom: 36px;
        flex-direction: column;

        .icon {
            width: 48px;
            min-width: 48px;
            min-height: 48px;
            max-height: 48px;
            background: #e2e8f0;
            color: #a0aec0;
            border-radius: 1000px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-weight: 800;
                font-size: 1.5em;
            }

            &.success {
                background: #d0ff61;
                color: #1a202c;
            }

            &.active {
                background: #fff;
                border: 1.5px solid #d0ff61;
                color: #1a202c;
            }
        }

        h1 {
            font-size: 1.125em;
            margin-bottom: 10px;
        }

        p {
            font-size: 0.875em;
            color: #718096;
        }
    }

    form {
        button {
            font-size: 0.75em;
            border-radius: 12px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;

            svg {
                margin-right: 7px;
                margin-left: 0px !important;
            }
        }

        > div {
            margin-bottom: 33px;
        }
    }

    @media (min-width: 64em) {
        border: 1px solid #edf2f7;
        border-radius: 16px;
        padding: 28px 24px;
        margin: 0 0 30px 0;

        .header {
            gap: 25px;
            align-items: center;
            flex-direction: row;

            h1 {
                font-size: 1.5em;
            }
        }

        &.title {
            .input-content {
                position: relative;
                display: flex;
                align-items: center;

                input {
                    padding-right: 129px;
                }

                button {
                    position: absolute;
                    right: 10px;
                    min-width: 109px;
                    min-height: 33px;
                    height: 33px;
                    padding: 0;
                    border-radius: 6px;
                    font-size: 0.75em;
                    span {
                        display: inline;
                        font-weight: 600;
                    }
                }
            }
        }

        &.introdution {
            .input-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 31px;

                button {
                    min-width: 109px;
                    min-height: 33px;
                    padding: 0;
                    border-radius: 6px;
                    font-size: 0.75em;
                    span {
                        display: inline;
                        font-weight: 600;
                    }
                }
            }
        }

        &.final {
            display: flex;
            justify-content: space-between;
            align-content: center;

            .header {
                margin: 0;
            }

            > div {
                display: flex;
                align-items: center;
            }
        }
    }
`;

export const Responsive = styled.div`
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 72px);
    max-height: calc(100vh - 72px);
    overflow: auto;

    @media (min-width: 64em) {
        max-width: 880px;
        margin: 0 auto;
        position: static;
        height: auto;
        max-height: inherit;
    }
`;
