import { api } from '../api';
import { SaveTemplate } from '../../app/presentation/providers/interfaces/templates-props.interface';
import { Rate } from '../../interfaces/documents.interface';

export class TemplatesService {
    private readonly path = '/templates';

    async getAll(category?: string | undefined) {
        const { data } = await api.get(
            `${this.path}?${category ? 'categories=' + category : ''}`,
        );

        return data.data;
    }

    async getOne(id: number) {
        const { data } = await api.get(`${this.path}/${id}`);

        return data;
    }

    async create(template: SaveTemplate) {
        const { data } = await api.post(`${this.path}/execute`, template);

        return data;
    }

    async getHistory(id: number, idProject: number) {
        const { data } = await api.get(
            `${this.path}/history/${id}?project_id=${idProject}`,
        );

        return data;
    }

    async removeHistory(ids: number[], idProject: number) {
        const { data } = await api.delete(
            `${this.path}/history/delete?project_id=${idProject}`,
            {
                data: { resource_ids: ids },
            },
        );

        return data;
    }

    async rateResponse(rate: Rate) {
        const { data } = await api.post(`${this.path}/response/rate`, rate);

        return data;
    }

    async setFavorite(id: number, user_id: number) {
        const { data } = await api.post(`${this.path}/${id}/favorite`, {
            user_id,
        });

        return data;
    }

    async getFavorites(user_id: number) {
        const { data } = await api.get(`${this.path}/user/${user_id}/favorite`);

        return data;
    }

    async removeFavorite(id: number, user_id: number) {
        const { data } = await api.delete(`${this.path}/${id}/favorite`, {
            data: { user_id },
        });

        return data;
    }

    async getCategories() {
        const { data } = await api.get('categories');

        return data;
    }

    async getOutputLanguages() {
        const { data } = await api.get(`${this.path}/output-languages/list`);

        return data;
    }

    getSocket = () => {
        return new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET}/../../templates/ws/execute`,
        );
    };
}
