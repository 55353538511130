import { api } from '../api';

export class LanguagesService {
    private readonly path = '/languages';

    async getAll() {
        const { data } = await api.get(this.path);

        return data;
    }
}
