import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Section } from "./components/Section/Section";
import { SettingsContainer } from "./styles";
import { routeTranslate as t } from "utils/intl";

const Settings: React.FC = () => {
    const intl = useIntl();

    return (
        <SettingsContainer>
            <div className="page-title">
                <p> <FormattedMessage id="brand-center.settings.header" /> </p>
            </div>

            <div className="switch">
                <p className="selected"> <FormattedMessage id="brand-center.settings.options.general" /> </p>
                {/*<p>Conteúdo e Estilo</p>*/}
            </div>

            <div className="sections">
                <Section disabled={false} text={t(intl, "brand-center.settings.components.sections.supported-languages.title")} />
                <Section disabled={true} text={t(intl, "brand-center.settings.components.sections.website-publication.title")} />
                <Section disabled={true} text={t(intl, "brand-center.settings.components.sections.analysis-and-privacy.title")} />
                <Section disabled={true} text={t(intl, "brand-center.settings.components.sections.images-and-social-media.title")} />
            </div>

        </SettingsContainer>
    );
};

export default Settings;
