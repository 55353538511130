import {
    IconPlugConnected,
    IconDatabase,
    IconSettings,
    IconChartPie,
} from '@tabler/icons-react';
import useText from 'app/presentation/hooks/useText';
import LogoWhiteImg from 'assets/images/png/logo-chat-branco.png';
import React from 'react';
import { SideMenu } from '../SideMenu';
import {
    SideMenuExpansionPanelButtonContent,
    SideMenuExpansionPanelItemContent,
    SideMenuItemContent,
} from '../SideMenu/styles';
import useAgent from 'app/presentation/hooks/useAgent';

export const AgentInfoMenu: React.FC = () => {
    const { routeGetter, textGetter } = useText();
    const agentRoutes = routeGetter('agent');
    const t = textGetter('agent.components.agent-info-menu');
    const { agent } = useAgent();

    return (
        <SideMenu
            expansionPanelButton={
                <SideMenuExpansionPanelButtonContent>
                    <img src={LogoWhiteImg} />
                    <p>{t('title')}</p>
                </SideMenuExpansionPanelButtonContent>
            }
            expansionPanelItems={[
                {
                    content: (
                        <SideMenuExpansionPanelItemContent>
                            {t('sources')}
                        </SideMenuExpansionPanelItemContent>
                    ),
                    route: agentRoutes('home', {param: agent ? agent.id : '0'}, 'sources'),
                },
                {
                    content: (
                        <SideMenuExpansionPanelItemContent>
                            {t('behavior')}
                        </SideMenuExpansionPanelItemContent>
                    ),
                    route: agentRoutes('home', {param: agent ? agent.id : '0'}, 'behavior'),
                },
            ]}
            menuItems={[
                {
                    content: (
                        <SideMenuItemContent>
                            <IconDatabase></IconDatabase>
                            <p>{t('training')}</p>
                        </SideMenuItemContent>
                    ),
                    route: agentRoutes('home', {param: agent ? agent.id : '0'}, 'training'),
                },
                {
                    content: (
                        <SideMenuItemContent>
                            <IconPlugConnected></IconPlugConnected>
                            <p>{t('integrations')}</p>
                        </SideMenuItemContent>
                    ),
                    route: agentRoutes('home', {param: agent ? agent.id : '0'}, 'integrations'),
                },
                {
                    content: (
                        <SideMenuItemContent>
                            <IconSettings></IconSettings>
                            <p>{t('config')}</p>
                        </SideMenuItemContent>
                    ),
                    route: agentRoutes('home', {param: agent ? agent.id : '0'}, 'config'),
                },
                {
                    content: (
                        <SideMenuItemContent>
                            <IconChartPie></IconChartPie>
                            <p>{t('insights')}</p>
                        </SideMenuItemContent>
                    ),
                    route: agentRoutes('home', {param: agent ? agent.id : '0'}, 'insights'),
                },
            ]}
        ></SideMenu>
    );
};
