import styled from 'styled-components';

export const Container = styled.div`
    .greyed-out,
    .success,
    .progress {
        width: 48px;
        border-radius: 3px;
        padding: 8px;
        padding-top: 1px;
        padding-bottom: 1px;
        font-size: 13px;
        font-weight: 600;
        color: #194bfb;
        background-color: #e8edff;
    }

    .greyed-out {
        background-color: #f1f1f1;
        color: #7e7e7e;
    }

    .success {
        background-color: #e8f6ed;
        color: #058434;
    }

    .progress {
    }
`;
