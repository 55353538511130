import styled from 'styled-components';

export const Empty = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 442px;
    margin: 40px auto;

    .container-submit {
        display: flex;
        justify-content: center;
        gap: 12px;

        button {
            width: 164px;
            height: 48px;
            font-size: 0.875em;
            border-radius: 8px;
        }

        button[type='submit'] {
            background: #d0ff61;
            color: #1a202c;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            padding: 0;

            svg {
                margin-right: 6px;
                margin-left: 0px !important;
            }
        }

        button[type='button'] {
            border: 1px solid #a0aec0;
            border-radius: 8px;
            color: #a0aec0;
            background-color: #fff;
        }
    }

    .image {
        width: 100%;
        max-width: 218px;
    }

    h1 {
        font-size: 2em;
        font-weight: 700;
        margin-top: 14px;
        margin-bottom: 10px;
        text-align: center;
    }

    p {
        font-size: 0.875em;
        color: #718096;
        text-align: center;
        margin-bottom: 30px;
    }
`;

export const Header = styled.div`
    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.5em;
        margin-bottom: 24px;
    }

    .input > div {
        margin: 0;
    }

    .input {
        position: relative;
        display: flex;
        align-items: center;

        .icon {
            position: absolute;
            left: 18px;
        }

        input {
            padding-left: 58px;
            height: 48px;
        }
    }

    #options {
        button {
            margin-top: 25px;
            display: flex;
            flex-direction: row-reverse;
            height: 48px;
            font-size: 0.875em;

            #icon {
                margin-left: 0 !important;
                margin-right: 4px;
            }
        }
    }

    @media (min-width: 64em) {
        position: relative;
        padding-top: 49px;
        display: flex;
        justify-content: space-between;

        #options {
            display: flex;
            align-items: center;

            button {
                margin-top: 0px;
                width: 192px;
                height: 48px;
                display: flex;
                flex-direction: row-reverse;
            }
        }

        .option:first-of-type {
            margin-right: 19px;
        }

        .option {
            cursor: pointer;
            width: 52px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #e2e8f0;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #718096;

            &.active {
                background: #fafafa;
                border: 1px solid #cbd5e0;
            }
        }

        h1 {
            position: absolute;
            top: -30px;
        }

        .input {
            width: 295px;
        }
    }

    .dark {
        input {
            background: #1d1d1f;
            border-color: #1d1d1f;
            color: #fff;

            &:focus {
                border-color: #fff !important;
            }
        }
    }
`;

export const DocumentsContainer = styled.div`
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;

    &.dark {
        padding: 0 24px;

        tbody {
            tr {
                border-bottom: 1px solid #2a313c;
            }

            tr:last-of-type {
                border-bottom: 0 !important;
            }
        }
    }

    .box:first-of-type {
        border-top: 1px solid #edf2f7;
    }

    .box {
        margin-top: 28px;
        padding: 24px 0 36px 0;
        border-bottom: 2px solid #edf2f7;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .flex {
            flex: 1;
            margin: 0 24px;

            h1 {
                font-size: 0.875em;
                font-weight: 700;
            }
        }
    }

    .box:last-of-type {
        border-top: 0;
        border-bottom: 0;
        margin: 0;
    }

    .dots {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon {
        width: 40px;
        height: 40px;
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        border-radius: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name-table {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 26px;
        }

        span {
            font-weight: 600;
            font-size: 1em;
        }
    }

    .infos .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;

        strong {
            font-size: 0.875em;
            color: #718096;
            font-weight: 600;
        }

        span {
            color: #232b38;
            font-size: 0.875em;
            font-weight: 600;
        }
    }

    #table {
        > table {
            display: none;
        }
    }

    @media (min-width: 64em) {
        border-radius: 16px;

        .box {
            background-color: red;
            display: none;
        }

        #table {
            margin-top: 25px;
            margin-bottom: 18px;
            display: block;
            width: 100%;
            height: 700px;

            > table {
                display: table;
                width: 100%;
            }
        }

        header {
            h1 {
                font-size: 1.125em;
            }

            button {
                width: 107.29px;
                height: 34px;
            }
        }
    }
`;

export const MuiMenuHeader = styled.div`
    .menu-mui-header-workspace {
        margin-top: 9px;
        padding-bottom: 17px;
        border-bottom: 2px solid #edf2f7;
        margin-bottom: 8px;

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        a {
            font-weight: 500;
            font-size: 0.75em;
            color: #194bfb;
        }
    }

    .menu-mui-header {
        margin-top: 9px;
        padding: 0 16px 16px 16px;
        border-bottom: 1px solid #edf2f7;

        #search > div {
            height: 42px;
            margin: 0;
        }

        input {
            height: 42px;
            border: 1px solid #718096;
            border-radius: 8px;
        }

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        p {
            font-weight: 500;
            font-size: 0.75em;
            color: #718096;
        }
    }

    ul.workspace {
        li {
            border-bottom: 0;
            padding: 0;
            cursor: pointer;

            span {
                font-weight: 600;
            }
        }
    }

    ul {
        list-style: none;

        li {
            cursor: pointer;
            height: 42px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            border-bottom: 1px solid #edf2f7;

            &:hover {
                background-color: #f7fafc;
            }
        }

        li:last-of-type {
            border-bottom: 0;
        }

        span {
            color: #718096;
            font-size: 0.75em;
            font-weight: 600;
            margin-left: 12px;
        }
    }
    &.muiHeaderDark {
        ul {
            li {
                span {
                    color: #fff !important;
                }
            }
        }
    }
`;
