import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Input } from 'app/presentation/components';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    handleConfirm: (link: string) => void;
}

const ModalExternalLink: React.FC<ModalProps> = ({
    active,
    handleClose,
    handleConfirm,
}) => {
    const { register, handleSubmit, setValue } = useForm<FieldValues>();
    const { darkmode } = useDarkMode();
    const intl = useIntl();

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };

    const handleSubmitForm: SubmitHandler<FieldValues> = async data => {
        handleConfirm(data.url);
        setValue('url', '');
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'inviteMemberDark' : ''}
            >
                <div className="content-modal">
                    <header>
                        <IoMdClose size={25} onClick={handleClose} />
                    </header>
                    <div id="title">
                        <h1>{routeTranslate(intl, 'chat.link.addTitle')}</h1>
                        <p>{routeTranslate(intl, 'chat.link.addText')}</p>
                    </div>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Input
                            id="url"
                            type="url"
                            required
                            placeholder="https://www.example.com"
                            register={register}
                        />
                        <Button type="submit">
                            {routeTranslate(intl, 'chat.link.add')}
                        </Button>
                    </form>
                </div>
            </ContainerModal>
        </Fade>
    );
};

export default ModalExternalLink;
