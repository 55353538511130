import React from 'react';
import { LoadingStyled } from './styles';
import CircularProgress from '@mui/material/CircularProgress';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface LoadingProps {
    open: boolean;
    absolute?: boolean;
    size?: number | undefined;
}

const Loading: React.FC<LoadingProps> = ({
    open,
    absolute = true,
    size = undefined,
}) => {
    if (!open) return <></>;
    const { darkmode } = useDarkMode();

    return (
        <>
            <LoadingStyled absolute={absolute}>
                <div>
                    <CircularProgress
                        sx={{
                            color: darkmode ? '#fff' : '#000',
                        }}
                        size={size}
                    />
                </div>
            </LoadingStyled>
        </>
    );
};

export default Loading;
