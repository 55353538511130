import React from 'react';

export const Popover: React.FC = () => (
    <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_13095_25305)">
            <path
                d="M29.5753 0.984375H2.42377C1.35267 0.984375 0.484375 1.85267 0.484375 2.92377V30.0753C0.484375 31.1464 1.35267 32.0147 2.42377 32.0147H29.5753C30.6464 32.0147 31.5147 31.1464 31.5147 30.0753V2.92377C31.5147 1.85267 30.6464 0.984375 29.5753 0.984375Z"
                fill="white"
                stroke="#A09FA5"
            />
            <path
                d="M27.6923 9.11523H20.3077C19.628 9.11523 19.0769 9.54573 19.0769 10.0768V23.5383C19.0769 24.0694 19.628 24.4998 20.3077 24.4998H27.6923C28.3721 24.4998 28.9231 24.0694 28.9231 23.5383V10.0768C28.9231 9.54573 28.3721 9.11523 27.6923 9.11523Z"
                fill="black"
            />
            <path
                d="M27.1513 29.5917C28.2224 29.5917 29.0907 28.7234 29.0907 27.6523C29.0907 26.5812 28.2224 25.7129 27.1513 25.7129C26.0802 25.7129 25.2119 26.5812 25.2119 27.6523C25.2119 28.7234 26.0802 29.5917 27.1513 29.5917Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_13095_25305">
                <rect
                    width="32"
                    height="32"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
