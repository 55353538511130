import React from 'react';
import * as S from './styles';
import {
    RadioGroup,
    FormFieldInfo,
    Button,
    TimeZoneSelect,
} from 'app/presentation/components/agent';
import { WorkingHoursContainer } from 'app/presentation/components/agent/WorkingHoursContainer';
import { IconCheck } from '@tabler/icons-react';
import { WorkingHours } from 'services/agent/interfaces';
import { SubmitHandler, useForm } from 'react-hook-form';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';

export const WorkingHoursPanel = () => {
    const { textGetter } = useText();
    const panelText = textGetter('agent.panel.working-hours');
    const commonsText = textGetter('agent.panel.commons');

    const [workingHours, setWorkingHours] = React.useState<WorkingHours[]>([]);
    const [timeZone, setTimeZone] = React.useState<string>('-3');
    const [replyTime, setReplyTime] = React.useState<string>('one_day');

    const { agent, updateWorkingHours } = useAgent();
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    const loadForm = () => {
        if (!agent || !agent.config) return;
        setTimeZone(agent.config.time_zone);
        setReplyTime(agent.config.reply_time);
        setWorkingHours(agent.config.working_hours);
    };

    React.useEffect(() => {
        loadForm();
    }, [agent]);

    const { handleSubmit } = useForm<{}>();

    const onSubmit: SubmitHandler<{}> = async () => {
        setFormSubmitLoading(true);
        updateWorkingHours(workingHours, replyTime, timeZone).finally(() =>
            setFormSubmitLoading(false),
        );
    };

    return (
        <S.WorkingHoursPanel onSubmit={handleSubmit(onSubmit)}>
            <FormFieldInfo
                title={panelText('working-hours.title')}
                text={panelText('working-hours.subtitle')}
            ></FormFieldInfo>
            <WorkingHoursContainer
                setWorkingHours={setWorkingHours}
                workingHours={workingHours}
            ></WorkingHoursContainer>
            <FormFieldInfo
                title={panelText('timezone.title')}
                text={panelText('timezone.subtitle')}
            ></FormFieldInfo>
            <TimeZoneSelect
                onChange={setTimeZone}
                value={timeZone}
            ></TimeZoneSelect>
            <div className="reply-time-container">
                <div className="reply-time-text-container">
                    <FormFieldInfo
                        title={panelText('reply-time.title')}
                        text={panelText('reply-time.subtitle')}
                    ></FormFieldInfo>
                </div>
                <RadioGroup
                    name="reply-time"
                    radios={[
                        {
                            title: panelText('reply-time.few_minutes'),
                            value: 'few_minutes',
                        },
                        {
                            title: panelText('reply-time.few_hours'),
                            value: 'few_hours',
                        },
                        {
                            title: panelText('reply-time.one_day'),
                            value: 'one_day',
                        },
                        {
                            title: panelText('reply-time.dynamic'),
                            value: 'dynamic',
                        },
                    ]}
                    setValue={setReplyTime}
                    value={replyTime}
                ></RadioGroup>
            </div>
            <div className="buttons-container">
                <Button variant="secondary" type="button" onClick={loadForm}>
                    {commonsText('button.cancel')}
                </Button>
                <Button
                    variant="dark"
                    type="submit"
                    loading={formSubmitLoading}
                    className="save-button"
                >
                    <IconCheck></IconCheck>
                    <span>
                        {commonsText('button.save')}
                        <span className="desktop">
                            {' '}
                            {commonsText('button.save-suffix')}
                        </span>
                    </span>
                </Button>
            </div>
        </S.WorkingHoursPanel>
    );
};
