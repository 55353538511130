import styled, { css } from 'styled-components';
import { ButtonThemeVariants } from 'styles/themes/themes';

interface Props {
    variant: ButtonThemeVariants;
    fullStyled?: boolean;
    width?: string;
}

export const Button = styled.button<Props>`
    ${({
        theme: {
            components: { buttons },
        },
        variant,
    }) => css`
        color: ${buttons[variant].color};
        background-color: ${buttons[variant].background};
        border: 1px solid ${buttons[variant].border.color};
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `}

    ${({ fullStyled, width }) =>
        fullStyled &&
        css`
            border-radius: 8px;
            padding: 8px 24px 8px 24px;
            height: 44px;
            width: ${width || 'auto'};
            font-size: 14px;
            font-weight: 700;
            gap: 4px;

            svg {
                width: 20px;
                height: 20px;
            }
        `}
`;
