import { LayoutProps } from 'app/presentation/layout/interfaces/layout-default-props.interface';
import React from 'react';
import UserConfigProvider from './app/presentation/providers/UserConfigProvider';
import NotificationProvider from './app/presentation/providers/NotificationProvider';
import { ThemeProvider } from 'styled-components';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { getTheme } from 'styles/themes/themes';
import AgentProvider from 'app/presentation/providers/AgentProvider';
import LanguageProvider from 'app/presentation/providers/LanguageProvider';
import SnackbarProvider from 'app/presentation/providers/SnackbarProvider';
import TeamProvider from 'app/presentation/providers/TeamProvider';
import TagProvider from 'app/presentation/providers/TagProvider';
import MenuProvider from 'app/presentation/providers/MenuProvider';

const AgentProviders: React.FC<LayoutProps> = ({ children }) => {
    const { darkmode } = useDarkMode();
    return (
        <ThemeProvider theme={darkmode ? getTheme('dark') : getTheme('light')}>
            <MenuProvider>
                <UserConfigProvider>
                    <NotificationProvider>
                        <TeamProvider>
                            <SnackbarProvider>
                                <LanguageProvider>
                                    <TagProvider>
                                        <AgentProvider>
                                            {children}
                                        </AgentProvider>
                                    </TagProvider>
                                </LanguageProvider>
                            </SnackbarProvider>
                        </TeamProvider>
                    </NotificationProvider>
                </UserConfigProvider>
            </MenuProvider>
        </ThemeProvider>
    );
};

export default AgentProviders;
