import React from 'react';
import * as S from './styles';
import { FormFieldInfo } from '../FormField';
import CheckSVG from 'assets/images/svg/check.svg';

interface Radio {
    value: string;
    title: string;
    desc?: string;
    tooltip?: string;
    node?: React.ReactNode;
}

interface Validation {
    validate: (value: string) => boolean;
    message: string;
}

interface Props {
    name: string;
    radios: Radio[];
    value: string;
    customValidation?: Validation;
    setValue: (value: string) => void;
}

export const RadioGroup: React.FC<Props> = ({
    name,
    radios,
    value,
    setValue,
    customValidation = undefined,
}) => {
    const activeInputRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        const el = activeInputRef.current;
        if (!el || !customValidation) {
            return;
        }

        if (!customValidation.validate(el.value)) {
            el.setCustomValidity(customValidation.message);
        } else {
            el.setCustomValidity('');
        }
    }, [activeInputRef, value, customValidation]);

    const onRadioClick = (value: string) => {
        const el = activeInputRef.current;
        if (el) {
            el.setCustomValidity('');
        }

        setValue(value);
    };

    return (
        <S.RadioGroup>
            {radios.map((radio, index) => {
                return (
                    <button
                        type="button"
                        key={index}
                        className={
                            'radio-button ' +
                            (value == radio.value ? 'active' : '')
                        }
                        onClick={() => {
                            onRadioClick(radio.value);
                        }}
                    >
                        <div className="circle">
                            <img src={CheckSVG} />
                        </div>
                        <FormFieldInfo
                            title={radio.title}
                            text={radio.desc}
                            tooltip={radio.tooltip}
                        ></FormFieldInfo>
                        {radio.node}
                        <input
                            ref={
                                value == radio.value
                                    ? activeInputRef
                                    : undefined
                            }
                            className="radio-input"
                            type="radio"
                            value={radio.value}
                            name={name}
                            radioGroup={name}
                        />
                    </button>
                );
            })}
        </S.RadioGroup>
    );
};
