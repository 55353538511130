import React from 'react';

export const Pdf: React.FC = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.75 10V3.125C3.75 2.95924 3.81585 2.80026 3.93306 2.68306C4.05026 2.56585 4.20924 2.5 4.375 2.5H11.875L16.25 6.875V10"
            stroke="#FA8347"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.875 2.5V6.875H16.25"
            stroke="#FA8347"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.75 15.625H5C5.33152 15.625 5.64946 15.4932 5.88389 15.2589C6.1183 15.0245 6.25 14.7065 6.25 14.375C6.25 14.0435 6.1183 13.7255 5.88389 13.4911C5.64946 13.2568 5.33152 13.125 5 13.125H3.75V16.875"
            stroke="#FA8347"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.875 13.125H14.6875V16.875"
            stroke="#FA8347"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.5625 15.3125H14.6875"
            stroke="#FA8347"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 16.875C10.4973 16.875 10.9742 16.6775 11.3258 16.3259C11.6775 15.9743 11.875 15.4972 11.875 15C11.875 14.5028 11.6775 14.0257 11.3258 13.6741C10.9742 13.3225 10.4973 13.125 10 13.125H8.90625V16.875H10Z"
            stroke="#FA8347"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
