import React from 'react';
import { DataGrid, GridColDef, GridRowsProp, ptBR } from '@mui/x-data-grid';
import {
    createTheme,
    ThemeProvider as ThemeProviderMui,
} from '@mui/material/styles';
import { Copy, Doc, Edit, Pdf, Trash } from '../../icons';
import { TbArrowsDownUp } from 'react-icons/tb';
import { Menu, MenuList, Paper } from '@mui/material';
import { MuiMenuHeader } from '../../../../../pages/dashboard/documents/styles';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

export type UserUsage = {
    user_id: number;
    email: string;
    name: string;
    total_credits: number;
    generations: number;
};

type IDataTable = {
    data: Array<UserUsage>;
};

const themeMui = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    ptBR,
);

const OrderIcon = () => {
    return <TbArrowsDownUp style={{ color: '#718096' }} size={16} />;
};

export const TableData: React.FC<IDataTable> = ({ data }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const intl = useIntl();
    const { darkmode } = useDarkMode();
    const handleClose = () => {
        setAnchorEl(null);
    };

    const rowsData = Boolean(data.length)
        ? data?.map(d => {
              return {
                  id: d?.user_id || 0,
                  col1: d?.email || '',
                  col2: d?.total_credits || '',
                  col3: d?.generations || '',
              };
          })
        : [];

    const rows: GridRowsProp = rowsData;

    const columns: GridColDef[] = [
        {
            field: 'col1',
            flex: 1,
            headerName: routeTranslate(intl, 'team.member'),
            renderCell: param => (
                <div className="name-table">
                    <span style={{ color: darkmode ? '#fff' : '' }}>
                        {param.value}
                    </span>
                </div>
            ),
        },
        {
            field: 'col2',
            headerName: routeTranslate(intl, 'data.credit'),
            flex: 1,
            renderCell: param => (
                <div className="name-table">
                    <span style={{ color: darkmode ? '#fff' : '' }}>
                        {param.value}
                    </span>
                </div>
            ),
        },
        {
            field: 'col3',
            headerName: routeTranslate(intl, 'data.generations'),
            flex: 1,
            renderCell: param => (
                <div className="Info">
                    <span style={{ color: darkmode ? '#fff' : '' }}>
                        {param.value}
                    </span>
                </div>
            ),
        },
    ];

    return (
        <ThemeProviderMui theme={themeMui}>
            <DataGrid
                rowSpacingType="border"
                disableSelectionOnClick
                components={{
                    ColumnUnsortedIcon: OrderIcon,
                }}
                sx={{
                    border: 0,
                    '&>.MuiDataGrid-main': {
                        '&>.MuiDataGrid-columnHeaders': {
                            borderBottomColor: darkmode ? '#3A3A3A' : '#EDF2F7',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 1,
                        },

                        '& div div div div >.MuiDataGrid-cell': {
                            borderBottom: 'none',
                        },
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus:focus':
                        {
                            outline: 'none',
                        },
                    '& .MuiDataGrid-row': {
                        borderBottomColor: darkmode ? '#3A3A3A' : '#EDF2F7',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                    },
                    '& .MuiDataGrid-footerContainer': {
                        border: 0,
                    },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        color: darkmode ? '#fff' : '#718096',
                        fontWeight: 700,
                    },
                    '& .MuiDataGrid-iconButtonContainer': {
                        marginLeft: '2px',
                        visibility: 'visible !important',
                        width: 'auto !important',
                    },
                    '.MuiTablePagination-toolbar': {
                        backgroundColor: darkmode ? '#3A3A3A' : '#fff',
                        color: darkmode ? '#fff' : '',
                        borderRadius: '8px',
                    },
                }}
                rows={rows}
                columns={columns}
                rowHeight={88}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    width: '100%',
                    '& > .MuiPaper-root': {
                        borderRadius: '12px',
                        boxShadow: '8px 12px 24px rgba(93, 106, 131, 0.1)',
                    },
                }}
                anchorOrigin={{
                    vertical: 260,
                    horizontal: -50,
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Paper
                    sx={{
                        width: 160,
                        boxShadow: 0,
                    }}
                >
                    <MenuList
                        sx={{ padding: 0, boxShadow: 0 }}
                        disablePadding
                        dense
                    >
                        <MuiMenuHeader>
                            <ul>
                                <li className="icon">
                                    <Edit />
                                    <span>Editar</span>
                                </li>
                                <li className="icon">
                                    <Copy />
                                    <span>Duplicar</span>
                                </li>
                                <li className="icon">
                                    <Pdf />
                                    <span className="bold">PDF</span>
                                </li>
                                <li className="icon">
                                    <Doc />
                                    <span className="bold">Microsoft Word</span>
                                </li>
                                <li className="icon">
                                    <Trash />
                                    <span className="bold">Excluir</span>
                                </li>
                            </ul>
                        </MuiMenuHeader>
                    </MenuList>
                </Paper>
            </Menu>
        </ThemeProviderMui>
    );
};
