import React from 'react';
import { IntlProvider } from 'react-intl';
import translations from 'i18n/translations.json';
import { LANGUAGES } from 'i18n/languages';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import { Language } from './interfaces/intl-provider.interface';
import IntlContext from '../contexts/IntlContext';

const IntlProviderConfigured: React.FC<ProviderProps> = ({ children }) => {
    const [state, setState] = React.useState({ loading: true, locale: '' });

    React.useEffect(() => {
        const currentUrlLang = window.location.pathname.split('/')[1];

        const keyTypedLanguages = currentUrlLang as keyof typeof LANGUAGES;

        const currentLanguage: Language = LANGUAGES[keyTypedLanguages];
        if (!currentLanguage)
            window.location.href = `/${LANGUAGES.default.urlLang}`;
        setState({ locale: currentLanguage.code, loading: false });
    }, []);

    const locale = state.locale;
    if (state.loading) return <div>...</div>;

    const keyTyped = locale as keyof typeof translations;

    const setLanguage = (locale: string) => {
        setState({ loading: false, locale: locale });
    };

    return (
        <IntlContext.Provider
            value={{
                setLanguage,
            }}
        >
            <IntlProvider locale={locale} messages={translations[keyTyped]}>
                {children}
            </IntlProvider>
        </IntlContext.Provider>
    );
};

export default IntlProviderConfigured;
