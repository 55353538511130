import React from 'react';
import { InputStyle, Label, InputContainer } from './styles';
import { InputProps } from '../../interfaces/input-props.interface';

export const Input: React.FC<InputProps> = ({
    id,
    label,
    register,
    step,
    stepFinal,
    stepPosition,
    ...rest
}) => {
    return (
        <InputContainer>
            {stepPosition === 'top' && step && stepFinal ? (
                <div className="label-custom">
                    {label && <Label htmlFor={id}>{label}</Label>}
                    <div className="step">
                        <span>
                            {step.toString()}/{stepFinal.toString()}
                        </span>
                    </div>
                </div>
            ) : (
                <>{label && <Label htmlFor={id}>{label}</Label>}</>
            )}
            {register ? (
                <InputStyle {...register(id)} {...rest} />
            ) : (
                <InputStyle {...rest} />
            )}
            {step && stepFinal && stepPosition !== 'top' && (
                <div className="step">
                    <span>
                        {step.toString()}/{stepFinal.toString()}
                    </span>
                </div>
            )}
        </InputContainer>
    );
};
