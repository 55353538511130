import React from 'react';
import { useSnackbar } from 'notistack';
import TemplateContext from '../contexts/TemplateContext';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import {
    Entry,
    History,
    SaveTemplate,
    Template,
    TemplateSelect,
    Categories,
} from './interfaces/templates-props.interface';
import useUserConfigs from '../hooks/useUserConfigs';
import { TemplatesService } from '../../../services/templates';
import { Favorite } from '../../../interfaces/favorites.interface';
import { Rate } from '../../../interfaces/documents.interface';
import { getToken } from 'services/api';
import { idsStore } from '../stores/idsStores';

const templatesService = new TemplatesService();

const TemplateProvider: React.FC<ProviderProps> = ({ children }) => {
    const [countTemplates, setCountTemplates] = React.useState<number>(0);
    const [templates, setTemplates] = React.useState<Template[]>([]);
    const [template, setTemplate] = React.useState<
        TemplateSelect | undefined
    >();
    const [history, setHistory] = React.useState<History[] | undefined>();
    const [entries, setEntries] = React.useState<Entry[]>([]);
    const [favorites, setFavorites] = React.useState<Favorite[] | undefined>();
    const [categories, setCategories] = React.useState<
        Categories[] | undefined
    >();
    const [loading, setLoading] = React.useState(false);
    const [languages, setLanguages] = React.useState<string[] | undefined>();
    const [menu, setMenu] = React.useState(false);
    const { data } = idsStore();
    const [completions, setCompletions] = React.useState<string[]>([
        '',
        '',
        '',
        '',
        '',
    ]);

    const { enqueueSnackbar } = useSnackbar();
    const { project, settings, setModalAntecipatePlan } = useUserConfigs();

    React.useEffect(() => {
        const fetchData = async () => {
            await getTemplates(undefined, undefined);
            await getFavorites();
            await getCategories();
            await getOutputLanguage();
        };

        fetchData().catch(console.error);
    }, []);

    const showLoading = () => {
        setLoading(true);
    };

    const closeLoading = () => {
        setLoading(false);
    };

    const getTemplates = async (
        category: string | undefined,
        search: string | undefined,
    ) => {
        try {
            showLoading();

            if (templates && templates.length > 0 && search) {
                const regex = new RegExp(search, 'i'); // prepare a regex object
                const templateFilter = templates.filter((item: Template) =>
                    regex.test(item.name),
                );

                setTemplates(templateFilter);

                return;
            }

            const data = await templatesService.getAll(category);

            if (!category && !search) {
                setCountTemplates(data.length);
            }
            setTemplates(data);
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const getTemplate = async (id: number) => {
        try {
            showLoading();

            const data = await templatesService.getOne(id);

            setTemplate(data);
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const getOutputLanguage = async () => {
        const data = await templatesService.getOutputLanguages();
        setLanguages(data);
    };

    const getHistory = async (id: number) => {
        try {
            showLoading();

            const data = await templatesService.getHistory(id, project.id);

            setHistory(data);
        } catch (e: any) {
            enqueueSnackbar('Erro ao obter histórico!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const setFavorite = async (id: number) => {
        await templatesService.setFavorite(id, settings.user_id);
        await getFavorites();
    };

    const removeFavorite = async (id: number) => {
        await templatesService.removeFavorite(id, settings.user_settings.id);
        await getFavorites();
    };

    const removeHistory = async (ids: number[], idTemplate: number) => {
        if (entries) {
            ids.forEach(id => {
                const newEntries = entries.map(entrie => {
                    if (entrie.template_id === idTemplate) {
                        const entriesNew = entrie.completions.filter(
                            item => item.id !== id,
                        );

                        return {
                            ...entrie,
                            completions: entriesNew,
                        };
                    }

                    return entrie;
                });

                setEntries(newEntries);
            });
        }

        await templatesService.removeHistory(ids, project.id);
        await getHistory(idTemplate);
    };

    const getFavorites = async () => {
        const data = await templatesService.getFavorites(settings.user_id);

        setFavorites(data);

        return data;
    };

    const getCategories = async () => {
        const data = await templatesService.getCategories();

        setCategories(data);

        return data;
    };

    let resource_usages_data: any = undefined;

    const startWebSocket = (websocket: WebSocket, callback?: () => void) => {
        websocket.onmessage = function (event) {
            closeLoading();

            const { data, index, type, resource_usages, error } = JSON.parse(
                event.data,
            );

            if (error === 'not_enough_word_credits') {
                setModalAntecipatePlan(true);
                closeLoading();
                setLoading(false);
                if (callback) {
                    callback();
                }

                return;
            }

            if (resource_usages_data === undefined) {
                resource_usages_data = resource_usages;
                return;
            }

            if (error === 'plan_not_enough_rank') {
                return;
            }

            if (data === undefined) {
                console.log('Socket não retornou conteudo.');
                return;
            }

            if (template && index >= 0 && !resource_usages) {
                const array = completions;
                array[index] = array[index] + data;

                setCompletions(array);

                const arrayCompletions = completions.map(completion => {
                    return {
                        id: resource_usages_data[index]
                            ? resource_usages_data[index].resource_id
                            : 0,
                        completion: completion,
                    };
                });

                setEntries(prev => {
                    return [
                        ...prev.filter(
                            entrie =>
                                entrie.template_id !== template.template_id,
                        ),
                        {
                            template_id: template.template_id,
                            completions: [
                                ...arrayCompletions.filter(
                                    completion => completion.completion !== '',
                                ),
                            ],
                            created_at: new Date(),
                        },
                    ];
                });
            }

            if (type === 'end') {
                setCompletions(['', '', '', '', '']);

                enqueueSnackbar('Finalizado!', {
                    variant: 'success',
                });

                resource_usages_data = undefined;
                if (callback) callback();

                if (template) getHistory(template.template_id);
            }
        };
    };

    const saveTemplate = async (
        template: SaveTemplate,
        callback?: () => void,
    ) => {
        try {
            showLoading();

            const websocket = templatesService.getSocket();
            const token = await getToken();

            websocket.onopen = () => {
                websocket.send(
                    JSON.stringify({
                        authorization: {
                            token: token,
                            workspace_id: data.workspace_id,
                            project_id: data.project_id,
                        },
                        input: template,
                    }),
                );

                setLoading(true);
            };

            startWebSocket(websocket, callback);
        } catch (e) {
            enqueueSnackbar('Erro ao executar modelo!', {
                variant: 'error',
            });
            setLoading(false);

            closeLoading();
        } finally {
            closeLoading();
        }
    };

    const rateResponse = async (rate: Rate) => {
        try {
            if (!template) return;

            showLoading();

            await templatesService.rateResponse(rate);

            enqueueSnackbar('Avaliação enviada com sucesso!', {
                variant: 'success',
            });

            await getHistory(template.template_id);
        } catch (e) {
            enqueueSnackbar('Erro ao avaliar modelo!', {
                variant: 'error',
            });
        } finally {
            closeLoading();
        }
    };

    const cleanEntries = () => {
        setEntries([]);
    };

    return (
        <TemplateContext.Provider
            value={{
                getTemplates,
                getTemplate,
                saveTemplate,
                templates,
                template,
                getHistory,
                removeHistory,
                history,
                cleanEntries,
                entries,
                countTemplates,
                favorites,
                setFavorite,
                removeFavorite,
                rateResponse,
                categories,
                loading,
                showLoading,
                closeLoading,
                languages,
                setMenu,
                menu,
            }}
        >
            {children}
        </TemplateContext.Provider>
    );
};

export default TemplateProvider;
