import React from 'react';
import {
    Button,
    Input,
    InputTags,
    Select as CustomSelect,
} from 'app/presentation/components';
import { IoIosArrowBack, IoMdClose } from 'react-icons/io';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { TbBrandYoutube } from 'react-icons/tb';
import {
    Copy,
    Document,
    Like,
    UnLike,
} from 'app/presentation/components/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { ContainerModalLib, Empty, FieldsTemplate } from './styles';
import InputAdd from 'app/presentation/components/dashboard/InputAdd';
import useTemplates from 'app/presentation/hooks/useTemplates';
import ModalEditHistory from 'app/presentation/components/dashboard/ModalEditHistory';
import UseUserConfigs from 'app/presentation/hooks/useUserConfigs';
import { timeDifference } from '../../../../utils/time';
import EmptyImage from '../../../../assets/images/svg/empty-entries.svg';
import EmptyImageDark from 'assets/images/svg/empty-entries-dark.svg';
import { useIntl } from 'react-intl';
import { routeTranslate } from '../../../../utils/intl';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Tooltip from '@mui/material/Tooltip';
import Loading from '../../../../app/presentation/components/common/Loading';
import { copyToClipboard } from 'utils/copyToClipboard';
import { useSnackbar } from 'notistack';
import ModalTutorialVideo from 'app/presentation/components/dashboard/ModalTutorialVideo';
import { SaveTemplate } from '../../../../app/presentation/providers/interfaces/templates-props.interface';
import useDocuments from '../../../../app/presentation/hooks/useDocuments';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import useDocumentTitle from 'utils/useDocumentTitle';
import {
    MemorieItem,
    MemorieItemObj,
    VoiceTone,
} from 'app/presentation/hooks/interfaces/brand';
import { VoiceToneServices } from 'services/brand/voice_tone';
import DropdownMemories from 'app/presentation/components/dashboard/DropdownMemories';
import { HighlightWithinTextarea } from 'react-highlight-within-textarea';
import { replaceTitlesWithContent } from 'utils/others';

const voiceToneService = new VoiceToneServices();

const Model: React.FC = () => {
    const [activeModalHistory, setModalHistory] = React.useState(false);
    const [activeModalVideo, setModalVideo] = React.useState(false);
    const [examples, setExamples] = React.useState<string[] | undefined>();
    const [textHistory, setTextHistory] = React.useState('');
    const [tab, setTabs] = React.useState<'history' | 'entries'>('entries');
    const [tone, setTone] = React.useState('');
    const [creatableValue, setCreatableValue] = React.useState('');
    const [idHistory, setIdHistory] = React.useState<number>();
    const [submiting, setSubmiting] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { handleSelectDocument, newDocument } = useDocuments();
    const [tags, setTags] = React.useState<string[]>();
    const [, setTextAreaKey] = React.useState('');
    const { darkmode } = useDarkMode();
    const { id } = useParams();
    const { workspace, project, activeBrand, brands } = UseUserConfigs();
    const workspaceId = workspace.id;
    const projectId = project.id;
    const {
        getTemplate,
        template,
        getHistory,
        history,
        entries,
        saveTemplate,
        cleanEntries,
        rateResponse,
        loading: loadingTemplate,
        languages,
    } = useTemplates();
    const possibleItens = [
        'nome da empresa',
        'nome de empresa',
        'nome da empresa ou produto',
        'nome de empresa ou produto',
        'company/product name',
        'nome para empresa',
        'company name',
    ];
    const intl = useIntl();
    const navigate = useNavigate();
    const { register, watch, handleSubmit, setValue } = useForm<FieldValues>();
    const { Option } = components;
    const IconOption = (props: any) => (
        <Option {...props}>
            <div className="toneOptions">
                <div>
                    <p>
                        {props.data.__isNew__
                            ? routeTranslate(intl, 'use').concat(
                                  ` ${props.data.value}`,
                              )
                            : props.data.name}
                    </p>
                    {props.data.is_default && (
                        <span>
                            {routeTranslate(intl, 'brand.voiceTone.default')}
                        </span>
                    )}
                </div>
            </div>
        </Option>
    );
    const [valueTextArea2, setValueTextArea2] = React.useState<any>(null);
    const [allMemories, setAllMemories] = React.useState<MemorieItemObj>();
    const [arrMemoriesHighlight, setArrMemoriesHighlight] =
        React.useState<any>();

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            background: darkmode ? '#000' : '',
            borderColor: darkmode ? '#000' : '',
            height: 45,
            minHeight: 45,
            border: state.isFocused ? '1px solid #000' : '1px solid #cccccc',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: state.isFocused
                    ? '1px solid #000'
                    : '1px solid #cccccc',
            },
            borderRadius: 6,
            padding: 0,
        }),
        menuList: (base: any) => ({
            ...base,
            background: darkmode ? '#000' : '',
        }),
    };
    const SingleValue = ({ ...props }: any) => (
        <components.SingleValue {...props}>
            <section
                style={{ display: 'flex', alignItems: 'center', marginTop: 3 }}
            >
                <p style={{ fontWeight: 500, fontSize: 14, marginTop: -2 }}>
                    {props.data.__isNew__ ? props.data.value : props.data.name}
                </p>

                {props.data.is_default && (
                    <span
                        style={{
                            marginLeft: 8,
                            marginTop: -2,
                            fontSize: 12,
                            borderRadius: 12,
                            height: 18,
                            background: '#d0ff61',
                            paddingRight: 8,
                            paddingLeft: 8,
                            fontWeight: 600,
                        }}
                    >
                        {routeTranslate(intl, 'brand.voiceTone.default')}
                    </span>
                )}
            </section>
        </components.SingleValue>
    );

    const handleChangeTone = (e: any) => {
        if (e?.__isNew__) {
            setTone(e.value);
        } else if (e) {
            setTone(e.content);
        }
    };

    const handleCreatableValue = (e: any) => {
        if (e?.__isNew__) {
            setCreatableValue(e.value);
        } else if (e) {
            setCreatableValue(e.name);
        }
    };

    const watchLanguage = watch('language') ?? '';
    const watchWord = watch('words') ?? '';
    const currentPageName = routeTranslate(intl, 'route.library').replace(
        '/',
        '',
    );
    const pageTitle =
        currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1);
    useDocumentTitle(pageTitle);
    const [voices, setVoices] = React.useState<VoiceTone[]>();

    const copyContent = (value: string) => {
        const copy = copyToClipboard(value);
        if (copy) {
            enqueueSnackbar(routeTranslate(intl, 'copy.success'), {
                variant: 'success',
            });
        }
    };

    if (!id) return <></>;

    React.useEffect(() => {
        const fetchData = async () => {
            await getTemplate(Number(id));
        };

        setValue('quantity-number', '2');

        fetchData().catch(console.error);
    }, []);

    React.useEffect(() => {
        if (template && template.template_fields) {
            const arr = template.template_fields
                .filter(item => item.data_type === 'textarea')
                .reduce((obj: any, item) => {
                    obj[item.key] = '';
                    return obj;
                }, {});

            setValueTextArea2(arr);
        }
    }, [template]);

    const getVoices = async () => {
        if (activeBrand) {
            try {
                const data = await voiceToneService.getAll(
                    workspaceId,
                    projectId,
                    activeBrand.id,
                );

                if (data instanceof Error) {
                    throw new Error('error to get voices');
                }

                setVoices(data);
            } catch (error) {
                enqueueSnackbar(
                    routeTranslate(intl, 'brand.voiceTone.getAll.error'),
                    {
                        variant: 'error',
                    },
                );
            }
        }
    };

    React.useEffect(() => {
        getVoices();
    }, [workspaceId, projectId, activeBrand]);
    const handleCloseModalHistory = () => {
        setModalHistory(false);
    };

    const handleModalHistory = (text: string, id?: number) => {
        setModalHistory(true);
        setTextHistory(text);

        if (id) {
            setIdHistory(id);
        }
    };

    const handleSubmitForm: SubmitHandler<FieldValues> = async () => {
        if (!template || !languages) return;

        try {
            setSubmiting(true);

            const handleChange = (value: string): string => {
                if (allMemories) {
                    // Flatten the object into an array
                    const flatArray = Object.values(allMemories).flat();

                    // Map over the array and pick only the id and title
                    const simplifiedArray = flatArray.map(
                        ({ title, content }) => ({
                            title: '@'.concat(title),
                            content,
                        }),
                    );

                    const outputString = replaceTitlesWithContent(
                        value,
                        simplifiedArray,
                    );
                    return outputString;
                } else {
                    return value;
                }
            };

            const template_fields_format = template?.template_fields.map(
                fields => {
                    const valueToUse =
                        fields.data_type === 'textarea' &&
                        (fields.key.toUpperCase() === 'CONTENT' ||
                            fields.key.toUpperCase() === 'BENEFITS')
                            ? handleChange(valueTextArea2[fields.key])
                            : watch(fields.key);

                    const isCreatable = possibleItens.includes(
                        fields.translation.toLowerCase(),
                    );

                    return {
                        key: fields.key,
                        value: isCreatable ? creatableValue : valueToUse,
                    };
                },
            );

            const templateFormat: SaveTemplate = {
                template_id: template.template_id,
                count: Number(watch('quantity-number')),
                project_id: project.id,
                output_language: languages[watchLanguage],
                template_fields: template_fields_format,
            };

            if (template?.has_voice_tone && tone !== '') {
                templateFormat['voice_tone'] = tone;
            }

            if (template?.has_examples) {
                templateFormat['examples'] = examples;
            }

            if (template.has_keywords) {
                templateFormat['keywords'] = await saveFields(tags);
            }

            await saveTemplate(templateFormat, () => {
                setSubmiting(false);
                setCreatableValue('');
                setTone('');
            });
        } catch (e: any) {
            setSubmiting(false);
        }
    };

    const handleChange = (values: string[]) => {
        setExamples(values);
    };

    const handleHistory = async () => {
        setTabs('history');
        await getHistory(Number(id));
    };

    const handleRate = async (resource_usage_id: number, is_good: boolean) => {
        const request = {
            resource_usage_id,
            is_good,
            rewrite_proposal: '',
        };

        await rateResponse(request);
    };

    const handleCloseModalVideo = () => {
        setModalVideo(false);
    };

    function removeByIndex(str: string, index: number) {
        return str.slice(0, index) + str.slice(index + 1);
    }

    const handleEdit = (id?: number, rtc_id?: string) => {
        if (!rtc_id || !id) return;

        const path =
            routeTranslate(intl, 'route.urlLang') +
            routeTranslate(intl, 'route.backoffice');

        navigate(
            `/${path}${routeTranslate(intl, 'route.documents')}${routeTranslate(
                intl,
                'route.document',
            )}/${rtc_id}/${id}`,
        );
    };

    const handleTags = async (value: string) => {
        setValue('words', value.trim());

        const positionFirstCommomWord = value.indexOf(',');
        if (positionFirstCommomWord === 0) {
            setValue('words', removeByIndex(value, positionFirstCommomWord));
        }

        if (
            value[value.length - 1] === ',' &&
            value[value.length - 2] === ','
        ) {
            setValue('words', value.substring(0, value.length - 1));
        }

        if (value.split(',').length !== 1) {
            if ((tags && tags.length > 4) || value === ',') {
                setValue('words', '');
                return;
            }

            setValue('words', '');

            if (tags) {
                setTags([...tags, value.split(',')[0]]);

                await saveFields([...tags, value.split(',')[0]]);
            } else {
                setTags([value.split(',')[0]]);

                await saveFields([value.split(',')[0]]);
            }
        }
    };
    const saveFields = async (keywords?: string[]) => {
        const value = keywords ? keywords.join(', ') : undefined;
        return value;
    };

    const handleRemoveTag = async (index: number) => {
        if (!tags) return;

        const tagsArray = tags;
        tagsArray.splice(index, 1);

        setTags([...tagsArray]);

        await saveFields([...tagsArray]);
    };
    const exitQtd = Number(watch('quantity-number'));

    const handleEditInDocument = async (text: string) => {
        const request = { title: 'Novo Documento', content: text.trim() };
        const documentResponse = await newDocument(request);

        if (documentResponse && documentResponse.id) {
            handleSelectDocument(documentResponse.id);
            handleEdit(documentResponse.id, documentResponse.rtc_id);
        }
    };

    const handleBack = async () => {
        navigate(-1);
    };

    const handleSetTextAreaValue = (item: MemorieItem, key?: string) => {
        if (key) {
            setValueTextArea2({
                ...valueTextArea2,
                [key]: valueTextArea2[key].concat(` @${item.title} `),
            });
        }
    };

    React.useEffect(() => {
        if (allMemories) {
            // Flatten the object into an array
            const flatArray = Object.values(allMemories).flat();

            // Map over the array and pick only the id and title
            const simplifiedArray = flatArray.map(({ title }) => ({
                highlight: '@'.concat(title),
                className: 'highlightedText',
            }));
            setArrMemoriesHighlight(simplifiedArray);
        }
    }, [allMemories]);

    const onChange2 = (value: string, key: string) => {
        setValueTextArea2({ ...valueTextArea2, [key]: value });
    };

    return (
        <ContainerModalLib className={darkmode ? 'containerModalLibDark' : ''}>
            <form
                onSubmit={handleSubmit(handleSubmitForm)}
                className="content-lib-model"
                style={{ background: darkmode ? '#000' : undefined }}
            >
                <div className="form">
                    <div className="fields">
                        <div>
                            <div className="feature-header">
                                <div id="back">
                                    <Tooltip
                                        title={routeTranslate(
                                            intl,
                                            'tooltip.allDocuments',
                                        )}
                                        placement="bottom"
                                        sx={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        }}
                                        PopperProps={{
                                            sx: {
                                                '& .MuiTooltip-tooltip': {
                                                    backgroundColor: darkmode
                                                        ? '#fff'
                                                        : '#000',
                                                    color: darkmode
                                                        ? '#000 '
                                                        : '#fff',
                                                },
                                            },
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -10],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <IoIosArrowBack
                                            size={25}
                                            onClick={handleBack}
                                            color={darkmode ? '#fff' : ''}
                                        />
                                    </Tooltip>
                                </div>
                                <div className="icon">
                                    <img
                                        src={template?.image_url}
                                        width={50}
                                        height={50}
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <h1>{template?.name}</h1>
                                    <p>{template?.description}</p>
                                </div>
                            </div>
                            <FieldsTemplate className="fieldsDark">
                                {template?.template_fields?.map(
                                    (fields, index) => {
                                        if (
                                            possibleItens.includes(
                                                fields.translation.toLowerCase(),
                                            ) &&
                                            brands
                                        ) {
                                            return (
                                                <div className="tone-section">
                                                    <p
                                                        style={{
                                                            marginBottom: 15,
                                                            color: darkmode
                                                                ? '#fff'
                                                                : undefined,
                                                        }}
                                                    >
                                                        {fields.translation.concat(
                                                            ' *',
                                                        )}
                                                    </p>

                                                    <CreatableSelect
                                                        isClearable
                                                        id={fields.key}
                                                        classNamePrefix="an-simple-select"
                                                        placeholder={routeTranslate(
                                                            intl,
                                                            'selectortype',
                                                        )}
                                                        options={brands.filter(
                                                            b =>
                                                                b.id ===
                                                                activeBrand?.id,
                                                        )}
                                                        onChange={
                                                            handleCreatableValue
                                                        }
                                                        components={{
                                                            Option: IconOption,
                                                            SingleValue,
                                                            IndicatorSeparator:
                                                                () => null,
                                                        }}
                                                        styles={customStyles}
                                                        noOptionsMessage={() =>
                                                            routeTranslate(
                                                                intl,
                                                                'lib.select.noOption',
                                                            )
                                                        }
                                                        required
                                                    />
                                                </div>
                                            );
                                        } else if (
                                            fields.data_type === 'input_text'
                                        ) {
                                            return (
                                                <Input
                                                    label={fields.translation.concat(
                                                        ' *',
                                                    )}
                                                    id={fields.key}
                                                    type="text"
                                                    placeholder={
                                                        fields.description
                                                    }
                                                    register={register}
                                                    key={index}
                                                    maxLength={80}
                                                    stepPosition="top"
                                                    step={
                                                        watch(fields.key)
                                                            ? watch(
                                                                  fields.key,
                                                              ).length.toString()
                                                            : '0'
                                                    }
                                                    stepFinal="80"
                                                    required
                                                />
                                            );
                                        } else if (
                                            fields.data_type === 'textarea'
                                        ) {
                                            return (
                                                <div className="textAreaContainer">
                                                    <p className="titleTextArea">
                                                        {fields.translation.concat(
                                                            ' *',
                                                        )}
                                                    </p>

                                                    <div className="textAreaContent">
                                                        <HighlightWithinTextarea
                                                            value={
                                                                valueTextArea2 &&
                                                                valueTextArea2[
                                                                    fields.key
                                                                ]
                                                                    ? valueTextArea2[
                                                                          fields
                                                                              .key
                                                                      ]
                                                                    : ''
                                                            }
                                                            onChange={value =>
                                                                onChange2(
                                                                    value,
                                                                    fields.key,
                                                                )
                                                            }
                                                            highlight={
                                                                arrMemoriesHighlight
                                                            }
                                                            placeholder=""
                                                        />
                                                    </div>

                                                    <div
                                                        className={`btn ${
                                                            !activeBrand &&
                                                            'flexStartText'
                                                        }`}
                                                    >
                                                        <DropdownMemories
                                                            setContent={
                                                                handleSetTextAreaValue
                                                            }
                                                            fieldKey={
                                                                fields.key
                                                            }
                                                            setFieldKey={
                                                                setTextAreaKey
                                                            }
                                                            setItemsMemories={
                                                                setAllMemories
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                    },
                                )}
                                {template?.has_voice_tone && (
                                    <div className="tone-section">
                                        <p
                                            style={{
                                                marginBottom: 15,
                                                color: darkmode
                                                    ? '#fff'
                                                    : undefined,
                                            }}
                                        >
                                            {routeTranslate(
                                                intl,
                                                'voiceTone.label',
                                            )}
                                        </p>

                                        <CreatableSelect
                                            isClearable
                                            id="selectTone"
                                            classNamePrefix="an-simple-select"
                                            placeholder={routeTranslate(
                                                intl,
                                                'selectortype',
                                            )}
                                            options={voices ? voices : []}
                                            components={{
                                                Option: IconOption,
                                                SingleValue,
                                                IndicatorSeparator: () => null,
                                            }}
                                            styles={customStyles}
                                            onChange={handleChangeTone}
                                            noOptionsMessage={() =>
                                                routeTranslate(
                                                    intl,
                                                    'lib.select.noOption',
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                {template?.has_keywords && (
                                    <InputTags
                                        label={routeTranslate(
                                            intl,
                                            'document.editor.keyWords',
                                        )}
                                        removeTag={handleRemoveTag}
                                        id="words"
                                        onChange={async e => {
                                            await register('words').onChange(e);
                                            await handleTags(e.target.value);
                                        }}
                                        tags={tags}
                                        type="text"
                                        placeholder=""
                                        register={register}
                                        maxLength={
                                            watchWord.split(',').length - 1 ===
                                            4
                                                ? watchWord.length
                                                : 'false'
                                        }
                                        step={(tags?.length ?? 0).toString()}
                                        stepFinal="5"
                                    />
                                )}
                                {template?.has_examples && (
                                    <InputAdd
                                        max={3}
                                        handleChange={handleChange}
                                    />
                                )}
                                {languages && (
                                    <CustomSelect
                                        label={routeTranslate(
                                            intl,
                                            'label.language',
                                        )}
                                        id="language"
                                        placeholder="Português (BR)"
                                        register={register}
                                        options={languages.map(language => {
                                            return { name: language };
                                        })}
                                    />
                                )}
                            </FieldsTemplate>
                            <div className="toolbar first">
                                <div
                                    className="header"
                                    style={{ paddingBottom: 0 }}
                                >
                                    <Button
                                        id="clean"
                                        type="reset"
                                        icon={<IoMdClose />}
                                    >
                                        {routeTranslate(
                                            intl,
                                            'button.clear.fields',
                                        )}
                                    </Button>

                                    <div id="quatity">
                                        <label
                                            data-domain={routeTranslate(
                                                intl,
                                                'label.exitQuantity',
                                            ).concat(exitQtd > 1 ? 's' : '')}
                                        >
                                            <Input
                                                id="quantity-number"
                                                type="number"
                                                register={register}
                                                min={1}
                                                max={5}
                                            />
                                        </label>
                                        <Button
                                            icon={
                                                <HiArrowNarrowRight size={20} />
                                            }
                                            loading={submiting}
                                        >
                                            {routeTranslate(
                                                intl,
                                                'button.create',
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="active editor">
                        <div className="toolbar">
                            <div className="options">
                                <span
                                    className={
                                        tab === 'entries'
                                            ? 'link active'
                                            : 'link'
                                    }
                                    onClick={() => setTabs('entries')}
                                >
                                    {routeTranslate(intl, 'button.entries')}
                                </span>
                                <span
                                    className={
                                        tab === 'history'
                                            ? 'option active'
                                            : 'option'
                                    }
                                    onClick={handleHistory}
                                >
                                    {routeTranslate(intl, 'button.history')}
                                </span>
                                {tab === 'entries' &&
                                entries &&
                                entries.length > 0 ? (
                                    <span
                                        onClick={cleanEntries}
                                        className="option"
                                        style={{
                                            border: darkmode
                                                ? '1px solid #fff'
                                                : '',
                                            color: darkmode ? '#fff' : '',
                                        }}
                                    >
                                        {routeTranslate(intl, 'button.clear')}
                                    </span>
                                ) : (
                                    <span></span>
                                )}
                            </div>
                        </div>
                        <div id="appetizer">
                            {loadingTemplate && (
                                <Loading open={loadingTemplate} />
                            )}
                            {!loadingTemplate && tab === 'history' && (
                                <>
                                    {history && history.length > 0 ? (
                                        history.map((item, index) => {
                                            const isLiked =
                                                item.feedback_status === 'good';
                                            const isUnliked =
                                                item.feedback_status === 'bad';

                                            return (
                                                <div
                                                    className="box"
                                                    key={index}
                                                >
                                                    <div
                                                        onClick={() =>
                                                            handleModalHistory(
                                                                item.response,
                                                                item.id,
                                                            )
                                                        }
                                                    >
                                                        <p>
                                                            {item.response.trim()}
                                                        </p>
                                                    </div>
                                                    <div className="footer">
                                                        <div
                                                            onClick={() =>
                                                                handleModalHistory(
                                                                    item.response,
                                                                    item.id,
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                {timeDifference(
                                                                    new Date(),
                                                                    new Date(
                                                                        item.created_at,
                                                                    ),
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="option-box">
                                                            <div
                                                                onClick={() => {
                                                                    return handleRate(
                                                                        item.id,
                                                                        true,
                                                                    );
                                                                }}
                                                            >
                                                                <Like
                                                                    liked={
                                                                        isLiked
                                                                    }
                                                                    darkmode={
                                                                        darkmode
                                                                    }
                                                                />
                                                            </div>

                                                            <div
                                                                onClick={() => {
                                                                    return handleRate(
                                                                        item.id,
                                                                        false,
                                                                    );
                                                                }}
                                                            >
                                                                <UnLike
                                                                    unliked={
                                                                        isUnliked
                                                                    }
                                                                    darkmode={
                                                                        darkmode
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                onClick={() =>
                                                                    copyContent(
                                                                        item.response.trim(),
                                                                    )
                                                                }
                                                            >
                                                                <Copy
                                                                    hideTooltip={
                                                                        false
                                                                    }
                                                                    darkmode={
                                                                        darkmode
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                onClick={() =>
                                                                    handleEditInDocument(
                                                                        item.response,
                                                                    )
                                                                }
                                                            >
                                                                <Document
                                                                    hideTooltip={
                                                                        false
                                                                    }
                                                                    darkmode={
                                                                        darkmode
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <Empty
                                            style={{
                                                background: darkmode
                                                    ? '#000'
                                                    : '',
                                            }}
                                        >
                                            <img
                                                src={
                                                    darkmode
                                                        ? EmptyImageDark
                                                        : EmptyImage
                                                }
                                            />
                                            <h1>
                                                {routeTranslate(
                                                    intl,
                                                    'create.together',
                                                )}
                                            </h1>
                                            <p
                                                style={{
                                                    color: darkmode
                                                        ? '#cccccc'
                                                        : '',
                                                }}
                                            >
                                                {routeTranslate(
                                                    intl,
                                                    'create.together.description',
                                                )}
                                            </p>

                                            {activeModalVideo && (
                                                <div className="container-submit">
                                                    <Button
                                                        type="button"
                                                        icon={
                                                            <TbBrandYoutube
                                                                size={20}
                                                            />
                                                        }
                                                    >
                                                        {routeTranslate(
                                                            intl,
                                                            'button.tutorial',
                                                        )}
                                                    </Button>
                                                </div>
                                            )}
                                        </Empty>
                                    )}
                                </>
                            )}
                            {!loadingTemplate && tab === 'entries' && (
                                <>
                                    {entries && entries.length > 0 ? (
                                        entries
                                            .filter(
                                                item =>
                                                    item.template_id ===
                                                    Number(id),
                                            )
                                            .reverse()
                                            .map(item => {
                                                return item.completions.map(
                                                    (completion, index) => {
                                                        const arrFilter =
                                                            history?.filter(
                                                                his =>
                                                                    his.id ===
                                                                    completion.id,
                                                            )[0];

                                                        const isLiked =
                                                            arrFilter?.feedback_status ===
                                                            'good';
                                                        const isUnliked =
                                                            arrFilter?.feedback_status ===
                                                            'bad';
                                                        return (
                                                            <div
                                                                className="box entry"
                                                                key={index}
                                                            >
                                                                <div
                                                                    onClick={() =>
                                                                        handleModalHistory(
                                                                            completion.completion,
                                                                            completion.id,
                                                                        )
                                                                    }
                                                                >
                                                                    <p>
                                                                        {completion.completion.trim()}
                                                                    </p>
                                                                </div>
                                                                <div className="footer">
                                                                    <div
                                                                        onClick={() =>
                                                                            handleModalHistory(
                                                                                completion.completion,
                                                                                completion.id,
                                                                            )
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {timeDifference(
                                                                                new Date(),
                                                                                new Date(
                                                                                    item.created_at,
                                                                                ),
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <div className="option-box">
                                                                        <div
                                                                            onClick={() => {
                                                                                return handleRate(
                                                                                    completion.id,
                                                                                    true,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <Like
                                                                                liked={
                                                                                    isLiked
                                                                                }
                                                                                darkmode={
                                                                                    darkmode
                                                                                }
                                                                            />
                                                                        </div>

                                                                        <div
                                                                            onClick={() => {
                                                                                return handleRate(
                                                                                    completion.id,
                                                                                    false,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <UnLike
                                                                                unliked={
                                                                                    isUnliked
                                                                                }
                                                                                darkmode={
                                                                                    darkmode
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            onClick={() =>
                                                                                copyContent(
                                                                                    completion.completion.trim(),
                                                                                )
                                                                            }
                                                                        >
                                                                            <Copy
                                                                                hideTooltip={
                                                                                    false
                                                                                }
                                                                                darkmode={
                                                                                    darkmode
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            onClick={() =>
                                                                                handleEditInDocument(
                                                                                    completion.completion,
                                                                                )
                                                                            }
                                                                        >
                                                                            <Document
                                                                                hideTooltip={
                                                                                    false
                                                                                }
                                                                                darkmode={
                                                                                    darkmode
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    },
                                                );
                                            })
                                    ) : (
                                        <Empty
                                            style={{
                                                background: darkmode
                                                    ? '#000'
                                                    : '',
                                            }}
                                        >
                                            <img
                                                src={
                                                    darkmode
                                                        ? EmptyImageDark
                                                        : EmptyImage
                                                }
                                            />
                                            <h1>
                                                {routeTranslate(
                                                    intl,
                                                    'create.together',
                                                )}
                                            </h1>
                                            <p
                                                style={{
                                                    color: darkmode
                                                        ? '#cccccc'
                                                        : '',
                                                }}
                                            >
                                                {routeTranslate(
                                                    intl,
                                                    'create.together.description',
                                                )}
                                            </p>
                                            {activeModalVideo && (
                                                <div className="container-submit">
                                                    <Button
                                                        type="button"
                                                        icon={
                                                            <TbBrandYoutube
                                                                size={20}
                                                            />
                                                        }
                                                    >
                                                        {routeTranslate(
                                                            intl,
                                                            'button.tutorial',
                                                        )}
                                                    </Button>
                                                </div>
                                            )}
                                        </Empty>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
            <ModalEditHistory
                idTemplate={template?.template_id}
                idHistory={idHistory}
                title={template?.name}
                image={template?.image_url}
                text={textHistory}
                active={activeModalHistory}
                handleClose={handleCloseModalHistory}
            />
            <ModalTutorialVideo
                active={activeModalVideo}
                handleClose={handleCloseModalVideo}
                videoLink={template?.video_url}
            />
        </ContainerModalLib>
    );
};

export default Model;
