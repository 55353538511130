import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .content-modal {
        width: fit-content;
        background-color: #ffffff;
        border-radius: 16px;
        padding: 15px;
    }
`;
