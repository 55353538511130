import React from 'react';
import { ArteAiContextData } from '../contexts/interfaces/arte-ai-context-props.interface';
import ArteAiContext from '../contexts/ArteAiContext';

function useArteAi(): ArteAiContextData {
    const context = React.useContext(ArteAiContext);

    if (!context) {
        throw new Error('useArteAi deve ser usado com o ArteAiProvider');
    }

    return context;
}

export default useArteAi;
