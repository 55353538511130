import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoIosArrowForward, IoMdClose } from 'react-icons/io';
import { StartBlog, StartDocument } from 'app/presentation/components/icons';
import { useNavigate } from 'react-router-dom';
import { routeTranslate } from '../../../../../utils/intl';
import { useIntl } from 'react-intl';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
}

const ModalNewDocument: React.FC<ModalProps> = ({ active, handleClose }) => {
    const navigate = useNavigate();
    const intl = useIntl();

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.documents');

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };

    const handleNewDocument = () => {
        handleClose();
        navigate(`/${path}${routeTranslate(intl, 'route.document')}`);
    };

    const handleNewPostBlog = () => {
        navigate(`/${path}${routeTranslate(intl, 'route.newPostBlog')}`);
        handleClose();
    };

    return (
        <>
            <Fade in={active} timeout={400}>
                <ContainerModal onClick={handleCloseContainer}>
                    <div className="content-modal">
                        <header>
                            <IoMdClose size={25} onClick={handleClose} />
                        </header>
                        <div id="title">
                            <h1>
                                {routeTranslate(intl, 'document.modal.title')}
                            </h1>
                            <p>
                                {routeTranslate(
                                    intl,
                                    'document.modal.description',
                                )}
                            </p>
                        </div>
                        <div className="box active" onClick={handleNewDocument}>
                            <div className="icon">
                                <StartDocument />
                            </div>
                            <div className="desc">
                                <h1>
                                    {routeTranslate(
                                        intl,
                                        'document.modal.newOne',
                                    )}
                                </h1>
                                <p>
                                    {routeTranslate(
                                        intl,
                                        'document.modal.newOne.desc',
                                    )}
                                </p>
                            </div>
                            <div>
                                <IoIosArrowForward
                                    size={23}
                                    style={{ color: '#A0AEC0' }}
                                />
                            </div>
                        </div>
                        <div className="box" onClick={handleNewPostBlog}>
                            <div className="icon">
                                <StartBlog />
                            </div>
                            <div className="desc">
                                <h1>
                                    {routeTranslate(
                                        intl,
                                        'document.modal.flow',
                                    )}
                                </h1>
                                <p>
                                    {routeTranslate(
                                        intl,
                                        'document.modal.flow.desc',
                                    )}
                                </p>
                            </div>
                            <div>
                                <IoIosArrowForward
                                    size={23}
                                    style={{ color: '#A0AEC0' }}
                                />
                            </div>
                        </div>
                    </div>
                </ContainerModal>
            </Fade>
        </>
    );
};

export default ModalNewDocument;
