import React from 'react';
import { ChatContextData } from '../contexts/interfaces/chat-context-props.interface';
import ChatContext from '../contexts/ChatContext';

function useChat(): ChatContextData {
    const context = React.useContext(ChatContext);

    if (!context) {
        throw new Error('useChat deve ser usado com o ChatProvider');
    }

    return context;
}

export default useChat;
