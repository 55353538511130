import React from 'react';
import { HeaderContainer } from './styles';
import { IconLogo } from 'app/presentation/components/icons';
import { Button } from 'app/presentation/components/index';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { routeTranslate } from '../../../../../utils/intl';
import useDarkMode from '../../../hooks/useDarkMode';

const Header: React.FC = () => {
    const intl = useIntl();
    const { darkmode } = useDarkMode();

    return (
        <HeaderContainer style={{ background: darkmode ? '#000' : '' }}>
            <nav className="content">
                <div className="ds-flex">
                    <IconLogo fill={darkmode ? '#fff' : '#1A202C'} />
                    <Link
                        title={routeTranslate(intl, 'signup.loginCall')}
                        to={`/${routeTranslate(
                            intl,
                            'route.urlLang',
                        )}${routeTranslate(intl, 'route.signin')}`}
                    >
                        <Button>Entrar</Button>
                    </Link>
                </div>
                <div className="clear"></div>
            </nav>
        </HeaderContainer>
    );
};

export default Header;
