import styled, { css } from 'styled-components';

export const DateRangePicker = styled.div`
    display: flex;
    gap: 10px;
    width: fit-content;   
`;

interface DayContainerProps {
    isFirstDay: boolean;
    isLastDay: boolean;
    isHovered: boolean;
    isInInterval: boolean;
    outsideCurrentMonth: boolean;
    day: Day;
}

export const DayContainer = styled.div<DayContainerProps>`
    ${({isFirstDay, isLastDay, isHovered, isInInterval, outsideCurrentMonth, day}) => css`
        ${isInInterval ? 'background-color: rgb(244, 244, 245)' : ''};
        border-radius:  ${isFirstDay || day == 0 ?  '5px' : '0px'} 
                        ${isLastDay || day == 6 ?  '5px 5px' : '0px 0px'} 
                        ${isFirstDay || day == 0 ?  '5px' : '0px'};
        margin-bottom: 1.5px;
        .btn-container {
            ${isHovered ? 'background-color: rgb(244, 244, 245)' : ''};
            ${!outsideCurrentMonth && (isFirstDay || isLastDay) ? 'background-color: #000' : ''};
            border-radius: 6px;
            .pickers-day {
                ${isFirstDay || isLastDay ? 'color: #fff' : '#000'};
                background-color: inherit !important;
                border: none !important;
            }
            font-size: 20px !important;
        }  
    `}
`;