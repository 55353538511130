import React from 'react';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import { Input, Button, TextArea } from 'app/presentation/components/common';
import { CheckBox } from 'app/presentation/components/mui';
import DoneIcon from '@mui/icons-material/Done';
import { BsCircle } from 'react-icons/bs';
import { VoiceToneServices } from 'services/brand/voice_tone';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { useSnackbar } from 'notistack';
import { VoiceTone } from 'app/presentation/hooks/interfaces/brand';
import { HiArrowNarrowRight } from 'react-icons/hi';

interface Step2Props {
    selectedMethod: 'url' | 'text' | undefined;
    generatedVoiceTone: string;
    setGeneratedVoiceTone: (value: string) => void;
    callback: () => void;
    handleClose: () => void;
    editing: boolean;
    item_editing?: VoiceTone | null;
    isGenerating: boolean;
    setGenerating: (value: boolean) => void;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const voiceToneService = new VoiceToneServices();

export const Step2: React.FC<Step2Props> = (props) => {
    const intl = useIntl();
    const [checkboxValue, setCheckBoxValue] = React.useState(false);
    const { workspace, project, activeBrand } = useUserConfig();
    const [loading, setLoading] = React.useState(false);
    const [rewriteContent, setRewriteContent] = React.useState('');
    const [name, setName] = React.useState('');
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (props.editing && props.item_editing) {
            setName(props.item_editing.name);
            setCheckBoxValue(props.item_editing.is_default);
        } else {
            setName('');
            setCheckBoxValue(false);
        }
    }, [props.item_editing, props.editing]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => {
        setCheckBoxValue(checked);
    };

    /*const handleRewrite = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        if (rewriteContent === '') {
            return;
        }
        try {
            if (selectedMethod && generatedContent) {
                setLoading(true);

                const data = {
                    text: generatedContent,
                    regeneration_information: rewriteContent,
                };

                const result = await voiceToneService.generateTone(
                    workspace.id,
                    project.id,
                    data,
                );

                if (result.content) {
                    setGeneratedContent(result.content);
                } else {
                    throw new Error();
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            alert(routeTranslate(intl, 'modal.tone.error.generic'));
        }
    };*/

    /*const handleCreate = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        if (name === '') {
            return;
        }
        try {
            if (selectedMethod && generatedContent && name && activeBrand) {
                setLoading(true);

                const data = {
                    brand_id: activeBrand.id,
                    name: name,
                    content: generatedContent,
                    is_default: checkboxValue,
                };

                const result = await voiceToneService.create(
                    workspace.id,
                    project.id,
                    data,
                );

                if (result.content) {
                    setGeneratedContent(result.content);
                    callback();
                } else {
                    throw new Error();
                }
                setLoading(false);
                enqueueSnackbar(
                    routeTranslate(intl, 'modal.tone.create.success'),
                    {
                        variant: 'success',
                    },
                );
                handleClose();
            }
        } catch (error) {
            setLoading(false);
            alert(routeTranslate(intl, 'modal.tone.error.generic'));
        }
    };*/

    /*const handleEdit = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        if (name === '') {
            return;
        }
        try {
            if (
                //selectedMethod &&
                props.generatedVoiceTone &&
                name &&
                activeBrand &&
                props.item_editing
            ) {
                setLoading(true);

                const data = {
                    brand_id: activeBrand.id,
                    name: name,
                    content:
                        props.generatedVoiceTone.length > 500
                            ? props.generatedVoiceTone.slice(0, 499)
                            : props.generatedVoiceTone,
                    is_default: checkboxValue,
                };

                const result = await voiceToneService.edit(
                    workspace.id,
                    project.id,
                    data,
                    props.item_editing.id,
                );

                if (result.content) {
                    props.setGeneratedVoiceTone(result.content);
                    props.callback();
                } else {
                    throw new Error();
                }
                setLoading(false);
                enqueueSnackbar(
                    routeTranslate(intl, 'modal.tone.create.success'),
                    {
                        variant: 'success',
                    },
                );
                props.handleClose();
            }
        } catch (error) {
            setLoading(false);
            alert(routeTranslate(intl, 'modal.tone.error.generic'));
        }
    };*/

    return (
        <div className="step2-container">

            {props.isGenerating ? (
                <div>Generating content</div>
            ) : (
                <>
                    <div>
                        <div className="teste">Tom de voz extraído por Jarbas:</div>

                        <TextArea
                            id="text"
                            minLength={1}
                            maxLength={2000}
                            onChange={e => props.setGeneratedVoiceTone(e.target.value)}
                            value={props.generatedVoiceTone}
                            required
                            style={{ background: '#fafafa', fontWeight: 'bold', marginTop: '12px' }}
                        />
                    </div>


                    <div className="teste" style={{marginTop: '30px'}}>
                        {routeTranslate(intl, 'modal.tone.modify')}
                    </div>


                    <div className="enhance-tone-form">
                        <form style={{width: '80%'}}>
                            <Input
                                id="rewrite"
                                type="text"
                                placeholder={routeTranslate(
                                    intl,
                                    'modal.tone.input.placeholder',
                                )}
                                onChange={e => setRewriteContent(e.target.value)}
                                required
                            />
                        </form>

                        <Button
                            //onClick={handleRewrite}
                            type="submit"
                            loading={loading}
                            style={{width: '20%', background: "#000", color: "#fff"}}
                            className="enhance-tone-btn"
                        >
                           <p> {routeTranslate(intl, 'modal.tone.btn-rewrite')} </p>
                        </Button>
                    </div>

                    <Button
                        type="submit"
                        //onClick={editing ? handleEdit : handleCreate}
                        onClick={() => props.setCurrentStep(prev => prev + 1)}
                        loading={loading}
                        icon={<HiArrowNarrowRight />}
                        style={{
                            marginTop: '30px',
                            marginBottom: '32px',
                            width: '20%',
                            marginLeft: 'auto',
                            marginRight: '0',
                            paddingTop: '12px',
                            paddingRight: '24px',
                            paddingBottom: '12px',
                            paddingLeft: '24px',
                            fontSize: '16px',
                        }}
                    >
                        {routeTranslate(intl, 'modal.tone.btn.finish')}
                    </Button>

                </>
            )}




        </div>
    );
};
