import { api } from '../api';

export class TeamService {
    async getAll(
        workspaceId: number,
        page: number | undefined,
        limit: number | undefined,
        search: string | undefined,
    ) {
        const pageCondition = page ?? 1;
        const limitCondition = limit ?? 6;

        const { data } = await api.get(
            `/workspaces/${workspaceId}/users?page=${pageCondition}&limit=${limitCondition}${
                search ? `&userName=${search}` : ''
            }`,
        );

        return data;
    }

    async delete(id: number, workspaceId: number) {
        return api.delete(`/workspaces/${workspaceId}/users/${id}`);
    }
}
