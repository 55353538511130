import styled from 'styled-components';

export const HeaderPlan = styled.div`
    text-align: center;
    margin-top: 30px;
    margin-bottom: 121px;
    position: relative;

    .box > h1 {
        margin-bottom: 12px;
        font-size: 1.5em;
    }

    .box > p {
        color: #718096;
        font-size: 0.875em;
    }

    .toggle {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 1000px;
        width: 187px;
        height: 40.25px;
        display: flex;
        align-items: center;
        padding: 2px;
        margin: 36px auto 8px auto;

        > span {
            cursor: pointer;
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #1a202c;
            font-weight: 700;
            font-size: 0.875em;
        }

        > span.active {
            background: #d0ff61;
            border-radius: 1000px;
        }
    }

    #economy {
        text-align: center;
        font-size: 0.875em;

        strong {
            color: #1a202c;
        }
    }

    .button-back {
        position: absolute;
        top: -87px;
        right: 0;
        cursor: pointer;
        background: #f7fafc;
        border: none;
        border-radius: 12px;
        padding: 8px 0;
        width: 120px;
        color: #000000;
        font-weight: 700;
    }

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 83px;
        margin-top: 100px;

        .button-back {
            top: -165px;
            padding: 16px 0;
            width: 150px;
        }

        .toggle {
            margin: 0 0 0 auto;
        }

        .box {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .box > h1 {
            font-size: 2.5em !important;
        }

        .box > p {
            font-size: 1.125em;
        }

        #economy {
            text-align: right;
            font-size: 0.875em;
            margin-top: 11px;
        }
    }

    &.headerPlansDark {
        .button-back {
            background: #000;
            color: #fff;
            border: 1px solid #fff;
        }
        .box {
            p {
                color: #cccccc;
                strong {
                    color: #fff !important;
                }
            }

            .toggle {
                background: #1d1d1f;
                border-color: #1d1d1f;

                > span {
                    color: #fff;
                }

                > span.active {
                    background: #d0ff61;
                    border-radius: 1000px;
                    color: #000;
                }
            }
        }
    }
`;

export const ContainerPlans = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;

    .words {
        margin: 0px 0 25px 0;
        color: #000 !important;
        font-weight: 700;
    }

    .label-popular {
        display: none;
    }

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 16px;

        .box {
            height: 95%;
            position: relative;

            .label-popular {
                display: block;
                padding: 3px 8px;
                position: absolute;
                top: -10px;
                right: 13px;
                border: 1px solid #1a202c;
                border-radius: 6px;
                background: #ffffff;

                span {
                    font-weight: 600;
                    font-size: 0.875em;
                    color: #1a202c;
                }
            }

            &.active {
                height: 100%;

                .description {
                    font-weight: 700;
                }
            }
        }
    }

    .box {
        background-color: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 30px;
        border-radius: 12px;
        text-align: center;
        width: 100%;
        max-width: 325px;
        margin-bottom: 28px;
        align-self: center;
        justify-self: center;

        > h1 {
            margin-bottom: 6px;
            font-size: 1.5em;
        }

        > p {
            color: #718096;
            font-size: 0.875em;
            font-weight: 500;
        }

        button {
            border-radius: 6px;
            background-color: #fff;
            border: 1px solid #cbd5e0;
            font-size: 15px;
        }

        ul {
            text-align: left;
            list-style: none;
            margin-top: 27px;
            margin-bottom: 25px;

            li {
                margin-bottom: 15px;
                font-size: 0.875em;
                font-weight: 500;
                color: #000000;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 11px;
                }

                &.disabled {
                    color: #718096;
                }
            }

            li:last-of-type {
                margin-bottom: 0;
            }
        }

        &.active {
            background-color: #d0ff61;
            color: #1a202c;
            border: 0;
            padding-top: 44px;

            .desc {
                font-weight: 600;
            }

            svg path {
                fill: #1a202c;
            }

            button {
                svg path {
                    fill: #fff;
                }
            }

            > h1 {
                color: #1a202c;
            }

            > p {
                color: #1a202c;
                font-weight: 400;
            }

            button {
                background-color: #1a202c;
                color: #fff;
                border: 0;
            }

            li {
                color: #1a202c;
                font-weight: 600;
            }
        }

        .price {
            margin-top: 36px;
            margin-bottom: 6px;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .value {
                font-weight: 700;
                font-size: 48px;
            }

            .period {
                font-size: 16px;
                font-weight: 500;
            }
        }

        .box:last-of-type {
            margin-bottom: 0;
        }

        .description {
            color: #000;
            text-align: left;
            line-height: 150%;
            letter-spacing: 0.3px;
            font-size: 1rem;
            font-weight: 700;
        }

        @media (min-width: 768px) {
            > h1 {
                font-size: 1.5em !important;
            }

            > p {
                font-size: 0.875em;
            }
        }
    }

    &.containerPlansDark {
        .box {
            background-color: #1d1d1f;
            border-color: #1d1d1f;

            > p {
                color: #a09fa5;
            }

            ul {
                li {
                    color: #fff;

                    &.disabled {
                        color: #cccccc;
                    }
                }
            }

            &.active {
                background-color: #d0ff61;
                color: red;
                border: 0;
                padding-top: 44px;

                .desc {
                    font-weight: 600;
                }

                svg path {
                    fill: #1a202c;
                }

                button {
                    svg path {
                        fill: #fff;
                    }
                }

                > h1 {
                    color: #1a202c;
                }

                > p {
                    color: #1a202c;
                    font-weight: 400;
                }

                button {
                    background-color: #000;
                    color: #fff;
                }

                li {
                    color: #1a202c;
                    font-weight: 600;
                }
                .price {
                    .value {
                        color: #000;
                    }
                }

                .words {
                    color: #000 !important;
                }
            }

            .words {
                color: #fff !important;
            }

            .description {
                color: #a09fa5;
            }
        }
    }
`;

export const ContainerFaq = styled.div`
    header {
        text-align: center;
        margin-top: 56px;
        margin-bottom: 48px;

        a {
            font-weight: 500;
        }

        h1 {
            margin-top: 8px;
        }

        p {
            font-size: 0.875em;
            color: #718096;
            line-height: 21px;
        }
    }

    .box {
        border: 1px solid #edf2f7;
        border-radius: 16px;
        padding: 20px 10px;
        margin: 0 auto 24px auto;
        width: 100%;
        max-width: 680px;

        h1 {
            font-weight: 600;
            font-size: 1em;
            margin-bottom: 12px;
        }

        p {
            font-size: 0.875em;
            color: #a09fa5;
            font-weight: 500;
            strong {
                color: #000;
                text-decoration: underline;
            }
        }
    }

    .box:last-of-type {
        margin-bottom: 0;
    }

    &.containerPlansFaqDark {
        header {
            p {
                color: #a09fa5;
            }
        }
        .box {
            border-color: #3a3a3a;

            p {
                color: #a09fa5;
            }
        }
    }
`;

export const SliderContainer = styled.div`
    text-align: center;
`;

export const StageContainer = styled.div`
    display: none;

    @media (min-width: 768px) {
        display: block;
        margin-bottom: 46px;
    }
`;
