import React, { ReactNode } from 'react';
import { TableBody, TableCell, TableHead, TableRow, MenuItem } from '@mui/material';
import { StyledTable, StyledMenu, TablePagination, PaginationBox } from './styles';
import * as icons from '@tabler/icons-react';
import { AnchorHandler } from './../../types';
import {
    ModalDeleteMemory,
    ModalEditMemory,
    ModalViewMemory,
    CustomCheckbox,
    Select
} from '../';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { FormattedMessage } from 'react-intl';
import { type Memory } from 'services/brand/memories/types';
import { useLocation } from "react-router-dom";
import { Row } from '../Row/Row';

const popperProps = { sx: { '& .MuiTooltip-tooltip': { backgroundColor: 'rgb(29, 29, 31)', color: 'white', } } };

type TableContextProps = {
    popperProps: {};
    setSelectedMemory: React.Dispatch<React.SetStateAction<Memory | undefined>>;
    setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedEditLanguage: React.Dispatch<React.SetStateAction<number | undefined>>;
    setAnimateViewModal: React.Dispatch<React.SetStateAction<boolean>>;
    anchor: HTMLElement | null;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    handleAnchor: AnchorHandler;
    setShowActionsDropdown: React.Dispatch<React.SetStateAction<boolean>>
}

export const TableContext = React.createContext<TableContextProps>({} as TableContextProps)

type TableProps = {
    anchor: HTMLElement | null;
    setAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export const Table: React.FC<TableProps> = (props) => {
    const { activeBrand: brand } = useUserConfig();
    const { memories, currPaginationPage, setCurrPaginationPage,
        currPaginationLimit, setCurrPaginationLimit, fetchMemories,
    } = useMemoriesContext();
    const { anchor, setAnchor } = props;

    const location = useLocation();

    const [showActionsDropdown, setShowActionsDropdown] = React.useState<boolean>(false);

    const [animateDeleteModal, setAnimateDeleteModal] = React.useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

    const [animateViewModal, setAnimateViewModal] = React.useState<boolean>(false);
    const [showViewModal, setShowViewModal] = React.useState<boolean>(false);

    const [animateEditModal, setAnimateEditModal] = React.useState<boolean>(false);
    const [showEditModal, setShowEditModal] = React.useState<boolean>(false);

    const [selectedMemory, setSelectedMemory] = React.useState<Memory | undefined>();
    const [selectedEditLanguage, setSelectedEditLanguage] = React.useState<number | undefined>(brand?.brand_config.default_language);
    const [firstRender, setFirstRender] = React.useState<boolean>(true);

    const handleAnchor: AnchorHandler = (action, event?, stateFunc?) => {
        switch (action) {
            case "bind":
                setAnchor(event!.currentTarget);
                stateFunc && stateFunc(true);
                break;

            case "unbind":
                setAnchor(null);
                stateFunc && stateFunc(false);
                break;
        }
    }

    // TODO refact this aberration chatgpt generated
    const handlePaginationFooter = (displayLimit: number): ReactNode => {
        if (!memories) return;

        const pagination: ReactNode[] = [];

        const visiblePages = Math.min(displayLimit, memories!.total_pages);
        const halfVisiblePages = Math.ceil(visiblePages / 2);

        if (memories.total_pages <= visiblePages) {
            for (let i = 1; i <= memories!.total_pages; i++) {
                pagination.push((
                    <PaginationBox
                        onClick={
                            currPaginationPage !== i ? () => setCurrPaginationPage(i) : () => undefined
                        }
                        selected={currPaginationPage === i}>
                        <span>{i}</span>
                    </PaginationBox>
                ));
            }
        } else {
            pagination.push((
                <PaginationBox
                    onClick={
                        currPaginationPage !== 1 ? () => setCurrPaginationPage(1) : () => undefined
                    }
                    selected={currPaginationPage === 1}>
                    <span>1</span>
                </PaginationBox>
            ));

            if (memories.page <= halfVisiblePages + 1) {
                for (let i = 2; i <= visiblePages - 1; i++) {
                    pagination.push((
                        <PaginationBox
                            onClick={
                                currPaginationPage !== i ? () => setCurrPaginationPage(i) : () => undefined
                            }
                            selected={currPaginationPage === i}>
                            <span>{i}</span>
                        </PaginationBox>
                    ));
                }

                pagination.push((
                    <PaginationBox selected={false}>
                        <span>...</span>
                    </PaginationBox>
                ));

                pagination.push((
                    <PaginationBox
                        onClick={
                            currPaginationPage !== memories.total_pages ? () => setCurrPaginationPage(memories.total_pages) : () => undefined
                        }
                        selected={currPaginationPage === memories.total_pages}>
                        <span>{memories.total_pages}</span>
                    </PaginationBox>
                ));

            } else if (memories.page >= memories.total_pages - halfVisiblePages) {
                pagination.push((
                    <PaginationBox selected={false}>
                        <span>...</span>
                    </PaginationBox>
                ));

                for (let i = memories.total_pages - visiblePages + 3; i <= memories.total_pages; i++) {
                    pagination.push((
                        <PaginationBox
                            onClick={
                                currPaginationPage !== i ? () => setCurrPaginationPage(i) : () => undefined
                            }
                            selected={currPaginationPage === i}>
                            <span>{i}</span>
                        </PaginationBox>
                    ));
                }

            } else {
                pagination.push((
                    <PaginationBox selected={false}>
                        <span>...</span>
                    </PaginationBox>
                ));

                for (let i = memories.page - halfVisiblePages + 1; i <= memories.page + halfVisiblePages - 1; i++) {
                    pagination.push((
                        <PaginationBox
                            onClick={
                                currPaginationPage !== i ? () => setCurrPaginationPage(i) : () => undefined
                            }
                            selected={currPaginationPage === i}>
                            <span>{i}</span>
                        </PaginationBox>
                    ));
                }

                pagination.push((
                    <PaginationBox selected={false}>
                        <span>...</span>
                    </PaginationBox>
                ));

                pagination.push((
                    <PaginationBox
                        onClick={
                            currPaginationPage !== memories.total_pages ? () => setCurrPaginationPage(memories.total_pages) : () => undefined
                        }
                        selected={currPaginationPage === memories.total_pages}>
                        <span>{memories.total_pages}</span>
                    </PaginationBox>
                ));
            }
        }

        return pagination;
    };

    // TODO change memdid to memory_id and language_id to show correct language
    React.useEffect(() => {
        if (!memories || !firstRender) return;

        const queryString = location.search.slice(1);
        const queryStringItems = queryString.split("&").map(querystringItem => querystringItem.split("="));

        const querystringParams = queryStringItems.reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {} as Record<string, string>);

        if (!("memid" in querystringParams)) return;

        const memory = memories?.data.find(memory => memory.id.toString() == querystringParams["memid"])
        if (!memory) return;

        setSelectedMemory(memory);
        setShowViewModal(true);
        setAnimateViewModal(true);
        setFirstRender(false);
    }, [memories]);

    // this here is to prevent state changes on collections not reflecting here
    React.useEffect(() => {
        fetchMemories();
    }, [])


    const memoryContentsLanguages: string[] | undefined = brand?.supported_languages.reduce((acc: string[], language) => {
        if (language.language_id == brand.brand_config.default_language) {
            acc = [language.language.lang, ...acc];
            return acc;
        }

        acc.push(language.language.lang);
        return acc;
    }, []);


    // TODO remove magic numbers and create constants for default values
    return (
        <>
            <StyledTable>
                <TableHead component="thead" key="table-head">
                    <TableRow>
                        <TableCell align="left" padding="checkbox" style={{ display: "none" }}>
                            <CustomCheckbox />
                        </TableCell>

                        <TableCell align="left">
                            <span>
                                <FormattedMessage id="brand-center.knowledge.table.title" />
                            </span>
                        </TableCell>

                        <TableCell align="right">
                            <span>
                                <FormattedMessage id="brand-center.knowledge.table.last-edit" />
                            </span>
                        </TableCell>

                        {memoryContentsLanguages &&
                            memoryContentsLanguages.map(language => (
                                <TableCell align="center">
                                    <span>{language}</span>
                                </TableCell>
                            ))}

                        <TableCell>
                            <span>
                                <FormattedMessage id="brand-center.knowledge.table.collection" />
                            </span>
                        </TableCell>

                        <TableCell align="right">
                            <span>
                                <FormattedMessage id="brand-center.knowledge.table.actions" />
                            </span>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableContext.Provider value={{
                    popperProps: popperProps,
                    setSelectedMemory: setSelectedMemory,
                    setShowViewModal: setShowViewModal,
                    setSelectedEditLanguage: setSelectedEditLanguage,
                    setAnimateViewModal: setAnimateViewModal,
                    anchor: anchor,
                    setAnchor: setAnchor,
                    handleAnchor: handleAnchor,
                    setShowActionsDropdown: setShowActionsDropdown
                }}>
                    <TableBody component="tbody">
                        {memories && memories.data.map((memory) => (
                            <Row memory={memory}></Row>
                        ))}
                    </TableBody>
                </TableContext.Provider>
            </StyledTable >

            <TablePagination>
                <div className="container-left">
                    <span>
                        <FormattedMessage id="brand-center.knowledge.table.pagination.show-result" />
                    </span>
                    <Select
                        onChange={e => {
                            setCurrPaginationPage(1);
                            setCurrPaginationLimit(+e.currentTarget.value);
                        }}
                        options={[
                            { value: 1, text: "1" },
                            { value: 3, text: "3" },
                            { value: 5, text: "5" },
                            { value: 10, text: "10" },
                            { value: 50, text: "50" }
                        ]}
                        defaultValue={currPaginationLimit}
                    />
                </div>

                <div className="container-right">
                    <icons.IconChevronLeft stroke={1.5} size={20} />

                    {handlePaginationFooter(5)}

                    <icons.IconChevronRight stroke={1.5} size={20} />
                </div>

            </TablePagination>

            <StyledMenu
                anchorEl={anchor}
                open={showActionsDropdown}
                onClose={() => handleAnchor("unbind", undefined, setShowActionsDropdown)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            >
                <MenuItem
                    onClick={() => {
                        setShowEditModal(true);
                        setAnimateEditModal(true);
                        handleAnchor("unbind", undefined, setShowActionsDropdown)
                    }}
                    className="actions-dropdown-btn"
                >
                    <icons.IconEdit stroke={1.5} size={18} />

                    <p> <FormattedMessage id="brand-center.knowledge.table.actions.edit" /> </p>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setShowDeleteModal(true);
                        setAnimateDeleteModal(true);
                        handleAnchor("unbind", undefined, setShowActionsDropdown)
                    }}
                    className="actions-dropdown-btn"
                >
                    <icons.IconTrash stroke={1.5} size={18} />
                    <p> <FormattedMessage id="brand-center.knowledge.table.actions.delete" /> </p>
                </MenuItem>
            </StyledMenu>

            {showDeleteModal && (
                <ModalDeleteMemory
                    memory={selectedMemory}
                    animate={animateDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setAnimateDeleteModal={setAnimateDeleteModal}
                ></ModalDeleteMemory>
            )}

            {showViewModal && selectedMemory && (
                <ModalViewMemory
                    memory={selectedMemory}
                    setMemory={setSelectedMemory}
                    animate={animateViewModal}
                    setShowModal={setShowViewModal}
                    setAnimateSlide={setAnimateViewModal}
                    handleShowEditModal={() => {
                        setShowEditModal(true);
                        setAnimateEditModal(true);
                    }}
                    setSelectedMemory={setSelectedMemory}
                    setSelectedEditLanguage={setSelectedEditLanguage}
                />
            )}

            {showEditModal && selectedMemory && selectedEditLanguage && (
                <ModalEditMemory
                    setShowEditModal={setShowEditModal}
                    setAnimateSlide={setAnimateEditModal}
                    animateSlide={animateEditModal}

                    memory={selectedMemory}

                    selectedLanguage={selectedEditLanguage}
                    setSelectedLanguage={setSelectedEditLanguage}
                />
            )}

        </>
    );

}
