import styled, { css } from 'styled-components';

export const IdentityPanel = styled.div`
    ${({
        theme: {
            agent: {
                pages: {
                    behavior: {
                        identityPanel: { hr },
                    },
                },
            },
        },
    }) => css`
        .text-inputs {
            border-bottom: 1px solid ${hr.color};
            .text-input-container {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-bottom: 20px;

                input {
                    height: 44px;
                    padding: 0px 16px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.2px;
                }
            }

            input {
                height: 44px;
            }

            .name-input {
                width: 265px;
            }
        }
        .img-inputs {
            display: grid;
            grid-template-columns: repeat(1, 271px);
            grid-auto-rows: auto;
            padding: 30px 0px;
            border-bottom: 1px solid ${hr.color};
            grid-row-gap: 20px;
            grid-column-gap: 20px;

            @media (min-width: 64em) {
                grid-template-columns: repeat(2, 271px);
            }

            .item-full {
                @media (min-width: 64em) {
                    grid-column: 1 / span 2;
                }
            }
        }
        .color-inputs {
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        .buttons-container {
            padding-top: 15px;
            border-top: 1px solid ${hr.color};
            margin-top: 15px;
            display: flex;
            gap: 8px;
            flex-direction: row-reverse;
            justify-content: start;

            @media (min-width: 64em) {
                flex-direction: row;
                justify-content: end;
            }

            button {
                height: 34px;
                padding: 8px 18px 8px 18px;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.2px;
                gap: 4px;
                border-radius: 6px;
                .desktop {
                    display: none;

                    @media (min-width: 64em) {
                        display: inline;
                    }
                }

                &.save-button {
                    width: 103px;
                    @media (min-width: 64em) {
                        width: 190px;
                    }
                }
            }
        }
    `}
`;
