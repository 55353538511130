import styled, { css } from 'styled-components';

export const ContainerModal = styled.div`
    ${({
        theme: {
            agent: {
                components: {
                    modalDeleteAgent: { background, text, name },
                },
            },
        },
    }) => css`
        background-color: #11151deb;
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;

        .content-modal {
            background: ${background.color};
            border-radius: 16px;
            min-width: 328px;
            max-width: 328px;
            padding: 30px 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (min-width: 64em) {
                min-width: 496px;
                max-width: 496px;
            }

            .modal-header {
                display: flex;
                justify-content: end;
                width: 100%;
                svg {
                    cursor: pointer;
                    width: 22px;
                    height: 22px;
                }
            }

            .modal-body {
                display: flex;
                flex-direction: column;
                gap: 25px;
                text-align: center;
                padding-right: 10px;
                .body-title {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: 0.2px;
                }
                .body-text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.2px;
                    color: ${text.color};
                    .agent-name {
                        color: ${name.color};
                    }
                }

                input {
                    height: 42px;
                }

                .button-container {
                    display: flex;
                    justify-content: space-between;
                    gap: 15px;

                    button {
                        padding: 8px 24px;
                        height: 48px;
                        border-radius: 8px;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 21px;
                        line-height: 0.2px;
                        gap: 7px;

                        .desktop {
                            display: none;
                            @media (min-width: 64em) {
                                display: inline;
                            }
                        }
                    }

                    .delete-button {
                        width: 178px;
                    }
                }
            }
        }
    `}
`;
