import { Route, Routes } from 'react-router-dom';
import Home from 'pages/agent/home';
import { AgentHeader } from 'app/presentation/components/agent';
import AgentLayout from '../app/presentation/layout/agent';
import { AgentInfoRoutes } from './agentInfoRoutes';
import AgentToolbar from 'app/presentation/components/agent/AgentToolbar';
import useText from 'app/presentation/hooks/useText';
import Notifications from 'pages/dashboard/notifications';
import { BrandCenterRoutes } from './brandCenterRoutes';
import PageNotFound from 'pages/not-found/PageNotFound';
import  Insights  from 'pages/agent/insights';

export const AgentRoutes = () => {
    const { routeGetter } = useText();
    const agentRoutes = routeGetter('agent', false);
    return (
        <AgentLayout>
            <>
                <AgentHeader></AgentHeader>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path={`:id/*`}
                        element={<AgentInfoRoutes />}
                    />
                    <Route
                        path={`${agentRoutes('notifications')}`}
                        element={<Notifications />}
                    />
                    <Route
                        path={`${agentRoutes('insights')}`}
                        element={<Insights />}
                    />
                    <Route
                        path={`${agentRoutes('brand-center')}/*`}
                        element={<BrandCenterRoutes />}
                    />
                    <Route path="*" element={<PageNotFound useAbsolutPath />} />
                </Routes>
                <AgentToolbar />
            </>
        </AgentLayout>
    );
};
