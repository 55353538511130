import React from 'react';
import Fade from '@mui/material/Fade';
import * as S from './styles';
import { Input } from '../../common';
import { GifService, IGifs } from 'services/gif';
import useText from 'app/presentation/hooks/useText';

interface Props {
    active: boolean;
    handleClose: () => void;
    onPickGif: (gif_url: string) => void;
}

const gifService = new GifService();

export const GifPicker: React.FC<Props> = ({
    active,
    handleClose,
    onPickGif,
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.gif-picker');
    const [gifs, setGifs] = React.useState<IGifs>({ next: '', gifs: [] });
    const [inputValue, setInputValue] = React.useState<string>('');
    React.useEffect(() => {
        if (!active) {
            return;
        }
        gifService.get(inputValue).then(result => {
            setGifs(result);
        });
    }, [active, inputValue]);
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };
    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            handleClose();
        }
    };

    let scrollAPICalled = false;
    const onContainerScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        const conteiner = event.target as HTMLDivElement;
        if (
            conteiner.scrollTop + conteiner.clientHeight >=
                conteiner.scrollHeight - 200 &&
            !scrollAPICalled
        ) {
            scrollAPICalled = true;
            gifService.get(inputValue, gifs.next).then(result => {
                const newGifs = { ...gifs };
                newGifs.next = result.next;
                newGifs.gifs = newGifs.gifs.concat(result.gifs);
                setGifs(newGifs);
            });
            scrollAPICalled = false;
        }
    };
    return (
        <Fade in={active} timeout={400}>
            <S.GifPicker onClick={handleCloseContainer}>
                <div className="content-modal">
                    <Input
                        id="gif-picker"
                        placeholder={t('placeholder')}
                        onChange={onInputChange}
                    ></Input>
                    <div className="gif-container" onScroll={onContainerScroll}>
                        {gifs &&
                            gifs.gifs.map((gif, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={gif}
                                        onClick={() => {
                                            onPickGif(gif);
                                        }}
                                    />
                                );
                            })}
                    </div>
                </div>
            </S.GifPicker>
        </Fade>
    );
};
