import React from 'react';
import LanguageContext from '../contexts/LanguageContext';
import { LanguageContextData } from '../contexts/interfaces/language-context-props.interface';

function useLanguage(): LanguageContextData {
    const context = React.useContext(LanguageContext);

    if (!context) {
        throw new Error('useLanguage deve ser usado com o LanguageProvider');
    }

    return context;
}

export default useLanguage;
