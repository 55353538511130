import styled from 'styled-components';

export const Nav = styled.nav`
    .header {
        padding-left: 24px;
    }

    h2 {
        font-weight: 800;
        font-size: 0.875em;
        color: #718096;
        margin-bottom: 8px;
    }

    ul {
        margin-bottom: 25px;
    }

    ul:last-of-type {
        margin-bottom: 0;
    }

    .active {
        > li {
            background: #fafafa;
            border-left: 3px solid #d0ff61;

            .icon {
                background-color: #fff;
            }
        }
    }

    li {
        display: flex;
        align-items: center;
        height: 96px;
        padding-left: 24px;
        border-left: 3px solid #fff;

        .icon {
            min-width: 48px;
            min-height: 48px;
            background: #f7fafc;
            border-radius: 1000px;
            margin-right: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .description {
            flex: 1;

            h1 {
                font-size: 0.875em;
                color: #1a202c;
                font-weight: 700;
                margin-bottom: 4px;
            }

            p {
                color: #718096;
                font-weight: 400;
                font-size: 0.75em;
            }
        }
    }

    @media (min-width: 64em) {
        padding-top: 40px;

        h2 {
            margin-bottom: 25px;
        }

        li {
            padding-left: 40px !important;
        }

        .header {
            padding-left: 40px !important;
        }

        flex: 1;
        max-width: 367px;
        height: calc(100vh - 104px);
        overflow: auto;
        border-right: 1px solid #e2e8f0;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            background: #edf2f7;
            border-radius: 50px;
        }
    }
`;

export const LayoutDefinitions = styled.div`
    .page {
        display: none;
    }

    .page.active {
        display: flex;
        position: fixed;
        z-index: 999;
        background-color: #fff;
        width: 100%;
        height: 100vh;
        overflow: auto;
        top: 0;
        left: 0;
        padding: 16px 24px;
    }

    @media (min-width: 64em) {
        display: flex;

        .page {
            flex: 1;
            display: block;
            padding: 40px 39px !important;
            height: calc(100vh - 104px) !important;
            overflow: auto;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            ::-webkit-scrollbar-thumb {
                background: #edf2f7;
                border-radius: 50px;
            }
        }

        .page.active {
            display: block;
            position: static;
            z-index: 1;
        }
    }

    &.layoutDark {
        .header {
            h2 {
                color: #a09fa5;
            }
        }

        nav {
            border-color: #1d1d1f;

            .active {
                > li {
                    background: #1d1d1f;
                    border-color: #d0ff61 !important;
                }
            }
        }

        .page.active {
            background-color: #000;

            input,
            textarea {
                background: #1d1d1f;
                color: #fff;
                border-color: #1d1d1f;

                &:focus {
                    border: 1px solid #fff !important;
                }
            }
        }

        li {
            border-left-color: #000;

            .description {
                h1 {
                    color: #fff;
                }

                p {
                    color: #a09fa5;
                }
            }
        }
    }
`;
