import React from 'react';
import * as S from './styles';
import { Chart as ChartJS } from 'chart.js';
import { externalTooltip } from 'utils/chart';

export interface SingleBarChartValue {
    color: string;
    label: string;
    value: number;
} 

interface ChartProps extends React.HTMLAttributes<HTMLCanvasElement> {
    width: string;
    height?: number;
    data: SingleBarChartValue[];
    barSpacing?: number;
}

export const SingleBarChart: React.FC<ChartProps> = ({barSpacing=0, data, height = 20, ...rest}) => {
    const chartRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(()=>{
        const container = chartRef.current;
        if (!container) {
            return;
        }
        
        ChartJS.defaults.font.size = 12;
        ChartJS.defaults.font.lineHeight = '18px';
        ChartJS.defaults.font.weight = '500';
        ChartJS.defaults.font.family = 'Manrope';
        
        const chart = new ChartJS(
            container,
            {
                type: 'bar',
                data: {
                    labels: [' '],
                    datasets: data.map(row => 
                         {return {
                            barThickness: 20 + 2 * barSpacing,
                            backgroundColor: row.color,
                            hoverBackgroundColor: row.color,
                            label: row.label,
                            data: [row.value],
                            borderWidth: barSpacing,
                            borderColor: 'transparent'
                        }})
                },
                options: {
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display:false
                        },
                        tooltip: {
                            enabled: false,
                            external: externalTooltip('%')
                        }
                    },
                    scales: {
                        y: {
                            display: false,
                            stacked: true, 
                        },
                        x: {
                            display: false,
                            stacked: true,
                            min: 0,
                            max: 100,
                        },
                    },
                }
            });

        return () => {
            chart.destroy();
        }
    },[data, rest.width, height]);

    return <S.Chart ref={chartRef} height={height} {...rest}/>;
};

export default SingleBarChart;
