import styled from 'styled-components';

interface ChartProps {
    width: string;
    height: number;
}

export const Chart = styled.canvas<ChartProps>`
    max-width: ${({width}) => width + 'px'};
    max-height:  ${({height}) => height + 'px'};
    width: ${({width}) => width + 'px'};
    height:  ${({height}) => height + 'px'};
`;
