import { Button } from 'app/presentation/components/agent';
import StatusLabel from 'app/presentation/components/agent/StatusLabel';
import { TrainingDrawer } from 'app/presentation/components/agent/Training/Drawers';
import { ModalUrlContent } from 'app/presentation/components/agent/Training/Modals/ModalUrlContent';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';
import { useEffect, useState } from 'react';
import { TbCirclePlus, TbPencil } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { TrainingResourceTypes } from 'services/training/interfaces';
import { URLContentsService } from 'services/url_contents';
import {
    CountGroupedResponse,
    TotalWithMostRecentResponse,
} from 'services/url_contents/interfaces';
import { removeUrlPath } from 'utils/url';
import * as S from './styles';

interface UrlProps {
    enabled: number;
    total: number;
    isReady: boolean;
}

export const UrlContentPanel = ({ enabled, total, isReady }: UrlProps) => {
    const { routeGetter, textGetter } = useText();
    const route = routeGetter('', true);
    const panelText = textGetter('agent.panel.urlContents');
    const commonsText = textGetter('agent.panel.commons');

    const [trainingDrawerActive, setTrainingDrawerActive] = useState(false);
    const [modalUrlContentActive, setModalUrlContentActive] = useState(false);
    const [groupedCount, setGroupedCount] = useState<CountGroupedResponse>();
    const [totalWithRecent, setTotalWithRecent] =
        useState<TotalWithMostRecentResponse>();

    const { agent, notifyAgentTrainingChanged } = useAgent();

    const service = new URLContentsService();
    const navigate = useNavigate();

    const getTotalWithRecentURL = (
        totalWithRecent?: TotalWithMostRecentResponse,
    ): string => {
        if (!totalWithRecent) return '';

        const keys = Object.keys(totalWithRecent);
        if (keys.length === 0) return '';

        return removeUrlPath(keys[0]);
    };

    const getTotalWithRecentCount = (
        totalWithRecent?: TotalWithMostRecentResponse,
    ): number => {
        if (!totalWithRecent) return 0;

        const keys = Object.keys(totalWithRecent);
        if (keys.length === 0) return 0;

        return totalWithRecent[keys[0]];
    };

    useEffect(() => {
        if (!agent) return;
        service.countGrouped(agent.id).then(setGroupedCount);
        service.totalWithMostRecent(agent.id).then(setTotalWithRecent);
    }, [agent]);

    const getContent = () => {
        if (
            totalWithRecent &&
            (Object.keys(totalWithRecent).length === 0 ||
                totalWithRecent[''] === 0)
        ) {
            return;
        }

        return isReady ? (
            Object.keys(groupedCount ?? {}).map(url => (
                <S.URLContentItem key={url}>
                    <p className="url-title">{url}</p>
                    <p className="url-count">
                        ({groupedCount![url]} {panelText('pages')})
                    </p>
                </S.URLContentItem>
            ))
        ) : (
            <S.URLContentProgressItem>
                <div className="first">
                    <div>
                        <p className="url-title">
                            {panelText('urlInformation', {
                                url: getTotalWithRecentURL(totalWithRecent),
                                count: getTotalWithRecentCount(totalWithRecent),
                            })}
                        </p>
                    </div>
                    <div>
                        <p className="url-count">{panelText('visiting')}</p>
                    </div>
                </div>
                <div>
                    <StatusLabel
                        status="progress"
                        text={panelText('inProgress')}
                    ></StatusLabel>
                </div>
            </S.URLContentProgressItem>
        );
    };

    return (
        <S.Container>
            {modalUrlContentActive && (
                <ModalUrlContent
                    active={modalUrlContentActive}
                    handleClose={() => setModalUrlContentActive(false)}
                />
            )}
            <TrainingDrawer
                animateSlide={trainingDrawerActive}
                handleClose={() => setTrainingDrawerActive(false)}
                isEditing={true}
                contentType={TrainingResourceTypes.Excerpt}
                notifyAgentTrainingChanged={notifyAgentTrainingChanged}
            />
            <p className="count-information">
                {total > 0
                    ? panelText('used', {
                          eligible: enabled,
                          total: total,
                      })
                    : panelText('zeroPages')}
            </p>

            {getContent()}

            <div className="buttons-container">
                <Button
                    variant="primary"
                    type="button"
                    onClick={() => setModalUrlContentActive(true)}
                >
                    <TbCirclePlus></TbCirclePlus>
                    {panelText('add')}
                </Button>

                <Button
                    variant="primary"
                    onClick={() =>
                        navigate(
                            route(
                                'agent.home',
                                { param: agent ? agent.id : '0' },
                                'agent.training',
                            ),
                        )
                    }
                >
                    <TbPencil></TbPencil>
                    <span>{commonsText('button.manage')}</span>
                </Button>
            </div>
        </S.Container>
    );
};
