import React from "react";
import { TableContext } from "../Table";
import { TableCell, TableRow, Tooltip } from '@mui/material';
import { Memory, MemoryContentStatus } from "services/brand/memories/types";
import { useIntl } from "react-intl";
import { handleLastEdit } from "../../utils";
import useUserConfig from "app/presentation/hooks/useUserConfigs";
import { ActionsButton, CustomCheckbox } from '../';
import * as icons from '@tabler/icons-react';
import { Ellipsis } from "./styles";
import useMemoriesContext from "app/presentation/hooks/useMemoriesContext";

type RowProps = {
    memory: Memory;
}

export const Row: React.FC<RowProps> = ({ memory }) => {
    const { popperProps, setShowViewModal, setAnimateViewModal, setSelectedMemory, setShowActionsDropdown } = React.useContext(TableContext);
    const { activeBrand: brand } = useUserConfig();
    const { fetchMemoryContentsPublishStatuses, memories } = useMemoriesContext();

    const [contentsStatuses, setContentsStatuses] = React.useState<MemoryContentStatus[] | undefined>();

    const intl = useIntl();

    const memoryContentsLanguages: number[] | undefined = brand?.supported_languages.reduce((acc: number[], language) => {
        if (language.language_id == brand.brand_config.default_language) {
            acc = [language.language_id, ...acc];
            return acc;
        }

        acc.push(language.language_id);
        return acc;
    }, []);

    const handleTitleOnClick = () => {
        setShowViewModal(true);
        setAnimateViewModal(true);
        setSelectedMemory(memory);
    }

    const handleCollectionIcon = (memory: Memory): React.ReactElement => {
        if (!memory.brand_memory_group) return <icons.IconEyeOff stroke={1.5} size={20} />;

        const CollectionIcon = icons[memory.brand_memory_group.icon as keyof typeof icons] as React.ElementType;
        if (!CollectionIcon) return <icons.IconEyeOff stroke={1.5} size={20} />

        return <CollectionIcon stroke={1.5} size={20} />;
    }

    const handleCollectionName = (memory: Memory): string => {
        if (!memory.brand_memory_group) return intl.formatMessage({ id: "brand-center.knowledge.table.row.no-collection" });

        if (memory.brand_memory_group.translations
            && memory.brand_memory_group.translations[0]
            && memory.brand_memory_group.translations[0].title != ""
        ) return memory.brand_memory_group.translations[0].title;

        return intl.formatMessage({ id: "brand-center.knowledge.table.row.no-collection-title" });
    }

    const handleEllipsisClass = (content: number): string => {
        const entry = contentsStatuses!.find(entry => entry.language_id == content);
        return entry ? entry.publish_status : "deactivated";
    }

    React.useEffect(() => {
        const fetchContentsStatuses = async () => {
            try {
                const statuses = await fetchMemoryContentsPublishStatuses(memory.id);
                setContentsStatuses(statuses);
            } catch (err) {
                return;
            }
        }

        fetchContentsStatuses();
    }, [memories]);

    return (
        <TableRow key={memory.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="left" padding="checkbox" style={{ display: "none" }}>
                <CustomCheckbox />
            </TableCell>
            <TableCell
                component="th"
                sx={{ fontWeight: 700, maxWidth: '150px' }}
                onClick={() => handleTitleOnClick()}
            >
                {/*this className validation and all empty memory values gotta be handled differently */}
                <span className={`span-title ${memory.title == "" ? "empty" : ""}`}>
                    {memory.title != "" ? memory.title : "Sem título"}
                </span>
            </TableCell>


            <TableCell align="right" component="th" sx={{ fontWeight: 600 }}>
                <Tooltip
                    title={memory.contents && memory.contents[0] ? new Date(memory.contents[0].updated_at).toLocaleString(intl.locale) : ""}
                    placement="top"
                    PopperProps={popperProps}
                >
                    <span>
                        {(memory.contents && memory.contents[0]) ? handleLastEdit(new Date(memory.contents[0].updated_at), intl) : "-"}
                    </span>
                </Tooltip>
            </TableCell>


            {contentsStatuses && memoryContentsLanguages && memoryContentsLanguages.map(content => {
                return (<TableCell component="th">
                    <Ellipsis className={handleEllipsisClass(content)} />
                </TableCell>)

            })}


            <TableCell component="th" className="th-collection">
                <div className="collection-row" >
                    {handleCollectionIcon(memory)}
                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {handleCollectionName(memory)}
                    </span>
                </div>
            </TableCell>

            <TableCell>
                <ActionsButton
                    stateFunc={setShowActionsDropdown}
                    memory={memory}
                />
            </TableCell>
        </TableRow>
    )
};
