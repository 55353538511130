import styled from 'styled-components';

export const ContainerFull = styled.div`
    float: left;
    width: 100%;
    min-height: calc(100vh - 80px - var(--footer-height));
    display: flex;
    align-items: center;
    background-color: #fff;

    .content {
        width: 100%;
        max-width: var(--max-width-content);
        margin: 0 auto;
    }
    .fade-in {
        animation: fadeIn 0.5s;
        -webkit-animation: fadeIn 0.5s;
        -moz-animation: fadeIn 0.5s;
        -o-animation: fadeIn 0.5s;
        -ms-animation: fadeIn 0.5s;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-o-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-ms-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .fade-out {
        animation: fadeOut 0.5s;
        -webkit-animation: fadeOut 0.5s;
        -moz-animation: fadeOut 0.5s;
        -o-animation: fadeOut 0.5s;
        -ms-animation: fadeOut 0.5s;
    }

    @keyframes fadeOut {
        100% {
            opacity: 0;
        }
        0% {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeOut {
        100% {
            opacity: 0;
        }
        0% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeOut {
        100% {
            opacity: 0;
        }
        0% {
            opacity: 1;
        }
    }

    @-o-keyframes fadeOut {
        100% {
            opacity: 0;
        }
        0% {
            opacity: 1;
        }
    }

    @-ms-keyframes fadeOut {
        100% {
            opacity: 0;
        }
        0% {
            opacity: 1;
        }
    }
`;
