import Fade from '@mui/material/Fade';
import {
    IconHelpCircle,
    IconMessageForward,
    IconSpeakerphone,
} from '@tabler/icons-react';
import { CardSelect } from 'app/presentation/components/agent';
import useText from 'app/presentation/hooks/useText';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { Agent } from 'services/agent/interfaces';
import { ContainerModal } from './styles';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    item_editing?: Agent | undefined;
    setExcerptDrawerActive: React.Dispatch<React.SetStateAction<boolean>>;
    setModalUrlContentActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalNewContent: React.FC<ModalProps> = ({
    active,
    handleClose,
    item_editing = undefined,
    setExcerptDrawerActive,
    setModalUrlContentActive,
}) => {
    const { textGetter } = useText();
    const modalText = textGetter(
        'agent.components.training.modals.new-content',
    );
    const commonsText = textGetter('agent.components.modal.commons');

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            handleClose();
        }
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal onClick={handleCloseContainer}>
                <form className="content-modal">
                    <div className="modal-header">
                        <p>{modalText('title')}</p>
                        <IoMdClose size={25} onClick={handleClose} />
                    </div>

                    {item_editing == undefined && (
                        <CardSelect
                            variant="primary"
                            height="195px"
                            padding="25px 20px"
                            align="left"
                            iconBG={{
                                height: '42px',
                                width: '42px',
                                radius: '6px',
                            }}
                            iconGap="12px"
                            textGap="12px"
                            cardGap="15px"
                            cards={[
                                {
                                    value: '2',
                                    icon: (
                                        <IconMessageForward color="#fff"></IconMessageForward>
                                    ),
                                    iconBackgroundColor: '#436CFF',
                                    title: modalText('items.excerpts.title'),
                                    text: modalText(
                                        'items.excerpts.description',
                                    ),
                                    onClick: () => setExcerptDrawerActive(true),
                                },
                                {
                                    value: '3',
                                    icon: (
                                        <IconSpeakerphone color="#fff"></IconSpeakerphone>
                                    ),
                                    iconBackgroundColor: '#9061FF',
                                    title: modalText('items.url-content.title'),
                                    text: modalText(
                                        'items.url-content.description',
                                    ),
                                    onClick: () => {
                                        setModalUrlContentActive(true);
                                        handleClose();
                                    },
                                },
                                {
                                    value: '',
                                    icon: (
                                        <IconHelpCircle color="#fff"></IconHelpCircle>
                                    ),
                                    iconBackgroundColor: '#FF784B',
                                    title: modalText('items.files.title'),
                                    text: modalText('items.files.description'),
                                    disable: true,
                                },
                            ]}
                        ></CardSelect>
                    )}
                </form>
            </ContainerModal>
        </Fade>
    );
};
