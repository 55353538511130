import React from 'react';
import * as S from './styles';
import {
    Input,
    ImgInput,
    ColorInput,
    FormFieldInfo,
    Button,
    VoiceToneSelect,
} from 'app/presentation/components/agent';
import HeadsetSVG from 'assets/images/svg/headset.svg';
import SparklesSVG from 'assets/images/svg/sparkles.svg';
import MessagesSVG from 'assets/images/svg/messages.svg';
import HelpSVG from 'assets/images/svg/help.svg';
import MessageSVG from 'assets/images/svg/message.svg';
import useAgent from 'app/presentation/hooks/useAgent';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IconCheck } from '@tabler/icons-react';
import { Image } from 'utils/files';
import useText from 'app/presentation/hooks/useText';

interface AgentIdentityData {
    name: string;
    subheading: string;
}

export const IdentityPanel = () => {
    const { textGetter } = useText();
    const panelText = textGetter('agent.panel.identity');
    const commonsText = textGetter('agent.panel.commons');

    const [chatIcon, setChatIcon] = React.useState<Image>({
        type: 'url',
        url: '',
    });
    const [brandIcon, setBrandIcon] = React.useState<Image>({
        type: 'url',
        url: '',
    });
    const [agentAvatar, setAgentAvatar] = React.useState<Image>({
        type: 'url',
        url: '',
    });
    const [bgColor, setBGColor] = React.useState<string>('#FFFFFF');
    const [actionColor, setActionColor] = React.useState<string>('#FFFFFF');
    const [voiceTone, setVoiceTone] = React.useState<string>('');
    const { agent, updateIdentity } = useAgent();
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    const { register, handleSubmit, setValue } = useForm<AgentIdentityData>();

    const loadForm = () => {
        if (!agent) {
            return;
        }
        const {
            agent_name,
            agent_subheading,
            background_color,
            action_color,
            avatar_url,
            logo_url,
            icon_url,
            tone,
        } = agent.identity;

        setValue('name', agent_name);
        setValue('subheading', agent_subheading);
        setBGColor(background_color ? background_color : '#FFFFFF');
        setActionColor(action_color ? action_color : '#FFFFFF');
        setChatIcon({ type: 'url', url: icon_url });
        setBrandIcon({ type: 'url', url: logo_url });
        setAgentAvatar({ type: 'url', url: avatar_url });
        setVoiceTone(tone);
    };

    React.useEffect(() => {
        loadForm();
    }, [agent]);

    const onVoiceToneSelectChange = (value: string) => {
        setVoiceTone(value);
    };

    const onSubmit: SubmitHandler<AgentIdentityData> = async (
        data: AgentIdentityData,
    ) => {
        setFormSubmitLoading(true);
        updateIdentity(
            data.name,
            data.subheading,
            voiceTone,
            bgColor,
            actionColor,
            brandIcon,
            agentAvatar,
            chatIcon,
        ).finally(() => setFormSubmitLoading(false));
    };

    return (
        <S.IdentityPanel>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-inputs">
                    <div className="text-input-container name-input">
                        <FormFieldInfo
                            title={panelText('agent-name')}
                        ></FormFieldInfo>
                        <Input
                            registerValues={register('name')}
                            type="text"
                            required
                        />
                    </div>
                    <div className="text-input-container">
                        <FormFieldInfo
                            title={panelText('agent-subtitle')}
                        ></FormFieldInfo>
                        <Input
                            registerValues={register('subheading')}
                            type="text"
                            required
                        />
                    </div>
                    {false && (
                        <div className="text-input-container">
                            <FormFieldInfo
                                title={panelText('tone')}
                            ></FormFieldInfo>
                            <VoiceToneSelect
                                value={voiceTone}
                                onChange={onVoiceToneSelectChange}
                            ></VoiceToneSelect>
                        </div>
                    )}
                </div>
                <div className="img-inputs">
                    <ImgInput
                        name="logo"
                        buttonText={panelText('logo.button')}
                        title={panelText('logo.title')}
                        color={bgColor}
                        img={brandIcon}
                        onChangeIMG={setBrandIcon}
                    ></ImgInput>
                    <ImgInput
                        name="avatar"
                        buttonText={panelText('avatar.button')}
                        title={panelText('avatar.title')}
                        color={bgColor}
                        img={agentAvatar}
                        onChangeIMG={setAgentAvatar}
                    ></ImgInput>
                    <div className="item-full">
                        <ImgInput
                            name="icon"
                            buttonText={panelText('icon.button')}
                            title={panelText('icon.title')}
                            color={bgColor}
                            img={chatIcon}
                            onChangeIMG={setChatIcon}
                            imgOptions={[
                                MessageSVG,
                                HelpSVG,
                                MessagesSVG,
                                HeadsetSVG,
                                SparklesSVG,
                            ]}
                        ></ImgInput>
                    </div>
                </div>
                <div className="color-inputs">
                    <ColorInput
                        title={panelText('bg-color.title')}
                        text={panelText('bg-color.subtitle')}
                        color={bgColor}
                        onChangeColor={setBGColor}
                    ></ColorInput>
                    <ColorInput
                        title={panelText('action-color.title')}
                        text={panelText('action-color.subtitle')}
                        color={actionColor}
                        onChangeColor={setActionColor}
                    ></ColorInput>
                </div>
                <div className="buttons-container">
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={loadForm}
                    >
                        {commonsText('button.cancel')}
                    </Button>
                    <Button
                        variant="dark"
                        type="submit"
                        loading={formSubmitLoading}
                        className="save-button"
                    >
                        <IconCheck></IconCheck>
                        <span>
                            {commonsText('button.save')}
                            <span className="desktop">
                                {' '}
                                {commonsText('button.save-suffix')}
                            </span>
                        </span>
                    </Button>
                </div>
            </form>
        </S.IdentityPanel>
    );
};
