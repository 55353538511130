import React from 'react';
import * as S from './styles';
import { ExpansionPanelContainer } from 'app/presentation/components/agent';
import {
    IconSettings,
    IconAddressBook,
    IconClockHour3,
    IconShield,
} from '@tabler/icons-react';
import { ConfigPanel } from './config-panel';
import { MultilanguagePanel } from './multilanguage-panel';
import { AgentFormPanel } from './agent-form-panel';
import { WorkingHoursPanel } from './working-hours-panel';
import { SecurityPanel } from './security-panel';
import { BaselineTranslate } from 'app/presentation/components/icons';
import useText from 'app/presentation/hooks/useText';

const Config = () => {
    const { textGetter } = useText();
    const t = textGetter('agent.pages.config');
    return (
        <S.Config>
            <div className="config-form-container">
                <h2 className="title">{t('title')}</h2>
                <ExpansionPanelContainer
                    id="config-expansion-panel-container"
                    panels={[
                        {
                            buttonTitle: t('panel.config'),
                            panelContent: <ConfigPanel></ConfigPanel>,
                            icon: <IconSettings></IconSettings>,
                        },
                        {
                            buttonTitle: t('panel.multilanguage'),
                            icon: <BaselineTranslate></BaselineTranslate>,
                            panelContent: (
                                <MultilanguagePanel></MultilanguagePanel>
                            ),
                        },
                        {
                            buttonTitle: t('panel.form'),
                            icon: <IconAddressBook></IconAddressBook>,
                            panelContent: <AgentFormPanel></AgentFormPanel>,
                        },
                        {
                            buttonTitle: t('panel.working-hours'),
                            icon: <IconClockHour3></IconClockHour3>,
                            panelContent: (
                                <WorkingHoursPanel></WorkingHoursPanel>
                            ),
                            disabled: true,
                        },
                        {
                            buttonTitle: t('panel.security'),
                            icon: <IconShield></IconShield>,
                            panelContent: <SecurityPanel></SecurityPanel>,
                        },
                    ]}
                ></ExpansionPanelContainer>
            </div>
        </S.Config>
    );
};

export default Config;
