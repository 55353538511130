import React from 'react';
import { Button } from 'app/presentation/components';
import { FaCheck } from 'react-icons/fa6';
import { IoCloseSharp } from 'react-icons/io5';
import { TextEditor } from '../TextEditor';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormFields, ModalNewMemoryProps, SubmitButtonOpts, AnimateButtonOpts } from './types';
import { createMemory } from 'services/brand/memories';
import { MemoryContentTranslation, CreateMemoryReq } from 'services/brand/memories/types';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';
import { useSnackbar } from 'notistack';
import useMemoriesContext from 'app/presentation/hooks/useMemoriesContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { routeTranslate as t } from 'utils/intl';
import { SliderModal } from '../SliderModal/';
import { Select, Option } from '../ModalViewMemory/styles';
import useLanguage from 'app/presentation/hooks/useLanguage';

export const ModalNewMemory: React.FC<ModalNewMemoryProps> = props => {
    const { workspace, project, activeBrand } = useUserConfig();
    const { enqueueSnackbar: toast } = useSnackbar();
    const { fetchMemories } = useMemoriesContext();
    const { getLanguageName } = useLanguage();
    const intl = useIntl();

    const [editorContent, setEditorContent] = React.useState<string>("");
    const { register, handleSubmit, setValue } = useForm<FormFields>();
    const [submitButton, setSubmitButton] = React.useState<SubmitButtonOpts>(null);
    const [buttonLoadAnimation, setButtonLoadAnimation] = React.useState<AnimateButtonOpts>();

    React.useEffect(() => setValue('content', editorContent), [editorContent]);
    React.useEffect(() => {
        activeBrand && activeBrand.brand_config.default_language &&
            setValue('language_id', String(activeBrand.brand_config.default_language))
    }, []);

    const onSubmit: SubmitHandler<FieldValues> = async data => {
        if (!activeBrand || !submitButton) {
            console.log('something bad happened');
            return;
        }

        setButtonLoadAnimation({ animate: true, button: submitButton });

        const content: MemoryContentTranslation = {
            title: data.title,
            description: data.description,
            content: data.content,
            language_id: +data.language_id,
            publish_status: submitButton,
        }

        const req: CreateMemoryReq = {
            brand_memory_group_id: undefined,
            visibility: "public",
            translations: [content],
            headers: {
                workspace_id: workspace.id,
                project_id: project.id,
                brand_id: activeBrand.id,
            }
        };


        try {
            await createMemory(req);
            fetchMemories();


            toast(<FormattedMessage id="brand-center.knowledge.create.success" />, { variant: "success" });
        }
        catch (err: any) {
            err instanceof Error ?
                toast(<FormattedMessage id="brand-center.knowledge.create.error" />, { variant: "error" }) :
                toast("Something went wrong", { variant: "error" })
        }
        finally {
            setButtonLoadAnimation({ animate: false, button: null });
            props.setAnimateSlide(false);
        }
    };

    return (
        <SliderModal
            animateSlide={props.animateSlide}
            onExit={() => props.setShowCreateModal(false)}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className="slide-content">
                        <div className="slide-header">
                            <input
                                className="slide-input"
                                placeholder={t(intl, "brand-center.knowledge.modal-new.title-placeholder")}
                                {...register('title')}
                            />
                            <Button
                                className="slide-btn save"
                                type="submit"
                                onClick={() => setSubmitButton("draft")}
                                loading={buttonLoadAnimation?.animate && buttonLoadAnimation.button == "draft"}
                            >
                                <FormattedMessage id="brand-center.knowledge.modal-new.save-draft" />
                            </Button>

                            <Button
                                className="slide-btn publish"
                                icon={<FaCheck />}
                                iconPositon="start"
                                onClick={() => setSubmitButton("published")}
                                loading={buttonLoadAnimation?.animate && buttonLoadAnimation.button == "published"}
                            >
                                <FormattedMessage id="brand-center.knowledge.modal-new.publish" />
                            </Button>

                            <button
                                type="reset"
                                className="slide-btn close"
                                onClick={() => props.setAnimateSlide(false)}
                            >
                                <IoCloseSharp size={20} />
                            </button>
                        </div>

                        <textarea
                            className="description-input"
                            placeholder={t(intl, "brand-center.knowledge.modal-new.description-placeholder")}
                            {...register('description')}
                        />

                        <Select value={activeBrand && activeBrand.brand_config.default_language} disabled>
                            {activeBrand?.supported_languages && activeBrand.supported_languages.map(elem => (
                                elem.language.id == activeBrand.brand_config.default_language && (
                                    <Option
                                        selected={elem.language_id == activeBrand.brand_config.default_language && true}
                                        value={elem.language.id}
                                    >
                                        <span>
                                            {getLanguageName(elem.language)}
                                            {elem.language_id == activeBrand.brand_config.default_language && (
                                                <>
                                                    {` (${intl.formatMessage({ id: "language.default" })})`}
                                                </>
                                            )}
                                        </span>
                                    </Option>
                                )))}
                        </Select>



                        <TextEditor
                            initialContent=""
                            content={editorContent}
                            setContent={setEditorContent}
                            editable={true}
                            placeholder={t(intl, "brand-center.knowledge.modal-new.editor-placeholder")}
                        />
                    </div>
                </div>
            </form>
        </SliderModal>
    );
};
