import { useContext } from 'react';
import TextContext from '../contexts/TextContext';
import { TextContextData } from '../contexts/interfaces/text-context-props.interface';

function useText(): TextContextData {
    const context = useContext(TextContext);

    if (!context) {
        throw new Error('useText deve ser usado com o TextProvider');
    }

    return context;
}

export default useText;
