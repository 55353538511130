import Slide from '@mui/material/Slide';
import styled from 'styled-components';

interface Props {
    zIndex?: number;
}

export const Backdrop = styled.div<Props>`
    background-color: #11151d90;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: ${props => props.zIndex ? `${props.zIndex} !important` : 0};
`;



export const StyledSlide = styled(Slide) <Props>`
    background-color: #fff;
    width: 898px;
    height: 100vh;
    position: fixed;
    top: 0vh;
    left: calc(100vw - 898px);
    margin: 0px;
    z-index: ${props => props.zIndex ? `${props.zIndex} !important` : 0};


    overflow-y: auto;

    textarea {
        resize: none;
    }

    .slide-content {
        margin-top: 32px;
        margin-left: 32px;
        margin-right: 32px;
        margin-bottom: 32px;

        hr {
            margin-top: 30px;
            margin-bottom: 30px;
            height: 1px;
            border: 0;
            background-color: rgba(226, 232, 240, 1);
        }
    }

    .slide-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        overflow: hidden;
        text-overflow: ellipsis;

        height: 44px;
        gap: 12px;

        .slide-btn {
            font-size: 14px;
            height: 44px;
            border: 0;
            border-radius: 8px;
            font-weight: 700;
            padding: 0;

            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1px;
        }

        .save {
            color: #fff;
            background-color: #000;
            width: 161px;
        }

        .publish {
            background-color: #d0ff61;
            width: 130px;
        }

        .close {
            background-color: #f1f1f1;
            width: 44px;
        }

        .unpublish {
            width: 256px;
            background-color: #f1f1f1;
        }

        .edit {
            background-color: #d0ff61;
            width: 114px;
        }

        .slide-input {
            &::placeholder {
                color: #a09fa5;
            }

            //width: 350px;
            width: auto;
            min-width: 150px;
            font-size: 24px;
            font-weight: 700;
            border: 0;

            margin-right: auto;
            box-sizing: border-box;
            border-bottom: 1px dashed #cbd5e0;

            font-family: Manrope;

            overflow: hidden;
        }
    }

    .description-input {
        &::placeholder {
            color: #a09fa5;
        }

        width: 100%;
        border: 0;
        margin-top: 30px;
        padding-top: 30px;
        padding-bottom: 30px;

        border-top: 1px solid #e2e8f0;
    }

    pre {
        background: #282c34;
        border-radius: 0.5rem;
        color: #fff;
        padding: 0.75rem 1rem;
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
            Liberation Mono, Courier New, monospace;

        code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
            font-family: inherit;
        }
    }

    a {
        color: #68cef8;
    }

    ul,
    ol {
        padding-left: 2rem;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 3px solid lightgray;
    }

    .slide-title {
        width: auto;
        max-width: 350px;
        font-size: 24px;
        font-weight: 700;
        border: 0;

        margin-right: auto;

        font-family: Manrope;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .no-text {
        color: rgba(160, 159, 165, 1);
    }
`;

// TODO this thing is reutilized on ModalDeleteKnowledge, so maybe can be generalized
export const SlideBackground = styled.div``;
