import { Route, Routes } from 'react-router-dom';
import Home from '../pages/dashboard/home';
import { routeTranslate } from '../utils/intl';
import { useIntl } from 'react-intl';
import Toolbar from '../app/presentation/components/dashboard/Toolbar';
import DashboardLayout from '../app/presentation/layout/dashboard';
import Library from '../pages/dashboard/library';
import Documents from '../pages/dashboard/documents';
import Models from '../pages/dashboard/models';
import NewModel from '../pages/dashboard/models/new-model';
import Definitions from '../pages/dashboard/definitions';
import { HeaderDashboard } from 'app/presentation/components';
import NewPostBlog from '../pages/dashboard/documents/new-post-blog';
import ArteAi from '../pages/dashboard/arte-ai';
import Model from '../pages/dashboard/library/model';
import Notifications from '../pages/dashboard/notifications';
import Document from '../pages/dashboard/documents/document';
import Chat from '../pages/dashboard/chat';
import NewChat from '../pages/dashboard/chat/new-chat';
import PageNotFound from 'pages/not-found/PageNotFound';
import RewardfulView from 'pages/onboarding/rewardful';
import AudioAi from 'pages/dashboard/audio-ai';
import { isDebug } from 'utils/isDebug';
import { BrandCenterRoutes } from './brandCenterRoutes';

export const DashboardRoutes = () => {
    const intl = useIntl();

    return (
        <DashboardLayout>
            <>
                <HeaderDashboard />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path={`${routeTranslate(intl, 'route.home')}`}
                        element={<Home />}
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.library')}`}
                        element={<Library />}
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.rewardful')}`}
                        element={<RewardfulView />}
                    />
                    <Route
                        path={`${routeTranslate(
                            intl,
                            'route.library',
                        )}/:category`}
                        element={<Library />}
                    />
                    <Route
                        path={`${routeTranslate(
                            intl,
                            'route.library',
                        )}${routeTranslate(intl, 'route.model')}/:id`}
                        element={<Model />}
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.documents')}`}
                        element={<Documents />}
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.models')}`}
                        element={<Models />}
                    />
                    <Route
                        path={`${routeTranslate(
                            intl,
                            'route.models',
                        )}${routeTranslate(intl, 'route.newModel')}`}
                        element={<NewModel />}
                    />
                    <Route
                        path={`${routeTranslate(
                            intl,
                            'route.chat',
                        )}${routeTranslate(intl, 'route.newChat')}`}
                        element={<NewChat />}
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.arteAi')}`}
                        element={<ArteAi />}
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.audioAi')}`}
                        element={
                            isDebug() ? <AudioAi hasId={false} /> : <Home />
                        }
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.audioAi')}/:id`}
                        element={
                            isDebug() ? <AudioAi hasId={true} /> : <Home />
                        }
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.notifications')}`}
                        element={<Notifications />}
                    />
                    <Route
                        path={`${routeTranslate(
                            intl,
                            'route.documents',
                        )}${routeTranslate(intl, 'route.newPostBlog')}`}
                        element={<NewPostBlog />}
                    />

                    <Route
                        path={`${routeTranslate(intl, 'route.definitions')}/*`}
                        element={<Definitions />}
                    />
                    <Route
                        path={`${routeTranslate(
                            intl,
                            'route.documents',
                        )}${routeTranslate(intl, 'route.document')}/:rtc/:id`}
                        element={<Document />}
                    />
                    <Route
                        path={`${routeTranslate(
                            intl,
                            'route.documents',
                        )}${routeTranslate(intl, 'route.document')}`}
                        element={<Document />}
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.chat')}`}
                        element={<Chat />}
                    />
                    <Route
                        path={`${routeTranslate(intl, 'route.brand-center')}/*`}
                        element={<BrandCenterRoutes />}
                    />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
                <Toolbar />
            </>
        </DashboardLayout>
    );
};
