import React from 'react';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import ReactSlider from 'react-slider';
import styled from 'styled-components';

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 14px;
`;

const StyledThumb = styled.div`
    height: 14px;
    line-height: 14px;
    width: 14px;
    background-color: ${(props: any) => (props.darkmode ? '#d0ff61' : '#000')};
    border-radius: 50%;
    cursor: grab;
`;

const StyledTrack = styled.div`
    top: 6px;
    bottom: 0;
    background: ${(props: any) =>
        !props.darkmode
            ? props.index === 1
                ? '#E2E8F0'
                : '#000'
            : props.index === 1
            ? '#000'
            : '#d0ff61'};
    border-radius: 999px;
    height: 3px;
`;

type ISlider = {
    defaultValue?: number | readonly number[];
    onChange: (value?: any) => void;
    step?: number;
    min?: number;
    max?: number;
    marks?: boolean;
    onSlideClick?: (value?: any) => void;
};

const Slider: React.FC<ISlider> = ({
    defaultValue,
    onChange,
    step,
    max,
    min,
    marks = false,
    onSlideClick,
}) => {
    const { darkmode } = useDarkMode();

    const Track = (props: any, state: any) => (
        <StyledTrack {...props} index={state.index} darkmode={darkmode} />
    );
    const Thumb = (props: any) => (
        <StyledThumb
            {...props}
            darkmode={darkmode}
            onMouseEnter={onSlideClick}
        />
    );
    return (
        <StyledSlider
            defaultValue={defaultValue}
            onChange={value => onChange(value as number)}
            renderTrack={Track}
            renderThumb={Thumb}
            step={step}
            min={min}
            max={max}
            marks={marks}
            onSliderClick={onSlideClick}
        />
    );
};

export default Slider;
