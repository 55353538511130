import { IconChevronRight } from '@tabler/icons-react';
import React from 'react';
import * as S from './styles';

interface ExpansionPanelProps {
    id: string;
    expandedPanel: string | undefined;
    setExpandedPanel: (expanded: string) => void;
    buttonTitle: string | React.ReactNode;
    buttonSubtitle?: string | null;
    panelContent: React.ReactNode;
    icon: React.ReactNode;
    disabled?: boolean;
}

const ExpansionPanel: React.FC<ExpansionPanelProps> = ({
    buttonTitle,
    buttonSubtitle = null,
    panelContent,
    icon,
    id,
    expandedPanel,
    setExpandedPanel,
    disabled = false,
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const panelTransitionSeconds = 0.1;
    const changeExpandedState = () => {
        if (expandedPanel != id) {
            setExpandedPanel(id);
        } else {
            setExpandedPanel('');
        }
    };
    const containerRef = React.useRef<HTMLDivElement>(null);
    const panelRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const container = containerRef.current;
        const panel = panelRef.current;
        if (container == null || panel == null) {
            return;
        }

        if (!expanded && expandedPanel == id) {
            panel.style.transition =
                'max-height linear ' + panelTransitionSeconds + 's';
            setExpanded(true);
            panel.style.maxHeight = panel.scrollHeight + 'px';
            setTimeout(() => {
                panel.style.transition = 'none';
                panel.style.maxHeight = '10000px';
            }, panelTransitionSeconds * 1000);
        } else if (expanded && expandedPanel != id) {
            panel.style.transition = 'none';
            panel.style.maxHeight = panel.scrollHeight + 'px';
            setTimeout(() => {
                panel.style.transition =
                    'max-height linear ' + panelTransitionSeconds + 's';
                setExpanded(false);
                panel.style.maxHeight = '0px';
            }, 1);
        }
    }, [expandedPanel]);

    return (
        <S.ExpansionPanel
            id={id}
            ref={containerRef}
            className={
                (expanded ? 'expanded-panel ' : '') +
                (disabled ? 'disabled' : '')
            }
        >
            <button
                type="button"
                onClick={changeExpandedState}
                className="panel-button"
                disabled={disabled}
            >
                <div className="button-content">
                    {icon}
                    <div>
                        {typeof buttonTitle === 'string' ? (
                            <p className="panel-button-title">{buttonTitle}</p>
                        ) : (
                            <div className="panel-button-title">
                                {buttonTitle}
                            </div>
                        )}
                        {buttonSubtitle != null ? (
                            <p className="panel-button-subtitle">
                                {buttonSubtitle}
                            </p>
                        ) : null}
                    </div>
                </div>
                <div className="expansion-button-icon">
                    <IconChevronRight></IconChevronRight>
                </div>
            </button>
            <div ref={panelRef} className="panel">
                <div className="panel-content">{panelContent}</div>
            </div>
        </S.ExpansionPanel>
    );
};

interface ExpansionPanel {
    buttonTitle: string | React.ReactNode;
    buttonSubtitle?: string | null;
    icon: React.ReactNode;
    panelContent: React.ReactNode;
    disabled?: boolean;
}

interface ExpansionContainerProps {
    id: string;
    panels: ExpansionPanel[];
}

export const ExpansionPanelContainer: React.FC<ExpansionContainerProps> = ({
    id,
    panels,
}) => {
    const [expandedPanel, setExpandedPanel] = React.useState<string>('');
    return (
        <S.ExpansionPanelContainer>
            {panels.map((panel, index) => {
                return (
                    <ExpansionPanel
                        {...panel}
                        setExpandedPanel={setExpandedPanel}
                        expandedPanel={expandedPanel}
                        id={id + '-' + index}
                        key={id + '-' + index}
                    ></ExpansionPanel>
                );
            })}
        </S.ExpansionPanelContainer>
    );
};
