import { useContext } from 'react';
import { DocumentsContext } from '../contexts/DocumentContext';
import { DocumentsContextData } from '../contexts/interfaces/document-context-props.interface';

function useDocuments(): DocumentsContextData {
    const context = useContext(DocumentsContext);

    if (!context) {
        throw new Error('useDocuments deve ser usado com o DocumentsProvider');
    }

    return context;
}

export default useDocuments;
