import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    .status-label {
        font-size: 14px !important;
    }

    @media (max-width: 64em) {
        .table {
            display: none;
        }
    }

    @media (min-width: 64em) {
        .box {
            display: none;
        }
    }
`;

export const Table = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    tr {
        border-bottom: 1px solid #edf2f7;
        height: 80px;
        td:first-of-type {
            cursor: pointer;
        }
    }

    tr:last-of-type {
        border-bottom: 0;
    }

    .training-type {
        display: flex;
        justify-items: center;
        align-items: center;
        svg {
            margin-right: 10px;
        }
    }

    th {
        color: #a09fa5;
        font-weight: 700;
        font-size: 16px;
        vertical-align: middle;
    }

    td {
        color: #000000;
        font-weight: 600;
        text-align: left;
        font-size: 14px;
    }

    .training-table-title {
        max-width: 302px;
    }
`;

export const Box = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    .box-item {
        border-top: 1px solid #edf2f7;
        padding-top: 24px;
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        justify-content: space-between;
        div {
            display: flex;
            flex-direction: row;

            .field-name {
                color: #a09fa5;
                flex-basis: 50%;
            }

            .field-value {
                flex-basis: 50%;
                text-align: right;
                display: flex;
                justify-content: flex-end;

                span {
                    width: fit-content;
                }
            }
        }

        * {
            font-weight: 600;
            text-align: left;
            font-size: 14px;
        }

        h1 {
            font-weight: 800;
        }
    }
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 34px;
    border-top: 1px solid #edf2f7;

    &.dark {
        border-top: 1px solid #2a313c !important;
    }

    button {
        color: #718096;
    }

    .Mui-selected {
        border: 1px solid #1a202c;
        border-radius: 8px;
        min-width: 40px;
        height: 40px;
        color: #000000;
        font-weight: 700;
        background: #fff !important;
    }

    .per-page {
        display: flex;
        align-items: center;
        gap: 16px;
        min-width: 214px;

        span {
            color: #a0aec0;
            font-size: 0.875em;
            font-weight: 700;
            flex: 1;
        }

        > div {
            width: 68px;
            min-height: 37px;

            select {
                font-weight: 700;
                font-size: 0.875em;
                padding: 0 12px;
                width: 68px;
                min-height: 37px;
                height: 37px;
                border-radius: 8px;
            }
        }
    }
`;
