import React from 'react';
import { TableContainerContent } from './styles';
import { FormattedMessage } from 'react-intl';
import { TableRow } from '..';
import { type Collection } from 'services/collections/types';
import { Table, TableHead, TableBody } from "./styles";
import useCollectionsContext from 'app/presentation/hooks/useCollectionsContext';
import { RowContent, type TreeNode } from 'app/presentation/providers/CollectionsProvider';

type CollectionsTableProps = {
    selectedLanguage: number;
    focusedElement: string | undefined;
}

export const CollectionsTable: React.FC<CollectionsTableProps> = ({ selectedLanguage, focusedElement }) => {
    const { tree } = useCollectionsContext();

    const [renderTree, setRenderTree] = React.useState<Map<string, TreeNode>>();
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

    const canvasRef = React.useRef(null);

    // table is always rerendering when tree changes, maybe change the validation to decrease rerendering?
    React.useEffect(() => setRenderTree(tree), [tree]);

    return (
        <TableContainerContent>
            <canvas ref={canvasRef} style={{ display: 'none' }} />

            {/*<Loading open={loading} />*/}

            <Table>
                <TableHead>
                    <div className="collection-name">
                        <FormattedMessage id="brand-center.collections.table.collection-name" />
                    </div>
                    <div className="collection-status">
                        <FormattedMessage id="brand-center.collections.table.collection-status" />
                    </div>
                    <div className="articles-count">
                        <FormattedMessage id="brand-center.collections.table.collection-article-count" />
                    </div>
                    <div className="empty-space"></div>
                </TableHead>

                <TableBody>
                    {/*<button onClick={() => { console.log(tree); }}>log tree</button>*/}

                    {renderTree && Array.from(renderTree).map(([, node]) => (
                        !node.parentId && node.type != RowContent.Memory && (
                            <TableRow
                                key={`${RowContent.Collection},${node.obj.id}`}
                                collect={node.obj as Collection}
                                selectedLanguage={selectedLanguage}
                                rowContent={RowContent.Collection}
                                depth={0}
                                isFocused={`${RowContent.Collection},${node.obj.id}` == focusedElement}
                                anchor={anchor}
                                setAnchor={setAnchor}
                            />
                        )
                    ))}

                </TableBody>
            </Table>

        </TableContainerContent>
    );
};

