import React from 'react';
import * as S from './styled';
import useText from 'app/presentation/hooks/useText';

interface UserLimitInputProps {
    value: number;
    onChange: (value: number) => void;
}

export const UserLimitInput: React.FC<UserLimitInputProps> = ({
    value,
    onChange,
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.user-limit-input');
    const [inputFocus, setInputFocus] = React.useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const validateValue = () => {
        const el = inputRef.current;
        if (!el) {
            return;
        }

        if (el.value == '' || parseInt(el.value) < 0) {
            el.setCustomValidity(t('min-limit-message'));
        } else {
            el.setCustomValidity('');
        }
    };

    const onInputContainerClick = () => {
        const input = inputRef.current;
        if (input) {
            input.focus();
        }
    };

    const onInputFocus = () => {
        setInputFocus(true);
    };

    const onInputBlur = () => {
        setInputFocus(false);
    };

    React.useEffect(() => {
        validateValue();
    }, [value]);

    const onUserLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
            event.target.value == '' ||
            Number.isNaN(Number(event.target.value))
        ) {
            onChange(0);
        } else {
            onChange(parseInt(event.target.value, 10));
        }
    };

    return (
        <S.UserLimitInput
            className={inputFocus ? 'active' : ''}
            onClick={onInputContainerClick}
        >
            <input
                type="text"
                value={value}
                onChange={onUserLimitChange}
                ref={inputRef}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                min={1}
            ></input>
            <p>{t('rate')}</p>
        </S.UserLimitInput>
    );
};
