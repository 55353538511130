import React from 'react';

type ISearchIcon = {
    darkmode?: boolean;
};

export const Search: React.FC<ISearchIcon> = ({ darkmode = false }) => (
    <svg
        width="19"
        height="19"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="9.7666"
            cy="9.76657"
            r="8.98856"
            stroke={darkmode ? '#fff' : '#1A202C'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.0183 16.4851L19.5423 20"
            stroke={darkmode ? '#fff' : '#1A202C'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
