import { Editor } from '@tiptap/react';
import React from 'react';
import { BubbleButton, StyledBubbleMenu } from './styles';
import {
    IconBold,
    IconItalic,
    IconStrikethrough,
    IconCode,
    IconLink,
    IconH1,
    IconH2,
    IconH3,
    IconH4,
    IconAlignLeft,
    IconAlignCenter,
    IconAlignRight,
    IconAlignJustified,
} from '@tabler/icons-react';
import Tooltip from '@mui/material/Tooltip';
import { useIntl } from 'react-intl';
import { routeTranslate as t } from 'utils/intl';

type InlineMenuProps = {
    editor: Editor;
};

const popperProps = {
    sx: {
        '& .MuiTooltip-tooltip': {
            backgroundColor: 'black',
            color: 'white',
        },
    },
};

export const InlineMenu: React.FC<InlineMenuProps> = props => {
    const intl = useIntl();

    const setLink = React.useCallback(() => {
        const previousUrl = props.editor?.getAttributes('link').href;
        const url = window.prompt('URL', previousUrl);

        // cancelled
        if (url === null) return;
        

        // empty
        if (url === '') {
            props.editor
                ?.chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run();
            props.editor?.chain().focus().unsetUnderline().run();
            return;
        }

        // update link
        props.editor
            ?.chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: url })
            .run();

        props.editor?.chain().focus().setUnderline().run();
    }, [props.editor]);

    return (
        <StyledBubbleMenu editor={props.editor}>
            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.bold')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().toggleBold().run()}
                    data-active={props.editor.isActive('bold')}
                >
                    <IconBold stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.italic')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().toggleItalic().run()}
                    data-active={props.editor.isActive('italic')}
                >
                    <IconItalic stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.strike')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().toggleStrike().run()}
                    data-active={props.editor.isActive('strike')}
                >
                    <IconStrikethrough stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.code')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().toggleCodeBlock().run()}
                    data-active={props.editor.isActive('codeBlock')}
                >
                    <IconCode stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.link')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => setLink()}
                    data-active={props.editor.isActive('link')}
                >
                    <IconLink stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <hr />

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.heading-1')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    data-active={props.editor.isActive('heading', { level: 1 })}
                >
                    <IconH1 stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.heading-2')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() =>
                        props.editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    data-active={props.editor.isActive('heading', { level: 2 })}
                >
                    <IconH2 stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.heading-3')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().toggleHeading({ level: 3 }).run()}
                    data-active={props.editor.isActive('heading', { level: 3 })}
                >
                    <IconH3 stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.heading-4')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().toggleHeading({ level: 4 }).run()}
                    data-active={props.editor.isActive('heading', { level: 4 })}
                >
                    <IconH4 stroke={1.5} />
                </BubbleButton>
            </Tooltip>
            <hr />

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.align-left')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().setTextAlign('left').run()}
                    data-active={props.editor.isActive({ textAlign: 'left' })}
                >
                    <IconAlignLeft stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.align-center')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().setTextAlign('center').run()}
                    data-active={props.editor.isActive({ textAlign: 'center' })}
                >
                    <IconAlignCenter stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.align-right')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() => props.editor.chain().focus().setTextAlign('right').run()}
                    data-active={props.editor.isActive({ textAlign: 'right' })}
                >
                    <IconAlignRight stroke={1.5} />
                </BubbleButton>
            </Tooltip>

            <Tooltip
                title={t(intl, 'bubble-menu.tooltip.align-justify')}
                placement="top"
                PopperProps={popperProps}
            >
                <BubbleButton
                    onClick={() =>
                        props.editor.chain().focus().setTextAlign('justify').run()}
                    data-active={props.editor.isActive({
                        textAlign: 'justify',
                    })}
                >
                    <IconAlignJustified stroke={1.5} />
                </BubbleButton>
            </Tooltip>
        </StyledBubbleMenu>
    );
};
