export const base64ToFile = (base64: string): File => {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    const arrBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrBuffer);

    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: mimeString });
    return new File([blob], 'resized.png', { type: mimeString });
};
