import React from 'react';

type IconProps = {
    darkmode?: boolean;
};

export const History: React.FC<IconProps> = ({ darkmode = false }) => (
    <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1707_18869)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.76833 2.39893C10.5269 2.04913 12.3523 2.31991 13.9336 3.16512C15.5149 4.01034 16.7542 5.3777 17.4404 7.03422C18.1265 8.69073 18.2171 10.5339 17.6966 12.2497C17.1761 13.9655 16.0768 15.4478 14.586 16.4439C13.0951 17.44 11.305 17.8884 9.52064 17.7127C7.73627 17.5369 6.06804 16.7479 4.8002 15.4801C4.5073 15.1872 4.5073 14.7123 4.8002 14.4194C5.09309 14.1265 5.56796 14.1265 5.86086 14.4194C6.88331 15.4419 8.22866 16.0782 9.66767 16.2199C11.1067 16.3616 12.5503 16 13.7526 15.1967C14.9549 14.3934 15.8414 13.198 16.2612 11.8143C16.6809 10.4306 16.6079 8.94414 16.0546 7.60824C15.5012 6.27234 14.5018 5.16963 13.2265 4.488C11.9513 3.80638 10.4792 3.58801 9.06096 3.8701C7.64278 4.1522 6.36627 4.9173 5.44896 6.03505C4.63495 7.02693 4.14847 8.24204 4.04923 9.51355L4.8002 8.76258C5.09309 8.46968 5.56796 8.46968 5.86086 8.76258C6.15375 9.05547 6.15375 9.53034 5.86086 9.82324L3.73954 11.9446C3.59889 12.0852 3.40812 12.1642 3.20921 12.1642C3.0103 12.1642 2.81953 12.0852 2.67888 11.9446L0.557561 9.82324C0.264667 9.53034 0.264667 9.05547 0.557561 8.76258C0.850454 8.46968 1.32532 8.46968 1.61822 8.76258L2.53683 9.68119C2.60594 8.00186 3.21953 6.38715 4.28944 5.08346C5.42691 3.69745 7.00978 2.74872 8.76833 2.39893ZM10.2803 6.25001C10.6945 6.25001 11.0303 6.5858 11.0303 7.00001V9.57537L13.1662 10.8569C13.5214 11.07 13.6365 11.5307 13.4234 11.8859C13.2103 12.2411 12.7496 12.3562 12.3944 12.1431L9.8944 10.6431C9.6685 10.5076 9.53028 10.2635 9.53028 10V7.00001C9.53028 6.5858 9.86606 6.25001 10.2803 6.25001Z"
                fill={darkmode ? '#CCCCCC' : 'black'}
            />
        </g>
        <defs>
            <clipPath id="clip0_1707_18869">
                <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.280273)"
                />
            </clipPath>
        </defs>
    </svg>
);
