import {
    IconBlockquote,
    IconRosetteDiscountCheck,
    IconWorld,
} from '@tabler/icons-react';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';
import { useState } from 'react';
import { Page } from 'services/client';
import { TrainingService } from 'services/training';
import { Training, TrainingResourceTypes } from 'services/training/interfaces';
import { timeDifference } from 'utils/time';
import StatusLabel from '../../StatusLabel';
import { SwitchCheckbox } from '../../SwitchCheckbox';
import { TrainingDrawer } from '../Drawers';
import * as S from './styles';

interface TrainingTableProps {
    page: Page<Training>;
}

const TrainingTable = ({ page }: TrainingTableProps) => {
    const t = useText().textGetter('agent.components.training-table');
    const { agent, notifyAgentTrainingChanged } = useAgent();
    const route = useText().routeGetter('', true);
    const trainingService = new TrainingService();
    const [enabledValues, setEnabledValues] = useState<Record<number, boolean>>(
        {},
    );
    const [trainingDrawerActive, setTrainingDrawerActive] = useState(false);
    const [selectedTraining, setSelectedTraining] = useState<Training>();

    const getTrainingTypeDisplay = (training: Training) => {
        switch (training.resource_type) {
            case TrainingResourceTypes.BrandCenter:
                return (
                    <>
                        <IconRosetteDiscountCheck />
                        {t('training-type.brandCenter')}
                    </>
                );
            case TrainingResourceTypes.URLContent:
                return (
                    <>
                        <IconWorld />
                        {t('training-type.urlContent')}
                    </>
                );
            case TrainingResourceTypes.Excerpt:
                return (
                    <>
                        <IconBlockquote />
                        {t('training-type.excerpt')}
                    </>
                );
            default:
                return <></>;
        }
    };

    const handleTableItemClick = (training: Training) => {
        let fn: () => void;
        switch (training.resource_type) {
            case TrainingResourceTypes.BrandCenter:
                fn = () =>
                    window.open(
                        route(
                            'backoffice',
                            'brand-center',
                            'brand-center.knowledge',
                        ) +
                            `?brand_memory_content_id=${training.getResourceID()}`,
                        '_blank',
                    );
                break;
            case TrainingResourceTypes.URLContent:
                fn = () => {
                    setTrainingDrawerActive(true);
                };
                break;
            case TrainingResourceTypes.Excerpt:
                fn = () => {
                    setTrainingDrawerActive(true);
                };
                break;
        }

        return () => {
            setSelectedTraining(training);
            fn();
        };
    };

    const getContent = (training?: Training) => {
        if (!training) return undefined;
        if (training.excerpt) return training.excerpt;
        if (training.url_content) return training.url_content;
        return undefined;
    };

    return (
        <S.Container>
            <TrainingDrawer
                animateSlide={trainingDrawerActive}
                handleClose={() => setTrainingDrawerActive(false)}
                content={getContent(selectedTraining) ?? undefined}
                contentType={selectedTraining?.resource_type ?? undefined}
                contentEnabled={selectedTraining?.enabled ?? false}
                isEditing={false}
                notifyAgentTrainingChanged={notifyAgentTrainingChanged}
            />

            <S.Table className="table">
                <tr>
                    <th>{t('title')}</th>
                    <th>{t('origin')}</th>
                    <th>{t('status')}</th>
                    <th>{t('updatedAt')}</th>
                </tr>

                {page.data.map((training, index) => (
                    <tr key={index}>
                        <td
                            className="training-table-title"
                            onClick={handleTableItemClick(training)}
                        >
                            {training.title}
                        </td>
                        <td>
                            <div className="training-type">
                                {getTrainingTypeDisplay(training)}
                            </div>
                        </td>
                        <td>
                            <SwitchCheckbox
                                name={`${index}-enabled`}
                                value={
                                    enabledValues[training.id] ??
                                    training.enabled
                                }
                                setValue={async (v: boolean) => {
                                    if (!agent) return;
                                    trainingService.updateTrainingEnabled(
                                        agent.id,
                                        training.getResourceID(),
                                        training.resource_type,
                                        v,
                                    );
                                    notifyAgentTrainingChanged();
                                    training.enabled = v;

                                    setEnabledValues({
                                        ...enabledValues,
                                        [training.id]: v,
                                    });
                                }}
                            />
                        </td>
                        <td>
                            {timeDifference(
                                new Date(),
                                new Date(training.getLastUpdatedAt()),
                            )}
                        </td>
                    </tr>
                ))}
            </S.Table>
            <S.Box className="box">
                {page.data.map((training, index) => (
                    <div className="box-item">
                        <h1>{training.title}</h1>
                        <div>
                            <span className="field-name">{t('origin')}</span>
                            <span className="field-value">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '5px',
                                    }}
                                >
                                    <IconRosetteDiscountCheck />{' '}
                                    {t('training-type.brandCenter')}
                                </div>
                            </span>
                        </div>
                        <div>
                            <span className="field-name">{t('status')}</span>
                            <span className="field-value">
                                <StatusLabel
                                    status={
                                        training.enabled
                                            ? 'success'
                                            : 'greyed-out'
                                    }
                                    text={
                                        training.enabled
                                            ? t('status.enabled')
                                            : t('status.disabled')
                                    }
                                    className="status-label"
                                />
                            </span>
                        </div>
                        <div>
                            <span className="field-name">{t('updatedAt')}</span>
                            <span className="field-value">
                                {timeDifference(
                                    new Date(),
                                    new Date(training.updated_at),
                                )}
                            </span>
                        </div>
                    </div>
                ))}
            </S.Box>
        </S.Container>
    );
};

export default TrainingTable;
