import {
    Agent,
    CreateAgentRequest,
    UpdateLanguageConfigRequest,
    UpdateFormRequest,
    UpsertQuestionsRequest,
    UpdateSecurityConfigRequest,
    UpsertWelcomeMessagesRequest,
    UpdateIdentityRequest,
    UpdateConfigRequest,
    UpdateWorkingHoursRequest,
    UpdateAdditionalMessages,
    Tag,
    UpdateBehaviorRequest,
    Action,
    UpdateAgentRequest,
} from './interfaces';
import { Page, client } from '../client';

export class AgentService {
    private readonly agentClient = client
        .addPath('/agents')
        .addWorkspaceHeaders();

    async getAll(brandID: number, page = 1, limit = 8): Promise<Page<Agent>> {
        return this.agentClient.getData<Page<Agent>>({
            queryParams: { id: brandID, page: page, limit: limit },
        });
    }

    async get(agentID: number): Promise<Agent> {
        return this.agentClient.getData<Agent>({
            pathParams: [agentID],
        });
    }

    async create(request: CreateAgentRequest): Promise<Agent> {
        return this.agentClient.postData<Agent, CreateAgentRequest>(request);
    }

    async updateAgent(
        agentID: number,
        request: UpdateAgentRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpdateAgentRequest>(request, {
            pathParams: [agentID],
        });
    }

    async upsertQuestions(
        agentID: number,
        request: UpsertQuestionsRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpsertQuestionsRequest>(request, {
            pathParams: [agentID, 'initial-questions'],
        });
    }

    async updateForm(
        agentID: number,
        request: UpdateFormRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpdateFormRequest>(request, {
            pathParams: [agentID, 'form'],
        });
    }

    async updateLanguageConfig(
        agentID: number,
        request: UpdateLanguageConfigRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpdateLanguageConfigRequest>(
            request,
            {
                pathParams: [agentID, 'languages'],
            },
        );
    }

    async updateSecurityConfig(
        agentID: number,
        request: UpdateSecurityConfigRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpdateSecurityConfigRequest>(
            request,
            {
                pathParams: [agentID, 'security-config'],
            },
        );
    }

    async upsertWelcomeMessages(
        agentID: number,
        request: UpsertWelcomeMessagesRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpsertWelcomeMessagesRequest>(
            request,
            {
                pathParams: [agentID, 'welcome-messages'],
            },
        );
    }

    async updateIdentity(
        agentID: number,
        request: UpdateIdentityRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpdateIdentityRequest>(request, {
            pathParams: [agentID, 'identity'],
        });
    }

    async updateConfig(
        agentID: number,
        request: UpdateConfigRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpdateConfigRequest>(request, {
            pathParams: [agentID, 'config'],
        });
    }

    async updateWorkingHours(
        agentID: number,
        request: UpdateWorkingHoursRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpdateWorkingHoursRequest>(
            request,
            {
                pathParams: [agentID, 'working-hours'],
            },
        );
    }

    async updateAdditionalMessages(
        agentID: number,
        request: UpdateAdditionalMessages,
    ): Promise<void> {
        return this.agentClient.putData<void, UpdateAdditionalMessages>(
            request,
            {
                pathParams: [agentID, 'additional-messages'],
            },
        );
    }

    async updateBehavior(
        agentID: number,
        request: UpdateBehaviorRequest,
    ): Promise<void> {
        return this.agentClient.putData<void, UpdateBehaviorRequest>(request, {
            pathParams: [agentID, 'behavior'],
        });
    }

    async delete(agentID: number): Promise<void> {
        return this.agentClient.deleteData<void>({
            pathParams: [agentID],
        });
    }

    async getAllTags(brandID: number): Promise<Tag[]> {
        return this.agentClient.getData<Tag[]>({
            path: '/tags',
            queryParams: { id: brandID },
        });
    }

    async getAllActions(agentID: number): Promise<Action[]> {
        return this.agentClient.getData<Action[]>({
            pathParams: [agentID, 'actions'],
        });
    }
}
