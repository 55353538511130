import React from 'react';
import { ContainerModal } from './styles';
import { Input } from 'app/presentation/components';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';
import useChat from 'app/presentation/hooks/useChat';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
}

const ModalPersonalities: React.FC<ModalProps> = ({ handleClose }) => {
    const [search, setSearch] = React.useState('');
    const intl = useIntl();

    const { personalities, setPersonality } = useChat();

    const { darkmode } = useDarkMode();

    const handleChangeProject = (id: number) => {
        if (!personalities) return;
        const findPersonality = personalities.data.find(item => item.id === id);
        setPersonality(findPersonality);
        handleClose();
    };

    return (
        <ContainerModal className={darkmode ? 'listProjectsDark' : ''}>
            <div className="content-modal">
                <form>
                    <div className="input">
                        <Input
                            name="search-personality"
                            id="search-personality"
                            placeholder={routeTranslate(
                                intl,
                                'label.search.personality',
                            )}
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                        />
                    </div>
                </form>
                <ul>
                    {personalities && personalities.data.length > 0 ? (
                        personalities.data
                            ?.filter(project => {
                                const myReg = new RegExp(
                                    search.toLowerCase() + '.*',
                                );
                                return project.translation[0].display_name
                                    .toLowerCase()
                                    .match(myReg);
                            })
                            .map((project, index) => (
                                <li
                                    key={index}
                                    onClick={() =>
                                        handleChangeProject(project.id)
                                    }
                                >
                                    <span className="option-list">
                                        {project.translation[0].display_name}
                                    </span>
                                    {index === 0 && (
                                        <span className="default">
                                            {routeTranslate(
                                                intl,
                                                'chat.default',
                                            )}
                                        </span>
                                    )}
                                </li>
                            ))
                    ) : (
                        <li>Sem resultados...</li>
                    )}
                </ul>
            </div>
        </ContainerModal>
    );
};

export default ModalPersonalities;
