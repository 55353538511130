import React from 'react';
import { Container } from 'app/presentation/components/';
import { Header, ContainerPage } from './styles';
import { useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import AudioToText from './audioToText';

type IAudioAi = {
    hasId?: boolean;
};

const AudioAi: React.FC<IAudioAi> = ({ hasId = false }) => {
    const { darkmode } = useDarkMode();
    const isDesktop = useMediaQuery('(min-width: 64em)');
    const [view] = React.useState<'default' | 'transform' | 'convert'>(
        'transform',
    );
    const [view2, setView2] = React.useState<'table' | 'new'>(
        hasId ? 'new' : 'table',
    );

    return (
        <Container
            bg={isDesktop ? '' : '#fafafa'}
            className={isDesktop ? 'content-full' : ''}
        >
            <Header className={darkmode ? 'headerDark' : ''}>
                <div>
                    <h1>
                        <FormattedMessage id={`audioai.${view}`} />
                        <span className="beta">
                            <FormattedMessage id="audioai.label.new" />
                        </span>
                    </h1>
                </div>
            </Header>
            <hr
                className="lineHrHeader"
                style={{ borderColor: darkmode ? '#3a3a3a' : '' }}
            />

            <ContainerPage
                className={darkmode ? 'arteAiContainerDark' : ''}
                style={{
                    background:
                        view2 === 'new'
                            ? `${darkmode ? '#000' : '#fafafa'}`
                            : `${darkmode ? '#000' : '#fff'}`,
                }}
            >
                {view === 'default' && <></>}
                {view === 'transform' && (
                    <AudioToText
                        hasId={hasId}
                        view={view2}
                        setView={setView2}
                    />
                )}
                {view === 'convert' && <></>}
            </ContainerPage>
        </Container>
    );
};

export default AudioAi;
