export const resizeInit = async (input: any): Promise<string | undefined> => {
    if (!input.init) return;

    let data = '';
    const isUpscaling = false;

    if ('base64' in input.init) {
        data = input.init.base64;
    } else if (input.init.src.startsWith('blob:')) {
        data = input.init.src;
    } else {
        const response = await fetch(input.init.src);
        data = URL.createObjectURL(await response.blob());
    }

    const image = new window.Image();
    image.src = data;

    await new Promise(resolve => {
        image.onload = resolve;
    });

    const canvas = document.createElement('canvas');
    canvas.width = !isUpscaling
        ? Math.ceil(input.width / 64) * 64
        : image.width;
    canvas.height = !isUpscaling
        ? Math.ceil(input.height / 64) * 64
        : image.height;

    const context = canvas.getContext('2d');
    if (!context) return;

    // draw grey background
    context.fillStyle = '#333';
    context.fillRect(0, 0, canvas.width, canvas.height);

    if (isUpscaling) {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        console.log({
            width: canvas.width,
            height: canvas.height,
        });
        return canvas.toDataURL('image/png');
    }

    const scaleFactor = Math.max(
        input.width / image.width,
        input.height / image.height,
    );
    const scaledWidth = image.width * scaleFactor;
    const scaledHeight = image.height * scaleFactor;
    const x = (input.width - scaledWidth) / 2;
    const y = (input.height - scaledHeight) / 2;

    context.drawImage(image, x, y, scaledWidth, scaledHeight);
    return canvas.toDataURL('image/png');
};
