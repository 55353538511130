import { colors } from '../commons/colors';
import { input, modal } from '../commons/components';

export const messageContainer = {
    dark: {
        chatLogo: { border: { color: colors.gs700 } },
        message: {
            background: { color: colors.gs200 },
            icon: { color: colors.secondary },
            border: { color: colors.transparent, active: colors.white },
            placeholder: { color: colors.gs600 },
            text: { color: colors.gs900 },
            asset: { border: { color: colors.gs300 } },
        },
    },
    light: {
        chatLogo: { border: { color: colors.gs300 } },
        message: {
            background: { color: colors.gs200 },
            icon: { color: colors.secondary },
            border: { color: colors.transparent, active: colors.gs900 },
            placeholder: { color: colors.gs600 },
            text: { color: colors.gs900 },
            asset: { border: { color: colors.gs300 } },
        },
    },
};

export const modalAdditionalMessages = {
    ...modal,
};

export const modalDeleteAgent = {
    dark: {
        text: { color: colors.gs600 },
        name: { color: colors.white },
        ...modal.dark,
    },
    light: {
        text: { color: colors.gs600 },
        name: { color: colors.gs900 },
        ...modal.light,
    },
};

export const modalNewAgent = {
    ...modal,
};

export const modalNewContent = {
    ...modal,
};

export const modalNewQuestion = {
    ...modal,
};

export const questionContainer = {
    dark: {
        question: {
            background: { color: colors.gs900 },
            border: { color: colors.white },
            text: { color: colors.white },
        },
    },
    light: {
        question: {
            background: { color: colors.white },
            border: { color: colors.gs300 },
            text: { color: colors.gs900 },
        },
    },
};

export const actionContainer = {
    dark: {
        action: {
            border: { color: colors.gs300 },
        },
    },
    light: {
        action: {
            border: { color: colors.gs300 },
        },
    },
};

export const userLimitInput = {
    dark: input.dark,
    light: input.light,
};
