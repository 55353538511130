import React from 'react';
import { Lightning, Play } from '../../icons';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { CheckBox } from '../../index';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneIcon from '@mui/icons-material/Done';
import { TbArrowsDownUp } from 'react-icons/tb';
import { TableStyle } from '../TableDocuments/styles';
import { Document } from '../../interfaces/document-props.interface';

const OrderIcon = () => {
    return <TbArrowsDownUp style={{ color: '#718096' }} size={16} />;
};

const CheckBoxCustom = (props: any) => {
    return (
        <CheckBox
            {...props}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={
                <DoneIcon
                    style={{
                        backgroundColor: '#d0ff61',
                        borderRadius: 4,
                    }}
                />
            }
            sx={{
                color: '#E2E8F0',
                '&.Mui-checked': {
                    color: '#1A202C',
                },
            }}
        />
    );
};

interface Props {
    data: Document[];
    limit?: number;
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const TableModels: React.FC<Props> = ({ data, limit }) => {
    return (
        <>
            <TableStyle>
                <thead>
                    <tr>
                        <th>
                            <CheckBoxCustom />
                        </th>
                        <th>
                            Nome <OrderIcon />
                        </th>
                        <th>
                            Criado por <OrderIcon />
                        </th>
                        <th>
                            Modificado <OrderIcon />
                        </th>
                        <th className="action">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((document, index) => {
                        if (limit && limit < index + 1) return;

                        const date = new Date(document.created_at);
                        const month = date.toLocaleString('default', {
                            month: 'short',
                        });
                        const year = date.toLocaleString('default', {
                            year: 'numeric',
                        });
                        const day = date.toLocaleString('default', {
                            day: 'numeric',
                        });

                        const dateString = `${capitalizeFirstLetter(
                            month.replace(/\.$/, ''),
                        )} ${day}, ${year}`;

                        return (
                            <tr key={index}>
                                <td>
                                    <CheckBoxCustom />
                                </td>
                                <td>
                                    <div className="name-table">
                                        <div className="icon">
                                            <Lightning />
                                        </div>
                                        <span>{document.title}</span>
                                    </div>
                                </td>
                                <td>Eu</td>
                                <td>{dateString}</td>
                                <td>
                                    <div className="action">
                                        <div className="play">
                                            <button>
                                                <Play />
                                                Rodar
                                            </button>
                                        </div>
                                        <div className="dots">
                                            <HiOutlineDotsHorizontal
                                                size={22}
                                                style={{ color: '#718096' }}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </TableStyle>
        </>
    );
};

export default TableModels;
