import React from 'react';
import * as S from './styles';
import {
    Button,
    FormFieldContainer,
    TextArea,
} from 'app/presentation/components/agent';
import { IconCheck } from '@tabler/icons-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';

export const SecurityPanel = () => {
    const { textGetter } = useText();
    const panelText = textGetter('agent.panel.security');
    const commonsText = textGetter('agent.panel.commons');

    const [authorizedDomainsString, setAuthorizedDomainsString] =
        React.useState<string>('');

    const { handleSubmit } = useForm<{}>();

    const { agent, updateSecurityConfig } = useAgent();
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    const loadForm = () => {
        if (!agent || !agent.config) return;
        setAuthorizedDomainsString(agent.config.authorized_domains.join(', '));
    };

    React.useEffect(() => {
        loadForm();
    }, [agent]);

    const onAuthorizedDomainsTextAreaChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setAuthorizedDomainsString(event.target.value);
    };

    const onSubmit: SubmitHandler<{}> = async () => {
        const domains = authorizedDomainsString
            .split(',')
            .map(domain => domain.trimStart())
            .filter(domain => domain != '');
        setFormSubmitLoading(true);
        updateSecurityConfig(domains).finally(() =>
            setFormSubmitLoading(false),
        );
    };

    return (
        <S.SecurityPanel onSubmit={handleSubmit(onSubmit)}>
            <FormFieldContainer
                info={{
                    title: panelText('authorized-domains.title'),
                    text: panelText('authorized-domains.subtitle'),
                }}
            >
                <TextArea
                    value={authorizedDomainsString}
                    placeholder={panelText('authorized-domains.placeholder')}
                    onChange={onAuthorizedDomainsTextAreaChange}
                ></TextArea>
            </FormFieldContainer>
            <p className="panel-desc">
                {panelText('desc.first-paragraph')}
                <br />
                {panelText('desc.second-paragraph')}
            </p>
            <div className="buttons-container">
                <Button variant="secondary" type="button" onClick={loadForm}>
                    {commonsText('button.cancel')}
                </Button>
                <Button
                    variant="dark"
                    type="submit"
                    loading={formSubmitLoading}
                    className="save-button"
                >
                    <IconCheck></IconCheck>
                    <span>
                        {commonsText('button.save')}
                        <span className="desktop">
                            {' '}
                            {commonsText('button.save-suffix')}
                        </span>
                    </span>
                </Button>
            </div>
        </S.SecurityPanel>
    );
};
