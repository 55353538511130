import React from 'react';
import MenuContext from '../contexts/MenuContext';
import { ProviderProps } from './interfaces/provider-default-props.interface';

const MenuProvider: React.FC<ProviderProps> = ({ children }) => {
    const [menu, setMenu] = React.useState(false);
    return (
        <MenuContext.Provider
            value={{
                setMenu,
                menu,
            }}
        >
            {children}
        </MenuContext.Provider>
    );
};

export default MenuProvider;
