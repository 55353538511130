import styled, { css } from 'styled-components';

export const UserLimitInput = styled.div`
    ${({
        theme: {
            agent: {
                components: {
                    userLimitInput: { border, background, text, placeholder },
                },
            },
        },
    }) => css`
        border: 1px solid ${border.color};
        background-color: ${background.color};
        transition: all linear 0.05s;
        border-radius: 8px;
        height: 44px;
        width: 220px;
        padding: 0px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        left: 0.2px;
        cursor: pointer;
        :hover,
        &.active {
            border-color: ${border.active};
        }
        input {
            padding: 10.5px 0px;
            border: none;
            width: 50px;
            background-color: inherit;
            color: ${text.color};
            ::placeholder {
                color: ${placeholder.color};
            }
        }
        p {
            padding: 16px 0px;
            color: ${placeholder.color};
        }
    `}
`;
