import React, { useState } from 'react';
import DarkModeContext from '../contexts/DarkModeContext';
import { ProviderProps } from './interfaces/provider-default-props.interface';

const DarkModeProvider: React.FC<ProviderProps> = ({ children }) => {
    const [active, setActive] = useState(false);

    React.useEffect(() => {
        const darkModeLocalStorage = localStorage.getItem('darkMode');
        if (darkModeLocalStorage) {
            activate();
        }
    }, []);

    const activate = () => {
        setActive(true);
        localStorage.setItem('darkMode', 'activate');
    };

    const deactivate = () => {
        setActive(false);
        localStorage.removeItem('darkMode');
    };

    return (
        <DarkModeContext.Provider
            value={{ activate, deactivate, darkmode: active }}
        >
            {children}
        </DarkModeContext.Provider>
    );
};

export default DarkModeProvider;
