import styled from 'styled-components';

export const ContainerModal = styled.div`
    background-color: #11151deb;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    .content-modal-history {
        background: #ffffff;
        border-radius: 16px;
        width: 98%;
        max-width: 850px;
        height: 100%;
        max-height: 536px;
        padding: 17px 23px;
        overflow: auto;

        header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 39px;
            height: 40px;

            svg {
                cursor: pointer;
            }
        }

        .dots {
            width: 35px;
            height: 35px;
            border: 1px solid #edf2f7;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        form {
            height: calc(100% - 79px) !important;
            padding: 0 71px;

            .input {
                height: calc(100% - 40px);

                > div {
                    height: 100%;
                }
            }

            textarea {
                border: 0 !important;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                padding: 0;
                height: 100%;

                ::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                }

                ::-webkit-scrollbar-thumb {
                    background: #edf2f7;
                    border-radius: 50px;
                }
            }
        }

        .name-template {
            display: flex;
            align-items: center;
            gap: 10px;

            .icon {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                overflow: hidden;

                img {
                    min-width: 40px;
                    min-height: 40px;
                }
            }

            h1 {
                color: #718096;
                font-size: 1em;
                font-weight: 600;
            }
        }

        .options-history {
            ul {
                list-style: none;
                display: flex;
                align-items: center;
                gap: 20px;
                cursor: pointer;

                li {
                    color: #718096;
                    font-weight: 600;

                    &.image {
                        background: #194bfb;
                        width: 32px;
                        border-radius: 1000px;
                        height: 32px;
                        font-weight: bold;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .modalHistoryDark {
        background: #1d1d1f;
        header {
            .name-template {
                h1 {
                    color: #a09fa5;
                }
            }
            .options-history {
                li {
                    color: #a09fa5;
                }
            }
            .dots {
                color: #cccccc;
            }
        }

        textarea {
            color: #fff;
            background: #1d1d1f !important;
        }
    }
`;

export const MuiMenuHeader = styled.div`
    .menu-mui-header-workspace {
        margin-top: 9px;
        padding-bottom: 17px;
        border-bottom: 2px solid #edf2f7;
        margin-bottom: 8px;

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        a {
            font-weight: 500;
            font-size: 0.75em;
            color: #194bfb;
        }
    }

    .menu-mui-header {
        margin-top: 9px;
        padding: 0 16px 16px 16px;
        border-bottom: 1px solid #edf2f7;

        #search > div {
            height: 42px;
            margin: 0;
        }

        input {
            height: 42px;
            border: 1px solid #718096;
            border-radius: 8px;
        }

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        p {
            font-weight: 500;
            font-size: 0.75em;
            color: #718096;
        }
    }

    ul.workspace {
        li {
            border-bottom: 0;
            padding: 0;
            cursor: pointer;

            span {
                font-weight: 600;
            }
        }
    }

    ul {
        list-style: none;
        li {
            cursor: pointer;
            height: 42px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            border-bottom: 1px solid #edf2f7;

            &:hover {
                background-color: #f7fafc;
            }
        }

        li:last-of-type {
            border-bottom: 0;
        }

        span {
            color: #718096;
            font-size: 0.75em;
            font-weight: 600;
            margin-left: 12px;
        }
    }
`;
