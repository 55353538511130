import styled from 'styled-components';

export const Ellipsis = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: auto;
    background-color: #a09fa5;

    &.published {
        background-color: #22c55e;
    }

    &.draft {
        background-color: #fde047;
    }

    &.deactivated {
        background-color: #a09fa5;
    }
`;
