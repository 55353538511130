import styled, { css } from 'styled-components';

export const MessagesContainer = styled.div`
    ${({
        theme: {
            agent: {
                components: {
                    messageContainer: { chatLogo },
                },
            },
        },
    }) => css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: start;

        .messages-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;

            .messages-row {
                display: flex;
                flex-direction: column;
                gap: 6px;

                @media (min-width: 64em) {
                    gap: 6px;
                    flex-direction: row;
                    align-items: end;
                    margin-left: 36px;

                    :last-child {
                        margin-left: 0px;
                    }
                }

                .jarbas-logo-container {
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    border-radius: 15px;
                    border: 1px solid;
                    border-color: ${chatLogo.border.color};
                    bottom: 0;
                    left: 0;
                    img {
                        width: 22px;
                        height: 22px;
                    }
                }

                .message-container {
                    display: flex;
                    align-items: end;
                    .message-icons {
                        width: 18px;
                        height: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 6px;
                        background-color: transparent;
                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }

        .add-button {
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 21px;
            font-size: 14px;
            padding: 0px;
            margin-left: 0px;
            cursor: pointer;

            @media (min-width: 64em) {
                margin-left: 36px;
            }
        }
    `}
`;

export const Message = styled.div`
    ${({
        theme: {
            agent: {
                components: {
                    messageContainer: {
                        message: {
                            background,
                            icon,
                            border,
                            placeholder,
                            text,
                            asset,
                        },
                    },
                },
            },
        },
    }) => css`
        min-height: 40px;
        background-color: ${background.color};

        border-radius: 6px;
        width: 100%;
        padding: 10px 8px;
        border: 1px solid;
        border-color: ${border.color};
        box-sizing: border-box;
        transition: all linear 0.1s;

        @media (min-width: 64em) {
            width: 436.79px;
        }

        :hover,
        &.active-message {
            border-color: ${border.active};
        }

        textarea {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0.2px;
            color: ${text.color};
            width: 100%;
            margin-bottom: 4px;
            height: auto;
            border: none;
            background-color: inherit;
            overflow-y: hidden;
            padding: 0px;
            ::placeholder {
                color: ${placeholder.color};
            }
        }

        .assets-container {
            display: flex;
            gap: 8px;
            .asset-container {
                width: fit-content;
                display: flex;
                flex-direction: column;
                align-items: end;
                margin-bottom: 20px;

                button {
                    margin-top: -4px;
                    margin-right: -4px;
                    border-radius: 7px;
                    width: 14px;
                    height: 14px;
                    z-index: 1;
                    svg {
                        width: 10px;
                        height: 10px;
                    }
                }
                img {
                    margin-top: -10px;
                    width: 80px;
                    height: 80px;
                    border-radius: 6px;
                    border: 1px solid ${asset.border.color};
                }
            }

            input {
                display: none;
            }
        }

        .message-footer {
            position: relative;
            .message-buttons-container {
                width: 72px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .icon-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 16px;
                    height: 16px;
                    border: none;
                    background-color: inherit;
                    overflow-y: hidden;
                    cursor: pointer;
                    svg {
                        color: ${icon.color};
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .emoji-picker-container {
                position: absolute;
                top: -5px;
                left: 20px;
                z-index: 2;
                overflow: none;
            }
        }
    `}
`;
