import React from 'react';

export const Lightning: React.FC = () => (
    <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.58251 11.1733H5.41501V17.7733C5.41501 19.3133 6.24918 19.625 7.26668 18.47L14.2058 10.5867C15.0583 9.62415 14.7008 8.82665 13.4083 8.82665H10.5758V2.22665C10.5758 0.686654 9.74168 0.374987 8.72418 1.52999L1.78501 9.41332C0.941679 10.385 1.29918 11.1733 2.58251 11.1733Z"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
