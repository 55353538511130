import React from 'react';
import {
    ContainerSignUp,
    LoginAlternatives,
    OrRegister,
    Form,
    DesktopLayout,
    Showcase,
} from './styles';
import { Container, Input, Button } from 'app/presentation/components';
import { IconGoogle, IconLogo } from 'app/presentation/components/icons';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import showCase from 'assets/images/svg/showcase-signup.svg';
import { auth, signInWithGoogle } from 'config/firebaseSetup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { updateProfile } from 'firebase/auth';
import { routeTranslate } from 'utils/intl';
import { useSnackbar } from 'notistack';
import useLoading from 'app/presentation/hooks/useLoading';
import parse from 'html-react-parser';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import mixpanel from 'mixpanel-browser';

const SignUp = () => {
    const { register, handleSubmit } = useForm<FieldValues>();
    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { darkmode } = useDarkMode();
    mixpanel.init('768d6c9c5cec179ad70544a18a887536');

    const intl = useIntl();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { showLoading, closeLoading } = useLoading();

    const signUpFail = () => {
        enqueueSnackbar('Verifique as informações!', {
            variant: 'error',
        });
    };

    const emailAlreadyUse = () => {
        enqueueSnackbar('O e-mail informado já está em uso.', {
            variant: 'error',
        });
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const signUp: SubmitHandler<FieldValues> = async data => {
        try {
            setLoading(true);
            showLoading();

            const { user } = await auth.createUserWithEmailAndPassword(data.email, data.password);

            if (user) {
                mixpanel.track('Sign Up', {
                    source: 'App Jarbas',
                    'Opted for social login': true,
                });
                updateProfile(user, {
                    displayName: `${data.firstName} ${data.lastName}`,
                }).then(() => {
                    navigate(`/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(intl, 'route.emailVerify')}`);
                }).catch(error => {
                    console.log(error);
                });
            }
        } catch (error: any) {
            if (error.code === 'auth/email-already-in-use') {
                emailAlreadyUse();
                setLoading(false);
            } else {
                setLoading(false);

                signUpFail();
            }
        } finally {
            setLoading(false);

            closeLoading();
        }
    };

    const currentYear = new Date(Date.now()).getFullYear();

    return (
        <DesktopLayout>
            <Showcase className={darkmode ? 'showcaseDark' : ''}>
                <div id="background">
                    <header>
                        <IconLogo />
                    </header>
                    <div>
                        <img
                            src={showCase}
                            title={routeTranslate(intl, 'showcaseSignUp.title')}
                            alt={routeTranslate(intl, 'showcaseSignUp.title')}
                        />
                        <FormattedMessage
                            tagName="h1"
                            id="showcaseSignUp.title"
                        />
                        <FormattedMessage
                            tagName="p"
                            id="showcaseSignUp.text"
                        />
                    </div>
                </div>
            </Showcase>
            <ContainerSignUp className={darkmode ? 'containerSingupDark' : ''}>
                <Container>
                    <div id="form-login">
                        <div className="logo">
                            <IconLogo />
                        </div>
                        <div>
                            <div className="center">
                                <FormattedMessage
                                    tagName={'h1'}
                                    id="signup.title"
                                />
                                <p className="tagline">
                                    <FormattedMessage id="signup.subtitle" />
                                </p>
                                <LoginAlternatives
                                    text={routeTranslate(
                                        intl,
                                        'signin.signinWith',
                                    )}
                                    className={
                                        darkmode ? 'alternativesDark' : ''
                                    }
                                >
                                    <button
                                        onClick={signInWithGoogle}
                                        className="background"
                                    >
                                        <IconGoogle size={22} />
                                        <span>Google</span>
                                    </button>
                                </LoginAlternatives>
                            </div>
                            <Form onSubmit={handleSubmit(signUp)}>
                                <OrRegister>
                                    <hr />
                                    <span
                                        className="background"
                                        style={{
                                            background: darkmode ? '#000' : '',
                                            color: darkmode ? '#A09FA5' : '',
                                        }}
                                    >
                                        <FormattedMessage id="signin.orEmail" />
                                    </span>
                                </OrRegister>
                                <div id="name">
                                    <Input
                                        id="firstName"
                                        type="text"
                                        placeholder={routeTranslate(
                                            intl,
                                            'signup.placeholderFirstName',
                                        )}
                                        required
                                        register={register}
                                    />
                                    <Input
                                        id="lastName"
                                        type="text"
                                        placeholder={routeTranslate(
                                            intl,
                                            'signup.placeholderLastName',
                                        )}
                                        required
                                        register={register}
                                    />
                                </div>
                                <Input
                                    id="email"
                                    type="email"
                                    placeholder={routeTranslate(
                                        intl,
                                        'signin.placeholderEmail',
                                    )}
                                    required
                                    register={register}
                                />
                                <div id="password-field">
                                    <Input
                                        id="password"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        placeholder={routeTranslate(
                                            intl,
                                            'signin.placeholderPassword',
                                        )}
                                        minLength={6}
                                        required
                                        register={register}
                                    />

                                    {showPassword ? (
                                        <AiOutlineEye
                                            style={{
                                                color: darkmode
                                                    ? '#A09FA5'
                                                    : '#718096',
                                            }}
                                            size={22}
                                            onClick={handleShowPassword}
                                        />
                                    ) : (
                                        <AiOutlineEyeInvisible
                                            style={{
                                                color: darkmode
                                                    ? '#A09FA5'
                                                    : '#718096',
                                            }}
                                            size={22}
                                            onClick={handleShowPassword}
                                        />
                                    )}
                                </div>
                                <div id="options">
                                    <div id="remember">
                                        <span id="remember-text">
                                            {parse(
                                                routeTranslate(
                                                    intl,
                                                    'signup.terms',
                                                ),
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <Button loading={loading} type="submit">
                                    <FormattedMessage id="signup.submitButton" />
                                </Button>
                            </Form>
                            <div id="call-user" className="desktop">
                                <p>
                                    <FormattedMessage id="signup.haveAccount" />
                                    <Link
                                        title={routeTranslate(
                                            intl,
                                            'signup.loginCall',
                                        )}
                                        to={`/${routeTranslate(
                                            intl,
                                            'route.urlLang',
                                        )}${routeTranslate(
                                            intl,
                                            'route.signin',
                                        )}`}
                                    >
                                        <FormattedMessage id="signup.loginCall" />
                                    </Link>
                                </p>
                            </div>
                        </div>
                        <div id="call-user" className="mobile">
                            <p>
                                <FormattedMessage id="signup.haveAccount" />
                                <Link
                                    title={routeTranslate(
                                        intl,
                                        'signup.loginCall',
                                    )}
                                    to={`/${routeTranslate(
                                        intl,
                                        'route.urlLang',
                                    )}${routeTranslate(intl, 'route.signin')}`}
                                >
                                    <FormattedMessage id="signup.loginCall" />
                                </Link>
                            </p>
                        </div>
                        <div id="footer">
                            <a
                                href="https://www.jarbas.ai/privacy"
                                title={routeTranslate(intl, 'footer.privacy')}
                            >
                                <FormattedMessage id="footer.privacy" />
                            </a>
                            <a href="#jarbas">© {currentYear} Jarbas.AI</a>
                        </div>
                    </div>
                </Container>
            </ContainerSignUp>
        </DesktopLayout>
    );
};

export default SignUp;
