import { styled } from '@mui/material/styles';
import { Slider } from '@mui/material';

export const PrettoSlider = styled(Slider)({
    maxWidth: '825px',
    color: '#000000',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        backgroundColor: '#EDF2F7',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#000000',
        border: '2px solid #FFFFFF',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 100,
        height: 32,
        borderRadius: '4px',
        backgroundColor: '#35383C',
        transformOrigin: 'bottom center',
        transform: 'translate(0%, -100%) rotate(0) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(0%, -100%) rotate(0) scale(1)',
        },
        '& > *': {
            transform: 'rotate(0)',
        },
    },
});
