import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { routeTranslate } from 'utils/intl';
import { useIntl } from 'react-intl';

type IEye = {
    color?: string;
    darkmode?: boolean;
};

export const EyeBlock: React.FC<IEye> = ({
    darkmode = false,
    color = '#1A202C',
}) => {
    const intl = useIntl();

    return (
        <Tooltip
            title={routeTranslate(intl, 'tooltip.eye')}
            placement="top"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.93868 6.44043C6.65741 6.72179 6.49944 7.10336 6.49951 7.50119C6.49958 7.89903 6.65769 8.28054 6.93905 8.5618C7.22041 8.84307 7.60198 9.00104 7.99981 9.00097C8.39765 9.0009 8.77916 8.84279 9.06042 8.56143"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.5107 11.0048C10.4587 11.663 9.24099 12.0082 8 12C5.3 12 3.05 10.5 1.25 7.5C2.204 5.91 3.284 4.7415 4.49 3.9945M6.635 3.135C7.08428 3.04405 7.54161 2.99882 8 3C10.7 3 12.95 4.5 14.75 7.5C14.2505 8.3325 13.7157 9.05025 13.1465 9.6525M1.25 0.75L14.75 14.25"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Tooltip>
    );
};
