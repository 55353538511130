import React from 'react';

export const Options: React.FC = () => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.1667 7.58075V3.87742C20.1667 2.41992 19.58 1.83325 18.1225 1.83325H14.4192C12.9617 1.83325 12.375 2.41992 12.375 3.87742V7.58075C12.375 9.03825 12.9617 9.62492 14.4192 9.62492H18.1225C19.58 9.62492 20.1667 9.03825 20.1667 7.58075Z"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.62501 7.80992V3.64825C9.62501 2.35575 9.03834 1.83325 7.58084 1.83325H3.87751C2.42001 1.83325 1.83334 2.35575 1.83334 3.64825V7.80075C1.83334 9.10242 2.42001 9.61575 3.87751 9.61575H7.58084C9.03834 9.62492 9.62501 9.10242 9.62501 7.80992Z"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.62501 18.1225V14.4192C9.62501 12.9617 9.03834 12.375 7.58084 12.375H3.87751C2.42001 12.375 1.83334 12.9617 1.83334 14.4192V18.1225C1.83334 19.58 2.42001 20.1667 3.87751 20.1667H7.58084C9.03834 20.1667 9.62501 19.58 9.62501 18.1225Z"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.2917 16.0417H18.7917"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
        <path
            d="M16.0417 18.7917V13.2917"
            stroke="#1A202C"
            strokeWidth="1.5"
            strokeLinecap="round"
        />
    </svg>
);
