import styled from 'styled-components';

interface Props {
    absolute?: boolean;
}

export const LoadingStyled = styled.div<Props>`
    position: ${props => (props.absolute ? 'absolute' : 'static')};
    top: 0;
    left: 0;
    z-index: 9;
    backdrop-filter: blur(4px);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
`;
