import React from 'react';
import { Container } from './styles';
import { LayoutProps } from '../interfaces/layout-default-props.interface';

import { MenuDesktop } from 'app/presentation/components';
import DashboardProviders from '../../../../dashboardProviders';

const DashboardLayout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <DashboardProviders>
            <Container>
                <MenuDesktop />
                <div id="content">{children}</div>
            </Container>
        </DashboardProviders>
    );
};

export default DashboardLayout;
