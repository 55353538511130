import styled from 'styled-components';
import Logo from 'assets/images/svg/logo.svg';
import LogoDark from 'assets/images/svg/logo-dark.svg';

export const HeaderContainer = styled.header`
    width: 100%;
    float: left;
    height: 72px;
    display: flex;
    align-items: center;

    .content {
        width: 100%;
        padding: 0 20px;
        max-width: initial;

        @media (min-width: 64em) {
            padding: 0 40px;
        }
    }

    .ds-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #options {
        display: flex;
        align-items: center;
    }

    .option {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        width: 48px;
        height: 48px;

        background: #fafafa;
        border-radius: 1000px;
        margin-right: 8px;
        position: relative;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        #new-notification {
            position: absolute;
            width: 10px;
            height: 10px;
            top: 13px;
            right: 10px;
            border-radius: 10px;
            background-color: #ff4747;
        }

        &.perfil {
            span,
            > svg {
                display: none;
            }
        }

        .image {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #eee;
        }
    }

    .option:last-child {
        margin-right: 0;
    }

    @media (min-width: 64em) {
        padding-top: 40px;

        .option.perfil {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 163px;
            height: 48px;
            border-radius: 1000px;
            padding: 8px;
            cursor: pointer;

            span,
            > svg {
                display: block;
            }

            span {
                color: #1a202c;
                font-weight: 600;
            }
        }
    }

    &.dark {
        .option {
            background: #1d1d1f;

            svg path,
            svg circle {
                stroke: #fff;
            }

            .image {
                background-color: #000;
            }

            &.perfil {
                span {
                    color: #fff;
                }
            }
        }
    }
`;

export const IconLogo = styled.div`
    width: 40px;
    height: 35px;

    background-image: url(${Logo});
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @media (min-width: 64em) {
        opacity: 0;
    }
`;

export const IconLogoDark = styled.div`
    width: 40px;
    height: 35px;

    background-image: url(${LogoDark});
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @media (min-width: 64em) {
        opacity: 0;
    }
`;

export const MuiMenuHeader = styled.div`
    .menu-mui-header {
        margin-top: 9px;
        padding-bottom: 17px;
        border-bottom: 1px solid #edf2f7;
        margin-bottom: 8px;

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        p {
            font-weight: 500;
            font-size: 0.75em;
            color: #718096;
        }
    }

    .usingDarkHeader {
        border-color: #3a3a3a !important;
    }

    ul {
        list-style: none;

        li {
            height: 44px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        #dark-mode {
            margin-top: 8px;
            height: 44px;
            border-top: 1px solid #edf2f7;
            justify-content: space-between;

            #toggle {
                width: 44px;
                height: 24px;
                background: #edf2f7;
                border-radius: 1000px;
                display: flex;
                align-items: center;

                &.active {
                    justify-content: flex-end;
                    background: #d0ff61;

                    > div {
                        border: 2px solid #d0ff61;
                    }
                }

                > div {
                    width: 24px;
                    height: 24px;
                    background: #ffffff;
                    border-radius: 1000px;
                    border: 2px solid #edf2f7;
                }
            }
        }

        .usingDarkMode {
            border-color: #3a3a3a !important;
        }

        span {
            margin-left: 12px;
            color: #1a202c;
            font-weight: 600;
            font-size: 0.875em;
        }
    }
`;
