import React from 'react';
import { useIntl } from 'react-intl';
import TextContext from '../contexts/TextContext';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import { Param } from './interfaces/text-provider.interface';

const TextProvider: React.FC<ProviderProps> = ({ children }) => {
    const intl = useIntl();

    const textGetter = (
        prefix?: string,
    ): ((id: string, values?: Record<string, string | number>) => string) => {
        return (id: string, values?: Record<string, string | number>) => {
            const keyItems: string[] = [
                ...(prefix ? [prefix] : []),
                ...(id ? [id] : []),
            ];

            return intl.formatMessage(
                {
                    id: keyItems.join('.'),
                },
                values,
            );
        };
    };

    const routeGetter = (
        prefix?: string,
        withLang = true,
    ): ((...ids: (string | Param)[]) => string) => {
        return (...ids: (string | Param)[]): string => {
            const lang = intl.formatMessage({
                id: 'route.urlLang',
            });

            const route = textGetter(
                `route${prefix ? '.'.concat(prefix) : ''}`,
            );
            
            return '/'.concat( 
                ids.map(id => 
                    typeof id == 'string' ? route(id) : `/${id.param.toString()}`
                ).reduce((prev, current) => {
                    return `${prev}${current}`;
                }, withLang ? lang : ''),
            );
        };
    };

    const numberFormatter = (
        minimumFractionDigits = 2, maximumFractionDigits = 2,
    ): ((num: number, fractionDigits?:number) => string) => {
        
        return (num: number, fractionDigits?:number): string => {
            const formatter = new Intl.NumberFormat(intl.locale,
                { 
                    minimumFractionDigits: fractionDigits != undefined ?  fractionDigits : minimumFractionDigits,
                    maximumFractionDigits: fractionDigits != undefined ?  fractionDigits : maximumFractionDigits
                }
            )
            return formatter.format(num)
        };
    };

    

    return (
        <TextContext.Provider
            value={{
                textGetter,
                routeGetter,
                numberFormatter,
            }}
        >
            {children}
        </TextContext.Provider>
    );
};

export default TextProvider;
