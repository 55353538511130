import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { Button, Input, CardSelect } from 'app/presentation/components/agent';
import {
    IconHelpCircle,
    IconMessageForward,
    IconSpeakerphone,
} from '@tabler/icons-react';
import useAgent from 'app/presentation/hooks/useAgent';
import { Agent } from 'services/agent/interfaces';
import { SubmitHandler, useForm } from 'react-hook-form';
import useText from 'app/presentation/hooks/useText';
import useUserConfig from 'app/presentation/hooks/useUserConfigs';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    item_editing?: Agent | undefined;
}

type AgentData = {
    name: string;
};

export const ModalNewAgent: React.FC<ModalProps> = ({
    active,
    handleClose,
    item_editing = undefined,
}) => {
    const { textGetter } = useText();
    const modalText = textGetter('agent.components.modal.new-agent');
    const commonsText = textGetter('agent.components.modal.commons');

    const { setModalNewBrand, activeBrand } = useUserConfig();

    const [type, setType] = React.useState<string>('');
    const { createAgent, updateAgent } = useAgent();
    const { register, handleSubmit, setValue } = useForm<AgentData>();

    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) {
            handleClose();
        }
    };

    React.useEffect(() => {
        if (!active) {
            return;
        }

        setValue('name', item_editing ? item_editing.identity.agent_name : '');
        setType(item_editing ? item_editing.type : 'support');
    }, [active]);

    const onSubmit: SubmitHandler<AgentData> = async (data: AgentData) => {
        if (!activeBrand) {
            handleClose();
            setTimeout(() => {
                setModalNewBrand(true);
            }, 400);
        }

        setFormSubmitLoading(true);
        if (item_editing == undefined) {
            createAgent(data.name, type)
                .then(() => {
                    handleClose();
                })
                .finally(() => setFormSubmitLoading(false));
        } else {
            updateAgent(item_editing.id, data.name)
                .then(() => {
                    handleClose();
                })
                .finally(() => setFormSubmitLoading(false));
        }
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal onClick={handleCloseContainer}>
                <form
                    className="content-modal"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="modal-header">
                        <p>{modalText('title')}</p>
                        <IoMdClose size={25} onClick={handleClose} />
                    </div>
                    <div className="input-container">
                        <p>{modalText('agent-name')}</p>
                        <Input
                            registerValues={register('name')}
                            required
                        ></Input>
                    </div>
                    {item_editing == undefined && (
                        <CardSelect
                            variant="primary"
                            value={type}
                            onChange={setType}
                            height="182px"
                            padding="25px 20px"
                            align="left"
                            iconBG={{
                                height: '42px',
                                width: '42px',
                                radius: '6px',
                            }}
                            iconGap="12px"
                            textGap="12px"
                            cardGap="15px"
                            cards={[
                                {
                                    value: 'support',
                                    icon: <IconHelpCircle></IconHelpCircle>,
                                    title: modalText(
                                        'agent-type.support.title',
                                    ),
                                    text: modalText('agent-type.support.text'),
                                },
                                {
                                    value: 'comment',
                                    icon: (
                                        <IconMessageForward></IconMessageForward>
                                    ),
                                    title: modalText(
                                        'agent-type.comment.title',
                                    ),
                                    text: modalText('agent-type.comment.text'),
                                    disable: true,
                                },
                                {
                                    value: 'marketing',
                                    icon: <IconSpeakerphone></IconSpeakerphone>,
                                    title: modalText(
                                        'agent-type.marketing.title',
                                    ),
                                    text: modalText(
                                        'agent-type.marketing.text',
                                    ),
                                    disable: true,
                                },
                            ]}
                        ></CardSelect>
                    )}
                    <div className="button-container">
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={handleCloseContainer}
                        >
                            {commonsText('button.cancel')}
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            loading={formSubmitLoading}
                            className="save-button"
                        >
                            {item_editing
                                ? modalText('button.update')
                                : modalText('button.create')}
                        </Button>
                    </div>
                </form>
            </ContainerModal>
        </Fade>
    );
};
