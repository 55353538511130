import styled from 'styled-components';

export const InitialQuestionsPanel = styled.div`
    .panel-desc {
        letter-spacing: 0.3px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 15px;
    }
`;
