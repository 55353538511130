import React from 'react';
import { Label, InputContainer } from './styles';
import { sizeOutputDocument } from '../../../../../interfaces/documents.interface';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import Tooltip from '@mui/material/Tooltip';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface SizeOutput {
    selectSize: (index: sizeOutputDocument) => void;
    size: sizeOutputDocument;
    label?: string;
}

export const SizeOutput: React.FC<SizeOutput> = ({
    selectSize,
    size,
    label,
}) => {
    const intl = useIntl();
    const { darkmode } = useDarkMode();
    return (
        <InputContainer className={darkmode ? 'inputContainerDark' : ''}>
            {label && <Label htmlFor="select-output">{label}</Label>}
            <div className="container-sizes">
                <Tooltip
                    title={routeTranslate(intl, 'tooltip.S')}
                    placement="top"
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                    }}
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: darkmode ? '#fff' : '#000',
                                color: darkmode ? '#000 ' : '#fff',
                            },
                        },
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    }}
                >
                    <div
                        onClick={() => {
                            selectSize('short');
                        }}
                        className={`box ${size === 'short' && 'active'}`}
                    >
                        <span>{routeTranslate(intl, 'output.short')}</span>
                    </div>
                </Tooltip>

                <Tooltip
                    title={routeTranslate(intl, 'tooltip.M')}
                    placement="top"
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                    }}
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: darkmode ? '#fff' : '#000',
                                color: darkmode ? '#000 ' : '#fff',
                            },
                        },
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    }}
                >
                    <div
                        onClick={() => {
                            selectSize('medium');
                        }}
                        className={`box ${size === 'medium' && 'active'}`}
                    >
                        <span>{routeTranslate(intl, 'output.medium')}</span>
                    </div>
                </Tooltip>

                <Tooltip
                    title={routeTranslate(intl, 'tooltip.L')}
                    placement="top"
                    sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                    }}
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: darkmode ? '#fff' : '#000',
                                color: darkmode ? '#000 ' : '#fff',
                            },
                        },
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    }}
                >
                    <div
                        onClick={() => {
                            selectSize('long');
                        }}
                        className={`box ${size === 'long' && 'active'}`}
                    >
                        <span>{routeTranslate(intl, 'output.long')}</span>
                    </div>
                </Tooltip>
            </div>
        </InputContainer>
    );
};
