import styled from 'styled-components';

export const Container = styled.div`
    @media (min-width: 64em) {
        display: flex;

        #content {
            height: 100vh;
            overflow: auto;
            flex: 1;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }

            ::-webkit-scrollbar-thumb {
                background: #edf2f7;
                border-radius: 50px;
            }
        }
    }
`;
