import React from 'react';
import { Container } from './style';

interface StatusLabelProps extends React.HTMLAttributes<HTMLDivElement> {
    text: string;
    status?: 'greyed-out' | 'progress' | 'success';
}

const StatusLabel = ({
    status = 'success',
    text,
    ...props
}: StatusLabelProps) => {
    return (
        <Container>
            <span className={status + ' ' + props.className}>{text}</span>
        </Container>
    );
};

export default StatusLabel;
