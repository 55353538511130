import React from 'react';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import Tooltip from '@mui/material/Tooltip';

type LikeProps = {
    liked?: boolean;
    darkmode?: boolean;
};

export const Like: React.FC<LikeProps> = ({ liked, darkmode = false }) => {
    const intl = useIntl();

    return (
        <Tooltip
            title={routeTranslate(intl, 'tooltip.like')}
            placement="top"
            sx={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: darkmode ? '#fff' : '#000',
                        color: darkmode ? '#000 ' : '#fff',
                    },
                },
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.5114 9.38139C16.8067 8.99115 16.9702 8.51303 16.9702 8.01556C16.9702 7.22631 16.529 6.47924 15.8188 6.06263C15.636 5.9554 15.4279 5.89896 15.2159 5.89916H11.0007L11.1062 3.73881C11.1308 3.21674 10.9462 2.72103 10.5876 2.3431C10.4116 2.15683 10.1993 2.00861 9.96378 1.90763C9.72825 1.80665 9.47452 1.75504 9.21826 1.75599C8.3042 1.75599 7.49561 2.37123 7.25303 3.25189L5.74307 8.71869H3.47021C3.15908 8.71869 2.90771 8.97006 2.90771 9.28119V15.6796C2.90771 15.9908 3.15908 16.2421 3.47021 16.2421H14.0399C14.2017 16.2421 14.3599 16.2105 14.5058 16.1472C15.3425 15.7904 15.8821 14.973 15.8821 14.066C15.8821 13.8445 15.8505 13.6265 15.7872 13.4156C16.0825 13.0253 16.246 12.5472 16.246 12.0497C16.246 11.8283 16.2144 11.6103 16.1511 11.3994C16.4464 11.0091 16.6099 10.531 16.6099 10.0335C16.6063 9.81205 16.5747 9.59232 16.5114 9.38139ZM4.17334 14.9765V9.98431H5.59717V14.9765H4.17334ZM15.3601 8.77142L14.9751 9.10541L15.2194 9.55189C15.2999 9.69897 15.3417 9.86411 15.3407 10.0318C15.3407 10.3218 15.2142 10.5978 14.9962 10.7876L14.6112 11.1216L14.8556 11.5681C14.9361 11.7152 14.9778 11.8803 14.9769 12.048C14.9769 12.338 14.8503 12.614 14.6323 12.8038L14.2474 13.1378L14.4917 13.5843C14.5722 13.7314 14.6139 13.8965 14.613 14.0642C14.613 14.4579 14.381 14.813 14.0224 14.9747H6.72217V9.92806L8.47119 3.59115C8.51629 3.42873 8.61311 3.28543 8.74696 3.18298C8.88082 3.08053 9.04443 3.02449 9.21299 3.02338C9.34658 3.02338 9.47842 3.06205 9.58389 3.14115C9.75791 3.27123 9.85107 3.4681 9.84053 3.67728L9.67178 7.16478H15.1983C15.5112 7.35639 15.7046 7.67982 15.7046 8.01556C15.7046 8.3056 15.578 8.57982 15.3601 8.77142Z"
                    fill={liked ? (darkmode ? '#fff' : '#000000') : '#A0AEC0'}
                />
            </svg>
        </Tooltip>
    );
};
