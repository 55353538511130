import React from 'react';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import SnackbarContext from '../contexts/SnackbarContext';
import { useSnackbar } from 'notistack';
import useText from '../hooks/useText';

const SnackbarProvider: React.FC<ProviderProps> = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { textGetter } = useText();
    const errorText = textGetter('snackbar.error');
    const successText = textGetter('snackbar.success');

    const success = (message: string) => {
        enqueueSnackbar(successText(message), {
            variant: 'success',
        });
    };
    const error = (message: string) => {
        enqueueSnackbar(errorText(message), {
            variant: 'error',
        });
    };

    return (
        <SnackbarContext.Provider
            value={{
                success,
                error,
            }}
        >
            {children}
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider;
