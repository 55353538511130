import React from 'react';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { Dots } from './styles';
import { Memory } from 'services/brand/memories/types';
import { TableContext } from '../Table';

type ActionsButtonProps = React.HTMLAttributes<HTMLDivElement> & {
    stateFunc: React.Dispatch<React.SetStateAction<boolean>>;
    memory: Memory | undefined;
}

// TODO handle better handleAnchor
export const ActionsButton: React.FC<ActionsButtonProps> = (props) => {
    const { stateFunc, memory, ...rest } = props;
    const { setSelectedMemory, anchor, handleAnchor } = React.useContext(TableContext);

    const [btnToggled, setBtnToggled] = React.useState<boolean>(false);
    const ref = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => setBtnToggled(anchor === ref.current), [anchor]);

    return (
        <>
            {memory && (
                <div className="action" onClick={() => setSelectedMemory(memory)}>
                    <Dots
                        selected={btnToggled}
                        onClick={(e) => {
                            handleAnchor("bind", e, stateFunc);
                            setSelectedMemory(memory);
                        }}
                        ref={ref}
                        {...rest}
                    >
                        <HiOutlineDotsHorizontal size={22} style={{ color: '#718096' }} />
                    </Dots>
                </div>
            )}
        </>
    )
}
