export const churnkeyTranslation = {
    messages: {
        pt: {
            next: 'Próximo',
            back: 'Voltar',
            nevermind: 'Voltar',
            goToAccount: 'ir para a conta',
            getHelp: 'Algo errado? Entre em contato conosco...',
            declineOffer: 'Recusar oferta',
            confirmAndCancel: 'Confirmar e cancelar',
            pauseSubscription: 'Pausar a assinatura',
            cancelSubscription: 'Cancelar',
            discountSubscription: 'Aceitar esta oferta',
            claimOffer: 'Reivindique sua oferta por tempo limitado',
            discountOff: 'desconto',
            discountFor: 'para',
            discountForever: 'para toda a vida',
            discountOneTime: 'sua próxima renovação',
            day: 'Dia | Dias',
            month: 'mês | meses ',
            year: 'ano | anos',
            error: 'Desculpe, algo deu errado',
            genericErrorDescription: 'Entre em contato conosco.',
            cancelNow: 'Cancelar assinatura →',
            applyingDiscount: 'Aplicando desconto...',
            applyingCancel: 'Cancelando a assinatura...',
            applyingResume: 'Retomando a assinatura...',
            applyingPause: 'Pausando a assinatura...',
            discountApplied: 'Desconto aplicado.',
            discountAppliedMessage:
                'Estamos muito felizes por você ainda estar aqui',
            pauseApplied: 'Assinatura pausada.',
            pauseAppliedMessage:
                'Você não será cobrado até que sua assinatura seja retomada',
            pauseAppliedResumeMessage: 'Sua assinatura será retomada em',
            pauseScheduledMessage: 'Sua assinatura será pausada a partir de',
            until: 'até',
            cancelApplied: 'Assinatura cancelada.',
            cancelAppliedMessage: 'Você não será cobrado novamente',
            cancelAppliedDateMessage: 'Sua assinatura será encerrada em',
            howLongToPausePrompt:
                'Escolha por quanto tempo você deseja fazer uma pausa...',
            whatCouldWeHaveDone: 'O que poderíamos ter feito melhor? ',
            weReadEveryAnswer: 'Nós lemos todas as respostas...',
            applyingCustomerAction: 'Isso levará apenas um segundo',
            loading: 'Carregando...',
            pauseWallCardPunch: 'Deseja acessar?',
            pauseWallCta: 'Retomar assinatura agora',
            pauseWallCardHeading: 'Retomar sua assinatura',
            scheduledToReactivate: 'Está programado para ser reativado em',
            resumeApplied: 'A assinatura foi retomada',
            resumeAppliedMessage:
                'Você será cobrado no próximo período de renovação da assinatura',
            resumeNextChargeMessage:
                'Após a reativação, você será cobrado de acordo com sua taxa original de ',
            resumeNextChargeMessageWithoutAmount:
                'Após a reativação, você será cobrado de acordo com sua tarifa normal.',
            resumeAccountDataInfo:
                'Os dados de sua conta estão sendo mantidos em segurança para quando sua assinatura for retomada',
            subscriptionPauseNotice:
                'Parece que sua assinatura ainda está pausada',
            failedPaymentNotice:
                'O acesso à sua conta está limitado no momento',
            chargedMultipleTimeNotice:
                'Tentamos várias vezes cobrar seu cartão registrado, mas simplesmente não deu certo. No entanto, você não é alguém que queremos perder 👇',
            failedPaymentCardPunch:
                'Atualize seu cartão para restaurar o acesso',
            resumeHey: 'Hey',
            invoicePaidTitle: 'Fatura paga com sucesso',
            logout: 'Logout →',
            note: 'Nota:',
            discount: 'desconto',
            discountNoticeHeadline:
                'Observação: você também perderá um desconto ativo.',
            discountNoticePrepend:
                'Se você cancelar agora, perderá seu desconto atual',
            discountOverride:
                'Se você aceitar essa oferta, perderá o desconto atual',
            discountValidUntil: 'É válido até',
            updateBilling: 'Atualizar cartão',
            extendTrialCTA: 'Estender o teste até',
            extendTrialPunch: 'Seu teste termina em ',
            extendTrialOfferTitle: 'Extensão do período de avaliação',
            trialExtended: 'Extensão do período de avaliação',
            trialExtendedMessage:
                'Seu período de avaliação foi estendido com sucesso',
            applyingTrialExtension: 'Estendendo seu período de avaliação',
            switchPlanCTA: 'Mudar de plano',
            changePlanHighlights: 'Destaques',
            changePlanOfferPunch: 'Planos secretos com desconto',
            planChanged: 'Plano alterado',
            planChangedMessage: 'Seu novo plano agora está em vigor',
            applyingPlanChange: 'Alterando seu plano...',
            surveyStepTag: 'Seu feedback',
            freeFormStepTag: 'Seu feedback',
            finalConfirmation: 'Confirmação final',
            offerDiscountTag: 'Oferta especial',
            offerChangePlanTag: 'Considerar outros planos',
            offerExtendTrialTag: 'Estender seu teste',
            offerRedirectTag: 'Deixe-nos ajudar',
            offerContactTag: 'Deixe-nos ajudar',
            offerPauseTag: 'Pausa na assinatura',
            completeTag: 'Assinatura cancelada',
            errorTag: 'Ocorreu um erro',
            offerAccepted: 'Oferta aceita',
        },
    },
};
