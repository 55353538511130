import React from 'react';
import Fade from '@mui/material/Fade';
import { ContainerModal } from './styles';
import { IoMdClose } from 'react-icons/io';
import { Button } from 'app/presentation/components';
import { FiCheck } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';
import useArteAi from '../../../hooks/useArteAi';
import useDarkMode from 'app/presentation/hooks/useDarkMode';

interface ModalProps {
    active: boolean;
    handleClose: () => void;
    selectedImage?: number | null;
}

export const ModalArteAiReport: React.FC<ModalProps> = ({
    active,
    handleClose,
    selectedImage,
}) => {
    const { saveReport, reportTypes, prompt } = useArteAi();
    const [radio, setRadio] = React.useState<number | null>(1);
    const intl = useIntl();
    const { darkmode } = useDarkMode();

    React.useEffect(() => {
        setRadio(1);
    }, [active]);

    const handleCloseContainer = (e: any) => {
        if (e.currentTarget === e.target) handleClose();
    };

    const handleChange = (id: number) => {
        setRadio(id);
    };

    const handleReport = async () => {
        handleClose();

        if (!reportTypes) return;

        let type = '';

        if (radio === 1) {
            type = reportTypes[0];
        } else if (radio === 2) {
            type = reportTypes[1];
        } else {
            return;
        }

        if (prompt && !selectedImage) {
            prompt.images.map(async image => {
                await saveReport({
                    report_type: type,
                    description: '',
                    resource_usage_id: image.id,
                });
            });
        }

        if (!selectedImage) return;

        await saveReport({
            report_type: type,
            description: '',
            resource_usage_id: selectedImage,
        });
    };

    return (
        <Fade in={active} timeout={400}>
            <ContainerModal
                onClick={handleCloseContainer}
                className={darkmode ? 'modalArteReportDark' : ''}
            >
                <div className="content-modal">
                    <header>
                        <IoMdClose size={25} onClick={handleClose} />
                    </header>
                    <div id="title">
                        <h1>{routeTranslate(intl, 'arteai.report')}</h1>
                        <p>
                            {routeTranslate(intl, 'arteai.report.description')}
                        </p>
                    </div>
                    <ul>
                        <li>
                            <span>
                                {routeTranslate(intl, 'arteai.report.explicit')}
                            </span>
                            <div
                                onClick={() => handleChange(1)}
                                className={
                                    radio === 1 ? 'radio active' : 'radio'
                                }
                            >
                                <FiCheck
                                    size={16}
                                    color={darkmode ? '#000' : ''}
                                />
                            </div>
                        </li>
                        <li>
                            <span>
                                {routeTranslate(intl, 'arteai.report.match')}
                            </span>
                            <div
                                onClick={() => handleChange(2)}
                                className={
                                    radio === 2 ? 'radio active' : 'radio'
                                }
                            >
                                <FiCheck
                                    size={16}
                                    color={darkmode ? '#000' : ''}
                                />
                            </div>
                        </li>
                    </ul>
                    <div className="container-submit">
                        <Button onClick={handleReport} type="submit">
                            {routeTranslate(intl, 'tooltip.report')}
                        </Button>
                        <Button onClick={handleClose} type="button">
                            {routeTranslate(intl, 'button.back')}
                        </Button>
                    </div>
                </div>
            </ContainerModal>
        </Fade>
    );
};
