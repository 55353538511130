import React from 'react';

export const Websearch: React.FC = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.5 10C17.5 8.51664 17.0601 7.0666 16.236 5.83323C15.4119 4.59986 14.2406 3.63856 12.8701 3.07091C11.4997 2.50325 9.99168 2.35472 8.53683 2.64411C7.08197 2.9335 5.7456 3.64781 4.6967 4.6967C3.64781 5.7456 2.9335 7.08197 2.64411 8.53683C2.35472 9.99168 2.50325 11.4997 3.07091 12.8701C3.63856 14.2406 4.59986 15.4119 5.83323 16.236C7.0666 17.0601 8.51664 17.5 10 17.5M3 7.5H17M3 12.5H9.58334"
            stroke="#194BFB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.58322 2.5C8.17934 4.74968 7.43506 7.34822 7.43506 10C7.43506 12.6518 8.17934 15.2503 9.58322 17.5M10.4166 2.5C11.7642 4.65811 12.5052 7.13961 12.5616 9.68333M16.8332 16.8333L18.3332 18.3333M12.4999 15C12.4999 15.663 12.7633 16.2989 13.2321 16.7678C13.701 17.2366 14.3368 17.5 14.9999 17.5C15.6629 17.5 16.2988 17.2366 16.7677 16.7678C17.2365 16.2989 17.4999 15.663 17.4999 15C17.4999 14.337 17.2365 13.7011 16.7677 13.2322C16.2988 12.7634 15.6629 12.5 14.9999 12.5C14.3368 12.5 13.701 12.7634 13.2321 13.2322C12.7633 13.7011 12.4999 14.337 12.4999 15Z"
            stroke="#194BFB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
