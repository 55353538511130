import { Step3and4Container, StyledSlider } from './../styles';
import { Button } from 'app/presentation/components/common';
import { FaCheck } from 'react-icons/fa6';
import Alert from '@mui/material/Alert';
import { PiWarningCircle } from "react-icons/pi";


interface Step4Props {
    setVoiceToneHumor: React.Dispatch<React.SetStateAction<number>>;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const marks = [
    { value: 0, label: 'Baixo' },
    { value: 100, label: 'Alto' },
];

export const Step4: React.FC<Step4Props> = (props) => {

    return (
        <Step3and4Container>
            <h1>Qual o nível de humor da sua marca?</h1>

            <span>
                O nível de humor faz com que o Jarbas use piadas, gírias
                informais ou emojis.
            </span>

            <StyledSlider
                marks={marks}
                defaultValue={50}
                step={50}
                onChange={(e, num) => {
                    props.setVoiceToneHumor(num as number);
                }}
            />
            
            <Alert
                icon={<PiWarningCircle color="#000" />}
                color="warning"
                sx={{
                    fontWeight: '600',
                    color: '#000',
                    borderRadius: '6px',
                    marginTop: '30px',
                }}
            >
                Mesmo com um alto nível de humor, é improvável que Jarbas
                utilize de ironia, sarcasmo ou deboche.
            </Alert>

            <div className="buttons">
                <Button
                    className="buttons-child left"
                    onClick={() => props.setCurrentStep(prev => prev - 1)}
                >
                    <p> Anterior </p>
                </Button>

                <Button
                    className="buttons-child right"
                    onClick={() => props.setCurrentStep(prev => prev + 1)}
                    icon={<FaCheck />}
                >
                    <p> Finalizar </p>
                </Button>
            </div>
        </Step3and4Container>
    )
}