import styled, { css } from 'styled-components';

export const Behavior = styled.div`
    ${({
        theme: {
            agent: {
                pages: {
                    behavior: { background, title, subtitle, chat },
                },
            },
        },
    }) => css`
        position: relative;
        padding: 30px 0px;
        display: flex;
        justify-content: center;
        max-height: calc(100vh - 80px);
        overflow-y: scroll;
        background-color: ${background.color};

        @media (min-width: 64em) {
            padding: 30px 28px;
            justify-content: start;
        }

        .behavior-form-container {
            min-width: 331px;
            width: 331px;
            height: fit-content;

            @media (min-width: 64em) {
                width: 594px;
                min-width: 594px;
            }

            .title {
                letter-spacing: 0.2px;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                margin-bottom: 10px;
                color: ${title.color};
            }

            .subtitle {
                letter-spacing: 0.2px;
                font-weight: 500;
                font-size: 13px;
                line-height: 19.5px;
                margin-bottom: 30px;
                color: ${subtitle.color};
            }

            .expansion-panel-container {
                display: grid;
                grid-row-gap: 15px;
            }
        }

        .behavior-chat-container {
            width: 360px;
            height: 630px;
            border-radius: 14px;
            box-shadow: 0px 5px 30px 0px #0000001a;
            background-color: ${chat.background.color};
            margin-left: 48px;
            display: none;

            @media (min-width: 1400px) {
                display: block;
            }
        }
    `}
`;
