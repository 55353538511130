import { routeTranslate as t } from 'utils/intl';
import { MINUTE, HOUR, DAY, MONTH, YEAR, Time, timeTextMap } from './constants';
import { IntlShape } from 'react-intl';
import { MemoryContent } from 'services/brand/memories/types';
import { Brands as Brand } from 'app/presentation/hooks/interfaces/brand';

export const handleLastEdit = (lastEdit: Date, intl: IntlShape): string => {
    const now = new Date();
    const timeDiffInSeconds = Math.floor((now.getTime() - lastEdit.getTime()) / 1000);

    const formatTime = (timeUnit: Time) => timeStringBuilder(timeDiffInSeconds, timeUnit, intl);

    if (timeDiffInSeconds < MINUTE) {
        return t(intl, "time.seconds-ago");
    } else if (timeDiffInSeconds < HOUR) {
        return formatTime(MINUTE);
    } else if (timeDiffInSeconds < DAY) {
        return formatTime(HOUR);
    } else if (timeDiffInSeconds < MONTH) {
        return formatTime(DAY);
    } else if (timeDiffInSeconds < YEAR) {
        return formatTime(MONTH);
    } else {
        return formatTime(YEAR);
    }
}

const timeStringBuilder = (diff: number, timeUnit: Time, intl: IntlShape): string => {
    const num = Math.floor(diff / timeUnit);
    const pluralSuffix = num > 1 ? "s" : "";
    const timeUnitTranslation = t(intl, "time." + timeTextMap[timeUnit]);
    const adverbTranslation = t(intl, "time.ago");

    return `${num} ${timeUnitTranslation}${pluralSuffix} ${adverbTranslation}`;
}

export const getOrderedContents = async (contents: MemoryContent[] | undefined, brand: Brand): Promise<MemoryContent[]> => {
    const contentsMap = new Map<number, MemoryContent>(contents && contents.map(content => [content.language_id, content]));

    const arr: MemoryContent[] = [];

    brand.supported_languages.map(brandLanguage => {
        const content = contentsMap.get(brandLanguage.language_id);
        content ? arr.push(content) : arr.push({ publish_status: "deactivated" } as MemoryContent);
    });

    return arr
}
