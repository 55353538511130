import styled from 'styled-components';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const Ellipsis = styled.div`
    width: 10px;
    height: 10px;
    background-color: rgba(160, 159, 165, 1);

    border-radius: 50%;

    &.published {
        background-color: rgba(34, 197, 94, 1) ;
    }

    &.draft {
        background-color: rgba(255, 200, 55, 1);
    }
`;

export const Select = styled(MuiSelect)`
    width: 250px;
    height: 32px;
    border: 0;


    background-color: #f1f1f1;
    color: black;

    span {
        font-weight: 700;
        font-size: 13px;
    }
`;

export const Option = styled(MenuItem)`
    height: 32px;

    &.MuiMenuItem-root {
        font-weight: 700;
        font-size: 13px;

        background-color: inherit;
    }
`;

export const PublishStatus = styled.div`
    width: 78px;
    height: 22px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;
    background-color: #f1f1f1;

    display: flex;
    align-items: center;
    justify-content: center;
    

    &.draft {
        color: black;
        background-color: rgba(255, 200, 55, 0.5);
    }

    &.published {
        color: rgba(5, 132, 52, 1);
        background-color: rgba(22, 163, 74, 0.1);
    }



`;

export const Details = styled.div`
    margin-top: 30px;

    .details-header {
        font-weight: 700;
        font-size: 16px;
        color: black;
    }
    
    span {
        font-weight: 600;
        font-size: 14px;
        color: rgba(160, 159, 165, 1);
    }

    .details-body {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        gap: 12px; 

        .details-item {
            display: flex;
            align-items: center;
            gap: 17px;

            height: 38px;
            
            span:first-child {
                width: 123px;
            }
    
            span:last-child {
                color: black
            }
        }
    }


`
