import styled from 'styled-components';

export const KnowledgeContainer = styled.div`
    h1 {
        font-weight: 700;
    }

    p {
        color: #7e7e7e;
        font-weight: 500;
        margin-top: 12px;
    }

    .knowledge-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            font-size: 20px;
        }

        p {
            font-size: 14px;
        }
    }

    .styled-button {
        width: 21%;
        padding-top: 8px;
        padding-right: 24px;
        padding-bottom: 8px;
        padding-left: 24px;
        font-size: 14px;
        font-weight: 700;
        height: 48px;
        border: 0;
        border-radius: 8px;

        display: flex;
        align-items: center;
        justify-content: center;
    
        background-color: #d0ff61;
    }

    .teste1 {
        z-index: 999;
    }
`;
