import React from 'react';
import { BodyPadding, Container, ToolbarContainer, MenuMobile } from './styles';
import { Chat, Home, Menu, Options } from 'app/presentation/components/icons';
import Slide from '@mui/material/Slide';
import { MenuDesktop } from '../MenuDesktop';
import { routeTranslate } from '../../../../../utils/intl';
import { Link, matchRoutes, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { IoMdClose } from 'react-icons/io';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import useTemplates from 'app/presentation/hooks/useTemplates';

const Toolbar: React.FC = () => {
    const { menu, setMenu } = useTemplates();

    const intl = useIntl();

    const path =
        routeTranslate(intl, 'route.urlLang') +
        routeTranslate(intl, 'route.backoffice') +
        routeTranslate(intl, 'route.chat');
    const { darkmode } = useDarkMode();

    const routes = [
        {
            path: path + routeTranslate(intl, 'route.newChat'),
        },
    ];

    const useCurrentPath = () => {
        const location = useLocation();
        const router = matchRoutes(routes, location);
        return !!router;
    };

    const currentPath = useCurrentPath();

    const handleMenu = () => {
        setMenu(!menu);
    };

    const CloseMenu = () => {
        setMenu(false);
    };

    if (currentPath) {
        return <></>;
    }

    const pathNow = window.location.pathname;

    return (
        <>
            <Container
                className={`container ${darkmode && 'containerToolbarDark'}`}
            >
                <div className="content">
                    <ToolbarContainer className={darkmode ? 'darkInside' : ''}>
                        <ul>
                            <li onClick={handleMenu}>
                                {!menu ? <Menu /> : <IoMdClose size={26} />}
                            </li>
                            <Link
                                onClick={CloseMenu}
                                to={`/${routeTranslate(
                                    intl,
                                    'route.urlLang',
                                )}${routeTranslate(
                                    intl,
                                    'route.backoffice',
                                )}${routeTranslate(intl, 'route.home')}`}
                            >
                                <li
                                    className={
                                        pathNow ===
                                        `/${routeTranslate(
                                            intl,
                                            'route.urlLang',
                                        )}${routeTranslate(
                                            intl,
                                            'route.backoffice',
                                        )}${routeTranslate(intl, 'route.home')}`
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    <Home />
                                </li>
                            </Link>
                            <Link
                                onClick={CloseMenu}
                                to={`/${routeTranslate(
                                    intl,
                                    'route.urlLang',
                                )}${routeTranslate(
                                    intl,
                                    'route.backoffice',
                                )}${routeTranslate(intl, 'route.library')}`}
                            >
                                <li
                                    className={
                                        pathNow ===
                                        `/${routeTranslate(
                                            intl,
                                            'route.urlLang',
                                        )}${routeTranslate(
                                            intl,
                                            'route.backoffice',
                                        )}${routeTranslate(
                                            intl,
                                            'route.library',
                                        )}`
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    <Options />
                                </li>
                            </Link>
                            <Link
                                onClick={CloseMenu}
                                to={`/${routeTranslate(
                                    intl,
                                    'route.urlLang',
                                )}${routeTranslate(
                                    intl,
                                    'route.backoffice',
                                )}${routeTranslate(intl, 'route.chat')}`}
                            >
                                <li>
                                    <Chat />
                                </li>
                            </Link>
                        </ul>
                    </ToolbarContainer>
                    <div className="clear"></div>
                </div>
                <BodyPadding />
                <Slide direction="right" in={menu}>
                    <MenuMobile>
                        <MenuDesktop handleCloseMenu={handleMenu} />
                    </MenuMobile>
                </Slide>
            </Container>
        </>
    );
};

export default Toolbar;
