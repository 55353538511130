import styled from 'styled-components';

export const NotFoundContainer = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .marginTop {
        margin-top: 50px;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
            color: #000000;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 130%;
            margin: 21px 0;
        }
        p {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 150%;
            color: #a09fa5;
            margin-bottom: 40px;
        }
        .backHome {
            background: #ffffff;
            width: 166px;
            height: 48px;
            border: 1px solid #cccccc;
            border-radius: 8px;
            color: #cccccc;
            text-align: center;
            font-size: 0.875rem;
            font-weight: 700;
            transition: 0.5s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
`;
