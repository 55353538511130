import styled from 'styled-components';

export const DetailsContent = styled.div`
    width: 100%;

    #change-img {
        text-align: center;
    }

    #image {
        width: 100px;
        height: 100px;
        border: 1px solid #d0ff61;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .border {
            width: 88px;
            height: 88px;
            border-radius: 88px;
            border: 1px solid #d0ff61;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
    }

    #imageText {
        width: 100px;
        height: 100px;
        border: 1px solid #194bfb;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #194bfb;

        .borderText {
            width: 88px;
            height: 88px;
            border-radius: 88px;
            border: 1px solid #194bfb;
            overflow: hidden;
            li {
                height: 100%;
                width: 100%;
                color: #718096;

                &.imageText {
                    border-radius: 1000px;
                    background-color: #194bfb;
                    color: #fff;
                    font-family: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 48px;
                }
            }
        }
    }

    #form {
        label {
            color: #718096;
        }

        .content-form {
            > div div {
                margin-bottom: 24px;
            }
        }

        button[type='submit'] {
            margin-top: -2px;
            width: 100%;
            height: 48px;
            font-size: 0.875em;
        }
    }

    #submit-image {
        margin-top: 32px;
        margin-bottom: 48px;
        display: flex;
        justify-content: center;
        gap: 12px;
        height: 40px;

        button[type='submit'] {
            width: 180px;
            font-size: 0.75em;
            border-radius: 8px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;

            svg {
                margin-right: 7px;
                margin-left: 0px !important;
            }
        }

        #remove {
            width: 90px;
            background: #ffffff;
            border: 1px solid #e2e8f0;
            border-radius: 8px;
            font-size: 0.75em;
        }
    }

    @media (min-width: 64em) {
        #change-img {
            display: flex;
        }

        #image,
        #imageText {
            margin-right: 32px;
        }

        button[type='submit'] {
            max-width: 139px;
        }

        #remove {
            min-width: 110px;
        }

        #form {
            margin-top: 48px;
            .content-form {
                display: flex;
                gap: 24px;

                > div {
                    flex: 1;

                    div {
                        margin-bottom: 24px;
                    }
                }

                > div div {
                    margin-bottom: 32px;
                }
            }

            button[type='submit'] {
                width: 145px;
            }
        }
    }
`;
