import React from 'react';
import { useSnackbar } from 'notistack';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import { HistoryArteAi, Rate } from '../../../interfaces/documents.interface';
import { ArteAiService } from 'services/arte-ai';
import ArteAiContext from '../contexts/ArteAiContext';
import {
    Generate,
    PromptArteAi,
    Report,
    Variation,
    Categories,
} from '../../../interfaces/arte-ai.interface';
import useUserConfig from '../hooks/useUserConfigs';
import { useIntl } from 'react-intl';
import { routeTranslate } from 'utils/intl';

const arteAiService = new ArteAiService();

const ArteAiProvider: React.FC<ProviderProps> = ({ children }) => {
    const [prompt, setPrompt] = React.useState<PromptArteAi>();
    const [categories, setCategories] = React.useState<Array<Categories>>([
        { id: -1, icon: '', display_name: '', tag: '' },
    ]);
    const [history, setHistory] = React.useState<HistoryArteAi>();
    const [reportTypes, setReportTypes] = React.useState<string[]>();

    const intl = useIntl();

    const { enqueueSnackbar } = useSnackbar();
    const { setModalAntecipatePlan } = useUserConfig();

    const getCategories = async () => {
        const result = await arteAiService.getCategories();

        if (result instanceof Error) {
            return;
        }

        setCategories(result);
    };

    React.useEffect(() => {
        getHistory(1);
        getReportTypes();
        getCategories();
    }, []);

    const getHistory = async (
        page: number,
        search?: string,
        reset?: boolean,
    ) => {
        try {
            const dataHistory = await arteAiService.getAll(page, search);

            if (search) {
                setHistory(dataHistory);
                return;
            }

            setHistory({
                ...dataHistory,
                data:
                    history && !reset && !search
                        ? [...history.data, ...dataHistory.data]
                        : dataHistory.data,
            });
        } catch (e) {
            enqueueSnackbar('Erro ao obter historico!', {
                variant: 'error',
            });
        } finally {
        }
    };

    const rateResponse = async (rate: Rate) => {
        try {
            await arteAiService.rateResponse(rate);

            enqueueSnackbar('Avaliação enviada com sucesso!', {
                variant: 'success',
            });
        } catch (e) {
            enqueueSnackbar('Erro ao avaliar resultado!', {
                variant: 'error',
            });
        } finally {
        }
    };

    const getReportTypes = async () => {
        try {
            const data = await arteAiService.reportTypes();

            setReportTypes(data);
        } catch (e) {
        } finally {
        }
    };

    const saveReport = async (request: Report) => {
        try {
            await arteAiService.report(request);

            enqueueSnackbar('Avaliação enviada com sucesso!', {
                variant: 'success',
            });
        } catch (e) {
            enqueueSnackbar('Erro ao avaliar resultado!', {
                variant: 'error',
            });
        } finally {
        }
    };

    const handleGenerate = async (request: Generate) => {
        try {
            const data = await arteAiService.create(request);

            setPrompt(data);

            enqueueSnackbar('Imagens geradas!', {
                variant: 'success',
            });
        } catch (e: any) {
            const errorArr = Object.values(e);
            const msg = errorArr.join('');
            const error = msg.replace(/[0-9]/g, '');

            if (error === 'rejected_by_safety_system') {
                enqueueSnackbar(
                    routeTranslate(intl, 'arteai.error.sensitive'),
                    {
                        variant: 'error',
                    },
                );
            } else if (error === 'image_generation_limit_exceeded') {
                setModalAntecipatePlan(true);
            } else if (error === 'image_generation_limit_may_exceeded') {
                setModalAntecipatePlan(true);
            }
        } finally {
            await getHistory(1, '', true);
        }
    };

    const handleVariation = async (request: Variation) => {
        try {
            const data = await arteAiService.createVariation(request);

            setPrompt(data);

            enqueueSnackbar('Imagens geradas!', {
                variant: 'success',
            });
        } catch (e: any) {
            if (e.error === 'rejected_by_safety_system') {
                enqueueSnackbar('Erro, Conteúdo é sensível! ', {
                    variant: 'error',
                });
            } else if (e.error === 'image_generation_limit_exceeded') {
                setModalAntecipatePlan(true);
            } else if (e.error === 'image_generation_limit_may_exceeded') {
                setModalAntecipatePlan(true);
            } else {
                enqueueSnackbar('Error ao gerar imagens! ', {
                    variant: 'error',
                });
            }
        } finally {
            await getHistory(1, '', true);
        }
    };

    const handleImg2Img = async (request: FormData) => {
        try {
            const data = await arteAiService.img2img(request);

            setPrompt(data);

            enqueueSnackbar('Imagens geradas!', {
                variant: 'success',
            });
        } catch (e: any) {
            const errorArr = Object.values(e);
            const msg = errorArr.join('');
            const error = msg.replace(/[0-9]/g, '');

            console.log(error);
            if (error === 'rejected_by_safety_system') {
                enqueueSnackbar(
                    routeTranslate(intl, 'arteai.error.sensitive'),
                    {
                        variant: 'error',
                    },
                );
            }
        } finally {
            await getHistory(1, '', true);
        }
    };

    return (
        <ArteAiContext.Provider
            value={{
                handleGenerate,
                handleImg2Img,
                reportTypes,
                saveReport,
                getHistory,
                rateResponse,
                handleVariation,
                prompt,
                history,
                categories,
            }}
        >
            {children}
        </ArteAiContext.Provider>
    );
};

export default ArteAiProvider;
