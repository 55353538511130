import React from 'react';
import * as S from './styles';
import {
    InfoMessage,
    RadioGroup,
    AgentFormContainer,
    Button,
} from 'app/presentation/components/agent';
import { IconCheck } from '@tabler/icons-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormField } from 'services/agent/interfaces';
import useAgent from 'app/presentation/hooks/useAgent';
import useText from 'app/presentation/hooks/useText';

export const AgentFormPanel = () => {
    const { textGetter } = useText();
    const panelText = textGetter('agent.panel.agent-form');
    const commonsText = textGetter('agent.panel.commons');

    const [formFrequency, setFormFrequency] = React.useState<string>('never');
    const [agentFormFields, setAgentFormFields] = React.useState<FormField[]>(
        [],
    );

    const { agent, updateForm } = useAgent();
    const [formSubmitLoading, setFormSubmitLoading] =
        React.useState<boolean>(false);
    const { handleSubmit } = useForm<{}>();

    const loadForm = () => {
        if (!agent || !agent.config) return;
        setFormFrequency(agent.config.form_frequency);

        if (agent.config.forms.length > 0) {
            setAgentFormFields(agent.config.forms[0].form_fields);
        } else {
            setAgentFormFields([]);
        }
    };

    React.useEffect(() => {
        loadForm();
    }, [agent]);

    const onSubmit: SubmitHandler<{}> = async () => {
        setFormSubmitLoading(true);
        updateForm(agentFormFields, formFrequency).finally(() =>
            setFormSubmitLoading(false),
        );
    };

    return (
        <S.AgentFormPanel onSubmit={handleSubmit(onSubmit)}>
            <p className="panel-desc">{panelText('desc')}</p>
            <RadioGroup
                name="form-frequency"
                setValue={setFormFrequency}
                value={formFrequency}
                customValidation={{
                    validate: (value: string) =>
                        value == 'never' || agentFormFields.length > 0,
                    message: panelText('invalid-form-message'),
                }}
                radios={[
                    {
                        value: 'never',
                        title: panelText('form-frequency.never.title'),
                        desc: panelText('form-frequency.never.subtitle'),
                    },
                    {
                        value: 'outside_business_hours',
                        title: panelText(
                            'form-frequency.outside-business-hours.title',
                        ),
                        desc: panelText(
                            'form-frequency.outside-business-hours.subtitle',
                        ),
                    },
                    {
                        value: 'always',
                        title: panelText('form-frequency.always.title'),
                        desc: panelText('form-frequency.always.subtitle'),
                    },
                ]}
            ></RadioGroup>
            <InfoMessage
                type="yellow"
                text={panelText('info-message')}
            ></InfoMessage>
            <AgentFormContainer
                fields={agentFormFields}
                setFields={setAgentFormFields}
            ></AgentFormContainer>
            <div className="buttons-container">
                <Button variant="secondary" type="button" onClick={loadForm}>
                    {commonsText('button.cancel')}
                </Button>
                <Button
                    variant="dark"
                    type="submit"
                    loading={formSubmitLoading}
                    className="save-button"
                >
                    <IconCheck></IconCheck>
                    <span>
                        {commonsText('button.save')}
                        <span className="desktop">
                            {' '}
                            {commonsText('button.save-suffix')}
                        </span>
                    </span>
                </Button>
            </div>
        </S.AgentFormPanel>
    );
};
