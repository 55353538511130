import styled from 'styled-components';

export const ContainerNotification = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: calc(100vh - 52px);

    header {
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        border-bottom: 1px solid #edf2f7;

        h1 {
            font-size: 1.125em;
        }

        span {
            font-size: 0.75em;
            color: #194bfb;
            font-weight: 600;
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }
    }

    .usingDark {
        border-color: #3a3a3a !important;
    }

    #body {
        height: 100%;
        max-height: calc(100vh - 52px - 64px);
        overflow: auto;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            background: #edf2f7;
            border-radius: 50px;
        }

        .notification {
            display: flex;
            padding: 24px;
            gap: 14px;

            #empty {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                h1 {
                    margin-bottom: 16px;
                    font-size: 24px;
                    font-weight: 700;
                }

                p {
                    color: #718096;
                    font-size: 14px;
                    margin-bottom: 39px;
                    font-weight: 500;
                }
            }

            .content-notification {
                flex: 1;

                h1 {
                    font-size: 0.875em;
                    margin-bottom: 4px;
                    color: #1a202c;
                    font-weight: 700;
                }

                p {
                    font-size: 0.75em;
                    color: #718096;
                    font-weight: 500;
                }

                .time {
                    margin-top: 8px;
                    margin-bottom: 16px;
                    font-size: 0.75em;
                    color: #718096;
                    font-weight: 600;
                }

                .buttons {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;

                    button {
                        width: 131.5px;
                        height: 40px;
                        font-size: 0.75em;
                        font-weight: 600;
                        border-radius: 8px;
                    }

                    button:first-of-type {
                        background-color: #fff;
                        border: 1px solid #e2e8f0;
                    }
                }
            }

            .icon {
                min-width: 40px;
                min-width: 40px;
                width: 40px;
                height: 40px;
                background: #f7fafc;
                border: 1px solid #e2e8f0;
                border-radius: 1000px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    @media (min-width: 64em) {
        height: 100%;
        position: static;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-height: 752px;

        #body {
            overflow-y: auto;
        }
    }
`;
