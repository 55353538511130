import React from 'react';
import * as S from './styles';
import { IconHelpCircle } from '@tabler/icons-react';
import useText from 'app/presentation/hooks/useText';

interface BaseProps {
    text: string;
    warning?: boolean;
    type: 'yellow' | 'grey';
}

export const InfoMessage: React.FC<BaseProps> = ({
    text,
    warning = false,
    type,
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.info-message');
    return (
        <S.InfoMessage variant={type}>
            <IconHelpCircle></IconHelpCircle>
            <p>
                {warning ? (
                    <span className="warning">{t('warning')} </span>
                ) : null}
                {text}
            </p>
        </S.InfoMessage>
    );
};
