import React from 'react';
import useLoading from '../hooks/useLoading';
import NotificationContext from '../contexts/NotificationContext';
import { ProviderProps } from './interfaces/provider-default-props.interface';
import { NotificationsService } from '../../../services/notifications';
import {
    Answer,
    Notification,
} from '../../../interfaces/notifications.interface';
import { WorkspacesService } from '../../../services/workspaces';

const notificationsService = new NotificationsService();
const workspacesService = new WorkspacesService();

const NotificationProvider: React.FC<ProviderProps> = ({ children }) => {
    const [notifications, setNotification] = React.useState<
        Notification[] | undefined
    >();
    const [newNotifications, setNewNotifications] = React.useState(false);
    const { showLoading, closeLoading } = useLoading();

    React.useEffect(() => {
        const fetchData = async () => {
            await getNotifications();
        };

        fetchData().catch(console.error);
    }, []);

    const hasNew = (notificationsResponse: Notification[] | undefined) => {
        const condition =
            notificationsResponse && notificationsResponse.length > 0;

        if (condition) {
            const isNotRead =
                notificationsResponse &&
                notificationsResponse.find(
                    notification => notification.is_read === false,
                );

            if (isNotRead) {
                setNewNotifications(true);
                return;
            }

            setNewNotifications(false);
            return;
        }

        setNewNotifications(false);
    };

    const getNotifications = async () => {
        try {
            showLoading();
            const response = await notificationsService.getNotifications();

            setNotification(response);
            hasNew(response);
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    const isRead = async (id: number) => {
        return await notificationsService.isRead(id);
    };

    const answerInvitation = async (answer: Answer) => {
        try {
            showLoading();
            await workspacesService.answerInvitation(answer);
            await getNotifications();
        } catch (e) {
        } finally {
            closeLoading();
        }
    };

    return (
        <NotificationContext.Provider
            value={{
                getNotifications,
                answerInvitation,
                notifications,
                newNotifications,
                isRead,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
