import React from 'react';
import { Button, Container } from 'app/presentation/components';
import { HeaderPlan, ContainerPlans, ContainerFaq } from './styles';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { routeTranslate as t } from 'utils/intl';
import { CheckEnable } from 'app/presentation/components/icons';
import { PlansService } from '../../../services/plans';
import { useMediaQuery } from '@mui/material';
import { Plans as IPlans, Session } from '../../../interfaces/plans.interface';
import { Workspace } from '../../../app/presentation/hooks/interfaces/workspace';
import { WorkspacesService } from '../../../services/workspaces';
import { AuthContext } from '../../../app/presentation/contexts/AuthContext';
import useDarkMode from 'app/presentation/hooks/useDarkMode';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from 'utils/useDocumentTitle';

enum Period {
    Year = 1,
    Month = 2,
}

const plansService = new PlansService();

interface IMark {
    plan_id: number;
    markValue: number | number[];
}

type PlansProps = {
    plans: IPlans[];
};

const Plans: React.FC<PlansProps> = ({ plans }) => {
    const [plan, setPlan] = React.useState<Period>(Period.Month);
    const [workspace, setWorkspace] = React.useState<Workspace>();
    const [mark] = React.useState<IMark[]>([]);
    const [loading, setLoading] = React.useState('');
    const intl = useIntl();
    const { darkmode } = useDarkMode();
    const isDesktop = useMediaQuery('(min-width: 48em)');
    const navigate = useNavigate();

    const user = React.useContext(AuthContext);

    const currentPageName = t(intl, 'route.plans').replace('/', '');
    const pageTitle = currentPageName.charAt(0).toUpperCase() + currentPageName.slice(1);

    useDocumentTitle(pageTitle);

    const asyncCall = async () => { await getWorkspace(); };
    React.useEffect(() => { asyncCall(); }, []);

    const handleClick = async (request: Session) => {
        setLoading(request.price_id);

        const data = await plansService.createSession(request);
        window.location.href = data.url;
    };

    const handlePlan = (period: Period) => { setPlan(period); };

    const getWorkspace = async () => {
        const workspacesService = new WorkspacesService();
        const data = await workspacesService.getAll();
        const findWorkspace = getWorkspaceMain(data);
        setWorkspace(findWorkspace);
    };
    const getWorkspaceMain = (workspacesData: Workspace[]) => {
        const workspaceId = localStorage.getItem(`${user?.uid}-workspaceSelected`);
        const findProject = workspacesData.find(item => item.id.toString() === workspaceId);

        return workspaceId ? findProject : workspacesData[0];
    };

    if (!workspace) return <></>;

    const plansArr = isDesktop
        ? [...plans].sort((a, b) => {
            if (a.plan.name.toLowerCase() === 'criador') return -1; // a comes first
            if (b.plan.name.toLowerCase() === 'criador') return 1; // b comes first
            return a.plan.name.localeCompare(b.plan.name); // if neither is "criador", sort alphabetically or any other criteria you want
        })
        : [...plans].reverse();

    const handleBack = () => {
        navigate(`/${t(intl, 'route.urlLang')}${t(intl, 'route.backoffice')}${t(intl, 'route.definitions')}${t(intl, 'route.plans')}`);
    };

    const basicFeatures =
        t(intl, 'plansPage.features') === 'BR'
            ? [
                'Palavras ilimitadas* geradas por IA',
                '+ de 60 modelos de IA',
                'Inclui 1 usuário',
                'Gerencie 1 marca',
                '50 ativos de Conhecimento',
                'Extensão de navegador',
            ]
            : [
                'Unlimited* AI-generated words',
                '60+ AI models',
                'Includes 1 user',
                'Manage 1 brand',
                '50 Knowledge assets',
                'Browser extension',
            ];
    const geniusFeatures =
        t(intl, 'plansPage.features') === 'BR'
            ? [
                'Palavras ilimitadas* geradas por IA',
                'Inclui 3 usuários',
                'Chat com acesse o internet',
                'Acesso ao Arte AI',
                'Gerencie 3 marcas',
                '150 ativos de Conhecimento',
            ]
            : [
                'Unlimited* AI-generated words',
                'Includes 3 users',
                'Chat with internet access',
                'Access to AI Art',
                'Manage 3 brands',
                '150 Knowledge assets',
            ];

    const enterpriseFeatures =
        t(intl, 'plansPage.features') === 'BR'
            ? [
                'Recursos ilimitados',
                'Modelos personalizados de IA',
                'Onboarding e treinamento',
                'Equipes maiores de 5 usuários',
                'Acesso a API*',
                'Gerente de sucesso dedicado',
            ]
            : [
                'Unlimited resources',
                'Custom AI models',
                'Onboarding and training',
                'Teams larger than 5 users',
                'API access*',
                'Dedicated success manager',
            ];

    return (
        <Container className="fade-in">
            <HeaderPlan className={darkmode ? 'headerPlansDark' : ''}>
                <div className="box">
                    <h1>{t(intl, 'plansPage.title')}</h1>
                    <p>{t(intl, 'plansPage.description')}</p>
                </div>
                <div className="box">
                    <div className="toggle">
                        <span
                            onClick={() => handlePlan(Period.Month)}
                            className={plan === Period.Month ? 'active' : ''}
                        >
                            {t(intl, 'plansPage.month')}
                        </span>
                        <span
                            onClick={() => handlePlan(Period.Year)}
                            className={plan === Period.Year ? 'active' : ''}
                        >
                            {t(intl, 'plansPage.year')}
                        </span>
                    </div>
                    <p id="economy">
                        {parse(t(intl, 'plansPage.freeMessage'))}
                    </p>
                </div>

                <button className="button-back" onClick={handleBack}>
                    {t(intl, 'button.back')}
                </button>
            </HeaderPlan>

            <ContainerPlans className={darkmode ? 'containerPlansDark' : ''}>
                {plansArr?.map(planItem => {
                    const isCriador =
                        planItem.plan.name.toLowerCase() === 'criador';
                    const marks = planItem.wordpacks.sort(function (a, b) {
                        return (a.wordpack.word_credits - b.wordpack.word_credits);
                    }).map((word, index) => ({
                        value: (100 / planItem.wordpacks.length) * (index + 1),
                        word: word.wordpack.word_credits,
                        unit_price_monthly: word.unit_price_monthly,
                        unit_price_yearly: word.unit_price_yearly,
                    }));

                    let period = '';
                    const findWork = mark.find(markItem => markItem.plan_id === planItem.plan.plan_id);

                    let price = 0;

                    if (plan === Period.Year) {
                        price = marks[0].unit_price_yearly / 100 / 12;
                    } else {
                        price = marks[0].unit_price_monthly / 100;
                    }

                    if (findWork) {
                        const quantityWord = marks.find(markItem => markItem.value === findWork.markValue)?.word ?? 0;

                        if (plan === Period.Year) {
                            price = marks.find(markItem => markItem.value === findWork.markValue)?.unit_price_yearly ?? 0;
                            price = price / 100 / 12;
                        } else {
                            price = marks.find(markItem => markItem.value === findWork.markValue)?.unit_price_monthly ?? 0;
                            price = price / 100;
                        }

                        const wordpack = planItem.wordpacks.find(wordpack =>
                            wordpack.wordpack.word_credits ===
                            quantityWord &&
                            planItem.plan.plan_id ===
                            wordpack.wordpack.plan_id,
                        );

                        if (wordpack) {
                            period = plan === Period.Month
                                ? wordpack.wordpack.stripe_price_monthly_id
                                : wordpack.wordpack.stripe_price_yearly_id;
                        } else {
                            period =
                                plan === Period.Month
                                    ? planItem.wordpacks[0].wordpack
                                        .stripe_price_monthly_id
                                    : planItem.wordpacks[0].wordpack
                                        .stripe_price_yearly_id;
                        }
                    } else {
                        period =
                            plan === Period.Month
                                ? planItem.wordpacks[0].wordpack
                                    .stripe_price_monthly_id
                                : planItem.wordpacks[0].wordpack
                                    .stripe_price_yearly_id;
                    }
                    const homeUrl =
                        `${process.env.REACT_APP_PUBLIC_URL}/${t(
                            intl,
                            'route.urlLang',
                        )}${t(
                            intl,
                            'route.backoffice',
                        )}${t(intl, 'route.agent.home')}` ??
                        'http://localhost:3000';

                    const planUrl =
                        `${process.env.REACT_APP_PUBLIC_URL}/${t(
                            intl,
                            'route.urlLang',
                        )}${t(
                            intl,
                            'route.begin',
                        )}${t(intl, 'route.plans')}` ??
                        'http://localhost:3000';

                    const requestPlan: Session = {
                        cancel_url: planUrl,
                        success_url: homeUrl,
                        mode: 'subscription',
                        price_id: period,
                        workspace_id: workspace.id,
                        seats: planItem.plan.name === 'Avançado' ? 5 : 1,
                    };

                    return (
                        <div
                            key={planItem.plan.plan_id}
                            className={`box ${planItem.plan.name === 'Equipes' ? 'active' : ''}`}
                        >
                            {planItem.plan.name === 'Equipes' && (
                                <div className="label-popular">
                                    <span>
                                        {t(intl, 'plansPage.mostPopular')}
                                    </span>
                                </div>
                            )}
                            <h1>{planItem.plan.name}</h1>
                            <p>{planItem.plan.description}</p>
                            <div className="price">
                                <p>
                                    <span className="value">
                                        <span>
                                            {price.toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL',
                                                maximumFractionDigits: 0,
                                            })}
                                        </span>
                                    </span>
                                </p>
                            </div>
                            <p className="words">
                                {t(intl, 'plansPage.forMonth')}{' '}
                                {plan === Period.Year
                                    ? t(intl, 'plansPage.year')
                                    : t(intl, 'plansPage.month')}
                            </p>

                            <Button
                                onClick={() => handleClick(requestPlan)}
                                type="submit"
                                icon={<HiArrowNarrowRight />}
                                loading={loading === period}
                                style={{ marginTop: 30, marginBottom: 30 }}
                            >
                                {t(intl, 'plansPage.startNow')}
                            </Button>

                            <p className="description">
                                {isCriador
                                    ? t(intl, 'plansPage.description.basic')
                                    : t(intl, 'plansPage.description.genius')}
                            </p>
                            {isDesktop ? (
                                isCriador ? (
                                    <ul>
                                        <li>
                                            <CheckEnable /> {basicFeatures[0]}
                                        </li>
                                        <li>
                                            <CheckEnable />
                                            {basicFeatures[1]}
                                        </li>
                                        <li>
                                            <CheckEnable />
                                            {basicFeatures[2]}
                                        </li>
                                        <li>
                                            <CheckEnable />
                                            {basicFeatures[3]}
                                        </li>
                                        <li>
                                            <CheckEnable />
                                            {basicFeatures[4]}
                                        </li>
                                        <li>
                                            <CheckEnable />
                                            {basicFeatures[5]}
                                        </li>
                                    </ul>
                                ) : (
                                    <ul>
                                        <li>
                                            <CheckEnable />
                                            {geniusFeatures[0]}
                                        </li>
                                        <li>
                                            <CheckEnable />
                                            {geniusFeatures[1]}
                                        </li>
                                        <li>
                                            <CheckEnable /> {geniusFeatures[2]}
                                        </li>
                                        <li>
                                            <CheckEnable /> {geniusFeatures[3]}
                                        </li>
                                        <li>
                                            <CheckEnable />
                                            {geniusFeatures[4]}
                                        </li>
                                        <li>
                                            <CheckEnable />
                                            {geniusFeatures[5]}
                                        </li>
                                    </ul>
                                )
                            ) : !isCriador ? (
                                <ul>
                                    <li>
                                        <CheckEnable /> {basicFeatures[0]}
                                    </li>
                                    <li>
                                        <CheckEnable />
                                        {basicFeatures[1]}
                                    </li>
                                    <li>
                                        <CheckEnable />
                                        {basicFeatures[2]}
                                    </li>
                                    <li>
                                        <CheckEnable />
                                        {basicFeatures[3]}
                                    </li>
                                    <li>
                                        <CheckEnable />
                                        {basicFeatures[4]}
                                    </li>
                                    <li>
                                        <CheckEnable />
                                        {basicFeatures[5]}
                                    </li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        <CheckEnable />
                                        {geniusFeatures[0]}
                                    </li>
                                    <li>
                                        <CheckEnable />
                                        {geniusFeatures[1]}
                                    </li>
                                    <li>
                                        <CheckEnable /> {geniusFeatures[2]}
                                    </li>
                                    <li>
                                        <CheckEnable /> {geniusFeatures[3]}
                                    </li>
                                    <li>
                                        <CheckEnable />
                                        {geniusFeatures[4]}
                                    </li>
                                    <li>
                                        <CheckEnable />
                                        {geniusFeatures[5]}
                                    </li>
                                </ul>
                            )}
                        </div>
                    );
                })}

                <div className="box">
                    <h1>{t(intl, 'plansPage.enterprises')}</h1>
                    <p>
                        {t(intl, 'plansPage.enterprises.description')}
                    </p>
                    <div className="price">
                        <span className="value">
                            {t(intl, 'plansPage.enterprises.check')}
                        </span>
                    </div>

                    <p className="words">
                        {t(intl, 'plansPage.enterprise')}
                    </p>
                    <Button
                        type="submit"
                        icon={<HiArrowNarrowRight />}
                        style={{ marginTop: 30, marginBottom: 30 }}
                    >
                        {t(intl, 'plansPage.contactUs')}
                    </Button>
                    <p className="description">
                        {t(intl, 'plansPage.description.enterprise')}
                    </p>
                    <ul>
                        <li>
                            <CheckEnable /> {enterpriseFeatures[0]}
                        </li>
                        <li>
                            <CheckEnable />
                            {enterpriseFeatures[1]}
                        </li>
                        <li>
                            <CheckEnable />
                            {enterpriseFeatures[2]}
                        </li>
                        <li>
                            <CheckEnable />
                            {enterpriseFeatures[3]}
                        </li>
                        <li>
                            <CheckEnable />
                            {enterpriseFeatures[4]}
                        </li>
                        <li>
                            <CheckEnable />
                            {enterpriseFeatures[5]}
                        </li>
                    </ul>
                </div>
            </ContainerPlans>
            <ContainerFaq className={darkmode ? 'containerPlansFaqDark' : ''}>
                <header>
                    <h1> {t(intl, 'plansPage.faq')}</h1>
                    <p>
                        {t(intl, 'plansPage.faq.1')}{' '}
                        <a href="#faq">
                            {t(intl, 'plansPage.faq.2')}
                        </a>
                    </p>
                </header>
                <div>
                    <div className="box">
                        <h1>{t(intl, 'plansPage.faq.3')}</h1>
                        <p>{t(intl, 'plansPage.faq.4')}</p>
                    </div>
                    <div className="box">
                        <h1>{t(intl, 'plansPage.faq.5')}</h1>
                        <p>{t(intl, 'plansPage.faq.6')}</p>
                    </div>
                    <div className="box refund">
                        <h1>{t(intl, 'plansPage.faq.7')}</h1>
                        <p>{parse(t(intl, 'plansPage.faq.8'))}</p>
                    </div>
                </div>
            </ContainerFaq>
        </Container>
    );
};

export default Plans;
