import React from 'react';
import * as S from './styles';
import { IconTrash } from '@tabler/icons-react';
import { Select } from '../Select';
import { Button } from '../Button';
import { WorkingHours } from 'services/agent/interfaces';
import useText from 'app/presentation/hooks/useText';

interface Props {
    workingHours: WorkingHours[];
    setWorkingHours: (workingHours: WorkingHours[]) => void;
}

const daysOptions: string[] = [
    'weekdays',
    'weekend',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export const WorkingHoursContainer: React.FC<Props> = ({
    workingHours,
    setWorkingHours,
}) => {
    const { textGetter } = useText();
    const t = textGetter('agent.components.working-hours-container');

    const daysLabels: Map<string, string> = new Map<string, string>([
        ['weekdays', t('weekdays')],
        ['weekend', t('weekend')],
        ['monday', t('monday')],
        ['tuesday', t('tuesday')],
        ['wednesday', t('wednesday')],
        ['thursday', t('thursday')],
        ['friday', t('friday')],
        ['saturday', t('saturday')],
        ['sunday', t('sunday')],
    ]);

    const handleDeleteWorkingHours = (wh: WorkingHours) => {
        if (wh != undefined) {
            const index = workingHours.indexOf(wh);
            if (index !== -1) {
                const newData = workingHours.filter((el, i) => {
                    return i != index;
                });
                setWorkingHours(newData);
            }
        }
    };

    const addField = () => {
        if (workingHours != undefined) {
            const items = [...workingHours];
            items.push({
                days: 'weekdays',
                start_at: '9:00',
                end_at: '17:00',
            });
            setWorkingHours(items);
            return;
        }
    };

    const onDaysSelectChange = (index: number): ((value: string) => void) => {
        return (value: string) => {
            const items = [...workingHours];
            items[index].days = value;
            setWorkingHours(items);
        };
    };

    const onStartHoursSelectChange = (
        index: number,
    ): ((value: string) => void) => {
        return (value: string) => {
            const items = [...workingHours];
            items[index].start_at = value;
            setWorkingHours(items);
        };
    };

    const onEndHoursSelectChange = (
        index: number,
    ): ((value: string) => void) => {
        return (value: string) => {
            const items = [...workingHours];
            items[index].end_at = value;
            setWorkingHours(items);
        };
    };

    return (
        <S.WorkingHoursContainer>
            {workingHours.length > 0 && (
                <div className="working-hours-container">
                    {workingHours.map((item, index) => {
                        return (
                            <div className="working-hours" key={index}>
                                <Select
                                    variant="grey"
                                    value={item.days}
                                    onSelectChange={onDaysSelectChange(index)}
                                    options={daysOptions.map(option => {
                                        return {
                                            value: option,
                                            node: daysLabels.get(option),
                                        };
                                    })}
                                    width="160px"
                                ></Select>
                                <div className="hours-container">
                                    <Select
                                        variant="grey"
                                        value={item.start_at}
                                        onSelectChange={onStartHoursSelectChange(
                                            index,
                                        )}
                                        options={Array.from(Array(24).keys())
                                            .filter(hour => {
                                                return (
                                                    parseInt(
                                                        item.end_at.split(
                                                            ':',
                                                        )[0],
                                                    ) > hour
                                                );
                                            })
                                            .map(hour => {
                                                return {
                                                    value: `${hour}:00`,
                                                    node: `${hour}:00 h`,
                                                };
                                            })}
                                        width="105px"
                                    ></Select>
                                    <p>às</p>
                                    <Select
                                        variant="grey"
                                        value={item.end_at}
                                        onSelectChange={onEndHoursSelectChange(
                                            index,
                                        )}
                                        options={Array.from(Array(25).keys())
                                            .filter(hour => {
                                                return (
                                                    parseInt(
                                                        item.start_at.split(
                                                            ':',
                                                        )[0],
                                                    ) < hour
                                                );
                                            })
                                            .map(hour => {
                                                return {
                                                    value: `${hour}:00`,
                                                    node: `${hour}:00 h`,
                                                };
                                            })}
                                        width="105px"
                                    ></Select>
                                    <Button
                                        type="button"
                                        variant="danger"
                                        className="delete-button"
                                        onClick={() =>
                                            handleDeleteWorkingHours(item)
                                        }
                                    >
                                        <IconTrash></IconTrash>
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <Button
                type="button"
                variant="link"
                className="add-button"
                onClick={addField}
            >
                + {t('add-working-hours')}
            </Button>
        </S.WorkingHoursContainer>
    );
};
