import styled from 'styled-components';

export const Header = styled.div`
    max-width: 880px;
    margin: 24px auto 20px auto;
    display: flex;
    align-items: center;
    @media (max-width: 64em) {
        flex-direction: column;
    }
    justify-content: space-between;
    top: 0;

    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.5em;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        span {
            margin-left: 13px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 66px;
            height: 24px;
            background: #d0ff61;
            border-radius: 100px;
            font-size: 14px;
        }
    }

    @media (min-width: 64em) {
        padding: 0 40px;
        margin: 24px auto 55px auto;

        max-width: none;
        margin-top: -36px;
        margin-right: 235px;
        p {
            display: block;
        }
    }

    &.headerDark {
        h1 {
            color: #fff;
            .beta {
                color: #000;
            }
        }

        p {
            color: #a09fa5;
        }

        button {
            background: #000 !important;
            color: #fff;
        }
    }
`;

export const ContainerPage = styled.div`
    min-height: 100vh;
    max-height: fit-content;

    @media (min-width: 64em) {
        padding: 40px 40px 20px 40px;

        overflow-y: hidden !important;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;
