import { api } from '../api';

export class PersonalitiesService {
    private readonly path = '/personalities';

    async getAll(search: string | undefined) {
        const { data } = await api.get(
            `${this.path}?limit=1000${search ? `&${search}` : ''}`,
        );

        return data;
    }
}
