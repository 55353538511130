import styled from 'styled-components';

export const Header = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid #edf2f7;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerIcons {
        min-width: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fafafa;
        border-radius: 8px;
        margin-right: 16px;
    }

    #more {
        margin-left: auto;
        margin-right: 0px;
    }

    h1 {
        font-weight: 700;
        color: #1a202c;
        font-size: 1.125em;
    }

    .title-desktop {
        display: none;
    }

    .headerNewChatDark {
        background: #000 !important;
    }

    @media (min-width: 64em) {
        .title-mobile {
            display: none;
        }

        display: block;
        padding: 0;
        border-bottom: 0;
        margin-top: 24px;
        height: auto;

        > svg {
            display: none;
        }

        p {
            margin-top: 8px;
            color: #718096;
            font-size: 1em;
        }
    }
`;

export const ModalMore = styled.div`
    width: 396px;
    padding: 12px;
    border-radius: 12px;
    bottom: calc(100% + 20px);
    display: flex;
    flex-direction: column;
    gap: 8px;

    .item {
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 12px;
        border-radius: 6px;
        cursor: pointer;

        &.disabled {
            h1,
            p {
                color: #7e7e7e;
            }
        }

        &:hover {
            background-color: #f7fafc;
        }

        &.active {
            background-color: #f7fafc;
        }

        h1 {
            font-size: 14px;
            color: #000000;
        }

        p {
            font-size: 12px;
            margin-top: 2px;
            color: #7e7e7e;
        }
    }
`;

export const Form = styled.form`
    box-shadow: 0px -8px 32px rgba(26, 32, 44, 0.05);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: #fff;
    min-height: 75px;
    max-height: 200px;
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 55px;
    gap: 10px;

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(100px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fadeOutDown {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(200px);
        }
    }

    .textAreaContainer {
        width: 100%;
        margin: 0;
        height: 100%;
        overflow: hidden;

        .titleTextArea {
            font-size: 0.875rem;
            color: #000;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .textAreaContent {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 8px;
            border: 1px solid #e2e8f0;
            background: #fff;
            padding: 16px 50px;
            color: #000;

            /* body/medium/medium */
            font-family: Manrope;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
            letter-spacing: 0.2px;

            .highlightedText {
                width: fit-content;
                padding: 8px 6px;
                border-radius: 6px;
                background: #e8edff;
                color: #436cff;
                text-align: right;

                /* body/medium/semibold */
                font-family: Manrope;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 300%; /* 21px */
                letter-spacing: 0.2px;
            }

            @media (min-width: 64rem) {
                max-height: 76px;
                overflow-y: auto;
            }
        }
        .btn {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: 8px;
        }

        .flexStartText {
            justify-content: flex-start;
        }

        .inputMobile {
            width: 100%;
            max-height: 150px;
            overflow-y: auto;
            height: 20px;
            min-height: 20px;
            resize: none;
            border: 1px solid transparent;
            ::-webkit-scrollbar {
                width: 0;
            }
        }
    }

    .audio-recorder {
        background-color: #fff;
        box-shadow: none;
    }

    .loadingMessage {
        position: absolute;
        right: 0;
        margin-right: 1rem;
    }

    button[type='submit'] {
        border: none;
    }

    .select-personality {
        position: relative;
        padding: 10px;
        height: 34px;
        border-radius: 6px;
        border: 1px solid #e2e8f0;
        background-color: #fff;
        display: flex;
        align-items: center;
        margin-left: 6px;
        justify-content: space-between;

        span {
            font-weight: 600;
        }
    }

    .select {
        display: flex;
        align-items: center;

        select {
            width: 174px;
            height: 34px;
            padding: 0 10px;
            border: 1px solid #e2e8f0;
            border-radius: 6px;
            margin-left: 13px;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
        }
    }

    .options-desktop {
        display: none;
    }

    .more-input {
        input {
            padding-left: 140px !important;
        }
    }

    .input-chat {
        display: flex;
        align-items: center;
        position: relative;
        margin: 0;
        width: 100%;

        input {
            padding-left: 56px;
            padding-right: 56px;
        }

        #add-option {
            position: relative;

            &.link {
                display: flex;
                gap: 10px;
                align-items: center;
                background-color: #e8edff;
                color: #436cff;
            }

            &.image {
                color: #936dff;
                background-color: #936dff1a;
            }

            &.more {
                width: auto !important;
                min-width: 67px;
                padding: 5px;
                min-height: 24px;
                border-radius: 6px;
                font-weight: 600;
            }

            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            background: #edf2f7;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 16px;
            cursor: pointer;
            font-size: 14px;
        }

        &.record {
            margin: 20px 0;
            height: 20px;
            #audio-record {
                position: absolute;
                right: 0px;
                width: 100% !important;
                z-index: 99;
                border: none;

                .audio-recorder {
                    width: 100% !important;
                    border: none;
                }
            }
        }

        #audio-record {
            position: absolute;
            right: 10px;
            z-index: 99;
            border: none;
            border-radius: 100%;
        }
    }

    > div {
        margin-bottom: 0;
    }

    .submit-button {
        width: 52px;
        height: 40px;
        background: #000;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;

        &:disabled {
            background: #808080;
            cursor: not-allowed;
        }
    }

    #send {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        span {
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            margin-left: 5px;
        }

        .stop-button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;
            margin-bottom: 14px;
            width: 40px;
            height: 40px;
            border-radius: 8px;
        }
    }

    #toggle {
        width: 44px;
        height: 24px;
        background: #edf2f7;
        border-radius: 1000px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.active {
            justify-content: flex-end;
            background: #000;

            > div {
                border: 2px solid #000;
            }
        }

        > div {
            width: 24px;
            height: 24px;
            background: #ffffff;
            border-radius: 1000px;
            border: 2px solid #edf2f7;
        }
    }

    .clean-button {
        display: flex;
    }

    #count {
        display: none;
    }

    #clean {
        width: 156px;
        height: 34px;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        font-size: 0.875em;
        background: #f7fafc;
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
        font-weight: 600;

        #icon {
            margin-left: 0 !important;
            margin-right: 13px;
        }
    }

    .select {
        display: flex;
        align-items: center;

        select {
            width: 174px;
            height: 34px;
            padding: 0 10px;
            border: 1px solid #e2e8f0;
            border-radius: 6px;
            margin-left: 13px;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
        }
    }

    .options-desktop {
        display: none;
    }

    @media (min-width: 64em) {
        position: relative !important;
        background: #fafafa;
        box-shadow: none;
        padding: 12px 24px 10px 24px !important;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        min-height: 100%;
        gap: 0;

        .options-desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            span {
                font-weight: 600;
                font-size: 14px;
            }
        }

        #send {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 12px;

            #count {
                display: block;
                font-weight: normal;
                font-size: 12px;
                color: #a09fa5;
            }

            .clean-button {
                display: flex;
                align-items: center;
                margin-right: auto;
            }

            .stop-button {
                margin-left: 20px;
                margin-bottom: 0;
            }
        }

        .submit-button {
            margin-left: 20px;
            margin-right: 0px;
            margin-bottom: 0px;
            width: 65px;
            cursor: pointer;
        }
    }

    &.dark {
        background-color: #000 !important;

        .textAreaContainer {
            ::-webkit-scrollbar {
                width: 0;
            }
            .inputMobile {
                background: #3a3a3a;
                color: #fff;
                &:focus {
                    border-color: transparent !important;
                }
            }
            .textAreaContent {
                background: #3a3a3a;
                border: none;

                .highlightedText {
                    background: #000;
                }
            }
        }

        span {
            color: #fff !important;
        }

        [name='prompt'],
        [name='other'] {
            background-color: #3a3a3a !important;
            color: #fff;
            border: 1px solid #3a3a3a !important;
        }

        #toggle {
            background-color: #3a3a3a !important;
            color: #fff;

            &.active {
                background-color: #22c55e !important;

                > div {
                    border: 1px solid #22c55e;
                }
            }
        }

        .select-personality {
            color: #fff;
            border: 1px solid #3a3a3a;
        }

        .submit-button {
            background-color: #d0ff61;

            &:disabled {
                background: #808080;
            }
        }
    }
`;

export const ContainerModel = styled.div`
    height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
    overflow: auto;
    background: #fafafa;
    position: relative;
    padding: 20px 24px 186px 24px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    .loading-dot {
        display: flex;
        align-items: flex-end;
        border: 0 !important;
        background: transparent !important;
        > div {
            position: relative !important;
            padding-top: 32px;
            padding-top: 0;
        }
    }

    > div {
        flex: 1;
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #e2e8f0;
        border-radius: 50px;
    }

    .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo-chat {
        text-align: center;
        margin-bottom: 77px;
    }

    .grid-feature {
        display: grid;
        gap: 16px;
    }

    .feature {
        .icon {
            width: 48px;
            height: 48px;
            background: #f6ffdf;
            border-radius: 1000px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        padding: 0px 26px 0px 26px;
        background: #ffffff;
        border: 1px solid #edf2f7;
        border-radius: 16px;
        text-align: center;
        height: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            font-weight: 800;
            font-size: 15px;
            margin-bottom: 4px;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            color: #718096;
        }
    }

    .message {
        margin-bottom: 17px;
    }

    .typing {
        width: 5em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 5px;
        background: #e6e6e6;
        border-radius: 20px;
    }

    .typing__dot {
        float: left;
        width: 8px;
        height: 8px;
        margin: 0 4px;
        background: #8d8c91;
        border-radius: 50%;
        opacity: 0;
        animation: loadingFade 1s infinite;
    }

    .typing__dot:nth-child(1) {
        animation-delay: 0s;
    }

    .typing__dot:nth-child(2) {
        animation-delay: 0.2s;
    }

    .typing__dot:nth-child(3) {
        animation-delay: 0.4s;
    }

    @keyframes loadingFade {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.8;
        }
        100% {
            opacity: 0;
        }
    }

    .websearch.darkmode {
        li {
            color: #fff !important;
        }

        span {
            color: #fff;
        }

        svg circle,
        svg path {
            stroke: #fff !important;
        }

        .main {
            background-color: #3a3a3a !important;
            border: 1px solid #3a3a3a !important;
        }
    }

    .websearch .text {
        background-color: transparent !important;
        border: 0 !important;
        padding-left: 0;

        .main {
            border: 1px solid #e2e8f0;
            border-radius: 8px;
            background-color: #fff;
            padding: 13px 18px;
            color: #194bfb;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
        }

        li {
            padding: 13px 18px;
            color: #000000;
            font-weight: 600;
            display: flex;
            gap: 10px;

            &.active {
                color: #7e7e7e;
            }
        }
    }

    .hide {
        color: #ccc;
    }

    .ds-flex {
        display: flex;
        gap: 16px;
        align-items: center;

        p {
            margin: 0 !important;
        }
    }

    .text {
        border: 1px solid #e2e8f0;
        border-radius: 16px 16px 16px 4px;
        background: #ffffff;
        padding: 16px 16px 0px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        width: fit-content;
        max-width: 80%;

        &.darkmode {
            background-color: #3a3a3a !important;
            border: 0 !important;
        }

        .external-link {
            background-color: #e8edff;
            color: #436cff;
            padding: 8px 6px 8px 6px;
            border-radius: 6px;
            font-weight: 600;
            font-size: 14px;
        }

        p {
            margin-bottom: 10px;
            width: 100%;
            word-wrap: break-word;
        }

        .icons {
            display: flex;
            justify-content: flex-end;
            gap: 16px;
        }

        .options {
            .references {
                flex: 1;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                align-items: center;

                > span {
                    font-size: 14px;
                    color: #000;
                    font-weight: 700;
                }

                gap: 6px;

                li {
                    color: #194bfb;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 8px 6px 8px 6px;
                    border-radius: 6px;
                    background-color: #e8edff;
                }
            }

            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 0 0 10px 0;

            @media (min-width: 64em) {
                flex-direction: row;
            }

            > div {
                cursor: pointer;
                display: flex;
                align-items: center;
            }
        }
    }

    .image {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1000px;
        margin-top: 8px;
        img {
            padding: 5px;
        }
    }

    .message.user {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .text {
            border-radius: 16px 16px 4px 16px;
            padding: 16px;
        }

        .image {
            background-color: #194bfb;
            color: #fff;
            border: 1px solid #e2e8f0;
            font-weight: bold;
        }
    }

    .clean-button {
        display: none;
    }

    #clean {
        width: 156px;
        height: 34px;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        font-size: 0.875em;
        background: #f7fafc;
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
        font-weight: 600;

        #icon {
            margin-left: 0 !important;
            margin-right: 13px;
        }
    }

    .select {
        display: flex;
        align-items: center;

        select {
            width: 174px;
            height: 34px;
            padding: 0 10px;
            border: 1px solid #e2e8f0;
            border-radius: 6px;
            margin-left: 13px;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
        }
    }

    .options-desktop {
        display: none;
    }

    @media (min-width: 64em) {
        height: calc(100vh - 186px - 72px - 30px) !important;
        max-height: calc(100vh - 186px - 72px - 30px) !important;
        padding: 20px 24px 0px 24px;

        .empty {
            height: 100%;
        }

        .grid-feature {
            grid-template-columns: minmax(auto, 393px) minmax(auto, 393px);
            justify-content: center;
            align-items: center;

            .feature {
                display: flex;
                flex-direction: row;
                text-align: left;
                height: 96px;
                padding: 0px 26px 0px 26px;

                .icon {
                    margin: 0 12px 0 0;
                }
            }
        }

        .submit-button {
            margin-left: 7px !important;
        }
    }

    &.containerChat {
        background: #000;

        .grid-feature {
            .feature {
                background-color: #1d1d1f;
                border-color: #1d1d1f;

                p {
                    color: #cccccc;
                }

                .icon {
                    background-color: #fff;
                }
            }
        }

        .text {
            background: #1d1d1f;
        }

        .image {
            border-color: #000;
        }
        .submit-button {
            background: #d0ff61;
            color: #000;
        }
        form {
            background: #000;
            input {
                background: #3a3a3a !important;
                color: #fff !important;
                border-color: #3a3a3a;

                &:focus {
                    border: 1px solid #fff !important;
                }
            }

            #toggle {
                &.active {
                    background: #22c55e;

                    > div {
                        border: 2px solid #22c55e;
                    }
                }
            }

            #send {
                button {
                    background: #000;
                    color: #fff;
                    border-color: #1d1d1f;
                }
                span {
                    color: #fff;
                }
            }
        }
    }
`;

export const Responsive = styled.div`
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;

    @media (min-width: 64em) {
        position: static;
        height: auto;
        max-height: inherit;
    }
`;

export const MuiMenuHeader = styled.div`
    border: 1px solid;
    border-radius: 8px;
    width: 100%;
    padding: 10px 10px 20px 10px;
    visibility: visible;
    box-shadow: 8px 12px 24px rgba(93, 106, 131, 0.1);
    z-index: 9999;

    .menu-mui-header {
        margin-top: 9px;
        padding-bottom: 17px;
        border-bottom: 1px solid #edf2f7;
        margin-bottom: 8px;

        #toggle {
            background-color: #3a3a3a !important;
            color: #fff;

            &.active {
                background-color: #22c55e !important;

                > div {
                    border: 1px solid #22c55e;
                }
            }
        }

        h1 {
            font-weight: 700;
            font-size: 1em;
            margin-bottom: 4px;
        }

        p {
            font-weight: 500;
            font-size: 0.75em;
            color: #718096;
        }
    }

    .usingDarkHeader {
        border-color: #3a3a3a !important;
    }

    ul {
        list-style: none;

        li {
            height: 44px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .liTitle {
            display: flex;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid #f1f1f1;
            p {
                color: #7e7e7e;
                font-weight: 600;
                font-size: 0.875rem;
            }
        }

        .select {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            justify-content: space-between;

            span {
                font-weight: 600;
                color: #000;
                margin: 0;
            }
            select {
                width: 174px;
                height: 34px;
                padding: 0 10px;
                border: 1px solid #e2e8f0;
                border-radius: 6px;
                margin-left: 13px;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
            }
        }

        .select-personality {
            position: relative;
            padding: 10px;
            height: 34px;
            border-radius: 6px;
            border: 1px solid #e2e8f0;
            background-color: #fff;
            display: flex;
            align-items: center;
            margin-left: 6px;
            justify-content: space-between;

            span {
                font-weight: 600;
                color: #000;
            }
        }

        .options-desktop {
            display: none;
        }

        .clean-button {
            display: flex;
            align-items: center;
            margin-right: auto;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            padding: 10px 0;

            span {
                display: block;
                flex: 1;
                color: #000;
                font-weight: 600;
                font-size: 0.875rem;
                margin: 0;
            }
        }

        #toggle {
            width: 44px;
            height: 24px;
            background: #edf2f7;
            border-radius: 1000px;
            display: flex;
            align-items: center;

            &.active {
                justify-content: flex-end;
                background: #000;

                > div {
                    border: 2px solid #000;
                }
            }

            > div {
                width: 24px;
                height: 24px;
                background: #ffffff;
                border-radius: 1000px;
                border: 2px solid #edf2f7;
            }
        }

        span {
            margin-left: 12px;
            color: #1a202c;
            font-weight: 600;
            font-size: 0.875em;
        }
    }

    .usingDarkMode {
        #toggle {
            background-color: #3a3a3a !important;
            &.active {
                background: #22c55e !important;

                > div {
                    border: 1px solid #22c55e;
                }
            }

            > div {
                background: #fff !important;
            }
        }
    }

    .personalizeDarkChat {
        color: #fff !important;
    }

    @media (min-width: 26.56rem) {
        width: 332px;
    }
`;
