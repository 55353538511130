export enum TranscriptIds {
    transcription = 'transcription',
    paragraph_summarization = 'paragraph_summarization',
    full_summarization = 'full_summarization',
    sentiment_analysis = 'sentiment_analysis',
    keyword_detection = 'keyword_detection',
    entity_detection = 'entity_detection',
    important_sentences = 'important_sentences',
    speakers_detection = 'speakers_detection',
    translation = 'translation',
}

export type AudioTranscriptionAction = {
    id: number;
    created_at: string;
    updated_at: string;
    type: string;
    result: string;
    status: string;
};

export type Transcript = {
    id: number;
    created_at: string;
    updated_at: string;
    youtube_url: string;
    length: number;
    text: string;
    audio_url: string;
    audio_transcription_actions: AudioTranscriptionAction[];
    status: string;
    title: string;
};
