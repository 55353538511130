import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routeTranslate } from 'utils/intl';

export const Management: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const intl = useIntl();

    const mode = searchParams.get('mode');
    const oobCode = searchParams.get('oobCode');
    const apiKey = searchParams.get('apiKey');

    React.useEffect(() => {
        if (mode && oobCode && apiKey) {
            if (mode === 'verifyEmail') {
                navigate(
                    `/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(
                        intl,
                        'route.emailVerify',
                    )}?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}`,
                );
            } else if (mode === 'resetPassword') {
                navigate(
                    `/${routeTranslate(intl, 'route.urlLang')}${routeTranslate(
                        intl,
                        'route.forgotPassword',
                    )}?mode=${mode}&oobCode=${oobCode}&apiKey=${apiKey}`,
                );
            }
        }
    }, []);

    return <>Carregando...</>;
};
