import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 156.95px;
    align-items: left;
    width: 100%;
    background-color: #f7fafc;
    color: black;
    border-radius: 12px;
    margin-top: 12px;
    padding: 20px;
    gap: 12px;

    .training-stats-title {
        font-weight: 700;
        font-size: 14px;
    }
`;

export const StatItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    flex-direction: row;
    @media (max-width: 64em) {
        justify-content: space-between;
    }
`;

export const StatItem = styled.div`
    color: #1a202c;
    width: 95px;

    .used {
        font-weight: 700;
        font-size: 32px;
    }

    .training-type {
        font-weight: 600;
        font-size: 12px;
    }

    .total {
        font-weight: 600;
        font-size: 12px;
        color: #a09fa5;
    }
`;
