import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: fit-content;
`;

export const Paginator = styled.div`
    ${({
        theme: {
            components: {
                paginator: { text, background, border },
            },
        },
    }) => css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: fit-content;

        button {
            color: ${text.color};
        }

        .page-item {
            border: 1px solid transparent;
            border-radius: 8px;
            min-width: 40px;
            height: 40px;
            font-weight: 700;
            background: ${background.color} !important;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(113, 128, 150);
        }

        .Mui-selected {
            color: ${text.active};
            border-color: ${border.active};
        }
    `}
`;
