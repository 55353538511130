import styled, { css } from 'styled-components';

export const GifPicker = styled.div`
    ${({
        theme: {
            components: {
                gifPicker: { background, border },
            },
        },
    }) => css`
        background-color: #11151deb;
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        .content-modal {
            background: ${background.color};
            min-width: 550px;
            height: 460px;
            border: 1px solid ${border.color};
            padding: 12px;
            display: flex;
            flex-direction: column;
            border-radius: 12px;
            gap: 12px;
            input {
                height: 42px;
                border-radius: 8px;
            }
            .gif-container {
                width: 100%;
                max-height: 100%;
                overflow-y: scroll;
                display: grid;
                grid-template-columns: repeat(3, 170px);
                grid-row-gap: 12px;
                align-items: center;
                justify-content: space-between;
                grid-column-gap: auto;
                img {
                    width: 170px;
                    height: 135px;
                    border-radius: 6px;
                }
            }
        }
    `}
`;
