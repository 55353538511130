import React from 'react';
import * as S from './styles';
import { QuestionsContainer } from 'app/presentation/components/agent';
import useText from 'app/presentation/hooks/useText';

export const InitialQuestionsPanel = () => {
    const { textGetter } = useText();
    const panelText = textGetter('agent.panel.inicial-questions');

    return (
        <S.InitialQuestionsPanel>
            <p className="panel-desc">{panelText('desc')}</p>
            <QuestionsContainer></QuestionsContainer>
        </S.InitialQuestionsPanel>
    );
};
