import React from 'react';
import { Container } from './styles';
import { LayoutProps } from '../interfaces/layout-default-props.interface';
import { AgentMenu } from 'app/presentation/components/agent';
import AgentProviders from '../../../../agentProviders';

const AgentLayout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <AgentProviders>
            <Container>
                <AgentMenu />
                <div id="content">{children}</div>
            </Container>
        </AgentProviders>
    );
};

export default AgentLayout;
